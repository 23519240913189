import React from "react";
import { Route, Routes } from "react-router-dom";
import ActivityFeed from "./ActivityFeed";
import InternalMetricFiles from "./InternalMetricFiles";
import TaskList from "./TaskList";

export default function MetricTasks() {
	return (
		<Routes>
			<Route path="" element={<TaskList />} />
			<Route path="activity-feed" element={<ActivityFeed />} />
			<Route path="internal-files" element={<InternalMetricFiles />} />
		</Routes>
	);
}
