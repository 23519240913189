import FirstMeetingResponseItem from "@/components/FirstMeetings/ResponseItem";
import useFirstMeetingReports from "@/components/FirstMeetings/useFirstMeetings";
import {
	AGREE_STATUS,
	DISAGREE_STATUS,
	IN_DISCUSSION_STATUS,
} from "@/components/FirstMeetings/utils";
import {
	canSeeVotesOnFirstMeeting,
	canVoteOnFirstMeeting,
} from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { getLastMonday } from "@/utils/general";
import { Alert, Box, Card, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

const TABS = [
	{
		key: "discuss",
		label: "Review with IC",
		statuses: [IN_DISCUSSION_STATUS],
	},
	{
		key: "disagree",
		label: "Disapproved + Further Discussion",
		statuses: [DISAGREE_STATUS],
	},
	{
		key: "agree",
		label: "Approved",
		statuses: [AGREE_STATUS],
	},
];

const getResponseStatus = (statuses, responses) => {
	if (!responses) return [];
	return responses.filter((response) => statuses.includes(response.status));
};

export default function FirstMeetingReports() {
	const LAST_MONDAY = getLastMonday(dayjs).format("YYYY-MM-DD");
	const { user } = useAuth();

	const canVote = canVoteOnFirstMeeting(user);
	const canSeeVotes = canSeeVotesOnFirstMeeting(user);
	const [status, setStatus] = useQueryParam(
		"status",
		withDefault(StringParam, ""),
	);
	const [completedSince, setCompletedSince] = useQueryParam(
		"completedSince",
		withDefault(StringParam, LAST_MONDAY),
	);

	const currentTab = TABS.find((t) => t.key === status);

	const { data: responses, isLoading } = useFirstMeetingReports(
		completedSince,
		null,
	);

	const currentStatusResponses = React.useMemo(
		() =>
			getResponseStatus(currentTab?.statuses || [], responses).sort((a, b) => {
				const aDate = dayjs(a.completedAt);
				const bDate = dayjs(b.completedAt);
				return aDate.isBefore(bDate) ? 1 : -1;
			}),
		[currentTab, responses],
	);

	if (!status) {
		const tabs = TABS;
		tabs.some((tab) => {
			const { statuses } = tab;
			return statuses.some((voteStatus) => {
				if (getResponseStatus(voteStatus, responses).length > 0) {
					setStatus(tab.key);
					return true; // Stops processing further
				}
				return false;
			});
		});
	}

	return (
		<Box m={1}>
			{/* <Typography
        variant="h1"
        sx={{
          marginBottom: 2,
        }}
      >
        Approval of Opportunity Assessment Recommendations
      </Typography> */}

			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				flexWrap="wrap"
				gap={1}
			>
				<ButtonTabs
					activeKey={status}
					options={TABS}
					width="500px"
					disableTest={(option) => {
						const { statuses } = option;
						return !statuses.some(
							(voteStatus) =>
								getResponseStatus(voteStatus, responses).length > 0,
						);
					}}
					onClick={(newValue) => {
						setStatus(newValue.key);
					}}
				/>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						label="Completed Since"
						value={dayjs(completedSince)}
						onChange={(newValue) => {
							setCompletedSince(newValue.format("YYYY-MM-DD"));
						}}
						format="MM/DD/YYYY"
						minDate={dayjs("2024-03-20")}
						// Surveys responses before this didnt have full question set
					/>
				</LocalizationProvider>
			</Box>

			<Box
				sx={{
					marginTop: 2,
				}}
			>
				{isLoading && <Typography variant="body1">Loading...</Typography>}

				{status && currentStatusResponses?.length === 0 && !isLoading && (
					<Typography variant="body1">No responses found</Typography>
				)}

				<Box
					sx={{
						"& > div:not(:last-child)": {
							marginBottom: (t) => `${t.spacing(2)} !important`,
						},
					}}
				>
					{currentStatusResponses?.length === 0 && !isLoading && (
						<Alert severity="info">
							No responses found for this date range.
						</Alert>
					)}
					{currentStatusResponses?.map((response) => (
						<Card key={response.id}>
							<FirstMeetingResponseItem
								response={response}
								canSeeVotes={canSeeVotes}
								canVote={canVote}
							/>
						</Card>
					))}
				</Box>
			</Box>
		</Box>
	);
}
