import { Box } from "@mui/material";
import { styled } from "@mui/system";

const ChatBubble = styled(Box, {
	shouldForwardProp: (prop) => prop !== "type",
})(({ theme, type = "BOT" }) => ({
	display: "flex",
	gap: theme.spacing(1),
	flexDirection: "row",
	padding: theme.spacing(2),
	borderRadius: theme.spacing(2),
	maxWidth: type === "USER" ? "fit-content" : "100%", // Conditionally apply fit-content
	wordBreak: "break-word",
	"*": {
		wordBreak: "break-word",
		maxWidth: "100%",
	},
	"& pre, & code": {
		maxWidth: "100%",
		overflowX: "auto",
		whiteSpace: "pre-wrap",
		wordBreak: "break-word",
	},
}));

export default ChatBubble;
