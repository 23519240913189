import {
	type Organization,
	getOrganizationFeaturesById,
} from "@/api/Organization";
import KPICard from "@/ui/molecules/KPICard";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import kebabCase from "lodash/kebabCase";
import React from "react";
import { useQuery } from "react-query";
import StaticStatsSchema from "./StaticStatsSchema";

type StaticStatsProps = {
	company: Organization;
};

export default function StaticStats({ company }: StaticStatsProps) {
	// get features for company
	const { data: features, isLoading: featuresLoading } = useQuery(
		["features", company.valorId],
		() => getOrganizationFeaturesById(company.valorId),
	);

	const renderStaticStatsCardContent = () => {
		const sections = StaticStatsSchema.map(
			({
				sectionTitle,
				stats,
				subtitle,
				alwaysRenderCharts = false,
				dataFetchedAccessor,
				// renderNoData,
			}) => {
				const cards = stats.map(({ title, metrics, data, dataFormatter }) => {
					const KPIs = [];
					const trendline = features?.features.find(
						(x) => x.type === data,
					)?.data;

					const noTrendline = trendline === null || trendline === undefined;
					if (!alwaysRenderCharts && noTrendline) {
						return null;
					}

					metrics?.forEach(({ label, getter, formatter }) => {
						const metricValue = getter(trendline);
						if (metricValue) {
							KPIs.push({ label, value: formatter(metricValue) });
						}
					});

					return (
						<Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={title} my={1}>
							<KPICard
								dataFormatter={dataFormatter}
								title={title}
								metrics={KPIs}
								data={trendline || []}
								keys={[data]}
							/>
						</Grid>
					);
				});

				if (cards.every((x) => x === null)) {
					return null;
				}
				const kebabedTitle = kebabCase(sectionTitle);
				return (
					<Grid key={kebabedTitle} id={kebabedTitle} spacing={0.5} container>
						<Grid xs={12} item>
							<Box display="flex" alignItems="center">
								<Typography variant="h3">{sectionTitle}</Typography>
								{dataFetchedAccessor !== undefined &&
									!company[dataFetchedAccessor] &&
									null}
							</Box>
							{subtitle && (
								<Typography variant="subtitle2">{subtitle(company)}</Typography>
							)}
						</Grid>
						{cards}
					</Grid>
				);
			},
		);

		if (sections.every((x) => x === null)) {
			return (
				<Typography variant="subtitle">
					No Primary Stats found. If you believe this is an error contact the
					labs team
				</Typography>
			);
		}
		return sections;
	};

	if (featuresLoading) {
		return (
			<Box display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
				<Box>
					<Skeleton variant="text" width={200} height={30} />
					<Skeleton variant="rectangular" width={300} height={200} />
				</Box>
				<Box>
					<Skeleton variant="text" width={200} height={30} />
					<Skeleton variant="rectangular" width={300} height={200} />
				</Box>
				<Box>
					<Skeleton variant="text" width={200} height={30} />
					<Skeleton variant="rectangular" width={300} height={200} />
				</Box>
			</Box>
		);
	}

	return <Box>{renderStaticStatsCardContent()}</Box>;
}
