import { getOrganizationById } from "@/api/Organization";
import { canSeeSurveyResults } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import Page from "@/ui/molecules/Page";
import slugify from "@/utils/slugify";
import { Alert } from "@mui/material";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useQuery } from "react-query";
import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import OrganizationCompetitors from "./Competitors";
import Contacts from "./Contacts";
import Explainability from "./Explainability";
import OrganizationFinancialsPage from "./Financials";
import OrgHeader from "./Header";
import OrganizationNotesPage from "./Notes";
import OverridePage from "./Override";
import OrganizationPortfolio from "./Portfolio";
import OrganizationPublicData from "./PublicData";
import Responses from "./Responses";
import SharepointFilesPage from "./SharepointFiles";
import OrganizationSummary from "./Summary";
import FrameworkSurveys from "./Surveys/InvestmentFramework";
import SurveyDrawer from "./Surveys/SurveyDrawer";
import Transcripts from "./Transcripts";

const withDefaultFalse = withDefault(BooleanParam, false);
function Organization() {
	const { id, slug } = useParams<{ id: string; slug: string }>();
	const { search, pathname } = useLocation();
	const { user } = useAuth();
	const navigate = useNavigate();

	const [openSurveyDrawer, setSurveyDrawerState] = useQueryParam(
		"surveyDrawerOpen",
		withDefaultFalse,
	);

	const {
		data: company,
		isLoading: isLoadingCo,
		error,
	} = useQuery(
		["Organization", id],
		async () => {
			const response = await getOrganizationById(id);
			return response;
		},
		{
			retry: 1,
		},
	);

	useEffect(() => {
		if (!slug && company?.name) {
			navigate(`/${slugify(company.name)}${pathname}${search}`, {
				replace: true,
			});
		}
	}, [slug, company, navigate, pathname, search]);

	if (isLoadingCo) {
		return <CenteredProgress isLoading={isLoadingCo} />;
	}

	const canViewResponses = canSeeSurveyResults(user);
	if (error) {
		return (
			<Page>
				<h1>Organization Not Found</h1>
				<Alert severity="error">
					You don't have access to this organization. Please contact your
					administrator.
				</Alert>
			</Page>
		);
	}

	return (
		<Page>
			<OrgHeader
				company={company}
				toggleSurveyDrawer={() => setSurveyDrawerState((o) => !o)}
			/>
			<Routes>
				<Route path="/" element={<Navigate to="summary" replace />} />

				<Route
					path="/surveys/:frameworkId"
					element={<FrameworkSurveys company={company} />}
				/>
				<Route
					path="/competitors/*"
					element={<OrganizationCompetitors company={company} />}
				/>

				{canViewResponses ? (
					<Route
						path="/response/:responseId"
						element={<Responses company={company} />}
					/>
				) : null}
				<Route
					path="/summary"
					element={<OrganizationSummary company={company} />}
				/>
				<Route
					path="/public-data"
					element={<OrganizationPublicData company={company} />}
				/>
				<Route path="/override" element={<OverridePage company={company} />} />
				<Route
					path="/documents"
					element={<SharepointFilesPage company={company} />}
				/>
				<Route
					path="/contacts"
					element={
						<Contacts
							companyName={company.name}
							valorId={company.valorId}
							logoUrl={company.logoUrl}
						/>
					}
				/>
				<Route
					path="/notes/*"
					element={<OrganizationNotesPage company={company} />}
				/>
				<Route
					path="/financials"
					element={<OrganizationFinancialsPage company={company} />}
				/>
				<Route
					path="/portfolio"
					element={<OrganizationPortfolio company={company} />}
				/>
				<Route
					path="/explainability/:modelId?"
					element={<Explainability company={company} />}
				/>
				<Route path="/transcripts/:transcriptId?" element={<Transcripts />} />
			</Routes>
			<Helmet>
				<title>{company.name}</title>
				<meta property="og:url" content={company.logoUrl} />
				<meta property="og:title" content={company.name} />
				<meta
					property="og:description"
					content={`See ${company.logoUrl} on vOS`}
				/>
			</Helmet>
			<SurveyDrawer
				open={openSurveyDrawer}
				onClose={() => {
					setSurveyDrawerState(false);
				}}
			/>
		</Page>
	);
}

export default Organization;
