import { Box } from "@mui/material";
import type dayjs from "dayjs";
import type React from "react";

function ActivityEvent({
	children,
}: {
	children: React.ReactNode;
}) {
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="flex-start"
			gap={0.5}
			flexWrap="wrap"
			sx={{
				fontSize: "0.75rem",
			}}
		>
			{children}
		</Box>
	);
}

ActivityEvent.Author = function ActivityEventAuthor({
	author,
}: {
	author: {
		firstName: string;
		lastName: string;
		profilePicture?: string;
	};
}) {
	return (
		<span
			style={{
				fontWeight: "bold",
			}}
		>
			{author.firstName} {author.lastName}
		</span>
	);
};

ActivityEvent.Value = function ActivityEventValue({
	children,
}: {
	children: React.ReactNode;
}) {
	return (
		<span
			style={{
				fontWeight: "bold",
			}}
		>
			{children}
		</span>
	);
};

ActivityEvent.When = function ActivityEventWhen({
	when,
}: {
	when: dayjs.Dayjs;
}) {
	if (!when) {
		return null;
	}
	return <span>{` - ${when.fromNow()}`}</span>;
};

export default ActivityEvent;
