import { getLastestNotesByValorIds } from "@/api/Notes";
import { getNotesByTasks } from "@/api/Process";

let taskCache = {};
const fetchTaskNotes = async (ids, resolves) =>
	new Promise((resolve) => {
		// filter out fetched notes
		const missingIds = ids.filter((id) => !taskCache[id]);

		if (missingIds.length > 0) {
			getNotesByTasks(missingIds).then((notes) => {
				const { data: notesByTask } = notes;
				const entries = Object.entries(notesByTask);
				entries.forEach(([key, value]) => {
					taskCache[key] = value;
					resolves[key].forEach((noteResolve) => {
						noteResolve(value);
					});
				});
				resolve();
			});
		}

		// resolve cached notes
		ids.forEach((id) => {
			if (taskCache[id]) {
				resolves[id].forEach((noteResolve) => {
					noteResolve(taskCache[id]);
				});
			}
		});
	});

const tasksToFetch = new Set<string>();

let taskTimeout;
let taskResolves = {};
const taskTimeoutWait = 250;
export function getNotesByTask(processId: string, taskId: string) {
	if (taskTimeout) {
		clearTimeout(taskTimeout);
	}

	tasksToFetch.add(`${processId}-${taskId}`);

	const thisPromise = new Promise((resolve) => {
		taskResolves = {
			...taskResolves,
			[`${processId}-${taskId}`]: [
				...(taskResolves[`${processId}-${taskId}`] || []),
				resolve,
			],
		};
		taskTimeout = setTimeout(() => {
			// fetch notes
			const taskResolvesCopy = { ...taskResolves };
			fetchTaskNotes(Array.from(tasksToFetch), taskResolvesCopy);

			// clear fetches
			tasksToFetch.clear();

			// clear resolves
			taskResolves = {};
		}, taskTimeoutWait);
	});
	return thisPromise;
}

export function clearTaskCache() {
	taskCache = {};
}

const companyCache = {};
const fetchOrgNotes = async (ids, resolves) =>
	new Promise((resolve) => {
		// fetch notes
		const missingIds = ids.filter((id) => !companyCache[id]);

		if (missingIds.length > 0) {
			getLastestNotesByValorIds(missingIds).then((notes) => {
				const { data: notesByTask } = notes;
				const entries = Object.entries(notesByTask);
				entries.forEach(([key, value]) => {
					companyCache[key] = value;
					resolves[key].forEach((noteResolve) => {
						noteResolve(value);
					});
				});
				resolve();
			});
		}

		// resolve cached notes
		ids.forEach((id) => {
			if (companyCache[id]) {
				resolves[id].forEach((noteResolve) => {
					noteResolve(companyCache[id]);
				});
			}
		});
	});

const companiesToFetch = new Set<string>();
let companyTimeout;
let companyResolves = {};
const companyTimeoutWait = 250;
export function getNotesByOrg(valorId: string) {
	if (companyTimeout) {
		clearTimeout(companyTimeout);
	}

	companiesToFetch.add(valorId);

	const thisPromise = new Promise((resolve) => {
		companyResolves = {
			...companyResolves,
			[valorId]: [...(companyResolves[valorId] || []), resolve],
		};
		companyTimeout = setTimeout(() => {
			// fetch notes
			const companyResolvesCopy = { ...companyResolves };
			fetchOrgNotes(Array.from(companiesToFetch), companyResolvesCopy);

			// clear fetches
			companiesToFetch.clear();

			// clear resolves
			companyResolves = {};
		}, companyTimeoutWait);
	});
	return thisPromise;
}
