import { ATREIDES_ROLE_ID, canSeeHome } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import analytics from "@/shared/analytics";
import Page from "@/ui/molecules/Page";
import ErrorBoundary from "@/utils/ErrorBoundary";
import {
	Box,
	ButtonBase,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React from "react";
import Helmet from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import CalendarReport from "./Calendar";
import CalendarCard from "./Calendar/CalendarCard";
import Drafts from "./Drafts";
import DraftCard from "./Drafts/DraftsCard";
import Interactions from "./Interactions";
import InteractionsCard from "./Interactions/InteractionsCard";
import News from "./News";
import NewsCard from "./News/NewsCard";
import ReportNavigation from "./ReportNavigation";

const HOMEPAGE_CURRENT_EVENTS_VIEW_ID = "74c4c50c-7e6e-4a03-8b23-23a67f15ad99";

function TabButton({ children, onClick, active }) {
	return (
		<ButtonBase
			sx={{
				padding: 2,
				fontWeight: (theme) => theme.typography.fontWeightBold,
				borderRadiusTopLeft: (theme) => theme.shape.borderRadius,
				borderRadiusTopRight: (theme) => theme.shape.borderRadius,
				...(active && {
					color: (theme) => theme.palette.primary.main,
					borderBottom: (theme) => `3px solid ${theme.palette.primary.main}`,
				}),
			}}
			onClick={onClick}
		>
			{children}
		</ButtonBase>
	);
}

const tabPathMap = {
	"/home": "REPORTS",
	"/home/news": "NEWS",
	"/home/recently-viewed": "HISTORY",
	"/home/drafts": "DRAFTS",
	"/home/calendar": "CALENDAR",
};

function HomePage() {
	const navigate = useNavigate();
	const location = useLocation();
	const theme = useTheme();
	const { user } = useAuth();

	const activeView = tabPathMap[location.pathname] || "REPORTS";

	const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

	const handleOrgClick = (event, valorId) => {
		analytics.track("INTERACTION_CARD Click", { valorId });
		/* eslint-disable-next-line no-param-reassign */
		event.cancelBubble = true;
		if (event.stopPropagation) {
			event.stopPropagation();
		}
		navigate(`/org/${valorId}`);
	};

	const handleSurveyClick = (event, valorId) => {
		if (event) {
			/* eslint-disable-next-line no-param-reassign */
			event.cancelBubble = true;
			if (event?.stopPropagation) {
				event.stopPropagation();
			}
		}
		analytics.track("INTERACTION_CARD SURVEY_Click", { valorId });
		navigate(`/org/${valorId}`, { openDrawer: true });
	};

	if (user.roleId === ATREIDES_ROLE_ID) {
		return (
			<Page data-cy="page__home">
				Welcome to the vOS platform. Please use the left navigation bar to
				access the VAAI Deal Flow, Market Maps or Search for a company in the
				search bar at the top.
			</Page>
		);
	}

	if (!canSeeHome(user)) {
		return (
			<Page data-cy="page__home">
				Use the left navigation bar to access the Market Maps or Financial
				Metrics
			</Page>
		);
	}

	return (
		<Page data-cy="page__home">
			<ErrorBoundary errorMessage="A problem occured with this page, Please contact the labs team if this persists">
				<Helmet>
					<title>Home - vOS</title>
				</Helmet>
				<Grid container spacing={3}>
					{isMdDown && (
						<Grid container item xs={12}>
							<Box
								sx={{
									display: "flex",
									borderBottom: `1px solid ${theme.palette.divider}`,
									mb: 1,
								}}
							>
								<TabButton
									active={activeView === "REPORTS"}
									onClick={() => navigate("/home")}
								>
									REPORTS
								</TabButton>
								<TabButton
									active={activeView === "HISTORY"}
									onClick={() => navigate("/home/recently-viewed")}
								>
									RECENTLY CONTACTED
								</TabButton>
								<TabButton
									active={activeView === "NEWS"}
									onClick={() => navigate("/home/news")}
								>
									NEWS
								</TabButton>
								<TabButton
									active={activeView === "DRAFTS"}
									onClick={() => navigate("/home/drafts")}
								>
									SURVEY DRAFTS
								</TabButton>
								<TabButton
									active={activeView === "CALENDAR"}
									onClick={() => navigate("/home/calendar")}
								>
									UPCOMING EVENTS
								</TabButton>
							</Box>
						</Grid>
					)}
					<Grid
						item
						xs={12}
						lg={9}
						style={{ paddingTop: 0, display: "flex", width: "100%" }}
					>
						{activeView === "HISTORY" && (
							<Interactions
								onClick={handleOrgClick}
								onSurveyClick={handleSurveyClick}
							/>
						)}
						{activeView === "REPORTS" && <ReportNavigation />}
						{activeView === "NEWS" && <News />}
						{activeView === "DRAFTS" && <Drafts />}
						{activeView === "CALENDAR" && (
							<Box sx={{ width: "100%", overflow: "hidden" }}>
								<Box>
									<Typography
										sx={{
											"& > a": {
												color: (t) => t.palette.primary.main,
												textDecoration: "none",
											},
										}}
										variant="h3"
									>
										<Link to="/">Home</Link> &gt; Upcoming Events
									</Typography>
								</Box>
								<CalendarReport />
							</Box>
						)}
					</Grid>
					{!isMdDown && (
						<Grid item xs={12} lg={3} style={{ paddingTop: 0, width: "100%" }}>
							{activeView !== "DRAFTS" && (
								<Box sx={{ marginBottom: 2 }}>
									<DraftCard />
								</Box>
							)}
							{activeView !== "CALENDAR" && (
								<Box sx={{ marginBottom: 2 }}>
									<CalendarCard />
								</Box>
							)}
							{activeView !== "HISTORY" && (
								<Box sx={{ marginBottom: 2 }}>
									<InteractionsCard
										onClick={handleOrgClick}
										onSurveyClick={handleSurveyClick}
									/>
								</Box>
							)}
							{activeView !== "NEWS" && (
								<Box>
									<NewsCard />
								</Box>
							)}
						</Grid>
					)}
				</Grid>
				{/* <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: `1px solid ${theme.palette.divider}`,
          padding: theme.spacing(1),
          width: 120,
          height: 80,
          borderRadius: theme.spacing(1),
        }}
        >
          <Typography variant="boldSubtitle2">
            Low Return
          </Typography>
          <Warning sx={{ color: theme.palette.warning.main }} />
          <Typography
            variant="boldSubtitle2"
          >
            Predicted
          </Typography>
        </Box> */}
			</ErrorBoundary>
		</Page>
	);
}

export default HomePage;
