import Box from "@mui/material/Box";
import type React from "react";

export default function NotPrintable({
	children,
}: { children: React.ReactNode }) {
	return (
		<Box
			sx={{
				"@media print": {
					display: "none",
				},
			}}
		>
			{children}
		</Box>
	);
}
