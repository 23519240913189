import type React from "react";

import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { SxProps } from "@mui/system";

type SectionProps = {
	children: React.ReactElement<SectionProps>[];
	"data-cy"?: string;
};

const SectionBox = styled(Box)<SectionProps>(() => ({
	// margin: theme.spacing(2),
	// padding: theme.spacing(2),
	// [theme.breakpoints.down("md")]: {
	//   margin: theme.spacing(0.5),
	//   padding: theme.spacing(0.5),
	// },
}));

type SectionTitleProps = {
	children:
		| string
		| React.ReactElement<SectionTitleProps>[]
		| React.ReactElement<SectionTitleProps>;
	"data-cy"?: string;
	sx?: SxProps;
};

const SectionTypography = styled(Typography)<SectionTitleProps>(
	({ theme }) => ({
		paddingTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		// borderBottom: `1px solid ${theme.palette.text.primary}`,
		width: "fit-content",
	}),
);
function SectionTitle({ children, "data-cy": dataCy, sx }: SectionTitleProps) {
	return (
		<SectionTypography data-cy={dataCy} variant="h3" sx={sx}>
			{children}
		</SectionTypography>
	);
}

type SectionContentProps = {
	children:
		| React.ReactElement<SectionContentProps>[]
		| React.ReactElement<SectionTitleProps>;
	"data-cy"?: string;
};
function SectionContent({ children, "data-cy": dataCy }: SectionContentProps) {
	return (
		<Box data-cy={dataCy} mt={2}>
			{children}
		</Box>
	);
}

function SectionComponent({ children, "data-cy": dataCy }: SectionProps) {
	return <SectionBox data-cy={dataCy}>{children}</SectionBox>;
}

SectionComponent.Title = SectionTitle;
SectionComponent.Content = SectionContent;

export default SectionComponent;
