import JSX from "@/ui/molecules/RichTextEditor/Serializer/JSX";
import MarkdownSerializer from "@/ui/molecules/RichTextEditor/Serializer/MarkdownSerializer";
import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";

function SingleCommentary({
	title,
	commentary,
}: { title: string; commentary: string }): JSX.Element {
	return (
		<Box>
			<Typography variant="h3">{title}</Typography>
			<Divider />
			<Box>
				{commentary &&
					JSX.serialize(MarkdownSerializer.deserialize(commentary))}
			</Box>
		</Box>
	);
}

export default function ReviewCommentary({
	management,
	growth,
	market,
	keyUnderwriting,
}: {
	management: string;
	growth: string;
	market: string;
	keyUnderwriting: string;
}): JSX.Element {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<SingleCommentary title="Management" commentary={management} />
			</Grid>
			<Grid item xs={12} md={6}>
				<SingleCommentary title="Growth" commentary={growth} />
			</Grid>
			<Grid item xs={12} md={6}>
				<SingleCommentary title="Market" commentary={market} />
			</Grid>
			<Grid item xs={12} md={6}>
				<SingleCommentary
					title="Key Underwriting Variances"
					commentary={keyUnderwriting}
				/>
			</Grid>
		</Grid>
	);
}
