import axios from "axios";

export default async function download(options: {
	url: string;
	filename?: string;
	params?: any;
	headers?: any;
}) {
	const { url, filename, params, headers } = options;

	const response = await axios.get(url, {
		params,
		headers,
		responseType: "blob",
	});

	const blob = new Blob([response.data], {
		type: response.headers["content-type"],
	});
	const downloadUrl = window.URL.createObjectURL(blob);

	const link = document.createElement("a");
	link.href = downloadUrl;
	link.download =
		filename ||
		response.headers["content-disposition"]
			.split("filename=")[1]
			.replace(/"/g, "");
	link.style.display = "none";
	document.body.appendChild(link);
	link.click();

	document.body.removeChild(link);
	window.URL.revokeObjectURL(downloadUrl);
}
