import type { paths } from "@/api/index";
import axios from "axios";

type GetTitleLabel =
	paths["/api/labels/employee-classification/{job_title}"]["get"];
export type GetTitleLabelResponse =
	GetTitleLabel["responses"]["200"]["content"]["application/json"];
export const getTitleLabel = async (
	title: string,
): Promise<GetTitleLabelResponse> => {
	const response = await axios.get(
		`/api/labels/employee-classification/${encodeURIComponent(title)}`,
	);
	return response.data;
};

type PostTitleLabel = paths["/api/labels/employee-classification"]["post"];
export type PostTitleLabelBody =
	PostTitleLabel["requestBody"]["content"]["application/json"];
export type PostTitleLabelResponse =
	PostTitleLabel["responses"]["200"]["content"]["application/json"];
export const postTitleLabel = async (
	jobTitle: string,
	label: string,
): Promise<PostTitleLabelResponse> => {
	const response = await axios.post("/api/labels/employee-classification", {
		jobTitle,
		label,
	});
	return response.data;
};

type SkipLabel = paths["/api/labels/employee-classification/skip"]["post"];
export type SkipLabelBody =
	SkipLabel["requestBody"]["content"]["application/json"];
export type SkipLabelResponse =
	SkipLabel["responses"]["200"]["content"]["application/json"];
export const skipLabel = async (
	jobTitle: string,
): Promise<SkipLabelResponse> => {
	const response = await axios.post(
		"/api/labels/employee-classification/skip",
		{
			jobTitle,
		},
	);
	return response.data;
};

type GetMixedLabels = paths["/api/labels"]["get"];
export type GetMixedLabelsResponse =
	GetMixedLabels["responses"]["200"]["content"]["application/json"];
export const getMixedLabels = async (
	filterCategory: string,
): Promise<GetMixedLabelsResponse> => {
	let url = "/api/labels";
	if (filterCategory) {
		url = `${url}?category=${filterCategory}`;
	}
	const response = await axios.get(url);
	return response.data;
};

export default {
	getTitleLabel,
	postTitleLabel,
	skipLabel,
	getMixedLabels,
};
