import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

export default function DateCell({ value }: { value: number | string | null }) {
	if (value === null) {
		return <>-</>;
	}
	return (
		<Box display="flex" flexDirection="column" gap={0.5}>
			<Typography
				variant="body2"
				sx={(theme) => ({
					fontWeight: theme.typography.fontWeightBold,
				})}
			>
				{dayjs(value).fromNow()}
			</Typography>
			{dayjs(value).format("MM/DD/YYYY")}
		</Box>
	);
}
