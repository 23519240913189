import CheckIcon from "@mui/icons-material/Check";
import React from "react";

export default function BooleanCell({
	value,
}: {
	value: boolean | string;
}) {
	return value === true || value === "Yes" ? <CheckIcon /> : <>-</>;
}
