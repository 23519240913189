import React from "react";

interface Props {
	errorMessage: string;
	children: React.ReactNode;
}

interface State {
	hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error: string, errorInfo: string) {
		// You can also log the error to an error reporting service
		// eslint-disable-next-line no-console
		console.error(error, errorInfo);
	}

	render() {
		const { hasError } = this.state;
		const {
			errorMessage = "Something went wrong. Contact the Labs Team",
			children,
		} = this.props;
		if (hasError) {
			return (
				<h3 style={{ alignSelf: "center", textAlign: "center" }}>
					{errorMessage}
				</h3>
			);
		}
		return children || null;
	}
}

export default ErrorBoundary;
