import UserAvatar from "@/ui/atoms/UserAvatar";
import { FormHelperText, Grid, ListItemText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import React from "react";
import { Field, useField } from "react-final-form";

interface User {
	id: string;
	firstName: string;
	lastName: string;
	profilePicture: string;
}

interface Props {
	questionId: string;
	users: User[];
	initialValue: string[];
	skippable?: boolean;
}

export function UserSelect({
	questionId,
	users,
	initialValue = null,
	skippable = true,
}: Props) {
	const objectUsers = users.reduce(
		(acc, user) => {
			acc[user.id] = user;
			return acc;
		},
		{} as Record<string, User>,
	);

	const userValidator = (value, skippable) =>
		!skippable && (!value ? "Required" : undefined);

	const { input: userInput, meta: userMeta } = useField(
		`${questionId}.userIds`,
		{
			initialValue,
			validate: (value) => userValidator(value, skippable),
		},
	);

	return (
		<Grid item xs={12} sx={{ paddingLeft: "0 !important" }}>
			<Field name={`${questionId}.userIds`} initialValue={initialValue}>
				{({ input, meta }) => {
					return (
						<>
							<Autocomplete
								id="checkboxes-tags-demo"
								disableCloseOnSelect
								options={users.map((user) => user.id)}
								defaultValue={meta.initial?.[0]}
								getOptionLabel={(userId: User) =>
									`${objectUsers[userId].firstName} ${objectUsers[userId].lastName}`
								}
								renderOption={(props, userId: string, { selected }) => (
									<li {...props} key={userId}>
										<Checkbox
											icon={<UserAvatar user={objectUsers[userId] as User} />}
											checkedIcon={
												<UserAvatar user={objectUsers[userId] as User} />
											}
											style={{ marginRight: 8 }}
											checked={selected}
										/>
										<ListItemText
											primary={`${objectUsers[userId].firstName} ${objectUsers[userId].lastName}`}
										/>
									</li>
								)}
								style={{ width: 500 }}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Select users"
										placeholder="Users"
										error={userMeta.touched && !!userMeta.error}
									/>
								)}
								onChange={(event, value) => {
									input.onChange(value ? [value] : []);
								}}
								onBlur={input.onBlur}
							/>
							{userMeta.touched && userMeta.error && (
								<FormHelperText error>{userMeta.error}</FormHelperText>
							)}
						</>
					);
				}}
			</Field>
		</Grid>
	);
}

export default UserSelect;
