import {
	type SignalDistribution,
	getSignalDistribution,
} from "@/api/SignalFeatures";
import { useQuery } from "react-query";

const useDistribution = (
	modelId,
	indexId,
	featureId,
	active,
): {
	data: SignalDistribution["data"];
	isLoading: boolean;
	isError: boolean;
	error: unknown;
} => {
	const { data, isLoading, isError, error } = useQuery(
		["SignalDistribution", modelId, indexId, featureId],
		async () => getSignalDistribution(modelId, indexId, featureId),
		{
			enabled: active,
			retry: false,
		},
	);
	return {
		data: data?.data,
		isLoading,
		isError,
		error,
	};
};

export default useDistribution;
