import type { AdvancedDescendant, RichText } from "../../types/RichText";
import renderElement from "./renderElement";
import renderLeaf from "./renderLeaf";

export const serialize = (
	tree: RichText,
	readonly = false,
	keyPrefix = "key",
): JSX.Element[] => {
	if (!tree) return null;

	const result: JSX.Element[] = tree.map(
		(node: AdvancedDescendant, index: number) => {
			const { text, children, attributes } = node;

			const key = `${keyPrefix}-${index}`;

			const attributesWithKey = {
				key,
				...attributes,
			};

			// render leaf
			if (text) {
				const leaf = node;
				return renderLeaf(leaf, attributesWithKey, text);
			}

			// render element
			if (children) {
				return renderElement(
					node,
					attributesWithKey,
					serialize(children, readonly, key),
					readonly,
				);
			}

			return null;
		},
	);

	return result;
};

export default {
	serialize,
};
