import { auditLPPdfs } from "@/api/Reporting";
import Progress from "@/ui/atoms/Progress";
import Page from "@/ui/molecules/Page";
import {
	Box,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	useTheme,
} from "@mui/material";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import Helmet from "react-helmet";

interface DropzoneProps {
	file: File | null;
	onChange: (file: File | null) => void;
	types: string[];
	placeholder: string;
}
function FileDropZone({ file, onChange, types, placeholder }: DropzoneProps) {
	const theme = useTheme();
	return (
		<FileUploader
			hoverTitle=" "
			handleChange={(f) => onChange(f)}
			types={types}
			dropMessageStyle={{
				background: theme.palette.background.blue,
				borderRadius: theme.spacing(4),
				padding: theme.spacing(2),
			}}
		>
			<Box
				sx={{
					height: theme.spacing(10),
					width: theme.spacing(32),
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					background: theme.palette.background.blue,
					border: "1px dashed rgba(0, 0, 0, 0.2)",
					borderRadius: theme.spacing(4),
					padding: theme.spacing(2),
				}}
			>
				{file ? file.name || "" : <Typography>{placeholder}</Typography>}
			</Box>
		</FileUploader>
	);
}

export function LPAudit() {
	const [pdfFile, setPdfFile] = React.useState<null | File>(null);
	const [excelFile, setExcelFile] = React.useState<null | File>(null);
	const [pagesPerReport, setPagesPerReport] = React.useState<number | null>(
		null,
	);

	const [busy, setBusy] = React.useState<boolean>(false);
	const [results, setResults] = React.useState<any>(null);

	const handleSubmit = async () => {
		setResults(null);
		setBusy(true);
		try {
			await auditLPPdfs(pdfFile, excelFile, pagesPerReport);
		} catch (e) {
			const { response } = e;
			if (response.status !== 200) {
				setResults({ error: "Unexpected Error, contact the labs team" });
			}
		} finally {
			setBusy(false);
		}
	};

	const renderResults = () => {
		if (!results) return null;
		if (results.error) {
			return (
				<Box>
					<Typography variant="h5" color="error.main">
						Unexpected Error Contact Labs team
					</Typography>
				</Box>
			);
		}
		return null;
	};

	return (
		<Page>
			<Helmet>
				<title>PDF PCAP Audit</title>
			</Helmet>
			<Page.Title> PDF PCAP Audit </Page.Title>
			<Page.Content>
				<Box display="flex" flexDirection="column" gap={2}>
					<Typography>
						<b>NOTE:</b>
						<ol>
							<li>
								{" "}
								All Columns must appear in the excel <b>exactly</b> as they are
								in each pdf
								<br />
								<span>
									Example: If the excel has a column named &quot;Management Fees
									(a)&quot; it will not match if the pdf calls it
									&quot;Management Fees&quot;
								</span>
							</li>
							<li>
								For long columns that jump to multilines in the excel such as
								&quot;Gain/ (Loss) From Operations Excluding $ Organization &
								Syndication Costs&quot; <b>it will not match </b> since the pdf
								breaks it into multiple lines.
								<br />
								First check the pdf and change the column name to match what
								appears on the same line as the number in the pdf ie &quot;Gain/
								(Loss) From Operations Excluding&quot;
							</li>
							<li>
								Remove any and all totals/summations at the bottom of the excel
							</li>
						</ol>
					</Typography>
					<Box display="flex" justifyContent="center" gap={1}>
						<FileDropZone
							file={pdfFile}
							onChange={setPdfFile}
							types={["PDF"]}
							placeholder="Upload LP Audit PDF Docs"
						/>
						<FileDropZone
							file={excelFile}
							onChange={setExcelFile}
							types={["XLSX"]}
							placeholder="Upload LP Audit Excel"
						/>
					</Box>
					<Box display="flex" justifyContent="center">
						<FormControl sx={{ m: 1, minWidth: 240 }}>
							<InputLabel id="pcapLabel">Number of Pages per PCAP</InputLabel>
							<Select
								labelId="pcapLabel"
								id="pcapSelector"
								value={pagesPerReport}
								label=" Number of Pages per PCAP"
								onChange={(e) => setPagesPerReport(Number(e.target.value))}
							>
								<MenuItem value={1}>1</MenuItem>
								<MenuItem value={2}>2</MenuItem>
							</Select>
						</FormControl>
						<Button
							onClick={() => handleSubmit()}
							variant="contained"
							sx={{ width: 240 }}
							disabled={
								!pdfFile || !excelFile || pagesPerReport == null || busy
							}
						>
							Submit
						</Button>
					</Box>
					{busy ? <Progress /> : null}
					<Box>{renderResults()}</Box>
				</Box>
			</Page.Content>
		</Page>
	);
}

export default LPAudit;
