import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import type React from "react";

const P2PAnalysisSkeleton: React.FC = () => {
	return (
		<Box>
			<Skeleton variant="text" width={210} height={40} />
			<Skeleton variant="rectangular" width="100%" height={40} />
			<Skeleton variant="text" width="60%" />
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<Skeleton
						variant="rectangular"
						width="100%"
						height={118}
						style={{ borderRadius: 8 }}
					/>
				</Grid>
				<Grid item xs={4}>
					<Skeleton
						variant="rectangular"
						width="100%"
						height={118}
						style={{ borderRadius: 8 }}
					/>
				</Grid>
				<Grid item xs={4}>
					<Skeleton
						variant="rectangular"
						width="100%"
						height={118}
						style={{ borderRadius: 8 }}
					/>
				</Grid>
			</Grid>
			{[...Array(4)].map((_, index) => (
				// biome-ignore lint/suspicious/noArrayIndexKey: doest make sense
				<Box key={index} mt={2}>
					<Skeleton variant="text" width="80%" />
					<Skeleton variant="rectangular" width="100%" height={200} />
				</Box>
			))}
			<Skeleton variant="text" width={210} height={40} />
			{[...Array(3)].map((_, index) => (
				// biome-ignore lint/suspicious/noArrayIndexKey: doesnt make sense
				<Skeleton key={index} variant="text" width="40%" />
			))}
			<Grid container spacing={2}>
				{[...Array(4)].map((_, index) => (
					// biome-ignore lint/suspicious/noArrayIndexKey: doesnt make sense
					<Grid item xs={6} key={index}>
						<Skeleton
							variant="rectangular"
							width="100%"
							height={118}
							style={{ borderRadius: 8 }}
						/>
					</Grid>
				))}
			</Grid>
			<Skeleton
				variant="rectangular"
				width="100%"
				height={200}
				style={{ borderRadius: 8 }}
			/>
		</Box>
	);
};

export default P2PAnalysisSkeleton;
