import SparkLine from "@/ui/atoms/SparkLine";
import { getYoyThreeMonthMonthlySmoothedGrowth } from "@/utils/calculateTimeSeriesMetrics";
import { Box, Popover, Tooltip, Typography } from "@mui/material";
import React, { useState, useRef } from "react";

type DomainValue =
	| number
	| "dataMin"
	| "dataMax"
	| "auto"
	| `${"dataMin" | "dataMax"} ${"+" | "-"} ${number}`;
type DomainFunction = (dataMin: number, dataMax: number) => [number, number];
type Domain = [DomainValue, DomainValue] | DomainFunction;
export default function TextCell({
	value,
	name,
	yDomain,
	showValue = true,
}: {
	value: {
		value?: number;
		timestamp?: number;
	}[];
	name: string;
	yDomain?: Domain;
	showValue?: boolean;
}) {
	const [showPopover, setShowPopover] = useState(false);
	const ref = useRef(null);

	if (!value || value.every((val) => val.value === 0)) return <>-</>;
	const firstValue = value.find((x) => x.value !== null)?.value ?? null;

	const dataToRender = [...value].reverse();

	const rate = getYoyThreeMonthMonthlySmoothedGrowth(dataToRender);
	const color = rate > 0 ? "green" : rate > -0.1 ? "yellow" : "red";
	const fixedRate = (rate * 100).toFixed(0);
	const formattedRate = `${fixedRate}%`;
	return (
		<Box>
			<Box
				ref={ref}
				onClick={() => {
					setShowPopover(true);
				}}
				sx={(t) => ({
					display: "flex",
					justifyContent: showValue ? "flex-start" : "center",
					alignItems: "center",
					textOverflow: "ellipsis",
					overflow: "hidden",
					whiteSpace: "normal",
					borderRadius: t.shape.borderRadius,
					"&:hover": {
						cursor: "pointer",
					},
					gap: 0.5,
					width: "100%",
				})}
			>
				{showValue ? (
					<Typography variant="boldBody1">
						{firstValue?.toLocaleString()}
					</Typography>
				) : null}
				{fixedRate === "0" ? null : (
					<Tooltip title="YoY calculation is smoothed and takes an average of trailing 3 months">
						<Box
							sx={{
								borderColor: (t) => t.palette[color].main,
								borderStyle: "solid",
								borderWidth: 1,
								borderRadius: (t) => t.shape.borderRadius,
								padding: (t) => t.spacing(0.25, 0.5),
								marginBottom: showValue ? 3 : 0,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Typography
								variant={showValue ? "gigaSmall" : "boldBody1"}
								sx={{
									color: (t) => t.palette[color].main,
								}}
							>
								{formattedRate}
								{" YoY"}
							</Typography>
						</Box>
					</Tooltip>
				)}
			</Box>
			<Box>
				<Popover
					open={showPopover}
					onClose={() => setShowPopover(false)}
					anchorEl={ref.current}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
				>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						width="300px"
						padding={2}
						margin={1}
					>
						<Typography variant="boldBody2">{name}</Typography>
						<Box display="flex" width="100%" justifyContent="center">
							<SparkLine
								domain={yDomain}
								data={dataToRender}
								xAxis
								height={200}
							/>
						</Box>
					</Box>
				</Popover>
			</Box>
		</Box>
	);
}
