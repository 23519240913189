import { FrameworkChoiceColorIconMapping } from "@/constants/FrameworkChoiceColorIconMapping";
import { serialize as JSXSerialize } from "@/ui/molecules/RichTextEditor/Serializer/JSX";
import MarkdownSerializer from "@/ui/molecules/RichTextEditor/Serializer/MarkdownSerializer";
import { formatBigDollars } from "@/utils/numberFormat";
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState, useMemo } from "react";

const periodConverter = {
	MONTHLY: "M",
	QUARTERLY: "Q",
	YEARLY: "Y",
};

const safeJsonParse = (str) => {
	try {
		return typeof str === "string" ? JSON.parse(str) : null;
	} catch (e) {
		console.error("Error parsing JSON:", e);
		return null;
	}
};

const calculatePersonalCoDifference = ({ personal, company }) => {
	let difference = null;
	if (company !== "N/A" && personal !== "N/A") {
		const coNum = Number.parseFloat(company);
		const perNum = Number.parseFloat(personal);
		if (coNum === 0 && perNum === 0) {
			return null;
		}
		if (coNum === 0) {
			difference = ((coNum - perNum) / Math.abs(perNum)) * 100;
		} else {
			difference = ((perNum - coNum) / Math.abs(coNum)) * 100;
		}
		return difference.toFixed(2);
	}
	return difference;
};

const getDifferenceColor = (value) => {
	if (value === null) return null;
	if (value >= 10) return "green";
	if (value >= -25) return "yellow";
	return "red";
};

const StatusIndicator = styled(Box)(
	({ theme, color, isMobile, hasCommentary }) => ({
		...(isMobile
			? {
					width: 12,
					height: 12,
					borderRadius: "50%",
					backgroundColor:
						theme.palette[color]?.main || theme.palette.grey[300],
					cursor: "pointer",
					boxSizing: "border-box",
					outline: hasCommentary
						? `2px solid ${theme.palette[color]?.main}`
						: "none",
					outlineOffset: "1px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}
			: {
					width: "100%",
					height: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					cursor: "pointer",
					backgroundColor: color
						? theme.palette[color]?.main
						: theme.palette.background.paper,
					transition: theme.transitions.create(["background-color"]),
					"&:hover": {
						backgroundColor: color
							? theme.palette[color]?.dark
							: theme.palette.action.hover,
					},
				}),
	}),
);

const StyledDialogContent = styled(DialogContent)(({ theme, borderColor }) => ({
	whiteSpace: "pre-line",
	minWidth: 300,
	padding: theme.spacing(3),
	"& .content-wrapper": {
		marginBottom: theme.spacing(2),
		"& .value": {
			padding: theme.spacing(2),
			border: `2px solid ${theme.palette[borderColor]?.main || theme.palette.grey[300]}`,
			borderRadius: theme.shape.borderRadius,
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(2),
		},
	},
}));

const getIndicator = ({
	theme,
	question,
	answerData,
	commentary,
	color,
	subject,
	isMobile,
}) => {
	if (isMobile) {
		const content = null;
		let statusColor = color;

		switch (question.questionType) {
			case "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE":
			case "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION": {
				const parsedData =
					typeof answerData === "string" && answerData !== "N/A"
						? JSON.parse(answerData)
						: answerData;
				const difference = calculatePersonalCoDifference({
					personal: parsedData?.personal,
					company: parsedData?.company,
				});
				statusColor = getDifferenceColor(difference);
				break;
			}
		}

		return {
			color: statusColor,
			content: content && (
				<Typography
					variant="caption"
					sx={{
						fontSize: "8px",
						color:
							theme.palette.getContrastText(theme.palette[statusColor]?.main) ??
							"white",
					}}
				>
					{content}
				</Typography>
			),
		};
	}

	// Desktop indicators
	switch (question.questionType) {
		case "PERCENTAGE_ESTIMATE":
			return {
				color,
				content: (
					<Typography variant="subtitle2">
						{answerData === null ? undefined : `${answerData}%`},
					</Typography>
				),
			};

		case "QUARTER":
			return {
				color,
				content: (
					<Typography variant="subtitle2" textAlign={"center"}>
						{commentary !== null ? commentary : null}
					</Typography>
				),
			};
		case "DOLLAR_ESTIMATE": {
			return {
				color: null,
				content: (
					<Box sx={{ display: "flex", gap: "4px" }}>
						<Typography variant="subtitle2">
							{answerData === "N/A" ? answerData : formatBigDollars(answerData)}
						</Typography>
					</Box>
				),
			};
		}
		case "DOLLAR_ESTIMATE_WITH_PERIOD_TYPE": {
			let personal, period;
			const answerObj = safeJsonParse(answerData);
			personal = answerObj.personal;
			period = answerObj.period;

			return {
				color: null,
				content: (
					<Box sx={{ display: "flex", gap: "4px" }}>
						<Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
							{personal === "N/A" ? personal : formatBigDollars(personal)}
						</Typography>
						{period && (
							<Typography variant="subtitle2">
								{periodConverter[period]}
							</Typography>
						)}
					</Box>
				),
			};
		}

		case "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE":
		case "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION": {
			let personal, period, company;

			if (
				["DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE"].includes(
					question.questionType,
				)
			) {
				const answerObj = safeJsonParse(answerData);
				if (!answerObj) {
					return { color };
				}
				personal = answerObj.personal;
				period = answerObj.period;
				company = answerObj.company;
			} else if (
				question.questionType === "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION"
			) {
				const answerObj = safeJsonParse(answerData);
				if (!answerObj) {
					return { color };
				}
				personal = answerObj.personal;
				company = answerObj.company;
			} else {
				personal = answerData;
			}

			const difference = getDifferenceColor(
				calculatePersonalCoDifference({
					personal,
					company,
				}),
			);

			return {
				color: difference,
				content: (
					<Box sx={{ display: "flex", gap: "4px" }}>
						{company && (
							<Typography variant="subtitle2">
								{company === "N/A" ? company : formatBigDollars(company)}
							</Typography>
						)}
						{company && <Typography variant="subtitle2">v.</Typography>}
						<Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
							{personal === "N/A" ? personal : formatBigDollars(personal)}
						</Typography>
						{period && (
							<Typography variant="subtitle2">
								{periodConverter[period]}
							</Typography>
						)}
					</Box>
				),
			};
		}

		case "FISCAL_YEAR": {
			const parse = JSON.parse(answerData);
			return {
				color,
				content: (
					<Typography variant="subtitle2">{`Current FY: ${parse.currentYear}`}</Typography>
				),
			};
		}

		case "SINGLE_USER":
			return {
				color,
				content: subject?.[0] && (
					<Typography variant="boldBody2">
						{`${subject[0].firstName} ${subject[0].lastName}`}
					</Typography>
				),
			};

		case "TEXT":
		case "RETURN_DISTRIBUTION":
		case "MULTIPLE_CHOICE": {
			const Icon = FrameworkChoiceColorIconMapping[color];
			const iconColor = ["red", "green", "blue"].includes(color)
				? "#fff"
				: theme.palette.mode === "light"
					? "#4d4d4d"
					: "black";

			return {
				color,
				content: Icon && (
					<Icon
						sx={{
							color: iconColor,
							border: commentary ? "2px solid" : "none",
							borderColor: iconColor,
							borderRadius: "50%",
							padding: 0.25,
						}}
					/>
				),
			};
		}

		case "DATE": {
			let dateContent = null;
			try {
				const answer = JSON.parse(answerData);
				dateContent = (
					<Box sx={rootSx}>
						<Typography variant="subtitle2">
							{dayjs(answer.date).format("MM/DD/YYYY")}
						</Typography>
					</Box>
				);
			} catch (e) {
				dateContent = (
					<Box sx={rootSx}>
						<Typography variant="subtitle2">{answerData}</Typography>
					</Box>
				);
			}

			return { color, content: dateContent };
		}

		default:
			return { color };
	}
};

const useDialogContent = (
	question,
	attribute,
	answerData,
	commentary,
	text,
	subject,
) => {
	return useMemo(() => {
		switch (question.questionType) {
			case "PERCENTAGE_ESTIMATE":
			case "QUARTER":
				return {
					title: attribute.name || "Estimate",
					value:
						answerData === null
							? undefined
							: `${answerData}${question.questionType === "PERCENTAGE_ESTIMATE" ? "%" : ""}`,
					commentary: commentary,
				};

			case "DOLLAR_ESTIMATE":
			case "DOLLAR_ESTIMATE_WITH_PERIOD_TYPE":
			case "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE":
			case "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION": {
				let content = "";
				let personal, period, company;

				if (
					[
						"DOLLAR_ESTIMATE_WITH_PERIOD_TYPE",
						"DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE",
					].includes(question.questionType)
				) {
					const answerObj = safeJsonParse(answerData) || {};
					personal = answerObj.personal;
					period = answerObj.period;
					company = answerObj.company;
				} else if (
					question.questionType ===
					"DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION"
				) {
					const answerObj = JSON.parse(answerData);
					personal = answerObj.personal;
					company = answerObj.company;
				} else {
					personal = answerData;
				}

				if (company) {
					content += `Company: ${company === "N/A" ? company : formatBigDollars(company)}\n`;
				}
				content += `Personal: ${personal === "N/A" ? personal : formatBigDollars(personal)}`;
				if (period) {
					content += `\nPeriod: ${periodConverter[period]}`;
				}
				return {
					title: attribute.name || "Financial Estimate",
					value: content,
					commentary: commentary,
				};
			}

			case "FISCAL_YEAR": {
				const parse = JSON.parse(answerData);
				return {
					title: attribute.name || "Fiscal Year",
					value: `Current FY: ${parse.currentYear}`,
					commentary: commentary,
				};
			}

			case "TEXT":
				return {
					title: attribute.name || "Text Response",
					value: null,
					commentary: commentary,
				};

			case "MULTIPLE_CHOICE":
				return {
					title: attribute.name || "Selected Choice",
					value: text,
					commentary: commentary,
				};

			case "SINGLE_USER":
				return {
					title: attribute.name || "Selected User",
					value: subject?.[0]
						? `${subject[0].firstName} ${subject[0].lastName}`
						: "No user selected",
					commentary: commentary,
				};

			case "RETURN_DISTRIBUTION": {
				const distributionData = JSON.parse(answerData);
				const content = Object.entries(distributionData)
					.map(([key, value]) => `${key}: ${value}%`)
					.join("\n");
				return {
					title: attribute.name || "Return Distribution",
					value: content,
					commentary: commentary,
				};
			}

			default:
				return {
					title: attribute.name || "Response",
					value: "No data available",
					commentary: commentary,
				};
		}
	}, [question, answerData, commentary, text, subject, attribute.name]);
};

export const GridItem = React.memo(function GridItem({
	commentary,
	color,
	text,
	answerData,
	question,
	subject,
	attribute,
}) {
	const [open, setOpen] = useState(false);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const indicator = useMemo(
		() =>
			getIndicator({
				theme,
				question,
				answerData,
				commentary,
				color,
				subject,
				isMobile,
			}),
		[theme, question, answerData, commentary, color, subject, isMobile],
	);

	const dialogContent = useDialogContent(
		question,
		attribute,
		answerData,
		commentary,
		text,
		subject,
	);

	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				p: isMobile ? 0.5 : 0,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<StatusIndicator
				color={indicator.color}
				onClick={() => setOpen(true)}
				isMobile={isMobile}
				hasCommentary={!!commentary}
			>
				{indicator.content}
			</StatusIndicator>

			<Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm">
				<DialogTitle>{dialogContent.title}</DialogTitle>
				<StyledDialogContent borderColor={indicator.color}>
					<div className="content-wrapper">
						<Typography className="value">{dialogContent.value}</Typography>
						{commentary && (
							<>
								<Typography variant="subtitle2" color="textSecondary">
									Commentary
								</Typography>
								<Typography variant="body2">
									{JSXSerialize(
										MarkdownSerializer.deserialize(commentary),
										true,
									)}
								</Typography>
							</>
						)}
					</div>
				</StyledDialogContent>
			</Dialog>
		</Box>
	);
});

export default GridItem;
