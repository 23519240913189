import { type GetCompletionsResponse, getCompletions } from "@/api/Chat";
import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	CircularProgress,
	Grid,
	Typography,
} from "@mui/material";
import dayjs from "dayjs";
import type React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const CompletionsList: React.FC = () => {
	const navigate = useNavigate();

	const {
		data: completions,
		isLoading,
		error,
	} = useQuery<GetCompletionsResponse>("completions", getCompletions);

	if (isLoading) {
		return (
			<Box display="flex" justifyContent="center" padding={4}>
				<CircularProgress />
			</Box>
		);
	}

	if (error) {
		return (
			<Box padding={4}>
				<Typography color="error">Error loading completions</Typography>
			</Box>
		);
	}

	return (
		<Box sx={{ padding: 2 }}>
			{completions?.map((completion) => (
				<Card
					key={completion.id}
					sx={{
						width: "100%",
						mb: 2,
						"&:hover": {
							boxShadow: 3,
							transition: "box-shadow 0.3s ease-in-out",
						},
					}}
				>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<Typography variant="h6">
										{completion.prompt.promptFamily.name}
									</Typography>
									<Typography variant="caption" color="textSecondary">
										Created by: {completion.prompt.creator.name}
									</Typography>
								</Box>
								<Typography
									color="textSecondary"
									variant="caption"
									display="block"
								>
									{dayjs(completion.createdAt).format("YYYY-MM-DD HH:mm:ss")}
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<Typography
									variant="body1"
									sx={{
										maxHeight: "100px",
										overflow: "hidden",
										textOverflow: "ellipsis",
										mb: 2,
									}}
								>
									{completion.response}
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<Box display="flex" gap={1} flexWrap="wrap" mb={2}>
									<Chip
										label={`${completion.model.provider}: ${completion.model.name} ${completion.model.version}`}
										size="small"
										color="primary"
									/>
									<Chip
										label={`Tokens: ${completion.tokensUsed}`}
										size="small"
										color="secondary"
									/>
									<Chip
										label={`Latency: ${completion.latencyMs}ms`}
										size="small"
									/>
								</Box>
								<Box display="flex" gap={1} flexWrap="wrap">
									{completion.evaluationCriteria.map((criteria, index) => (
										<Chip
											key={index}
											label={criteria}
											size="small"
											variant="outlined"
										/>
									))}
								</Box>
							</Grid>

							<Grid item xs={12}>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<Chip
										label={`${completion.evaluationCount} evaluations`}
										color="info"
										size="small"
									/>
									<Button
										variant="contained"
										onClick={() => navigate(`/evals/evals/${completion.id}`)}
										size="small"
									>
										View Details
									</Button>
								</Box>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			))}
		</Box>
	);
};

export default CompletionsList;
