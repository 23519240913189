import { searchNotes } from "@/api/Notes";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import PageComponent from "@/ui/molecules/Page";
import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { groupBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import MeetingPage from "../MeetingPage";
import { FundList, FundListFilter } from "../utils";
import CompanyNotesTable from "./CompanyNotesTable";

const noteFlavors = {
	"liquidity-events": {
		title: "Liquidity Events",
		type: "Liquidity Event - Active",
	},
	"pro-entropic-m-a": {
		title: "Pro-Entropic M&A",
		type: "Pro-Entropic M&A - Active",
	},
	"fund-raising": {
		title: "Fundraising (Debt & Equity)",
		type: "Fundraising (Debt and Equity) – Active",
	},
};

const noteOptions = Object.keys(noteFlavors).map((key) => ({
	label: noteFlavors[key].title,
	key,
}));

function MeetingNotesPages({ subPath }) {
	const { subPage } = useParams<{ subPage?: string }>();
	const navigate = useNavigate();
	const [notesSince, setNotesSince] = useState(
		dayjs().subtract(1, "week").valueOf(),
	);
	const [activeFund, setActiveFund] = useState(null);
	useEffect(() => {
		setActiveFund(null);
	}, [subPage]);

	const noteFlavor = noteFlavors[subPage];

	const { type } = noteFlavor ?? {};
	const {
		data = {},
		isLoading,
		error,
	} = useQuery(
		type,
		async () => {
			const response = await searchNotes(type, null, null, null, 0, 100);
			return groupBy(response?.hits ?? [], "valorId"); // change
		},
		{
			enabled: !!noteFlavor,
		},
	);

	const coNotes = Object.values(data ?? {});
	let sortedNotes = coNotes.sort((a, b) => {
		const maxUpdate = (x) =>
			Math.max(...x.map((y) => y.updatedAt ?? y.createdAt));
		return maxUpdate(b) - maxUpdate(a);
	});

	if (sortedNotes.length) {
		sortedNotes = sortedNotes.map((notes) => {
			const fundNotes = notes.filter((x) => {
				const timeToCheck = x.updatedAt ?? x.createdAt;
				if (!timeToCheck) {
					return false;
				}
				if (dayjs(timeToCheck * 1000).isBefore(dayjs(notesSince))) {
					return false;
				}
				return true;
			});
			return fundNotes;
		});
		sortedNotes = sortedNotes.filter((x) => x.length);
	}

	const flatNotes = Object.values(sortedNotes ?? {}).flat();

	const buttonOptions = FundList.filter((x) => x !== "R&D")
		.map((key) => [key, FundListFilter[key]])
		.map(([fundName, fundBoolConditional]) => {
			let shouldDisable = !flatNotes.some(
				(x) => x?.funds.primaryFund === fundName,
			);
			const anySecondary =
				shouldDisable &&
				flatNotes.every((x) =>
					x?.funds.primaryFund === null
						? x?.funds[fundBoolConditional] === false
						: true,
				);
			shouldDisable = shouldDisable && anySecondary;

			return {
				label: fundName,
				key: fundBoolConditional,
				disabled: fundName === "VAF" ? true : shouldDisable,
			};
		});

	buttonOptions.push({
		label: "Other",
		key: "UNCLASSIFIED",
		disabled: !flatNotes.some(
			(x) =>
				x?.funds.primaryFund === null ||
				FundList.indexOf(x.funds.primaryFund) === -1,
		),
	});

	if (sortedNotes.length) {
		sortedNotes = sortedNotes.map((notes) => {
			const fundNotes = notes.filter((x) => {
				if (
					activeFund === "UNCLASSIFIED" &&
					(x.funds.primaryFund === null ||
						FundList.indexOf(x.funds.primaryFund) === -1)
				) {
					return true;
				}
				if (activeFund === FundListFilter[x.funds.primaryFund]) {
					return true;
				}
				if (x.funds.primaryFund === null) {
					return x.funds[activeFund] === true;
				}
				return false;
			});
			return fundNotes;
		});
		sortedNotes = sortedNotes.filter((x) => x.length);
	}

	const buttonTabs = (
		<ButtonTabs
			width="720px"
			wrap
			tabLabel="Financing Type"
			activeKey={subPage}
			options={noteOptions}
			onClick={(option) => navigate(`${subPath}/${option.key}`)}
		/>
	);
	return (
		<MeetingPage title="Portfolio Company Financing">
			<Box
				m={1}
				display="flex"
				alignItems="center"
				justifyContent="space-between"
			>
				<Box>{buttonTabs}</Box>
				<DatePicker
					label="Updated Since"
					format="YYYY-MM-DD"
					value={notesSince ? dayjs(notesSince) : null}
					onChange={(date) => {
						setNotesSince(date.format("YYYY-MM-DD"));
					}}
				/>
			</Box>
			{subPage ? (
				<>
					<Box m={1} height={80}>
						<ButtonTabs
							width="720px"
							wrap
							tabLabel="Fund"
							activeKey={activeFund}
							options={buttonOptions}
							disableTest={(option) => option.disabled}
							onClick={(option) => setActiveFund(option.key)}
						/>
					</Box>
					{activeFund ? (
						<CompanyNotesTable
							companyNotes={sortedNotes}
							isLoading={isLoading}
							error={error}
						/>
					) : null}
				</>
			) : null}
		</MeetingPage>
	);
}

export function FinancingReport() {
	return (
		<PageComponent>
			<PageComponent.Content>
				<MeetingNotesPages subPath="/financing-report" />
			</PageComponent.Content>
		</PageComponent>
	);
}

export default MeetingNotesPages;
