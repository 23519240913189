import { useLocalStorageState } from "@/hooks/useLocalStorageState";
import analytics from "@/shared/analytics";
import { LinkBehavior, getTheme } from "@/utils/valorMUITheme";
import {
	StylesProvider,
	ThemeProvider as ThemeProviderV4,
	createGenerateClassName,
	createTheme as createThemeV4,
} from "@material-ui/core/styles";
import {
	ThemeProvider as ThemeProviderV5,
	createTheme as createThemeV5,
} from "@mui/material/styles";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import React, { useMemo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { ProvideAuth } from "@/hooks/useAuth";
import { ProvidePopover } from "@/hooks/useGlobalPopover";
import { ProvidePlatformInfo } from "@/hooks/usePlatformInfo";
import { SocketProvider } from "@/hooks/useSocket";
import type { LinkProps } from "@mui/material/Link";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import packageJson from "../package.json";
import AuthApp from "./AuthApp";
import "./App.css";

dayjs.extend(relativeTime);

if (window.location.hostname === "os.labs.valorep.com") {
	LogRocket.init("wb5g1h/vos", {
		release: packageJson.version,
		sdkServer: "https://logrocket.labs.valorep.com",
		ingestServer: "https://logrocket.labs.valorep.com",
	});
	setupLogRocketReact(LogRocket);
}

LogRocket.getSessionURL((sessionURL) => {
	analytics.track("LogRocket", { sessionURL });
});

const generateClassName = createGenerateClassName({
	disableGlobal: true,
	seed: "argos",
});

const queryClient = new QueryClient();

function App() {
	const [isDarkMode] = useLocalStorageState(false, "isDarkMode");

	const v4Theme = useMemo(
		() => createThemeV4(getTheme(isDarkMode ? "dark" : "light")),
		[isDarkMode],
	);
	const v5Theme = useMemo(
		() =>
			createThemeV5({
				components: {
					MuiButtonBase: {
						defaultProps: {
							LinkComponent: LinkBehavior,
						},
					},
					MuiLink: {
						defaultProps: {
							component: LinkBehavior,
						} as LinkProps,
					},
				},
				...getTheme(isDarkMode ? "dark" : "light"),
			}),
		[isDarkMode],
	);
	return (
		<StylesProvider generateClassName={generateClassName}>
			<ThemeProviderV5 theme={v5Theme}>
				<ThemeProviderV4 theme={v4Theme}>
					<Router>
						<QueryParamProvider adapter={ReactRouter6Adapter}>
							<ProvideAuth>
								<ProvidePopover>
									<ProvidePlatformInfo>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<SocketProvider>
												<QueryClientProvider client={queryClient}>
													<AuthApp />
												</QueryClientProvider>
											</SocketProvider>
										</LocalizationProvider>
									</ProvidePlatformInfo>
								</ProvidePopover>
							</ProvideAuth>
						</QueryParamProvider>
					</Router>
				</ThemeProviderV4>
			</ThemeProviderV5>
		</StylesProvider>
	);
}

export default App;
