import { updateOrgFieldValue } from "@/api/Organization";
import {
	type Process,
	type TaskList,
	createChoice,
	createField,
	createTask as createTaskAction,
	createView,
	deleteChoice,
	deleteField,
	deleteView,
	getFilterOptionsCount,
	getProcessTasks,
	linkField,
	updateTask as patchTask,
	updateChoice,
	updateField,
	updateFields,
	updateProcess,
	updateTaskFieldValue as updateTaskFieldValueApi,
	updateView,
	updateViews,
} from "@/api/Process";
import React, { createContext, useMemo, useCallback } from "react";

import { useAuth } from "@/hooks/useAuth";

import {
	depageData,
	getNextPageParam,
	mapSorting,
} from "@/components/InfiniteTable";
import { useInfiniteQuery, useQueryClient } from "react-query";

import { useParams } from "react-router-dom";

import isDateOnlyISOString from "@/utils/dates";
import type {
	ColumnFiltersState,
	SortingState,
	VisibilityState,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import omit from "lodash/omit";
import { filterBodyToArray } from "../ProcessView/ViewUtils";
import { updateTask as updateTaskAction } from "./actions";
import useProcess from "./useProcess";

type ProcessViewParams = { processId: string; "*": string };

export const ProcessDataContext = createContext<{
	id: string;
	name: string;
	entityName: string;
	fields: Process["fields"];
	fieldFilters: Process["fieldFilters"];
	settings: Process["settings"];
	tasks: TaskList["tasks"];
	isLoadingTasks: boolean;
	currentTaskId: string;
	groupedTasks: {
		group: string;
		tasks: TaskList["tasks"];
	}[];
	sorting: SortingState;
	columnFilters: ColumnFiltersState;
	apiColumnFilters: Record<string, any>;
	columnVisibility: VisibilityState;
}>({
	id: "",
	name: "",
	entityName: "",
	fields: [],
	fieldFilters: [],
	settings: [],
	tasks: [],
	isLoadingTasks: true,
	currentTaskId: "",
	groupedTasks: [],
	sorting: [],
	columnFilters: [],
	apiColumnFilters: {},
	columnVisibility: {},
});

export const ProcessActionsContext = createContext<{
	addTask: () => void;
	updateTask: () => void;
	fetchNextPage: () => void;
	setSorting: () => void;
	setColumnFilters: () => void;
	setColumnVisibility: () => void;
	setColumnOrder: () => void;
}>({
	addTask: () => {},
	updateTask: () => {},
	fetchNextPage: () => {},
	setSorting: () => {},
	setColumnFilters: () => {},
	setColumnVisibility: () => {},
	setColumnOrder: () => {},
});

export function ProcessProvider(props: {
	processId: string;
	viewId: string;
	mode: "grouped" | "ungrouped";
	activeGroup: string;
	query: string;
	archived?: boolean;
	children: React.ReactNode;
	sorting?: SortingState;
	columnFilters?: ColumnFiltersState;
	columnVisibility?: VisibilityState;
	columnOrder?: string[];
	onColumnFiltersChange?: (newFilter: ColumnFiltersState) => void;
	onSortingChange?: (newSort: SortingState) => void;
	onColumnVisibilityChange?: (newVisibility: VisibilityState) => void;
	onColumnOrderChange?: (newOrder: string[]) => void;
}) {
	const {
		processId,
		mode,
		activeGroup,
		query,
		sorting = [],
		onSortingChange = () => {},
		// columnFilters = [],
		onColumnFiltersChange = () => {},
		columnVisibility = {},
		onColumnVisibilityChange = () => {},
		columnOrder = [],
		onColumnOrderChange = () => {},
		children,
		archived = false,
		viewId,
	} = props;

	// deconstruct url
	const { "*": ticketId } = useParams<ProcessViewParams>();
	const idFragments = ticketId?.split("-");
	const currentTaskId = idFragments?.[idFragments.length - 1];
	const { user: currentUser } = useAuth();

	// query client
	const queryClient = useQueryClient();

	// get process data
	const {
		data: processData,
		actions: { refetch: refetchProcess },
		isLoading: isProcessLoading,
	} = useProcess(processId);
	const { fields, views } = processData || {};

	const activeView = useMemo(
		() => views?.find((v) => v.id === viewId),
		[views, viewId],
	);

	const columnFilters = useMemo(() => {
		const filters = props?.columnFilters;
		// if filters are an array, return them
		if (Array.isArray(filters)) {
			return filters;
		}
		// if an object, convert to array
		if (filters) {
			return filterBodyToArray(filters, fields);
		}

		return filters;
	}, [props?.columnFilters, fields]);

	const handleFilterChange = useCallback(
		(newFilter) => {
			if (onColumnFiltersChange) {
				// hide conversion til we need to handle more complex filters in ui
				if (typeof newFilter === "function" || Array.isArray(newFilter)) {
					onColumnFiltersChange(newFilter);
				} else {
					// this handles saved views
					onColumnFiltersChange(filterBodyToArray(newFilter, fields));
				}
			}
		},
		[onColumnFiltersChange, fields],
	);

	const handleSortChange = useCallback(
		(newSort) => {
			if (onSortingChange) {
				onSortingChange(newSort);
			}
		},
		[onSortingChange],
	);

	const handleVisibilityChange = useCallback(
		(newVisibility) => {
			if (onColumnVisibilityChange) {
				onColumnVisibilityChange(newVisibility);
			}
		},
		[onColumnVisibilityChange],
	);

	const handleOrderChange = useCallback(
		(newOrder) => {
			if (onColumnOrderChange) {
				onColumnOrderChange(newOrder);
			}
		},
		[onColumnOrderChange],
	);

	const activeFieldFilters = useMemo(() => {
		// get the group by field
		const groupBy = activeGroup;

		// find field filters for it
		return processData?.fieldFilters?.filter(
			({ subjectId }) => subjectId === groupBy,
		);
	}, [processData, activeGroup]);

	// all task data
	const LIMIT = 40;
	const getFilters = useMemo(() => {
		if (mode === "grouped" || archived) {
			return { AND: {}, OR: {}, NOT: {} };
		}

		// Helper function to process range values for date fields
		const processRangeValue = (value, fieldType, filterId) => {
			if (value === null) {
				return null;
			}
			if (["date", "target_date", "date_time"].includes(fieldType)) {
				return dayjs(value).format("YYYY-MM-DD");
			}
			if (filterId === "dateAdded") {
				return dayjs(value).endOf("day").valueOf();
			}
			return value;
		};

		// Helper function to safely merge filters maintaining nested structure
		const mergeFilters = (acc, logicalOp, operator, fieldId, value) => ({
			...acc,
			[logicalOp]: {
				...acc[logicalOp],
				[operator]: {
					...(acc[logicalOp]?.[operator] || {}),
					[fieldId]: value,
				},
			},
		});

		// Process column filters
		const filters = columnFilters?.reduce(
			(acc, filter) => {
				const [, , fieldId] = filter.id.split(".");
				const field = fields.find((f) => f.id === fieldId);
				const type = field?.type;
				const logicalOp = filter.logicalOp || "AND";
				const operator = filter.operator || "eq";

				// Handle range types (date, number, etc.)
				const rangeTypes = [
					"date",
					"target_date",
					"number",
					"dollar",
					"date_time",
				];
				if (rangeTypes.includes(type) || filter.id === "dateAdded") {
					const { min, max } = filter.value;
					if (min === null && max === null) return acc;

					const processedMin = processRangeValue(min, type, filter.id);
					const processedMax = processRangeValue(max, type, filter.id);

					let result = acc;

					// Handle min value (gte)
					if (processedMin !== null) {
						result = mergeFilters(
							result,
							logicalOp,
							"gte",
							filter.id,
							processedMin,
						);
					}

					// Handle max value (lte)
					if (processedMax !== null) {
						result = mergeFilters(
							result,
							logicalOp,
							"lte",
							filter.id,
							processedMax,
						);
					}

					return result;
				}

				if (
					[
						"select",
						"select_multi",
						"ryg",
						"user",
						"user_multi",
						"person",
						"person_multi",
						"company",
					].includes(type)
				) {
					return mergeFilters(
						acc,
						logicalOp,
						operator,
						filter.id,
						filter.value.map(({ value: v }) => v),
					);
				}

				if (type === "checkbox") {
					return mergeFilters(acc, logicalOp, operator, filter.id, [
						filter.value ? "Yes" : "No",
					]);
				}

				// Handle text and default cases
				return mergeFilters(acc, logicalOp, operator, filter.id, filter.value);
			},
			{ AND: {}, OR: {}, NOT: {} },
		);

		// Handle search query
		const searchFields = {
			name: fields.find((f) => f.type === "text" && f.name === "Name"),
			description: fields.find(
				(f) => f.type === "text_multi" && f.name === "Description",
			),
			primaryCompany: fields.find((f) => f.type === "company" && f.isPrimary),
		};

		const searchFilter =
			query?.length > 2
				? {
						OR: {
							ilike: {
								...(searchFields.name && {
									[`tasks.fieldValues.${searchFields.name.id}.text`]: query,
								}),
								...(searchFields.description && {
									[`tasks.fieldValues.${searchFields.description.id}.text`]:
										query,
								}),
								...(searchFields.primaryCompany && {
									[`tasks.fieldValues.${searchFields.primaryCompany.id}.company`]:
										query,
								}),
							},
						},
					}
				: {};

		return {
			AND: { ...filters.AND },
			OR: { ...filters.OR, ...searchFilter.OR },
			NOT: { ...filters.NOT },
		};
	}, [columnFilters, query, mode, archived, fields]);

	const pagedQueryKey = useMemo(
		() => [
			"processTasks",
			processId,
			query?.length > 2 ? query : undefined,
			sorting,
			columnFilters,
			mode,
			archived,
			processData?.fields,
			activeFieldFilters,
			activeView,
		],
		[
			processId,
			query,
			sorting,
			columnFilters,
			mode,
			archived,
			processData?.fields,
			activeFieldFilters,
			activeView,
		],
	);

	const {
		data: pagedTasks,
		isLoading: isLoadingTasks,
		isFetching,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage: fetchNextPageTasks,
		refetch,
	} = useInfiniteQuery({
		queryKey: pagedQueryKey,
		queryFn: ({ pageParam = null }) => {
			const filterBody = getFilters;
			return getProcessTasks(
				[processId],
				mode === "grouped" ? 5000 : LIMIT,
				pageParam,
				mode === "grouped" || archived ? [] : mapSorting(sorting),
				filterBody,
				archived && { archived },
			);
		},
		getNextPageParam,
		enabled: processData?.fields?.length > 0,
		refetchInterval: false,
		refetchOnWindowFocus: false,
	});

	const tasks = useMemo(() => depageData(pagedTasks), [pagedTasks]);

	// memoize tasks
	const data = useMemo<{
		tasks: TaskList["tasks"];
		groupedTasks: {
			group: string;
			tasks: TaskList["tasks"];
		}[];
	}>(
		() => ({
			processId,
			name: processData?.name || "",
			entityName: processData?.settings.entityName || "Task",
			boardViewDisabled: processData?.settings.boardViewDisabled || false,
			replyEntity: processData?.settings.replyEntity || "comments",
			enableSurveyLinking: processData?.settings.enableSurveyLinking || false,
			modalEditTaskTitle: processData?.settings.modalEditTaskTitle || false,
			addButtonLabel: processData?.settings.addButtonLabel || false,
			modalNewTaskTitle: processData?.settings.modalNewTaskTitle || false,
			fields,
			fieldFilters: processData.fieldFilters || [],
			views: views || [],
			forms: processData.forms || [],
			settings: processData.settings || [],
			lifecycleId: processData.lifecycleProcessFieldId || "",
			lifecycleStages: processData.lifecycleStages || [],
			currentTaskId: currentTaskId || "",
			tasks: tasks || [],
			isLoadingTasks,
			isProcessLoading,
			groupedTasks: [],
			isFetching: isFetching || isFetchingNextPage,
			sorting,
			columnFilters,
			apiColumnFilters: getFilters,
			columnVisibility,
			columnOrder,
		}),
		[
			processId,
			processData,
			tasks,
			isLoadingTasks,
			currentTaskId,
			isFetching,
			isFetchingNextPage,
			sorting,
			columnFilters,
			columnVisibility,
			columnOrder,
			isProcessLoading,
			fields,
			views,
			getFilters,
		],
	);

	// define actions
	const createTask = useCallback(
		(pId, task) => createTaskAction(pId, task),
		[],
	);
	const updateTask = useCallback(
		(
			updatedTaskId: string,
			fieldId: string,
			newValue: string,
			sortOrder: number,
		) => {
			const taskToUpdate = tasks.find(
				(t) => t.id.toString() === updatedTaskId.toString(),
			);
			const field = fields.find((f) => f.id === fieldId);

			if (!field || !taskToUpdate) {
				return;
			}

			const task = updateTaskAction(
				processId,
				taskToUpdate,
				field,
				newValue,
				sortOrder,
			);

			// update cache
			queryClient.setQueryData(pagedQueryKey, ({ pages, ...rest }) => ({
				...rest,
				pages: pages.map((page) => ({
					...page,
					data: page.data.map((t) => {
						if (t.id.toString() === updatedTaskId.toString()) {
							return task;
						}
						return t;
					}),
				})),
			}));
		},
		[processData, tasks, queryClient, processId, pagedQueryKey],
	);
	const publishTask = useCallback(
		(taskId) => {
			// get task
			patchTask(processId, taskId.toString(), {
				published: true,
			});
		},
		[processId],
	);

	// const fetchTask = useCallback(() => {}, []);
	const fetchNextPage = useCallback(() => {
		// fetch next page based on current filter / sort OR group
		if (!isFetching && !isFetchingNextPage && hasNextPage) {
			fetchNextPageTasks();
		}
	}, [isFetching, isFetchingNextPage, hasNextPage, fetchNextPageTasks]);
	// const fetchNextPageByGroup = useCallback(() => {

	// }, []);
	const updateProcessName = useCallback(
		(newName) => {
			// update cache
			queryClient.setQueryData(["process", processId], (oldData: TaskList) => ({
				...oldData,
				name: newName,
			}));

			// update server
			updateProcess({ id: processId, name: newName });
		},
		[queryClient, processId],
	);

	const toTypeFieldValue = (fieldType, fieldValue) => {
		if (fieldValue === null) {
			return null;
		}
		if (fieldType === "user_multi") {
			return fieldValue.map(({ id }) => ({ userId: id }));
		}
		if (fieldType === "person_multi") {
			return fieldValue.map(({ id }) => ({ personId: id }));
		}
		if (fieldType === "select_multi") {
			return fieldValue.map((choiceId) => ({ choiceId }));
		}
		if (fieldType === "date_time") {
			if (!fieldValue) {
				return [{ dateValue: null, dateTimeValue: null }];
			}
			if (isDateOnlyISOString(fieldValue)) {
				return [{ dateValue: fieldValue }];
			}
			return [{ dateTimeValue: fieldValue }];
		}
		return [
			{
				...(fieldType === "probability_equity_value_json" && {
					jsonValue: fieldValue,
				}),
				...(fieldType === "company" && { valorId: fieldValue.valorId }),
				...(fieldType === "user" && { userId: fieldValue.id }),
				...(fieldType === "person" && { personId: fieldValue.id }),
				...(fieldType === "select" && { choiceId: fieldValue }),
				...(["ryg", "text", "text_multi"].includes(fieldType) && {
					value: fieldValue,
				}),
				...(["number", "dollar"].includes(fieldType) && {
					numericValue: fieldValue === "" ? null : Number(fieldValue),
				}),
				...(["date", "target_date"].includes(fieldType) && {
					dateValue: fieldValue === "" ? null : fieldValue,
				}),
				...(fieldType === "checkbox" && {
					value: fieldValue ? "Yes" : "No",
				}),
			},
		];
	};
	const updateTaskFieldValue = useCallback(
		async (
			pId: string,
			taskId: string, // TODO update to default.
			fieldType: string,
			fieldId: string,
			settingId: string,
			value: any, // TODO type this
			isOrganizationField?: boolean,
			primaryValorId?: string,
		) => {
			const apiValue = toTypeFieldValue(fieldType, value);
			if (isOrganizationField && primaryValorId) {
				await updateOrgFieldValue(primaryValorId, settingId, {
					correlationId: crypto.randomUUID(),
					values: apiValue,
				});
			} else if (!isOrganizationField) {
				await updateTaskFieldValueApi(pId, taskId, fieldId, {
					correlationId: crypto.randomUUID(),
					values: apiValue,
				});
			} else {
				console.error(
					"Invalid field update, org field missing primary valor id.",
				);
			}
		},
		[queryClient, pagedQueryKey, processId],
	);

	// updates cache, comment form makes call to api
	const addComment = useCallback(
		async (pId: string, taskId: string, comment: any) => {
			// update cache
			queryClient.setQueryData(pagedQueryKey, ({ pages, ...rest }) => ({
				...rest,
				pages: pages.map((page) => ({
					...page,
					data: page.data.map((t) => {
						if (t.id.toString() === taskId) {
							let newComments = [
								...(t.comments || []),
								{
									...comment,
									pending: true,
									id: crypto.randomUUID(),
									user: currentUser,
									createdAt: dayjs.utc().toISOString(),
								},
							];
							if (comment.id) {
								newComments = t.comments.map((c) =>
									c.id === comment.id
										? {
												...comment,
												updatedAt: dayjs.utc().toISOString(),
											}
										: c,
								);
							}
							return {
								...t,
								comments: newComments,
							};
						}
						return t;
					}),
				})),
			}));
		},
		[queryClient, pagedQueryKey, currentUser],
	);

	const getFilterCounts = useCallback(
		async (processFieldId) => {
			const filters = getFilters;
			const modifiedFilters = Object.keys(filters).reduce((acc, boolType) => {
				const ops = Object.keys(filters[boolType]).reduce((accOp, op) => {
					const oppFilters = Object.keys(filters[boolType][op]).reduce(
						(accFilter, field) => {
							if (field.indexOf(processFieldId) === -1) {
								return {
									...accFilter,
									[field]: filters[boolType][op][field],
								};
							}
							return accFilter;
						},
						{},
					);
					if (Object.keys(filters).length > 0) {
						return {
							...accOp,
							[op]: oppFilters,
						};
					}
					return accOp;
				}, {});
				// if (Object.keys(ops).length > 0) {
				acc[boolType] = ops;
				// }/
				return acc;
			}, {});
			return getFilterOptionsCount(processId, processFieldId, modifiedFilters);
		},
		[processId, getFilters],
	);

	// createField,
	const createFieldAction = useCallback(
		async (pId, fieldBody) => {
			const result = await createField(pId, fieldBody);
			queryClient.setQueryData(["process", pId], (oldData) => ({
				...oldData,
				fields: [...oldData.fields, result],
			}));
			return result;
		},
		[queryClient],
	);
	// updateField,
	const updateFieldAction = useCallback(async (pId, fieldBody) => {
		updateField(pId, fieldBody);
		queryClient.setQueryData(["process", pId], (oldData) => ({
			...oldData,
			fields: oldData.fields.map((f) => {
				if (f.id === fieldBody.id) {
					return {
						...f,
						...fieldBody,
					};
				}
				return f;
			}),
		}));
	}, []);
	// deleteField,
	const deleteFieldAction = useCallback(
		(pId, fieldId) => {
			deleteField(pId, fieldId);
			queryClient.setQueryData(["process", pId], (oldData) => ({
				...oldData,
				fields: oldData.fields.filter((f) => f.id !== fieldId),
				organizationFields: oldData.organizationFields.filter(
					(f) => f.id !== fieldId,
				),
			}));
		},
		[queryClient],
	);
	// createChoice,
	const createChoiceAction = useCallback(
		async (pId, fieldId, choiceBody) => {
			const result = await createChoice(pId, fieldId, choiceBody);
			return result;
		},
		[queryClient],
	);
	// updateChoice,
	const updateChoiceAction = useCallback(async (pId, fieldId, choiceBody) => {
		const result = await updateChoice(pId, fieldId, choiceBody);
		return result;
	}, []);
	// deleteChoice,
	const deleteChoiceAction = deleteChoice;
	// linkField,
	const linkFieldAction = useCallback(
		(pId, organizationField) => {
			linkField(pId, organizationField.id).then((processField) => {
				queryClient.setQueryData(["process", pId], (oldData) => ({
					...oldData,
					organizationField: oldData.organizationFields.map((f) => {
						if (f.settingId === organizationField.id) {
							return processField;
						}
						return f;
					}),
				}));
			});
			queryClient.setQueryData(["process", pId], (oldData) => ({
				...oldData,
				organizationFields: [
					...oldData.organizationFields,
					{
						...organizationField,
						name: organizationField.label,
						settingId: organizationField.id,
						isOrganizationField: true,
						sort:
							Math.max(
								...[...oldData.fields, ...oldData.organizationFields].map(
									(f) => f.sort,
								),
							) + 1,
					},
				],
			}));
		},
		[queryClient],
	);
	const updateFieldsAction = useCallback(
		async (pId, newFields) => {
			updateFields(pId, newFields);
			queryClient.setQueryData(["process", pId], (oldData) => ({
				...oldData,
				fields: oldData.fields.map((f) => {
					const field = fields.find((ff) => ff.id === f.id);
					return {
						...f,
						...field,
					};
				}),
				organizationFields: oldData.organizationFields.map((f) => {
					const field = fields.find((ff) => ff.id === f.id);
					return {
						...f,
						...field,
					};
				}),
			}));
		},
		[queryClient],
	);

	const createViewAction = useCallback(
		async (pId, viewBody) => {
			const newView = await createView(pId, viewBody);
			queryClient.setQueryData(["process", pId], (oldData) => ({
				...oldData,
				views: [...oldData.views, newView],
			}));
			return newView;
		},
		[queryClient],
	);
	const updateViewAction = useCallback(
		async (pId, viewBody) => {
			updateView(pId, omit(viewBody, ["createdAt", "createdBy"]));
			queryClient.setQueryData(["process", pId], (oldData) => ({
				...oldData,
				views: oldData.views.map((v) => {
					if (v.id === viewBody.id) {
						return viewBody;
					}
					return v;
				}),
			}));
		},
		[queryClient],
	);
	const updateViewsAction = useCallback(
		async (pId, newViews) => {
			// update server
			updateViews(pId, newViews);
			// update cache
			queryClient.setQueryData(["process", pId], (oldData) => ({
				...oldData,
				views: oldData.views.map((v) => {
					const view = views.find((vv) => vv.id === v.id);
					return {
						...v,
						...view,
					};
				}),
			}));
		},
		[queryClient],
	);
	const deleteViewAction = useCallback(
		async (pId, viewIdToDelete) => {
			// update server
			deleteView(pId, viewIdToDelete);
			// update cache
			queryClient.setQueryData(["process", pId], (oldData) => ({
				...oldData,
				views: oldData.views.filter((v) => v.id !== viewIdToDelete),
			}));
		},
		[queryClient],
	);

	const actions = useMemo<{
		createTask: () => void;
		updateTask: () => void;
		fetchNextPage: () => void;
		publishTask: (unpublishedTaskId: string) => void;
	}>(
		() => ({
			getFilterCounts,
			addComment,
			createTask,
			updateTask,
			publishTask,
			updateTaskFieldValue,
			updateProcessName,
			unarchiveTask: async (task) => {
				// eager remove from query cache
				queryClient.setQueryData(pagedQueryKey, (oldData) => ({
					...oldData,
					pages: oldData.pages.map((page) => ({
						...page,
						data: page.data.filter(({ id }) => id !== task.id),
					})),
				}));
				await patchTask(processId, task.id, { archived: false });
			},
			fetchNextPage,
			setSorting: handleSortChange,
			setColumnFilters: handleFilterChange,
			setColumnVisibility: handleVisibilityChange,
			setColumnOrder: handleOrderChange,
			refetch,
			createField: createFieldAction,
			updateField: updateFieldAction,
			deleteField: deleteFieldAction,
			createChoice: createChoiceAction,
			updateChoice: updateChoiceAction,
			deleteChoice: deleteChoiceAction,
			linkField: linkFieldAction,
			refetchProcess,
			updateFields: updateFieldsAction,
			createView: createViewAction,
			updateView: updateViewAction,
			updateViews: updateViewsAction,
			deleteView: deleteViewAction,
		}),
		[
			queryClient,
			createTask,
			updateTask,
			fetchNextPage,
			updateProcessName,
			handleSortChange,
			handleFilterChange,
			handleVisibilityChange,
			handleOrderChange,
			pagedQueryKey,
			processId,
			refetch,
			// getTask,
			updateTaskFieldValue,
			addComment,
			getFilterCounts,
			createFieldAction,
			updateFieldAction,
			deleteFieldAction,
			createChoiceAction,
			updateChoiceAction,
			deleteChoiceAction,
			linkFieldAction,
			refetchProcess,
			updateFieldsAction,
			createViewAction,
			updateViewAction,
			updateViewsAction,
			deleteViewAction,
			publishTask,
		],
	);

	return (
		<ProcessDataContext.Provider value={data}>
			<ProcessActionsContext.Provider value={actions}>
				{children}
			</ProcessActionsContext.Provider>
		</ProcessDataContext.Provider>
	);
}

export function useProcessData() {
	const context = React.useContext(ProcessDataContext);
	if (!context) {
		throw new Error("useProcess must be used within a ProcessProvider");
	}
	return context;
}

export function useProcessActions() {
	const context = React.useContext(ProcessActionsContext);
	if (!context) {
		throw new Error("useProcessActions must be used within a ProcessProvider");
	}
	return context;
}
