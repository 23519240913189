import { getTranscriptUserScores, postUserScore } from "@/api/Tegus";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import {
	IndeterminateCheckBox,
	NotInterested,
	ThumbDown,
	ThumbUp,
} from "@mui/icons-material";
import {
	Box,
	ClickAwayListener,
	Paper,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { promptTypeTitleMap as elements } from "./utils";

function IHEQuestion({
	promptType,
	value,
	onChange,
	isLoading,
}: {
	promptType: string;
	value?: {
		color: string;
		reason: string;
	};
	onChange: (newValue: {
		color: string;
		reason: string;
	}) => void;
	isLoading?: boolean;
}) {
	const { color, reason } = value || {};

	const [localReason, setLocalReason] = React.useState(reason);

	React.useEffect(() => {
		setLocalReason(reason);
	}, [reason]);

	const onColorChange = (newColor) => {
		onChange({ color: newColor, reason: localReason });
	};

	return (
		<Box>
			<Typography variant="overline">{elements[promptType]}</Typography>
			<Stack direction="row" spacing={1}>
				<Tooltip title="Agree">
					<IconButton
						onClick={() => onColorChange("green")}
						disabled={isLoading}
					>
						<ThumbUp
							sx={{
								color:
									color === "green"
										? (theme) => theme.palette.green.main
										: null,
								"&:hover": {
									color: (theme) => theme.palette.green.main,
								},
							}}
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title="I don't know">
					<IconButton
						onClick={() => onColorChange("yellow")}
						disabled={isLoading}
					>
						<IndeterminateCheckBox
							sx={{
								color:
									color === "yellow"
										? (theme) => theme.palette.yellow.main
										: null,
								"&:hover": {
									color: (theme) => theme.palette.yellow.main,
								},
							}}
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title="Disagree">
					<IconButton onClick={() => onColorChange("red")} disabled={isLoading}>
						<ThumbDown
							sx={{
								color:
									color === "red" ? (theme) => theme.palette.red.main : null,
								"&:hover": {
									color: (theme) => theme.palette.red.main,
								},
							}}
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title="Not Applicable">
					<IconButton
						onClick={() => onColorChange("gray")}
						disabled={isLoading}
					>
						<NotInterested
							sx={{
								color:
									color === "gray"
										? (theme) => theme.palette.secondary.main
										: null,
								"&:hover": {
									color: (theme) => theme.palette.secondary.main,
								},
							}}
						/>
					</IconButton>
				</Tooltip>
			</Stack>
			<TextField
				fullWidth
				multiline
				value={localReason}
				disabled={isLoading}
				onChange={(e) => setLocalReason(e.target.value)}
				onBlur={() => {
					onChange({
						color,
						reason: localReason,
					});
				}}
			/>
		</Box>
	);
}

export default function IHEWindow({
	transcriptId,
	onClose,
}: {
	transcriptId: number;
	onClose: () => void;
}) {
	const width = Math.min(400, window.innerWidth - 100);
	const height = window.innerHeight - 240;

	const [position] = React.useState({
		x: window.innerWidth - width / 2 - 16,
		y: window.innerHeight / 2 - 50,
	});

	React.useEffect(() => {
		const listener = window.addEventListener("resize", () => {
			onClose();
		});
		return () => window.removeEventListener("resize", listener);
	});

	// load answers for trascript
	const { data, isLoading, refetch } = useQuery(
		["transcriptUserScores", transcriptId],
		() => getTranscriptUserScores(transcriptId),
		{
			refetchInterval: false,
		},
	);

	const scoreMap = React.useMemo(
		() =>
			data?.reduce(
				(acc, curr) => ({
					...acc,
					[curr.promptType]: curr,
				}),
				{},
			) || {},
		[data],
	);

	const queryClient = useQueryClient();

	const postIHE = async (promptType: string, color: string, reason: string) => {
		// eager update
		queryClient.setQueryData(
			["transcriptUserScores", transcriptId],
			(oldData: any) => [
				...(oldData?.filter((d) => d.promptType !== promptType) || []),
				{
					promptType,
					color,
					reason,
				},
			],
		);
		await postUserScore(transcriptId, { promptType, color, reason });
		refetch();
	};

	return (
		<ClickAwayListener onClickAway={onClose}>
			<Box
				position="fixed"
				top={position.y - height / 2}
				left={position.x - width / 2}
				zIndex={1000}
				width={width}
				height={height}
				sx={{
					userSelect: "none",
				}}
			>
				<Paper
					sx={{
						width: "100%",
						height: "100%",
						padding: 2,
						overflow: "auto",
					}}
				>
					{isLoading && <CenteredProgress />}
					<Typography variant="h3">Investment Hypothesis Elements</Typography>
					<Stack spacing={1}>
						{Object.keys(elements).map((key) => (
							<IHEQuestion
								key={key}
								promptType={key}
								value={scoreMap[key]}
								isLoading={isLoading}
								onChange={(value) => {
									postIHE(key, value.color, value.reason);
								}}
							/>
						))}
					</Stack>
				</Paper>
			</Box>
		</ClickAwayListener>
	);
}
