import {
	getAllSections,
	getResponses,
	getSurveyQuestions,
} from "@/api/FrameworkSurvey";
import { cellHelper } from "@/components/Framework/utils";
import DataTable from "@/components/LocalTable";
import {
	MultiUserCell,
	TextCell,
	UserCell,
} from "@/components/Table/Primitives";
import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";
import { useAuth } from "@/hooks/useAuth";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import UserAvatar from "@/ui/atoms/UserAvatar";
import Page from "@/ui/molecules/Page";
import { formatBigDollars } from "@/utils/numberFormat";
import { CalendarMonth } from "@mui/icons-material";
import {
	Box,
	Card,
	Divider,
	Link,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import TaskSelector from "../Tasks";

function createTableData(surveyResponse, surveySections) {
	const result = { multiChoiceData: [], otherData: [], allData: [] };
	surveySections.forEach((section) => {
		section.attributes.forEach((attribute) => {
			if (surveyResponse.answers[attribute.id]) {
				if (
					surveyResponse.answers[attribute.id].question.questionType ===
					"MULTIPLE_CHOICE"
				) {
					result.multiChoiceData.push({
						attributeName: attribute.name,
						attributeSubtitle: attribute.subtitle,
						...surveyResponse.answers[attribute.id],
					});
				} else {
					result.otherData.push({
						attributeName: attribute.name,
						attributeSubtitle: attribute.subtitle,
						...surveyResponse.answers[attribute.id],
					});
				}
				result.allData.push({
					attributeName: attribute.name,
					attributeSubtitle: attribute.subtitle,
					...surveyResponse.answers[attribute.id],
				});
			}
		});
	});
	return result;
}

export function OrgFrameworkResponse() {
	const { id: valorId, responseId } = useParams();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const surveyId = queryParams.get("surveyId");
	const { user } = useAuth();

  const scrollTargetRef = React.useRef(null);

	const theme = useTheme();

	const columns = useMemo(
		() =>
			[
				{
					header: "Question",
					id: "question",
					accessorFn: (row) => row.attributeName,
					cell: (prop) => {
						const { subtitle } = prop.row.original.question;
						return (
							<Tooltip title={subtitle || null} placement="top">
								<div>
									<TextCell value={prop.getValue()} />
								</div>
							</Tooltip>
						);
					},
					size: 200,
				},
				{
					header: "Answer",
					id: "answer",
					accessorFn: (row) => cellHelper(row, theme).accessor(row),
					size: 200,
					cell: (prop) => cellHelper(prop.row.original, theme).cell(prop),
				},
				{
					header: "Commentary",
					id: "value",
					accessorFn: (row) => row.commentary,
					cell: (prop) => <TextCell value={prop.getValue()} clamp={false} />,
					size: 775,
				},
			].map((column) => ({
				...column,
				enableColumnFilter: false,
				enableSorting: false,
				enableColumnMenu: false,
			})),
		[theme],
	);

	const multiChoiceColumns = useMemo(() => {
		const newColumns = [
			...columns.slice(0, 1),
			{
				header: "Color",
				id: "color",
				accessorFn: (row) => row.choice.color,
				size: 84,
				cell: (prop) => (
					<Box
						sx={{
							width: "64px",
							height: "32px",
							borderRadius: (theme) => theme.spacing(1),
							backgroundColor: (theme) =>
								prop.getValue()
									? theme.palette[prop.getValue()].main
									: theme.palette.grey[400],
						}}
					/>
				),
			},
			...columns.slice(1),
		];
		return newColumns;
	}, [columns]);

	const { data: response, isLoading: isLoadingResponse } = useQuery(
		["surveyResponse", responseId],
		async () => {
			const r = await getResponses({ responseIds: [responseId] });
			return r?.[0];
		},
    {
      refetchOnWindowFocus: false,
      refetchInterval: 0,
      refetchOnMount: false,
      onSuccess: () => {
        scrollTargetRef.current?.scrollIntoView();
      },
    },
	);

	const { data: sections = [], isLoading: isLoadingSections } = useQuery(
		["Sections", response?.vertical],
		() =>
			getAllSections({ isAAR: response.vertical === "after_action_review" }),
		{
			enabled: !!response,
		},
	);

	const { data: survey, isLoading: isLoadingSurvey } = useQuery(
		["InvestmentFramework", surveyId],
		() => getSurveyQuestions(surveyId),
		{
			enabled: !!surveyId,
		},
	);

	const { allData } = useMemo(
		() => createTableData(response, sections),
		[response, sections],
	);

	const isLoading = useMemo(
		() => isLoadingResponse || isLoadingSections || isLoadingSurvey,
		[isLoadingResponse, isLoadingSections, isLoadingSurvey],
	);

	if (isLoading) {
		return <CenteredProgress />;
	}

	return (
		<Page>
			<Page.Title>
				<Box display="flex" flexDirection="column" gap={1} ref={scrollTargetRef}>
					<span>
						{response.vertical === "after_action_review"
							? `${InvestmentProductMappings[response.vertical]} \u2014 ${response.surveyName}`
							: `${InvestmentProductMappings[response.department]} \u2014 ${response.surveyName}`}
					</span>
				</Box>
			</Page.Title>
			<Page.Content>
				<Box display="flex" justifyContent="center" mb={4}>
					<Card
						sx={{
							width: "1500px",
							maxWidth: "2000px",
						}}
					>
						<Box padding={2} display="flex" flexDirection="column" gap={2}>
							<Box
								display="flex"
								justifyContent="space-between"
								flexDirection={{
									xs: "column",
									sm: "row",
								}}
							>
								<Box display="flex" flexGrow={1} gap={2}>
									<Box display="flex" gap={1} alignItems="center">
										<CompanyAvatar
											name={response.organization.name}
											domain={response.organization.domain}
											size="64"
											src={response.organization.logoUrl}
										/>
										<Box
											display="flex"
											flexDirection="column"
											justifyContent="center"
										>
											<Typography variant="h3">
												{response.organization.name}
											</Typography>
											<Link
												href={`//www.${response.organization.domain}`}
												target="_blank"
												sx={{
													textDecoration: "none",
													color: theme.palette.text.secondary,
												}}
											>
												<Typography variant="body2">
													{response.organization.domain}
												</Typography>
											</Link>
										</Box>
									</Box>
									<Divider orientation="vertical" flexItem />
									<Box
										display="flex"
										gap={1}
										alignItems="center"
										flexDirection="row"
									>
										<UserAvatar user={response.user} />
										<Stack>
											<Typography variant="boldSubtitle2">
												{response.vertical !== "after_action_review"
													? "Completed by"
													: "Reviewer"}
											</Typography>
											<Typography variant="body1">
												{`${response.user.firstName} ${response.user.lastName}`}
											</Typography>
										</Stack>
									</Box>
									{response.surveySubjectUser && (
										<Box
											display="flex"
											gap={1}
											alignItems="center"
											flexDirection="row"
										>
											<UserAvatar user={response.surveySubjectUser} />
											<Stack>
												<Typography variant="boldSubtitle2">
													Reviewee
												</Typography>
												<Typography variant="body1">
													{`${response.surveySubjectUser.firstName} ${response.surveySubjectUser.lastName}`}
												</Typography>
											</Stack>
										</Box>
									)}
									<Box
										display="flex"
										gap={1}
										alignItems="center"
										flexDirection="row"
									>
										<CalendarMonth
											fontSize="large"
											sx={{ color: theme.palette.primary.main }}
										/>
										<Stack>
											<Typography variant="boldSubtitle2">
												Completed on
											</Typography>
											<Typography variant="body1">
												{dayjs
													.utc(response.completedAt)
													.local()
													.format("MM/DD/YYYY")}
											</Typography>
										</Stack>
									</Box>
								</Box>
							</Box>
							{survey?.canLinkTasks && response.user.id === user.id && (
								<Box display="flex" flexDirection="column" gap={2}>
									<Typography
										variant="h2"
										sx={{
											color: theme.palette.text.secondary,
										}}
									>
										Link to a CRM List
									</Typography>
									<TaskSelector responseId={responseId} valorId={valorId} />
								</Box>
							)}
							<Box display="flex" flexDirection="column" gap={2}>
								<Typography
									variant="h2"
									sx={{
										color: theme.palette.text.secondary,
									}}
								>
									Response Summary
								</Typography>
								{allData?.length > 0 && (
									<Box borderLeft={(t) => `1px solid ${t.palette.divider}`}>
										<DataTable data={allData} columns={multiChoiceColumns} />
									</Box>
								)}
							</Box>
						</Box>
					</Card>
				</Box>
			</Page.Content>
		</Page>
	);
}

export default OrgFrameworkResponse;
