import { createTaskComment, updateTaskComment } from "@/api/Process";
import { Box, Button } from "@mui/material";
import React from "react";
import { Field, Form } from "react-final-form";
import TextFieldAdapter from "../FormAdapters/TextFieldAdapter";
import { required } from "../FormAdapters/validators";

type CommentFormProps = {
	processId?: string;
	taskId?: string;
	comment?: { comment: string };
	onComment: (comment: { id?: string; comment: string }) => void;
	onCancel?: () => void;
};

function CommentForm({
	processId,
	taskId,
	comment,
	onComment,
	onCancel,
}: CommentFormProps) {
	// create comment
	const onSubmit = async ({ comment: newComment }, form) => {
		if (comment) {
			await updateTaskComment(processId, comment.id, { comment: newComment });
		} else {
			if (!taskId || !processId) {
				// eslint-disable-next-line no-console
				console.error("Task ID or Process ID is missing.");
				return;
			}
			await createTaskComment(processId, taskId, { comment: newComment });
		}
		form.reset();

		if (onComment) onComment({ id: comment?.id, comment: newComment });
	};

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={comment}
			render={({ handleSubmit, values, submitting }) => (
				<form onSubmit={handleSubmit}>
					<Field
						name="comment"
						component={TextFieldAdapter}
						placeholder="Leave a comment..."
						fullWidth
						minRows={3}
						multiline
						validate={required}
						disabled={submitting}
					/>
					<Box
						marginTop={1}
						display="flex"
						flexDirection="row"
						justifyContent="flex-end"
						gridGap={8}
					>
						{comment && (
							<Button
								variant="outlined"
								disabled={submitting}
								onClick={() => {
									if (onCancel) onCancel();
								}}
							>
								Cancel
							</Button>
						)}
						<Button
							variant="outlined"
							color="primary"
							type="submit"
							disabled={
								submitting || !values.comment || values.comment.trim() === ""
							}
						>
							{comment ? "Save" : "Submit Comment"}
						</Button>
					</Box>
				</form>
			)}
		/>
	);
}

export default CommentForm;
