import { createProcess } from "@/api/Process";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	FormControl,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";

export default function CreateNewListModal({
	open,
	onClose,
}: {
	open: boolean;
	onClose: (list: any) => void;
}) {
	const [selectedTemplate, setSelectedTemplate] = React.useState(null);
	const [listName, setListName] = React.useState("");
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		if (!open) {
			setSelectedTemplate(null);
			setListName("");
		}
	}, [open]);

	async function handleCreateList() {
		if (listName.trim() === "") {
			return;
		}

		setLoading(true);

		// create new list
		const newList = {
			name: listName,
			description: `${listName} description`,
			type: "CRM_LIST",
			lastViewedAt: null,
			favorited: null,
			settings: [{ key: "tag", value: selectedTemplate }],
		};
		await createProcess(newList);

		// add to lists
		setLoading(false);
		onClose(newList);
	}

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			{!selectedTemplate && (
				<Box display="flex" flexDirection="row">
					<Box
						width="50%"
						padding={2}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						height={300}
						sx={{
							background: (theme) => theme.palette.background.default,
						}}
					>
						<Typography variant="h2">Create New List</Typography>
						<Typography variant="body1">
							Select a template or build a list from scratch to start managing
							what&apos;s important to your team.
						</Typography>
					</Box>
					<Box
						width="50%"
						padding={2}
						display="flex"
						flexDirection="column"
						gap={1}
					>
						<Box
							sx={{
								cursor: "pointer",
								background: (theme) => theme.palette.background.blue,
								color: (theme) => theme.palette.primary.main,
							}}
							padding={1}
							borderRadius={1}
							onClick={() => setSelectedTemplate("Organizations")}
						>
							<Typography variant="h3">Organizations</Typography>
						</Box>
						<Box
							sx={{
								cursor: "pointer",
								background: (theme) => theme.palette.background.blue,
								color: (theme) => theme.palette.secondary.main,
							}}
							padding={1}
							borderRadius={1}
							onClick={() => setSelectedTemplate("Opportunities")}
						>
							<Typography variant="h3">Opportunities</Typography>
						</Box>
					</Box>
				</Box>
			)}
			{selectedTemplate && (
				<Box display="flex" flexDirection="row">
					<Box
						width="50%"
						padding={2}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						height={300}
						sx={{
							background: (theme) => theme.palette.background.default,
						}}
					>
						<Typography variant="h2">
							Build your {selectedTemplate} list
						</Typography>
						<Typography variant="body1">
							Before you start managing your list, give it a name.
						</Typography>
					</Box>
					<Box
						width="50%"
						padding={2}
						display="flex"
						flexDirection="column"
						gap={1}
						alignContent="center"
						justifyContent="center"
					>
						<FormControl>
							<TextField
								variant="outlined"
								size="small"
								value={listName}
								onChange={(e) => setListName(e.target.value)}
								label="List Name"
								disabled={loading}
							/>
						</FormControl>
					</Box>
				</Box>
			)}
			<DialogActions>
				{!selectedTemplate && <Button onClick={() => onClose()}>Cancel</Button>}
				{selectedTemplate && (
					<>
						<Button
							variant="text"
							onClick={() => setSelectedTemplate(null)}
							disabled={loading}
						>
							Back
						</Button>
						<Button
							onClick={() => {
								handleCreateList();
							}}
							disabled={loading}
						>
							Create List
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
}
