import type { Prompt } from "@/api/Chat";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
	Box,
	Button,
	CircularProgress,
	Collapse,
	Grid,
	Typography,
} from "@mui/material";
import React from "react";
import PromptCard from "../Prompts/PromptCard";

interface PromptSectionProps {
	isOpen: boolean;
	selectedPrompt: Prompt | null;
	prompts: Prompt[];
	isLoading: boolean;
	onPromptSelect: (prompt: Prompt) => void;
	onEditClick: () => void;
}

export const PromptSection = ({
	isOpen,
	selectedPrompt,
	prompts,
	isLoading,
	onPromptSelect,
	onEditClick,
}: PromptSectionProps) => {
	return (
		<Box sx={{ mb: 3, position: "relative" }}>
			<Box
				sx={{
					p: 2,
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
					<Typography variant="h4">2. Select Prompt </Typography>
					{selectedPrompt && <CheckCircleIcon color="success" sx={{ ml: 1 }} />}
				</Box>
				{selectedPrompt && !isOpen && (
					<Button startIcon={<EditIcon />} onClick={onEditClick}>
						Edit
					</Button>
				)}
			</Box>

			<Collapse in={isOpen}>
				<Box sx={{ p: 2 }}>
					{isLoading ? (
						<CircularProgress />
					) : (
						<Grid container spacing={2}>
							{prompts?.map((prompt) => (
								<Grid item xs={12} key={prompt.id}>
									<Box
										onClick={() => onPromptSelect(prompt)}
										sx={{ cursor: "pointer", "&:hover": { opacity: 0.8 } }}
									>
										<PromptCard {...prompt} />
									</Box>
								</Grid>
							))}
						</Grid>
					)}
				</Box>
			</Collapse>

			{selectedPrompt && !isOpen && (
				<Box sx={{ px: 2, pb: 2 }}>
					<PromptCard {...selectedPrompt} />
				</Box>
			)}
		</Box>
	);
};

export default PromptSection;
