import { Box, Card, CardContent, Typography } from "@mui/material";
import {
	flexRender,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import React from "react";

export function DataTable({ data, columns }) {
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	});

	return (
		<Box
			sx={{
				overflow: "auto",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					gap: 2,
				}}
			>
				{table.getRowModel().rows.map((row) => (
					<Card key={row.id} sx={{ flexGrow: 1 }}>
						<CardContent>
							{row.getVisibleCells().map((cell) => (
								<Box
									key={cell.id}
									sx={{
										marginBottom: 1,
									}}
								>
									<Typography variant="boldBody1">
										{cell.column.columnDef.header}
									</Typography>
									: {flexRender(cell.column.columnDef.cell, cell.getContext())}
								</Box>
							))}
						</CardContent>
					</Card>
				))}
			</Box>
		</Box>
	);
}
export default DataTable;
