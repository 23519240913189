import { updateTask } from "@/api/Process";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton,
	Tooltip,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useState, useMemo, useRef } from "react";

import Activity from "../Activity";
import NotesDrawer from "../NotesDrawer";
import SurveySelector from "../Surveys";
import EditTaskForm from "../TaskForms/EditTaskForm";

import useProcess from "../ProcessContext/useProcess";
import useTask from "../ProcessContext/useTask";

type EditTaskDialogProps = {
	open: boolean;
	processId: string;
	taskId: string;
	onArchive: (taskId: string) => void;
	onArchived: (taskId: string) => void;
	onClose: () => void;
	onUpdate: () => void;
	fullWidth?: boolean;
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
	scroll?: "paper" | "body";
	onCommentCreate?: (
		processId: string,
		taskId: string,
		comment: { comment: string },
	) => void;
};

export default function EditTaskDialog({
	processId,
	taskId,
	open,
	onArchive,
	onArchived,
	onClose,
	onUpdate,
	fullWidth,
	maxWidth,
	scroll,
	onCommentCreate = () => {},
}: EditTaskDialogProps) {
	// if within context, need to add comment to cache
	const addComment = onCommentCreate;

	const {
		data: {
			fields: fieldData,
			settings: {
				entityName,
				enableSurveyLinking,
				replyEntity,
				modalEditTaskTitle,
			},
		},
	} = useProcess(processId);

	const {
		data: { task: taskData },
	} = useTask(processId, taskId);

	const editTaskTitle = modalEditTaskTitle;

	const [editUpdateState, setEditsUpdateState] = useState<
		"Saving" | "Saved" | "Error"
	>(null);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

	const primaryValorIdField = fieldData?.find(
		(f) => f.isPrimary && f.type === "company",
	)?.id;

	const primaryCompany = useMemo(
		() => taskData?.fieldValues?.[primaryValorIdField]?.company ?? {},
		[taskData, primaryValorIdField],
	);

	const openDeleteConfirmation = () => {
		setDeleteDialogOpen(true);
	};

	const confirmDelete = () => {
		onArchive(taskId);
		updateTask(processId, taskId, { archived: true }).then(() =>
			onArchived(taskId),
		);
		setDeleteDialogOpen(false);
		onClose();
	};

	const formRef = useRef();
	const alertRef = useRef();
	const [errors, setErrors] = useState([]);
	const handleOnClose = () => {
		// validate form
		const result = formRef.current?.validate();
		setErrors(result);
		if (result.length === 0) {
			setEditsUpdateState(null);
			onClose();
		} else {
			setTimeout(() => alertRef.current?.scrollIntoView(), 1);
		}
	};
	const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	if (!taskId || !processId) {
		return null;
	}

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleOnClose}
				fullWidth={fullWidth}
				fullScreen={isSmDown}
				maxWidth={maxWidth}
				scroll={scroll}
			>
				<DialogTitle
					sx={{
						position: "sticky",
						px: {
							xs: 1,
							md: 2,
						},
						py: {
							xs: 1,
							md: 2,
						},
					}}
				>
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
					>
						<span>{editTaskTitle || `Edit ${entityName}`}</span>
						<Box display="flex" flexDirection="row" justifyContent="flex-end">
							<Typography variant="caption">
								{editUpdateState === "Saving" && "Saving changes..."}
								{editUpdateState === "Saved" && (
									<Box display="flex" flexDirection="row" alignItems="center">
										Changes Saved{" "}
										<CheckCircleIcon
											fontSize="small"
											style={{ marginLeft: "4px" }}
										/>
									</Box>
								)}
							</Typography>
						</Box>
						<Box>
							<Tooltip title={`Archive ${entityName}`} arrow>
								<IconButton
									onClick={() => {
										openDeleteConfirmation();
									}}
									size="small"
									data-cy="task-archive-button"
								>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Close" arrow>
								<IconButton
									onClick={() => handleOnClose()}
									size="small"
									data-cy="edit-close-button"
								>
									<CloseIcon />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						px: {
							xs: 0.5,
							md: 2,
						},
						overflowX: {
							xs: "hidden",
						},
					}}
				>
					{editUpdateState === "Error" && (
						<Alert
							severity="error"
							style={{
								marginBottom: 16,
							}}
						>
							Error saving changes. Please refresh and try again.
						</Alert>
					)}

					<Box
						display="flex"
						flexDirection={{
							xs: "column",
							md: "row",
						}}
						gap={1}
					>
						<Box
							display="flex"
							flexDirection="column"
							gap={1}
							flexGrow={1}
							maxWidth={{
								md: "50%",
								sm: "100%",
							}}
						>
							<EditTaskForm
								ref={formRef}
								alert={
									errors.length > 0 && (
										<Alert severity="error" ref={alertRef}>
											Please fill out all required fields. Missing value(s) for:{" "}
											<ul>
												{errors
													.filter(({ error }) => error === "required")
													.map((error) => (
														<li key={error.field.id}>{error.field.name}</li>
													))}
											</ul>
										</Alert>
									)
								}
								onBeforeUpdate={() => {
									setEditsUpdateState("Saving");
								}}
								onUpdateError={() => {
									setEditsUpdateState("Error");
								}}
								onUpdate={() => {
									setEditsUpdateState("Saved");
									onUpdate?.();
								}}
								processId={processId}
								taskId={taskId}
							/>
						</Box>

						<Divider flexItem vertical />

						<Box
							display="flex"
							flexDirection="column"
							gap={1}
							flexGrow={1}
							maxWidth={{
								md: "50%",
								sm: "100%",
							}}
							maxHeight={{
								md: "calc(100vh - 160px)",
							}}
							sx={{
								overflowY: {
									xs: "visible",
									md: "auto",
								},
							}}
						>
							{replyEntity === "notes" && (
								<>
									<NotesDrawer open processId={processId} taskId={taskId} />
									<Divider flexItem />
								</>
							)}
							{enableSurveyLinking === "true" && (
								<>
									<SurveySelector
										open
										processId={processId}
										taskId={taskId}
										primaryCompany={primaryCompany}
									/>
									<Divider flexItem />
								</>
							)}
							<Activity
								processId={processId}
								taskId={taskId}
								replyEntity={replyEntity}
								onComment={({ comment }) => {
									addComment(processId, taskId, { comment });
								}}
							/>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>

			<Dialog
				open={deleteDialogOpen}
				onClose={() => setDeleteDialogOpen(false)}
			>
				<DialogTitle>{`Archive ${entityName}?`}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						This will archive the {entityName.toLowerCase()}. You can restore it
						later.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						variant="outlined"
						onClick={() => confirmDelete()}
						startIcon={<DeleteIcon />}
						data-cy="task-archive-confirm"
					>
						Yes
					</Button>
					<Button
						color="primary"
						variant="contained"
						onClick={() => setDeleteDialogOpen(false)}
						data-cy="task-archive-cancel"
					>
						No
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
