import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useField } from "react-final-form";

const StyledTextField = styled(TextField)({
	width: "100%",
});

interface Props {
	questionId: string;
	skippable: boolean;
	initialValue: string;
}

export default function FreeText({
	questionId,
	skippable,
	initialValue = "",
}: Props) {
	const required = (value: string) =>
		value || skippable ? undefined : "Required";
	const { input: commentaryInput, meta: commentaryMeta } = useField(
		`${questionId}.commentary`,
		{
			initialValue,
			validate: required,
		},
	);

	return (
		<StyledTextField
			data-cy={`${questionId}-commentary`}
			maxRows={8}
			multiline
			data-formstate={commentaryMeta.error ? "error" : ""}
			error={!!(commentaryMeta.submitFailed && commentaryMeta.error)}
			label={commentaryMeta.submitFailed ? commentaryMeta.error : ""}
			{...commentaryInput}
			variant="outlined"
		/>
	);
}
