import {
	formatBigDollars,
	formatBigFundingRoundDollars,
} from "@/utils/numberFormat";
import { Box, Tooltip } from "@mui/material";
import React from "react";

export default function MoneyCell({
	value,
	isFundingRound = true,
}: {
	value: number | string | null;
	isFundingRound?: boolean;
}) {
	let num = null;
	if (
		value === null ||
		value === undefined ||
		(typeof value === "string" && value.trim() === "")
	) {
		return <>-</>;
	}
	if (!isFundingRound) {
		num = formatBigDollars(value);
	} else {
		num = formatBigFundingRoundDollars(value);
	}
	const tooltipVal = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		maximumFractionDigits: 2,
	}).format(Number(value));

	return (
		<Tooltip title={tooltipVal}>
			<Box
				sx={{
					// display: "-webkit-box",
					textOverflow: "ellipsis",
					overflow: "hidden",
					whiteSpace: "normal",
					display: "flex",
					height: "100%",
					alignItems: "center",
				}}
			>
				{num}
			</Box>
		</Tooltip>
	);
}
