import { useMediaQuery } from "@mui/material";
import type React from "react";
import LocalCards from "../LocalCards";
import LocalTable from "../LocalTable";

interface LocalCardTableProps {
	data: any[];
	columns: any[];
}

const LocalCardTable: React.FC<LocalCardTableProps> = ({ data, columns }) => {
	const isMdOrAbove = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

	return (
		<div>
			{isMdOrAbove ? (
				<LocalTable data={data} columns={columns} />
			) : (
				<LocalCards data={data} columns={columns} />
			)}
		</div>
	);
};

export default LocalCardTable;
