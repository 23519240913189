import { type GetSAYTforOrgResponse, saytForOrg } from "@/api/Search";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import { Search } from "@mui/icons-material";
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	InputAdornment,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import type React from "react";
import { useState } from "react";
import type {
	CompanyListProps,
	ContextTypeSelectorProps,
	MentionDialogProps,
} from "./utils/ChatState";

function ContextTypeSelector({
	company,
	selectedTypes,
	existingTypes,
	onTypeSelect,
	onConfirm,
}: ContextTypeSelectorProps) {
	const [focusedIndex, setFocusedIndex] = useState(-1);
	const handleKeyDown = (
		event: React.KeyboardEvent,
		type: string,
		index: number,
	) => {
		const focusedType = company.contextTypes?.[focusedIndex];
		switch (event.key) {
			case "Enter":
			case " ": // Space key
				event.preventDefault();
				if (!existingTypes.includes(focusedType)) {
					onTypeSelect(focusedType);
				}
				break;
			case "Tab":
				// Update focused index when tabbing
				setFocusedIndex(index);
				break;
			case "ArrowDown":
				event.preventDefault();
				setFocusedIndex((prev) =>
					Math.min(prev + 1, (company.contextTypes?.length || 1) - 1),
				);
				break;
			case "ArrowUp":
				event.preventDefault();
				setFocusedIndex((prev) => Math.max(prev - 1, 0));
				break;
			default:
				break;
		}
	};
	return (
		<Box sx={{ p: 2 }}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					mb: 2,
					gap: 1,
				}}
			>
				<CompanyAvatar src={company.logoUrl} name={company.name} size={32} />
				<Stack>
					<Typography variant="subtitle1">{company.name}</Typography>
					<Typography variant="subtitle2">{company.domain}</Typography>
				</Stack>
			</Box>

			<Typography variant="subtitle2" sx={{ mb: 1 }}>
				Select Context Types:
			</Typography>

			<List sx={{ width: "100%", p: 0 }}>
				{company.contextTypes?.map((type: string, index: number) => {
					const isExisting = existingTypes.includes(type);
					const isFocused = focusedIndex === index;

					return (
						<ListItem
							key={type}
							dense
							component="div"
							tabIndex={0}
							onKeyDown={(e) => handleKeyDown(e, type, index)}
							onClick={() => {
								if (!isExisting) onTypeSelect(type);
								setFocusedIndex(index);
							}}
							onFocus={() => setFocusedIndex(index)}
							sx={(theme) => ({
								cursor: isExisting ? "default" : "pointer",
								bgcolor: selectedTypes.includes(type)
									? "action.selected"
									: "transparent",
								outline: isFocused
									? `2px solid ${theme.palette.primary.main}`
									: "none",
							})}
						>
							<ListItemIcon>
								<Checkbox
									edge="start"
									checked={selectedTypes.includes(type)}
									disabled={isExisting}
									tabIndex={-1} // Remove checkbox from tab sequence
									disableRipple
								/>
							</ListItemIcon>
							<ListItemText
								primary={type}
								secondary={isExisting ? "Already added" : null}
							/>
						</ListItem>
					);
				})}
			</List>

			<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
				<Button
					variant="contained"
					disabled={selectedTypes.length === 0}
					onClick={onConfirm}
				>
					Add Contexts
				</Button>
			</Box>
		</Box>
	);
}

function CompanyList({ onSelect }: CompanyListProps) {
	const [searchQuery, setSearchQuery] = useState("");
	const [results, setResults] = useState<GetSAYTforOrgResponse>([]);
	const [isSearching, setIsSearching] = useState(false);
	const [searchError, setSearchError] = useState<string | null>(null);

	const handleSearch = async (query: string) => {
		setSearchQuery(query);

		if (query.length < 2) {
			setResults([]);
			setSearchError(null);
			return;
		}

		setIsSearching(true);
		setSearchError(null);

		try {
			const searchResults = await saytForOrg(query);
			setResults(searchResults.slice(0, 5));
		} catch (error) {
			setSearchError("Failed to search companies. Please try again.");
			setResults([]);
		} finally {
			setIsSearching(false);
		}
	};

	return (
		<Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<Box sx={{ p: 2 }}>
				<TextField
					fullWidth
					autoFocus
					size="small"
					value={searchQuery}
					onChange={(e) => handleSearch(e.target.value)}
					placeholder="Search companies..."
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search />
							</InputAdornment>
						),
					}}
				/>
			</Box>

			<Box sx={{ flex: 1, overflow: "auto" }}>
				{searchError ? (
					<Box sx={{ p: 2, textAlign: "center", color: "error.main" }}>
						{searchError}
					</Box>
				) : isSearching ? (
					<Box sx={{ p: 2, textAlign: "center" }}>
						<CircularProgress size={20} />
					</Box>
				) : results.length === 0 ? (
					<Box sx={{ p: 2, textAlign: "center", color: "text.secondary" }}>
						{searchQuery.length === 0
							? "Start typing to search for companies..."
							: "No companies found"}
					</Box>
				) : (
					<Box sx={{ flex: 1, overflow: "auto" }}>
						{results.map((company) => (
							<ListItem
								key={company.valorId}
								button
								onClick={() => onSelect(company)}
							>
								<CompanyAvatar
									src={company.logoUrl}
									name={company.name}
									size={32}
									sx={{ mr: 1 }}
								/>
								<ListItemText
									primary={
										<Box
											sx={{
												ml: 1,
											}}
										>
											{company.name}
										</Box>
									}
									secondary={
										<Box
											sx={{
												fontSize: "0.75rem",
												color: "text.secondary",
												display: "flex",
												alignItems: "center",
												gap: 1,
												ml: 1,
											}}
										>
											{company.domain}
										</Box>
									}
								/>
							</ListItem>
						))}
					</Box>
				)}
			</Box>
		</Box>
	);
}

export function MentionDialog({
	open,
	onClose,
	selectedCompany,
	selectedContextTypes,
	selectedContexts,
	onSelectMention,
	onContextTypeSelect,
	onConfirm,
}: MentionDialogProps) {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			sx={{
				"& .MuiDialog-paper": {
					width: "400px",
					height: "400px",
					minHeight: "400px",
					maxHeight: "400px",
					minWidth: "400px",
					maxWidth: "400px",
					overflow: "hidden",
				},
			}}
		>
			{!selectedCompany ? (
				<CompanyList onSelect={onSelectMention} />
			) : (
				<ContextTypeSelector
					company={selectedCompany}
					selectedTypes={selectedContextTypes}
					existingTypes={selectedContexts
						.filter((ctx) => ctx.valorId === selectedCompany.valorId)
						.map((ctx) => ctx.contextType)} // Add this
					onTypeSelect={onContextTypeSelect}
					onConfirm={onConfirm}
				/>
			)}
		</Dialog>
	);
}

export default MentionDialog;
