import { canWriteMarketMaps } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import React from "react";
import Helmet from "react-helmet";
import { Route, Routes } from "react-router-dom";
import MarketMapCreateEdit from "./MarketMapCreateEdit";
import MarketMapView from "./MarketMapView";
import MarketMaps from "./MarketMaps";

function MarketMapRoot() {
	const { user } = useAuth();
	const canEditMaps = canWriteMarketMaps(user);
	return (
		<>
			<Helmet>
				<title>MarketMap - vOS</title>
			</Helmet>
			<Routes>
				<Route path="" element={<MarketMaps />} />
				{canEditMaps ? (
					<Route path="create" element={<MarketMapCreateEdit />} />
				) : null}
				{canEditMaps ? (
					<Route path="edit/:id" element={<MarketMapCreateEdit />} />
				) : null}

				<Route path=":slug?/view/:id" element={<MarketMapView />} />
			</Routes>
		</>
	);
}

export default MarketMapRoot;
