import type React from "react";
import { createContext, useMemo, useState } from "react";

export const EditorContext = createContext({
	selectedColor: null,
	setSelectedColor: (color: string) => color,
	selectedHighlight: null,
	setSelectedHighlight: (highlight: string) => highlight,
});

export default function EditorContextProvider({
	children,
}: { children: React.ReactNode }) {
	const [selectedColor, setSelectedColor] = useState(null);
	const [selectedHighlight, setSelectedHighlight] = useState(null);

	const providerValue = useMemo(
		() => ({
			selectedColor,
			setSelectedColor,
			selectedHighlight,
			setSelectedHighlight,
		}),
		[selectedColor, setSelectedColor, selectedHighlight, setSelectedHighlight],
	);

	return (
		<EditorContext.Provider value={providerValue}>
			{children}
		</EditorContext.Provider>
	);
}
