import React, { useEffect } from "react";

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@mui/material";

export default function RenameDialog({
	open,
	onClose,
	onSubmit,
	initialValue,
}: {
	open: boolean;
	onClose: () => void;
	onSubmit: (value: string) => void;
	initialValue: string;
}) {
	const [maskValue, setMaskValue] = React.useState(initialValue);
	const value = React.useMemo(() => maskValue, [maskValue]);

	useEffect(() => {
		setMaskValue(initialValue);
	}, [initialValue]);

	const [disabled, setDisabled] = React.useState(false);

	const handleSubmit = async () => {
		if (value.length === 0) return;
		setDisabled(true);
		await onSubmit(value);
		setDisabled(false);
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Rename chat</DialogTitle>
			<DialogContent>
				<Box py={1}>
					<TextField
						label="Chat name"
						value={value}
						onChange={(e) => setMaskValue(e.target.value)}
						fullWidth
						disabled={disabled}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								handleSubmit();
							}
						}}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						setMaskValue(null);
						onClose();
					}}
					disabled={disabled}
				>
					Cancel
				</Button>
				<Button onClick={handleSubmit} variant="contained" disabled={disabled}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
