import Tooltip from "@material-ui/core/Tooltip";
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";

export default function OverflowTip({ children, placement = "top" }) {
	const [isOverflowed, setIsOverflow] = useState(false);
	const textElementRef = useRef();

	useEffect(() => {
		setIsOverflow(
			textElementRef.current.scrollWidth > textElementRef.current.clientWidth,
		);
	}, []);

	return (
		<Tooltip
			title={children}
			placement={placement}
			disableHoverListener={!isOverflowed}
			disableFocusListener={!isOverflowed}
			disableTouchListener={!isOverflowed}
		>
			<div
				ref={textElementRef}
				style={{
					cursor: isOverflowed ? "pointer" : "default",
					whiteSpace: "nowrap",
					overflow: "hidden",
					textOverflow: "ellipsis",
				}}
			>
				{children}
			</div>
		</Tooltip>
	);
}
