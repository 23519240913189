import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import {
	Box,
	Button,
	Chip,
	FormControl,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemIcon,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";

import { useQuery } from "react-query";

import { getProcess, getProcessTasks } from "@/api/Process";
import { Delete, OpenInNew } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

type PushToProcessProps = {
	valorId: string;
	disabled: boolean;
	note: any;
	onPush: (oppId: string) => void;
	onDelete: (oppId: string) => void;
};

export default function PushToProcess({
	valorId,
	disabled,
	note,
	onPush,
	onDelete,
}: PushToProcessProps) {
	const theme = useTheme();
	const [internalValue, setInternalValue] = useState<string>("none");
	const navigate = useNavigate();

	//   __g@@@@@@p_
	//   _@@@@@P_qg}Q@45bggy_,
	// _@@@@@@9@g"B[@@@0o@@@@@
	// /@@@@@@_q2@@@@PX@@@@@@@/
	// @@@@@@NW@@@BCg@@@WQ@@@"
	// @@@@B@@F   @@@@@@@@ ,;
	// /@@@@%Wa~g@@g@@@@@@@@g4F\
	// .@@@@Y@@@@@,!@g"@@@@@@WQ4El
	// @@BB@@a0@W"+pGg@@@@@@@@@g@
	// "EBg"`@@@@@   <B@@@@@@@F
	// "@8@@@@@@@      `0@@B"
	//  '""j@@@@@___ __g@P$gg@@|
	//     @7@@@@@@@@@@@@@@@@@@
	//    "=> @@@@@@@@@@@@@@@@@
	//         @@@@@@@@@@@@@@@
	//         |@@@@@@@E@@R&g
	//          @@@@@@@@@@@P
	//        _,@@@@@@@@@P
	//     __Q@@LER@P=@BgaQ@a,
	//   _@@@,@@@r@@@@@@@@h@@,@g
	// ,@@@@@'0[l@=]$?5=__ MBg\@@\
	// .@@@@@@U[K@[|@@@@@@@'!N}@\@@,
	// BBBBBBXBL0wDiBBBBBBBm`:dB1BBB
	// UPDATE THIS EVENTUALLY TO PULL
	// ALL PROCESSES, NOT JUST DEALS

	// get all possible opportunities that use notes (deals)
	const { data: processData, isLoading: dealLoading } = useQuery(
		["process", "deals"],
		() => getProcess("deals"),
	);

	const { data: oppData, isLoading: oppLoading } = useQuery(
		["opportunities", valorId],
		() =>
			getProcessTasks(["deals"], 50, null, ["dateAdded"], {
				AND: {
					eq: {
						"tasks.fieldValues.e46f4ec3-b130-4b19-8a9a-94ff0bb42732.valorId": [
							valorId,
						],
					},
				},
			}),
	);

	const existingOpps = React.useMemo(() => {
		const opps = oppData?.data || [];
		const internalIds = note?.processRelationsInternalIds || [];
		return opps.filter((opp) => internalIds.includes(opp.internalId));
	}, [oppData, note?.processRelationsInternalIds]);

	const getOppName = (opp) => {
		const name =
			opp.fieldValues?.["44fcec37-381d-bfae-6c4f-e4f530fb376e"]?.value;
		const org =
			opp.fieldValues?.["e46f4ec3-b130-4b19-8a9a-94ff0bb42732"]?.company?.name;

		const roundSeriesField = processData?.fields.find(
			(f) => f.id === "f5ca1c59-e6d5-47d0-a79f-55c8cd77efe7",
		);
		const roundSeriesId =
			opp.fieldValues?.["f5ca1c59-e6d5-47d0-a79f-55c8cd77efe7"]?.choiceId;
		const roundSeries = roundSeriesField?.choices.find(
			(c) => c.id === roundSeriesId,
		)?.value;

		if (roundSeries) {
			return (
				<>
					{name || org}
					<Chip
						label={roundSeries}
						size="small"
						sx={{
							ml: 1,
						}}
					/>
				</>
			);
		}
		return name || org;
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				if (internalValue !== "none") {
					onPush(internalValue.toString());
				}
			}}
		>
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="flex-start"
				alignItems="center"
				gap={1}
				flexWrap="wrap"
			>
				<FormControl fullWidth>
					<InputLabel id="push-to-process-label">Opportunity</InputLabel>
					<Select
						labelId="push-to-process-label"
						label="Opportunity"
						value={internalValue}
						onChange={(e) => setInternalValue(e.target.value)}
						sx={{
							"& > div": {
								display: "flex",
							},
						}}
						disabled={oppLoading || dealLoading}
					>
						<MenuItem value="none">None</MenuItem>
						{oppData?.data.map((opp) => (
							<MenuItem key={opp.internalId} value={opp.internalId}>
								<ListItemIcon>
									<LocalAtmIcon
										style={{
											color: theme.palette.orange.main,
										}}
									/>
								</ListItemIcon>
								<Typography variant="body1">{getOppName(opp)}</Typography>
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<Button
					variant="outlined"
					color="primary"
					type="submit"
					disabled={
						disabled ||
						internalValue === "none" ||
						existingOpps.some(({ internalId }) => internalId === internalValue)
					}
					sx={{
						whiteSpace: "nowrap",
					}}
					data-cy="edit-notes__push-to-affinity"
				>
					Push to Opportunity
				</Button>

				<Box
					width="100%"
					visibility={existingOpps.length ? "visible" : "hidden"}
				>
					<Typography variant="boldBody1">Existing Opportunities</Typography>
					<List>
						{existingOpps.map((opp) => (
							<ListItem key={opp.id}>
								<Box
									display="flex"
									flexDirection="row"
									justifyContent="space-between"
									alignItems="center"
									width="100%"
								>
									<Box display="flex" flexDirection="row" alignItems="center">
										<ListItemIcon>
											<LocalAtmIcon
												style={{
													color: theme.palette.orange.main,
												}}
											/>
										</ListItemIcon>
										<Typography variant="body1">{getOppName(opp)}</Typography>
									</Box>
									<Box>
										<IconButton
											onClick={() => {
												onDelete(opp.internalId);
											}}
										>
											<Delete />
										</IconButton>
										<IconButton
											onClick={() => {
												navigate(
													`/process-management/deals/tasks/deals-${opp.id}`,
												);
											}}
										>
											<OpenInNew />
										</IconButton>
									</Box>
								</Box>
							</ListItem>
						))}
					</List>
				</Box>
			</Box>
		</form>
	);
}
