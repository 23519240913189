import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useField } from "react-final-form";

const PREFIX = "DollarEstimateBizPersonalPrediction";
const classes = {
	subSection: `${PREFIX}-subSection`,
	text: `${PREFIX}-text`,
};

const Root = styled("div")(({ theme }) => ({
	[`& .${classes.subSection}`]: {
		paddingBottom: theme.spacing(0.5),
		color: theme.palette.text.secondary,
		textAlign: "left",
	},

	[`& .${classes.text}`]: {
		width: "100%",
		"& > div > textarea": {
			height: theme.spacing(4),
			fontSize: theme.spacing(3),
		},
	},
}));

interface Estimate {
	personal: string;
	company: string;
	period: string;
}

interface Props {
	questionId: string;
	initialValue: Estimate;
	initialComment: string;
}
const initialState = { personal: "", company: "", period: "" };
export default function DollarEstimateBizPersonalPrediction(props: Props) {
	const {
		questionId,
		initialValue = initialState,
		initialComment = "",
	} = props;
	const [notPersonalApplicable, setPersonalNotApplicable] = useState(false);
	const [notCompanyApplicable, setCompanyNotApplicable] = useState(false);
	const [estimates, setEstimates] = useState(initialValue);

	useEffect(() => {
		if (estimates.personal === "N/A") {
			setPersonalNotApplicable(true);
		}
		if (estimates.company === "N/A") {
			setCompanyNotApplicable(true);
		}
	}, [estimates.personal, estimates.company]);

	const validate = (value: string) => {
		const { personal, company, period } = JSON.parse(value);
		if (
			(personal && personal !== "N/A" && !period) ||
			(company && company !== "N/A" && !company)
		) {
			return "Required Period Type";
		}
		if (!personal || !company) {
			return "Required";
		}
		if (
			(personal !== "N/A" && Number.isNaN(Number(personal))) ||
			(company !== "N/A" && Number.isNaN(Number(company)))
		) {
			return "Invalid Input Numbers / Commas Only unless N/A";
		}
		return undefined;
	};

	const formatVal = (val: string) => {
		if (!val) return "";
		if (val !== "N/A" && !Number.isNaN(Number(val))) {
			return `$${Number(val).toLocaleString("en-US")}`;
		}
		return val;
	};

	const cleanValue = (val) => {
		const parsedValue = val.replaceAll(",", "").replaceAll("$", "");
		if (!parsedValue) {
			return "";
		}
		if (parsedValue === "N/A" || Number.isNaN(Number(parsedValue))) {
			return parsedValue;
		}

		return String(Number(parsedValue));
	};

	const parse = (value: string) => {
		const obj = JSON.parse(value);

		const cleanedPersonal = cleanValue(obj.personal);
		const cleanedCompany = cleanValue(obj.company);
		let cleanedPeriod = obj.period;

		if (cleanedPersonal === "N/A" && cleanedCompany === "N/A") {
			cleanedPeriod = "";
		}

		return JSON.stringify({
			personal: cleanedPersonal,
			company: cleanedCompany,
			period: cleanedPeriod,
		});
	};

	const { input: estimateInput, meta: estimateMeta } = useField(
		`${questionId}.answerData`,
		{
			initialValue: JSON.stringify(initialValue),
			validate,
			parse,
		},
	);

	const { input: commentaryInput } = useField(`${questionId}.commentary`, {
		initialValue: initialComment,
	});

	const handleChange = (key: string, val: string) => {
		const cleanedVal = cleanValue(val);
		const copy = { ...estimates, [key]: cleanedVal };
		const formattedCopy = { ...estimates, [key]: formatVal(cleanedVal) };
		setEstimates(formattedCopy);
		estimateInput.onChange(JSON.stringify(copy));
	};

	const onCheckChange = (event, setCheckChange, onChange) => {
		const { checked } = event.target;
		setCheckChange(checked);
		if (checked) {
			onChange("N/A");
		} else {
			onChange("");
		}
	};

	return (
		<Root>
			<Grid container item xs={12}>
				<Grid item xs={12}>
					<Typography
						variant="body1"
						style={{
							color:
								estimateMeta.submitFailed && estimateMeta.error
									? "red"
									: "inherit",
						}}
					>
						What Period Type is this Metric For?
					</Typography>
					<RadioGroup
						data-cy={`period-${questionId}`}
						defaultValue={null}
						row
						onChange={(event) => handleChange("period", event.target.value)}
						value={estimates.period}
					>
						<FormControlLabel
							disabled={
								estimates.company === "N/A" && estimates.personal === "N/A"
							}
							value="MONTHLY"
							control={<Radio />}
							label="Monthly"
						/>
						<FormControlLabel
							disabled={
								estimates.company === "N/A" && estimates.personal === "N/A"
							}
							value="QUARTERLY"
							control={<Radio />}
							label="Quarterly"
						/>
						<FormControlLabel
							disabled={
								estimates.company === "N/A" && estimates.personal === "N/A"
							}
							value="YEARLY"
							control={<Radio />}
							label="Yearly"
						/>
					</RadioGroup>
				</Grid>

				<Grid container item xs={12} spacing={1}>
					<Grid item xs={12}>
						<Typography className={classes.subSection} variant="body2">
							Numbers and Commas Only, Complete Numbers not in Thousands or
							Millions
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography style={{ fontWeight: 900 }} variant="subtitle1">
							Company Forecast
						</Typography>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										data-cy={`${questionId}-company-not-applicable`}
										checked={notCompanyApplicable}
										onClick={(event) =>
											onCheckChange(event, setCompanyNotApplicable, (val) =>
												handleChange("company", val),
											)
										}
									/>
								}
								label="Company Forecast Not Applicable/Available"
							/>
						</FormGroup>
						<TextField
							data-cy={`${questionId}-company`}
							value={formatVal(estimates.company)}
							onChange={(event) => handleChange("company", event.target.value)}
							className={classes.text}
							disabled={notCompanyApplicable}
							//
							multiline
							variant="outlined"
							error={!!(estimateMeta.submitFailed && estimateMeta.error)}
							label={estimateMeta.submitFailed ? estimateMeta.error : ""}
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography style={{ fontWeight: 900 }} variant="subtitle1">
							Personal Forecast
						</Typography>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										data-cy={`${questionId}-personal-not-applicable`}
										checked={notPersonalApplicable}
										onClick={(event) =>
											onCheckChange(event, setPersonalNotApplicable, (val) =>
												handleChange("personal", val),
											)
										}
									/>
								}
								label="Personal Forecast Not Applicable/Available"
							/>
						</FormGroup>
						<TextField
							data-cy={`${questionId}-personal`}
							value={formatVal(estimates.personal)}
							onChange={(event) => handleChange("personal", event.target.value)}
							className={classes.text}
							disabled={notPersonalApplicable}
							multiline
							variant="outlined"
							error={!!(estimateMeta.submitFailed && estimateMeta.error)}
							label={estimateMeta.submitFailed ? estimateMeta.error : ""}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography
							className={classes.subSection}
							style={{ height: "42px" }}
							variant="body2"
						>
							COMMENTS
						</Typography>
						<TextField
							{...commentaryInput}
							className={classes.text}
							multiline
							variant="outlined"
							onChange={(event) => commentaryInput.onChange(event.target.value)}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Root>
	);
}
