import {
	type Field,
	type Task,
	type TaskList,
	downloadProcessTasks,
} from "@/api/Process";
import { mapSorting } from "@/components/InfiniteTable";
import {
	canEditProcess,
	canEditProcessFields,
	canEditProcessViews,
} from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import { Box } from "@mui/material";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import ArchivedView from "./ArchivedView";
import BoardView from "./BoardView";
import Header from "./Header";

import FormWizard from "../Forms/Wizard";
import { useProcessActions, useProcessData } from "../ProcessContext";
import TableView from "../TableView";
import NewTaskFormDialog from "../TaskForms/NewTaskFormDialog";
import EditTaskDialog from "./EditTaskDialog";

type ProcessViewType = "board" | "archived" | "table";

export function ProcessView({
	query,
	onQueryChange,
	view,
	groupBy,
}: {
	query: string;
	onQueryChange: (q: string) => void;
	view: ProcessViewType;
	groupBy: string;
}) {
	const {
		processId,
		name: processName,
		entityName,
		boardViewDisabled,
		addButtonLabel,
		tasks,
		forms,
		isLoadingTasks,
		fields,
		fieldFilters,
		currentTaskId: taskId,
		isFetching,
		// settings,
		sorting,
		columnFilters,
		apiColumnFilters,
		columnVisibility,
		columnOrder,
		views,
	} = useProcessData();

	const {
		createTask,
		updateTask,
		fetchNextPage,
		updateProcessName,
		setSorting,
		setColumnFilters,
		setColumnVisibility,
		setColumnOrder,
		getFilterCounts,
		refetch,
		addComment,
	} = useProcessActions();

	// get last part of ticket id as taskId
	const navigate = useNavigate();
	const location = useLocation();

	const [showNewTask, setShowNewTask] = useState(false);
	const [showEditTask, setShowEditTask] = useState(false);
	const [newTaskChanged, setNewTaskChanged] = useState(false);

	const [activeForm, setActiveForm] = useState(null);
	const [showFormWizard, setShowFormWizard] = useState(false);

	const [disableAddTask, setDisableAddTask] = useState(false);

	const { user: currentUser } = useAuth();
	const canEdit = canEditProcess(currentUser);
	const canEditFields = canEditProcessFields(currentUser);
	const canEditViews = canEditProcessViews(currentUser);

	const [activeViewId] = useQueryParam("activeView", StringParam);
	const activeViewObj = React.useMemo(
		() => views?.find((v) => v.id === activeViewId),
		[views, activeViewId],
	);

	const openFormWizard = useCallback((formId) => {
		setActiveForm(formId);
		setShowFormWizard(true);
	}, []);

	const handleSortingChange = useCallback(
		(newSort) => {
			setSorting(newSort);
		},
		[setSorting],
	);

	const handleColumnFilterChange = useCallback(
		(newFilters) => {
			setColumnFilters(newFilters);
		},
		[setColumnFilters],
	);

	const handleColumnVisibilityChange = useCallback(
		(newVisibility) => {
			setColumnVisibility(newVisibility);
		},
		[setColumnVisibility],
	);

	const handleColumnOrderChange = useCallback(
		(newOrder) => {
			setColumnOrder(newOrder);
		},
		[setColumnOrder],
	);

	useEffect(() => {
		setShowNewTask(
			!!location.pathname.endsWith(`new-task/${processId}-${taskId}`),
		);
		setShowEditTask(
			!!(taskId && location.pathname.endsWith(`tasks/${processId}-${taskId}`)),
		);
	}, [location, processId, taskId]);

	const queryClient = useQueryClient();

	const buildQueryString = React.useCallback(
		(queryParams) => {
			const existing = location.search
				? location.search
						.split("?")[1]
						.split("&")
						.map((param) => param.split("="))
						.reduce((acc, [key, value]) => {
							if (acc[key]) {
								if (Array.isArray(acc[key])) {
									return { ...acc, [key]: [...acc[key], value] };
								}
								return { ...acc, [key]: [acc[key], value] };
							}
							return { ...acc, [key]: value };
						}, {})
				: {};
			const combined = {
				...(existing || {}),
				...queryParams,
			};
			const queryString = Object.keys(combined)
				.map((key) => {
					const values = combined[key];
					if (Array.isArray(values)) {
						return values.map((value) => `${key}=${value}`).join("&");
					}
					return `${key}=${values}`;
				})
				.join("&");
			return queryString ? `?${queryString}` : "";
		},
		[location.search],
	);

	function closeTaskModal() {
		navigate({
			pathname: `/process-management/${processId}`,
			search: buildQueryString({}),
		});
	}

	function goToView(newView: ProcessViewType) {
		navigate({
			search: buildQueryString({ view: newView }),
		});
	}

	const goToGrouping = React.useCallback(
		(grouping) => {
			navigate(
				{
					search: buildQueryString({ groupBy: grouping || "default" }),
				},
				{
					replace: true,
				},
			);
		},
		[navigate, buildQueryString],
	);

	useEffect(() => {
		if (fields.length) {
			const groupByField = fields.find((field) => field.id === groupBy);
			if (!groupByField) {
				// find default groupBy
				const defaultGroupBy = fields.find((field) => field.defaultGroupBy);
				if (!defaultGroupBy) {
					// find first groupable field
					const groupableField = fields.find((field) => field.groupable);
					if (groupableField) {
						goToGrouping(groupableField?.id);
					}
				} else {
					goToGrouping(defaultGroupBy?.id);
				}
			}
		}
	}, [fields, location, groupBy, goToGrouping]);

	const groupableFields = useMemo(() => {
		if (fields) {
			return fields.filter((field) => field.groupable);
		}
		return [];
	}, [fields]);

	const groupedTasks = useMemo(() => {
		// todo add sorting. + headers  should be a list of dicts
		if (!groupBy) return [];
		const field: Field = fields.find((f) => f.id === groupBy);
		if (!field) return [];
		const groupType = field.type;
		const groupName = field?.name;
		const groupChoices = field?.choices;

		let categories = {};
		if (groupChoices) {
			categories = groupChoices?.reduce(
				(acc, choice) => ({
					...acc,
					[choice.id]: [],
				}),
				{},
			);
		}

		if (groupType === "ryg") {
			categories = {
				R: [],
				Y: [],
				G: [],
			};
		}

		if (groupType === "checkbox") {
			categories = {
				Yes: [],
				No: [],
			};
		}

		if (tasks) {
			const choiceSortOrder = groupChoices?.reduce(
				(acc, choice, index) => ({
					...acc,
					[choice.id]: index,
				}),
				{},
			);
			const groupedData: { [group: string]: Task } = tasks.reduce(
				(acc, task) => {
					let group = "default";
					if (!task.fieldValues || !task.fieldValues[groupBy]) {
						if (acc[group]) {
							acc[group].push(task);
						} else {
							acc[group] = [task];
						}
						return acc;
					}
					const groupedFieldValue = task.fieldValues[groupBy];
					if (groupType === "user") {
						group = groupedFieldValue?.user?.id;
					}
					if (groupType === "ryg" || groupType === "checkbox") {
						group = groupedFieldValue?.value;
					}
					if (groupType === "select") {
						group = groupedFieldValue?.choiceId;
					}

					if (group) {
						if (acc[group]) {
							acc[group].push(task);
						} else {
							acc[group] = [task];
						}
					}
					return acc;
				},
				{
					...categories,
				},
			);

			const response: { group: string; taskList: Task[] }[] = [];

			if (groupType === "user") {
				Object.keys(groupedData).forEach((group) => {
					if (group === "default") {
						response.push({
							group: "Unassigned",
							taskList: groupedData[group],
						});
					} else {
						const { user } = groupedData[group][0].fieldValues[groupBy];
						response.push({
							group: `${user?.firstName || ""} ${user?.lastName || ""}`,
							taskList: groupedData[group],
						});
					}
				});
				response.sort((a, b) => {
					if (a.group === "Unassigned") return -1;
					if (b.group === "Unassigned") return 1;
					return a.group.localeCompare(b.group);
				});
			} else if (groupType === "select") {
				Object.keys(groupedData).forEach((group) => {
					if (group === "default") {
						response.push({
							choiceId: null,
							group: `No ${groupName}`,
							taskList: groupedData[group],
						});
					} else {
						const choice = groupChoices?.find((c) => c.id === group);
						if (choice) {
							response.push({
								choiceId: choice.id,
								group: choice.value,
								taskList: groupedData[group],
							});
						}
					}
				});
				response.sort((a, b) => {
					if (a.choiceId === null) return -1;
					if (b.choiceId === null) return 1;
					return choiceSortOrder[a.choiceId] - choiceSortOrder[b.choiceId];
				});
			} else if (groupType === "ryg") {
				const rygSortOrder = {
					R: 1,
					Y: 2,
					G: 3,
				};
				Object.keys(groupedData).forEach((group) => {
					if (group === "default") {
						response.push({
							group: `No ${groupName}`,
							taskList: groupedData[group],
						});
					} else {
						response.push({
							group,
							taskList: groupedData[group],
						});
					}
				});
				response.sort((a, b) => rygSortOrder[a] - rygSortOrder[b]);
			} else if (groupType === "checkbox") {
				const checkboxSortOrder = {
					Yes: 1,
					No: 2,
				};
				response.push({
					group: "Yes",
					taskList: groupedData.Yes,
				});
				response.push({
					group: "No",
					taskList: [...groupedData.No, ...(groupedData.default || [])],
				});
				response.sort((a, b) => checkboxSortOrder[a] - checkboxSortOrder[b]);
			}

			// sort by manual sort order and filter out tasks
			return response.map((group) => {
				const unsortedTasks = group.taskList;

				// get filters for this group
				const filters = fieldFilters.filter(
					(filter) => filter.subjectId === groupBy,
				);

				const operations = {
					EQUAL: (a, b) => a === b,
					NOT_EQUAL: (a, b) => a !== b,
					GREATER_THAN: (a, b) => a > b,
					LESS_THAN: (a, b) => a < b,
					GREATER_THAN_OR_EQUAL: (a, b) => a >= b,
					LESS_THAN_OR_EQUAL: (a, b) => a <= b,
				};

				// filter out tasks that match the filters
				const filteredUnsortedTasks = unsortedTasks.filter(
					(task) =>
						!filters.some((filter) => {
							const fieldValue = task.fieldValues?.[filter.objectId];
							if (!fieldValue) return false;
							return (
								operations[filter.operator](filter.value, fieldValue.value) ||
								operations[filter.operator](filter.value, fieldValue.choiceId)
							);
						}),
				);

				return {
					...group,
					taskList: [...filteredUnsortedTasks].toSorted((a, b) => {
						if (!a.sortOrder) return 1;
						if (!b.sortOrder) return -1;
						return a.sortOrder - b.sortOrder;
					}),
				};
			});
		}
		return [];
	}, [tasks, fields, groupBy, fieldFilters]);

	const showTask = (p, tId: string) => {
		navigate({
			pathname: `/process-management/${processId}/tasks/${processId}-${tId}`,
			search: buildQueryString({}),
		});
	};

	async function addTask() {
		// create unpublished task
		setDisableAddTask(true);
		try {
			const task = await createTask(processId, {});

			navigate({
				pathname: `/process-management/${processId}/new-task/${processId}-${task.id}`,
				search: buildQueryString({}),
			});
		} catch (e) {
			console.error(e);
		} finally {
			setDisableAddTask(false);
		}
	}

	async function exportProcess(archived = false, limit = 50000) {
		await downloadProcessTasks(
			processId,
			processName,
			archived,
			activeViewObj?.name,
			{ limit, sort: mapSorting(sorting), filter: apiColumnFilters },
		);
	}

	return (
		<Box
			sx={{
				margin: {
					xs: 0,
					sm: 2,
				},
				padding: 0,
				background: (theme) => theme.palette.background.paper,
				border: (theme) => `1px solid ${theme.palette.divider}`,
			}}
			data-cy="page__process_management"
		>
			<Box padding={1}>
				<Header
					processId={processId}
					processName={processName}
					groupBy={groupBy || ""}
					groupableFields={groupableFields}
					setGroupBy={(grouping) => goToGrouping(grouping)}
					view={view}
					forms={forms}
					showBoardView={() => goToView("board")}
					showTableView={() => goToView("table")}
					showArchived={() => goToView("archived")}
					addTask={() => addTask()}
					exportProcess={async (archived, limit) =>
						exportProcess(archived, limit)
					}
					entityName={entityName}
					boardViewDisabled={boardViewDisabled}
					query={query}
					onQueryChange={(newQuery) => onQueryChange(newQuery)}
					onProcessNameChange={(newName) => updateProcessName(newName)}
					canEdit={canEdit}
					canEditFields={canEditFields}
					canEditViews={canEditViews}
					disableAddTask={disableAddTask}
					onFormActionClick={(formId) => {
						openFormWizard(formId);
					}}
					addButtonLabel={addButtonLabel}
				/>
			</Box>

			{view === "board" && !boardViewDisabled && (
				<BoardView
					isLoading={isLoadingTasks}
					fields={fields}
					processId={processId}
					groupedBy={groupBy}
					groupedTasks={groupedTasks}
					fieldFilters={fieldFilters}
					showTask={showTask}
					updateTaskGroup={(tid, group, sortOrder) => {
						// get task
						updateTask(Number(tid), groupBy, group, sortOrder);
					}}
					entityName={entityName}
				/>
			)}
			{view === "table" && (
				<TableView
					isLoading={isLoadingTasks}
					fields={fields}
					processId={processId}
					tasks={tasks}
					views={views}
					fieldFilters={fieldFilters}
					entityName={entityName}
					isFetching={isFetching}
					fetchNextPage={fetchNextPage}
					sorting={sorting}
					columnFilters={columnFilters}
					columnOrder={columnOrder}
					columnVisibility={columnVisibility}
					getFilterCounts={getFilterCounts}
					showTask={showTask}
					onSortingChange={handleSortingChange}
					onColumnFilterChange={handleColumnFilterChange}
					onColumnVisibilityChange={handleColumnVisibilityChange}
					onColumnOrderChange={handleColumnOrderChange}
				/>
			)}
			{view === "archived" && (
				<ArchivedView processId={processId} entityName={entityName} />
			)}

			<NewTaskFormDialog
				open={showNewTask}
				onClose={() => {
					if (!newTaskChanged) {
						closeTaskModal();
					}
				}}
				onCancel={() => {
					setNewTaskChanged(false);
					closeTaskModal();
				}}
				onChange={() => setNewTaskChanged(true)}
				onCreate={() => {
					setNewTaskChanged(false);
					closeTaskModal();
					refetch();
				}}
				processId={processId}
				taskId={taskId}
			/>

			<EditTaskDialog
				processId={processId}
				taskId={taskId}
				open={showEditTask}
				onClose={() => {
					closeTaskModal();
				}}
				onArchive={(archivedTaskId) => {
					// eager remove from cache
					queryClient.setQueriesData(
						["processTasks", processId],
						(oldData: TaskList) => ({
							...oldData,
							tasks: tasks.filter(
								({ id }) => archivedTaskId.toString() !== id.toString(),
							),
						}),
					);
				}}
				onArchived={() => {
					refetch();
				}}
				fullWidth
				maxWidth="lg"
				scroll="paper"
				onCommentCreate={addComment}
				onUpdate={() => {
					refetch();
					// i dont think we should do this. we should just update the cache
				}}
			/>

			<FormWizard
				open={showFormWizard}
				onClose={() => {
					setShowFormWizard(false);
					setTimeout(() => {
						setActiveForm(null);
					}, 1);

					// fetch tasks
					refetch();
				}}
				processId={processId}
				formId={activeForm}
				entityName={entityName}
				forms={forms}
			/>
		</Box>
	);
}

export default ProcessView;
