import { Box, Tooltip, Typography } from "@material-ui/core";
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Link } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import CompanyAvatar from "../CompanyAvatar";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			width: "100%",
			padding: theme.spacing(1),
			cursor: "pointer",
			"&:hover": {
				backgroundColor: theme.palette.action.hover,
			},
		},
		articleContainer: {
			display: "flex",
			gap: theme.spacing(2),
			alignItems: "center",
			justifyContent: "flex-start",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			cursor: "pointer",
		},
		image: {
			width: "72px",
			height: "56px",
			objectFit: "cover",
			objectPosition: "center",
			borderRadius: theme.shape.borderRadius,
		},
		textContainer: {
			display: "flex",
			flexDirection: "column",
		},
		companyFrame: {
			display: "flex",
			flex: 1,
			alignItems: "center",
			justifyContent: "flex-end",
			marginLeft: theme.spacing(0.5),
		},
		title: {
			overflow: "hidden",
			color: theme.palette.text.primary,
			fontWeight: theme.typography.fontWeightMedium,
			fontSize: "1rem",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
		},
		source: {
			color: theme.palette.text.secondary,
			fontWeight: theme.typography.fontWeightMedium,
			fontSize: ".875rem",
		},
	}),
);

interface Props {
	showCompany?: boolean;
	showNewsImage?: boolean;
	articleTimestamp: string;
	imageUrl?: string;
	pageUrl: string;
	siteName?: string;
	title: string;
	backupImage?: string;
	name?: string;
	domain?: string;
	valorId?: string;
	logoUrl?: string;
}

export default function NewsArticle(props: Props) {
	const {
		title,
		siteName = "",
		imageUrl = "",
		articleTimestamp,
		pageUrl,
		showCompany = false,
		showNewsImage = false,
		name = "",
		logoUrl = "",
		domain = "",
		valorId = "",
		backupImage,
	} = props;

	const classes = useStyles();
	const [articleSrc, setArticleSrc] = useState(imageUrl);
	return (
		<Box className={classes.root}>
			<Link
				className={classes.articleContainer}
				href={pageUrl}
				target="_blank"
				rel="noreferrer"
			>
				{showNewsImage && (
					<img
						className={classes.image}
						src={articleSrc}
						onError={() => setArticleSrc(backupImage)}
						alt={title}
					/>
				)}
				<Tooltip title={title} placement="top">
					<Box className={classes.textContainer}>
						<Typography className={classes.title}>{title}</Typography>
						<Typography className={classes.source}>{siteName}</Typography>
						<Typography className={classes.source}>
							{dayjs(articleTimestamp).format("MMMM D, YYYY")}
						</Typography>
					</Box>
				</Tooltip>
			</Link>
			{showCompany && (
				<Box className={classes.companyFrame}>
					<Tooltip title={name}>
						<Link href={`/org/${valorId}`}>
							<CompanyAvatar
								name={name}
								src={logoUrl}
								domain={domain}
								size="48"
							/>
						</Link>
					</Tooltip>
				</Box>
			)}
		</Box>
	);
}
