import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
	Alert,
	Box,
	Button,
	FormControl,
	FormLabel,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
	styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

type Probability = {
	dollar: number | string;
	percent: number;
};

type ProbabilityValue = {
	numericValue: number;
	probabilities: Probability[];
};

type ProbabilityInputProps = {
	value?: ProbabilityValue;
	onBlur: (value: ProbabilityValue) => void;
	label: string;
	required?: boolean;
	disabled?: boolean;
	dataCy?: string;
	error?: string;
};

const DEFAULT_VALUE: ProbabilityValue = {
	numericValue: 0,
	probabilities: [{ dollar: 0, percent: 0 }],
};
const formatDollar = (value: number): string =>
	new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	}).format(value);

const removeCommas = (value: string | number): string =>
	value ? value.toString().replace(/,/g, "") : "";

const parseDollarAmount = (value: string | number): number => {
	if (typeof value === "number") return value;
	const cleanValue = removeCommas(value).replace(/[^\d.-]/g, "");
	return Number.parseFloat(cleanValue) || 0;
};

const formatNumberWithCommas = (value: string | number): string => {
	if (value === undefined || value === null) return "";
	const stringVal = value.toString();
	if (!stringVal) return "";

	// Remove any existing commas and non-numeric characters except decimal point
	const cleanValue = stringVal.replace(/[^\d.]/g, "");

	// Split number into integer and decimal parts
	const [integerPart, decimalPart] = cleanValue.split(".");

	// Add commas to integer part
	const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

	// Return formatted number (with decimal part if it exists)
	return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

const parsePercent = (value: string): number => {
	const cleanValue = value.replace(/[^\d.-]/g, "");
	return Number.parseFloat(cleanValue) || null;
};

const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
	color: theme.palette.text.secondary,
	"&:hover": {
		color: theme.palette.white.main,
		backgroundColor: theme.palette.error.light,
	},
	"&.Mui-disabled": {
		color: theme.palette.action.disabled,
	},
}));

const StyledContainer = styled(Box)<{ error?: string }>(({ theme, error }) => ({
	border: `1px solid ${
		error
			? theme.palette.error.main
			: theme.palette.mode === "light"
				? "rgba(0, 0, 0, 0.23)"
				: "rgba(255, 255, 255, 0.23)"
	}`,
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(2),
	"&:hover": {
		borderColor: error ? theme.palette.error.main : theme.palette.text.primary,
	},
	"&:focus-within": {
		borderColor: error ? theme.palette.error.main : theme.palette.primary.main,
		borderWidth: 2,
	},
}));

const StyledLabel = styled(FormLabel)<{ error?: string }>(
	({ theme, error }) => ({
		transform: "translate(14px, -6px) scale(0.75)",
		position: "absolute",
		backgroundColor: theme.palette.background.default,
		padding: "0 4px",
		color: error ? theme.palette.error.main : theme.palette.text.secondary,
		"&.Mui-focused": {
			color: error ? theme.palette.error.main : theme.palette.primary.main,
		},
	}),
);

function ProbabilityDollarInput({
	value: externalValue,
	onBlur,
	label,
	required = false,
	disabled = false,
	dataCy,
	error,
}: ProbabilityInputProps) {
	const [internalValue, setInternalValue] = useState<ProbabilityValue>(
		externalValue || DEFAULT_VALUE,
	);
	const [internalError, setInternalError] = useState<string>("");

	useEffect(() => {
		if (externalValue) {
			setInternalValue(externalValue);
		}
	}, [externalValue]);

	const calculateNumericValue = (probabilities: Probability[]): number =>
		probabilities.reduce((acc, curr) => {
			const dollarValue =
				typeof curr.dollar === "string"
					? parseDollarAmount(removeCommas(curr.dollar))
					: curr.dollar;
			return acc + dollarValue * (curr.percent / 100);
		}, 0);

	const updateValue = (newValue: ProbabilityValue) => {
		setInternalValue(newValue);
	};

	const handleDollarChange = (index: number, inputValue: string) => {
		const formattedValue = formatNumberWithCommas(inputValue);
		const newProbabilities = [...internalValue.probabilities];
		newProbabilities[index] = {
			...newProbabilities[index],
			dollar: formattedValue,
		};

		const newValue: ProbabilityValue = {
			probabilities: newProbabilities,
			numericValue: calculateNumericValue(newProbabilities),
		};

		updateValue(newValue);
	};

	const handleProbabilityChange = (index: number, inputValue: string) => {
		const percentValue = parsePercent(inputValue);
		if (percentValue <= 100) {
			const newProbabilities = [...internalValue.probabilities];
			newProbabilities[index] = {
				...newProbabilities[index],
				percent: percentValue,
			};

			const newValue: ProbabilityValue = {
				probabilities: newProbabilities,
				numericValue: calculateNumericValue(newProbabilities),
			};

			updateValue(newValue);
		}
	};

	const validateProbabilities = () => {
		const totalProbability = internalValue.probabilities.reduce(
			(sum, curr) => sum + curr.percent,
			0,
		);
		if (totalProbability !== 100) {
			setInternalError(
				`Total probability must equal 100%. Current total: ${totalProbability}%`,
			);
			return false;
		}
		setInternalError("");
		return true;
	};

	const onSave = () => {
		if (validateProbabilities()) {
			// Clean up dollar values befor

			const sortedProbabilities = internalValue.probabilities.sort(
				(a, b) => a.dollar - b.dollar,
			);

			const newValue = {
				numericValue: calculateNumericValue(sortedProbabilities),
				probabilities: sortedProbabilities,
			};
			setInternalValue(newValue);
			const cleanedProbabilities = sortedProbabilities.map((prob) => ({
				...prob,
				dollar:
					typeof prob.dollar === "string"
						? parseDollarAmount(prob.dollar)
						: prob.dollar,
			}));

			const cleanedValue = {
				probabilities: cleanedProbabilities,
				numericValue: calculateNumericValue(cleanedProbabilities),
			};

			onBlur(cleanedValue);
		}
	};

	const addRow = () => {
		const newProbabilities = [
			...internalValue.probabilities,
			{ dollar: 0, percent: 0 },
		];
		updateValue({
			probabilities: newProbabilities,
			numericValue: calculateNumericValue(newProbabilities),
		});
	};

	const removeRow = (index: number) => {
		if (internalValue.probabilities.length > 1) {
			const newProbabilities = internalValue.probabilities.filter(
				(_, i) => i !== index,
			);
			updateValue({
				probabilities: newProbabilities,
				numericValue: calculateNumericValue(newProbabilities),
			});
		}
	};

	return (
		<FormControl fullWidth error={!!error}>
			<Box sx={{ position: "relative", pt: 1 }}>
				<StyledLabel required={required} error={error}>
					{label}
				</StyledLabel>
				<StyledContainer error={error}>
					{internalError && (
						<Alert severity="error" sx={{ mb: 2 }}>
							{internalError}
						</Alert>
					)}
					<Stack direction="row" mb={1} spacing={16} sx={{ width: "100%" }}>
						<Typography variant="tableHeader">Dollar Amount</Typography>
						<Typography variant="tableHeader">Probability</Typography>
					</Stack>
					{internalValue.probabilities.map((probability, index) => (
						<Stack
							// eslint-disable-next-line react/no-array-index-key
							key={index}
							direction="row"
							spacing={2}
							sx={{
								mb: index !== internalValue.probabilities.length - 1 ? 2 : 0,
							}}
							alignItems="center"
						>
							<TextField
								size="small"
								placeholder="Amount"
								value={formatNumberWithCommas(probability.dollar)}
								onChange={(e) => handleDollarChange(index, e.target.value)}
								disabled={disabled}
								data-cy={`${dataCy}-dollar-${index}`}
								fullWidth
								required={required}
								error={!!error}
								inputProps={{
									startAdornment: (
										<InputAdornment position="start">$</InputAdornment>
									),
									pattern: "^\\$?[0-9,]*(\\.[0-9]{0,2})?$",
								}}
							/>
							<TextField
								size="small"
								placeholder="Probability"
								value={probability.percent}
								onChange={(e) => handleProbabilityChange(index, e.target.value)}
								disabled={disabled}
								data-cy={`${dataCy}-probability-${index}`}
								fullWidth
								required={required}
								error={!!error}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">%</InputAdornment>
									),
								}}
							/>
							<StyledDeleteButton
								onClick={() => removeRow(index)}
								disabled={disabled || internalValue.probabilities.length === 1}
								data-cy={`${dataCy}-remove-${index}`}
								size="small"
							>
								<DeleteIcon fontSize="small" />
							</StyledDeleteButton>
						</Stack>
					))}

					<Box sx={{ mt: 2 }}>
						<IconButton
							onClick={addRow}
							color="primary"
							disabled={disabled}
							data-cy={`${dataCy}-add`}
							size="small"
						>
							<AddIcon />
						</IconButton>
					</Box>

					<Stack spacing={1} sx={{ mt: 2 }}>
						<Box sx={{ display: "flex", justifyContent: "space-between" }}>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Typography variant="body2" color="text.secondary">
									Total Probability:{" "}
									<Typography variant="boldBody2" color="text.primary">
										{internalValue.probabilities
											.reduce((sum, curr) => sum + curr.percent, 0)
											.toFixed(2)}
										%
									</Typography>
								</Typography>
								<Typography variant="body2" color="text.secondary">
									Weighted Value:{" "}
									<Typography variant="boldBody2" color="text.primary">
										{formatDollar(internalValue.numericValue)}
									</Typography>
								</Typography>
							</Box>
							<Button variant="outlined" onClick={onSave}>
								Save
							</Button>
						</Box>
					</Stack>
				</StyledContainer>
			</Box>
		</FormControl>
	);
}

export default ProbabilityDollarInput;
