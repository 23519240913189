import type { Organization } from "@/api/Organization";
import { Box } from "@mui/material";
import React from "react";
import ModelFeatures from "./ModelFeatures";

export default function Explainability({
	company,
}: {
	company: Organization;
}) {
	return (
		<Box display="flex" flexDirection="column" alignItems="center" gap={1}>
			<ModelFeatures
				company={company}
				modelId="2e290559-f806-4f82-8090-a1b191bf5d20"
			/>
		</Box>
	);
}
