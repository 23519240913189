import { Person } from "@mui/icons-material";
import { Box, Chip, ClickAwayListener, Tooltip } from "@mui/material";
import React from "react";
import OverflowBox from "./OverflowBox";

type PersonValue = {
	id: string;
	firstName: string;
	lastName: string;
	primaryEmail: string;
};

const renderPersons = (persons: PersonValue[], showLabel = true) =>
	persons.map((person) => {
		const fullName = `${person.firstName} ${person.lastName}`;
		const initials = `${person.firstName} ${person.lastName[0].toUpperCase()}`;

		if (!showLabel) {
			return (
				<Tooltip title={`${fullName} (${person.primaryEmail})`} key={person.id}>
					<Chip label={initials} sx={{ height: "25px" }} />
				</Tooltip>
			);
		}

		return (
			<Tooltip title={person.primaryEmail} key={person.id}>
				<Chip label={fullName} avatar={<Person />} sx={{ height: "25px" }} />
			</Tooltip>
		);
	});

export default function PersonCell({
	value,
	showLabel = true,
}: { value: PersonValue[]; showLabel?: boolean }) {
	const [showOverflow, setShowOverflow] = React.useState(false);
	if (!value || !Array.isArray(value)) return <>-</>;

	return (
		<ClickAwayListener onClickAway={() => setShowOverflow(false)}>
			<Box position="relative">
				<Box
					display="inline-flex"
					flexWrap="nowrap"
					alignItems="center"
					gap={0.5}
					overflow="hidden"
					whiteSpace="nowrap"
					width="100%"
					sx={{
						overflow: "hidden",
						cursor: "pointer",
					}}
					onClick={() => setShowOverflow(true)}
				>
					{renderPersons(value, showLabel)}
				</Box>
				<OverflowBox open={showOverflow}>
					{renderPersons(value, true)}
				</OverflowBox>
			</Box>
		</ClickAwayListener>
	);
}
