import isDateOnlyISOString from "@/utils/dates";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

export default function DateTimeCell({
	value,
}: {
	value: string | null;
}) {
	let content = null;
	if (value === null || value === undefined) {
		content = "-";
	} else {
		const date = dayjs(value);
		if (isDateOnlyISOString(value)) {
			content = date.format("ddd, MMM Do");
		} else {
			content = date.format("ddd, MMM Do\nhh:mm A z");
		}
	}
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			sx={{ width: "100%", height: "100%" }}
		>
			<Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
				{content}
			</Typography>
		</Box>
	);
}
