import { getTaskActivity } from "@/api/Process";
import { useAuth } from "@/hooks/useAuth";
import ErrorBoundary from "@/utils/ErrorBoundary";
import {
	Box,
	Breadcrumbs,
	Button,
	Collapse,
	Skeleton,
	Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Comment } from "../Comments";
import CommentForm from "../Comments/CommentForm";
import useProcess from "../ProcessContext/useProcess";
import ActivityEvent from "./ActivityEvent";
import FieldChangeEvent from "./FieldChangeEvent";
import TaskDetails from "./TaskDetails";

type ActivityProps = {
	processId: string;
	taskId: string;
	replyEntity?: string;
	onComment: (comment: { comment: string }) => void;
};

export default function Activity({
	processId,
	taskId,
	onComment,
	replyEntity,
}: ActivityProps) {
	const {
		data: {
			fields: fieldData = [],
			settings: { entityName } = {},
			organizationFields: orgFieldData = [],
		},
	} = useProcess(processId);

	const { user } = useAuth();
	const [editingComment, setEditingComment] = useState(null);
	const [showActivity, setShowActivity] = useState(true);

	const {
		data: activityData,
		isLoading: isLoadingActivity,
		refetch: refetchActivity,
	} = useQuery(["taskActivity", processId, taskId], () =>
		getTaskActivity(processId, taskId),
	);
	const queryClient = useQueryClient();

	const fields = [...(fieldData ?? []), ...(orgFieldData ?? [])];
	const {
		fieldChanges: fieldChangesData,
		orgFieldChanges: orgFieldChangesData,
	} = activityData || {};
	const activity = [
		...(fieldChangesData ?? []),
		...(orgFieldChangesData ?? []),
	];

	const handleOnComment = ({ comment }) => {
		if (onComment) {
			onComment({ comment });
		}
		// eager update the activity
		queryClient.setQueryData(["taskActivity", processId, taskId], (prev) => ({
			...prev,
			comments: [
				...prev.comments,
				{
					id: "temp",
					user,
					comment,
					createdAt: new Date().toISOString(),
					updatedAt: new Date().toISOString(),
				},
			],
		}));

		refetchActivity();
	};

	// process data
	const timeline = useMemo(() => {
		if (!activityData || !fieldData) {
			return [];
		}

		// process field changes
		let fieldChanges =
			activity?.map((change) => {
				const type = "fieldChange";
				const when = dayjs.utc(change.createdAt);
				const { author } = change;

				return {
					type,
					when,
					author,
					field:
						fields.find((f) => f.id === change.fieldId) ||
						fields.find((f) => f.settingId === change.fieldId),
					fieldValue: change.fieldValue,
				};
			}) || [];

		fieldChanges = fieldChanges.filter(
			(x) => dayjs(activityData.createdAt).diff(x.when, "minutes") < 1,
		);

		const comments =
			activityData.comments?.map((comment) => {
				const type = "comment";
				const when = dayjs.utc(comment.createdAt);
				const updatedAt = dayjs.utc(comment.updatedAt);
				const { user: author } = comment;

				return {
					type,
					when,
					updatedAt,
					id: comment.id,
					author,
					comment: comment.comment,
				};
			}) || [];

		let taskDetails =
			activityData.taskDetailChanges
				?.map((detail, index) => {
					const type = "taskDetail";
					const when = dayjs.utc(detail.createdAt);
					const { author } = detail;

					// previous detail
					let prev = null;
					if (index > 0) {
						prev = activityData.taskDetailChanges[index - 1];
					}

					// if there's a previous detail, and it's the same as this one, skip it
					if (
						!prev ||
						(prev &&
							prev.name === detail.name &&
							prev.description === detail.description)
					) {
						return null;
					}

					// figure out what changed
					let nameChanged = null;
					let descriptionChanged = null;

					if (prev && prev.name !== detail.name) {
						nameChanged = {
							from: prev.name,
							to: detail.name,
						};
					}

					if (prev && prev.description !== detail.description) {
						descriptionChanged = {
							from: prev.description,
							to: detail.description,
						};
					}

					return {
						type,
						when,
						author,
						name: nameChanged,
						description: descriptionChanged,
					};
				})
				.filter((x) => x) || [];

		// if user is the same between task details, and made within the hour, combine them
		taskDetails = taskDetails.reduce((acc, detail) => {
			if (acc.length === 0) {
				return [detail];
			}
			const prev = acc[acc.length - 1];
			if (
				detail.author.id === prev.author.id &&
				detail.when.isSame(prev.when, "hour")
			) {
				return [
					...acc,
					{
						...detail,
						name:
							detail.name || prev.name
								? {
										from: prev.name?.from || detail.name?.from,
										to: detail.name?.to || prev.name?.to,
									}
								: null,
						description:
							prev.description || detail.description
								? {
										from: prev.description?.from || detail.description?.from,
										to: detail.description?.to || prev.description?.to,
									}
								: null,
					},
				];
			}
			return [...acc, detail];
		}, []);

		const createdChange = {
			type: "createdAt",
			author: activityData.createdBy,
			when: dayjs.utc(activityData.createdAt),
		};

		return [createdChange, ...fieldChanges, ...comments, ...taskDetails]
			.filter((x) => x)
			.toSorted((a, b) => (a.when.isBefore(b.when) ? -1 : 1))
			.filter((x, index, changes) => {
				const next = changes[index + 1];
				if (!next) {
					return true;
				}
				// if a field change and the next one is the same and made within the hour, skip it
				if (
					x.type === "fieldChange" &&
					next.type === "fieldChange" &&
					x.field?.id === next.field?.id
				) {
					return !x.when.isSame(next.when, "hour");
				}
				if (x.type === "fieldChange") {
					return true;
				}

				if (x.type === "taskDetail" && next.type === "taskDetail") {
					return !x.when.isSame(next.when, "hour");
				}

				return x.type !== "fieldChange";
			});
	}, [activityData, fieldData]);

	const [displayCount, setDisplayCount] = useState(7);
	const OLDEST_ACTIVITY_ITEM_COUNT = 3;
	const REMAINING_ITEMS =
		timeline.length - displayCount - OLDEST_ACTIVITY_ITEM_COUNT;
	const incrementDisplay = () => {
		const INCREMENT = 25;
		const maxItems = timeline.length - OLDEST_ACTIVITY_ITEM_COUNT;
		const newDisplayCount = displayCount + INCREMENT;
		if (newDisplayCount <= maxItems) setDisplayCount(newDisplayCount);
		else setDisplayCount(maxItems);
	};

	const renderItem = React.useCallback(
		(item) => {
			if (item.type === "createdAt") {
				return (
					<ErrorBoundary errorMessage="" key={item.type}>
						<ActivityEvent>
							<ActivityEvent.Author author={item.author} />
							{` created this ${entityName.toLowerCase()}`}
							<ActivityEvent.When when={item.when} />
						</ActivityEvent>
					</ErrorBoundary>
				);
			}

			if (item.type === "fieldChange") {
				return (
					<ErrorBoundary errorMessage="" key={item.id}>
						<FieldChangeEvent
							author={item.author}
							when={item.when}
							field={item.field}
							fieldValue={item.fieldValue}
							entityName={entityName.toLowerCase()}
						/>
					</ErrorBoundary>
				);
			}

			if (item.type === "comment") {
				return (
					<Box mt={0.5} mb={0.5} key={item.id}>
						<Comment
							processId={processId}
							currentUser={user}
							comment={{
								id: item.id,
								user: item.author,
								comment: item.comment,
								createdAt: item.when,
								updatedAt: item.updatedAt,
							}}
							activeComment={editingComment}
							setActiveComment={setEditingComment}
							onComment={handleOnComment}
						/>
					</Box>
				);
			}

			if (item.type === "taskDetail") {
				return (
					<TaskDetails
						key={item.id}
						author={item.author}
						when={item.when}
						name={item.name}
						description={item.description}
						entityName={entityName.toLowerCase()}
					/>
				);
			}

			return null;
		},
		[entityName, editingComment, handleOnComment, user, processId],
	);

	if (isLoadingActivity) {
		return (
			<div>
				<Typography>Activity</Typography>
				<Box display="flex" flexDirection="column" gap={1} mt={1}>
					<Skeleton variant="rounded" height={30} />
					<Skeleton variant="rounded" height={30} />
					<Box display="flex" flexDirection="row" gap={1}>
						<Skeleton variant="circular" width={30} height={30} />
						<Skeleton variant="rounded" width="100%" height={60} />
					</Box>
					<Skeleton variant="rounded" height={30} />
					<Box display="flex" flexDirection="row" gap={1}>
						<Skeleton variant="circular" width={30} height={30} />
						<Skeleton variant="rounded" width="100%" height={60} />
					</Box>
					{replyEntity === "comments" && (
						<Box mt={2}>
							<CommentForm
								processId={processId}
								taskId={taskId}
								onComment={handleOnComment}
							/>
						</Box>
					)}
				</Box>
			</div>
		);
	}

	return (
		<div>
			<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
				<Breadcrumbs>
					<Typography>Activity</Typography>
				</Breadcrumbs>
				<Button
					variant="outlined"
					size="small"
					onClick={() => setShowActivity((prev) => !prev)}
				>
					{showActivity ? "Hide" : "Show"}
				</Button>
			</Box>
			<Collapse in={showActivity}>
				<Box display="flex" flexDirection="column" gap={0.5} mt={1}>
					<Box>
						{timeline.slice(0, OLDEST_ACTIVITY_ITEM_COUNT).map(renderItem)}
						{displayCount < timeline.length - OLDEST_ACTIVITY_ITEM_COUNT && (
							<Box
								display="flex"
								flexDirection="row"
								alignItems="center"
								gap={1}
								mt={1}
							>
								<Typography variant="caption" color="text.secondary">
									{REMAINING_ITEMS} more events
								</Typography>
								<Button
									onClick={() => incrementDisplay()}
									size="small"
									variant="text"
								>
									Show More
								</Button>
							</Box>
						)}
						{displayCount > 0 && timeline.slice(-displayCount).map(renderItem)}
					</Box>
				</Box>
				{replyEntity === "comments" && (
					<Box mt={2}>
						<CommentForm
							processId={processId}
							taskId={taskId}
							onComment={handleOnComment}
						/>
					</Box>
				)}
			</Collapse>
		</div>
	);
}
