/* eslint-disable max-classes-per-file */
import axios from "axios";
import dayjs from "dayjs";
import type { paths } from ".";

type GetSummariesByOrg =
	paths["/api/tegus/{valor_id}/transcript-summaries"]["get"];
export type Summaries =
	GetSummariesByOrg["responses"]["200"]["content"]["application/json"];

type GetTranscriptById = paths["/api/tegus/transcript/{transcript_id}"]["get"];
export type RawTranscript =
	GetTranscriptById["responses"]["200"]["content"]["application/json"];

export class Score {
	data: RawTranscript["scores"][0];

	constructor(data) {
		this.data = data;
	}

	get color() {
		return this.data?.color;
	}

	get promptType() {
		return this.data?.promptType;
	}

	get reason() {
		return this.data?.reason;
	}
}

export class Transcript {
	data: RawTranscript;

	constructor(data) {
		this.data = data;
		// could validate data here
	}

	get id() {
		return this.data?.id;
	}

	get summary() {
		return this.data?.summary;
	}

	get biography() {
		// find instance of Q:, split on it, and return the first half
		if (!this.data?.biography) return null;
		const biography = this.data?.biography;
		const questionIndex = biography.indexOf("Q:");
		if (questionIndex === -1) return biography;
		return biography.slice(0, questionIndex);
	}

	get screeningQuestions(): { question: string; answer: string }[] {
		// find instance of Q: in biography, split on it, and return the second half
		if (!this.data?.biography) return null;
		const biography = this.data?.biography;
		const questionIndex = biography.indexOf("Q:");
		if (questionIndex === -1) return null;
		const questions = `Q:${biography.slice(questionIndex)}`;
		// split on Q: and A: and format into an array of objects
		const questionArray = questions.split("Q:").map((el) => {
			const splitElement = el.split("A:");
			return {
				question: splitElement[0],
				answer: splitElement[1],
			};
		});
		return questionArray.filter((el) => el.question !== "");
	}

	get conversation() {
		return this.data?.conversation;
	}

	get inferredDiscussionTopics() {
		return this.data?.inferredDiscussionTopic;
	}

	get title() {
		return this.data?.title;
	}

	get url() {
		return this.data?.url;
	}

	get callDate(): dayjs.Dayjs | null {
		if (!this.data?.callDate) return null;
		return dayjs.utc(this.data?.callDate);
	}

	get postDate(): dayjs.Dayjs | null {
		if (!this.data?.postDate) return null;
		return dayjs.utc(this.data?.postDate);
	}

	get scores(): Score[] | null {
		return this.data?.scores?.map((score) => new Score(score));
	}

	get sentiment() {
		if (!this.data?.scores || !this.scores?.length) return Number.NaN;
		const elementCount = this.scores.filter(
			(score) => score.color !== "gray",
		).length;
		const sentiment = this.scores.reduce((acc, score) => {
			const scoreValueMap = {
				green: 1,
				yellow: 0,
				gray: 0,
				red: -1,
			};
			return acc + scoreValueMap[score.color];
		}, 0);
		return Math.round((sentiment / elementCount) * 10) / 10;
	}

	get sentimentColor() {
		if (Number.isNaN(this.sentiment)) return null;
		if (this.sentiment > 0.3) return "green";
		if (this.sentiment < -0.3) return "red";
		return "yellow";
	}
}

export const getSummariesByOrg = async (
	valorId: string,
	limit = 5,
): Promise<{ data: Transcript[] }> => {
	const response = await axios.get(
		`/api/tegus/${valorId}/transcript-summaries?limit=${limit}`,
	);
	return {
		data: response.data.data.map((d) => new Transcript(d)),
	};
};

export const getTranscriptById = async (
	transcriptId: number,
): Promise<Transcript> => {
	const response = await axios.get(`/api/tegus/transcripts/${transcriptId}`);
	return new Transcript(response.data.data);
};

type GetTranscriptUserScores =
	paths["/api/tegus/transcripts/{transcript_id}/scores"]["get"];
export type TranscriptUserScores =
	GetTranscriptUserScores["responses"]["200"]["content"]["application/json"];
export const getTranscriptUserScores = async (
	transcriptId: number,
): Promise<Score[]> => {
	const response = await axios.get(
		`/api/tegus/transcripts/${transcriptId}/scores`,
	);
	return response.data.data.map((d) => new Score(d));
};

type PostTranscriptUserScores =
	paths["/api/tegus/transcripts/{transcript_id}/scores"]["post"];
type PostTranscriptUserScoresBody =
	PostTranscriptUserScores["requestBody"]["content"]["application/json"];
type PostTranscriptUserScoresResponse =
	PostTranscriptUserScores["responses"]["200"]["content"]["application/json"];
export const postUserScore = async (
	transcriptId: number,
	{ promptType, color, reason }: PostTranscriptUserScoresBody,
): Promise<PostTranscriptUserScoresResponse> => {
	const response = await axios.post(
		`/api/tegus/transcripts/${transcriptId}/scores`,
		{
			promptType,
			color,
			reason,
		},
	);
	return response.data;
};

type PostFavorite =
	paths["/api/tegus/transcripts/{transcript_id}/favorites"]["post"];
type PostFavoriteResponse =
	PostFavorite["responses"]["200"]["content"]["application/json"];
export const postFavorite = async (
	transcriptId: number,
): Promise<PostFavoriteResponse> => {
	const response = await axios.post(
		`/api/tegus/transcripts/${transcriptId}/favorites`,
	);
	return response.data;
};

type DeleteFavorite =
	paths["/api/tegus/transcripts/{transcript_id}/favorites"]["delete"];
type DeleteFavoriteResponse =
	DeleteFavorite["responses"]["200"]["content"]["application/json"];
export const deleteFavorite = async (
	transcriptId: number,
): Promise<DeleteFavoriteResponse> => {
	const response = await axios.delete(
		`/api/tegus/transcripts/${transcriptId}/favorites`,
	);
	return response.data;
};

type GetFavorites = paths["/api/tegus/favorites"]["get"];
type GetFavoritesResponse =
	GetFavorites["responses"]["200"]["content"]["application/json"];
export const getFavorites = async (): Promise<GetFavoritesResponse> => {
	const response = await axios.get("/api/tegus/favorites");
	return response.data;
};
