import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import SimilarityBadge from "@/ui/atoms/SimilarityBadge";
import { formatBigDollars } from "@/utils/numberFormat";
import {
	Box,
	ButtonBase,
	Chip,
	Collapse,
	Grid,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import dayjs from "dayjs";
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
		backgroundColor: theme.palette.background.paper,
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: theme.shape.borderRadius,
	},
	popover: {
		pointerEvents: "none",
	},
	title: ({ company }) => ({
		display: "block",
		marginRight: theme.spacing(1),
		"&:hover": {
			color: company?.valorId && theme.palette.blue.main,
			cursor: company?.valorId && "pointer",
		},
	}),
	lastContact: ({ company }) => ({
		display: "block",
		"&:hover": {
			color: company?.affId && theme.palette.blue.main,
			cursor: company?.affId && "pointer",
		},
	}),
	domain: {
		display: "block",
		"&:hover": {
			color: theme.palette.blue.main,
			cursor: "pointer",
		},
	},
	fundingTitle: {
		paddingBottom: theme.spacing(0.5),
		color: theme.palette.text.secondary,
		fontWeight: theme.typography.fontWeightBold,
		fontSize: "12px",
	},
	paper: {
		position: "absolute",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "320px",
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.paper,
		borderRadius: theme.shape.borderRadius,
		outline: "none",
		boxShadow: theme.shadows[5],
	},
	relevanceContainer: {
		display: "flex",
		flexWrap: "wrap",
		"& > div": {
			marginRight: theme.spacing(1),
		},
	},
	expandDescriptionBtn: {
		color: theme.palette.text.secondary,
		borderRadius: theme.shape.borderRadius,
	},
	selectResultBtn: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: theme.spacing(5),
		height: theme.spacing(5),
		color: theme.palette.text.secondary,
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: theme.shape.borderRadius,
	},
	description: {
		color: theme.palette.text.secondary,
	},
	relevanceChip: {
		fontWeight: theme.typography.fontWeightSemiBold,
	},
}));

export default function DiscoveryResult(props) {
	const { company, onSelect, isCompetitor } = props;
	const classes = useStyles(props);
	const theme = useTheme();

	const [hasBeenSelected, setHasBeenSelected] = useState(false);
	const [showDescription, setShowDescription] = useState(false);

	useEffect(() => setHasBeenSelected(isCompetitor), [isCompetitor]);

	const handleSelect = () => {
		setHasBeenSelected(true);
		onSelect();
	};

	const renderRelevance = () => {
		const sourceInfo = {
			descriptionSimilarity: {
				text: "Description Similarity",
				key: "descriptionSimilarity",
				description: "This companies uses similar language in its description",
				color: theme.palette.yellow.highlight,
			},
			keywordSimilarity: {
				text: "Keyword Similarity",
				key: "keywordSimilarity",
				description: "This company has similar keywords",
				color: theme.palette.red.highlight,
			},
			semrushRelevance: {
				text: "SEMRush",
				key: "semrushRelevance",
				description: "SEMrush shows this company bids on similar Google Ads",
				color: theme.palette.green.highlight,
			},
			simwebRelevance: {
				text: "SimWeb",
				key: "simwebRelevance",
				description: "Similarweb shows this company bids on similar Google Ads",
				color: theme.palette.purple.highlight,
			},
		};
		return (
			<Box className={classes.relevanceContainer}>
				{company?.overallSimilarity && (
					<SimilarityBadge similarity={company?.overallSimilarity} />
				)}
				{Object.keys(sourceInfo).map((source) => {
					if (company[sourceInfo[source].key] > 0) {
						return (
							<Tooltip
								title={sourceInfo[source].description}
								placement="top"
								key={sourceInfo[source].text}
							>
								<Chip
									className={classes.relevanceChip}
									style={{
										backgroundColor: sourceInfo[source].color,
										color: theme.palette.getContrastText(
											sourceInfo[source].color,
										),
									}}
									label={sourceInfo[source].text}
								/>
							</Tooltip>
						);
					}
					return null;
				})}
			</Box>
		);
	};
	return (
		<Grid container className={classes.root}>
			<Grid item container xs={2} sm={1}>
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
				>
					<Box
						onClick={() =>
							company?.valorId &&
							window.open(`/org/${company.valorId}`, "_blank")
						}
						style={{ cursor: company?.valorId && "pointer" }}
					>
						<CompanyAvatar
							size="48"
							src={company.logoUrl}
							name={company.name || company.domain}
							domain={company.domain}
						/>
					</Box>

					{Boolean(company?.description) && (
						<ButtonBase
							className={classes.expandDescriptionBtn}
							onClick={() => setShowDescription(!showDescription)}
						>
							{showDescription ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</ButtonBase>
					)}
				</Box>
			</Grid>
			<Grid item container spacing={1} xs={10} sm={11}>
				<Grid item xs={12}>
					<Box height="32px" display="flex" alignItems="center">
						<Typography
							variant="h2"
							className={classes.title}
							onClick={() =>
								company?.valorId &&
								window.open(`/org/${company.valorId}`, "_blank")
							}
							component={company?.valorId && "a"}
						>
							{company.name || company.domain}
						</Typography>
					</Box>
				</Grid>
				<Grid item container xs={12}>
					<Grid item xs={6} md={4}>
						<Typography
							className={classes.domain}
							variant="subtitle2"
							onClick={() =>
								window.open(`https://www.${company.domain}`, "_blank")
							}
							component="a"
						>
							{company.domain}
						</Typography>
						{company.lastInteractionTs && (
							<Typography
								className={classes.lastContact}
								onClick={() =>
									company?.affId &&
									window.open(
										`https://valor.affinity.co/companies/${Number.parseInt(
											company?.affId,
											10,
										)}`,
										"_blank",
									)
								}
								variant="subtitle2"
								component={company?.affId && "a"}
							>
								Last Contacted: &thinsp;
								{dayjs.unix(company.lastInteractionTs).format("MM/DD/YYYY")}
							</Typography>
						)}
					</Grid>
					<Grid item container xs={12} md={6}>
						<Grid item md={3}>
							<Typography className={classes.fundingTitle}>
								TOTAL RAISED
							</Typography>
							<Typography variant="h3" component="p">
								{company?.totalFundingUsd
									? formatBigDollars(company.totalFundingUsd)
									: "-"}
							</Typography>
						</Grid>
						<Grid item container justifyContent="space-between" md={9}>
							<Grid item md={4}>
								<Typography className={classes.fundingTitle}>
									LAST ROUND
								</Typography>
								<Typography variant="h3" component="p">
									{company?.lastRoundTotalUsd
										? formatBigDollars(company.lastRoundTotalUsd)
										: "-"}
								</Typography>
							</Grid>
							<Grid item md={8}>
								<Box display="flex" flexDirection="column">
									{company?.lastRoundTs && (
										<Typography variant="subtitle1" component="p">
											Last Raise: &thinsp;
											{dayjs.unix(company?.lastRoundTs).format("MMM DD, YYYY")}
										</Typography>
									)}
									{company?.lastRoundType && (
										<Typography variant="subtitle1" component="p">
											Type: &thinsp;
											{company?.lastRoundType}
										</Typography>
									)}
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={2}>
						<Box display="flex" alignItems="center" justifyContent="flex-end">
							<Tooltip
								title={
									company?.valorId
										? "Add to Competitors List"
										: "This company is from a 3rd party and not in our database"
								}
								placement="top"
							>
								<Box>
									<ButtonBase
										className={classes.selectResultBtn}
										onClick={() => company?.valorId && handleSelect()}
										style={{
											backgroundColor: hasBeenSelected
												? theme.palette.primary.main
												: !company?.valorId && theme.palette.background.paper,
											color: hasBeenSelected && "white",
										}}
										disabled={!company?.valorId}
									>
										<CheckCircleOutlineIcon />
									</ButtonBase>
								</Box>
							</Tooltip>
						</Box>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					{renderRelevance()}
				</Grid>
				<Grid item xs={10}>
					<Collapse in={showDescription}>
						<Typography className={classes.description} variant="body2">
							{company?.description}
						</Typography>
					</Collapse>
				</Grid>
			</Grid>
		</Grid>
	);
}
