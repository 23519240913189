import HeaderCell from "@/components/Table/Header/Cell";
import { StickyTable, StickyTableHead } from "@/components/Table/StickyTable";
import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import {
	flexRender,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import React from "react";

export function DataTable({ data, columns }) {
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	});

	return (
		<Box
			sx={{
				overflow: "auto",
			}}
			data-cy="local-table__container"
		>
			<StickyTable>
				<StickyTableHead>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow
							key={headerGroup.id}
							data-cy={`local-table__header-group-${headerGroup.id}`}
						>
							{headerGroup.headers.map((header) => {
								const isParent = !!header.subHeaders?.length;
								return (
									<HeaderCell
										key={header.id}
										header={header}
										column={header.column}
										table={table}
										isParent={isParent}
									/>
								);
							})}
						</TableRow>
					))}
				</StickyTableHead>
				<TableBody>
					{table.getRowModel().rows.map((row, index) => (
						<TableRow key={row.id} data-cy={`local-table__row-${row.id}`}>
							{row.getVisibleCells().map((cell) => (
								<TableCell
									key={cell.id}
									sx={(theme) => ({
										background: cell.column.getIsFiltered()
											? theme.palette.background.activeTableColumn
											: index % 2
												? theme.palette.background.oddTableRow
												: theme.palette.background.paper,
										borderRight: `1px solid ${theme.palette.divider}`,
										boxSizing: "border-box",
										minWidth: cell.column.getSize(),
										width: cell.column.getSize(),
										maxWidth: cell.column.getSize(),
										padding: 1,
									})}
									data-cy={`local-table__cell-${row.id}-${cell.column.id}`}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</StickyTable>
		</Box>
	);
}
export default DataTable;
