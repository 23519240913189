import React from "react";

import Clamps from "@/components/Clamps";
import IHEChip from "@/pages/Org/Transcripts/IHEChip";
import { NotificationImportant } from "@mui/icons-material";
import { Box, IconButton, Popover, Stack, Typography } from "@mui/material";

export default function AdminSummaryRow({
	transcript,
	ihe,
	allIhe,
	iheChanges,
	onSummaryOpen,
}) {
	const [aggOpen, setAggOpen] = React.useState(false);
	const [changeOpen, setChangeOpen] = React.useState(false);

	const aggRef = React.useRef(null);
	const changeRef = React.useRef(null);

	const iheSort = ([x], [y]) => {
		const labels = [
			"skills_traits_attributes",
			"domain_experience_expertise",
			"resources_process_values",
			"market_positioning",
			"macro_perspective",
			"micro_perspective",
			"financials",
			"risk",
			"scalability",
			"product_market_fit",
			"growth_rate",
			"cash_flow",
			"uniqueness_of_asset",
		];
		const customSortOrder = labels.reduce(
			(acc, label, index) => ({
				...acc,
				[label]: index,
			}),
			{},
		);
		return customSortOrder[x] - customSortOrder[y];
	};

	return (
		<Box
			display="grid"
			gridTemplateColumns={`
    60px auto auto
  `}
			gridTemplateRows={`
    40px 20px 20px
  `}
			alignItems="start"
		>
			<Box
				width="40px"
				height="40px"
				margin={1}
				display="flex"
				alignItems="center"
				justifyContent="center"
				sx={{
					color: (theme) =>
						transcript.sentimentColor &&
						theme.palette[transcript.sentimentColor].main,
					fontWeight: (theme) => theme.typography.fontWeightBold,
					gridRow: "1 / span 3",
					justifySelf: "center",
					border: (theme) =>
						`2px solid ${
							transcript.sentimentColor &&
							theme.palette[transcript.sentimentColor].main
						}`,
					borderRadius: 0.5,
					userSelect: "none",
					cursor: transcript.scores?.length > 0 ? "pointer" : "default",
				}}
				ref={aggRef}
				onClick={() => {
					if (transcript.scores?.length > 0) {
						setAggOpen(true);
					}
				}}
			>
				{Number.isNaN(transcript.sentiment) ? "NA" : transcript.sentiment}
			</Box>

			<Popover
				open={aggOpen}
				anchorEl={aggRef.current}
				onClose={() => setAggOpen(false)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<Stack padding={1} spacing={1}>
					{allIhe
						.toSorted((a, b) => iheSort([a.promptType], [b.promptType]))
						.map(({ promptType, color }) => (
							<IHEChip
								key={promptType}
								label={promptType
									.split("_")
									.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
									.join(" ")}
								size="small"
								color={color}
							/>
						))}
				</Stack>
			</Popover>

			<Box
				onClick={() => {
					onSummaryOpen(transcript);
				}}
				sx={{
					cursor: "pointer",
					overflow: "hidden",
					fontWeight: (theme) => theme.typography.fontWeightBold,
					"&:hover": {
						textDecoration: "underline",
					},
				}}
				width="100%"
				maxWidth="300px"
			>
				<Clamps maxLines={2}>{transcript.title}</Clamps>
			</Box>

			<Stack direction="row" alignItems="center" justifyContent="flex-end">
				{iheChanges > 0 && (
					<IconButton
						ref={changeRef}
						onClick={() => {
							setChangeOpen(true);
						}}
					>
						<NotificationImportant />
					</IconButton>
				)}
			</Stack>

			<Popover
				open={changeOpen}
				anchorEl={changeRef.current}
				onClose={() => setChangeOpen(false)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<Stack padding={1} spacing={1}>
					<Typography variant="caption">Notable IHE</Typography>
					<Stack direction="row" spacing={1} flexWrap="wrap">
						{ihe.map(([trend, [, color]]) => (
							<IHEChip
								key={trend}
								label={trend
									.split("_")
									.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
									.join(" ")}
								size="small"
								color={color}
							/>
						))}
					</Stack>
				</Stack>
			</Popover>

			<Typography variant="caption" sx={{ alignSelf: "start" }} gridRow="2">
				{transcript.postDate.format("MM/DD/YYYY")}
			</Typography>
		</Box>
	);
}
