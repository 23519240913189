import {
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useField } from "react-final-form";

const CustomGrid = styled(Grid)(({ theme }) => ({
	padding: theme.spacing(1),
	backgroundColor: theme.palette.background.paper,
}));

const ErrorText = styled(Typography)(({ theme }) => ({
	color: theme.palette.error.main,
}));

const SelectFormControl = styled(FormControl)(({ theme }) => ({
	width: theme.spacing(32),
}));

const currYear = new Date().getFullYear();

type AccountingYear = "Calendar Year" | "Fiscal Year";

const DEFAULT_VALUE = {
	currentYear: currYear,
	priorYear: currYear - 1,
	yearType: "Calendar Year",
	fyeMonth: null,
};
const defaultString = JSON.stringify(DEFAULT_VALUE);
interface Answer {
	currentYear: number;
	priorYear: number;
	yearType: AccountingYear;
	fyeMonth: number | null;
}

interface FiscalYearProps {
	questionId: string;
	initialValue: string;
}

export default function FiscalYearChooser({
	questionId,
	initialValue = defaultString,
}: FiscalYearProps) {
	const getRange = (min: number, max: number): number[] =>
		Array.from({ length: max - min + 1 }, (_, i) => min + i);
	const years = getRange(currYear - 3, currYear + 3);
	const months = getRange(1, 12);

	const fiscalYearValidate = (value: string): string | undefined => {
		const parsed = JSON.parse(value) as Answer;
		if (parsed.yearType === "Fiscal Year" && parsed.fyeMonth === null) {
			return "Fiscal year end month is required if fiscal year is selected";
		}
		return undefined;
	};

	const getMonthName = (monthNumber: number): string => {
		const date = new Date();
		date.setMonth(monthNumber - 1);
		return date.toLocaleString("en-US", { month: "short" });
	};

	const { input, meta } = useField(`${questionId}.answerData`, {
		initialValue,
		validate: fiscalYearValidate,
	});

	const answer = JSON.parse(input.value) as Answer;

	return (
		<CustomGrid container>
			<Grid item xs={12}>
				<ErrorText data-formstate={meta.error ? "error" : ""}>
					{meta.submitFailed && meta.error}
				</ErrorText>
			</Grid>

			<Grid item xs={6} lg={2}>
				<SelectFormControl
					component="fieldset"
					error={meta.submitFailed && !!meta.error}
				>
					<FormLabel component="legend">Accounting Year Type</FormLabel>
					<RadioGroup
						name="yearType"
						value={answer.yearType}
						onChange={(e) => {
							const data = {
								...answer,
								yearType: e.target.value as AccountingYear,
							};
							if (data.yearType === "Calendar Year") {
								data.fyeMonth = null;
							}
							input.onChange(JSON.stringify(data));
						}}
					>
						<FormControlLabel
							value="Calendar Year"
							control={<Radio />}
							label="Calendar Year End"
						/>
						<FormControlLabel
							value="Fiscal Year"
							control={<Radio />}
							label="Fiscal Year End"
						/>
					</RadioGroup>
				</SelectFormControl>
			</Grid>

			<Grid item xs={6} lg={4}>
				<SelectFormControl
					variant="filled"
					error={meta.submitFailed && !!meta.error}
				>
					<InputLabel>Prior Year</InputLabel>
					<Select
						data-cy={`${questionId}-prior-year`}
						value={answer.priorYear}
						onChange={(e) => {
							const data = {
								...answer,
								priorYear: Number(e.target.value),
								currentYear: Number(e.target.value) + 1,
							};
							input.onChange(JSON.stringify(data));
						}}
					>
						{years.map((year) => (
							<MenuItem
								data-cy={`${questionId}-prior-year-${year}`}
								key={year}
								value={year}
							>
								{year}
							</MenuItem>
						))}
					</Select>
				</SelectFormControl>
			</Grid>

			<Grid item xs={6} lg={2}>
				<SelectFormControl
					style={{
						visibility:
							answer.yearType === "Calendar Year" ? "hidden" : "visible",
					}}
					variant="filled"
					error={meta.submitFailed && !!meta.error}
				>
					<InputLabel>Year End</InputLabel>
					<Select
						data-cy={`${questionId}-fye-month`}
						value={answer.fyeMonth ?? ""}
						onChange={(e) => {
							const data = { ...answer, fyeMonth: Number(e.target.value) };
							input.onChange(JSON.stringify(data));
						}}
					>
						{months.map((month) => (
							<MenuItem
								data-cy={`${questionId}-fye-month-${month}`}
								key={month}
								value={month}
							>
								{getMonthName(month)}
							</MenuItem>
						))}
					</Select>
				</SelectFormControl>
			</Grid>
		</CustomGrid>
	);
}
