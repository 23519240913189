import Clamps from "@/components/Clamps";
import { NotificationImportant } from "@mui/icons-material";
import { Box, Chip, IconButton, Popover, Typography } from "@mui/material";
import React from "react";

export default function AssociateSummaryRow({
	transcript,
	ihe,
	iheChanges,
	onSummaryOpen,
}) {
	const changeRef2 = React.useRef(null);
	const [changeOpen, setChangeOpen] = React.useState(false);

	return (
		<Box
			display="grid"
			gridTemplateColumns={`
    auto auto
  `}
			gridTemplateRows={`
    40px 20px auto
  `}
			alignItems="start"
		>
			<Box
				onClick={() => {
					onSummaryOpen(transcript);
				}}
				sx={{
					cursor: "pointer",
					overflow: "hidden",
					fontWeight: (theme) => theme.typography.fontWeightBold,
					"&:hover": {
						textDecoration: "underline",
					},
				}}
				width="100%"
				maxWidth="100%"
			>
				<Clamps maxLines={2}>{transcript.title}</Clamps>
			</Box>

			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="flex-end"
			>
				{iheChanges > 0 && (
					<IconButton
						ref={changeRef2}
						onClick={() => {
							setChangeOpen(true);
						}}
					>
						<NotificationImportant />
					</IconButton>
				)}
			</Box>

			<Typography
				variant="caption"
				sx={{
					alignSelf: "start",
				}}
			>
				{transcript.postDate.format("MM/DD/YYYY")}
			</Typography>

			<Popover
				open={changeOpen}
				anchorEl={changeRef2.current}
				onClose={() => setChangeOpen(false)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<Box padding={1} display="flex" flexDirection="column" gap={1}>
					<Typography variant="caption">Notable IHE Changes</Typography>
					<Box display="flex" flexDirection="row" gap={1} flexWrap="wrap">
						{ihe.map(([trend]) => (
							<Chip
								key={trend}
								label={trend
									.split("_")
									.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
									.join(" ")}
								size="small"
							/>
						))}
					</Box>
				</Box>
			</Popover>
		</Box>
	);
}
