export const flattenData = (objects) => {
	function flatten(node) {
		if (!node.children || node.children.length === 0) {
			return [node];
		}
		const flattened = node.children.filter(
			(child) => child.children && child.children.length > 0,
		);
		if (flattened.length === 0) {
			return [node];
		}
		return [node, ...flattened.flatMap((child) => flatten(child))];
	}

	return objects.flatMap((obj) => flatten(obj));
};
export const primaryFundSort = ["Lead", "", "Participating"];
export const agendaActions = {
	"weekly-ic": [
		{
			link: "agenda",
			key: "agenda",
			label: "Agenda",
		},
		{
			link: "ceo-remarks",
			key: "ceo-remarks",
			label: "CEO Remarks",
			disabled: true,
		},
		{
			link: "requested-investment-approvals",
			key: "requested-investment-approvals",
			label: "IC Approval Requests",
			agendaLabel: "Investment Committee Approval Requests",
		},
		{
			link: "investments-and-opportunities/pre-oa",
			key: "investments-and-opportunities",
			label: "Investments & New Opportunities",
			agendaLabel: "Investments and New Opportunities",
			children: [
				{
					link: "investments-and-opportunities/pre-oa",
					key: "pre-oa",
					agendaLabel: "Pre Opportunity Assessment Backlog",
					label: "Pre OA Backlog",
				},
				{
					link: "investments-and-opportunities/oa-approvals",
					key: "oa-approvals",
					agendaLabel: "Opportunity Assessment Approvals",
					label: "OA Approvals",
				},
				{
					link: "investments-and-opportunities/under-ts",
					key: "under-ts-2",
					label: "Under Term Sheet",
					agendaLabel: "Under Term Sheet",
				},
				{
					link: "investments-and-opportunities/considering-for-ts-review",
					key: "considering-for-ts-review",
					label: "Considering for Term Sheet Review",
					agendaLabel: "Considering for Term Sheet Review",
				},
				{
					link: "investments-and-opportunities/signal-model-fund",
					key: "signal-model-fund",
					label: "Signal Model Fund",
					agendaLabel: "Signal Model Fund",
				},
			],
		},
		{
			link: "following-for-investment",
			key: "following-for-investment",
			label: "Following for Investment",
			agendaLabel: "Following for Investment",
		},
		{
			link: "post-close-actions",
			key: "post-close-actions",
			label: "Post Closing Actions",
			// disabled: true,
		},
		{
			link: "performance-to-plan",
			key: "performance-to-plan",
			label: "P2P Reviews",
			agendaLabel: "Performance To Plan Reviews",
			disabled: true,
		},
		// {
		//   link: "new-investments",
		//   key: "new-investments",
		//   label: "New Investments",
		//   disabled: true,
		//   children: [
		// {
		//   link: "first-meeting",
		//   key: "first-meeting-investment-recommendations",
		//   label: "First Meeting Recommendation Review",
		// },
		//     {
		//       link: "resource-allocation",
		//       key: "resource-allocation",
		//       label: "Resource Allocation / Team Formation",
		//       disabled: true,
		//     },
		//   ],
		// },
		{
			link: "requested-scale-group-approvals/lean-growth",
			key: "requested-scale-group-approvals",
			label: "Scale Group Update",
			agendaLabel: "Scale Group Updates and Approvals",
			children: [
				{
					link: "requested-scale-group-approvals/lean-growth/pipeline",
					key: "requested-scale-group-approvals/lean-growth",
					label: "Lean/Growth",
					agendaLabel: "Lean/Growth",
				},
				{
					link: "requested-scale-group-approvals/rev-gen/portco-update",
					key: "requested-scale-group-approvals/rev-gen",
					label: "Revenue Generation",
					agendaLabel: "Revenue Generation",
				},
				{
					link: "requested-scale-group-approvals/people-ops",
					key: "requested-scale-group-approvals/people-ops",
					label: "Human Capital",
				},
			],
		},
		{
			link: "investment-ihe-review",
			key: "investment-ihe-review",
			label: "IHE Changes",
			agendaLabel: "Portfolio Company Surveys Since Last IC",
		},

		{
			link: "portfolio-financing/liquidity-events",
			key: "portfolio-financing",
			label: "PortCo Financing Activity",
			agendaLabel: "Portfolio Company Financing Activity",
			children: [
				{
					link: "portfolio-financing/liquidity-events",
					key: "liquidity-events",
					label: "Liquidity Events",
				},
				{
					link: "portfolio-financing/pro-entropic-m-a",
					key: "pro-entropic-m-a",
					label: "Pro-Entropic M&A",
				},
				{
					link: "portfolio-financing/fund-raising",
					key: "fund-raising",
					label: "Fundraising (Debt & Equity)",
				},
			],
		},
		{
			link: "ir/active",
			key: "ir",
			label: "Investor Relations",
			children: [
				{
					link: "ir/active",
					key: "valor-fundraising/active",
					label: "Funds - Active",
				},
				{
					link: "ir/in-consideration",
					key: "valor-fundraising/in-consideration",
					label: "Funds - In Consideration",
				},
				{
					link: "ir/upcoming-meetings",
					key: "upcoming-meetings",
					label: "Upcoming LP/AB Meetings",
				},
				{
					link: "ir/lp-consents",
					key: "fund-admin/lp-consents",
					label: "LP/AB Consents",
				},
			],
		},
		{
			link: "fund-management",
			key: "fund-management",
			label: "Fund Management",
			disabled: true,
			children: [
				{
					link: "fund-management/fund-metrics",
					key: "fund-management/fund-metrics",
					label: "Fund Metrics",
				},
				{
					link: "fund-management/pacing",
					key: "fund-management/pacing",
					label: "Pacing / Reserve Analysis",
					disabled: true,
				},
				{
					link: "fund-management/reserve-analysis",
					key: "fund-management/reserve-analysis",
					label: "Portfolio Construction",
					disabled: true,
				},
			],
		},
		{
			link: "smaller-group-discussion",
			key: "smaller-group-discussion",
			label: "Smaller Group Discussion",
			agendaLabel:
				"Smaller Group Discussion (if needed) for any agenda item or ad hoc discussion",
			disabled: true,
		},
		{
			link: "partner-group-discussion",
			key: "partner-group-discussion",
			label: "Partner Group Discussion",
			disabled: true,
			children: [
				{
					link: "management-exception-reporting",
					label: "Management & Exception Reporting",
					disabled: true,
				},
				{
					link: "partner-vp-principal-meeting",
					label: "Partner/Principal/VP/Officer Meeting Action Items",
					disabled: true,
				},
			],
		},
	],
};

export const FundListFilter = {
	"Fund VI": "isGrowthFund6",
	"Fund V": "isGrowthFund5",
	"Fund IV": "isGrowthFund4",
	"Fund III": "isGrowthFund3",
	"VOF I": "isVofFund",
	"CV Consortio": "isCvConsortioFund",
	"Seed I": "isSeedFund1",
	"VSV I": "isVsvFund1",
	"VSV II": "isVsvFund2",
	VAF: "isVafFund1",
};

export const FundList = [
	"Fund VI",
	"Fund V",
	"Fund IV",
	"Fund III",
	"VOF I",
	"CV Consortio",
	"VAF",
	"Seed I",
	"VSV I",
	"VSV II",
	"R&D",
];

export const FundListAffinityKey = {
	"Fund IV": "Fund IV",
	"Fund V": "Fund V",
	"Fund VI": "Fund VI",
	"Fund III": "Fund III",
	VOF: "VOF",
	"CV Consortio": "CV Consortio",
	VAF: "VAF",
	"Seed I": "Seed",
	"VSV I": "VSV I",
	"VSV II": "VSV II",
	"R&D": "R&D",
};

export const GrowthStatuses = {
	TS: "TS",
	TeamAssigned: "Team Assigned",
	Considering: "Considering",
	Won: "Won",
};

export const VSVStatuses = {
	TS: "TS Executed",
	TeamAssigned: "Team Assigned",
	Considering: "Considering for TS",
	Won: "Won",
};

export const FundListStatus = {
	"Fund IV": GrowthStatuses,
	"Fund V": GrowthStatuses,
	"Fund VI": GrowthStatuses,
	VOF: GrowthStatuses,
	VAF: GrowthStatuses,
	"CV Consortio": GrowthStatuses,
	"Seed I": GrowthStatuses,
	"VSV I": VSVStatuses,
	"VSV II": VSVStatuses,
	"R&D": GrowthStatuses,
};

export default agendaActions;
