import { listModels } from "@/api/Chat"; // Assuming this is your API function
import {
	Alert,
	Box,
	CircularProgress,
	Container,
	Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import ModelCard from "./ModelCard";

const ModelsList = () => {
	const { data: models, isLoading, error } = useQuery("models", listModels);

	if (isLoading) {
		return (
			<Box display="flex" justifyContent="center" m={4}>
				<CircularProgress />
			</Box>
		);
	}

	if (error) {
		return (
			<Box m={4}>
				<Alert severity="error">
					Error loading models: {(error as Error).message}
				</Alert>
			</Box>
		);
	}

	return (
		<Container maxWidth="md">
			<Typography variant="h4" component="h1" gutterBottom sx={{ my: 4 }}>
				Models
			</Typography>

			{models?.length === 0 ? (
				<Typography color="text.secondary">No models found.</Typography>
			) : (
				<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
					{models?.map((model) => (
						<ModelCard
							key={model.id}
							id={model.id}
							name={model.name}
							version={model.version}
							provider={model.provider}
							parameters={model.parameters}
						/>
					))}
				</Box>
			)}
		</Container>
	);
};

export default ModelsList;
