import {
	getInternalFiles,
	getMissingMetrics,
	updateInternalFileStatus,
} from "@/api/Metrics";
import {
	VirtualTableBody,
	VirtualTableContainer,
	depageData,
	getNextPageParam,
	mapFilters,
	mapSorting,
} from "@/components/InfiniteTable";
import {
	Cell as HeaderCell,
	Toolbar as TableToolbar,
} from "@/components/Table/Header";
import { CompanyCell, TextCell } from "@/components/Table/Primitives";
import UserCell from "@/components/Table/Primitives/UserCell";
import { StickyTable, StickyTableHead } from "@/components/Table/StickyTable";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import Page from "@/ui/molecules/Page";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
	Box,
	Breadcrumbs,
	Button,
	Card,
	Chip,
	Link,
	Paper,
	TableRow,
	Typography,
	useMediaQuery,
} from "@mui/material";
import {
	type ColumnFiltersState,
	type SortingState,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import React, { useMemo, useState, useCallback } from "react";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { Link as RouterLink } from "react-router-dom";

export default function InternalMetricFiles() {
	const limit = 25;
	const [sorting, setSorting] = useState<SortingState>([]);
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const [globalFilter, setGlobalFilter] = useState<string | null>("");
	const [columnVisibility, setColumnVisibility] = useState({});

	const pagedQueryKey = useMemo(
		() => ["metrics", "internalFiles", sorting, columnFilters, globalFilter],
		[sorting, columnFilters, globalFilter],
	);
	const {
		data: pagedFileData,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		status: queryStatus,
	} = useInfiniteQuery({
		queryKey: pagedQueryKey,
		queryFn: ({ pageParam = null }) =>
			getInternalFiles(
				limit,
				pageParam,
				mapSorting(sorting),
				mapFilters(columnFilters, globalFilter),
			),
		getNextPageParam,
	});

	const isLoading = queryStatus === "loading";
	const isError = queryStatus === "error";

	const { data: missingMetrics } = useQuery(
		["metrics", "missingMetrics"],
		async () =>
			(await getMissingMetrics()).reduce(
				(acc, curr) => ({
					...acc,
					[curr.valorId]: curr.missingMetrics,
				}),
				{},
			),
		{
			refetchInterval: 0,
			refetchOnWindowFocus: false,
		},
	);

	const queryClient = useQueryClient();

	const removeFromFileList = useCallback(
		(fileId) => {
			queryClient.setQueryData(pagedQueryKey, (oldData) => {
				// find the page the item is in
				const pageIndex = oldData.pages.findIndex((page) =>
					page.data.some((item) => item.file.id === fileId),
				);
				if (pageIndex === -1) {
					return oldData;
				}
				// remove the item from the page
				const page = oldData.pages[pageIndex];
				const newPage = {
					...page,
					data: page.data.filter((item) => item.file.id !== fileId),
				};
				// replace the page in the array
				const newData = [
					...oldData.pages.slice(0, pageIndex),
					newPage,
					...oldData.pages.slice(pageIndex + 1),
				];
				return {
					...oldData,
					pages: newData,
				};
			});
		},
		[pagedQueryKey, queryClient],
	);

	const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const pagedColumns = useMemo(
		() => [
			{
				header: "Company",
				accessorFn: (row) => ({
					valorId: row.valorId,
					name: row.name,
					domain: row.domain,
					logoUrl: row.logoUrl,
				}),
				id: "organization.name",
				cell: (info) => <CompanyCell value={info.getValue()} />,
				minSize: isSmDown ? 48 : 250,
				size: isSmDown ? 48 : 250,
			},
			{
				header: "File",
				accessorKey: "file.filename",
				id: "file.name",
				cell: (info) => <TextCell value={info.getValue()} />,
				minSize: 200,
			},
			{
				header: "Doc Type",
				accessorKey: "file.docType",
				id: "file.docType",
				minSize: 200,
			},
			{
				header: "MSL",
				accessorKey: "monitoringServiceLead",
				id: "organization.msl",
				cell: (info) => <UserCell value={info.getValue()} />,
			},
			{
				header: "Possible Missing Metrics",
				accessorKey: "metrics",
				enableColumnFilter: false,
				enableSorting: false,
				cell: function MissingMetricCell(info) {
					const value = info.getValue();
					const [mouseOvered, setMouseOvered] = useState(false);
					if (!value) {
						return null;
					}
					return (
						<Box
							maxHeight={50}
							overflow={mouseOvered ? "visible" : "hidden"}
							onClick={() => {
								setMouseOvered(!mouseOvered);
							}}
							onMouseOver={() => {
								setMouseOvered(true);
							}}
							onMouseOut={() => {
								setMouseOvered(false);
							}}
							position="relative"
						>
							<Box display="flex" flexDirection="row" flexWrap="wrap" gap={0.5}>
								{value.map((metric) => (
									<Chip key={metric} label={metric} size="small" />
								))}
							</Box>
							<Box
								display="flex"
								flexDirection="row"
								flexWrap="wrap"
								gap={0.5}
								position="absolute"
								zIndex={mouseOvered ? 10 : 9}
								backgroundColor="background.paper"
								top={0}
								left={0}
								padding={1}
								margin={-1}
								boxShadow={mouseOvered ? 2 : 0}
								visibility={
									mouseOvered && value.length > 2 ? "visible" : "hidden"
								}
							>
								{value.map((metric) => (
									<Chip key={metric} label={metric} size="small" />
								))}
							</Box>
						</Box>
					);
				},
			},
			{
				header: "Actions",
				id: "actions",
				enableColumnFilter: false,
				enableSorting: false,
				cell: function ActionsCell({ row }) {
					const [isUpdating, setIsUpdating] = useState(false);
					return (
						<Box display="flex" flexDirection="row" gap={1}>
							<Button
								variant="outlined"
								size="small"
								startIcon={<BlockIcon />}
								color="error"
								disabled={isUpdating}
								onClick={async () => {
									setIsUpdating(true);
									try {
										await updateInternalFileStatus(row.original.file.id, {
											status: "IGNORED",
										});
										removeFromFileList(row.original.file.id);
									} catch (e) {
										console.error(e);
									}
									setIsUpdating(false);
								}}
							>
								Ignore
							</Button>
							<Button
								variant="outlined"
								size="small"
								startIcon={<CheckCircleIcon />}
								color="success"
								disabled={isUpdating}
								onClick={async () => {
									setIsUpdating(true);
									try {
										await updateInternalFileStatus(row.original.file.id, {
											status: "COMPLETE",
										});
										removeFromFileList(row.original.file.id);
									} catch (e) {
										console.error(e);
									}
									setIsUpdating(false);
								}}
							>
								Complete
							</Button>
						</Box>
					);
				},
				minSize: 240,
			},
		],
		[removeFromFileList, isSmDown],
	);

	const pageTitle = () => (
		<>
			<Breadcrumbs>
				<Link component={RouterLink} color="inherit" to="/metric-tasks">
					Metric Tasks
				</Link>
				<Typography>Internal Metric Files</Typography>
			</Breadcrumbs>
			<Page.Title>Internal Metric Files</Page.Title>
		</>
	);

	const pages = useMemo(() => depageData(pagedFileData), [pagedFileData]);

	// convert to table
	const tableData = useMemo(() => {
		if (!pages) {
			return [];
		}
		return pages.map((record) => ({
			...record,
			metrics: missingMetrics[record.valorId],
		}));
	}, [pages, missingMetrics]);

	const table = useReactTable({
		data: tableData,
		columns: pagedColumns,
		state: {
			sorting,
		},
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
	});

	if (isError) {
		return (
			<Page>
				<Breadcrumbs>
					<Link component={RouterLink} color="inherit" to="/metric-tasks">
						Metric Tasks
					</Link>
					<Typography>Activity Feed</Typography>
				</Breadcrumbs>
				<Page.Title>Activity Feed</Page.Title>
				<Page.Content>
					<Paper sx={{ padding: 2 }}>
						<Box>
							<Typography variant="body1">
								Error loading internal files.
							</Typography>
						</Box>
					</Paper>
				</Page.Content>
			</Page>
		);
	}

	return (
		<Page>
			{pageTitle()}
			<Page.Content>
				<Card
					sx={{
						maxHeight: "calc(100vh - 200px)",
						overflow: "hidden",
					}}
				>
					{(isLoading || isFetchingNextPage) && (
						<CenteredProgress
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
						/>
					)}

					<TableToolbar
						columnHeaders={pagedColumns}
						globalFilter={globalFilter}
						columnVisibility={columnVisibility}
						sorting={sorting}
						columnFilters={columnFilters}
						onRemoveColumnFilters={() => {
							setColumnFilters([]);
						}}
						onRemoveSorting={() => {
							setSorting([]);
						}}
						onRemoveVisibility={() => {
							setColumnVisibility({});
						}}
						onGlobalFilterChange={(value) => {
							setGlobalFilter(value);
						}}
					/>

					<VirtualTableContainer
						onScrollBottomReached={() => {
							if (!isFetching && hasNextPage) {
								fetchNextPage?.();
							}
						}}
					>
						<StickyTable centerTotalSize={table.getCenterTotalSize()}>
							<StickyTableHead>
								{table.getHeaderGroups().map((headerGroup) => (
									<TableRow key={headerGroup.id}>
										{headerGroup.headers.map((header) => (
											<HeaderCell
												key={header.id}
												header={header}
												column={header.column}
												table={table}
											/>
										))}
									</TableRow>
								))}
							</StickyTableHead>
							<VirtualTableBody
								rows={table.getRowModel().rows}
								estimateSize={60}
								table={table}
							/>
						</StickyTable>
					</VirtualTableContainer>
				</Card>
			</Page.Content>
		</Page>
	);
}
