import { Typography } from "@mui/material";
import highlightWords from "highlight-words";
import React from "react";

export default function renderTextWithHighlighting(text, queryText) {
	if (!text || !queryText) {
		return <Typography component="span">{text}</Typography>;
	}

	const chunks = highlightWords({
		text,
		query: queryText,
	});

	return chunks.map(({ key, text: textChunk, match }) => (
		<Typography
			key={key}
			component="span"
			style={{ fontWeight: match ? "700" : "300" }}
		>
			{textChunk}
		</Typography>
	));
}
