import type { TaskList } from "@/api/Process";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Chip, Popover, Typography } from "@mui/material";
import React from "react";

type HiddenTasksInfoProps = {
	currentGroup: string;
	fields: TaskList["fields"];
	fieldFilters: TaskList["fieldFilters"];
	entityName?: string;
};

export default function HiddenTasksInfo({
	currentGroup,
	fields,
	fieldFilters,
	entityName,
}: HiddenTasksInfoProps) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const hasHiddenFields = fieldFilters?.some(
		({ subjectId }) => subjectId === currentGroup,
	);
	const printableFields = fieldFilters
		.filter(({ subjectId }) => subjectId === currentGroup)
		.map(({ objectId, value: filterValue, operator }) => {
			const field = fields.find(({ id }) => id === objectId);

			const operatorGrammar = {
				EQUAL: " is ",
				NOT_EQUAL: " is not ",
				GREATER_THAN: " is greater than ",
				GREATER_THAN_OR_EQUAL: " is greater than or equal to ",
				LESS_THAN: " is less than ",
				LESS_THAN_OR_EQUAL: " is less than or equal to ",
			};

			const value =
				field.type === "select"
					? field.choices.find(({ id }) => id === filterValue)?.value
					: filterValue;

			return {
				name: field?.name,
				operator: operatorGrammar[operator],
				value,
			};
		});
	const uniqueFields = [...new Set(printableFields.map(({ name }) => name))];

	return hasHiddenFields ? (
		<>
			<Box
				height={40}
				width="100%"
				display="flex"
				flexDirection="row"
				justifyContent="flex-end"
			>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					gap={1}
					aria-owns={open ? "mouse-over-popover" : undefined}
					aria-haspopup="true"
					onMouseEnter={handlePopoverOpen}
				>
					<Typography variant="body2">
						Can&apos;t see a {entityName?.toLowerCase() || "task"}?
					</Typography>
					<InfoIcon />
				</Box>
			</Box>
			<Popover
				id="mouse-over-popover"
				sx={{
					padding: 2,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Box padding={2}>
					<Typography variant="body2">
						The following tasks are hidden for this group if they meet the
						following conditions:
					</Typography>
					<Box
						display="flex"
						flexDirection="row"
						flexWrap="wrap"
						gap={1}
						my={1}
					>
						{printableFields.map(({ name, operator, value }) => (
							<Chip
								key={name + operator + value}
								label={`${name}${operator}${value}`}
							/>
						))}
					</Box>
					<Typography variant="body2">
						{`Change the "Group Tasks By" to view tasks by ${uniqueFields.join(" or ")} if you want to look for hidden tasks.`}
					</Typography>
				</Box>
			</Popover>
		</>
	) : null;
}
