import type { GetEventsResponse, TaskEnum } from "@/api/Tasks";
import useProcess from "@/pages/ProcessManagement/ProcessContext/useProcess";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import UserAvatar from "@/ui/atoms/UserAvatar";
import Page from "@/ui/molecules/Page";
import {
	Box,
	Button,
	Card,
	CardContent,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	styled,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";
import { Link } from "react-router-dom";
import { taskTypePrettyName } from "./utils";

type TaskResponse = GetEventsResponse[0];

interface ITaskActionsProps {
	taskType: TaskEnum;
	processId: string;
	taskId: number;
	valorId: string;
	navigate: (url: string) => void;
}

interface IProcessTaskLinkProps {
	id: TaskResponse["task"]["id"];
	processId: TaskResponse["task"]["processId"];
	name: string;
	dueDate: TaskResponse["dueDate"];
	targetDate?: TaskResponse["task"]["targetCloseDate"];
}

interface IOrganizationDisplayProps {
	organization: TaskResponse["organization"];
}

interface IUsersDisplayProps {
	users: TaskResponse["assignees"] | TaskResponse["ccs"];
	label: string;
}

interface IEventCardProps {
	event: TaskResponse;
}

dayjs.extend(relativeTime);

export const MTableHead = styled(TableHead)(({ theme }) => ({
	borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const MTableCell = styled(TableCell)(({ theme }) => ({
	textAlign: "center",
	padding: theme.spacing(0.75, 0.25),
	overflowWrap: "break-word",
	[theme.breakpoints.down("sm")]: {
		padding: theme.spacing(0.5, 0.125),
	},
}));

export const MHeaderCell = styled(TableCell)(({ theme }) => ({
	textAlign: "center",
	padding: theme.spacing(0.75, 0.25),
	overflowWrap: "break-word",
	[theme.breakpoints.down("sm")]: {
		padding: theme.spacing(0.5, 0.125),
	},
}));

export const MTableBody = styled(TableBody)(({ theme }) => ({
	"& > tr:nth-of-type(odd)": {
		background: theme.palette.background.paper,
	},
	"& > tr:nth-of-type(even)": {
		background: theme.palette.background.oddTableRow,
	},
}));

export function TaskActions({
	taskType,
	processId,
	taskId,
	valorId,
	navigate,
}: ITaskActionsProps) {
	const orgSurveyUrl = `org/${valorId}/summary?surveyDrawerOpen=1`;
	const orgFinancials = `org/${valorId}/financials`;
	const taskUrl = `/process-management/${processId}/tasks/${processId}-${taskId}?view=table`;
	const portfolio = "/process-management/portfolio?view=table";

	const { data: processData } = useProcess(processId);

	const entityName = processData?.settings.entityName;

	const surveyTasks = [
		"p2p_month_1_missing",
		"p2p_month_6_missing",
		"oa_missing",
		"os_missing",
		"leangrowth_active_engagement",
		"vsvrevops_active_engagement_s",
	];

	const actionTypes = {
		viewPortfolio: (
			<Button
				onClick={() => navigate(portfolio)}
				variant="contained"
				color="primary"
			>
				Portfolio
			</Button>
		),
		viewPortco: (
			<Button
				onClick={() => navigate(taskUrl)}
				variant="contained"
				color="primary"
			>
				View PortCo
			</Button>
		),
		linkSurvey: (
			<>
				<Button
					onClick={() => navigate(taskUrl)}
					variant="contained"
					color="primary"
				>
					{entityName || "Task"}
				</Button>
				<Button
					onClick={() => navigate(orgSurveyUrl)}
					variant="outlined"
					color="secondary"
				>
					Survey
				</Button>
			</>
		),
		takeSurvey: (
			<Button
				onClick={() => navigate(orgSurveyUrl)}
				variant="contained"
				color="primary"
			>
				Survey
			</Button>
		),
		viewFinancials: (
			<Button
				onClick={() => navigate(orgFinancials)}
				variant="contained"
				color="primary"
			>
				Financials
			</Button>
		),
	};
	if (surveyTasks.includes(taskType)) {
		return actionTypes.linkSurvey;
	}
	if (
		taskType === "financial_metrics_setup_needed" ||
		taskType === "financial_metrics_setup_but_no_files"
	) {
		return actionTypes.viewFinancials;
	}
	if (["partner_signoff_on_portco_required"].includes(taskType)) {
		return actionTypes.viewPortco;
	}
	if (
		taskType === "quarterly_assessment" ||
		taskType === "portco_setup_required" ||
		taskType === "additional_capital"
	) {
		return actionTypes.viewPortfolio;
	}
	return actionTypes.takeSurvey;
}

export function ProcessTaskLink({
	id,
	processId,
	name,
	dueDate,
	targetDate,
}: IProcessTaskLinkProps) {
	const url = `/process-management/${processId}/tasks/${processId}-${id}?view=table`;
	return (
		<Stack>
			<Link
				to={url}
				style={{
					textDecoration: "none",
					color: "inherit",
				}}
				target="_blank"
			>
				<Typography
					variant="body2"
					sx={{ "&:hover": { textDecoration: "underline" } }}
				>
					{name}
				</Typography>
			</Link>
			<Typography variant="caption" color="text.secondary">
				{targetDate
					? `Expected ${dayjs(dueDate).utc().format("MM-DD-YYYY")} | Target Close ${dayjs(targetDate).utc().format("MM-DD-YYYY")}`
					: `Expected ${dayjs(dueDate).utc().format("MM-DD-YYYY")}`}
			</Typography>
		</Stack>
	);
}

export function OrganizationDisplay({
	organization,
}: IOrganizationDisplayProps) {
	return (
		<Stack
			direction="row"
			spacing={2}
			alignItems="center"
			onClick={() => window.open(`/org/${organization.valorId}`, "_blank")}
		>
			<CompanyAvatar
				name={organization.name}
				domain={organization.domain}
				src={organization.logoUrl}
				size="40"
			/>
			<Box>
				<Typography
					variant="subtitle1"
					color="text.primary"
					sx={{ textAlign: "left" }}
				>
					{organization.name}
				</Typography>
				<Typography
					variant="body2"
					color="text.secondary"
					sx={{ textAlign: "left" }}
				>
					{organization.domain}
				</Typography>
			</Box>
		</Stack>
	);
}

export function UsersDisplay({ users, label }: IUsersDisplayProps) {
	return (
		<Stack direction="row" spacing={1} alignItems="center">
			<Typography variant="body2" color="text.secondary">
				{label}
			</Typography>
			<Stack direction="row" spacing={-1}>
				{users
					?.sort((a, b) => a.lastName.localeCompare(b.lastName))
					?.map((user) => (
						<UserAvatar
							key={user.id}
							user={user}
							style={{ width: "24px", height: "24px" }}
							displayTooltip
						/>
					))}
			</Stack>
		</Stack>
	);
}

export function EventCard({ event }: IEventCardProps) {
	return (
		<Card sx={{ mb: 2 }}>
			<CardContent>
				<Stack spacing={2}>
					<OrganizationDisplay organization={event.organization} />
					<ProcessTaskLink
						id={event.task.id}
						processId={event.task.processId}
						name={
							event.task.processId === "deals"
								? `${event.task.name}${event.task.targetFund ? ` - ${event.task.targetFund}` : ""}`
								: ["vsv-revops", "leangrowth", "rev-gen"].includes(
											event.task.processId,
										)
									? event.task.name
									: event.organization.name
						}
						targetDate={event.task.targetCloseDate}
						dueDate={event.dueDate}
					/>
					<Typography variant="h4" color="text.primary">
						{taskTypePrettyName[event.taskType] ?? event.taskType}
					</Typography>
					<Box>
						<UsersDisplay users={event.assignees} label="Assignee(s)" />
						<UsersDisplay users={event.ccs} label="CC" />
					</Box>
					<Typography variant="body2" color="text.secondary">
						Due {dayjs(event.dueDate).fromNow()}
					</Typography>
					<TaskActions
						taskType={event.taskType}
						processId={event.task.processId}
						taskId={event.task.id}
						valorId={event.organization.valorId}
						navigate={(u) => window.open(u, "_blank")}
					/>
				</Stack>
			</CardContent>
		</Card>
	);
}

export function SkeletonLoader({
	isMobile,
	canCurrentUserSeeAllEvents,
}: {
	canCurrentUserSeeAllEvents: boolean;
	isMobile: boolean;
}) {
	return (
		<Page>
			<Page.Content>
				<Box
					sx={{
						display: "flex",
						flexDirection: {
							xs: "column",
							md: "row",
						},
						justifyContent: {
							xs: "flex-start",
							md: "space-between",
						},
						mb: 1,
						gap: "8px",
					}}
				>
					<Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
						<Skeleton variant="rectangular" width={400} height={40} />
						<Skeleton variant="rectangular" width={400} height={40} />
						{canCurrentUserSeeAllEvents && (
							<Skeleton variant="rectangular" width={400} height={40} />
						)}
					</Box>
					<Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
						<Skeleton variant="rectangular" width={220} height={56} />
						<Skeleton variant="rectangular" width={220} height={56} />
					</Box>
				</Box>
				{isMobile ? (
					<Box sx={{ p: 2 }}>
						{[...Array(4)].map((_, index) => (
							<Card key={index} sx={{ mb: 2 }}>
								<CardContent>
									<Stack spacing={2}>
										<Skeleton variant="rectangular" width="100%" height={40} />
										<Skeleton variant="rectangular" width="100%" height={20} />
										<Skeleton variant="rectangular" width="100%" height={20} />
										<Skeleton variant="rectangular" width="100%" height={20} />
										<Skeleton variant="rectangular" width="100%" height={20} />
										<Skeleton variant="rectangular" width="100%" height={40} />
									</Stack>
								</CardContent>
							</Card>
						))}
					</Box>
				) : (
					<Card>
						<Table>
							<MTableHead>
								<TableRow>
									<MHeaderCell>
										<Skeleton variant="rectangular" width="100%" height={20} />
									</MHeaderCell>
									<MHeaderCell>
										<Skeleton variant="rectangular" width="100%" height={20} />
									</MHeaderCell>
									<MHeaderCell>
										<Skeleton variant="rectangular" width="100%" height={20} />
									</MHeaderCell>
									<MHeaderCell>
										<Skeleton variant="rectangular" width="100%" height={20} />
									</MHeaderCell>
									<MHeaderCell>
										<Skeleton variant="rectangular" width="100%" height={20} />
									</MHeaderCell>
								</TableRow>
							</MTableHead>
							<MTableBody>
								{[...Array(6)].map((_, index) => (
									<TableRow key={index} sx={{ height: "96px" }}>
										<MTableCell>
											<Skeleton
												variant="rectangular"
												width="100%"
												height={40}
											/>
										</MTableCell>
										<MTableCell>
											<Skeleton
												variant="rectangular"
												width="100%"
												height={40}
											/>
										</MTableCell>
										<MTableCell>
											<Skeleton
												variant="rectangular"
												width="100%"
												height={40}
											/>
										</MTableCell>
										<MTableCell>
											<Skeleton
												variant="rectangular"
												width="100%"
												height={40}
											/>
										</MTableCell>
										<MTableCell>
											<Skeleton
												variant="rectangular"
												width="100%"
												height={40}
											/>
										</MTableCell>
									</TableRow>
								))}
							</MTableBody>
						</Table>
					</Card>
				)}
			</Page.Content>
		</Page>
	);
}
