import { MultiPersonCell, MultiUserCell } from "@/components/Table/Primitives";
import BooleanCell from "@/components/Table/Primitives/BooleanCell";
import ChipCell from "@/components/Table/Primitives/ChipCell";
import CompanyCell from "@/components/Table/Primitives/CompanyCell";
import DateCell from "@/components/Table/Primitives/DateCell";
import DateTimeCell from "@/components/Table/Primitives/DateTimeCell";
import MoneyCell from "@/components/Table/Primitives/MoneyCell";
import PersonCell from "@/components/Table/Primitives/PersonCell";
import ProbabilisticEquityValueCell from "@/components/Table/Primitives/ProbablisticEquityValueCell";
import RYGCell from "@/components/Table/Primitives/RYGCell";
import TextCell from "@/components/Table/Primitives/TextCell";
import UserCell from "@/components/Table/Primitives/UserCell";
import { Box, Skeleton } from "@mui/material";
import React from "react";
import CommentCell from "./CommentCell";
import NoteCell from "./NoteCell";

export default function Cell({
	processId,
	taskId,
	primaryCompanyValorId,
	field,
	value,
	isLoading,
}) {
	if (isLoading && !value) {
		return <Skeleton variant="rounded" width="100" height="30" />;
	}

	const { type } = field;
	if (type === "company") {
		return <CompanyCell value={value.company} shrink />;
	}

	if (type === "date" || type === "target_date") {
		return <DateCell value={value.dateValue} format="MM/DD/YYYY" />;
	}

	if (type === "date_time") {
		return <DateTimeCell value={value.dateTimeValue || value.dateValue} />;
	}

	if (type === "select") {
		// get choice from field
		return <ChipCell value={value ? [value] : null} />;
	}

	if (type === "select_multi") {
		return <ChipCell value={value} />;
	}

	if (type === "user") {
		return <UserCell value={value.user} />;
	}

	if (type === "user_multi") {
		return <MultiUserCell value={value.users} showLabel={false} />;
	}

	if (type === "checkbox") {
		return <BooleanCell value={value.value} />;
	}

	if (type === "text" || type === "text_multi") {
		return <TextCell value={value.value} />;
	}

	if (type === "most_recent_comment") {
		return <CommentCell processId={processId} taskId={taskId} />;
	}

	if (type === "probability_equity_value_json") {
		return <ProbabilisticEquityValueCell value={value.jsonValue} />;
	}

	if (type === "number") {
		return (
			<TextCell
				value={
					value.numericValue !== null && value.numericValue !== undefined
						? Number.parseFloat(value.numericValue).toLocaleString()
						: null
				}
			/>
		);
	}

	if (type === "dollar") {
		return (
			<MoneyCell
				isFundingRound
				value={
					value.numericValue !== null && value.numericValue !== undefined
						? value.numericValue
						: null
				}
			/>
		);
	}

	if (type === "person") {
		return <PersonCell value={value.person} />;
	}

	if (type === "person_multi") {
		return <MultiPersonCell value={value.persons} showLabel={false} />;
	}

	if (type === "latest_note") {
		return <NoteCell processId={processId} taskId={taskId} />;
	}

	if (type === "latest_org_note") {
		return <NoteCell valorId={primaryCompanyValorId} />;
	}

	if (type === "ryg") {
		return <RYGCell value={value.value} />;
	}

	return (
		<Box>
			{type}:{value.value}
		</Box>
	);
}
