import type { RichText } from "../types/RichText";

import { serialize } from "./JSX";
import Plain from "./PlainSerializer";

export default {
	toJSX: (richText: RichText, readonly: boolean): JSX.Element[] =>
		serialize(richText, readonly),
	toString: (richText: RichText): string => Plain.serialize(richText),
	toHTMLString: (): string => "",
	toMarkdown: (): string => "",
	fromMarkdown: (): RichText => Plain.deserialize(""),
	fromString: (plainText: string): RichText => Plain.deserialize(plainText),
};
