import renderElement from "../Serializer/JSX/renderElement";
import type { AdvancedDescendant } from "../types/RichText";

type ElementProps = {
	attributes: JSX.IntrinsicAttributes;
	children: JSX.Element;
	element: AdvancedDescendant;
};

export default function Element({
	attributes,
	children,
	element,
}: ElementProps) {
	return renderElement(element, attributes, children);
}
