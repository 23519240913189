import { Box, useTheme } from "@mui/material";
import React from "react";
import {
	Bar,
	BarChart as BarReChart,
	CartesianGrid,
	Label,
	ReferenceLine,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

import type { SignalDistribution } from "@/api/SignalFeatures";
import { CustomTooltip, CustomXTick, CustomYTick } from "./ChartUtils";
import { ValueTransformer } from "./Utils";

export default function BarChart({
	data,
}: {
	data: SignalDistribution["data"];
}) {
	const theme = useTheme();
	if (!data) return null;

	let { distribution } = data;

	distribution = distribution.map((d) => ({
		...d,
		color:
			d.x === data.value
				? theme.palette.error.main
				: theme.palette.primary.main,
	}));

	const renderBar = (props: {
		x: number;
		y: number;
		width: number;
		height: number;
		payload: {
			color: string;
		};
	}) => {
		const { x, y, width, height, payload } = props;
		if (height < 0) {
			return (
				<g>
					<rect
						x={x}
						y={y + height}
						width={width}
						height={-height}
						fill={payload.color || theme.palette.primary.main}
					/>
				</g>
			);
		}

		return (
			<g>
				<rect
					x={x}
					y={y}
					width={width}
					height={height}
					fill={payload.color || theme.palette.primary.main}
				/>
			</g>
		);
	};

	return (
		<Box height={300} margin={2}>
			<BarReChart
				width={500}
				height={300}
				data={distribution.map((d) => ({
					x: d.x,
					y: d.y,
					color: d.color,
				}))}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<Tooltip
					content={({ active, payload, label, formatter, labelFormatter }) => (
						<CustomTooltip
							payload={payload}
							label={label}
							active={active}
							formatter={formatter}
							labelFormatter={labelFormatter}
						/>
					)}
					formatter={(value) => [
						Math.floor(value * 100) / 100,
						"Avg. Prediction",
					]}
					labelFormatter={
						ValueTransformer[data.units] || ValueTransformer.default
					}
				/>
				<XAxis
					dataKey="x"
					type="category"
					tick={(props) => (
						<CustomXTick
							x={props.x}
							y={props.y}
							payload={props.payload}
							tickFormatter={
								ValueTransformer[data.units] || ValueTransformer.default
							}
						/>
					)}
					tickFormatter={
						ValueTransformer[data.units] || ValueTransformer.default
					}
				>
					{data?.chartArgs?.xtitle && (
						<Label
							value={data.chartArgs.xtitle}
							fill={theme.palette.text.primary}
							offset={0}
							position="insideBottom"
						/>
					)}
				</XAxis>
				<YAxis
					dataKey="y"
					tick={(props) => (
						<CustomYTick
							x={props.x}
							y={props.y}
							payload={props.payload}
							tickFormatter={
								ValueTransformer[data.units] || ValueTransformer.default
							}
						/>
					)}
				>
					<Label
						value={data?.chartArgs?.ytitle || "Frequency"}
						fill={theme.palette.text.primary}
						angle={-90}
						position="insideLeft"
					/>
				</YAxis>
				<Bar dataKey="y" shape={renderBar} />
				<ReferenceLine y={0} stroke="#000" />
			</BarReChart>
		</Box>
	);
}
