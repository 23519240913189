import type { paths } from "@/api/index";
import axios from "axios";

type getXInternalId = paths["/api/x/username/{username}/internal-id"]["get"];
export type getXInternalIdResponses = getXInternalId["responses"];
export type getXInternalIdResult =
	getXInternalIdResponses["200"]["content"]["application/json"];

export const getXInternalId = async (
	username: string,
): Promise<getXInternalIdResult> => {
	const response = await axios.get<getXInternalIdResult>(
		`/api/x/username/${username}/internal-id`,
	);
	return response.data;
};
