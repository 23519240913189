import { saytForOrg } from "@/api/Search";
import {
	acknowledgeFolderAndAllChildren,
	getDocTypes,
	getUnmappedFolders,
	getUntaggedFiles,
	ignoreFile,
	ignoreFolder,
	postNote,
} from "@/api/Verity";
import { useAuth } from "@/hooks/useAuth";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import SingleSelect from "@/ui/atoms/SingleSelect";
import DateRangeFilter from "@/ui/atoms/TableFilters/DateRangeFilter";
import CustomTable from "@/ui/molecules/CustomTable";
import Page from "@/ui/molecules/Page";
import SearchBar from "@/ui/molecules/SearchBar";
import { Typography } from "@material-ui/core";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { format } from "date-fns";
import dayjs from "dayjs";
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useQuery } from "react-query";

function VerityPage() {
	const { user } = useAuth();
	const SEVEN_DAYS_AGO = dayjs().subtract(7, "day");

	const [fetchFilter, setFetchFilter] = useState(SEVEN_DAYS_AGO);

	const formattedDateFilter = fetchFilter.format("YYYY-MM-DD");

	const {
		data: unmappedFolders = [],
		isLoading: isLoadingFolders,
		refetch: refetchFolders,
	} = useQuery(["UnmappedFolders", formattedDateFilter], async () =>
		getUnmappedFolders(formattedDateFilter),
	);
	const {
		data: untaggedFiles = [],
		isLoading: isLoadingFiles,
		refetch: refetchFiles,
	} = useQuery(["UntaggedFiles", formattedDateFilter], async () =>
		getUntaggedFiles(formattedDateFilter),
	);
	const [dialogProps, setDialogProps] = useState({
		open: false,
		id: null,
		isFile: false,
	});

	const { data: docTypes = [] } = useQuery(["DocTypes"], getDocTypes, {
		staleTime: Number.POSITIVE_INFINITY,
	});

	const [selectedFolders] = useState({});
	const [selectedFiles] = useState({});

	const foldersColumns = React.useMemo(
		() => [
			{
				Header: "Folder Path",
				accessor: "path",
			},
			{
				Header: "Created Date",
				accessor: (row) => row.createdDatetime && new Date(row.createdDatetime),
				filter: "dateRange",
				sortType: "date",
				Filter: ({ column }) => (
					<DateRangeFilter
						filterValue={column.filterValue}
						setFilter={column.setFilter}
					/>
				),
				Cell: ({ value: createdDatetime }) =>
					format(createdDatetime, "MMM dd, yyyy"),
			},
			{
				Header: "Company",
				Cell: ({
					row: {
						original: { id },
					},
				}) => {
					const selectedCompany = selectedFolders[`${id}-company`];
					return (
						<Button
							style={{ minWidth: "150px" }}
							variant="outlined"
							color="primary"
							onClick={() =>
								setDialogProps({
									open: true,
									id,
									isFile: false,
								})
							}
						>
							{selectedCompany || "Pick"}
						</Button>
					);
				},
				disableFilters: true,
				disableSortBy: true,
			},
			{
				Header: "Action",
				Cell: ({
					row: {
						original: { id, path, valorId },
					},
				}) => (
					<Button
						variant="outlined"
						color="primary"
						onClick={async () => {
							const newValorId = selectedFolders[`${id}-valor-id`] || valorId;

							if (newValorId) {
								await acknowledgeFolderAndAllChildren(id, newValorId);
								refetchFolders();
								refetchFiles();
							} else {
								alert(`Please select a company for folder '${path}'`);
							}
						}}
					>
						Done
					</Button>
				),

				disableFilters: true,
				disableSortBy: true,
			},
			{
				Header: "Ignore",
				Cell: ({
					row: {
						original: { id, valorId },
					},
				}) => (
					<Button
						variant="outlined"
						color="primary"
						onClick={async () => {
							await ignoreFolder({
								id,
								excluded: `Excluded by ${user.firstName} ${user.lastName}`,
								valor_id: selectedFolders[`${id}-valor-id`] || valorId,
							});
							refetchFolders();
						}}
					>
						Ignore
					</Button>
				),
				disableFilters: true,
				disableSortBy: true,
			},
		],
		[docTypes, unmappedFolders],
	);

	const filesColumns = React.useMemo(
		() => [
			{
				Header: "File Path",
				accessor: "path",
			},
			{
				Header: "File Name",
				accessor: "filename",
			},
			{
				Header: "Company",
				Cell: ({
					row: {
						original: { id, company = null },
					},
				}) => {
					const selectedCompany = selectedFiles[`${id}-company`];
					return (
						<Button
							style={{ minWidth: "150px" }}
							variant="outlined"
							color="primary"
							onClick={() =>
								setDialogProps({
									open: true,
									id,
									isFile: true,
								})
							}
						>
							{selectedCompany || company || "Pick"}
						</Button>
					);
				},
				disableFilters: true,
				disableSortBy: true,
			},
			{
				Header: "Doc Type",
				Cell: ({
					row: {
						original: { id, docType },
					},
				}) => {
					// We need to keep and update the state of the cell normally
					const [value, setValue] = useState(docType);

					const onChange = (newValue) => {
						setValue(newValue);
						selectedFiles[`${id}-doc`] = newValue;
					};

					return (
						<SingleSelect
							id={`doc-type-column-select-${id}`}
							value={value || ""}
							variant="standard"
							onChange={onChange}
							options={docTypes}
							getKey={(option) => option.docType}
							getLabel={(option) => option.docType}
						/>
					);
				},
				disableFilters: true,
				disableSortBy: true,
			},
			{
				Header: "Action",
				Cell: ({
					row: {
						original: {
							id,
							parentId,
							path,
							siteId,
							driveId,
							filename,
							product,
							docType,
							valorId,
							createdBy,
							createdDatetime,
							lastModifiedBy,
							lastModifiedDatetime,
						},
					},
				}) => (
					<Button
						variant="outlined"
						color="primary"
						onClick={async () => {
							const selectedValorId =
								selectedFiles[`${id}-valor-id`] || valorId;
							const doc = selectedFiles[`${id}-doc`] || docType;
							if (selectedValorId && doc) {
								await postNote({
									site_id: siteId,
									drive_id: driveId,
									id,
									parent_id: parentId,
									path,
									valor_id: selectedValorId,
									note_type: docTypes.find((d) => d.docType === doc).noteType,
									doc_type: doc,
									product,
									filename,
									created_by: createdBy,
									created_datetime: createdDatetime,
									last_modified_by: lastModifiedBy,
									last_modified_datetime: lastModifiedDatetime,
								});
								refetchFiles();
							} else {
								alert(
									`Please select the company and doc type for file '${filename}'`,
								);
							}
						}}
					>
						Post
					</Button>
				),
				disableFilters: true,
				disableSortBy: true,
			},
			{
				Header: "Ignore",
				Cell: ({
					row: {
						original: { id, docType, product, valorId },
					},
				}) => {
					const doc = selectedFiles[`${id}-doc`] || docType;
					return (
						<Button
							variant="outlined"
							color="primary"
							onClick={async () => {
								await ignoreFile({
									id,
									note_type: docTypes.find((d) => d.docType === doc)?.noteType,
									doc_type: doc,
									product,
									excluded: `Excluded by ${user.firstName} ${user.lastName}`,
									valor_id: selectedFiles[`${id}-valor-id`] || valorId,
								});
								refetchFiles();
							}}
						>
							Ignore
						</Button>
					);
				},
				disableFilters: true,
				disableSortBy: true,
			},
		],
		[docTypes, untaggedFiles],
	);

	function PickCompanyDialog({ dialogProps: companyDialogProps, handleClose }) {
		const { open, id, isFile } = companyDialogProps;

		const handleSelectCompany = ({ valorId, name }) => {
			if (isFile) {
				selectedFiles[`${id}-valor-id`] = valorId;
				selectedFiles[`${id}-company`] = name;
			} else {
				selectedFolders[`${id}-valor-id`] = valorId;
				selectedFolders[`${id}-company`] = name;
			}
			handleClose();
		};

		return (
			<Dialog
				PaperProps={{
					style: {
						position: "inherit",
					},
				}}
				fullWidth
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogContent>
					<DialogContentText>
						Search for a company to assign to document.
					</DialogContentText>
					<SearchBar
						autoFocus
						showSAYT
						selectTerm={handleSelectCompany}
						entity="company"
						search={saytForOrg}
						placeholder="Search..."
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<Page>
			<Helmet>
				<title>Untagged Documents - vOS</title>
			</Helmet>
			<Page.Title>Untagged Documents</Page.Title>
			<Page.Content>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						value={fetchFilter}
						onChange={(date) => setFetchFilter(date)}
					/>
				</LocalizationProvider>
				<PickCompanyDialog
					dialogProps={dialogProps}
					selectedFiles={selectedFiles}
					selectedFolders={selectedFolders}
					handleClose={() =>
						setDialogProps({
							...dialogProps,
							open: false,
						})
					}
				/>
				<Box mb={3} data-cy="untagged-docs__table">
					<CenteredProgress isLoading={isLoadingFolders}>
						{unmappedFolders.length ? (
							<CustomTable
								title="Unmapped Folders"
								data={unmappedFolders}
								columns={foldersColumns}
							/>
						) : (
							<Typography>No Folders in this range</Typography>
						)}
					</CenteredProgress>
				</Box>
				<CenteredProgress isLoading={isLoadingFiles}>
					{untaggedFiles.length ? (
						<CustomTable
							title="Untagged Files"
							data={untaggedFiles}
							columns={filesColumns}
						/>
					) : (
						<Typography>No Files in this range</Typography>
					)}
				</CenteredProgress>
			</Page.Content>
		</Page>
	);
}

export default VerityPage;
