import ButtonTabs from "@/ui/atoms/ButtonTabs";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import FormWizard from "./Forms/Wizard";
import {
	ProcessProvider,
	useProcessActions,
	useProcessData,
} from "./ProcessContext";
import TableView from "./TableView";

function TableContainer({
	viewId,
	disableFilters = false,
	formId = null,
	readOnly = false,
}: {
	viewId: string;
	disableFilters?: boolean;
	formId?: string;
	readOnly?: boolean;
}) {
	const navigate = useNavigate();
	const {
		processId,
		tasks,
		isLoadingTasks,
		fields,
		forms,
		isFetching,
		// settings,
		sorting,
		columnFilters,
		columnVisibility,
		columnOrder,
		views,
		fieldFilters,
		entityName,
	} = useProcessData();

	const {
		fetchNextPage,
		setSorting,
		setColumnFilters,
		setColumnVisibility,
		setColumnOrder,
		getFilterCounts,
	} = useProcessActions();

	const [openFormModal, setOpenFormModal] = React.useState(false);
	const currentForm = forms?.find((f) => f.id === formId);

	const viewReport = `/process-management/${processId}?view=table&activeView=${viewId}`;
	const activeView = React.useMemo(
		() => views?.find((v) => v.id === viewId),
		[views, viewId],
	);

	const [, setActiveViewId] = useQueryParam("activeView", StringParam);

	useEffect(() => {
		if (viewId) {
			setActiveViewId(viewId);
		}
	}, [viewId, setActiveViewId]);

	const [activeHotFilter, setActiveHotFilter] = React.useState(null);
	// get hotfilter options
	const hotFilterId = "24f4430a-25a5-4d87-beda-5c3c3467ed31";
	const hotFilter = fields?.find((f) => f.id === hotFilterId);
	const hotFilterOptions = (hotFilter?.choices || []).map((m) => ({
		key: m.id,
		label: m.value,
		disabled: false,
	}));

	const { data = {}, isLoading: isLoadingHotData } = useQuery(
		["hotFilter", hotFilterId, viewId, activeView?.columnFilters],
		async () => {
			const filterCounts = await getFilterCounts(hotFilterId);
			return filterCounts.reduce((acc, { value, count }) => {
				acc[value] = Boolean(count);
				return acc;
			}, {});
		},
		{
			enabled: !!hotFilter,
		},
	);

	return (
		<>
			<Box>
				<Box
					m={1}
					display="flex"
					gap={1}
					alignItems="center"
					justifyContent="flex-start"
				>
					<Button
						color="primary"
						variant="contained"
						onClick={() => navigate(viewReport)}
					>
						Edit
					</Button>
					{!disableFilters && hotFilterOptions?.length > 0 && (
						<ButtonTabs
							options={hotFilterOptions}
							activeKey={activeHotFilter?.key}
							width="100%"
							disableTest={(option) => isLoadingHotData || !data[option.key]}
							evenSizedButtons
							onClick={(option) => {
								if (!option) {
									setActiveHotFilter(null);
									setColumnFilters((prev) =>
										prev.filter(
											(f) =>
												f.id !== `tasks.fieldValues.${hotFilterId}.choiceId`,
										),
									);
								} else {
									setActiveHotFilter(option);
									setColumnFilters((prev) => {
										const next = [
											...(prev.filter(
												(f) =>
													f.id !== `tasks.fieldValues.${hotFilterId}.choiceId`,
											) || []),
											{
												id: `tasks.fieldValues.${hotFilterId}.choiceId`,
												operator: "eq",
												value: [
													{
														label: option.label,
														value: option.key,
													},
												],
											},
										];
										return next;
									});
								}
							}}
						/>
					)}
					{formId && (
						<Button
							color="primary"
							variant="contained"
							onClick={() => setOpenFormModal(true)}
							startIcon={<PostAddIcon />}
						>
							{currentForm?.title}
						</Button>
					)}
				</Box>
				<TableView
					enableViews={false}
					isLoading={isLoadingTasks}
					fields={fields}
					processId={processId}
					tasks={tasks}
					views={views}
					// fieldFilters={fieldFilters}
					// entityName={entityName}
					isFetching={isFetching}
					fetchNextPage={fetchNextPage}
					sorting={sorting || []}
					columnFilters={columnFilters || []}
					columnOrder={columnOrder || []}
					columnVisibility={columnVisibility || {}}
					onColumnOrderChange={setColumnOrder}
					onSortingChange={setSorting}
					onColumnFilterChange={setColumnFilters}
					onColumnVisibilityChange={setColumnVisibility}
					enableColumnFilter={false}
					enableSorting={false}
					readOnly={readOnly}
				/>
			</Box>
			<FormWizard
				open={openFormModal}
				onClose={() => setOpenFormModal(false)}
				formId={formId}
				forms={forms}
				processId="portfolio"
			/>
		</>
	);
}

export default function TableReport({
	processId,
	viewId,
	disableFilters = false,
	formId = null,
	readOnly = true,
}: {
	processId: string;
	viewId: string;
	disableFilters?: boolean;
	formId?: string;
	readOnly?: boolean;
}) {
	// store sorting / filters / etc state here
	const [sorting, setSorting] = React.useState([]);
	const [columnFilters, setColumnFilters] = React.useState([]);
	const [columnVisibility, setColumnVisibility] = React.useState({});
	const [columnOrder, setColumnOrder] = React.useState([]);
	return (
		<ProcessProvider
			processId={processId}
			mode="ungrouped"
			viewId={viewId}
			sorting={sorting}
			columnFilters={columnFilters}
			columnVisibility={columnVisibility}
			columnOrder={columnOrder}
			onColumnFiltersChange={setColumnFilters}
			onColumnVisibilityChange={setColumnVisibility}
			onColumnOrderChange={setColumnOrder}
			onSortingChange={setSorting}
		>
			<TableContainer
				viewId={viewId}
				disableFilters={disableFilters}
				formId={formId}
				readOnly={readOnly}
			/>
		</ProcessProvider>
	);
}
