import {
	createOrg,
	getOrgValues,
	getOverrideOrg,
	overrideOrganization,
} from "@/api/Organization";
import { useAuth } from "@/hooks/useAuth";
import useLocalStorageState from "@/hooks/useLocalStorageState";
import OrgFieldValues from "@/pages/Org/Override/OrgFieldValues";
import Override from "@/pages/Org/Override/Override";
import { Box, Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useQueryParam } from "use-query-params";

export function DevPage() {
	const { firebaseToken } = useAuth();
	const [valorId, setValorId] = useQueryParam("valorId");
	const [currIdState, setCurrentIdState] = useState({});
	const [, setIsDarkMode] = useLocalStorageState(false, "isDarkMode");

	useEffect(() => {
		if (!valorId) {
			const newId = crypto.randomUUID();
			setValorId(newId);
			createOrg({ valorId: newId });
		}
	}, [setValorId, valorId]);

	useEffect(() => {
		if (!valorId) {
			return;
		}
		const fetchData = async () => {
			const data = await getOverrideOrg({ valorId });
			setCurrentIdState(data);
			return data;
		};
		fetchData();
	}, [valorId]);

	const copyBearer = async () => {
		await navigator.clipboard.writeText(firebaseToken);
	};

	const copyValorId = async () => {
		await navigator.clipboard.writeText(valorId);
	};

	const { data: fieldValues = [], refetch: refetchOrgValues } = useQuery(
		["orgValues", valorId],
		() => getOrgValues(valorId),
		{
			refetchOnWindowFocus: false,
		},
		{
			enabled: !!valorId,
		},
	);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				margin: 6,
				wordBreak: "break-all",
			}}
		>
			<Button
				color="primary"
				variant="outlined"
				onClick={() => {
					setIsDarkMode((o) => !o);
					window.location.reload();
				}}
			>
				Toggle UI Mode
			</Button>
			<Button
				onClick={copyBearer}
				style={{ width: "300px" }}
				color="primary"
				variant="contained"
			>
				Copy Bearer Token Clipboard
			</Button>

			<Typography variant="h1">Create new Org</Typography>

			<Typography variant="subtitle2"> Generated UUID: {valorId}</Typography>
			<Button onClick={copyValorId}>Copy Valor ID</Button>

			<OrgFieldValues
				valorId={valorId}
				fieldValues={fieldValues}
				onUpdate={() => refetchOrgValues()}
			/>

			<Override
				valorId={valorId}
				currentState={currIdState}
				onSubmit={overrideOrganization}
				mode="edit"
			/>
		</Box>
	);
}

export default DevPage;
