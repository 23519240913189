import { useTheme } from "@mui/material";
import React, { memo, useState } from "react";
import TableCell from "./TableCell";

const VirtualRow = memo(
	({
		row,
		virtualRow,
		visibleColumns,
		virtualColumns,
		virtualPaddingLeft,
		virtualPaddingRight,
		estimateSize,
		onRowClick,
		onRowDoubleClick,
		handleTouchStart,
		handleTouchEnd,
	}: any) => {
		const visibleCells = row.getVisibleCells();
		const [activeRow, setActiveRow] = useState(false);
		const theme = useTheme();

		return (
			<tr
				data-index={virtualRow.index}
				key={row.id}
				style={{
					display: "flex",
					position: "absolute",
					transform: `translateY(${virtualRow.start}px)`,
					width: "100%",
					height: `${estimateSize}px`,
					maxHeight: `${estimateSize}px`,
					minHeight: `${estimateSize}px`,
					zIndex: activeRow ? 1 : 0,
				}}
				onClick={() => onRowClick?.(row)}
				onDoubleClick={() => onRowDoubleClick?.(row)}
				onTouchStart={(e) => handleTouchStart(e, row)}
				onTouchEnd={handleTouchEnd}
				onTouchCancel={handleTouchEnd}
				onMouseOver={() => setActiveRow(true)}
				onMouseOut={() => setActiveRow(false)}
			>
				{/* First column */}
				<TableCell
					cell={visibleCells[0]}
					background={
						virtualRow.index % 2
							? theme.palette.background.oddTableRow
							: theme.palette.background.paper
					}
					columnSize={visibleColumns[0]?.getSize()}
					theme={theme}
				/>

				{/* Left padding */}
				{virtualPaddingLeft > 0 && (
					<td style={{ display: "flex", width: `${virtualPaddingLeft}px` }} />
				)}

				{/* Virtual columns */}
				{virtualColumns.map((virtualColumn) => {
					const cell = visibleCells[virtualColumn.index + 1];
					if (!cell) return null;

					return (
						<TableCell
							key={cell.id}
							cell={cell}
							background={
								cell.column.getIsFiltered()
									? theme.palette.background.activeTableColumn
									: virtualRow.index % 2
										? theme.palette.background.oddTableRow
										: theme.palette.background.paper
							}
							columnSize={cell.column.getSize()}
							theme={theme}
						/>
					);
				})}

				{/* Right padding */}
				{virtualPaddingRight > 0 && (
					<td style={{ display: "flex", width: `${virtualPaddingRight}px` }} />
				)}
			</tr>
		);
	},
);

VirtualRow.displayName = "VirtualRow";
export default VirtualRow;
