import { Add } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";

interface AddContextButtonProps {
	onClick: () => void;
	label?: string;
}

export function AddContextButton({
	onClick,
	label = "Add Company Context",
}: AddContextButtonProps) {
	return (
		<Box sx={{ mb: 1 }}>
			<Button
				variant="outlined"
				size="small"
				onClick={onClick}
				startIcon={<Add />}
				sx={{
					borderStyle: "dashed",
					"&:hover": {
						borderStyle: "dashed",
					},
				}}
			>
				{label}
			</Button>
		</Box>
	);
}

export default AddContextButton;
