import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
/* eslint-disable react/prop-types */
import React from "react";
import { MapStep } from "./MapStepEnum";

const tabs = ["plan", "collect", "map"];

export function MapSubHeader(props) {
	const { onTabClick, activeTab } = props;
	const theme = useTheme();

	const currentTabStyle = {
		color: theme.palette.action.active,
		borderBottom: `2px solid ${theme.palette.action.active}`,
	};

	const enumMapping = {
		plan: MapStep.Plan,
		map: MapStep.Map,
		collect: MapStep.Collect,
	};

	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "flex-end",
				backgroundColor: theme.palette.background.paper,
				boxShadow: theme.shadows[1],
				height: theme.spacing(5),
				"& > button:not(last-child)": {
					marginRight: theme.spacing(2),
				},
			}}
		>
			{tabs.map((t) => (
				<ButtonBase
					key={t}
					sx={{
						height: theme.spacing(4),
						padding: theme.spacing(2, 0),
						color: theme.palette.text.secondary,
						borderBottom: "2px solid transparent",
					}}
					style={t === activeTab.name ? currentTabStyle : {}}
					onClick={() => onTabClick(enumMapping[t])}
				>
					<Typography
						component="p"
						sx={{
							fontWeight: theme.typography.fontWeightBold,
							fontSize: "14px",
							letterSpacing: "1.25px",
						}}
					>
						{t.toUpperCase()}
					</Typography>
				</ButtonBase>
			))}
		</Box>
	);
}

export default MapSubHeader;
