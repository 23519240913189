/* eslint-disable no-console */
import axios from "axios";

export const postCompetitorAnalysis = async (valorIds) => {
	try {
		const response = await axios.post(
			"/api/competitor-analysis/xlsx",
			valorIds,
			{
				responseType: "blob",
			},
		);

		const filename = response.headers["content-disposition"]
			.split(";")
			.find((x) => x.includes("filename"))
			.replace("filename=", "")
			.replace(/"/g, "")
			.trim();

		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", filename);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		return "success";
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export default {
	postCompetitorAnalysis,
};
