import Progress from "@/ui/atoms/Progress";
import Section from "@/ui/atoms/Section";
import {
	Alert,
	Box,
	Collapse,
	Skeleton,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useState } from "react";

import BarChart from "./BarChart";
import HistChart from "./HistChart";
import useDistribution from "./useDistribution";
import useFeature from "./useFeature";

function FeatureListItem({
	modelId,
	valorId,
	companyName,
	feature,
}: {
	modelId: string;
	valorId: string;
	companyName: string;
	feature: any;
}) {
	const [
		{
			Icon,
			value,
			iconColor,
			isOutlier,
			OutlierIcon,
			outlierIconColor,
			sourcedFrom,
			sourcedFromColor,
		},
	] = useFeature(feature);

	const [showChart, setShowChart] = useState(false);
	const { data: distribution, isLoading: distributionLoading } =
		useDistribution(modelId, valorId, feature?.id, showChart);

	const quantileOrder = distribution?.quantileOrder;

	return (
		<Box
			padding={1}
			borderRadius={1}
			border={(theme) =>
				showChart
					? `1px solid ${theme.palette.divider}`
					: `1px solid ${theme.palette.background.paper}`
			}
			sx={{
				cursor: "pointer",
				"&:hover": {
					backgroundColor: (theme) => theme.palette.action.hover,
				},
			}}
		>
			<Box display="flex" flexDirection="column">
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between"
					onClick={() => {
						setShowChart(!showChart);
					}}
				>
					<Box display="flex" flexDirection="row" alignItems="center" gap={2}>
						<Box
							sx={{
								color: iconColor,
							}}
						>
							<Icon />
						</Box>
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="center"
							marginRight={2}
						>
							<Typography
								variant="body1"
								sx={{
									fontWeight: "bold",
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: 1,
								}}
							>
								{feature.name}{" "}
								{isOutlier && (
									<Tooltip title="This feature has on outsized impact on final predicted MOIC">
										<OutlierIcon
											fontSize="small"
											sx={{
												color: outlierIconColor,
											}}
										/>
									</Tooltip>
								)}
							</Typography>
							<Typography variant="body2">
								{feature.description}
								<br />
								{sourcedFrom && (
									<Box
										component="span"
										display="flex"
										flexDirection="row"
										alignItems="center"
										gap={0.5}
									>
										<Typography
											component="span"
											variant="body2"
											color="text.secondary"
										>
											Sourced from:
										</Typography>
										<Typography
											component="span"
											variant="body2"
											color={sourcedFromColor || "text.primary"}
											sx={{
												fontWeight: (theme) => theme.typography.fontWeightBold,
											}}
										>
											{sourcedFrom}
										</Typography>
									</Box>
								)}
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							whiteSpace: "nowrap",
						}}
					>
						{distributionLoading && <Progress />}
						{!distributionLoading && (
							<Typography variant="body1">{value}</Typography>
						)}
					</Box>
				</Box>

				<Box
					sx={{
						overflowY: "hidden",
						overflowX: "auto",
					}}
				>
					<Collapse in={showChart && !distributionLoading}>
						<Box marginTop={1}>
							{distribution?.chartType === "HIST" && (
								<HistChart
									data={distribution}
									companyName={companyName}
									xAsc={quantileOrder === "ASCENDING"}
								/>
							)}
							{distribution?.chartType === "BAR" && (
								<BarChart data={distribution} companyName={companyName} />
							)}
						</Box>
					</Collapse>
				</Box>
			</Box>
		</Box>
	);
}

function FeatureList({
	modelId,
	features,
	company,
	showLowCoverageAlert = false,
}: any) {
	return (
		<Box padding={1}>
			<Section>
				<Section.Title>Top Features</Section.Title>
				<Section.Content>
					{(showLowCoverageAlert || features?.length === 0) && (
						<Alert severity="warning">
							<Typography variant="body2">
								Sufficient feature coverage is unavailable for this organization{" "}
								to provide meaningful signal explainability.
							</Typography>
						</Alert>
					)}
					{!showLowCoverageAlert && (
						<Box display="flex" flexDirection="column" gap={1}>
							{features?.map((feature) => (
								<FeatureListItem
									key={feature.id}
									modelId={modelId}
									feature={feature}
									valorId={company.valorId}
									companyName={company.name}
								/>
							))}
						</Box>
					)}
				</Section.Content>
			</Section>
		</Box>
	);
}

FeatureList.Skeleton = function FeatureListSkeleton() {
	return (
		<Box width="100%" padding={1}>
			<Section>
				<Section.Title>Top Features</Section.Title>
				<Section.Content>
					<Box display="flex" flexDirection="column" gap={1}>
						<Skeleton variant="rectangular" height={100} width="100%" />
						<Skeleton variant="rectangular" height={100} width="100%" />
						<Skeleton variant="rectangular" height={100} width="100%" />
					</Box>
				</Section.Content>
			</Section>
		</Box>
	);
};

export default FeatureList;
