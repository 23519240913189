import type { Organization } from "@/api/Organization";
import { Box, Card, Grid } from "@mui/material";
import React from "react";
import FundingRounds from "./FundingRounds";
import GeneralInformation from "./GeneralInformation";
import RecentNews from "./RecentNews";
import SurveyResponse from "./SurveyResponses";
import Transcripts from "./Transcripts";
import ValorFunding from "./ValorFunding";

interface SummaryProps {
	company: Organization;
}

function OrganizationSummary({ company }: SummaryProps) {
	return (
		<Grid container spacing={1} rowSpacing={2}>
			<Grid item xs={12} lg={8} xl={9}>
				<Box display="flex" flexDirection="column" sx={{ gap: "16px" }}>
					<Card
						data-cy="organization-summary__general-information-card"
						elevation={0}
						sx={{
							padding: 1,
							display: "flex",
							flexDirection: "column",
						}}
					>
						<GeneralInformation company={company} />
					</Card>
					<Card
						data-cy="organization-summary__valor-funding-card"
						elevation={0}
						sx={{
							padding: 1,
							display: "flex",
							flexDirection: "column",
						}}
					>
						<ValorFunding company={company} />
					</Card>
					<Card
						data-cy="organization-summary__survey-response-card"
						elevation={0}
						sx={{
							padding: 1,
							display: "flex",
							flexDirection: "column",
						}}
					>
						<SurveyResponse company={company} />
					</Card>
				</Box>
			</Grid>
			<Grid item xs={12} lg={4} xl={3}>
				<Card
					data-cy="organization-summary__funding-rounds-card"
					elevation={0}
					sx={{ padding: 1, marginBottom: 2 }}
				>
					<FundingRounds company={company} />
				</Card>
				<Card
					data-cy="organization-summary__transcripts-card"
					elevation={0}
					sx={{ padding: 1, marginBottom: 2 }}
				>
					<Transcripts valorId={company?.valorId} />
				</Card>
				<Card
					data-cy="organization-summary__recent-news-card"
					elevation={0}
					sx={{ padding: 1 }}
				>
					<RecentNews company={company} />
				</Card>
			</Grid>
		</Grid>
	);
}

export default OrganizationSummary;
