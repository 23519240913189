import type { TaskActivityResponse } from "@/api/Process";
import { formatBigDollars } from "@/utils/numberFormat";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import React from "react";
import ActivityEvent from "./ActivityEvent";

export default function FieldChangeEvent({
	author,
	when,
	field,
	fieldValue,
	entityName,
}: {
	author: TaskActivityResponse["fieldChanges"][0]["author"];
	when: dayjs.Dayjs;
	field: TaskActivityResponse["fieldChanges"][0]["field"];
	fieldValue: TaskActivityResponse["fieldChanges"][0]["fieldValue"];
	entityName: string;
}) {
	if (!field || !fieldValue) {
		return null;
	}
	if (field.type === "checkbox") {
		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{` set ${field.name} to `}
				<ActivityEvent.Value>{fieldValue.value}</ActivityEvent.Value>
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	if (field.type === "select") {
		const choice = field.choices.find((x) => x.id === fieldValue.choiceId);
		if (!choice) {
			return (
				<ActivityEvent>
					<ActivityEvent.Author author={author} />
					{` set ${field.name} to None.`}
					<ActivityEvent.When when={when} />
				</ActivityEvent>
			);
		}
		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{` set ${field.name} to `}
				<ActivityEvent.Value>{choice.value}</ActivityEvent.Value>
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	if (field.type === "select_multi") {
		const choices = field.choices.filter((x) =>
			fieldValue.choices.includes(x.id),
		);
		const choiceValues = choices.map((x) => x.value);
		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{` set ${field.name} to `}
				<ActivityEvent.Value>{choiceValues.join(", ")}</ActivityEvent.Value>
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	if (field.type === "text") {
		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{` set ${field.name} to `}
				<ActivityEvent.Value>{fieldValue.value}</ActivityEvent.Value>
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	if (field.type === "user") {
		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{` set ${field.name} to `}
				<ActivityEvent.Value>
					{fieldValue.user?.firstName} {fieldValue.user?.lastName}
				</ActivityEvent.Value>
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	if (field.type === "user_multi") {
		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{` set ${field.name} to `}
				<ActivityEvent.Value>
					{fieldValue.users
						.map((x) => `${x.firstName} ${x.lastName}`)
						.join(", ")}
				</ActivityEvent.Value>
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	if (field.type === "person") {
		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{` set ${field.name} to `}
				<ActivityEvent.Value>
					{fieldValue.person?.firstName} {fieldValue.person?.lastName}
				</ActivityEvent.Value>
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	if (field.type === "person_multi") {
		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{` set ${field.name} to `}
				<ActivityEvent.Value>
					{fieldValue.persons
						?.map((x) => `${x.firstName} ${x.lastName}`)
						.join(", ")}
				</ActivityEvent.Value>
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	if (field.type === "company") {
		if (!fieldValue.company) {
			return (
				<ActivityEvent>
					<ActivityEvent.Author author={author} />
					{` removed ${field.name}.`}
					<ActivityEvent.When when={when} />
				</ActivityEvent>
			);
		}

		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{` set ${field.name} to `}
				<ActivityEvent.Value>{fieldValue.company?.name}</ActivityEvent.Value>
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	if (field.type === "date" || field.type === "target_date") {
		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{fieldValue.dateValue === null && ` cleared ${field.name}`}
				{fieldValue.dateValue && (
					<>
						{` set ${field.name} to `}
						<ActivityEvent.Value>
							{dayjs(fieldValue.dateValue).format("MMMM D, YYYY")}
						</ActivityEvent.Value>
					</>
				)}
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	if (field.type === "date_time") {
		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{fieldValue.dateValue === null &&
					fieldValue.dateTimeValue === null &&
					` cleared ${field.name}`}
				{fieldValue.dateValue && (
					<>
						{` set ${field.name} to `}
						<ActivityEvent.Value>
							{dayjs(fieldValue.dateValue).format("MMMM D, YYYY")}
						</ActivityEvent.Value>
					</>
				)}
				{fieldValue.dateTimeValue && (
					<>
						{` set ${field.name} to `}
						<ActivityEvent.Value>
							{dayjs(fieldValue.dateTimeValue).format("MMMM D, YYYY hh:mm A z")}
						</ActivityEvent.Value>
					</>
				)}
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	if (field.type === "ryg") {
		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{` set ${field.name} to `}
				<ActivityEvent.Value>
					{fieldValue.value === "R" && (
						<Chip label="R" color="error" size="small" />
					)}
					{fieldValue.value === "Y" && (
						<Chip label="Y" color="warning" size="small" />
					)}
					{fieldValue.value === "G" && (
						<Chip label="G" color="success" size="small" />
					)}
				</ActivityEvent.Value>
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	if (field.type === "dollar") {
		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{` set ${field.name} to `}
				<ActivityEvent.Value>
					{formatBigDollars(fieldValue.numericValue)}
				</ActivityEvent.Value>
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	if (field.type === "number") {
		return (
			<ActivityEvent>
				<ActivityEvent.Author author={author} />
				{` set ${field.name} to `}
				<ActivityEvent.Value>
					{Number(fieldValue.numericValue).toLocaleString()}
				</ActivityEvent.Value>
				<ActivityEvent.When when={when} />
			</ActivityEvent>
		);
	}

	return (
		<ActivityEvent>
			<ActivityEvent.Author author={author} /> made a change to {entityName}.
		</ActivityEvent>
	);
}
