import isDateOnlyISOString from "@/utils/dates";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";

type DateTimeInputProps = {
	value: string | null;
	onChange: (value: string | null) => void;
	label: string;
	required?: boolean;
	disabled?: boolean;
};

export default function DateTimeInput({
	value,
	onChange,
	label,
	required,
	disabled,
	error,
}: DateTimeInputProps) {
	const [allDay, setAllDay] = useState(false);

	useEffect(() => {
		setAllDay(isDateOnlyISOString(value));
	}, [value]);

	const handleAllDayChange = (event) => {
		setAllDay(event.target.checked);
		if (event.target.checked && value) {
			const date = dayjs(value).format("YYYY-MM-DD");
			onChange(date);
		} else if (!event.target.checked && value) {
			const dateTime = dayjs(value).format();
			onChange(dateTime);
		}
	};

	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "flex-start",
			}}
		>
			{allDay ? (
				<Box sx={{ width: "75%" }}>
					<DatePicker
						disabled={disabled}
						sx={{
							width: "100%",
						}}
						label={label}
						value={value ? dayjs(value).local() : null}
						onChange={(date) => {
							onChange(date ? dayjs(date).format("YYYY-MM-DD") : null);
						}}
						slotProps={{
							field: {
								clearable: true,
								onKeyDown: (e) => {
									e.preventDefault();
								},
								required,
								error,
							},
						}}
						closeOnSelect
						orientation="portrait"
					/>
				</Box>
			) : (
				<Box sx={{ width: "75%" }}>
					<DateTimePicker
						disabled={disabled}
						sx={{
							width: "100%",
						}}
						label={label}
						value={value ? dayjs(value).local() : null}
						onChange={(date) => {
							onChange(date ? dayjs(date).format() : null);
						}}
						slotProps={{
							field: {
								clearable: true,
								onKeyDown: (e) => {
									e.preventDefault();
								},
								required,
								error,
							},
						}}
						closeOnSelect={false}
						orientation="portrait"
						ampm
					/>
					<Typography
						variant="caption"
						sx={{
							color: "text.secondary",
							fontStyle: "italic",
							display: "block",
							textAlign: "right",
						}}
					>
						All times are in your local timezone
					</Typography>
				</Box>
			)}
			<FormControlLabel
				control={
					<Checkbox
						checked={allDay}
						onChange={handleAllDayChange}
						disabled={disabled}
					/>
				}
				label="All Day"
			/>
		</Box>
	);
}
