import PropTypes from "prop-types";
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import SingleSelect from "../SingleSelect";

export default function SelectColumnFilter({
	column: { filterValue, setFilter, preFilteredRows, id },
	labelFormatter,
	width,
}) {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = React.useMemo(() => {
		const uniqueOptions = new Set();
		preFilteredRows.forEach((row) => {
			const value = row.values[id];
			if (value !== null) {
				if (Array.isArray(value)) {
					if (value.length > 0) {
						uniqueOptions.add(...value);
					}
				} else {
					uniqueOptions.add(value);
				}
			}
		});
		return [...uniqueOptions].sort();
	}, [preFilteredRows]);

	return (
		<SingleSelect
			id={`${id}-table-select-filter`}
			showNone
			variant="standard"
			width={width || 150}
			onChange={(value) => setFilter(value === "" ? null : value)}
			options={options}
			value={filterValue || ""}
			getLabel={(label) => labelFormatter(label)}
		/>
	);
}

SelectColumnFilter.propTypes = {
	labelFormatter: PropTypes.func,
};

SelectColumnFilter.defaultProps = {
	labelFormatter: (title) => title,
};
