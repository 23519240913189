import { LocalAtm } from "@mui/icons-material";
import {
	Box,
	Button,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";

import type { OrganizationSignals } from "@/api/Organization";
import {
	canSeeMOICChip,
	canSeeMOICScoreWithoutDisclaimer,
} from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import { usePopover } from "@/hooks/useGlobalPopover";
import { styled, useTheme } from "@mui/material/styles";

type Signal = OrganizationSignals["signals"][0];
interface FimoicaChipProps {
	signal: Signal;
	showIcon?: boolean;
	showName?: boolean;
	showBorder?: boolean;
	valorId: string;
}
export function FimoicaChip({
	signal,
	showIcon,
	showName,
	showBorder,
	valorId,
}: FimoicaChipProps) {
	const { score, signalId } = signal;
	const isBig = showName && showIcon;
	const { user: valorUser } = useAuth();
	const showMoicScoreWithoutDisclaimer =
		canSeeMOICScoreWithoutDisclaimer(valorUser);
	const showMOICChip = canSeeMOICChip(valorUser);

	const [hasAcceptedFimoica, setAcceptedFimoica] = useState(false);
	const [isFimoicaDisclaimerOpen, setFimoicaDisclaimerOpen] = useState(false);
	const theme = useTheme();
	const divRef = useRef(null);

	const { openPopover } = usePopover();

	const getConfusion = (value: number) => {
		if (value < 1) {
			return {
				"7+": 2,
				"5-7": 3,
				"3-5": 5,
				"1-3": 32,
				"<=1": 58,
			};
		}
		if (value < 3) {
			return {
				"7+": 4,
				"5-7": 2,
				"3-5": 9,
				"1-3": 36,
				"<=1": 48,
			};
		}
		if (value < 5) {
			return {
				"7+": 9,
				"5-7": 5,
				"3-5": 14,
				"1-3": 40,
				"<=1": 32,
			};
		}
		if (value < 7) {
			return {
				"7+": 18,
				"5-7": 10,
				"3-5": 15,
				"1-3": 36,
				"<=1": 21,
			};
		}
		return {
			"7+": 34,
			"5-7": 11,
			"3-5": 17,
			"1-3": 27,
			"<=1": 12,
		};
	};

	const colors = [
		theme.palette.stopLight.best,
		theme.palette.stopLight.great,
		theme.palette.stopLight.good,
		theme.palette.stopLight.okay,
		theme.palette.stopLight.bad,
	];

	const scoreSquish = (s) => {
		if (s < 1) {
			return "<1x";
		}
		if (s < 3) {
			return "1-3x";
		}
		if (s < 5) {
			return "3-5x";
		}
		if (s < 7) {
			return "5-7x";
		}
		return "7x+";
	};

	const getColor = (s) => {
		if (s < 1) {
			return colors[4];
		}
		if (s < 3) {
			return colors[3];
		}
		if (s < 5) {
			return colors[2];
		}
		if (s < 7) {
			return colors[1];
		}
		return colors[0];
	};
	const squishedScore = scoreSquish(score);

	const fimoicaAcceptedContent = (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Box
				display="flex"
				justifyContent={valorId ? "space-between" : "center"}
				alignItems="center"
			>
				<Typography
					mb={1}
					sx={{
						marginRight: valorId ? "auto" : undefined,
						color: getColor(score),
					}}
					variant="h6"
				>
					{squishedScore}
				</Typography>
			</Box>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
			>
				<Typography variant="subtitle2">
					Outcomes of MOIC estimates perform probabilistically. Please carefully
					consider the full range of possible outcomes, from false positives to
					false negatives.
				</Typography>
				<Typography variant="boldSubtitle2">
					When the score is {squishedScore}, the distribution of actual MOIC
					based on backtesting data is:
				</Typography>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="flex-start"
					width="100%"
					mt={2}
				>
					<Table>
						<TableHead>
							<TableRow
								sx={{
									"& th": {
										padding: "8px",
									},
								}}
							>
								<TableCell>
									<Typography
										sx={{
											textAlign: "center",
											borderBottom: `3px solid ${colors[4]}`,
										}}
									>
										{" "}
										&le; 1x
									</Typography>
								</TableCell>
								<TableCell>
									<Typography
										sx={{
											textAlign: "center",
											borderBottom: `3px solid ${colors[3]}`,
										}}
									>
										{" "}
										1-3x
									</Typography>
								</TableCell>
								<TableCell>
									<Typography
										sx={{
											textAlign: "center",
											borderBottom: `3px solid ${colors[2]}`,
										}}
									>
										{" "}
										3-5x
									</Typography>
								</TableCell>
								<TableCell>
									<Typography
										sx={{
											textAlign: "center",
											borderBottom: `3px solid ${colors[1]}`,
										}}
									>
										{" "}
										5-7x
									</Typography>
								</TableCell>
								<TableCell>
									<Typography
										sx={{
											textAlign: "center",
											borderBottom: `3px solid ${colors[0]}`,
										}}
									>
										{" "}
										7x+
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell sx={{ textAlign: "center" }}>
									{getConfusion(score)["<=1"]}%
								</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									{getConfusion(score)["1-3"]}%
								</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									{getConfusion(score)["3-5"]}%
								</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									{getConfusion(score)["5-7"]}%
								</TableCell>
								<TableCell sx={{ textAlign: "center" }}>
									{getConfusion(score)["7+"]}%
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					{valorId ? (
						<Box display="flex" justifyContent="center" sx={{ width: "100%" }}>
							<Button
								onClick={() =>
									window.open(`/org/${valorId}/explainability`, "_blank")
								}
							>
								Explain Score
							</Button>
						</Box>
					) : null}
				</Box>
			</Box>
		</Box>
	);

	const SignalBox = styled(Box)(() => ({
		display: "flex",
		border: showBorder ? `1px solid ${theme.palette.divider}` : null,
		flexDirection: isBig ? "column" : "row",
		justifyContent: isBig ? "space-between" : "flex-start",
		gap: isBig ? null : "8px",
		alignItems: "center",
		padding: theme.spacing(1),
		width: isBig ? "120px" : "96px",
		height: isBig ? "80px" : "40px",
		background: theme.palette.background.paper,
		borderRadius: theme.spacing(1),
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
	}));

	if (!showMOICChip) {
		return null;
	}

	return (
		<div ref={divRef} key={signalId} data-cy="fimoica-chip__container">
			<Modal
				open={isFimoicaDisclaimerOpen}
				onClose={() => {
					setFimoicaDisclaimerOpen(false);
					setAcceptedFimoica(false);
				}}
			>
				<Box
					sx={{
						position: "absolute" as const,
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 400,
						bgcolor: "background.paper",
						border: "2px solid #000",
						boxShadow: 24,
						p: 4,
					}}
				>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
					>
						<Typography variant="h6">MOIC Disclaimer</Typography>
						<Typography variant="subtitle2">
							The MOIC score you are about to view is a predictive estimate
							generated by the vOS Signal Model using current information.
							Today’s estimate is probabilistic and subject to change as the
							model incorporates new information. Additionally, evaluation of
							numerical estimates is prone to multiple cognitive biases.
						</Typography>
						<Typography
							varaint="boldSubtitle2"
							sx={{ textDecoration: "underline" }}
						>
							Please interpret with caution and take steps to recognize and
							minimize any interpretation bias.
						</Typography>
						<Button
							sx={{ marginTop: "16px", width: "140px" }}
							variant="contained"
							onClick={() => {
								setFimoicaDisclaimerOpen(false);
								setAcceptedFimoica(true);
								openPopover(divRef, fimoicaAcceptedContent);
							}}
						>
							I Understand
						</Button>
					</Box>
				</Box>
			</Modal>
			<SignalBox
				onClick={async () => {
					setFimoicaDisclaimerOpen(true);
					console.log("Clicked");
				}}
			>
				{showName && (
					<Typography sx={{ textAlign: "center" }} variant="boldSubtitle2">
						MOIC
					</Typography>
				)}
				{showIcon && <LocalAtm />}
				{showMoicScoreWithoutDisclaimer || hasAcceptedFimoica ? (
					<Typography sx={{ color: getColor(score) }} variant="boldBody2">
						{squishedScore}
					</Typography>
				) : (
					<Typography variant="boldBody2" p={isBig ? 0 : 1}>
						?
					</Typography>
				)}
			</SignalBox>
		</div>
	);
}

export default FimoicaChip;
