import { Business, Description } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import type React from "react";
import { useRef } from "react";
import type { ActionButtonsProps } from "./utils/ChatState";

export function ActionButtons({
	onAddContext,
	onAddFile,
	allowedFileTypes,
}: ActionButtonsProps) {
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleFileClick = () => {
		fileInputRef.current?.click();
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(event.target.files || []);
		onAddFile(files);
		// Reset input value to allow selecting the same file again
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};
	return (
		<Box sx={{ display: "flex", gap: 1 }}>
			<Button
				variant="outlined"
				size="small"
				onClick={onAddContext}
				startIcon={<Business />}
				sx={{
					borderStyle: "dashed",
					"&:hover": {
						borderStyle: "dashed",
					},
				}}
			>
				Add Private Context
			</Button>
			<input
				type="file"
				ref={fileInputRef}
				onChange={handleFileChange}
				style={{ display: "none" }}
				multiple
				accept={allowedFileTypes.map((type) => `.${type}`).join(",")}
			/>
			<Button
				variant="outlined"
				size="small"
				onClick={handleFileClick}
				startIcon={<Description />}
				sx={{
					borderStyle: "dashed",
					"&:hover": {
						borderStyle: "dashed",
					},
				}}
			>
				Upload File
			</Button>
		</Box>
	);
}

export default ActionButtons;
