import { type GetFinanceOrgResult, updateFinanceOrg } from "@/api/Metrics";
import { isAdmin } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import Section from "@/ui/atoms/Section";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	MenuItem,
	Select,
	Skeleton,
	Snackbar,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";

interface CompanyFinanceConfigProps {
	valorId: string;
	disabled?: boolean;
	companyConfig: GetFinanceOrgResult;
	refetch: () => void;
	error: boolean;
	isLoading: boolean;
}

function CompanyFinanceConfigLoader() {
	const width = 100;
	const height = 30;
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="flex-start"
			gap={0.5}
			py={1}
		>
			<Skeleton variant="rounded" width={width} height={height} />
			<Skeleton variant="rounded" width={width * 2} height={height} />
			<Skeleton variant="rounded" width={width} height={height} />
		</Box>
	);
}

export default function CompanyFinanceConfig({
	valorId,
	companyConfig,
	disabled,
	isLoading,
	refetch,
	error,
}: CompanyFinanceConfigProps) {
	const [successOpen, setSuccessOpen] = useState(false);
	const currentYear = new Date().getFullYear();
	const { user } = useAuth();
	const canSeeSensitive = isAdmin(user);

	if (error) {
		return (
			<Section mt={2}>
				<Section.Title variant="h3">Company Financials</Section.Title>
				<Section.Content>
					<Alert severity="error">
						There was an error loading this company's Configuration. Please
						refresh the page or contact the labs team if issues persist.
					</Alert>
				</Section.Content>
			</Section>
		);
	}

	return (
		<Section mt={2}>
			<Section.Title variant="h3">Company Financials</Section.Title>
			<Section.Content>
				{isLoading ? (
					<CompanyFinanceConfigLoader />
				) : (
					<Box gap={1} py={1}>
						<Form
							onSubmit={async (values) => {
								const { fyeMonth, startYear, sensitiveCompany } = values;
								await updateFinanceOrg(valorId, {
									...companyConfig,
									fyeMonth,
									startYear,
									sensitiveCompany,
								});
								setSuccessOpen(true);
								refetch();
							}}
							render={({ handleSubmit, dirty, submitting }) => (
								<form onSubmit={handleSubmit}>
									<FormControl component="fieldset">
										<Field
											name="fyeMonth"
											initialValue={companyConfig?.fyeMonth}
											defaultValue={12}
											render={({ input }) => (
												<>
													<Typography>FYE Month</Typography>
													<Select
														sx={{ width: "200px" }}
														value={input.value}
														onChange={(e) => input.onChange(e.target.value)}
														size="small"
														disabled={disabled}
													>
														{Array.from({ length: 12 }, (_, i) => 12 - i).map(
															(mo) => (
																<MenuItem key={mo} value={mo}>
																	{new Date(0, mo - 1).toLocaleString("en-US", {
																		month: "short",
																	})}
																</MenuItem>
															),
														)}
													</Select>
												</>
											)}
										/>
										<Field
											name="startYear"
											initialValue={companyConfig?.startYear}
											render={({ input }) => (
												<>
													<Typography>Start Year</Typography>
													<Select
														sx={{ width: "200px" }}
														value={input.value}
														onChange={(e) => input.onChange(e.target.value)}
														size="small"
														disabled={disabled}
													>
														{Array.from(
															{ length: currentYear - 2010 + 1 },
															(_, i) => currentYear - i,
														).map((year) => (
															<MenuItem key={year} value={year}>
																{year}
															</MenuItem>
														))}
													</Select>
												</>
											)}
										/>
										{canSeeSensitive && (
											<Field
												name="sensitiveCompany"
												type="checkbox"
												initialValue={companyConfig?.sensitiveCompany || false}
												render={({ input }) => (
													<FormControlLabel
														control={
															<Checkbox
																checked={input.checked}
																onChange={(e) =>
																	input.onChange(e.target.checked)
																}
																disabled={disabled}
															/>
														}
														label="Sensitive Company (Only Valor FTE should have access)"
													/>
												)}
											/>
										)}
									</FormControl>
									<Box mt={1} display="flex" flexDirection="row" gap={1}>
										<Button
											type="submit"
											variant="contained"
											color="primary"
											disabled={isLoading || disabled || submitting || !dirty}
										>
											Save Changes
										</Button>
									</Box>
								</form>
							)}
						/>
					</Box>
				)}
			</Section.Content>
			<Snackbar
				open={successOpen}
				autoHideDuration={6000}
				onClose={() => setSuccessOpen(false)}
				message="Company Config Updated"
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert severity="success" onClose={() => setSuccessOpen(false)}>
					Company Config Updated
				</Alert>
			</Snackbar>
		</Section>
	);
}
