import type { components } from "@/api/index";
import UserAvatar from "@/ui/atoms/UserAvatar";
import RichTextSerializer from "@/ui/molecules/RichTextEditor/Serializer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CopyLinkIcon from "@mui/icons-material/FileCopy";
import LaunchIcon from "@mui/icons-material/Launch";
import LockIcon from "@mui/icons-material/Lock";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PublicIcon from "@mui/icons-material/Public";
import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Snackbar,
	Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useNavigate } from "react-router";

type Note = components["schemas"]["Note"];
type NoteProps = {
	note: Note;
	showEdit?: boolean;
	showDelete?: boolean;
	showViewNote?: boolean;
	showCopyLink?: boolean;
	editNote?: () => void;
	viewNote?: () => void;
	deleteNote?: () => void;
};

function renderRichText(richText: any) {
	// assign keys for each element
	const jsx = RichTextSerializer.toJSX(richText, true);
	return jsx;
}

const MINUTES_30 = 30 * 60 * 1000;

function Note({
	note,
	showEdit = false,
	showDelete = false,
	showViewNote = false,
	showCopyLink = false,
	editNote = () => {},
	viewNote = () => {},
	deleteNote = () => {},
}: NoteProps) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [copyLinkSuccess, setCopyLinkSuccess] = useState(false);

	const navigate = useNavigate();

	function showMenu(e) {
		setAnchorEl(e.currentTarget);
	}
	function hideMenu() {
		setAnchorEl(null);
	}

	function copyLink() {
		// copy link to clipboard
		if (note.visibility !== "private") {
			const link = `${window.location.origin}/org/${note.valorId}/notes/view/${note.documentId}`;
			navigator.clipboard.writeText(link);
			// show snackbar
			setCopyLinkSuccess(true);
		}
	}
	const showControls = showEdit || showDelete || showViewNote || showCopyLink;

	return (
		<Card variant="outlined" data-cy="view-notes__note-card">
			<CardContent>
				<Box>
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
						flexWrap="wrap"
						gap={1}
					>
						<Box
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							alignItems="center"
							gap={1}
						>
							<UserAvatar
								user={{
									id: note.authorId,
									name: note.authorName,
									profilePicture: note.profilePicture,
								}}
							/>
							<Typography variant="body1" style={{ fontWeight: "bold" }}>
								{note.authorName}
							</Typography>
							<Typography variant="body2">
								{dayjs((note.updatedAt || note.createdAt) * 1000).fromNow()}
								{note.updatedAt > 0 &&
									note.updatedAt > note.createdAt + MINUTES_30 &&
									" (edited)"}
							</Typography>
						</Box>
						<Box
							display="flex"
							flexDirection="row"
							justifyContent="flex-end"
							alignItems="center"
							flexWrap="wrap"
							gap={1}
						>
							<Box
								display="flex"
								flexDirection="row"
								justifyContent="flex-end"
								alignItems="center"
								flexWrap="wrap"
								gap={1}
							>
								{note.processRelationsInternalIds.map((internalId) => (
									<Button
										key={internalId}
										onClick={() => navigate(`/redirect-task/${internalId}`)}
									>
										Go to linked task
									</Button>
								))}
							</Box>

							<Chip
								color="primary"
								variant="outlined"
								label={note.meetingType}
								sx={{
									maxWidth: {
										xs: "100px",
										sm: "100%",
									},
								}}
							/>
							{note.visibility === "private" ? (
								<Chip icon={<LockIcon />} label="Private" variant="filled" />
							) : (
								<Chip
									icon={<PublicIcon />}
									label="Public"
									variant="filled"
									color="primary"
								/>
							)}
							{showControls && (
								<IconButton
									color="primary"
									onClick={(e) => showMenu(e)}
									size="small"
									data-cy="note-card__menu"
								>
									<MoreVertIcon />
								</IconButton>
							)}
							<Menu
								id={`note-${note.documentId}-menu`}
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={() => hideMenu()}
							>
								{showEdit && (
									<MenuItem
										onClick={() => {
											editNote();
											hideMenu();
										}}
										data-cy="note-card__menu-edit"
									>
										<ListItemIcon>
											<EditIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText primary="Edit" />
									</MenuItem>
								)}
								{showDelete && (
									<MenuItem
										onClick={() => {
											deleteNote();
											hideMenu();
										}}
										data-cy="note-card__menu-delete"
									>
										<ListItemIcon>
											<DeleteIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText primary="Delete" />
									</MenuItem>
								)}
								{showViewNote && (
									<MenuItem
										onClick={() => {
											hideMenu();
											viewNote();
										}}
										data-cy="note-card__menu-view-note"
									>
										<ListItemIcon>
											<LaunchIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText primary="View Note" />
									</MenuItem>
								)}
								{showCopyLink && <Divider />}
								{showCopyLink && (
									<MenuItem
										onClick={() => {
											copyLink();
											hideMenu();
										}}
										data-cy="note-card__menu-copy-link"
										disabled={note.visibility === "private"}
									>
										<ListItemIcon>
											<CopyLinkIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText primary="Copy Link to Note" />
									</MenuItem>
								)}
							</Menu>
						</Box>
					</Box>
					<Box marginTop={1}>
						<Typography variant="body2">
							Date: {dayjs(note.meetingDate * 1000).format("MMM D, YYYY")}
						</Typography>
					</Box>
					<Box marginTop={2}>
						<Typography variant="h2">{note.title}</Typography>
					</Box>
					<Box marginTop={1}>
						{renderRichText(note.richText) || (
							<Typography variant="body1">{note.text}</Typography>
						)}
					</Box>
				</Box>
			</CardContent>

			<Snackbar
				open={copyLinkSuccess}
				autoHideDuration={6000}
				onClose={() => setCopyLinkSuccess(false)}
			>
				<Alert severity="success">Link copied to clipboard</Alert>
			</Snackbar>
		</Card>
	);
}

export default Note;
