import { getOrganizationFatIds } from "@/api/Organization";
import {
	VirtualTableBody,
	VirtualTableContainer,
} from "@/components/InfiniteTable";
import HeaderCell from "@/components/Table/Header/Cell";
import {
	BooleanCell,
	CompanyCell,
	TextCell,
} from "@/components/Table/Primitives";
import { StickyTable, StickyTableHead } from "@/components/Table/StickyTable";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Alert, Box, Button, Card, TableRow, Typography } from "@mui/material";
import {
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	useReactTable,
} from "@tanstack/react-table";
import React, { useMemo } from "react";
import { useQuery } from "react-query";

export default function OverrideTable({
	valorIds,
}: {
	valorIds: string[];
}) {
	const pagedDomainKey = useMemo(() => ["Domains", ...valorIds], [valorIds]);
	const { data, isLoading, isError } = useQuery(
		pagedDomainKey,
		({ pageParam = null }) => {
			if (valorIds.length > 0) {
				return getOrganizationFatIds(50, pageParam, [], {
					valorIds,
				});
			}
			return [];
		},
	);

	const headerMappings = {
		domain: "Domain",
		pbDomain: "Pitchbook",
		pdlDomain: "PDL",
		afDomain: "Affinity",
		sm2Domain: "Second Measure",
		ceDomain: "Consumer Edge",
		syDomain: "Synaptic",
		dfDomain: "Diffbot",
		srDomain: "Semrush",
	};

	const columnDef = useMemo(
		() => [
			{
				header: "Organization",
				accessorFn: (row) => row,
				cell: (prop) => <CompanyCell value={prop.getValue()} />,
				id: "organization",
			},
			...Object.keys(headerMappings).map((key) => ({
				header: headerMappings[key],
				accessorFn: (row) => row[key],
				cell: (prop) => <TextCell value={prop.getValue()} />,
				id: key.toString(),
			})),
			{
				header: "Has Matching Domains",
				accessorFn: (row) => row,
				cell: (prop) => {
					// if any of the domain values exist and are not equal
					const value = prop.getValue();
					const domains = Object.keys(headerMappings);
					if (domains.length > 1) {
						const values = domains.map((x) => value[x]).filter((x) => x);
						const uniqueValues = Array.from(new Set(values));
						return <BooleanCell value={uniqueValues.length === 1} />;
					}
					return <BooleanCell value />;
				},
			},
			{
				header: "Actions",
				accessorFn: (row) => row.valorId,
				cell: (prop) => (
					<Button
						href={`/org/${prop.getValue()}/override`}
						target="_blank"
						endIcon={<OpenInNewIcon />}
						variant="outlined"
					>
						Override
					</Button>
				),
				id: "actions2",
			},
		],
		[data],
	);

	const table = useReactTable({
		data: data?.data || [],
		columns: columnDef,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	});

	if (isError) {
		return (
			<Card>
				<Alert severity="error">There was an error loading the data.</Alert>
			</Card>
		);
	}

	return (
		<Card>
			{isLoading && (
				<CenteredProgress
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				/>
			)}

			<Box
				px={1}
				py={2}
				maxWidth="100%"
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between"
					gap={1}
					width="100%"
				>
					<Typography variant="h3">Domain Mapping</Typography>
				</Box>
			</Box>

			<VirtualTableContainer height="calc(100vh - 300px)">
				<StickyTable>
					<StickyTableHead>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<HeaderCell
										key={header.id}
										header={header}
										column={header.column}
										table={table}
									/>
								))}
							</TableRow>
						))}
					</StickyTableHead>
					<VirtualTableBody
						rows={table.getRowModel().rows}
						estimateSize={100}
						table={table}
					/>
				</StickyTable>
			</VirtualTableContainer>
		</Card>
	);
}
