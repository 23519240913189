import CenteredProgress from "@/ui/atoms/CenteredProgress";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import ErrorMessage from "@/ui/atoms/ErrorMessage";
import Affinity from "@/ui/atoms/Icons/Affinity";
import UserAvatar from "@/ui/atoms/UserAvatar";
import { titleCase } from "@/utils/titleCase";
import {
	Box,
	ButtonBase,
	Link,
	Popover,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import dayjs from "dayjs";
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useQuery } from "react-query";

import LockIcon from "@mui/icons-material/Lock";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { getKeyFrameworkAnswers } from "@/api/FrameworkSurvey";
import { getManagementData } from "@/api/Users";
import { FrameworkChoiceColorIconMapping } from "@/constants/FrameworkChoiceColorIconMapping";
import {
	canReadManagementDashboard,
	canSeeSurveyDashboard,
} from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import SingleSelect from "@/ui/atoms/SingleSelect";
import SelectColumnFilter from "@/ui/atoms/TableFilters/SelectColumnFilter";
import CustomTable from "@/ui/molecules/CustomTable";
import Page from "@/ui/molecules/Page";

const dateOptions = {
	custom: "Custom",
	last_week: "Last week",
	last_two_weeks: "Last two weeks",
	last_month: "Last month",
	all_time: "All Time",
};

export function ManagementDashboard() {
	const { user } = useAuth();
	const theme = useTheme();
	const [dateRange, setDateRange] = useState("last_week");

	const renderPopOverContent = ({
		color,
		commentary,
		attributeName,
		choiceText,
	}) => {
		let questionSpecificVisualization = null;
		const Icon = FrameworkChoiceColorIconMapping[color];
		questionSpecificVisualization = (
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					minWidth: "calc(100% - 32px)",
					height: theme.spacing(10),
					padding: 2,
					borderRadius: 1,
					border: `1px solid ${theme.palette?.[color]?.main}`,
				}}
			>
				<Icon
					style={{
						color: theme.palette?.[color]?.main,
						marginRight: theme.spacing(1),
					}}
				/>
				{choiceText}
			</Box>
		);

		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "420px",
					padding: 2,
				}}
			>
				{attributeName}
				{questionSpecificVisualization}
				{Boolean(commentary?.length) && (
					<Box
						width="100%"
						display="flex"
						flexDirection="column"
						justifyContent="flex-start"
					>
						<Typography
							variant="body2"
							sx={{
								padding: theme.spacing(0.5, 0, 1, 0),
								color: theme.palette.text.secondary,
								fontWeight: theme.typography.fontWeightBold,
								textAlign: "left",
							}}
						>
							COMMENT
						</Typography>
						<Typography variant="body2">{commentary}</Typography>
					</Box>
				)}
			</Box>
		);
	};

	const today = dayjs();

	const mapDate = {
		custom: null,
		last_week: today.subtract(7, "days"),
		last_two_weeks: today.subtract(14, "days"),
		last_month: today.subtract(30, "days"),
		all_time: null,
	};

	const [sinceDate, setSinceDate] = useState(mapDate.last_week);

	const onChangeDateRange = (range) => {
		setDateRange(range);
		setSinceDate(mapDate[range]);
	};

	const minTakenDate = (sinceDate || dayjs("2020-01-01")).format("YYYY-MM-DD");

	const { data: userData = [], isLoading: isLoadingManagementStats } = useQuery(
		["ManagementData", minTakenDate],
		async () => {
			const response = await getManagementData({ sinceDate: minTakenDate });
			return response;
		},
	);

	const { data: answerData = [], isLoading: isLoadingAnswerData } = useQuery(
		["KeyFrameworkAnswers", minTakenDate],
		async () => {
			const response = await getKeyFrameworkAnswers({
				sinceDate: minTakenDate,
			});
			return response;
		},
	);

	const userCell = ({
		row: {
			original: { firstName, lastName, profilePicture },
		},
	}) => (
		<div style={{ display: "flex" }}>
			<UserAvatar
				user={{
					name: `${firstName} ${lastName}`,
					profilePicture,
				}}
			/>
			<Box ml={1} mt={1}>
				{`${firstName} ${lastName}`}
			</Box>
		</div>
	);

	const managementColumns = React.useMemo(
		() => [
			{
				Header: "User",
				accessor: (row) => `${row.firstName} ${row.lastName}`,
				Cell: userCell,
				disableFilters: true,
			},
			{
				Header: "Role",
				accessor: "role",
				Filter: SelectColumnFilter,
				filter: "equals",
			},
			{
				Header: "Surveys Taken",
				accessor: "surveysTaken",
				disableFilters: true,
			},
			{
				Header: "Overdue Tasks",
				accessor: "overdueTasks",
				disableFilters: true,
			},
			{
				Header: "Upcoming Tasks",
				accessor: "upcomingTasks",
				disableFilters: true,
			},
		],
		[],
	);

	const answerColumns = React.useMemo(
		() => [
			{
				Header: "User",
				accessor: (row) => `${row.firstName} ${row.lastName}`,
				Cell: userCell,
				disableFilters: true,
			},
			{
				Header: "Company",
				accessor: "companyName",
				Cell: ({
					row: {
						original: { companyName, valorId, affId, logoUrl, domain },
					},
				}) =>
					companyName?.length ? (
						<Box display="flex">
							<CompanyAvatar
								src={logoUrl}
								name={companyName}
								domain={domain}
								size="40"
							/>
							<Box ml={1} display="flex" flexDirection="column">
								<Link href={`/org/${valorId}`}>{companyName}</Link>
								{affId && (
									<Tooltip title="Go to Affinity" aria-label="affinity">
										<ButtonBase
											style={{ width: "15px", height: "15px" }}
											onClick={() =>
												window.open(
													`https://valor.affinity.co/companies/${affId}`,
													"_blank",
												)
											}
										>
											<Affinity />
										</ButtonBase>
									</Tooltip>
								)}
							</Box>
						</Box>
					) : (
						"N/A"
					),
				disableFilters: true,
			},
			{
				Header: "Answer Type",
				accessor: "answerType",
				Cell: ({
					row: {
						original: { answerType },
					},
				}) => titleCase(answerType),
				Filter: ({ column }) => (
					<SelectColumnFilter column={column} labelFormatter={titleCase} />
				),
			},
			{
				Header: "Answer",
				accessor: "answerId",
				Cell: ({ row: { original } }) => {
					const anchorRef = React.useRef();
					const [openPopOver, setOpenPopover] = useState(false);

					const { attributeName, color } = original;
					const Icon = FrameworkChoiceColorIconMapping[color];
					return (
						<>
							<Box
								ref={anchorRef}
								onClick={() => setOpenPopover(true)}
								style={{ cursor: "pointer" }}
								display="flex"
								flexDirection="row"
								alignItems="center"
							>
								{attributeName}
								<Box
									sx={{
										border: `1px solid ${theme.palette?.[color]?.main}`,
										marginLeft: "8px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										borderRadius: 1,
									}}
								>
									<Icon
										style={{
											color: theme.palette?.[color]?.main,
											margin: "2px",
										}}
									/>
								</Box>
							</Box>
							<Popover
								open={openPopOver}
								anchorEl={anchorRef.current}
								onClose={() => setOpenPopover(false)}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "center",
								}}
								transformOrigin={{
									vertical: "top",
									horizontal: "center",
								}}
								classes={{
									paper: {},
								}}
								disableScrollLock
							>
								{renderPopOverContent(original)}
							</Popover>
						</>
					);
				},
				disableFilters: true,
				disableSortBy: true,
			},
			{
				Header: "Survey",
				accessor: "surveyName",
				Cell: ({
					row: {
						original: { surveyName, completedAt },
					},
				}) => (
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
						alignItems="center"
					>
						<div>{surveyName}</div>
						{dayjs(completedAt).format("MMM DD, YYYY")}
					</Box>
				),
				disableFilters: true,
			},
		],
		[],
	);

	if (!canReadManagementDashboard(user) || !canSeeSurveyDashboard(user)) {
		return (
			<ErrorMessage
				Icon={<LockIcon />}
				title="Unauthorized"
				message={
					<>
						You don’t have access to view Management Hub page. If you think this
						is an error contact{" "}
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<Link
							onClick={(e) => {
								window.location.href =
									"mailto:labs@valorep.com?subject=Management Page Permissions";
								e.preventDefault();
							}}
						>
							labs@valorep.com
						</Link>
					</>
				}
			/>
		);
	}

	return (
		<CenteredProgress
			isLoading={isLoadingAnswerData || isLoadingManagementStats}
			displayChildren
		>
			<Helmet>
				<title>Management Tools - vOS</title>
			</Helmet>

			<Page>
				<Page.Title data-cy="management-tools__title">
					Survey Management Dashboard
				</Page.Title>
				<Page.Content>
					<Box display="flex" justifyContent="flex-end">
						<SingleSelect
							title="Since"
							value={dateRange}
							onChange={onChangeDateRange}
							options={dateOptions}
						/>

						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								view="day"
								format="MMMM-DD-YYYY"
								label="Since Date"
								value={sinceDate}
								onChange={setSinceDate}
								disabled={dateRange !== "custom"}
								id="date-picker-since-date"
								slotProps={{
									textField: { size: "small" },
								}}
								sx={{
									mt: 1,
								}}
							/>
						</LocalizationProvider>
					</Box>
					<Box mt={1}>
						<CustomTable
							title="Key Survey Answers"
							data={answerData}
							columns={answerColumns}
						/>
					</Box>
					<Box mt={1}>
						<CustomTable
							title="System Compliance Statistics"
							data={userData}
							columns={managementColumns}
						/>
					</Box>
				</Page.Content>
			</Page>
		</CenteredProgress>
	);
}

export default ManagementDashboard;
