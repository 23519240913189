import {
	Box,
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Link,
	Skeleton,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import useDebounce from "@/hooks/useDebounce";
import ErrorMessage from "@/ui/atoms/ErrorMessage";
import Section from "@/ui/atoms/Section";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";

import {
	type CompanyNoteResult,
	PAGE_SIZE,
	deleteNote,
	queryCompanyNotes,
} from "@/api/Notes";

import { AuthenticationError } from "@/api/Error";
import { useAuth } from "@/hooks/useAuth";

import useTripwire from "../../useTripwire";
import NotesFilters from "../NotesFilters";
import NotesList from "../NotesList";
import NotesSearch from "../NotesSearch";

function ViewNotes() {
	const params = useParams();
	const queryClient = useQueryClient();
	const { user } = useAuth();
	const valorId = params.id || null;

	const [loading, setLoading] = useState<boolean>();
	const [authorized, setAuthorized] = useState<boolean>(true);
	const [shouldSearch, setShouldSearch] = useState<boolean>(true);
	const [search, setSearch] = useState<string>("");
	const [searchOffset, setSearchOffset] = useState<number>(0);
	const [filter, setFilter] = useState<[string, string, string]>(null);
	const [notes, setNotes] = useState<CompanyNoteResult>(null);
	const [error, setError] = useState(null);

	const [deleteId, setDeleteId] = useState<string>(null);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

	const debouncedSearch = useDebounce(search, 500);

	useTripwire(valorId, "notes");
	const navigate = useNavigate();

	function goToNewNote() {
		navigate(`/org/${valorId}/notes/new`);
	}

	function localFilterResults(results?: CompanyNoteResult): CompanyNoteResult {
		if (!results) {
			return {
				total: {
					value: 0,
					offset: 0,
				},
				hits: [],
			};
		}
		return {
			total: results.total,
			hits: results.hits.filter((note) => {
				if (filter) {
					const [, field, value] = filter;
					return note[field] === value;
				}
				return true;
			}),
		};
	}

	const { isLoading } = useQuery(
		`notes-${valorId}-${debouncedSearch}-${searchOffset}`,
		async () => {
			if (shouldSearch) {
				setLoading(true);
				try {
					const results = await queryCompanyNotes(
						valorId,
						debouncedSearch,
						searchOffset,
					);
					const totalHits =
						searchOffset > 0
							? [...(notes?.hits || []), ...results.hits]
							: results.hits;

					setNotes({
						total: results.total,
						hits: totalHits,
					});
					setError(null);
				} catch (e) {
					if (e instanceof AuthenticationError) {
						setAuthorized(false);
						setLoading(false);
						setNotes(null);
						return;
					}

					if (e instanceof Error) {
						setNotes(null);
						setError("An error occurred.");
					}
				}
				setShouldSearch(false);
				setLoading(false);
			}
		},
	);

	function filtersToShow() {
		if (!notes) {
			return [];
		}
		return Object.keys(
			notes.hits.reduce(
				(acc, note) => {
					const next = {
						...acc,
						[note.meetingType]: true,
					};
					return next;
				},
				{
					"My Notes": true,
				},
			),
		);
	}

	const handleDeleteNote = async (noteId: string) => {
		// show confirmation dialog
		setDeleteId(noteId);
		setDeleteDialogOpen(true);
	};

	const confirmDeleteNote = async () => {
		// delete note
		deleteNote(deleteId);
		setDeleteId(null);
		setDeleteDialogOpen(false);

		// update local
		const newNotes = {
			...notes,
			hits: notes.hits.filter((note) => note.documentId !== deleteId),
		};
		setNotes(newNotes);

		queryClient.setQueriesData(`notes-${valorId}-${debouncedSearch}`, newNotes);
	};

	const searchNotes = useCallback((value: string, offset: number) => {
		setShouldSearch(true);
		setSearch(value);
		setSearchOffset(offset);
	}, []);

	useEffect(() => {
		searchNotes("", 0);
	}, [searchNotes]);

	if (!authorized) {
		return (
			<ErrorMessage
				Icon={<LockIcon />}
				title="Unauthorized"
				message={
					<>
						You don’t have access to Notes for this company. If you think this
						is an error, please contact{" "}
						<Link href="mailto:labs@valorep.com?subject=Notes Access for Company">
							labs@valorep.com
						</Link>
					</>
				}
			/>
		);
	}
	return (
		<Card elevation={0}>
			<Box padding={1}>
				<Section>
					<Section.Title>Notes</Section.Title>
				</Section>
				<Box marginBottom={2}>
					<Button
						variant="contained"
						color="primary"
						onClick={() => goToNewNote()}
						data-cy="view-notes__write-note"
					>
						Write Note
					</Button>
				</Box>
				<Box>
					<NotesSearch
						value={search}
						onUpdate={(value) => searchNotes(value, 0)}
					/>
				</Box>
				<NotesFilters
					userId={user?.id}
					onChange={(label, field, value) => {
						if (filter && filter[0] === label) setFilter(null);
						else setFilter([label, field, value]);
					}}
					filtersToShow={filtersToShow()}
					value={filter && filter[0]}
				/>
				<Box>{error && "An error occurred."}</Box>
				<Box>
					{isLoading ? (
						<Box display="flex" flexDirection="column" sx={{ gap: "8px" }}>
							<Skeleton variant="rectangular" width="100%" height="160px" />
							<Skeleton variant="rectangular" width="100%" height="160px" />
							<Skeleton variant="rectangular" width="100%" height="160px" />
						</Box>
					) : null}
				</Box>
				{notes?.hits?.length === 0 && <Box>No notes found.</Box>}
				<Box>
					<NotesList
						notes={localFilterResults(notes).hits}
						userId={user?.id}
						editNote={(noteId) =>
							navigate(`/org/${valorId}/notes/edit/${noteId}`)
						}
						viewNote={(noteId) =>
							navigate(`/org/${valorId}/notes/view/${noteId}`)
						}
						deleteNote={(noteId) => handleDeleteNote(noteId)}
					/>
				</Box>

				{notes?.hits?.length > 0 &&
					notes?.total.value > searchOffset + PAGE_SIZE && (
						<Box paddingY={2}>
							<Button
								disabled={loading}
								onClick={() => searchNotes(search, searchOffset + PAGE_SIZE)}
								variant="outlined"
								color="primary"
								fullWidth
							>
								{loading ? "Loading..." : "View More"}
							</Button>
						</Box>
					)}
				<Dialog
					open={deleteDialogOpen}
					onClose={() => setDeleteDialogOpen(false)}
					aria-labelledby="delete-note-dialog"
					aria-describedby="delete-note-dialog-description"
				>
					<DialogTitle id="delete-note-dialog">Delete Note?</DialogTitle>
					<DialogContent>
						<DialogContentText id="delete-note-dialog-description">
							This will delete the note permanently.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => confirmDeleteNote()}
							color="secondary"
							variant="outlined"
							startIcon={<DeleteIcon />}
							data-cy="confirm-delete__yes"
						>
							Yes
						</Button>
						<Button
							onClick={() => setDeleteDialogOpen(false)}
							color="primary"
							variant="contained"
							data-cy="confirm-delete__no"
						>
							No
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
		</Card>
	);
}

export default ViewNotes;
