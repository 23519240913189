import { Box, MenuItem, TextField, useTheme } from "@mui/material";
import React from "react";

type FilterValue = {
	min: number | null;
	max: number | null;
};
type FilterProps = {
	getFilterValue: () => FilterValue;
	setFilterValue: (value: FilterValue) => void;
};

type NumberRange = {
	min: number | null;
	max: number | null;
};

export default function NumberRange({
	getFilterValue,
	setFilterValue,
}: FilterProps) {
	const theme = useTheme();

	const currentValue = (getFilterValue() as NumberRange) ?? {
		min: null,
		max: null,
	};
	return (
		<Box width={theme.spacing(45)} padding={1}>
			<Box display="flex" justifyContent="space-between" gap={1}>
				<TextField
					value={currentValue.min ?? ""}
					onChange={(e) => {
						const { value } = e.target;
						setFilterValue({
							min: value ? Number.parseInt(value, 10) : null,
							max: currentValue.max,
						});
					}}
					label="From"
					type="number"
				/>
				<TextField
					value={currentValue.max ?? ""}
					onChange={(e) => {
						const { value } = e.target;
						setFilterValue({
							min: currentValue.min,
							max: value ? Number.parseInt(value, 10) : null,
						});
					}}
					label="To"
					type="number"
				/>
			</Box>
			<Box
				mt={1}
				pt={1}
				sx={{ borderTop: `1px solid ${theme.palette.divider}` }}
			>
				<MenuItem
					disabled={!currentValue.min && !currentValue.max}
					onClick={() => setFilterValue(undefined)}
				>
					Clear
				</MenuItem>
			</Box>
		</Box>
	);
}
