import SAYTCompanyResult from "@/ui/atoms/SAYTCompanyResult";
import SearchBar from "@/ui/molecules/SearchBar";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";
/* eslint-disable react/prop-types */
import React, { useState } from "react";

export default function AddCompanyDialog({
	open,
	handleClose,
	handleAddClick,
	search,
}) {
	const [selectedCompanies, setSelectedCompanies] = useState([]);

	const onAddClick = () => {
		handleAddClick(selectedCompanies.map((company) => company.valorId));
		setSelectedCompanies([]);
		handleClose(false);
	};

	const handleRemove = (valorId) => {
		setSelectedCompanies(
			selectedCompanies.filter((company) => company.valorId !== valorId),
		);
	};

	const handleNewCompanySelected = ({ valorId, name, domain, logoUrl }) => {
		if (!selectedCompanies.some((c) => c.valorId === valorId)) {
			setSelectedCompanies([
				...selectedCompanies,
				{
					valorId,
					name,
					domain,
					logoUrl,
				},
			]);
		}
	};

	return (
		<Dialog
			PaperProps={{
				style: {
					position: "inherit",
				},
			}}
			fullWidth
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="add-company-dialog-title">Add company</DialogTitle>
			<DialogContent>
				<DialogContentText>Search for a company to add.</DialogContentText>
				<SearchBar
					autoFocus
					showSAYT
					selectTerm={handleNewCompanySelected}
					entity="company"
					search={search}
					placeholder="Search..."
				/>
				{selectedCompanies.map((company) => (
					<Box
						id="added-companies"
						padding={2}
						borderBottom={1}
						borderColor="rgba(49, 49, 49, .1)"
						key={company.valorId}
					>
						<SAYTCompanyResult
							valorId={company.valorId}
							handleRemove={() => handleRemove(company.valorId)}
							title={company.name}
							domain={company.domain}
							logoUrl={company.logoUrl}
						/>
					</Box>
				))}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancel
				</Button>
				<Button onClick={onAddClick} color="primary">
					Add
				</Button>
			</DialogActions>
		</Dialog>
	);
}
