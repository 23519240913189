import { svgElToSvgFile } from "@/utils/svgElToSvgFile";
import {
	Box,
	ButtonBase,
	type Theme,
	Tooltip,
	createStyles,
	makeStyles,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		download: {
			color: theme.palette.text.secondary,
			padding: theme.spacing(0.5),
			borderRadius: "100%",
		},
	}),
);

const boxStyle = { flex: 1, margin: "1%" };
const margin = {
	top: 16,
	right: 32,
	bottom: 16,
	left: 16,
};

interface Props {
	children: React.ReactElement;
	metric: {
		label: string;
		id: string;
		subtitle?: string;
	};
	currentCompany: string;
	height: number;
	defaultHighlightedCompany: string;
}
export default function CompetitorChart(props: Props) {
	const {
		metric,
		currentCompany,
		children,
		defaultHighlightedCompany,
		height,
	} = props;
	const classes = useStyles();
	const [highlightedCompany, setHighlightedCompany] = useState(
		defaultHighlightedCompany,
	);

	useEffect(() => {
		setHighlightedCompany(defaultHighlightedCompany);
	}, [defaultHighlightedCompany]);

	return (
		<Box
			style={boxStyle}
			boxShadow={1}
			bgcolor="background.paper"
			borderRadius="10px"
			p={1}
		>
			{React.cloneElement(children, {
				highlightedCompany,
				setHighlightedCompany,
				margin,
				height,
				metric,
			})}
			<Box>
				<Tooltip title="Download Chart as Image" placement="top">
					<ButtonBase
						onClick={() =>
							svgElToSvgFile(
								document.getElementById(metric.label),
								`${metric.label}-${currentCompany}.svg`,
							)
						}
						className={classes.download}
					>
						<GetAppIcon />
					</ButtonBase>
				</Tooltip>
			</Box>
		</Box>
	);
}

CompetitorChart.propTypes = {
	children: PropTypes.element.isRequired,
	metric: PropTypes.shape({
		label: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		subtitle: PropTypes.string,
	}).isRequired,
	currentCompany: PropTypes.string.isRequired,
	height: PropTypes.number.isRequired,
	defaultHighlightedCompany: PropTypes.string.isRequired,
};
