import { flexRender } from "@tanstack/react-table";
import React, { memo } from "react";

const TableCell = memo(
	({
		cell,
		background,
		columnSize,
		theme,
	}: {
		cell: any;
		background: string;
		columnSize: number;
		theme: any;
	}) => (
		<td
			style={{
				background,
				borderBottom: `1px solid ${theme.palette.white.dark}`,
				borderRight: `1px solid ${theme.palette.divider}`,
				boxSizing: "border-box",
				minWidth: `${columnSize}px`,
				width: `${columnSize}px`,
				maxWidth: `${columnSize}px`,
			}}
		>
			{flexRender(cell.column.columnDef.cell, cell.getContext())}
		</td>
	),
);

TableCell.displayName = "TableCell";
export default TableCell;
