import { Box } from "@mui/material";
import React from "react";
import BooleanInput from "./BooleanInput";
import CompanyInput from "./CompanyInput";
import DateInput from "./DateInput";
import DateTimeInput from "./DateTimeInput";
import DollarInput from "./DollarInput";
import NumberInput from "./NumberInput";
import PersonInput from "./PersonInput";
import ProbabilityEquityValueInput from "./ProbabilityEquityValueInput";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";
import UserInput from "./UserInput";

type InputProps = {
	type: string;
	name: string;
	value: any;
	onChange: (value: any) => void;
	onBlur?: (value: any) => void;
	choices?: any[];
	isOrganizationField?: boolean;
	organizationName?: string;
	required?: boolean;
	disabled?: boolean;
};

export default function Input({
	type,
	name,
	value,
	onChange,
	onBlur,
	choices,
	isOrganizationField,
	organizationName,
	required,
	disabled,
	error,
	dataCy,
}: InputProps) {
	if (type === "company") {
		return (
			<Box
				sx={(t) => ({
					"& > div > div > label": {
						color: isOrganizationField ? t.palette.primary.main : null,
					},
				})}
			>
				<CompanyInput
					value={value}
					onChange={(company) => {
						onChange(company);
					}}
					onBlur={onBlur}
					required={required}
					error={error}
					disabled={disabled}
					label={`${name} ${
						isOrganizationField && organizationName
							? `- ${organizationName}`
							: ""
					}`}
					dataCy={dataCy}
				/>
			</Box>
		);
	}
	if (type === "user" || type === "user_multi") {
		return (
			<Box
				sx={(t) => ({
					"& > div > div > label": {
						color: isOrganizationField ? t.palette.primary.main : null,
					},
				})}
			>
				<UserInput
					value={value}
					onChange={(user) => {
						onChange(user);
						onBlur(user);
					}}
					multiple={type === "user_multi"}
					required={required}
					disabled={disabled}
					label={`${name} ${
						isOrganizationField && organizationName
							? `- ${organizationName}`
							: ""
					}`}
					dataCy={dataCy}
					error={error}
				/>
			</Box>
		);
	}
	if (type === "person" || type === "person_multi") {
		return (
			<Box
				sx={(t) => ({
					"& > div > div > label": {
						color: isOrganizationField ? t.palette.primary.main : null,
					},
				})}
			>
				<PersonInput
					value={value}
					onChange={(person) => {
						onChange(person);
						onBlur(person);
					}}
					multiple={type === "person_multi"}
					required={required}
					disabled={disabled}
					label={`${name} ${
						isOrganizationField && organizationName
							? `- ${organizationName}`
							: ""
					}`}
					dataCy={dataCy}
					error={error}
				/>
			</Box>
		);
	}
	if (type === "date" || type === "target_date") {
		return (
			<Box>
				<DateInput
					value={value}
					onChange={(date: string) => {
						onChange(date);
						onBlur(date);
					}}
					label={`${name} ${
						isOrganizationField && organizationName
							? `- ${organizationName}`
							: ""
					}`}
					required={required}
					disabled={disabled}
					dataCy={dataCy}
					error={error}
				/>
			</Box>
		);
	}
	if (type === "date_time") {
		return (
			<DateTimeInput
				value={value}
				onChange={(date: string) => {
					onChange(date);
					onBlur(date);
				}}
				label={`${name} ${
					isOrganizationField && organizationName ? `- ${organizationName}` : ""
				}`}
				required={required}
				disabled={disabled}
				dataCy={dataCy}
				error={error}
			/>
		);
	}
	if (type === "select" || type === "select_multi") {
		const multiple = type === "select_multi";
		const label = `${name} ${
			isOrganizationField && organizationName ? `- ${organizationName}` : ""
		}`;
		const selectOptions = choices?.map((choice) => ({
			value: choice.id,
			label: choice.value,
		}));
		return (
			<SelectInput
				value={value}
				onBlur={(v) => onBlur(v)}
				onChange={(v) => onChange(v)}
				options={selectOptions}
				label={label}
				required={required}
				multiple={multiple}
				sx={(t) => ({
					"& > label": {
						color: isOrganizationField ? t.palette.primary.main : null,
					},
					width: "100%",
				})}
				dataCy={dataCy}
				disabled={disabled}
				error={error}
			/>
		);
	}

	if (type === "ryg") {
		const values = ["R", "Y", "G"];
		const rygOptions = values.map((v) => ({
			value: v,
			label: v,
		}));
		const label = name;
		return (
			<SelectInput
				value={value}
				onBlur={(v) => onBlur(v)}
				onChange={(v) => onChange(v)}
				options={rygOptions}
				label={label}
				required={required}
				dataCy={dataCy}
				disabled={disabled}
				error={error}
			/>
		);
	}

	if (type === "checkbox") {
		return (
			<BooleanInput
				value={value}
				onChange={(v) => {
					onChange(v);
					onBlur?.(v);
				}}
				label={`${name} ${
					isOrganizationField && organizationName ? `- ${organizationName}` : ""
				}`}
				required={required}
				disabled={disabled}
				dataCy={dataCy}
				error={error}
			/>
		);
	}

	if (type === "dollar") {
		return (
			<DollarInput
				label={`${name} ${
					isOrganizationField && organizationName ? `- ${organizationName}` : ""
				}`}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				disabled={disabled}
				required={required}
				error={error}
				dataCy={dataCy}
			/>
		);
	}

	if (type === "number") {
		return (
			<NumberInput
				label={`${name} ${
					isOrganizationField && organizationName ? `- ${organizationName}` : ""
				}`}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				disabled={disabled}
				required={required}
				error={error}
				dataCy={dataCy}
			/>
		);
	}

	if (type === "probability_equity_value_json") {
		return (
			<ProbabilityEquityValueInput
				value={value}
				onBlur={onBlur}
				required={required}
				disabled={disabled}
				error={error}
				dataCy={dataCy}
				label={`${name} ${
					isOrganizationField && organizationName ? `- ${organizationName}` : ""
				}`}
			/>
		);
	}

	return (
		<TextInput
			label={`${name} ${
				isOrganizationField && organizationName ? `- ${organizationName}` : ""
			}`}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			multiple={type === "text_multi"}
			disabled={disabled}
			required={required}
			dataCy={dataCy}
			error={error}
		/>
	);
}
