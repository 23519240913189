import {
	addOrgAssociation,
	createCellMember,
	createDimension,
	createDimensionValue,
	createMap,
	deleteCellMember,
	deleteDimensionValue,
	deleteMap,
	deleteOrgAssociation,
	getAssociatedOrgs,
	getCellMembers,
	getMarketMap,
	patchDimension,
	patchDimensionValue,
	updateMap,
} from "@/api/MarketMap";
import { Box } from "@mui/material";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useReducer, useState, useMemo, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { MapClassifierFooter } from "./MapClassifierFooter";
import { MapCollector } from "./MapCollector";
import { MapEditor } from "./MapEditor";
import { MapHeader } from "./MapHeader";
import { MapStep } from "./MapStepEnum";
import { MapSubHeader } from "./MapSubHeader";
import buildCoordinate from "./buildCoordinate";

const initialMarketMapState = {
	id: null,
	title: "Map Title",
	isDraft: true,
	step: MapStep.Plan,
	segment: null,
	cells: {},
	columnId: crypto.randomUUID(),
	rowId: crypto.randomUUID(),
	rowValues: {}, // {id : {name, tooltip}}
	columnValues: {},
	columnTitle: null,
	rowTitle: null,
	columns: {},
	rows: {},
	companies: [],
	filters: {
		totalRaised: { range: [0, Number.POSITIVE_INFINITY] },
		employeeCount: { range: [1, Number.POSITIVE_INFINITY] },
		usOnly: { value: false },
		lastFunding: { values: [] },
		allowNull: false,
	},
};

const reducer = (state, action) => {
	switch (action.type) {
		case "changeValue":
			return { ...state, [action.field]: action.value };
		case "appendValue":
			return {
				...state,
				[action.field]: [...state[action.field], action.value],
			};
		case "replaceState":
			return { ...action.state };
		case "mergeState":
			return { ...state, ...action.state };
		case "changeArrayValue": {
			const { field, index, value } = action;
			const newState = { ...state };
			newState[field][index] = value;
			return newState;
		}
		default:
			throw new Error();
	}
};

export class Mode {
	static Create = new Mode("create");

	static Edit = new Mode("edit");

	constructor(name) {
		this.name = name;
	}
}

export function MarketMapCreateEdit() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { id: urlMapId } = useParams();
	const mode = urlMapId ? Mode.Edit : Mode.Create;
	const [isBusy, setBusy] = useState(false);

	const [mapState, dispatchMap] = useReducer(reducer, initialMarketMapState);

	const [currentlyMappedValorId, setCurrentlyMappedValorId] = useState(null);

	useEffect(() => {
		const fetchMap = async () => {
			setBusy(true);
			const map = await getMarketMap(urlMapId);
			const cellMembers = await getCellMembers(urlMapId);
			const associatedOrgs = await getAssociatedOrgs(urlMapId);

			const rows = map.row?.values?.reduce((acc, curr) => {
				acc[curr.id] = { name: curr.name, tooltip: curr.tooltip };
				return acc;
			}, {});
			const columns = map.column?.values?.reduce((acc, curr) => {
				acc[curr.id] = { name: curr.name, tooltip: curr.tooltip };
				return acc;
			}, {});

			const rowValues = map.row?.values?.map((x) => x.id) ?? [];
			const colValues = map.column?.values?.map((x) => x.id) ?? [];
			let cells = rowValues
				.flatMap((rowId) =>
					colValues.flatMap((colId) =>
						buildCoordinate({ rows: rowId, columns: colId }),
					),
				)
				.reduce((acc, curr) => {
					acc[curr] = [];
					return acc;
				}, {});
			cells = cellMembers.reduce((acc, curr) => {
				const coord = buildCoordinate({
					rows: curr.rowDimensionValueId,
					columns: curr.colDimensionValueId,
				});
				acc[coord].push(curr.valorId);
				return acc;
			}, cells);

			const priorState = {
				id: urlMapId,
				title: map.title,
				segment: map.segment,
				cells,
				columnTitle: map.column?.name || "Column Title",
				columnId: map.column?.id || crypto.randomUUID(),
				columns: columns || initialMarketMapState.columns,
				rowTitle: map.row?.name || "Row Title",
				rowId: map.row?.id || crypto.randomUUID(),
				rows: rows || initialMarketMapState.rows,
				companies: associatedOrgs || [],
				isDraft: map.isDraft,
				filters: map.filters
					? JSON.parse(map.filters)
					: initialMarketMapState.filters,
			};
			setBusy(false);
			dispatchMap({ type: "mergeState", state: priorState });
		};

		const setupMap = async () => {
			const mapId = crypto.randomUUID();
			dispatchMap({ type: "changeValue", field: "id", value: mapId });

			await createMap({
				id: mapId,
				title: "Map Title",
				segment: null,
			});
			await createDimension(mapId, {
				id: mapState.columnId,
				name: "Column Title",
				axis: "column",
			});
			await createDimension(mapId, {
				id: mapState.rowId,
				name: "Row Title",
				axis: "row",
			});
			queryClient.invalidateQueries("getAllMarketMaps");
		};

		if (mode === Mode.Edit) {
			fetchMap();
		}
		if (mode === Mode.Create) {
			setupMap();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const companyLookup = useMemo(
		() =>
			mapState.companies.reduce((acc, curr) => {
				acc[curr.valorId] = curr;
				return acc;
			}, {}),
		[mapState.companies],
	);

	const cellString = JSON.stringify(mapState.cells);

	const companyMemberships = useMemo(() => {
		const members = {};
		Object.keys(mapState.cells).forEach((coord) => {
			mapState.cells[coord]?.forEach((member) => {
				if (members[member]) {
					members[member].push(coord);
				} else {
					members[member] = [coord];
				}
			});
		});
		return members;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cellString]);

	const onCompanySelect = (co) => {
		if (mapState.companies.map((x) => x.valorId).indexOf(co.valorId) !== -1) {
			return;
		}
		addOrgAssociation(mapState.id, co.valorId);
		dispatchMap({
			type: "appendValue",
			field: "companies",
			value: co,
		});
	};

	// field = rows || columns
	const onAddNewDimension = (newName, field) => {
		const opposite = {
			rows: "columns",
			columns: "rows",
		};
		const newId = crypto.randomUUID();
		const copy = { ...mapState };
		copy[field] = { ...copy[field], [newId]: { name: newName, tooltip: "" } };
		const { columns, rows } = copy;
		let { cells } = copy;

		const oppositeField = opposite[field];

		const fieldBuilder = (val) => ({
			[field]: newId,
			[oppositeField]: val,
		});

		if (copy[oppositeField]?.length === 0) {
			cells = { ...cells, [newId]: [] };
		} else {
			cells = {
				...cells,
				...Object.keys(copy[oppositeField]).reduce(
					(acc, curr) => ({
						...acc,
						[buildCoordinate(fieldBuilder(curr))]: [],
					}),
					{},
				),
			};
		}

		if (rows.length && columns.length) {
			cells = Object.fromEntries(
				Object.entries(cells).filter(
					([coords]) => coords.split(",").filter((x) => x.length).length === 2,
				),
			);
		}
		dispatchMap({
			type: "mergeState",
			state: {
				[field]: copy[field],
				cells,
			},
		});
		const dimensionId = mapState[field === "rows" ? "rowId" : "columnId"];

		createDimensionValue(mapState.id, dimensionId, {
			id: newId,
			name: newName,
			dimensionId,
			sort: Object.keys(copy[field]).length,
		});
	};

	const onRenameDimension = (newName, id, field) => {
		const copy = { ...mapState };

		copy[field][id].name = newName;
		dispatchMap({
			type: "mergeState",
			state: {
				[field]: copy[field],
			},
		});
	};

	const onDeleteDimension = (id, field) => {
		const copy = { ...mapState };
		delete copy[field][id];

		const newCells = Object.keys(copy.cells)
			.filter((coords) => coords.indexOf(id) === -1)
			.reduce((acc, curr) => ({ ...acc, [curr]: copy.cells[curr] }), {});

		deleteDimensionValue(
			mapState.id,
			mapState[field === "rows" ? "rowId" : "columnId"],
			id,
		);
		dispatchMap({
			type: "mergeState",
			state: {
				[field]: copy[field],
				cells: newCells,
			},
		});
	};

	const onRemoveCompany = (coValorId) => {
		if (currentlyMappedValorId === coValorId) {
			setCurrentlyMappedValorId(null);
		}
		const copy = { ...mapState };
		let { companies } = copy;
		const { cells } = copy;
		companies = copy.companies.filter((x) => x.valorId !== coValorId);
		Object.keys(cells).forEach((coordinate) => {
			if (cells[coordinate].indexOf(coValorId) !== -1) {
				cells[coordinate] = cells[coordinate].filter((x) => x !== coValorId);
			}
		});
		deleteOrgAssociation(mapState.id, coValorId);
		dispatchMap({
			type: "mergeState",
			state: {
				companies,
				cells,
			},
		});
	};

	const onCellClick = (row, col) => {
		const coordinate = buildCoordinate({ rows: row, columns: col });
		if (mapState.cells[coordinate].indexOf(currentlyMappedValorId) === -1) {
			dispatchMap({
				type: "changeValue",
				field: "cells",
				value: {
					...mapState.cells,
					[coordinate]: [...mapState.cells[coordinate], currentlyMappedValorId],
				},
			});
			createCellMember(mapState.id, {
				valorId: currentlyMappedValorId,
				rowDimensionValueId: row,
				colDimensionValueId: col,
			});
		} else {
			dispatchMap({
				type: "changeValue",
				field: "cells",
				value: {
					...mapState.cells,
					[coordinate]: mapState.cells[coordinate].filter(
						(x) => x !== currentlyMappedValorId,
					),
				},
			});
			deleteCellMember(mapState.id, {
				valorId: currentlyMappedValorId,
				rowDimensionValueId: row,
				colDimensionValueId: col,
			});
		}
	};

	const onChangeTooltip = (tooltip, id, field) => {
		const copy = { ...mapState };
		copy[field][id].tooltip = tooltip;
		dispatchMap({
			type: "mergeState",
			state: {
				[field]: copy[field],
			},
		});
	};

	if (isBusy) return <div>Loading</div>;

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				flexGrow: 1,
				maxHeight: "100%",
				overflow: "hidden",
			}}
		>
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<MapHeader
					currentlyMappedValorId={currentlyMappedValorId}
					setCurrentlyMappedValorId={setCurrentlyMappedValorId}
					mapTitle={mapState.title}
					segment={mapState.segment}
					viewMap={() => navigate(`/market-maps/view/${mapState.id}`)}
					onMapTitleChange={(val) => {
						queryClient.invalidateQueries("getAllMarketMaps");
						updateMap({
							id: mapState.id,
							title: val,
							segment: mapState.segment,
						});
						dispatchMap({
							type: "changeValue",
							field: "title",
							value: val,
						});
					}}
					onMapClassificationChange={(seg) => {
						queryClient.invalidateQueries("getAllMarketMaps");
						dispatchMap({
							type: "mergeState",
							state: {
								segment: seg,
							},
						});
						updateMap({
							id: mapState.id,
							title: mapState.title,
							segment: seg,
						});
					}}
					isDraft={mapState.isDraft}
					toggleDraft={(e) => {
						queryClient.invalidateQueries("getAllMarketMaps");
						dispatchMap({
							type: "changeValue",
							field: "isDraft",
							value: !e.target.checked,
						});
						updateMap({
							id: mapState.id,
							isDraft: !e.target.checked,
						});
					}}
					onDelete={() => {
						deleteMap(mapState.id);
						queryClient.invalidateQueries("getAllMarketMaps");
						navigate("/market-maps");
					}}
				/>
				<MapSubHeader
					activeTab={mapState.step}
					onTabClick={(tab) =>
						dispatchMap({
							type: "changeValue",
							field: "step",
							value: tab,
						})
					}
				/>
			</Box>

			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					flexGrow: 1,
					maxHeight: "calc(100% - 104px)",
				}}
			>
				{(mapState.step === MapStep.Plan || mapState.step === MapStep.Map) && (
					<Box
						style={{
							height:
								mapState.step === MapStep.Plan ? "100%" : "calc(100% - 152px)",
							width: "100%",
						}}
					>
						<MapEditor
							onCellButtonClick={({ row, col }) => {
								onCellClick(row, col);
							}}
							activeValorId={currentlyMappedValorId ?? null}
							activeValorIdMemberships={
								companyMemberships[currentlyMappedValorId] ?? []
							}
							companyLookup={companyLookup}
							onRenameAxis={(name, field) => {
								dispatchMap({
									type: "changeValue",
									field,
									value: name,
								});
							}}
							onRenameAxisBlur={(name, field) => {
								patchDimension(mapState.id, {
									id: field === "rowTitle" ? mapState.rowId : mapState.columnId,
									name,
								});
							}}
							onNewDimension={(dimensionName, field) =>
								onAddNewDimension(dimensionName, field)
							}
							onRenameDimension={(newName, id, field) =>
								onRenameDimension(newName, id, field)
							}
							onRenameDimensionBlur={(newName, id, field) => {
								patchDimensionValue(
									mapState.id,
									mapState[field === "rows" ? "rowId" : "columnId"],
									{
										id,
										dimensionId:
											mapState[field === "rows" ? "rowId" : "columnId"],
										name: newName,
									},
								);
							}}
							onChangeTooltip={(tooltip, id, field) => {
								onChangeTooltip(tooltip, id, field);
								patchDimensionValue(
									mapState.id,
									mapState[field === "rows" ? "rowId" : "columnId"],
									{
										id,
										dimensionId:
											mapState[field === "rows" ? "rowId" : "columnId"],
										tooltip,
									},
								);
							}}
							deleteDimension={(id, field) => onDeleteDimension(id, field)}
							activeStep={mapState.step}
							propState={{
								rows: mapState.rows,
								rowTitle: mapState.rowTitle,
								columns: mapState.columns,
								columnTitle: mapState.columnTitle,
								cells: mapState.cells,
							}}
						/>
					</Box>
				)}
				{mapState.step === MapStep.Collect && (
					<MapCollector
						mapId={mapState.id}
						companies={mapState.companies}
						onCompanySelect={({ name, logoUrl, domain, valorId }) =>
							onCompanySelect({
								name,
								logoUrl,
								domain,
								valorId,
							})
						}
						onCompanyDelete={(coValorId) => onRemoveCompany(coValorId)}
						unmappedCompanies={mapState.companies?.filter(
							(co) => (companyMemberships[co.valorId]?.length ?? 0) === 0,
						)}
						filters={mapState.filters}
						onFilterChange={(filters) => {
							dispatchMap({
								type: "changeValue",
								field: "filters",
								value: filters,
							});
							updateMap({
								id: mapState.id,
								filters: JSON.stringify(filters),
							});
						}}
					/>
				)}
				{mapState.step === MapStep.Map &&
					Boolean(mapState.companies?.length) && (
						<MapClassifierFooter
							companyMemberships={companyMemberships}
							companies={mapState.companies}
							currentMappingId={currentlyMappedValorId}
							setCurrentMappingId={setCurrentlyMappedValorId}
						/>
					)}
			</Box>
		</Box>
	);
}

export default MarketMapCreateEdit;
