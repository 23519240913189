import CenteredProgress from "@/ui/atoms/CenteredProgress";
import { Box, Button, Card, Chip, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useMemo } from "react";

import {
	type ColumnFiltersState,
	type ColumnResizeMode,
	type SortingState,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	useReactTable,
} from "@tanstack/react-table";

import {
	Cell as HeaderCell,
	Toolbar as TableToolbar,
} from "@/components/Table/Header";
import { CompanyCell, DateCell, UserCell } from "@/components/Table/Primitives";

import {
	VirtualTableBody,
	VirtualTableContainer,
	depageData,
} from "@/components/InfiniteTable";
import { DateRangeFilter } from "@/components/Table/CustomFilters";
import { StickyTable, StickyTableHead } from "@/components/Table/StickyTable";

export type MSLSurvey = {
	msl: {
		id: string;
		firstName: string;
		lastName: string;
		profilePicture: string;
	};
	organization: {
		valorId: string;
		name: string;
		domain: string;
		logoUrl: string;
	};
	funds: {
		[key: string]: boolean;
	};
	surveyCompletedAt?: string;
	surveyName: string;
};

export type MSLSurveyTableProps = {
	data: MSLSurvey[];
	isLoading?: boolean;
	isFetching?: boolean;
	isFetchingNextPage?: boolean;
	hasNextPage?: boolean;
	fetchNextPage?: () => void;
	sorting: SortingState;
	columnFilters: ColumnFiltersState;
	columnVisibility: Record<string, boolean>;
	onSortingChange?: (sorting: SortingState) => void;
	onColumnFiltersChange?: (columnFilters: ColumnFiltersState) => void;
	onColumnVisibilityChange?: (
		columnVisibility: Record<string, boolean>,
	) => void;
};

export default function MSLSurveyTable({
	data,
	isLoading,
	isFetching,
	isFetchingNextPage,
	hasNextPage,
	sorting,
	columnFilters,
	columnVisibility,
	fetchNextPage,
	onSortingChange,
	onColumnFiltersChange,
	onColumnVisibilityChange,
}: MSLSurveyTableProps) {
	// define column def
	const columnResizeMode: ColumnResizeMode = "onEnd";
	const columnDef = useMemo(
		() =>
			[
				{
					header: "Company",
					accessorFn: (row) => row.organization,
					cell: (prop) => <CompanyCell value={prop.getValue()} />,
					id: "organization",
					minSize: 252,
				},
				{
					header: "Funds",
					id: "funds[]",
					accessorKey: "funds",
					enableSorting: false,
					enableColumnFilter: false,
					minSize: 220,
					size: 500,
					cell: (info) => (
						<Box
							display="inline-flex"
							flexDirection="row"
							alignItems="center"
							gap={1}
							flexWrap="wrap"
							width="100%"
							height="100%"
						>
							{(info.getValue() ?? {}).map((key) => (
								<Chip key={key} label={key} size="small" />
							))}
						</Box>
					),
				},
				{
					header: "MSL",
					accessorFn: (row) => row.msl,
					cell: (prop) => (
						<UserCell value={prop.getValue()}>
							<Typography variant="body2">
								{prop.row.original.monitoringServiceRequired === false
									? "MSR (No)"
									: ""}
							</Typography>
						</UserCell>
					),
					id: "msl.name",
				},
				{
					header: "Last Survey Taken",
					accessorKey: "surveyName",
					id: "surveyName",
					cell: (prop) => (
						<UserCell value={prop.row.original.surveyTaker}>
							<Typography
								sx={{
									whiteSpace: "normal",
									wordWrap: "normal",
								}}
								width="120px"
								variant="body2"
							>
								{prop.getValue()}
							</Typography>
						</UserCell>
					),
					minSize: 150,
					size: 150,
				},
				{
					header: "Last Survey Date",
					accessorKey: "surveyCompletedAt",
					cell: (prop) => <DateCell value={prop.getValue()} />,
					filter: DateRangeFilter,
					id: "surveyCompletedAt",
					meta: {
						sortType: "date",
					},
				},
				{
					header: "Next Survey Date",
					id: "nextSurveyDate",
					accessorFn: (row) => row.surveyCompletedAt,
					enableSorting: false,
					enableColumnFilter: false,
					cell: (info) => {
						const weeksTilNextSurvey = 13;
						const value = info.getValue();
						const nextSurveyDate = value
							? dayjs(value).add(weeksTilNextSurvey, "w")
							: null;
						const isOverdue = dayjs().isAfter(nextSurveyDate);

						return (
							<Box display="flex" flexDirection="column">
								<Typography
									variant="body2"
									sx={(theme) => ({
										color: isOverdue ? theme.palette.error.main : "inherit",
										fontWeight: isOverdue
											? theme.typography.fontWeightBold
											: "inherit",
									})}
								>
									{nextSurveyDate && `Due ${nextSurveyDate?.fromNow()}`}
								</Typography>
								{nextSurveyDate?.format("MM/DD/YYYY")}
							</Box>
						);
					},
				},
				{
					header: "growthIsExited",
					accessorKey: "growthIsExited",
					id: "growthIsExited",
					visible: false,
				},
				{
					header: "vsvIsExited",
					accessorKey: "vsvIsExited",
					id: "vsvIsExited",
					visible: false,
				},
			].map((x) => ({
				...x,
				filterFn: () => true,
			})),
		[],
	);

	const pages = useMemo(() => depageData(data), [data]);
	const totalCount = data?.pages[0]?.totalCount;

	const table = useReactTable({
		data: pages,
		columns: columnDef,
		columnResizeMode,
		state: {
			sorting,
			columnFilters,
			columnVisibility: {
				...columnVisibility,
				vsvIsExited: false,
				growthIsExited: false,
			},
		},
		onColumnVisibilityChange,
		onColumnFiltersChange,
		onSortingChange,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	});

	return (
		<Card
			sx={{
				maxHeight: "100vh",
				overflowY: "hidden",
			}}
		>
			{(isLoading || isFetchingNextPage) && (
				<CenteredProgress
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				/>
			)}

			<Box
				px={1}
				py={2}
				maxWidth="100%"
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
					<Typography variant="h3">
						{"Portfolio Monitoring "}
						{typeof totalCount === "number" &&
							`- ${
								totalCount > 0 ? totalCount.toLocaleString() : "No"
							} Results`}
					</Typography>
				</Box>
				<Box display="flex" gap={1}>
					<Button
						variant="outlined"
						onClick={() => {
							const prev = columnFilters;

							const showVsvIsExited = !prev?.find((x) => x.id === "vsvIsExited")
								?.value;

							onColumnFiltersChange?.(
								[
									...prev.filter((x) => x.id !== "vsvIsExited"),
									{ id: "vsvIsExited", value: showVsvIsExited },
								].filter((x) => x) as ColumnFiltersState,
							);
						}}
					>
						{columnFilters.find((x) => x.id === "vsvIsExited")?.value
							? "Exclude VSV Exited Companies"
							: "Include VSV Exited Companies"}
					</Button>

					<Button
						variant="outlined"
						onClick={() => {
							const prev = columnFilters;
							const showGrowthIsExited = !prev?.find(
								(x) => x.id === "growthIsExited",
							)?.value;

							onColumnFiltersChange?.(
								[
									...prev.filter((x) => x.id !== "growthIsExited"),
									{ id: "growthIsExited", value: showGrowthIsExited },
								].filter((x) => x) as ColumnFiltersState,
							);
						}}
					>
						{columnFilters.find((x) => x.id === "growthIsExited")?.value
							? "Exclude Growth Exited Companies"
							: "Include Growth Exited Companies"}
					</Button>
				</Box>
			</Box>

			<TableToolbar
				columnHeaders={columnDef}
				sorting={sorting}
				columnFilters={columnFilters}
				columnVisibility={columnVisibility}
				onRemoveColumnFilters={() => onColumnFiltersChange([])}
				onRemoveSorting={() => onSortingChange([])}
				onRemoveVisibility={() => onColumnVisibilityChange({})}
			/>

			<VirtualTableContainer
				onScrollBottomReached={() => {
					if (!isFetching && hasNextPage) {
						fetchNextPage?.();
					}
				}}
			>
				<StickyTable>
					<StickyTableHead>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<HeaderCell
										key={header.id}
										header={header}
										column={header.column}
										table={table}
									/>
								))}
							</TableRow>
						))}
					</StickyTableHead>
					<VirtualTableBody
						rows={table.getRowModel().rows}
						estimateSize={100}
						table={table}
					/>
				</StickyTable>
			</VirtualTableContainer>
		</Card>
	);
}
