import TextCell from "@/components/Table/Primitives/TextCell";
import { useVisibility } from "@/hooks/useVisibility";
import { Skeleton } from "@mui/material";
import React, { useRef } from "react";
import { useQuery } from "react-query";

import { getNotesByOrg, getNotesByTask } from "@/utils/noteCache";

export default function NoteCell({
	processId,
	taskId,
	valorId,
}: { processId?: string; taskId?: string; valorId?: string }) {
	const cellRef = useRef<HTMLDivElement>(null);
	const isVisible = useVisibility(cellRef);

	const { data: oppData, isLoading: oppLoading } = useQuery(
		["notes", processId, taskId],
		() => getNotesByTask(processId, taskId),
		{
			enabled: !!processId && !!taskId && isVisible, // && false,
			refetchInterval: false,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		},
	);

	const { data: orgData, isLoading: orgLoading } = useQuery(
		["notes", valorId],
		() => getNotesByOrg(valorId),
		{
			enabled: !!valorId && isVisible, // && false,
			refetchInterval: false,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		},
	);

	const notes = React.useMemo(() => {
		if (oppData) {
			return oppData;
		}
		if (orgData) {
			return orgData;
		}
		return [];
	}, [oppData, orgData]);
	const note = notes?.[0];
	if (orgLoading || oppLoading) {
		return (
			<div
				ref={cellRef}
				style={{
					height: "100%",
				}}
			>
				<Skeleton variant="text" />
			</div>
		);
	}

	if (!note) {
		return (
			<div
				ref={cellRef}
				style={{
					height: "100%",
				}}
			>
				-
			</div>
		);
	}

	return (
		<div
			ref={cellRef}
			style={{
				height: "100%",
			}}
		>
			<TextCell value={note.plainText} />
		</div>
	);
}
