import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import React from "react";

export default function NewListDialog({ open = false, handleClose, onCreate }) {
	const [name, setName] = React.useState("");
	const [description, setDescription] = React.useState("");

	const handleCreate = () => {
		onCreate(name, description);
		handleClose();
		setName("");
		setDescription("");
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="create-firm-list">Create List</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Name"
					required
					fullWidth
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				<TextField
					autoFocus
					margin="dense"
					id="description"
					label="Description"
					required
					fullWidth
					value={description}
					onChange={(e) => setDescription(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancel
				</Button>
				<Button
					disabled={name.length === 0 || description.length === 0}
					onClick={handleCreate}
					color="primary"
				>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
}
