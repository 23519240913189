import type { SignalDistribution } from "@/api/SignalFeatures";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import {
	Bar,
	BarChart as BarReChart,
	CartesianGrid,
	Label,
	ReferenceArea,
	ReferenceLine,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

import { CustomTooltip, CustomXTick, CustomYTick } from "./ChartUtils";
import { ValueTransformer } from "./Utils";

const generateTicks = (min, max) => {
	const minSign = Math.sign(min);
	const maxSign = Math.sign(max);
	const newMin = minSign * 10 ** Math.ceil(Math.log10(Math.abs(min)));
	const newMax = maxSign * 10 ** Math.ceil(Math.log10(Math.abs(max)));

	// get the magnitude of the max value
	const magnitude = Math.floor(
		Math.log10(Math.max(Math.abs(newMax), Math.abs(newMin))),
	);
	const newMagnitude = 10 ** (magnitude - 1);

	// start should be first magnitude lower than min
	// round min down to nearest magnitude
	const start = min;

	const end = max;

	const roundToMag = (x, mag) => Math.round(x / mag) * mag;

	let series = [];
	let j = start;
	for (
		let i = 0;
		j < end;
		j = roundToMag(start, newMagnitude) + i * newMagnitude
	) {
		series.push(j);
		i += 1;
	}

	// if series < 5, then regenerate with smaller magnitude
	if (series.length <= 3) {
		series = [];
		j = start;
		for (
			let i = 0;
			j < end;
			j = roundToMag(start, newMagnitude / 10) + (i * newMagnitude) / 10
		) {
			series.push(j);
			i += 3;
		}
	}

	return series;
};

export default function HistChart({
	data,
	xAsc,
}: {
	data: SignalDistribution["data"];
	xAsc: boolean;
}) {
	const theme = useTheme();
	if (!data) return null;

	const { distribution: rawDist } = data;
	let distribution = rawDist?.map(({ x, y, isMedian }) => ({
		x: Number(x),
		y: Number(y),
		isMedian,
	}));

	const { quantileValues } = data;
	let refLine = null;
	let medianLine = null;
	if (distribution) {
		distribution = distribution
			.map((d) => ({
				...d,
				x: data.chartArgs.xlogscaled ? Math.max(0.00001, d.x) : d.x,
			}))
			.toSorted((a, b) => {
				if (xAsc) return a.x - b.x;
				return b.x - a.x;
			});

		// bin data
		if (data.chartArgs.bins) {
			const { bins } = data.chartArgs;
			const binned = bins.map((x, i) => ({
				x,
				yValues: distribution.filter((d) => {
					if (i === 0) return d.x <= x;
					return d.x > data.chartArgs.bins[i - 1] && d.x <= x;
				}),
			}));
			// sum y values for each bin
			distribution = binned.map((d) => ({
				x: d.x,
				y: d.yValues.reduce((acc, curr) => acc + curr.y, 0),
				isMedian: d.yValues.some((v) => v.isMedian),
			}));
		}

		refLine = distribution.reduce((acc, curr) => {
			if (xAsc && curr.x <= Number(data.value)) {
				return curr.x;
			}
			if (!xAsc && curr.x >= Number(data.value)) {
				return curr.x;
			}
			return acc;
		}, 0);

		medianLine = distribution.find((d) => d.isMedian);
	}

	const sortedQuantiles = quantileValues?.toSorted(
		(a, b) => a.quantile - b.quantile,
	);
	const expectedQuantiles = [5, 25, 75, 95];
	const maxValue = distribution?.reduce(
		(acc, curr) => Math.max(acc, curr.x),
		Number.NEGATIVE_INFINITY,
	);
	const filledQuantiles = expectedQuantiles
		.map((q) => {
			const quantile = sortedQuantiles?.find((v) => v.quantile >= q);
			return quantile;
		})
		.map((q) => {
			if (q.quantile === 95) {
				return {
					...q,
					value: Math.min(maxValue, Number(q.value)).toString(),
				};
			}
			return q;
		});

	const values = filledQuantiles
		?.toSorted((a, b) => {
			if (xAsc) return a.quantile - b.quantile;
			return b.quantile - a.quantile;
		})
		.map((q) => Number(q.value));

	const closestValues = values.map(
		(v) =>
			distribution?.reduce((acc, curr) => {
				// get the closest value that is greater than the quantile value
				if (xAsc && curr.x <= v && Math.abs(curr.x - v) < Math.abs(acc.x - v)) {
					return curr;
				}
				// get the closest value that is less than the quantile value
				if (
					!xAsc &&
					curr.x >= v &&
					Math.abs(curr.x - v) < Math.abs(acc.x - v)
				) {
					return curr;
				}

				return acc;
			}).x,
	);

	const minX = Math.min(...distribution.map((d) => d.x));
	const maxX = Math.max(...distribution.map((d) => d.x));
	const xTicks = generateTicks(minX, maxX); // [0, 1, 2, 3]; // generateTicks(maxX);
	const xDomain = [Math.min(...xTicks), Math.max(...xTicks)];

	const renderBar = (props: {
		x: number;
		y: number;
		width: number;
		height: number;
		payload: {
			color: string;
		};
	}) => {
		const { x, y, width, height, payload } = props;
		if (height < 0) {
			return (
				<g>
					<rect
						x={x}
						y={y + height}
						width={width}
						height={-height}
						fill={payload.color || theme.palette.primary.main}
					/>
				</g>
			);
		}

		return (
			<g>
				<rect
					x={x + (width - Math.min(width, 15)) / 2}
					y={y}
					width={Math.min(width, 15)}
					height={height}
					fill={payload.color || theme.palette.primary.main}
				/>
			</g>
		);
	};

	return (
		<Box
			height={300}
			margin={2}
			display="flex"
			flexDirection="row"
			alignItems="center"
			gap={1}
		>
			<BarReChart
				width={500}
				height={300}
				margin={{ bottom: 20 }}
				data={distribution.map((d) => ({
					x: d.x,
					y: d.y,
				}))}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<Tooltip
					content={({ active, payload, label, formatter, labelFormatter }) => (
						<CustomTooltip
							payload={payload}
							label={label}
							active={active}
							formatter={formatter}
							labelFormatter={labelFormatter}
						/>
					)}
					formatter={(value) => [
						(Math.round(Number(value) * 100) / 100).toLocaleString(),
						data?.chartArgs?.ytitle || "Frequency",
					]}
					labelFormatter={
						ValueTransformer[data.units] || ValueTransformer.default
					}
				/>
				<XAxis
					dataKey="x"
					type="number"
					ticks={xTicks}
					domain={xDomain}
					tick={(props) => (
						<CustomXTick
							x={props.x}
							y={props.y}
							payload={props.payload}
							tickFormatter={
								ValueTransformer[data.units] || ValueTransformer.default
							}
						/>
					)}
				>
					{data?.chartArgs?.xtitle && (
						<Label
							value={data.chartArgs.xtitle}
							fill={theme.palette.text.primary}
							offset={-10}
							position="insideBottom"
						/>
					)}
				</XAxis>
				<YAxis
					dataKey="y"
					name="Frequency"
					tick={(props) => (
						<CustomYTick
							x={props.x}
							y={props.y}
							payload={props.payload}
							tickFormatter={ValueTransformer.default}
						/>
					)}
				>
					<Label
						value={data?.chartArgs?.ytitle || "Frequency"}
						fill={theme.palette.text.primary}
						angle={-90}
						position="insideLeft"
					/>
				</YAxis>

				<ReferenceArea
					x1={closestValues[0]}
					x2={closestValues[1]}
					ifOverflow="hidden"
					fill={theme.palette.background.graphOverlay}
					fillOpacity={0.1}
				/>

				<ReferenceArea
					x1={closestValues[1]}
					x2={closestValues[2]}
					ifOverflow="hidden"
					fill={theme.palette.background.graphOverlay}
					fillOpacity={0.25}
				/>

				<ReferenceArea
					x1={closestValues[2]}
					x2={closestValues[3]}
					ifOverflow="hidden"
					fill={theme.palette.background.graphOverlay}
					fillOpacity={0.1}
				/>

				<Bar dataKey="y" fill={theme.palette.primary.main} shape={renderBar} />

				<ReferenceLine y={0} stroke={theme.palette.primary.contrastText} />
				<ReferenceLine
					x={refLine}
					stroke={theme.palette.error.main}
					strokeWidth={3}
					strokeOpacity={0.5}
				/>
				<ReferenceLine
					x={medianLine?.x}
					stroke={theme.palette.info.main}
					strokeOpacity={0.5}
					strokeWidth={3}
				>
					<Label
						value="Median"
						fill={theme.palette.info.main}
						fillOpacity={0.5}
						position="insideTopLeft"
					/>
				</ReferenceLine>
			</BarReChart>
			<Box>
				<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
					<div
						style={{
							width: 10,
							height: 10,
							backgroundColor: theme.palette.background.graphOverlay,
							opacity: 0.1,
						}}
					/>
					<Typography variant="caption" whiteSpace="nowrap">
						5-95%
					</Typography>
				</Box>

				<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
					<div
						style={{
							width: 10,
							height: 10,
							backgroundColor: theme.palette.background.graphOverlay,
							opacity: 0.25,
						}}
					/>
					<Typography variant="caption" whiteSpace="nowrap">
						25-75%
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}
