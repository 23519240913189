import {
	// isInAlpha,
	canCreateOrgs,
	canDealSearch,
	canReadManagementDashboard,
	canSeeAARVertical,
	canSeeCompliance,
	canSeeDeals,
	canSeeFirmList,
	canSeeHome,
	canSeeLLMChat,
	canSeeMarketMaps,
	canSeeMetricTasks,
	canSeeOnboarding,
	canSeeP2PAnalysis,
	canSeePCAPAudit,
	canSeeProcess,
	canSeeRevGen,
	canSeeSignalModelPipeline,
	canSeeSignalModelProcess,
	canSeeUntaggedDocs,
	canSeeVAAIReports,
	canSeeVPO,
	canSeeWeeklyMeeting,
	canVoteOnFirstMeeting,
} from "../constants/Roles";

import {
	AccountBalance,
	AddCircleOutline,
	Assessment,
	Assignment,
	Business,
	CalendarMonth,
	CheckCircleOutline,
	Construction,
	CorporateFare,
	Description,
	DoneAll,
	Edit,
	Group,
	Home,
	HourglassEmpty,
	MonetizationOn,
	NoteAdd,
	PostAdd,
	RateReview,
	Report,
	RequestQuote,
	Search,
	ShowChart,
	type SvgIconComponent,
	Timeline,
	TrackChanges,
	TrendingFlat,
	Visibility,
} from "@mui/icons-material";
import type { User } from "../hooks/useAuth";
import GrokIcon from "../ui/atoms/Icons/Grok";

type CanSeeFunction = (user: User) => boolean;

interface Destination {
	name: string;
	path?: string;
	canSee: CanSeeFunction;
	Icon?: SvgIconComponent | React.ElementType;
	subtitle?: string;
	disableCmdK?: boolean;
	"data-cy"?: string;
}
export const destinations: Record<string, Destination> = {
	"Additional Capital Deployed": {
		name: "Additional Capital Deployment Form",
		path: "",
		subtitle: "Complete an Additional Capital Deployment form",
		Icon: RequestQuote,
		canSee: (u) => canSeeProcess(u, "portfolio"),
		"data-cy": "page__additional_capital_deployment_form",
	},
	"After Action Reviews": {
		name: "After Action Reviews",
		path: "/aar-dashboard",
		canSee: canSeeAARVertical,
		"data-cy": "page__after_action_reviews",
	},
	Calendar: {
		Icon: CalendarMonth,
		name: "Calendar",
		// TODO: fix sessionId logic to work with landing on activeView page
		// path: "/process-management/calendar?view=table&activeView=c57b31a8-3563-4830-aac2-19b014ea9ce1",
		path: "/process-management/calendar?view=table",
		canSee: (u) => canSeeProcess(u, "calendar"),
	},
	"Closed Investments": {
		Icon: DoneAll,
		name: "Closed Investments",
		path: "/investment-reports/closed-last-seven-days",
		subtitle: "Review investments recently closed",
		canSee: (u) => canSeeProcess(u, "deals"),
		"data-cy": "page__closed_investments",
	},
	"Considering for Term Sheet": {
		name: "Considering for Term Sheet",
		Icon: Description,
		subtitle: "Review opportunities being considered for term sheet",
		path: "/investment-process/considering-for-ts",
		canSee: (u) => canSeeProcess(u, "deals"),
		"data-cy": "page__considering_for_term_sheet",
	},
	"Create Organization": {
		name: "Create Organization",
		Icon: CorporateFare,
		path: "/organization",
		canSee: canCreateOrgs,
		"data-cy": "page__create_organization",
	},
	"Deal Search": {
		Icon: Search,
		name: "Deal Search",
		path: "/search",
		canSee: canDealSearch,
		"data-cy": "page__deal_search",
	},
	Following: {
		Icon: Visibility,
		name: "Following Opportunities",
		path: "/investment-reports/company-follow-list",
		subtitle: "Review status of following opportunities",
		canSee: (u) => canSeeProcess(u, "deals"),
		"data-cy": "page__following_opportunities",
	},
	"Finance Metric Tasks": {
		name: "Finance Metric Tasks",
		path: "/metric-tasks",
		canSee: canSeeMetricTasks,
		"data-cy": "page__finance_metric_tasks",
	},
	"Finance Tracker": {
		name: "Finance Tracker",
		path: "/process-management/finance",
		canSee: (u) => canSeeProcess(u, "finance"),
		"data-cy": "page__finance_tracker",
	},
	"Funds (Active / In Consideration)": {
		name: "Funds (Active / In Consideration)",
		path: "/process-management/funds?view=table",
		canSee: (u) => canSeeProcess(u, "funds"),
		"data-cy": "page__funds_active_in_consideration",
	},
	"Fund Administration": {
		name: "Fund Administration",
		path: "/process-management/lp-ab?view=table",
		canSee: (u) => canSeeProcess(u, "lp-ab"),
		"data-cy": "page__fund_administration",
	},
	Grok: {
		Icon: GrokIcon,
		name: "Grok",
		path: "/grok",
		canSee: canSeeLLMChat,
		"data-cy": "page__grok",
	},
	HomeInvest: {
		Icon: ShowChart,
		name: "Investment Group",
		path: "/home/investments",
		canSee: canSeeHome,
		"data-cy": "page__home",
	},
	HomeIR: {
		Icon: MonetizationOn,
		name: "Investor Relations",
		path: "/home/i-r",
		canSee: canSeeHome,
		"data-cy": "page__home",
	},
	HomeInfra: {
		Icon: AccountBalance,
		name: "Infrastructure",
		path: "/home/infrastructure",
		canSee: canSeeHome,
		"data-cy": "page__home",
	},
	HomeScale: {
		Icon: Construction,
		name: "Scale Group",
		path: "/home/scale-group",
		canSee: canSeeHome,
		"data-cy": "page__home",
	},
	Home: {
		name: "Home",
		Icon: Home,
		path: "/home",
		canSee: canSeeHome,
		"data-cy": "page__home",
	},
	"Human Capital": {
		name: "Human Capital",
		path: "/human-capital",
		canSee: canSeeVPO,
		"data-cy": "page__human_capital",
	},
	"Talent Acquisition": {
		name: "Talent Acquisition",
		path: "/talent-acquisition",
		canSee: canSeeVPO,
		"data-cy": "page__talent_acquisition",
	},
	"IHE Changes": {
		name: "IHE Changes",
		path: "/ihe-changes",
		canSee: (u) => canSeeWeeklyMeeting(u),
		"data-cy": "page__ihe_changes",
	},
	"Investment Announcements": {
		name: "Investment Announcements",
		path: "/investment-reports/investment-announcements",
		canSee: (user) => canSeeProcess(user, "deals"),
		"data-cy": "page__investment_announcements",
	},
	IT: {
		name: "IT",
		path: "/process-management/it",
		canSee: (u) => canSeeProcess(u, "it"),
		"data-cy": "page__it",
	},
	"IT (CEO/CIO)": {
		name: "IT (CEO/CIO)",
		path: "/process-management/itceo",
		canSee: (u) => canSeeProcess(u, "itceo"),
		"data-cy": "page__it_ceo_cio",
	},
	"Lean Growth": {
		name: "Lean Growth",
		path: "/process-management/leangrowth",
		canSee: (u) => canSeeProcess(u, "leangrowth"),
		"data-cy": "page__process_management",
	},
	Legal: {
		name: "Legal",
		path: "/process-management/legal",
		canSee: (u) => canSeeProcess(u, "legal"),
		"data-cy": "page__legal",
	},
	"LP / AB Meetings & Consents": {
		name: "LP / AB Meetings & Consents",
		path: "/process-management/lp-ab?view=table",
		canSee: (u) => canSeeProcess(u, "lp-ab"),
		"data-cy": "page__lp_ab_meetings_consents",
	},
	"Market Maps": {
		name: "Market Maps",
		path: "/market-maps",
		canSee: canSeeMarketMaps,
		"data-cy": "page__market_maps",
	},
	"Meeting Recordings": {
		name: "Meeting Recordings",
		path: "/meetings",
		canSee: canReadManagementDashboard,
		"data-cy": "page__meeting_recordings",
	},
	"My Active Portfolio Companies": {
		Icon: Business,
		name: "My Active Portfolio Companies",
		subtitle:
			"Quick link to portfolio companies where user is either the MSL 1 or MSL 2",
		path: "/process-management/portfolio?view=table&groupBy=f7274069-4879-45d4-948e-f8af823fa087&activeView=336d70eb-fcc3-4da1-a7b8-8d2fc109f066",
		canSee: (u) => canSeeProcess(u, "portfolio"),
		"data-cy": "page__my_active_portfolio_companies",
	},
	"New Opportunities - Pre OA": {
		Icon: HourglassEmpty,
		name: "Pre-OA Status",
		subtitle: "Review opportunities in Pre OA stage",
		path: "/investment-process/new-opps-pre-oa",
		canSee: (u) => canSeeDeals(u),
		"data-cy": "page__pre_oa_status",
	},
	"New Opportunity Distribution": {
		Icon: Report,
		name: "New Opportunity Distribution",
		subtitle: "Top of funnel activity (qualified v. unqualified)",
		path: "/investment-process/newly-added-opps",
		canSee: (u) => canSeeDeals(u),
		"data-cy": "page__new_opportunity_distribution",
	},
	"New Opportunity": {
		Icon: AddCircleOutline,
		name: "New / Edit Opportunity",
		path: "/process-management/deals?view=table&sort=%5B%7B%22id%22%3A%22tasks.fieldValues.a31088f0-0993-4c00-a3d1-04bf3be8a0e6.choiceId%22%2C%22desc%22%3Afalse%7D%5D&filter=%5B%7B%22id%22%3A%22tasks.fieldValues.a31088f0-0993-4c00-a3d1-04bf3be8a0e6.choiceId%22%2C%22value%22%3A%5B%7B%22value%22%3A%22421eb50c-7522-0508-3563-ab0b9302b488%22%2C%22label%22%3A%22IC%2BApproved%2Bto%2BClose%22%7D%2C%7B%22value%22%3A%22ffc04fd1-e834-d42b-7983-02497aa7cd47%22%2C%22label%22%3A%22IC%2BApproval%2Bto%2BClose%22%7D%2C%7B%22value%22%3A%22e52a34e9-b3cb-8c4f-6771-20b180834860%22%2C%22label%22%3A%22Term%2BSheet%2BApproved%2Bby%2BIC%22%7D%2C%7B%22value%22%3A%22ba617fa4-5825-8040-da39-fb1374a7e583%22%2C%22label%22%3A%22IC%2BTerm%2BSheet%2BApproval%22%7D%2C%7B%22value%22%3A%2228d03b76-6d74-47f0-aee7-c82ef8387959%22%2C%22label%22%3A%22Considering%2Bfor%2BTerm%2BSheet%22%7D%2C%7B%22value%22%3A%22830ba2b3-6902-3cba-15cb-64b5ff3773ec%22%2C%22label%22%3A%22IC%2BOpportunity%2BAssessment%2BApproval%22%7D%2C%7B%22value%22%3A%225c8f6ed1-e464-0376-7c20-579e3418a556%22%2C%22label%22%3A%22Pre%2BOpportunity%2BAssessment%22%7D%2C%7B%22value%22%3A%2263cdc524-c097-9ff0-09b6-8cfb59267f9b%22%2C%22label%22%3A%22New%2BOpportunity%22%7D%5D%7D%5D&visibility=%7B%7D&order=%5Bobject%2BObject%5D",
		subtitle: "Create or edit an investment opportunity",
		canSee: (u) => canSeeDeals(u),
		"data-cy": "page__new_edit_opportunity",
	},
	"Opportunity Assessments": {
		name: "OA Approvals",
		path: "/opportunity-assessment-reports",
		canSee: (user) => canVoteOnFirstMeeting(user),
		Icon: CheckCircleOutline,
		subtitle: "IC approval of opportunity assessments",
		"data-cy": "page__oa_approvals",
	},
	"Opportunity Assessment - Create": {
		Icon: Edit,
		name: "Create Opportunity Assessment",
		path: "",
		subtitle: "Create or edit an opportunity assessment",
		canSee: () => true,
		"data-cy": "page__create_opportunity_assessment",
	},
	"Pass Follow Throughs": {
		Icon: TrendingFlat,
		name: "Pass Follow Through",
		path: "/investment-process/pass-follow-through",
		subtitle: "Monitor pass follow through activity",
		canSee: (u) => canSeeProcess(u, "deals"),
		"data-cy": "page__pass_follow_through",
	},
	"PCAP Audit": {
		name: "PCAP Audit",
		path: "/pdf-pcap-audit",
		canSee: canSeePCAPAudit,
	},
	"PortCo Financing Activity": {
		name: "PortCo Financing Activity",
		path: "/financing-report/liquidity-events",
		canSee: (u) => canSeeWeeklyMeeting(u),
	},
	"Fund Portfolio CRM": {
		name: "Portfolio CRM",
		path: "/process-management/portfolio?view=table",
		canSee: (u) => canSeeProcess(u, "portfolio"),
	},
	"PortCo Setup": {
		name: "Portfolio Company Setup",
		path: "",
		subtitle: "Set up a new portfolio company",
		Icon: AddCircleOutline,
		canSee: (u) => canSeeProcess(u, "portfolio"),
	},
	"Portfolio Surveys": {
		name: "Portfolio Surveys",
		path: "/portfolio-monitoring",
		canSee: canReadManagementDashboard,
	},
	"Post Closing Actions": {
		Icon: PostAdd,
		name: "Post-Closing Items",
		subtitle: "Review status of post closing actions",
		path: "/investment-reports/post-closing-actions",
		canSee: (u) => canSeeProcess(u, "deals"),
		"data-cy": "page__post_closing_items",
	},
	"Quarterly Assessment": {
		name: "Quarterly Assessment",
		path: "",
		subtitle: "Complete a Quarterly Assessment",
		Icon: Timeline,
		canSee: (u) => canSeeProcess(u, "portfolio"),
		"data-cy": "page__quarterly_assessment",
	},
	"Research Projects": {
		name: "Research Projects",
		path: "/process-management/research?view=table",
		canSee: (u) => canSeeProcess(u, "research"),
	},
	"Revenue Generation": {
		name: "Rev Gen Dashboard",
		path: "/rev-ops/dashboard",
		canSee: canSeeRevGen,
	},
	"Rev Gen Engagements": {
		name: "Rev Gen Engagements",
		path: "/process-management/vsv-revops?view=table",
		canSee: canSeeRevGen,
	},
	"Rev Gen Onboardings": {
		name: "Rev Gen Onboardings ",
		path: "/process-management/rev-gen?view=table",
		canSee: canSeeOnboarding,
	},
	"Signal Model Process": {
		name: "Signal Model Process",
		path: "/process-management/sig-model?view=table",
		canSee: canSeeSignalModelProcess,
	},
	"Signal Model Opportunities": {
		name: "Signal Model Opportunities",
		path: "/signal-model-pipeline/pipeline",
		canSee: canSeeSignalModelPipeline,
	},
	"Sourcing Lists": {
		name: "Sourcing Lists",
		path: "/sourcing-lists",
		canSee: canSeeFirmList,
	},
	"Survey Drafts": {
		Icon: Assessment,
		canSee: () => true,
		subtitle: "Drafts of surveys in progress",
		name: "Survey Drafts",
		path: "/home/drafts",
	},
	"Survey Management": {
		name: "Survey Management",
		path: "/management-dashboard",
		canSee: canReadManagementDashboard,
	},
	"Take a Survey": {
		Icon: RateReview,
		name: "Take a Survey",
		subtitle: "Take a Survey",
		path: "",
		canSee: () => true,
	},
	"Under Term Sheet": {
		Icon: Assignment,
		name: "Under Term Sheet",
		subtitle: "Review opportunities under a term sheet",
		path: "/investment-process/under-ts",
		canSee: (u) => canSeeProcess(u, "deals"),
		"data-cy": "page__under_term_sheet",
	},
	"Untagged Documents": {
		name: "Untagged Docs",
		path: "/untagged-documents",
		canSee: canSeeUntaggedDocs,
	},
	"User Management": {
		name: "User Management",
		path: "/compliance-and-permissions",
		canSee: canSeeCompliance,
		"data-cy": "page__compliance",
	},
	"VAAI Deals Reports": {
		name: "VAAI Deal Flow",
		path: "/vaai/deals",
		canSee: canSeeVAAIReports,
		"data-cy": "page__vaai",
	},
	"VAAI Portfolio Reports": {
		name: "VAAI Portfolio",
		path: "/vaai/portfolio",
		canSee: canSeeVAAIReports,
		"data-cy": "page__vaai",
	},
	"vOS Opportunity CRM": {
		name: "vOS CRM",
		Icon: TrackChanges,
		path: "/process-management/deals?view=table&sort=%5B%7B%22id%22%3A%22dateAdded%22%2C%22desc%22%3Atrue%7D%5D",
		canSee: canSeeDeals,
	},
	"Weekly IC Meeting": {
		name: "Investment Committee",
		Icon: Group,
		subtitle: "Weekly investment committee meeting materials",
		path: "/weekly-ic/weekly-ic/agenda",
		canSee: canSeeWeeklyMeeting,
		"data-cy": "page__weekly_ic",
	},
	"Write a Note": {
		Icon: NoteAdd,
		name: "Write a Note",
		subtitle: "Document and note relevant details",
		disableCmdK: true,
		// note widget.
		path: "", // TODO
		canSee: () => true,
	},
	"Create Opportunity": {
		Icon: TrackChanges,
		name: "Create Opportunity",
		subtitle: "Create a new deal",
		canSee: (u) => canSeeProcess(u, "deals"),
	},
	"Create Lean Growth Project": {
		Icon: TrackChanges,
		name: "Create Lean Growth Project",
		subtitle: "Create a new project",
		canSee: (u) => canSeeProcess(u, "leangrowth"),
	},
	"Create Rev Gen Project": {
		Icon: TrackChanges,
		name: "Create Rev Gen Project",
		subtitle: "Create a new project",
		canSee: (u) => canSeeProcess(u, "rev-gen"),
	},
	"P2P Analysis": {
		name: "P2P Analysis",
		Icon: Assessment,
		path: "/p2p-analysis",
		canSee: (u) => canSeeP2PAnalysis(u),
	},
} as Record<string, Destination>;

export default destinations;
