// import {
//   VirtualTableContainer, VirtualTableBody, TableRow,
// } from "@/components/InfiniteTable";
import {
	TableBody,
	TableContainer,
	TableRow,
} from "@/components/PaginatedTable";
import HeaderCell from "@/components/Table/Header/Cell";
import { StickyTable, StickyTableHead } from "@/components/Table/StickyTable";
import { Card } from "@mui/material";
import {
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	useReactTable,
} from "@tanstack/react-table";
import React from "react";

export default function PermissionsTable({
	columns,
	rows,
}: {
	columns: any[];
	rows: any[];
}) {
	const table = useReactTable({
		data: rows,
		columns,
		// state: {
		//   sorting: {},
		//   columnFilters: {},
		//   columnVisibility: {},
		// },
		// onColumnFiltersChange,
		// onGlobalFilterChange,
		// onColumnVisibilityChange,
		// onSortingChange,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	});

	return (
		<Card>
			<TableContainer
				totalCount={table.getRowModel().rows.length}
				pageSize={25}
				height="calc(100vh - 250px)"
			>
				<StickyTable>
					<StickyTableHead>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<HeaderCell
										key={header.id}
										header={header}
										column={header.column}
										table={table}
									/>
								))}
							</TableRow>
						))}
					</StickyTableHead>
					<TableBody rows={table.getRowModel().rows} />
				</StickyTable>
			</TableContainer>
		</Card>
	);
}
