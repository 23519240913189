import { getLastInteractions } from "@/api/Users";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import { Box, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-query";
import InteractionLineItem from "./InteractionLineItem";

export function Interactions(props) {
	const { onClick, onSurveyClick } = props;
	const isMdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const theme = useTheme();

	const { data: companies = [], isLoading: isLoadingInteractions } = useQuery(
		["lastInteractions", 20],
		async () => {
			const response = await getLastInteractions({ limit: 40 });
			return response;
		},
	);

	const lineItems = companies?.map(
		({ valorId, name, logoUrl, interactionDate }) => (
			<InteractionLineItem
				key={valorId}
				valorId={valorId}
				name={name}
				logoUrl={logoUrl}
				interactionDate={interactionDate}
				onClick={onClick}
				onSurveyClick={onSurveyClick}
			/>
		),
	);

	return (
		<Box>
			<CenteredProgress isLoading={isLoadingInteractions}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						width: "98%",
						pt: 1,
						pr: 3,
						pb: 3,
						pl: 2,
						[theme.breakpoints.down("md")]: {
							flexDirection: "column",
							alignItems: "flex-start",
							paddingBottom: theme.spacing(1),
						},
					}}
				>
					{!isMdDown && (
						<Box>
							<Typography
								variant="h3"
								sx={{
									"& > a": {
										color: theme.palette.primary.main,
										textDecoration: "none",
									},
								}}
							>
								<Link href="/">Home</Link> &gt; Last Pipeline and Portfolio
								Contacts
							</Typography>
						</Box>
					)}
				</Box>
				{lineItems.length > 0 ? (
					<Box
						sx={{
							width: "100%",
							height: "80vh",
							overflowX: "hidden",
							overflowY: "auto",
							msOverflowStyle: "none" /* IE and Edge */,
							scrollbarWidth: "none" /* Firefox */,
							"&::-webkit-scrollbar": {
								display: "none",
							},
							[theme.breakpoints.down("md")]: {
								height: "75vh",
							},

							"& > div:not(:last-child)": {
								borderBottom: `1px solid ${theme.palette.divider}`,
							},
						}}
					>
						{lineItems}
					</Box>
				) : (
					<Typography variant="h2">
						You are all caught up, future meetings will appear here.
					</Typography>
				)}
			</CenteredProgress>
		</Box>
	);
}

export default Interactions;

Interactions.propTypes = {
	onClick: PropTypes.func.isRequired,
	onSurveyClick: PropTypes.func.isRequired,
};
