import { searchNotes } from "@/api/Notes";
import { Skeleton } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import TextCell from "../Primitives/TextCell";

export default function OpportunityNote({
	opportunityId,
}: {
	opportunityId: number;
}) {
	// get note
	const { data, isLoading } = useQuery(
		["OpportunityNote", opportunityId],
		async () => searchNotes(null, null, null, opportunityId),
		{
			refetchInterval: false,
			refetchOnWindowFocus: false,
		},
	);

	const latestNote = React.useMemo(() => {
		const notes = data?.hits.toSorted((a, b) => b.createdAt - a.createdAt);
		return notes?.[0];
	}, [data]);

	if (isLoading) {
		return <Skeleton variant="text" />;
	}

	return <TextCell value={latestNote?.plainText} />;
}
