import {
	getContext,
	getContextNotes,
	getContextSurveys,
	getGeneralContexts,
} from "@/api/Contexts";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
	Box,
	Button,
	CircularProgress,
	Collapse,
	Container,
	Paper,
	Stack,
	TextField,
	Typography,
	styled,
} from "@mui/material";
import React from "react";
import { type UseQueryResult, useQuery } from "react-query";
import { XMLDebugView, XMLTreeNode, formatContext } from "./XML";
const defaultValorId = "49d3ef8d-d873-4900-b1c6-18f420f17608";

// Types
interface QueryConfig<T> {
	queryKey: string;
	queryFn: (id: string) => Promise<T>;
	enabled: boolean;
}

interface Section {
	title: string;
	queryKey: string;
	queryFn: (id: string) => Promise<any>;
}
const copyToClipboard = (text: string) => {
	navigator.clipboard.writeText(text).catch((err) => {
		console.error("Failed to copy text: ", err);
	});
};

// Custom Hook for handling queries
const useContextQuery = <T,>({
	queryKey,
	queryFn,
	enabled,
	valorId,
}: QueryConfig<T> & { valorId: string }): UseQueryResult<T> => {
	return useQuery([queryKey, valorId], () => queryFn(valorId), {
		enabled,
	});
};

// Section configurations
const SECTIONS: Section[] = [
	{
		title: "Context",
		queryKey: "context",
		queryFn: getContext,
	},
	{
		title: "General Context",
		queryKey: "generalContext",
		queryFn: getGeneralContexts,
	},
	{
		title: "Context Notes",
		queryKey: "contextNotes",
		queryFn: getContextNotes,
	},
	{
		title: "Context Surveys",
		queryKey: "contextSurveys",
		queryFn: getContextSurveys,
	},
];

// Styled components (same as before)
const StyledPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(3),
	marginTop: theme.spacing(2),
	marginBottom: theme.spacing(2),
}));

const DataContainer = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.grey[50],
	padding: theme.spacing(2),
	borderRadius: theme.shape.borderRadius,
	// maxHeight: "00px",
	overflowY: "auto",
	"& pre": {
		margin: 0,
		whiteSpace: "pre-wrap",
		wordWrap: "break-word",
	},
}));

// Types for the ExpandableSection
interface ExpandableSectionProps {
	title: string;
	isExpanded: boolean;
	queryResult: UseQueryResult<any>;
	onToggle: () => void;
}
const ExpandableSection: React.FC<ExpandableSectionProps> = ({
	title,
	isExpanded,
	queryResult,
	onToggle,
}) => {
	const [copySuccess, setCopySuccess] = React.useState(false);

	const handleCopy = () => {
		const textToCopy =
			typeof queryResult.data === "string"
				? queryResult.data
				: JSON.stringify(queryResult.data, null, 2);

		copyToClipboard(textToCopy);
		setCopySuccess(true);
		setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
	};

	const renderContent = () => {
		if (!queryResult?.data?.context) {
			return <Typography>No context data available</Typography>;
		}

		const { element, error } = formatContext(queryResult.data.context);

		if (error) {
			return <XMLDebugView error={error} />;
		}

		return element ? (
			<XMLTreeNode node={element} />
		) : (
			<Typography color="error">Failed to render XML structure</Typography>
		);
	};

	return (
		<Box mb={2}>
			<Button
				variant="contained"
				onClick={onToggle}
				fullWidth
				sx={{ mb: 1 }}
				color="primary"
			>
				{isExpanded ? "Collapse" : "Expand"} {title}
			</Button>
			<Collapse in={isExpanded}>
				<DataContainer>
					{queryResult.isLoading ? (
						<Box display="flex" justifyContent="center" p={2}>
							<CircularProgress />
						</Box>
					) : queryResult.isError ? (
						<Typography color="error">
							Error: {(queryResult.error as Error)?.message}
						</Typography>
					) : (
						<Box position="relative">
							{queryResult.data && (
								<Button
									size="small"
									onClick={handleCopy}
									sx={{
										position: "absolute",
										right: 8,
										top: 8,
										minWidth: "auto",
										backgroundColor: "rgba(255, 255, 255, 0.9)",
										zIndex: 1,
										"&:hover": {
											backgroundColor: "rgba(255, 255, 255, 1)",
										},
									}}
									startIcon={<ContentCopyIcon />}
								>
									{copySuccess ? "Copied!" : "Copy"}
								</Button>
							)}
							{renderContent()}
						</Box>
					)}
				</DataContainer>
			</Collapse>
		</Box>
	);
};

const PrivateContexts: React.FC = () => {
	const [valorId, setValorId] = React.useState(defaultValorId);
	const [expandedSections, setExpandedSections] = React.useState<
		Record<string, boolean>
	>(
		SECTIONS.reduce(
			(acc, section) => ({ ...acc, [section.queryKey]: false }),
			{},
		),
	);

	const toggleSection = (queryKey: string) => {
		setExpandedSections((prev) => ({
			...prev,
			[queryKey]: !prev[queryKey],
		}));
	};

	const queryResults = SECTIONS.map((section) =>
		useContextQuery({
			queryKey: section.queryKey,
			queryFn: section.queryFn,
			enabled: expandedSections[section.queryKey],
			valorId,
		}),
	);

	return (
		<Container maxWidth="lg">
			<StyledPaper elevation={3}>
				<Typography variant="h4" gutterBottom color="primary">
					Private Contexts
				</Typography>

				<TextField
					label="Valor ID"
					value={valorId}
					onChange={(e) => setValorId(e.target.value)}
					fullWidth
					margin="normal"
					variant="outlined"
				/>

				<Stack spacing={2} mt={3}>
					{SECTIONS.map((section, index) => (
						<ExpandableSection
							key={section.queryKey}
							title={section.title}
							isExpanded={expandedSections[section.queryKey]}
							queryResult={queryResults[index]}
							onToggle={() => toggleSection(section.queryKey)}
						/>
					))}
				</Stack>
			</StyledPaper>
		</Container>
	);
};

export default PrivateContexts;
