import SearchIcon from "@mui/icons-material/Search";
import { Box, InputBase, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "react-query";

import useDebounce from "@/hooks/useDebounce";
import useKeyPress from "@/hooks/useKeyPress";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";

import highlightWords from "highlight-words";

interface SearchResult {
	id?: string;
	valorId?: string;
	name?: string;
	domain?: string;
	logoUrl?: string;
	keyword?: string;
}

interface Props {
	search: (query: string) => SearchResult[];
	selectTerm?: (item: string) => void;
	onClose?(): () => void;
	placeholder?: string;
}

const Search = styled("div")(({ theme }) => ({
	position: "relative",
	zIndex: theme.zIndex.appBar + 1,
	color: theme.palette.text.secondary,
	borderRadius: theme.spacing(5),
	backgroundColor: theme.palette.background.blue,
	"&:hover": {
		boxShadow: theme.shadows[2],
	},
	"&:focus-within": {
		boxShadow: theme.shadows[2],
	},
	margin: theme.spacing(0.5, 0),
	minWidth: "320px",
	maxWidth: "320px",
	[theme.breakpoints.up("md")]: {
		minWidth: "512px",
		maxWidth: "512px",
		margin: theme.spacing(0.5, 1),
	},
	[theme.breakpoints.down("sm")]: {
		minWidth: "180px",
		maxWidth: "180px",
		margin: theme.spacing(0.5, 2),
	},
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 2),
	color: theme.palette.text.primary,
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: theme.palette.text.primary,
	width: "100%",
	border: "none",
	"& .MuiInputBase-input": {
		width: "100%",
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
	},
}));

const SAYTResults = styled(Box)(({ theme }) => ({
	position: "absolute",
	cursor: "pointer",
	zIndex: theme.zIndex.appBar - 1,
	maxHeight: "420px",
	overflowY: "auto",
	boxShadow: theme.shadows[3],
	"& #sayt-box": {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	"& :last-child": {
		border: 0,
	},
	backgroundColor: theme.palette.background.paper,
	minWidth: "240px",
	maxWidth: "240px",

	[theme.breakpoints.up("md")]: {
		minWidth: "496px",
		maxWidth: "496px",
	},

	[theme.breakpoints.up("sm")]: {
		minWidth: "320px",
		maxWidth: "320px",
	},
}));

export function MainSearchBar(props: Props) {
	// rename to select result
	const {
		search = () => {},
		selectTerm = () => {},
		placeholder = "Search...",
		onClose = () => {},
	} = props;

	const theme = useTheme();
	const searchRef = useRef(null);
	const resultsRef = useRef(null);
	const [searchTerm, setSearchTerm] = useState("");
	const [focusedIndex, setFocusedIndex] = useState(-1);
	const [shouldSearch, setShouldSearch] = useState(false);
	const [showResults, setShowResults] = useState(false);
	const debouncedSearchTerm = useDebounce(searchTerm, 150);

	const { data: results = [], status } = useQuery(
		`searchTerm-${debouncedSearchTerm}`,
		async () => {
			if (debouncedSearchTerm && shouldSearch) {
				const apiResults = await search(debouncedSearchTerm);
				setShowResults(true);
				return apiResults;
			}
			if (shouldSearch && debouncedSearchTerm === "") {
				setShowResults(false);
			}
			return [];
		},
	);

	useEffect(() => {
		if (searchRef.current && resultsRef.current) {
			const rect = searchRef.current.getBoundingClientRect();
			resultsRef.current.style.top = `${rect.bottom + window.scrollY}px`;
		}
	}, [searchTerm, showResults]);

	const scrollResult = (direction) => {
		const { length } = results;
		if (length) {
			if (direction === "up") {
				if (focusedIndex === -1) {
					return setFocusedIndex(results.length - 1);
				}
				return setFocusedIndex(focusedIndex - 1);
			}
			if (focusedIndex === length - 1) {
				return setFocusedIndex(-1);
			}
			return setFocusedIndex(focusedIndex + 1);
		}
		return -1;
	};

	function renderTextWithHighlighting(text, queryText) {
		if (!text || !queryText) {
			return <Typography component="span">{text}</Typography>;
		}

		const chunks = highlightWords({
			text,
			query: queryText,
		});

		return chunks.map(({ key, text: textChunk, match }) => (
			<Typography
				key={key}
				component="span"
				sx={
					match
						? {
								fontWeight: 800,
								cursor: "default",
							}
						: null
				}
			>
				{textChunk}
			</Typography>
		));
	}

	const onChange = (event) => {
		const term = event.target.value;
		setShouldSearch(Boolean(term.length));
		setSearchTerm(term);
	};

	const onClick = (item) => {
		setShouldSearch(false);
		setSearchTerm("");
		setShowResults(false);
		setFocusedIndex(-1);
		selectTerm(item);
	};

	const clearResults = () => {
		setShowResults(false);
		setSearchTerm("");
		setFocusedIndex(-1);
		onClose();
	};

	useKeyPress("Escape", () => clearResults());

	useKeyPress("ArrowUp", () => scrollResult("up"));
	useKeyPress("ArrowDown", () => scrollResult("down"));
	useKeyPress("Enter", () => {
		if (focusedIndex === -1 && results.length) {
			onClick(results[0]);
		} else if (results[focusedIndex]) {
			onClick(results[focusedIndex]);
		}
	});

	const renderCo = (name, domain, logoUrl) => (
		<Box display="flex" alignItems="center">
			<CompanyAvatar src={logoUrl} name={name} domain={domain} size="40" />
			<Box display="flex" flexDirection="column" ml={1}>
				<Typography variant="body2">
					{renderTextWithHighlighting(name, debouncedSearchTerm)}
				</Typography>
				<Typography variant="caption">
					{renderTextWithHighlighting(domain, debouncedSearchTerm)}
				</Typography>
			</Box>
		</Box>
	);

	const buildSAYTCompanyResults = (searchResults = []) => {
		const focusedResults = {
			backgroundColor: theme.palette.action.hover,
		};

		if (searchResults.length) {
			return searchResults.map((searchResult, index) => {
				const focused = index === focusedIndex;
				return (
					<Box
						id="sayt-box"
						data-cy={`sayt-${searchResult.valorId || searchResult.id}`}
						padding={1}
						onMouseEnter={() => setFocusedIndex(index)}
						onMouseLeave={() => setFocusedIndex(null)}
						key={searchResult.valorId || searchResult.id}
						sx={focused ? focusedResults : {}}
						onClick={() => onClick(searchResult)}
					>
						{renderCo(
							searchResult.name,
							searchResult.domain,
							searchResult.logoUrl,
						)}
					</Box>
				);
			});
		}
		return null;
	};

	return (
		<div style={{ width: "100%" }}>
			{shouldSearch && Boolean(searchTerm.length) ? (
				<Box
					sx={{
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						zIndex: theme.zIndex.appBar - 2,
					}}
					onClick={clearResults}
				/>
			) : null}
			<Search ref={searchRef}>
				<SearchIconWrapper>
					<SearchIcon />
				</SearchIconWrapper>
				<StyledInputBase
					value={searchTerm}
					onChange={onChange}
					placeholder={placeholder}
					inputProps={{
						autoComplete: "off",
						"data-lpignore": "true",
						"data-form-type": "other",
						"data-cy": "search-input",
					}}
				/>
			</Search>
			{Boolean(shouldSearch) &&
				status !== "loading" &&
				showResults &&
				Boolean(searchTerm.length) && (
					<SAYTResults ref={resultsRef}>
						{buildSAYTCompanyResults(results)}
					</SAYTResults>
				)}
		</div>
	);
}

export default MainSearchBar;
