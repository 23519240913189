import { useTheme } from "@mui/styles";
import confetti from "canvas-confetti";
import { useCallback } from "react";

export const useConfetti = () => {
	const theme = useTheme();

	const triggerConfetti = useCallback(
		(options = {}) => {
			const defaults = {
				particleCount: 300,
				colors: [theme.palette.white.main, theme.palette.primary.main],
			};

			confetti({
				angle: 60,
				spread: 55,
				origin: { x: 0 },
				...defaults,
				...options,
			});
			confetti({
				angle: 120,
				spread: 55,
				origin: { x: 1 },
				...defaults,
				...options,
			});
		},
		[theme.palette.primary.main, theme.palette.white.main],
	);

	return triggerConfetti;
};

export default useConfetti;
