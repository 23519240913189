import type { paths } from "@/api/index";
import { buildURLParameters } from "@/utils/general";
/* eslint-disable no-console */
import axios from "axios";

export type GenericDepartment = "vsv" | "growth";
export type MeetingType = "first_meeting" | "following_meeting";

type getResponseReport = paths["/api/framework/response-report"]["get"];
export type getResponseReportResponses = getResponseReport["responses"];
export type getResponseReportResult =
	getResponseReportResponses["200"]["content"]["application/json"];

export const getResponseReport = async (
	department: GenericDepartment,
	meetingType: MeetingType,
	sinceDate: Date,
): Promise<getResponseReportResult> => {
	const response = await axios.get<getResponseReportResult>(
		"/api/framework/response-report",
		{ params: { department, meetingType, sinceDate } },
	);
	return response.data;
};

type getKeyFrameworkAnswers = paths["/api/framework/key-survey-answers"]["get"];
export type getKeyFrameworkAnswersResponse =
	getKeyFrameworkAnswers["responses"];
export type KeyFrameworkAnswer =
	getKeyFrameworkAnswersResponse["200"]["content"]["application/json"][0];

export const getKeyFrameworkAnswers = async ({
	sinceDate,
}): Promise<KeyFrameworkAnswer[]> => {
	const params = buildURLParameters({
		takenSince: sinceDate,
	});
	try {
		const response = await axios.get(
			`/api/framework/key-survey-answers?${params}`,
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

type GetResponses = paths["/api/framework/search"]["get"]["responses"];
export type ResponsesSearchResult =
	GetResponses["200"]["content"]["application/json"][];
const getResponses = async ({
	responseIds,
	surveyIds,
	completedSince,
	valorIds,
	isMsl,
	departments,
	userIds,
	subjectIds,
	verticals,
	draftStatus,
	isReleased,
	mslResponse,
	limit,
	meetingType,
	includeMaps,
	linkedTaskIds,
}: {
	responseIds?: string[];
	surveyIds?: string[];
	completedSince?: string;
	valorIds?: string[];
	isMsl?: boolean;
	departments?: string[];
	userIds?: string[];
	subjectIds?: string[];
	verticals?: string[];
	draftStatus?: string;
	isReleased?: boolean;
	mslResponse?: boolean;
	limit?: number;
	meetingType?: string;
	includeMaps?: boolean;
	linkedTaskIds?: string[];
} = {}): Promise<Array<ResponsesSearchResult>> => {
	const params = buildURLParameters({
		responseIds,
		surveyIds,
		completedSince,
		valorIds,
		isMsl,
		departments,
		userIds,
		subjectIds,
		verticals,
		draftStatus,
		isReleased,
		mslResponse,
		limit,
		meetingType,
		includeMaps,
		linkedTaskIds,
	});
	const response = await axios.get(`/api/framework/search?${params}`);
	return response.data;
};

type GetAvailableFrameworkSurveys =
	paths["/api/framework/surveys/org/{valor_id}"]["get"];
export type AvailableFrameworkSurveys =
	GetAvailableFrameworkSurveys["responses"]["200"]["content"]["application/json"][0];

const getAvailableFrameworkSurveys = async ({
	valorId,
	excludeVertical,
}: {
	valorId: string;
	excludeVertical?: string;
}): Promise<Array<AvailableFrameworkSurveys>> => {
	try {
		const response = await axios.get(`/api/framework/surveys/org/${valorId}`, {
			params: excludeVertical ? { excludeVertical } : {},
		});
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};
type PatchResponse = paths["/api/framework/responses/{response_id}"]["patch"];
type PatchBody = PatchResponse["requestBody"]["content"]["application/json"];
const patchResponse = async (responseId: string, data: PatchBody) => {
	try {
		const response = await axios.patch(
			`/api/framework/responses/${responseId}`,
			data,
		);
		return response;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

type GetAllSections = paths["/api/framework/all-sections"]["get"]["responses"];
export type Section = GetAllSections["200"]["content"]["application/json"][0];
const getAllSections = async ({
	isAAR,
}: {
	isAAR?: boolean;
} = {}): Promise<Array<Section>> => {
	try {
		const response = await axios.get("/api/framework/all-sections", {
			params: isAAR ? { isAAR } : {},
		});
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

type GetSurveyQuestions =
	paths["/api/framework/surveys/{framework_survey_id}"]["get"]["responses"];
export type SurveyQuestions =
	GetSurveyQuestions["200"]["content"]["application/json"];

const getSurveyQuestions = async (
	surveyId: string,
): Promise<SurveyQuestions> => {
	try {
		const response = await axios.get(`/api/framework/surveys/${surveyId}`);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

type GetAllCompanyResponses =
	paths["/api/framework/company-responses"]["get"]["responses"];
export type CompanyResponses =
	GetAllCompanyResponses["200"]["content"]["application/json"][0];

const getCompanyResponses = async ({
	valorId,
	mostRecentMslOnly = false,
}): Promise<Array<CompanyResponses>> => {
	const params = buildURLParameters({ valorId, mostRecentMslOnly });

	const response = await axios.get(
		`/api/framework/company-responses?${params}`,
	);
	return response.data;
};

const getCompanyResponsesExcel = async ({
	valorId,
	surveys = null,
	attributes = null,
}: { valorId: string; surveys: string[]; attributes: string[] }) => {
	const params = buildURLParameters({ valorId, surveys, attributes });
	try {
		const response = await axios.post(
			`/api/framework/company-responses-excel?${params}`,
			undefined,
			{ responseType: "arraybuffer", headers: { "Content-Type": "blob" } },
		);
		const buffer = response.data;

		const u8 = new Uint8Array(buffer);

		const file = new Blob([u8], { type: "application/vnd.ms-excel" });
		const url = URL.createObjectURL(file);
		const link = document.createElement("a");
		link.download = "responses.xlsx";
		link.href = url;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		return "success";
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

type PostVote = paths["/api/framework/vote/{response_id}"]["post"]["responses"];
export type PostVoteData = PostVote["200"]["content"]["application/json"];
const postVote = async (
	vote: string,
	responseId: string,
	commentary: string = null,
): Promise<PostVoteData> => {
	const response = await axios.post(`/api/framework/vote/${responseId}`, {
		vote,
		commentary,
	});

	return response.data;
};

type GetVotes = paths["/api/framework/vote/{response_id}"]["get"]["responses"];
export type VoteData = GetVotes["200"]["content"]["application/json"];
const getVotes = async (responseId: string): Promise<VoteData> => {
	const response = await axios.get(`/api/framework/vote/${responseId}`);
	return response.data;
};

type GetResponse =
	paths["/api/framework/responses/{response_id}"]["get"]["responses"];
export type Response = GetResponse["200"]["content"]["application/json"];

const getResponse = async (responseId: string): Promise<Response> =>
	axios.get(`/api/framework/responses/${responseId}`);

type PutResponseComment =
	paths["/api/framework/comment/{response_id}"]["put"]["responses"];
type OKResponseComment =
	PutResponseComment["200"]["content"]["application/json"];

const putResponseComment = async (
	responseId: string,
	comment: string,
): Promise<OKResponseComment> => {
	const response = await axios.put(`/api/framework/comment/${responseId}`, {
		comment,
	});
	return response.data;
};

type PutAnswer =
	paths["/api/framework/response/{response_id}/questions/{question_id}/answers"]["put"];
type PutAnswerRequest = PutAnswer["requestBody"]["content"]["application/json"];
type PutAnswerResponse =
	PutAnswer["responses"]["200"]["content"]["application/json"];

const putAnswer = async (
	responseId: string,
	questionId: string,
	answer: PutAnswerRequest,
): Promise<PutAnswerResponse> => {
	const response = await axios.put(
		`/api/framework/response/${responseId}/questions/${questionId}/answers`,
		answer,
	);
	return response.data;
};

type GetAnswer =
	paths["/api/framework/response/{response_id}/questions/{question_id}/answers"]["get"]["responses"];
export type Answer = GetAnswer["200"]["content"]["application/json"];
const getAnswer = async (
	responseId: string,
	questionId: string,
): Promise<Answer> => {
	const response = await axios.get(
		`/api/framework/response/${responseId}/questions/${questionId}/answers`,
	);
	return response.data;
};

type GetAllAnswers =
	paths["/api/framework/response/{response_id}/answers"]["get"]["responses"];
export type AllAnswer = GetAllAnswers["200"]["content"]["application/json"];
const getAllAnswers = async (responseId: string): Promise<AllAnswer> => {
	const response = await axios.get(
		`/api/framework/response/${responseId}/answers`,
	);
	return response.data;
};

type GetDrafts = paths["/api/framework/search"]["get"]["responses"];
export type Draft = GetDrafts["200"]["content"]["application/json"][0];
const getDrafts = async (valorId: string): Promise<Array<Draft>> => {
	const params = buildURLParameters({ valorId });
	const response = await axios.get(`/api/framework/drafts?${params}`);
	return response.data;
};

export {
	getAllSections,
	getResponses,
	getAvailableFrameworkSurveys,
	putResponseComment,
	getSurveyQuestions,
	postVote,
	getVotes,
	getAnswer,
	getDrafts,
	putAnswer,
	getAllAnswers,
	getResponse,
	patchResponse,
	getCompanyResponses,
	getCompanyResponsesExcel,
};
