/* eslint-disable no-console */
import axios from "axios";

// This endpoint is designed for bulk deletion, but the UI is doing only one deletion at a time.
export const deleteNeedleCompetitors = async (valorId, competitorValorIds) => {
	const queryParams = competitorValorIds.map((id) => `id=${id}`).join("&");
	try {
		const response = await axios.delete(
			`/api/competitor-kpis/${valorId}?${queryParams}`,
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const createNeedleCompetitors = async (valorId, competitorValorIds) => {
	const queryParams = competitorValorIds.map((id) => `id=${id}`).join("&");
	try {
		const response = await axios.post(
			`/api/competitor-kpis/${valorId}?${queryParams}`,
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const getNeedleCompetitorsByValorId = async (valorId) => {
	try {
		const response = await axios.get(`/api/competitor-kpis/${valorId}`);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const getCompetitorsHistoriesByValorId = async (valorId) => {
	try {
		const response = await axios.get(
			`/api/competitor-kpis/histories/${valorId}`,
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export default { getNeedleCompetitorsByValorId };
