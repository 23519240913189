import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

export default function Pitchbook() {
	return (
		<SvgIcon>
			<path d="M3.64,17.9C1.67,9.71,9.5,3.45,17,3.05c-4.77,2.89-6.73,6.67-6.44,11A9.78,9.78,0,0,0,3.64,17.9Z" />
			<path d="M11.52,14.75a10.48,10.48,0,0,1,3.57-9.14,25.42,25.42,0,0,1,5.58-1.43c-2.44,1.91-6.61,6.5-5.24,13.13-2-.09-7.12.59-8.46,3.69a7.86,7.86,0,0,1-1.22-4A10.71,10.71,0,0,1,11.52,14.75Z" />
		</SvgIcon>
	);
}
