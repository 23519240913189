import { FIELD_VALUE_TYPE_MAP as typeMap } from "@/pages/ProcessManagement/constants";
import { uniqBy } from "lodash";
import React, {
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from "react";
import useProcess from "../ProcessContext/useProcess";
import useTask from "../ProcessContext/useTask";
import {
	SPECIAL_FIELD_SETTING_IDS,
	getFieldValue,
	getFieldsByStage,
	getHasLifecycle,
} from "../ProcessView/ViewUtils";

const useEditTaskForm = ({
	processId,
	taskId,
	onBeforeUpdate,
	onUpdateError,
	onUpdate,
	ref,
}) => {
	const {
		data: {
			fields: fieldData,
			lifecycleProcessFieldId: lifecycleId,
			lifecycleStages,
		},
		isLoading: isLoadingFields,
	} = useProcess(processId);

	const {
		data: { task: serverValue },
		actions: { refetch, updateTaskFieldValue },
		isLoading: isLoadingTask,
	} = useTask(processId, taskId);

	const [openStage, setOpenStage] = useState(null);
	const [maskValues, setMaskValues] = useState<any | null>(null);
	const [primaryCompanyFieldsDisabled, setPrimaryCoFieldsDisabled] =
		useState(false);
	const [validating, setValidating] = useState(false);

	const nameField = fieldData?.find(
		(field) => field?.settingId === SPECIAL_FIELD_SETTING_IDS.NAME,
	);
	const descriptionField = fieldData?.find(
		(field) => field?.settingId === SPECIAL_FIELD_SETTING_IDS.DESCRIPTION,
	);

	const remainingFields = fieldData?.filter(
		(field) =>
			!(
				field?.settingId === SPECIAL_FIELD_SETTING_IDS.DESCRIPTION ||
				field?.settingId === SPECIAL_FIELD_SETTING_IDS.NAME
			),
	);
	const formFields = remainingFields?.filter(
		(field) => field.showOnForm !== false,
	);

	const computedValues = useMemo(() => {
		// combine maskValues and value.fieldValues
		const currentValues = serverValue?.fieldValues;
		const mask = maskValues;
		if (!mask || Object.keys(mask).length === 0) {
			return currentValues;
		}

		return {
			...(serverValue?.fieldValues || {}),
			...(maskValues || {}),
		};
	}, [serverValue, maskValues]);

	const hasLifecycle = React.useMemo(
		() => getHasLifecycle(lifecycleStages),
		[lifecycleStages],
	);
	const fieldsByStage = React.useMemo(
		() => getFieldsByStage(lifecycleId, lifecycleStages, fieldData),
		[lifecycleId, lifecycleStages, fieldData],
	);

	const primaryCompanyField = useMemo(
		() => fieldData?.find((f) => f.isPrimary && f.type === "company"),
		[fieldData],
	);
	const primaryCompany = useMemo(
		() => computedValues?.[primaryCompanyField?.id]?.company,
		[computedValues, primaryCompanyField],
	);
	const primaryValorId = primaryCompany?.valorId;

	const currentStageId = useMemo(
		() =>
			computedValues?.[lifecycleId]?.choiceId ||
			computedValues?.fieldValues?.[lifecycleId]?.choiceId ||
			null,
		[computedValues, lifecycleId],
	);

	const getRequiredFields = React.useCallback((stageId, stages, fields) => {
		let required = fields.filter((f) => f.required);
		const allStage = stages?.find((s) => s.stageId === null);
		const currentStage = stages?.find((s) => s.stageId === stageId);
		if (allStage || currentStage) {
			required = uniqBy(
				[
					...uniqBy(
						[...(allStage?.fields || []), ...(currentStage?.fields || [])],
						"childProcessFieldId",
					)
						.filter((f) => f.required)
						.map(({ childProcessFieldId }) =>
							fields.find((f) => f.id === childProcessFieldId),
						),
					...required,
				],
				"id",
			);
		}
		return required.map((f) => ({
			...f,
			stages: stages?.filter((s) =>
				s.fields.some(
					(field) => field.childProcessFieldId === f.id && field.visible,
				),
			),
		}));
	}, []);

	const getInvalidFields = React.useCallback(
		(values, requiredFields) =>
			requiredFields.filter(({ id: fieldId, type }) => {
				const fieldValue = getFieldValue(values?.[fieldId], type);
				if (fieldValue === undefined || fieldValue === null) return true;
				if (Array.isArray(fieldValue) && fieldValue.length === 0) return true;
				return false;
			}),
		[],
	);

	const requiredFields = useMemo(
		() => getRequiredFields(currentStageId, lifecycleStages, fieldData),
		[currentStageId, lifecycleStages, fieldData, getRequiredFields],
	);

	const unfilledRequiredFields = useMemo(
		() => getInvalidFields(computedValues, requiredFields),
		[computedValues, requiredFields, getInvalidFields],
	);

	useImperativeHandle(ref, () => ({
		validate: () => {
			setValidating(true);
			// validate every required field has a value
			const required = getRequiredFields(
				currentStageId,
				lifecycleStages,
				fieldData,
			);
			// set error state for required
			const emptyRequiredFields = getInvalidFields(computedValues, required);
			// return if valid
			return emptyRequiredFields.map((f) => ({
				field: f,
				error: "required",
			}));
		},
	}));

	useEffect(() => {
		// get lifecycle field value id
		setOpenStage(currentStageId);
	}, [currentStageId]);

	const pushChanges = async (formValues: T) => {
		// get diff of changes
		const values = JSON.parse(JSON.stringify(formValues));
		if (Object.keys(values).length === 0) return;

		const hasFields = Object.keys(values || {}).length > 0;

		// if primary company field has changed, fetch new company data
		const currentPCFValue =
			primaryCompanyField &&
			values?.fieldValues?.[primaryCompanyField.id]?.company?.valorId;
		const newPCFValue =
			primaryCompanyField &&
			formValues?.[primaryCompanyField.id]?.company?.valorId;
		const hasPrimaryCompanyChanged =
			currentPCFValue !== newPCFValue && newPCFValue !== undefined;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let updatePromises: Promise<any>[] = [];
		if (hasFields) {
			const changedFields = Object.entries(values).flatMap(
				([fieldId, fieldValues]) => {
					// get field
					const field = fieldData.find((f) => f.id === fieldId);
					if (Array.isArray(fieldValues)) {
						return fieldValues.map((fieldValue) => ({
							fieldId,
							fieldValue: fieldValue[typeMap[field.type]],
						}));
					}
					return { fieldId, fieldValue: fieldValues[typeMap[field.type]] };
				},
			);

			const changedFieldsWithFieldData = changedFields.map(
				({ fieldId, fieldValue }) => {
					const foundField = fieldData.find((f) => f.id === fieldId);
					return {
						fieldId,
						fieldType: foundField.type,
						fieldValue,
						isOrganizationField: foundField?.isOrganizationField,
						settingId: foundField?.settingId,
					};
				},
			);

			const fieldValueUpdates = changedFieldsWithFieldData.map(
				({ fieldId, fieldType, fieldValue, isOrganizationField, settingId }) =>
					updateTaskFieldValue(
						processId,
						taskId,
						fieldType,
						fieldId,
						settingId,
						fieldValue,
						isOrganizationField,
						primaryValorId,
					),
			);

			updatePromises = fieldValueUpdates;
		}

		onBeforeUpdate?.();
		try {
			if (hasPrimaryCompanyChanged) {
				setPrimaryCoFieldsDisabled(true);
				await Promise.all(updatePromises);
			} else {
				await Promise.all(updatePromises);
			}

			refetch().then(() => {
				setPrimaryCoFieldsDisabled(false);
				// unset any mask values present in values
				setMaskValues((prev) => {
					const newValues = { ...prev };
					Object.keys(values).forEach((key) => {
						delete newValues[key];
					});
					return newValues;
				});
			});

			onUpdate?.();
		} catch (e) {
			onUpdateError?.();
		}
	};

	const handleBlur = (newValue, field) => {
		const newValObj = {
			[typeMap[field.type]]: newValue,
		};

		const oldValue =
			serverValue?.fieldValues?.[field.id]?.[typeMap[field.type]];

		if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
			// eslint-disable-next-line eqeqeq
			if (
				(oldValue === undefined || oldValue === null) &&
				((Array.isArray(newValue) && newValue.length === 0) || !newValue) &&
				field.type !== "checkbox"
			) {
				return;
			}
			pushChanges({ [field.id]: newValObj });

			// update mask
			setMaskValues((prev) => ({
				...prev,
				[field.id]: newValObj,
			}));
		}
	};

	function getStageIdentifier(stageId, stageName, isFieldDependent) {
		if (isFieldDependent) {
			return stageName;
		}
		return stageId;
	}

	return {
		// Loading States
		isLoadingFields,
		isLoadingTask,

		// Field Data
		nameField,
		descriptionField,
		formFields,

		// Values
		computedValues,
		primaryCompany,
		primaryValorId,
		// Stage Management
		openStage,
		currentStageId,
		hasLifecycle,
		fieldsByStage,

		// Required Fields
		requiredFields,
		unfilledRequiredFields,

		// States
		primaryCompanyFieldsDisabled,
		validating,

		// Handlers
		handleBlur,
		setOpenStage,
		getStageIdentifier,
	};
};
export default useEditTaskForm;
