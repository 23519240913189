import DebouncedTextField from "@/components/InfiniteTable/DebouncedTextField";
import { Box } from "@mui/material";
import type React from "react";

type FilterProps = {
	getFilterValue: () => any;
	setFilterValue: (value: any) => void;
	getFacetedUniqueValues?: () => any;
	component?: React.ComponentType<any>;
	header?: string;
	field?: any;
};

export function TextFilter({
	header,
	getFilterValue,
	setFilterValue,
}: {
	header: string;
	getFilterValue: () => any;
	setFilterValue: (value: any) => void;
}) {
	return (
		<Box padding={2}>
			<DebouncedTextField
				placeholder={`Filter by ${header}`}
				value={(getFilterValue?.() as string) ?? ""}
				variant="outlined"
				onChange={(value) => {
					setFilterValue?.(value.toString());
				}}
				clearable
			/>
		</Box>
	);
}

export default function Filter({
	getFilterValue,
	setFilterValue,
	getFacetedUniqueValues,
	component,
	header,
	columnId,
	field,
}: FilterProps) {
	if (!component) {
		return (
			<TextFilter
				header={header}
				getFilterValue={getFilterValue}
				setFilterValue={setFilterValue}
			/>
		);
	}

	const CustomFilter = component;
	return (
		<Box>
			<CustomFilter
				getFilterValue={getFilterValue}
				setFilterValue={setFilterValue}
				getFacetedUniqueValues={getFacetedUniqueValues}
				field={field}
				columnId={columnId}
			/>
		</Box>
	);
}
