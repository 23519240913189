import { Box, TableRow as MuiTableRow, useTheme } from "@mui/material";
import type { ColumnFiltersState, SortingState } from "@tanstack/react-table";
import { debounce, isObject } from "lodash";
import type React from "react";
import { useCallback, useMemo, useRef } from "react";
import VirtualRow from "./TableRow";
import useVirtualTableBody, {
	InfiniteTableProvider,
} from "./useVirtualTableBody";

export const TableRow = MuiTableRow;

export function VirtualTableContainer({
	onScrollBottomReached,
	children,
	height,
	minHeight,
	fullScreen = false,
}: {
	onScrollBottomReached?: () => void;
	children: React.ReactNode;
	height?: string;
	minHeight?: string;
	fullScreen?: boolean;
}) {
	const ref = useRef<HTMLDivElement>(null);
	const theme = useTheme();
	const defaultNegativeHeight = useMemo(() => {
		if (theme.breakpoints.values.md < window.innerWidth) {
			return 450;
		}
		if (theme.breakpoints.values.sm < window.innerWidth) {
			return 250;
		}
		return 550;
	}, [window.innerWidth]);

	const debounceFetch = useCallback(
		debounce(() => {
			onScrollBottomReached?.();
		}, 200),
		[onScrollBottomReached],
	);
	const handleScroll = useCallback(
		(containerRefElement?: HTMLDivElement | null) => {
			if (containerRefElement) {
				const { scrollTop, scrollHeight, clientHeight } = containerRefElement;
				if (scrollHeight - scrollTop - clientHeight < defaultNegativeHeight) {
					debounceFetch();
				}
			}
		},
		[defaultNegativeHeight, onScrollBottomReached],
	);

	const containerStyle = useMemo(
		() => ({
			position: "relative",
			height: {
				xs: height || "calc(100vh - 450px)",
				sm: height || "calc(100vh - 350px)",
				md: height || "calc(100vh - 450px)",
			},
			minHeight: minHeight || "500px",
			overflow: "auto",
			...(fullScreen
				? {
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						zIndex: theme.zIndex.drawer - 1,
						backgroundColor: theme.palette.background.paper,
					}
				: {}),
		}),
		[height, minHeight, fullScreen, theme],
	);

	return (
		<InfiniteTableProvider value={ref}>
			<Box
				ref={ref}
				onScroll={(e) => handleScroll(e.target as HTMLDivElement)}
				sx={containerStyle}
			>
				{children}
			</Box>
		</InfiniteTableProvider>
	);
}

export function VirtualTableBody({
	rows,
	estimateSize,
	onRowClick,
	onRowDoubleClick,
	table,
}: {
	rows: any[];
	estimateSize: number;
	onRowClick?: (row: any) => void;
	onRowDoubleClick?: (row: any) => void;
	table: any;
}) {
	const {
		visibleColumns,
		totalSize,
		virtualRows,
		virtualColumns,
		virtualPaddingLeft,
		virtualPaddingRight,
		handleTouchStart,
		handleTouchEnd,
		isInitialized,
	} = useVirtualTableBody({
		rows,
		estimateSize,
		onRowDoubleClick,
		table,
	});

	if (!isInitialized) {
		return <tbody />;
	}

	return (
		<tbody
			style={{
				display: "grid",
				height: `${totalSize}px`,
				position: "relative",
			}}
		>
			{virtualRows.map((virtualRow) => {
				const row = rows[virtualRow.index];
				if (!visibleColumns.length) return null;

				return (
					<VirtualRow
						row={row}
						key={virtualRow.index}
						virtualRow={virtualRow}
						visibleColumns={visibleColumns}
						virtualColumns={virtualColumns}
						virtualPaddingLeft={virtualPaddingLeft}
						virtualPaddingRight={virtualPaddingRight}
						estimateSize={estimateSize}
						onRowClick={onRowClick}
						onRowDoubleClick={onRowDoubleClick}
						handleTouchStart={handleTouchStart}
						handleTouchEnd={handleTouchEnd}
					/>
				);
			})}
		</tbody>
	);
}

export const mapSorting = (sorting: SortingState): string[] =>
	sorting?.map((x) => `${x.desc ? "-" : ""}${x.id}`) || [];

const parseValue = (val: Array<{ value: string }> | { value: string }) => {
	if (Array.isArray(val) && isObject(val[0])) {
		return val.map((x) => x.value);
	}
	if (isObject(val) && val.value !== undefined) {
		return val.value;
	}
	return val;
};

export const mapFilters = (
	columnFilters: ColumnFiltersState,
	globalFilter: string | null = "",
) => ({
	...columnFilters.reduce(
		(acc, curr) => ({
			...acc,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			[curr.id]: parseValue(curr.value as any),
		}),
		{},
	),
	...(globalFilter?.length ? { "*": globalFilter } : {}),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const depageData = (data?: { pages: any[] }) =>
	data?.pages.flatMap((x) => x.data) || [];

export const getNextPageParam = (lastPage) => lastPage?.markers?.next;
