import { Add, Delete, DragIndicatorOutlined, Info } from "@mui/icons-material";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Snackbar,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
	ChoiceFields,
	FieldTypes as fieldTypes,
	GroupableFields as groupableFields,
} from "./Constants";

const CheckboxField = styled(Box)(() => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
}));

function CheckboxHelp({
	title,
	disabled,
}: {
	title: string;
	disabled: boolean;
}) {
	return (
		<Tooltip title={title}>
			<Info fontSize="small" color={disabled ? "disabled" : "inherit"} />
		</Tooltip>
	);
}

export default function FieldForm({
	field,
	onCancel,
	onSave,
	disabled,
	formError,
	dismissFormError,
}: {
	field?: any;
	onCancel: () => void;
	onSave: (fieldToSave: any) => void;
	disabled: boolean;
	formError: string;
	dismissFormError: () => void;
}) {
	const [formValues, setFormValues] = React.useState(
		field || {
			name: "",
			type: "text",
		},
	);
	const [confirmDelete, setConfirmDelete] = React.useState(null);
	const [showIsPrimarySnackbar, setShowIsPrimarySnackbar] =
		React.useState(false);

	React.useEffect(() => {
		if (formValues.type !== "company" && formValues.isPrimary) {
			setShowIsPrimarySnackbar(true);
			setFormValues({
				...formValues,
				isPrimary: false,
			});
		}

		if (formValues.defaultGroupBy && !formValues.groupable) {
			setFormValues({
				...formValues,
				groupable: true,
			});
		}

		if (!groupableFields.includes(formValues.type) && formValues.groupable) {
			setFormValues({
				...formValues,
				groupable: false,
				defaultGroupBy: false,
			});
		}
	}, [formValues]);

	return (
		<Box padding={1}>
			{formError && (
				<Alert
					severity="error"
					onClose={dismissFormError}
					sx={{
						mb: 2,
					}}
				>
					{formError.response?.data?.detail ||
						"An error occurred. Please refresh and try again."}
				</Alert>
			)}
			<form
				onSubmit={(e) => {
					e.preventDefault();
					const values = formValues;
					values.choices = values.choices?.filter(
						(choice) => choice.value.trim() !== "",
					);
					onSave(values);
				}}
			>
				<Box display="flex" flexDirection="column" gap={1} mb={1}>
					<TextField
						label="Field Name"
						fullWidth
						value={formValues.name}
						onChange={(e) => {
							setFormValues({
								...formValues,
								name: e.target.value,
							});
						}}
						required
						disabled={disabled}
					/>

					<FormControl>
						<InputLabel for="field-type">Field Type</InputLabel>
						<Select
							id="field-type"
							label="Field Type"
							fullWidth
							value={formValues.type}
							onChange={(e) => {
								setFormValues({
									...formValues,
									type: e.target.value,
								});
							}}
							required
							disabled={disabled}
							// disabled={disabled || formValues.isOrganizationField}
						>
							{fieldTypes.map((type) => (
								<MenuItem key={type} value={type}>
									{type}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<Box display="flex" flexDirection="column">
						<CheckboxField>
							<FormControlLabel
								control={
									<Checkbox
										checked={formValues.groupable}
										onChange={(e) => {
											setFormValues({
												...formValues,
												groupable: e.target.checked,
											});
										}}
										disabled={
											disabled || !groupableFields.includes(formValues.type)
										}
									/>
								}
								label="Groupable"
							/>
							<CheckboxHelp
								title="Field can be grouped by on the board"
								disabled={
									disabled || !groupableFields.includes(formValues.type)
								}
							/>
						</CheckboxField>

						<CheckboxField>
							<FormControlLabel
								control={
									<Checkbox
										checked={formValues.defaultGroupBy}
										onChange={(e) => {
											setFormValues({
												...formValues,
												defaultGroupBy: e.target.checked,
											});
										}}
										disabled={
											disabled || !groupableFields.includes(formValues.type)
										}
									/>
								}
								label="Default Group By"
							/>
							<CheckboxHelp
								title="Field is the default group by on the board"
								disabled={
									disabled || !groupableFields.includes(formValues.type)
								}
							/>
						</CheckboxField>

						<CheckboxField>
							<FormControlLabel
								control={
									<Checkbox
										checked={formValues.showOnCard}
										onChange={(e) => {
											setFormValues({
												...formValues,
												showOnCard: e.target.checked,
											});
										}}
										disabled={disabled}
									/>
								}
								label="Show On Card"
							/>
							<CheckboxHelp
								title="Field is displayed on the boards card"
								disabled={disabled}
							/>
						</CheckboxField>

						<CheckboxField>
							<FormControlLabel
								control={
									<Checkbox
										checked={formValues.showOnForm}
										onChange={(e) => {
											setFormValues({
												...formValues,
												showOnForm: e.target.checked,
											});
										}}
										disabled={disabled}
									/>
								}
								label="Show On Form"
							/>
							<CheckboxHelp
								title="Field is displayed on the add / edit form (if no lifecycle has been defined)"
								disabled={disabled}
							/>
						</CheckboxField>

						<CheckboxField>
							<FormControlLabel
								control={
									<Checkbox
										checked={formValues.sortable}
										onChange={(e) => {
											setFormValues({
												...formValues,
												sortable: e.target.checked,
											});
										}}
										disabled={disabled}
									/>
								}
								label="Sortable"
							/>
							<CheckboxHelp title="Field is sortable" disabled={disabled} />
						</CheckboxField>

						<CheckboxField>
							<FormControlLabel
								control={
									<Checkbox
										checked={formValues.isPrimary}
										onChange={(e) => {
											setFormValues({
												...formValues,
												isPrimary: e.target.checked,
											});
										}}
										disabled={disabled || formValues.type !== "company"}
									/>
								}
								label="Is Primary"
							/>
							<CheckboxHelp
								title="Field is the primary company field"
								disabled={disabled || formValues.type !== "company"}
							/>
						</CheckboxField>
					</Box>

					{ChoiceFields.includes(formValues.type) && (
						<Box display="flex" flexDirection="column" gap={1}>
							<Typography variant="boldBody1">Choices</Typography>

							<DragDropContext
								onDragEnd={(dragEvent) => {
									// update choices order
									const { source, destination } = dragEvent;
									if (!destination) {
										return;
									}

									const newChoices = [...formValues.choices];
									newChoices.splice(source.index, 1);
									newChoices.splice(
										destination.index,
										0,
										formValues.choices[source.index],
									);

									setFormValues({
										...formValues,
										choices: newChoices,
									});
								}}
							>
								<Droppable droppableId="choices">
									{(dropProvided) => (
										<Box
											ref={dropProvided.innerRef}
											/* eslint-disable-next-line react/jsx-props-no-spreading */
											{...dropProvided.droppableProps}
										>
											{formValues.choices?.map((choice, index) => (
												<Draggable
													key={choice.id}
													draggableId={choice.id}
													index={index}
												>
													{(dragProvided) => (
														<Box
															ref={dragProvided.innerRef}
															/* eslint-disable-next-line react/jsx-props-no-spreading */
															{...dragProvided.draggableProps}
															/* eslint-disable-next-line react/jsx-props-no-spreading */
															{...dragProvided.dragHandleProps}
															mt={1}
														>
															<Box
																display="flex"
																flexDirection="row"
																alignItems="center"
															>
																<DragIndicatorOutlined
																	sx={{
																		cursor: "grab",
																	}}
																/>
																<TextField
																	key={choice.id}
																	label="Choice"
																	value={choice.value}
																	onChange={(e) => {
																		setFormValues({
																			...formValues,
																			choices: [
																				...formValues.choices.slice(0, index),
																				{
																					...choice,
																					value: e.target.value,
																				},
																				...formValues.choices.slice(index + 1),
																			],
																		});
																	}}
																	fullWidth
																	required
																	disabled={disabled}
																/>
																<Tooltip title="Delete Choice">
																	<IconButton
																		onClick={() => {
																			setConfirmDelete(choice.id);
																		}}
																		disabled={disabled}
																	>
																		<Delete />
																	</IconButton>
																</Tooltip>
															</Box>
														</Box>
													)}
												</Draggable>
											))}
											{dropProvided.placeholder}
										</Box>
									)}
								</Droppable>
							</DragDropContext>

							<Tooltip title="Add Choice">
								<Button
									onClick={() => {
										// add blank choice
										setFormValues({
											...formValues,
											choices: [
												...(formValues.choices || []),
												{
													id: crypto.randomUUID(),
													value: "",
												},
											],
										});
									}}
									fullWidth
									disabled={disabled}
								>
									<Add />
								</Button>
							</Tooltip>
						</Box>
					)}
				</Box>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="flex-end"
					gap={1}
				>
					<Button onClick={onCancel} disabled={disabled}>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						disabled={disabled}
					>
						Save
					</Button>
				</Box>
			</form>

			<Dialog
				open={confirmDelete !== null}
				onClose={() => setConfirmDelete(null)}
			>
				<DialogTitle>Are you sure you want to delete this choice?</DialogTitle>
				<DialogContent>This will permanently delete the choice.</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setFormValues({
								...formValues,
								choices: formValues.choices.filter(
									(choice) => choice.id !== confirmDelete,
								),
							});
							setConfirmDelete(null);
						}}
						color="primary"
					>
						Yes
					</Button>
					<Button onClick={() => setConfirmDelete(null)}>No</Button>
				</DialogActions>
			</Dialog>

			<Snackbar
				open={showIsPrimarySnackbar}
				onClose={() => setShowIsPrimarySnackbar(false)}
				message="Is Primary can only apply to a company field. It has been disabled and set to false."
			/>
		</Box>
	);
}
