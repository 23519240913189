import { capitalizeFirstLetter } from "@/utils/general";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Typography, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
	path: string;
}

export default function PathBreadCrumbs(props: Props) {
	const theme = useTheme();
	const { path } = props;

	const formatPath = (pathName) => {
		const parts = pathName.split("-").filter((x) => x);
		return parts.map((part) => capitalizeFirstLetter(part)).join(" ");
	};
	const parts = path.split("/").filter((x) => x);
	let buildPath = "";
	return (
		<Breadcrumbs
			aria-label="breadcrumb"
			separator={<NavigateNextIcon fontSize="small" />}
		>
			{parts.map((value, index) => {
				buildPath += `/${value}`;
				const isLast = index === parts.length - 1;
				const formatted = formatPath(value);
				return (
					<Typography
						key={`breadcrumbs-typography-${formatted}`}
						color="textPrimary"
						sx={{
							"& > a": {
								textDecoration: "none",
								color: theme.palette.primary.main,
							},
						}}
						variant="h3"
					>
						{isLast ? (
							formatted
						) : (
							<Link color="inherit" key={`link-${buildPath}`} to={buildPath}>
								{formatted}
							</Link>
						)}
					</Typography>
				);
			})}
		</Breadcrumbs>
	);
}
