import {
	getRestrictions,
	updateProfilePicture,
	updateUserData,
} from "@/api/Users";
import { useAuth } from "@/hooks/useAuth";
import useLocalStorageState from "@/hooks/useLocalStorageState";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import Section from "@/ui/atoms/Section";
import UserAvatar from "@/ui/atoms/UserAvatar";
import Page from "@/ui/molecules/Page";
import {
	Alert,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	FormControlLabel,
	Skeleton,
	Snackbar,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Field, Form } from "react-final-form";
import { useQuery } from "react-query";

interface NameData {
	firstName: string;
	lastName: string;
}

function UserSettingsConfigLoader() {
	const width = 100;
	const height = 30;
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="flex-start"
			gap={0.5}
			py={1}
		>
			<Skeleton variant="rounded" width={width} height={height} />
			<Skeleton variant="rounded" width={width * 2} height={height} />
			<Skeleton variant="rounded" width={width} height={height} />
		</Box>
	);
}

function UserSettingsPage({ error, isLoading, disabled }) {
	const [isDarkSet, setIsDarkMode] = useLocalStorageState(false, "isDarkMode");
	const [initialNames, setInitialNames] = useState<NameData>({
		firstName: "",
		lastName: "",
	});
	const [successOpen, setSuccessOpen] = useState(false);
	const { user, fetchUser } = useAuth();
	const role = user.roleName;

	useEffect(() => {
		if (user) {
			const { firstName, lastName } = user;
			setInitialNames({ firstName, lastName });
		}
	}, [user, setInitialNames]);

	const theme = useTheme();

	const { data: roleData = { access: [], restrictions: [] } } = useQuery(
		["RoleRestrictions", user.roleId],
		async () => {
			const data = await getRestrictions({
				id: user.roleId,
				column: "role_id",
			});
			return data;
		},
		{
			enabled: !!user.roleId,
		},
	);

	const { data: userData = { access: [], restrictions: [] } } = useQuery(
		["UserRestrictions", user.id],
		async () => {
			const data = await getRestrictions({ id: user.id, column: "user_id" });
			return data;
		},
		{
			enabled: !!user.id,
		},
	);

	const allowedUserValorIds = userData.access.map((x) => x.valorId);

	const dedupeById = (array) => {
		const seen = new Set();
		return array.filter((item) => {
			const duplicate = seen.has(item.valorId);
			seen.add(item.valorId);
			return !duplicate;
		});
	};
	const combinedData = dedupeById([
		...roleData.restrictions.filter(
			(roleCo) => allowedUserValorIds.indexOf(roleCo.valorId) === -1,
		),
		...userData.restrictions,
	]).sort((a, b) => a.name.localeCompare(b.name));

	const onChange = async (file: File) => {
		try {
			const result = await updateProfilePicture(file);
			console.log(result);
		} catch (error) {
			console.error("Error uploading file:", error);
		}
		await fetchUser();
	};

	const onSubmit = async (values: NameData, file: File) => {
		try {
			const response = await updateUserData({ id: user.id, ...values });
			const result = await updateProfilePicture(file);
			console.log(response, result);
		} catch (error) {
			console.error("Error:", error);
		}
		await fetchUser();
	};

	if (error) {
		return (
			<Section mt={2}>
				<Section.Title variant="h3">User Settings</Section.Title>
				<Section.Content>
					<Alert severity="error">
						There was an error loading the settings page. Please refresh the
						page or contact the labs team if issues persist.
					</Alert>
				</Section.Content>
			</Section>
		);
	}

	return (
		<Page>
			<Page.Title> Settings </Page.Title>
			<Page.Content>
				<Section mt={2}>
					<Card sx={{ minWidth: 275 }}>
						<CardContent>
							<Section.Content>
								{isLoading ? (
									<UserSettingsConfigLoader />
								) : (
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
										}}
										gap={2.5}
										py={1.5}
									>
										<Form
											onSubmit={onSubmit}
											initialValues={initialNames}
											render={({
												handleSubmit,
												submitting,
												pristine,
												dirty,
											}) => (
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														alignItems: "center",
													}}
												>
													<Box
														sx={{
															padding: "8px",
															borderRadius: "8px",
															"&:hover": {
																backgroundColor: (t) =>
																	t.palette.background.blue,
															},
														}}
													>
														<FileUploader
															hoverTitle="Change Profile Picture"
															type={["png", "jpg"]}
															handleChange={onChange}
															dropMessageStyle={{
																background: theme.palette.background.blue,
																borderRadius: theme.spacing(4),
																padding: theme.spacing(2),
															}}
														>
															<UserAvatar
																useLargeImage
																style={{ width: "144px", height: "144px" }}
																user={user}
															/>
														</FileUploader>
													</Box>
													<Box
														sx={{
															"& > :not(style)": { m: 1.5, width: "75ch" },
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
														noValidate
														autoComplete="off"
													>
														<Field name="firstName">
															{({ input }) => (
																<TextField
																	{...input}
																	id="filled-basic"
																	label="First Name"
																	variant="filled"
																	type="text"
																	placeholder="Enter first name"
																/>
															)}
														</Field>
														<Field name="lastName">
															{({ input }) => (
																<TextField
																	{...input}
																	id="filled-basic"
																	label="Last Name"
																	variant="filled"
																	type="text"
																	placeholder="Enter last name"
																/>
															)}
														</Field>
														<Button
															type="submit"
															variant="contained"
															color="primary"
															handleChange={onChange}
															onClick={handleSubmit}
															disabled={
																isLoading || disabled || submitting || pristine
															}
														>
															Save Changes
														</Button>
													</Box>
												</Box>
											)}
										/>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
											}}
											gap={0.5}
											py={0.5}
										>
											<Typography variant="h5">Your Role: </Typography>
											<Typography variant="h2"> {role} </Typography>
										</Box>
										<Typography variant="h3">
											Companies You're Restricted from Accessing:
										</Typography>
										<Box
											sx={{
												display: "flex",
												flexWrap: "wrap",
												flexDirection: "row",
												gap: "4px",
											}}
										>
											{combinedData.map(({ valorId, name, logoUrl }) => (
												<Box
													sx={{
														display: "flex",
														justifyContent: "center",
														gap: "8px",
														alignItems: "center",
														border: (t) => `4px solid ${t.palette.divider}`,
														padding: "6px",
														borderRadius: "6px",
													}}
													mt={1}
													key={valorId}
												>
													<CompanyAvatar size={48} src={logoUrl} name={name} />
													<Typography>{name}</Typography>
												</Box>
											))}
										</Box>
										<Box mt={1} display="flex" flexDirection="row" gap={1}>
											<CardActions>
												<FormControlLabel
													control={
														<Switch
															size="medium"
															checked={isDarkSet}
															onChange={(e) => {
																setIsDarkMode(e.currentTarget.checked);
																window.location.reload();
															}}
														/>
													}
													label={
														isDarkSet ? "Toggle Lightmode" : "Toggle Darkmode"
													}
												/>
											</CardActions>
										</Box>
									</Box>
								)}
							</Section.Content>
						</CardContent>
					</Card>
					<Snackbar
						open={successOpen}
						autoHideDuration={6000}
						onClose={() => setSuccessOpen(false)}
						anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					>
						<Alert severity="success" onClose={() => setSuccessOpen(false)}>
							Name Updated
						</Alert>
					</Snackbar>
				</Section>
			</Page.Content>
		</Page>
	);
}

export default UserSettingsPage;
