import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

export default function AtreidesIcon(props) {
	return (
		<SvgIcon {...props} viewBox="0 0 24 24">
			<path
				opacity="0.57"
				transform="scale(0.06 0.06)"
				d="M237.073 228.073C237.895 227.577 238.75 227.087 239.62 226.682C241.928 225.607 243.947 225.907 246.266 226.748C246.792 227.075 246.872 227.046 247.048 227.629C246.723 228.069 246.629 228.074 246.107 228.262Q241.589 228.237 237.073 228.073Z"
			/>
			<path
				opacity="0.65"
				transform="scale(0.06 0.06)"
				d="M130.233 176.893L171.712 103.716L187.595 75.7107C190.316 70.9222 193.592 66.013 195.877 61.0208C198.312 65.6998 201.084 70.2183 203.674 74.8139L220.252 104.103L261.269 176.354L285.958 219.867L294.397 234.798C296.28 238.138 298.077 241.62 300.226 244.799C308.567 248.479 317.199 248.922 325.99 250.233C321.347 250.675 316.696 251.185 312.027 250.901C298.545 250.082 287.203 244.829 275.609 238.3C269.965 235.121 264.296 231.802 258.036 229.975C254.122 228.833 250.153 228.5 246.107 228.262C246.629 228.074 246.723 228.069 247.048 227.629C246.872 227.046 246.792 227.075 246.266 226.748C246.42 226.051 246.378 226.054 245.991 225.453C244.628 223.336 241.535 219.719 238.872 219.279C237.376 219.032 235.563 219.443 234.071 219.654C230.218 220.198 221.625 221.979 218.153 220.852C211.901 218.825 207.573 211.968 203.082 207.544C199.621 204.135 195.711 201.04 191.347 198.873C185.751 196.094 179.323 195.073 173.228 194.03C152.644 190.507 132.625 189.005 118.668 171.324C116.95 169.147 115.438 166.836 113.996 164.47C119.064 169.574 124.048 173.227 130.233 176.893Z"
			/>
			<path
				opacity="0.85"
				transform="scale(0.06 0.06)"
				d="M113.235 173.612C113.448 173.775 113.579 173.886 113.713 174.139C114.047 174.77 114.094 175.982 114.28 176.71C114.885 179.084 115.643 181.228 116.81 183.383C122.101 193.151 131.349 201.198 141.926 204.695C148.083 206.731 154.603 208.015 160.444 210.918C164.355 212.862 167.715 215.712 171.409 218.016C179.855 223.283 186.877 225.861 196.318 228.668C187.846 230.702 181.68 229.891 173.392 228.037C180.802 237.71 192.725 241.832 204.037 244.96C204.548 243.317 205.069 242.22 206.201 240.923C209.502 239.619 213.427 240.486 216.883 239.456C221.5 241.323 226.103 243.279 230.776 245.001Q236.31 247.064 242.046 248.475Q247.781 249.887 253.641 250.627C260.338 251.466 266.869 251.722 273.515 253.111Q278.152 254.04 282.683 255.392C289.346 257.444 295.806 259.991 302.86 260.248C309.182 260.479 314.249 258.876 319.897 256.262C316.817 258.881 314.001 261.351 310.175 262.822C313.322 267.676 316.026 272.929 318.888 277.962Q325.763 289.869 332.341 301.942C325.715 301.593 319.011 301.795 312.375 301.802L140.528 301.803L87.2094 301.799L70.4789 301.788C66.7541 301.787 62.975 301.662 59.2647 302.002C67.4283 287.213 75.914 272.571 84.27 257.888L119.605 195.593C114.12 189.395 112.828 181.602 113.235 173.612Z"
			/>
		</SvgIcon>
	);
}
