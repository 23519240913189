import type { SignalModelPipeline } from "@/api/Reporting";
import {
	Box,
	Button,
	Card,
	Divider,
	Skeleton,
	Stack,
	TableRow,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useMemo } from "react";

import {
	type ColumnFiltersState,
	type SortingState,
	type VisibilityState,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	useReactTable,
} from "@tanstack/react-table";

import {
	VirtualTableBody,
	VirtualTableContainer,
	depageData,
} from "@/components/InfiniteTable";
import { SelectFilter } from "@/components/Table/CustomFilters/Select";
import { Toolbar as TableToolbar } from "@/components/Table/Header";
import HeaderCell from "@/components/Table/Header/Cell";
import OpportunityNoteCell from "@/components/Table/OpportunityNote/OpportunityNoteCell";
import {
	CompanyCell,
	DateCell,
	LocationCell,
	TextCell,
} from "@/components/Table/Primitives";
import ChipCell from "@/components/Table/Primitives/ChipCell";
import LastContactedCell from "@/components/Table/Primitives/LastContactedCell";
import MoicCell from "@/components/Table/Primitives/MoicCell";
import MoneyCell from "@/components/Table/Primitives/MoneyCell";
import MultiUserCell from "@/components/Table/Primitives/MultiUserCell";
import SectorsCell from "@/components/Table/Sectors/SectorsCell";
import { StickyTable, StickyTableHead } from "@/components/Table/StickyTable";
import { canSeeDev } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import ExplainabilityChip from "@/pages/Org/Explainability/ModelFeatures/ExplainabilityChip";
import CenteredProgress from "@/ui/atoms/CenteredProgress";

export default function MantisTable({
	data,
	isWeeklyIC = false,
	isLoading,
	isFetching,
	isFetchingNextPage,
	hasNextPage,
	fetchNextPage,
	sorting,
	columnFilters,
	columnVisibility,
	onSortingChange,
	onColumnFiltersChange,
	onColumnVisibilityChange,
	onGlobalFilterChange,
}: {
	data: SignalModelPipeline[];
	isWeeklyIC?: boolean;
	isLoading?: boolean;
	isFetching?: boolean;
	isFetchingNextPage?: boolean;
	hasNextPage?: boolean;
	sorting: SortingState;
	columnFilters: ColumnFiltersState;
	columnVisibility: VisibilityState;
	fetchNextPage?: () => void;
	onSortingChange?: (s: SortingState) => void;
	onColumnFiltersChange?: (c: ColumnFiltersState) => void;
	onColumnVisibilityChange?: (cv: VisibilityState) => void;
	onGlobalFilterChange?: (globalFilter: string | null) => void;
}) {
	const modelId = "1035901f-04fa-4155-bf5c-49f5ee035c33";
	const theme = useTheme();
	const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

	const [showAllFeatures, setShowAllFeatures] = React.useState(false);

	const { user } = useAuth();
	const canViewInsignificantFeatures = canSeeDev(user);

	const columnDef = useMemo(() => {
		let cols;
		if (isWeeklyIC) {
			cols = [
				{
					header: "Company",
					accessorFn: (row) => ({
						...row.company,
						description: row.description,
					}),
					cell: (prop) => <CompanyCell value={prop.getValue()} />,
					id: "company",
					minSize: isSmDown ? 48 : 250,
					size: isSmDown ? 48 : 250,
				},
				{
					header: "Status",
					accessorKey: "status",
					cell: (prop) => <TextCell value={prop.getValue()} />,
					id: "status",
					enableColumnFilter: true,
					filter: (prop) => (
						<SelectFilter
							getFilterValue={prop.getFilterValue}
							setFilterValue={prop.setFilterValue}
							getFacetedUniqueValues={prop.getFacetedUniqueValues}
							multiple
							options={["Outreach", "Quality Review", "Connected", "Access"]}
						/>
					),
				},
				{
					header: "Series A MOIC",
					accessorKey: "seriesA_moic",
					cell: (prop) => (
						<MoicCell
							value={prop.getValue()}
							signalType="signal_mantis_prediction"
						/>
					),
					id: "seriesA_moic",
					meta: {
						sortType: "numeric",
					},
				},
				{
					header: "Monitoring Service Lead",
					accessorKey: "monitoringServiceLead",
					cell: (prop) => <MultiUserCell value={prop.getValue()} />,
					id: "monitoringServiceLead",
					minSize: 200,
				},
				{
					header: "Notes",
					accessorFn: (row) => row.id,
					cell: (prop) => (
						<OpportunityNoteCell opportunityId={prop.getValue()} />
					),
					id: "notes",
					minSize: 200,
				},
				{
					header: "Amount",
					accessorKey: "amount",
					cell: (prop) => (
						<MoneyCell value={prop.getValue()} isFundingRound={false} />
					),
					id: "amount",
				},
				{
					header: "Close Date",
					accessorKey: "closeDate",
					cell: (prop) => <DateCell value={prop.getValue()} />,
					id: "closeDate",
				},
				{
					header: "Valor Security Type",
					accessorKey: "dealType",
					cell: (prop) => <TextCell value={prop.getValue()} />,
					id: "dealType",
				},
			];
		} else {
			cols = [
				{
					header: "Company",
					accessorFn: (row) => ({
						...row.company,
						description: row.description,
					}),
					cell: (prop) => <CompanyCell value={prop.getValue()} />,
					id: "company",
					minSize: isSmDown ? 100 : 250,
					size: isSmDown ? 100 : 250,
				},
				{
					header: "Status",
					accessorKey: "status",
					cell: (prop) => <TextCell value={prop.getValue()} />,
					id: "status",
					enableColumnFilter: true,
					filter: (prop) => (
						<SelectFilter
							getFilterValue={prop.getFilterValue}
							setFilterValue={prop.setFilterValue}
							getFacetedUniqueValues={prop.getFacetedUniqueValues}
							multiple
							options={["Outreach", "Quality Review", "Connected", "Access"]}
						/>
					),
				},
				{
					header: "Monitoring Service Lead",
					accessorKey: "monitoringServiceLead",
					cell: (prop) => <MultiUserCell value={prop.getValue()} />,
					id: "monitoringServiceLead",
					minSize: 200,
				},
				{
					header: "Series A MOIC",
					accessorKey: "seriesA_moic",
					cell: (prop) => (
						<MoicCell
							value={prop.getValue()}
							signalType="signal_mantis_prediction"
						/>
					),
					id: "seriesA_moic",
					meta: {
						sortType: "numeric",
					},
				},
				{
					header: "Pass Reason",
					accessorKey: "passReason",
					cell: (prop) => <TextCell value={prop.getValue()} />,
					id: "passReason",
				},
				{
					header: "Amount",
					accessorKey: "amount",
					cell: (prop) => (
						<MoneyCell value={prop.getValue()} isFundingRound={false} />
					),
					id: "amount",
				},
				{
					header: "Close Date",
					accessorKey: "closeDate",
					cell: (prop) => <DateCell value={prop.getValue()} />,
					id: "closeDate",
				},
				{
					header: "Deal Type",
					accessorKey: "dealType",
					cell: (prop) => <TextCell value={prop.getValue()} />,
					id: "dealType",
				},
				{
					header: "Notable Investors",
					accessorKey: "investors",
					cell: (prop) => <ChipCell value={prop.getValue()} />,
					id: "investors",
				},
				{
					header: "Notes",
					accessorFn: (row) => row.id,
					cell: (prop) => (
						<OpportunityNoteCell opportunityId={prop.getValue()} />
					),
					id: "notes",
				},
				{
					header: "Explainability",
					accessorFn: (row) => row,
					cell: (prop) => {
						const row = prop.getValue();
						if (!row.signalFeaturesByDeal?.[0]?.features) {
							return (
								<Stack direction="row" spacing={1}>
									<Skeleton variant="rounded" width={100} height={24} />
									<Skeleton variant="rounded" width={100} height={24} />
									<Skeleton variant="rounded" width={100} height={24} />
								</Stack>
							);
						}
						/* eslint-disable-next-line camelcase */
						const { seriesA_moic, signalFeaturesByDeal } = row;
						// see addison about these magic numbers
						// 2.95 is shap base value, 0.1 is the threshold
						/* eslint-disable-next-line camelcase */
						const threshold = Math.abs(seriesA_moic - 2.95) * 0.1;

						const significantFeatures = signalFeaturesByDeal?.[0]?.features
							?.filter((x) => x.shapScore > threshold)
							.slice(0, 3);
						const insignificantFeatures = signalFeaturesByDeal?.[0]?.features
							?.filter((x) => !significantFeatures.find((y) => x.id === y.id))
							.slice(0, 7);
						return (
							<Box
								display="flex"
								flexDirection="row"
								alignItems="center"
								gap={1}
								flexWrap="wrap"
							>
								{signalFeaturesByDeal?.[0]?.features?.length === 0 && (
									<Typography variant="body2">
										No features exist for this deal.
									</Typography>
								)}
								{significantFeatures?.map((x) => (
									<ExplainabilityChip
										key={x.id}
										modelId={modelId}
										indexId={signalFeaturesByDeal[0].dealId}
										feature={x}
										dense
									/>
								))}
								{canViewInsignificantFeatures && showAllFeatures && (
									<>
										<Divider />
										<Box
											display="flex"
											flexDirection="row"
											alignItems="center"
											gap={1}
											flexWrap="wrap"
											sx={{
												opacity: 0.8,
											}}
										>
											{insignificantFeatures?.length > 0 &&
												insignificantFeatures?.map((x) => (
													<ExplainabilityChip
														key={x.id}
														modelId={modelId}
														indexId={signalFeaturesByDeal[0].dealId}
														feature={x}
														dense
														showAsPositiveOrNegative
													/>
												))}
										</Box>
									</>
								)}
							</Box>
						);
					},
					minSize: 350,
					id: "features",
				},
				{
					header: "Data Coverage",
					accessorFn: (row) => {
						const { total, existing } =
							row.signalFeaturesByDeal?.[0]?.coverage || {};
						if (!total || !existing) return null;
						return `${existing}/${total} - ${((existing / total) * 100).toFixed(
							0,
						)}%`;
					},
					cell: (prop) => <TextCell value={prop.getValue()} />,
					id: "coverage",
				},
				{
					header: "Sectors",
					accessorKey: "predictedSectors",
					cell: (prop) => <SectorsCell value={prop.getValue()} />,
					id: "predictedSectors",
					minSize: 200,
				},
				{
					header: "Location",
					accessorKey: "location",
					cell: (prop) => <LocationCell value={prop.getValue()} />,
					id: "location",
				},
				{
					header: "Last Contacted",
					accessorKey: "lastContacted",
					cell: (prop) => <LastContactedCell value={prop.getValue()} />,
					id: "lastContacted",
				},
				{
					header: "Last Deal Date",
					accessorFn: (row) => row.signalFeaturesByDeal?.[0]?.dealDate,
					cell: (prop) => <DateCell value={prop.getValue()} />,
					id: "dealDate",
				},
				{
					header: "Last Deal Type",
					accessorFn: (row) => row.signalFeaturesByDeal?.[0]?.dealType,
					cell: (prop) => <TextCell value={prop.getValue()} />,
					id: "lastDealType",
				},
				{
					header: "Last Deal Size",
					accessorFn: (row) => row.signalFeaturesByDeal?.[0]?.dealSize,
					cell: (prop) => <MoneyCell value={prop.getValue()} />,
					id: "dealSize",
				},
				{
					header: "Last Deal Pre-Money",
					accessorFn: (row) => row.signalFeaturesByDeal?.[0]?.premoneyValuation,
					cell: (prop) => <MoneyCell value={prop.getValue()} />,
					id: "dealPremoney",
				},
				{
					header: "Date Added",
					accessorKey: "dateAdded",
					cell: (prop) => <DateCell value={prop.getValue()} />,
					id: "dateAdded",
				},
			];
		}
		return cols.map((x) => ({
			enableColumnFilter: false,
			enableSorting: false,
			filterFn: () => true,
			...x,
		}));
	}, [
		isWeeklyIC,
		showAllFeatures,
		canViewInsignificantFeatures,
		modelId,
		isSmDown,
	]);

	const pages = useMemo(() => depageData(data), [data]);

	const totalCount = data?.pages[0]?.totalCount;

	const table = useReactTable({
		data: pages,
		columns: columnDef,
		state: {
			sorting,
			columnFilters,
			columnVisibility: {
				...columnVisibility,
			},
		},
		onColumnFiltersChange,
		onGlobalFilterChange,
		onColumnVisibilityChange,
		onSortingChange,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	});

	return (
		<Card
			sx={{
				maxHeight: "100vh",
				overflowY: "hidden",
			}}
		>
			{(isLoading || isFetchingNextPage) && (
				<CenteredProgress
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				/>
			)}

			{!isWeeklyIC && (
				<Box
					px={1}
					py={2}
					maxWidth="100%"
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="space-between"
				>
					<Box
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="space-between"
						gap={1}
						width="100%"
					>
						<Typography
							variant="h3"
							data-cy={totalCount === 0 ? "no-data-message" : null}
						>
							{"Signal Model Pipeline "}
							{typeof totalCount === "number" &&
								`- ${
									totalCount > 0 ? totalCount.toLocaleString() : "No"
								} Results`}
						</Typography>
						{canViewInsignificantFeatures && (
							<Button
								variant="outlined"
								size="small"
								onClick={() => setShowAllFeatures((prev) => !prev)}
							>
								{showAllFeatures
									? "Show Significant Features"
									: "Show All Features"}
							</Button>
						)}
					</Box>
				</Box>
			)}

			<TableToolbar
				columnHeaders={columnDef}
				sorting={sorting}
				columnFilters={columnFilters}
				columnVisibility={columnVisibility}
				readOnly
				// onRemoveColumnFilters={() => onColumnFiltersChange([])}
				// onRemoveSorting={() => onSortingChange([])}
				// onRemoveVisibility={() => onColumnVisibilityChange({})}
			/>

			<VirtualTableContainer
				height="calc(100vh - 200px)"
				onScrollBottomReached={() => {
					if (!isFetching && hasNextPage) {
						fetchNextPage?.();
					}
				}}
			>
				<StickyTable>
					<StickyTableHead>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<HeaderCell
										key={header.id}
										header={header}
										column={header.column}
										table={table}
									/>
								))}
							</TableRow>
						))}
					</StickyTableHead>
					<VirtualTableBody
						rows={table.getRowModel().rows}
						estimateSize={60}
						table={table}
					/>
				</StickyTable>
			</VirtualTableContainer>
		</Card>
	);
}
