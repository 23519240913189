import axios from "axios";

export default async function upload<T>({
	method,
	url,
	data,
}: {
	method: "PUT" | "POST";
	url: string;
	data: { [key: string]: string | Blob | string[] };
}): Promise<T> {
	const formData = new FormData();

	const dataEntries = Object.entries(data);
	dataEntries.forEach(([key, value]) => {
		if (Array.isArray(value)) {
			// formData.append(key, JSON.stringify(value));
			value.forEach((item) => {
				formData.append(`${key}`, item);
			});
		} else {
			formData.append(key, value);
		}
	});

	const config = {
		method,
		url,
		data: formData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
	};

	const response = await axios<T>(config);
	return response.data;
}
