import { Person } from "@mui/icons-material";
import { Box, Chip, Tooltip } from "@mui/material";
import type React from "react";

type PersonValue = {
	firstName: string;
	lastName: string;
	primaryEmail: string;
};

export default function PersonCell({
	value,
	children,
}: {
	value: PersonValue;
	children?: React.ReactNode;
}) {
	if (!value || typeof value !== "object") return <>-</>;

	const fullName = `${value.firstName} ${value.lastName}`;

	return (
		<Box
			display="flex"
			alignItems="flex-start"
			gap={0.5}
			sx={{ flexDirection: "column" }}
			overflow="hidden"
			whiteSpace="nowrap"
			minWidth="140px"
			height="100%"
		>
			<Tooltip title={value.primaryEmail}>
				<Chip label={fullName} avatar={<Person />} sx={{ height: "25px" }} />
			</Tooltip>

			{children ? (
				<Box display="flex" flexDirection="column">
					{children}
				</Box>
			) : null}
		</Box>
	);
}
