import { patchResponse } from "@/api/FrameworkSurvey";
import ClampsTooltip from "@/components/ClampsTooltip";
import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";
import { canEditAllSurveys, canReleaseAllPRs } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import UserAvatar from "@/ui/atoms/UserAvatar";
import { getSurveyLink } from "@/utils/surveys";
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Link as MuiLink,
	Stack,
	Switch,
	Typography,
	styled,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useState, useMemo, useCallback, memo } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import GridItem from "./GridItem";

dayjs.extend(utc);

const RESPONSE_ROW_OFFSET = 3;
const MOBILE_HEADER_HEIGHT = 100;
const DESKTOP_HEADER_HEIGHT = 160;

const MOBILE_WIDTH = 120;
const DESKTOP_WIDTH = 224;

const AvatarStack = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	gap: theme.spacing(0.5),
}));

const MobileHeader = memo(({ response, dateObj }) => (
	<Box
		sx={{
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			gap: 0.5,
		}}
	>
		<UserAvatar
			user={response.user}
			style={{ width: 24, height: 24 }}
			displayTooltip
		/>
		<Stack
			direction="column"
			spacing={0.5}
			alignItems="center"
			sx={{
				minWidth: "60px",
				padding: "8px",
			}}
		>
			<Typography variant="h4" sx={{ fontWeight: "bold", lineHeight: 1 }}>
				{dateObj.format("D")}
			</Typography>
			<Typography
				variant="subtitle2"
				sx={{ textTransform: "uppercase", lineHeight: 1 }}
			>
				{dateObj.format("MMM")}
			</Typography>
			<Typography variant="caption" sx={{ opacity: 0.7, lineHeight: 1 }}>
				{dateObj.format("YYYY")}
			</Typography>
		</Stack>
	</Box>
));

const DesktopHeader = memo(({ response, surveyUrl, dateObj }) => (
	<>
		<AvatarStack>
			<UserAvatar
				user={response.user}
				style={{ width: 32, height: 32 }}
				displayTooltip
			/>
			{response.organization && (
				<UserAvatar
					user={{
						name: response.organization.name,
						profilePicture: response.organization.logo,
					}}
					style={{ width: 28, height: 28 }}
					displayTooltip
				/>
			)}
		</AvatarStack>

		<Typography
			variant="caption"
			noWrap
			sx={{ color: "text.secondary", textAlign: "center" }}
		>
			{dateObj.format("D MMM YY")}
		</Typography>

		{response.department && (
			<Typography
				variant="caption"
				noWrap
				sx={{ color: "text.secondary", textAlign: "center", maxWidth: "150px" }}
			>
				{InvestmentProductMappings[response.department]}
			</Typography>
		)}

		{surveyUrl ? (
			<MuiLink
				component={Link}
				to={surveyUrl}
				sx={{
					fontSize: "0.75rem",
					color: "primary.main",
					textAlign: "center",
					"&:hover": { textDecoration: "underline" },
				}}
			>
				{response.surveyName}
			</MuiLink>
		) : (
			<Typography
				variant="caption"
				noWrap
				sx={{ color: "text.secondary", textAlign: "center", maxWidth: "150px" }}
			>
				{response.surveyName}
			</Typography>
		)}
	</>
));

const CompactHeaderCell = memo(
	({ response, user, isAAR, handleChange, sx }) => {
		const [dialogOpen, setDialogOpen] = useState(false);
		const theme = useTheme();
		const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

		const surveyUrl = useMemo(() => getSurveyLink(response), [response]);
		const dateObj = useMemo(
			() => dayjs.utc(response.completedAt).local(),
			[response.completedAt],
		);

		const toggleDialog = useCallback(() => setDialogOpen((prev) => !prev), []);

		return (
			<>
				<Box
					sx={{
						height: isMobile ? MOBILE_HEADER_HEIGHT : DESKTOP_HEADER_HEIGHT,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "space-evenly",
						position: "sticky",
						top: 0,
						zIndex: 499,
						backgroundColor: (theme) =>
							alpha(theme.palette.background.paper, 1),
						padding: isMobile ? 1 : 2,
						cursor: "pointer",
						borderBottom: "1px solid",
						borderColor: "dividerBlue",
						"&:hover": {
							cursor: "pointer",
							backgroundColor: (theme) =>
								theme.palette.background.activeTableColumn,
						},
						...sx,
					}}
					onClick={toggleDialog}
				>
					{isMobile ? (
						<MobileHeader response={response} dateObj={dateObj} />
					) : (
						<DesktopHeader
							response={response}
							surveyUrl={surveyUrl}
							dateObj={dateObj}
						/>
					)}
				</Box>

				<Dialog
					open={dialogOpen}
					onClose={toggleDialog}
					maxWidth="xs"
					fullWidth
				>
					<DialogTitle>Survey Details</DialogTitle>
					<DialogContent>
						<Box
							sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 1 }}
						>
							<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
								<UserAvatar
									user={response.user}
									style={{ width: 40, height: 40 }}
									displayTooltip
								/>
								<Box>
									<Typography variant="subtitle1">
										{response.user.name}
									</Typography>
									{response.organization && (
										<Typography variant="body2" color="text.secondary">
											{response.organization.name}
										</Typography>
									)}
								</Box>
							</Box>

							<Typography variant="body2">
								<strong>Date:</strong> {dateObj.format("D-MM-YYYY")}
							</Typography>

							{response.department && (
								<Typography variant="body2">
									<strong>Department:</strong>{" "}
									{InvestmentProductMappings[response.department]}
								</Typography>
							)}

							{response.surveyName && (
								<Typography variant="body2">
									<strong>Survey:</strong> {response.surveyName}
								</Typography>
							)}

							{surveyUrl && (
								<MuiLink component={Link} to={surveyUrl} sx={{ mt: 1 }}>
									View Full Survey
								</MuiLink>
							)}

							{canEditAllSurveys(user) && !isAAR && (
								<MuiLink
									component={Link}
									to={`/org/${response.valorId}/surveys/${response.surveyId}?responseId=${response.id}`}
									sx={{ mt: 1 }}
								>
									Edit Survey
								</MuiLink>
							)}

							{isAAR &&
								(user.id === response.user.id || canReleaseAllPRs(user)) &&
								response.department === "peer_review" && (
									<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
										<Switch
											checked={response?.isReleased || false}
											onChange={(e) =>
												handleChange(response?.id, e.target.checked)
											}
										/>
										<Typography>
											{response?.isReleased ? "Released" : "Unreleased"}
										</Typography>
									</Box>
								)}
						</Box>
					</DialogContent>
				</Dialog>
			</>
		);
	},
);

const GridCell = memo(({ row, column, isMobile, children }) => (
	<Box
		sx={{
			gridColumn: column,
			gridRow: row,
			borderLeft: column === 2 ? "none" : !isMobile ? "1px solid" : "none",
			borderBottom: !isMobile ? "1px solid" : "none",
			borderRight: !isMobile ? "1px solid" : "none",
			borderColor: "dividerBlue",
		}}
	>
		{children}
	</Box>
));

const FrameworkGrid = ({
	responses,
	sections,
	isAAR = false,
	aarParams = {},
}) => {
	const queryClient = useQueryClient();
	const { user } = useAuth();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const StyledGrid = styled(Box)(({ theme }) => ({
		display: "grid",
		width: "100%",
		overflowX: "auto",
		overflowY: "auto",
		height: "80vh",
		backgroundColor: theme.palette.background.paper,
		position: "relative",
	}));

	const handleChange = useCallback(
		async (id, checked) => {
			try {
				queryClient.setQueryData(["aarSurveyResponses", aarParams], (oldData) =>
					oldData.map((r) => ({
						...r,
						isReleased: r.id === id ? checked : r.isReleased,
					})),
				);

				const response = await patchResponse(id, {
					hidden: false,
					status: "PUBLISHED",
					isReleased: checked,
				});

				if (response?.status === 403) {
					throw new Error(response.status);
				}
			} catch (err) {
				queryClient.invalidateQueries(["aarSurveyResponses", aarParams], {
					exact: true,
				});
			}
		},
		[queryClient, aarParams],
	);

	const leftColumn = useMemo(() => {
		let row = 1;
		return sections.map((section) => {
			row += RESPONSE_ROW_OFFSET;
			return (
				<React.Fragment key={section.id}>
					<Box
						sx={{
							gridRow: row,
							gridColumn: 1,
							position: "sticky",
							left: 0,
							zIndex: 950, // Between corner overlay and headers
							bgcolor: "background.paper",
							p: 1,
							width: isMobile ? MOBILE_WIDTH : DESKTOP_WIDTH,
							borderRight: "1px solid",
							borderColor: "dividerBlue",
						}}
					>
						<ClampsTooltip title={section.name}>
							<Typography
								variant="boldBody1"
								noWrap
								sx={{
									maxWidth: {
										// -4 for ellipsis
										xs: MOBILE_WIDTH - 4,
										sm: DESKTOP_WIDTH - 4,
									},
								}}
							>
								{section.name}
							</Typography>
						</ClampsTooltip>
					</Box>
					{section.attributes.map((attribute) => {
						row += RESPONSE_ROW_OFFSET;
						return (
							<Box
								key={attribute.id}
								sx={{
									gridRow: row,
									gridColumn: 1,
									position: "sticky",
									left: 0,
									zIndex: 950, // Same as section headers
									bgcolor: "background.paper",
									p: 1,
									borderRight: "1px solid",
									borderColor: "divider",
									display: "flex",
									alignItems: "center",
								}}
							>
								<ClampsTooltip title={attribute.name}>
									<Typography
										variant="body2"
										noWrap
										sx={{
											maxWidth: {
												xs: MOBILE_WIDTH - 4,
												sm: DESKTOP_WIDTH - 4,
											},
										}}
									>
										{attribute.name}
									</Typography>
								</ClampsTooltip>
							</Box>
						);
					})}
				</React.Fragment>
			);
		});
	}, [sections, isMobile]);

	const getResponses = useCallback(
		(answers, column) => {
			let row = 1;
			return sections.flatMap((section) => {
				row += RESPONSE_ROW_OFFSET;
				const cells = [
					<GridCell
						key={`cell-${row}-${column}`}
						row={row}
						column={column}
						isMobile={isMobile}
					/>,
				];

				section.attributes.forEach((attribute) => {
					row += RESPONSE_ROW_OFFSET;
					const response = answers[attribute.id];

					cells.push(
						<GridCell
							key={`cell-${row}-${column}`}
							row={row}
							column={column}
							isMobile={isMobile}
						>
							{response && (
								<GridItem
									question={response.question}
									commentary={response.commentary}
									color={response.choice.color}
									text={response.choice.text}
									answerData={response.answerData}
									attribute={attribute}
									subject={response?.users}
								/>
							)}
						</GridCell>,
					);
				});

				return cells;
			});
		},
		[sections, isMobile],
	);

	const gridContent = useMemo(
		() => (
			<StyledGrid
				sx={{
					gridTemplateColumns: `${isMobile ? MOBILE_WIDTH : DESKTOP_WIDTH}px repeat(${responses.length}, ${isMobile ? "48px" : `${DESKTOP_HEADER_HEIGHT}px`})`,
					marginTop: isMobile
						? `-${MOBILE_HEADER_HEIGHT}px`
						: `-${DESKTOP_HEADER_HEIGHT}px`,
				}}
			>
				{leftColumn}
				{responses.map((response, idx) => (
					<React.Fragment key={response.id}>
						<CompactHeaderCell
							response={response}
							user={user}
							isAAR={isAAR}
							handleChange={handleChange}
							sx={{
								gridColumn: idx + 2,
								gridRow: 1,
								zIndex: 900,
							}}
						/>
						{getResponses(response.answers, idx + 2)}
					</React.Fragment>
				))}
			</StyledGrid>
		),
		[responses, leftColumn, isMobile, user, isAAR, handleChange, getResponses],
	);

	if (!responses?.length) {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					p: 2,
				}}
			>
				<Typography variant="h6">No Survey Responses</Typography>
			</Box>
		);
	}

	return (
		<Box
			sx={{ maxWidth: "100%", overflow: "hidden" }}
			data-cy="framework-grid__container"
		>
			<Box sx={{ position: "relative" }}>
				<Box
					sx={{
						position: "sticky",
						top: 0,
						left: 0,
						width: `${isMobile ? MOBILE_WIDTH : DESKTOP_WIDTH}px`,
						height: isMobile ? MOBILE_HEADER_HEIGHT : DESKTOP_HEADER_HEIGHT,
						backgroundColor: "background.paper",
						zIndex: 1000,
						borderBottom: "1px solid",
						borderRight: "1px solid",
						borderColor: "divider",
					}}
				/>
				{gridContent}
			</Box>
		</Box>
	);
};

export default memo(FrameworkGrid);
