import UserAvatar from "@/ui/atoms/UserAvatar";
import { Box, Chip, useTheme } from "@mui/material";
import React from "react";
import ChatBubble from "./ChatBubble";

export default function UserChatBubble({ user, content }) {
	const theme = useTheme();
	const color = theme.palette.background.paper;
	const fontColor = theme.palette.getContrastText(color);

	const parseContent = (text) => {
		if (typeof text !== "string") return text;

		const parts = text.split(/(<private_context>.*?<\/private_context>)/s);

		return parts.map((part) => {
			if (part.startsWith("<private_context>")) {
				// Extract title from XML structure
				const titleMatch = part.match(/<title>\s*(.*?)\s*<\/title>/);
				const title = titleMatch ? titleMatch[1] : "Private";

				return (
					<Chip
						key={title}
						label={title}
						size="small"
						sx={{
							mx: 0.5,
							backgroundColor: theme.palette.primary.main,
							color: theme.palette.primary.contrastText,
							"& .MuiChip-label": {
								maxWidth: "280px",
							},
						}}
					/>
				);
			}
			return part;
		});
	};

	return (
		<ChatBubble
			type="USER"
			sx={{
				color: fontColor,
				backgroundColor: color,
				border: `1px solid ${theme.palette.divider}`,
			}}
		>
			<UserAvatar
				user={user}
				style={{
					height: 30,
					width: 30,
				}}
			/>
			<Box
				sx={{
					lineHeight: "30px",
					color: fontColor,
					fontSize: "1rem",
				}}
			>
				{parseContent(content)}
			</Box>
		</ChatBubble>
	);
}
