import type { ResponsesSearchResult } from "@/api/FrameworkSurvey"; // Adjust the import path as necessary
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import React from "react";

export default function ChoiceCell({
	value,
}: { value: ResponsesSearchResult[0][0]["answers"][0]["choice"] }) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<Box>
			<Box
				sx={{
					width: "64px",
					height: "32px",
					borderRadius: (theme) => theme.spacing(1),
					backgroundColor: value
						? (theme) => theme.palette[value.color].main
						: (theme) => theme.palette.grey[400],
				}}
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			/>
			<Popover
				sx={{
					pointerEvents: "none",
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Box sx={{ p: 1, maxWidth: 300 }}>
					{value ? value.text : "No choice"}
				</Box>
			</Popover>
		</Box>
	);
}
