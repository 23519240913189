import { Send, Square } from "@mui/icons-material";
import { Box, IconButton, TextField } from "@mui/material";
import type React from "react";
import { useState } from "react";
import type { MessageInputProps } from "./utils/ChatState";

export function MessageInput({
	message,
	onChange,
	onKeyDown,
	onSend,
	onStop,
	onFileDrop,
	disabled,
	placeholder,
	inputRef,
	handlePaste,
}: MessageInputProps) {
	const [isDragging, setIsDragging] = useState(false);

	const handleDragOver = (e: React.DragEvent) => {
		e.preventDefault();
		setIsDragging(true);
	};

	const handleDragLeave = (e: React.DragEvent) => {
		e.preventDefault();
		setIsDragging(false);
	};

	const handleDrop = (e: React.DragEvent) => {
		e.preventDefault();
		setIsDragging(false);

		const files = Array.from(e.dataTransfer.files);
		if (files.length > 0) {
			onFileDrop(files);
		}
	};

	return (
		<Box
			padding={1}
			position="relative"
			bottom={0}
			borderRadius={1}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}
			sx={{
				border: (theme) =>
					`solid 1px ${isDragging ? theme.palette.primary.main : theme.palette.divider}`,
				background: (theme) => theme.palette.background.paper,
				transition: "border-color 0.2s ease",
			}}
		>
			<TextField
				multiline
				value={message}
				onChange={onChange}
				onKeyDown={onKeyDown}
				onPaste={handlePaste}
				ref={inputRef}
				maxRows={8}
				sx={{
					width: "100%",
					background: (theme) => theme.palette.background.paper,
					maxHeight: {
						sm: 200,
						md: 300,
						lg: 400,
					},
					overflowY: "auto",
				}}
				placeholder={isDragging ? "Drop file here..." : placeholder}
				InputProps={{
					endAdornment: (
						<IconButton
							onClick={() => {
								if (!disabled && message.trim()) {
									onSend();
								} else if (disabled) {
									onStop();
								}
							}}
						>
							{disabled ? (
								<Square />
							) : (
								<Send sx={{ color: (t) => t.palette.primary.main }} />
							)}
						</IconButton>
					),
				}}
			/>
		</Box>
	);
}

export default MessageInput;
