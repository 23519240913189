import { useEffect, useState } from "react";

export default function useLandscape() {
	const [isLandscape, setIsLandscape] = useState(
		window.matchMedia("(orientation: landscape)").matches,
	);

	useEffect(() => {
		const mediaQuery = window.matchMedia("(orientation: landscape)");
		const handleOrientationChange = () => setIsLandscape(mediaQuery.matches);

		mediaQuery.addEventListener("change", handleOrientationChange);

		return () => {
			mediaQuery.removeEventListener("change", handleOrientationChange);
		};
	}, []);

	return isLandscape;
}
