import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	useMediaQuery,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Form } from "react-final-form";

import useProcess from "../ProcessContext/useProcess";
import useTask from "../ProcessContext/useTask";

import EditTaskForm from "./EditTaskForm";

type NewTaskFormProps = {
	open: boolean;
	onClose: () => void;
	onCreate: () => void;
	onChange: () => void;
	onCancel: () => void;
	processId: string;
	taskId: string;
};

export function NewTaskFormDialog({
	open,
	onClose,
	onCreate,
	onChange,
	onCancel,
	processId,
	taskId,
}: NewTaskFormProps) {
	const {
		data: process,
		// isLoading: processLoading,
	} = useProcess(processId);
	const { entityName, modalNewTaskTitle } = process.settings;

	// TODO useTask
	const {
		actions: { publishTask },
	} = useTask(processId, taskId);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleOnChange = () => {
		//
		onChange();
	};
	const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const formRef = useRef();
	const alertRef = useRef();
	const [errors, setErrors] = useState([]);
	const validateRequired = () => {
		//
		const result = formRef.current?.validate();
		setErrors(result);
		return !result.length;
	};

	const handleClose = () => {
		//
		setErrors([]);
		onClose();
	};

	// TODO: rewrite these two forms to use headless api of final form
	return (
		<Form
			onSubmit={async () => {
				if (validateRequired()) {
					setIsSubmitting(true);
					await publishTask(taskId);
					onCreate();
					setIsSubmitting(false);
				} else {
					setTimeout(() => {
						alertRef.current?.scrollIntoView();
					}, 2);
				}
			}}
			render={({ handleSubmit }) => (
				<Dialog
					open={open}
					onClose={handleClose}
					fullWidth
					maxWidth="lg"
					fullScreen={isSmDown}
					PaperProps={{
						sx: {
							maxHeight: {
								md: "90vh",
							},
						},
					}}
					disablePortal
				>
					<form
						onChange={() => {
							handleOnChange();
						}}
					>
						<DialogTitle
							sx={{
								position: "sticky",
								top: 0,
								px: {
									xs: 1,
									md: 2,
								},
								py: {
									xs: 1,
									md: 2,
								},
							}}
							data-cy="new-task-dialog-title"
						>
							{modalNewTaskTitle || `New - ${entityName}`}
						</DialogTitle>
						<DialogContent
							sx={{
								maxHeight: {
									xs: "calc(90vh - 60.5px)",
									md: "calc(90vh - 200px)",
								},
								px: {
									xs: 0.5,
									md: 2,
								},
							}}
						>
							<EditTaskForm
								ref={formRef}
								alert={
									errors.length > 0 && (
										<Alert severity="error" ref={alertRef}>
											Please fill out all required fields. Missing value(s) for:{" "}
											<ul>
												{errors
													.filter(({ error }) => error === "required")
													.map((error) => (
														<li key={error.field.id}>{error.field.name}</li>
													))}
											</ul>
										</Alert>
									)
								}
								onBeforeUpdate={() => {
									// setEditsUpdateState("Saving");
								}}
								onUpdateError={() => {
									// setEditsUpdateState("Error");
								}}
								onUpdate={() => {
									// setEditsUpdateState("Saved");
									// onUpdate();
								}}
								processId={processId}
								taskId={taskId}
								onChange={(task) => {
									if (
										task?.fieldValues &&
										Object.keys(task?.fieldValues).length
									) {
										handleOnChange();
									}
								}}
								maxWidth="100%"
								scroll="none"
							/>
						</DialogContent>
						<DialogActions
							sx={{
								position: "sticky",
								bottom: 0,
							}}
						>
							<Box
								marginTop={1}
								display="flex"
								flexDirection="row"
								justifyContent="flex-end"
								gap="8px"
							>
								<Button
									type="button"
									variant="outlined"
									color="primary"
									onClick={onCancel}
									disabled={isSubmitting}
									data-cy="new-task-cancel"
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									color="primary"
									disabled={isSubmitting}
									data-cy="new-task-create"
									onClick={handleSubmit}
								>
									Create {entityName}
								</Button>
							</Box>
						</DialogActions>
					</form>
				</Dialog>
			)}
		/>
	);
}

export default NewTaskFormDialog;
