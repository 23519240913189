import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Box, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import type React from "react";

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return createStyles({
		messageContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		iconContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "72px",
			height: "72px",
			margin: theme.spacing(2),
			backgroundColor: theme.palette.background.paper,
			borderRadius: "100%",
		},
		titleText: {
			margin: theme.spacing(1),
			color: theme.palette.text.secondary,
			textAlign: "center",
		},
		descriptionText: {
			color: theme.palette.text.secondary,
			textAlign: "center",
		},
	});
});

interface Props {
	Icon?: React.ReactElement;
	title: string;
	message?: React.ReactElement;
}

export default function ErrorMessage(props: Props) {
	const { Icon = <PriorityHighIcon />, title, message = null } = props;
	const classes = useStyles();

	return (
		<Box className={classes.messageContainer} data-cy="error__error_message">
			<Box className={classes.iconContainer}>{Icon}</Box>
			<Typography className={classes.titleText} variant="h3">
				{title}
			</Typography>
			<Typography className={classes.descriptionText} variant="subtitle1">
				{message}
			</Typography>
		</Box>
	);
}
