import Affinity from "@/ui/atoms/Icons/Affinity";
import Crunchbase from "@/ui/atoms/Icons/Crunchbase";
import Diffbot from "@/ui/atoms/Icons/Diffbot";
import Linkedin from "@/ui/atoms/Icons/Linkedin";
import Pitchbook from "@/ui/atoms/Icons/Pitchbook";
import Synaptic from "@/ui/atoms/Icons/Synaptic";
import X from "@/ui/atoms/Icons/X";
import { Box, ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";
/* eslint-disable react/destructuring-assignment */
import React from "react";

const ResourceButton = styled(ButtonBase)(({ theme }) => ({
	[theme.breakpoints.up("sm")]: {
		marginRight: theme.spacing(1),
	},
	marginRight: theme.spacing(0.5),
	padding: theme.spacing(1),
	borderRadius: theme.shape.borderRadius,
	color: theme.palette.primary.main,
	border: `1px solid ${theme.palette.primary.main}`,
	backgroundColor: theme.palette.background.paper,
	"&:hover": {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.blue,
		border: `1px solid ${theme.palette.primary.main}`,
	},
}));

interface Props {
	crunchbase?: string;
	pitchbook?: string;
	affinity?: string;
	linkedin?: string;
	synaptic?: string;
	diffbot?: string;
	x?: string;
}

export function ResourceLinks(props: Props) {
	const { crunchbase, x, pitchbook, linkedin, synaptic, diffbot, affinity } =
		props;
	return (
		<Box display="flex">
			{x && (
				<ResourceButton
					onClick={() =>
						window.open(`https://www.x.com/intent/user?user_id=${x}`, "_blank")
					}
				>
					<X />
				</ResourceButton>
			)}
			{pitchbook && (
				<ResourceButton
					onClick={() =>
						window.open(
							`https://my.pitchbook.com/profile/${pitchbook}`,
							"_blank",
						)
					}
				>
					<Pitchbook />
				</ResourceButton>
			)}
			{linkedin && (
				<ResourceButton
					onClick={() => window.open(`https://www.${linkedin}`, "_blank")}
				>
					<Linkedin />
				</ResourceButton>
			)}
			{synaptic && (
				<ResourceButton
					onClick={() =>
						window.open(
							`https://app.synaptic.com/companies/${synaptic}/`,
							"_blank",
						)
					}
				>
					<Synaptic />
				</ResourceButton>
			)}
			{crunchbase && (
				<ResourceButton
					onClick={() =>
						window.open(
							`https://www.crunchbase.com/organization/${crunchbase}`,
							"_blank",
						)
					}
				>
					<Crunchbase />
				</ResourceButton>
			)}
			{diffbot && (
				<ResourceButton
					onClick={() =>
						window.open(`https://app.diffbot.com/entity/${diffbot}`, "_blank")
					}
				>
					<Diffbot />
				</ResourceButton>
			)}

			{affinity && (
				<ResourceButton
					onClick={() =>
						window.open(
							`https://valor.affinity.co/companies/${affinity}`,
							"_blank",
						)
					}
				>
					<Affinity />
				</ResourceButton>
			)}
		</Box>
	);
}

export default ResourceLinks;
