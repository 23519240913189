import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
	IconButton,
	InputAdornment,
	type SxProps,
	TextField,
} from "@mui/material";
import React from "react";

type OrganizationFilterProps = {
	value: string;
	onChange: (value: string) => void;
	sx?: SxProps;
};

export default function OrganizationFilter({
	value,
	onChange,
	sx,
}: OrganizationFilterProps) {
	return (
		<TextField
			label="Filter companies"
			variant="outlined"
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				),
				endAdornment: value.length > 0 && (
					<InputAdornment position="end">
						<IconButton
							onClick={() => {
								onChange("");
							}}
						>
							<ClearIcon />
						</IconButton>
					</InputAdornment>
				),
			}}
			value={value}
			onChange={(e) => {
				onChange(e.target.value);
			}}
			sx={sx}
		/>
	);
}
