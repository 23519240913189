import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

// eslint-disable-next-line react/prop-types
export default function VOSFull({ isDarkMode = false }) {
	return (
		<SvgIcon
			data-cy="global__logo"
			sx={{ width: "200px", height: "75px" }}
			viewBox="0 0 200 75"
			width="200"
			height="75"
		>
			<path d="M24.1563 46.7551L0 11L48 11L24.1563 46.7551Z" fill="#37B9F9" />
			<path
				d="M31.2033 36.0913L15.2958 11L48 11L31.2033 36.0913Z"
				fill="#013EE1"
			/>
			<path
				d="M143.244 34.4591C142.381 40.9466 138.598 45.2617 132.097 46.4337C125.25 47.6694 118.651 47.2036 113.403 41.7704C111.257 39.5491 110.364 36.8325 110.024 33.8266C109.886 32.6203 110.349 32.4438 111.341 32.4928C112.406 32.5467 113.477 32.5173 114.542 32.5026C116.89 32.4683 118.823 32.0074 119.756 35.5723C120.979 40.2552 129.162 41.5252 132.693 38.1172C135.081 35.8125 134.721 31.5807 131.973 29.4673C130.119 28.0404 127.924 27.3637 125.749 26.6477C122.799 25.6719 119.839 24.7402 117.107 23.2054C112.51 20.6261 110.66 16.7033 111.114 11.5741C111.528 6.901 114.059 3.64012 118.246 1.81108C123.845 -0.630906 129.537 -0.665232 135.105 2.08568C139.185 4.10105 141.271 7.50414 141.991 11.8879C142.169 12.9716 141.932 13.3835 140.797 13.3541C138.746 13.3002 136.689 13.2806 134.642 13.3639C133.542 13.4081 133.113 13.055 132.777 11.9811C131.436 7.71499 127.006 5.92028 122.888 7.85229C119.223 9.56855 118.784 14.3103 122.079 16.6395C124.323 18.2234 126.982 18.8559 129.522 19.7288C131.929 20.5526 134.277 21.4646 136.536 22.6415C140.561 24.7353 142.859 27.9521 143.209 32.4928C143.219 33.145 143.234 33.8021 143.244 34.4591Z"
				fill={isDarkMode ? "#ffffff" : "#050214"}
			/>
			<path
				d="M105.175 23.5C105.175 36.4787 94.3907 47 81.0875 47C67.7843 47 57 36.4787 57 23.5C57 10.5213 67.7843 0 81.0875 0C94.3907 0 105.175 10.5213 105.175 23.5ZM65.7494 23.5C65.7494 31.7644 72.6165 38.464 81.0875 38.464C89.5585 38.464 96.4256 31.7644 96.4256 23.5C96.4256 15.2356 89.5585 8.53604 81.0875 8.53604C72.6165 8.53604 65.7494 15.2356 65.7494 23.5Z"
				fill={isDarkMode ? "#ffffff" : "#050214"}
			/>
		</SvgIcon>
	);
}
