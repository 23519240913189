import type { paths } from "@/api/index";
import axios from "axios";

// Get context by ID
type GetContext = paths["/api/contexts/{valor_id}"]["get"];
export type GetContextResponse =
	GetContext["responses"]["200"]["content"]["application/json"];

export const getContext = async (
	valorId: string,
): Promise<GetContextResponse> => {
	const result = await axios.get<GetContextResponse>(
		`/api/contexts/${valorId}`,
	);
	return result.data;
};

type GetContextNotes = paths["/api/contexts/{valor_id}/notes"]["get"];
export type GetContextNotesResponse =
	GetContextNotes["responses"]["200"]["content"]["application/json"];

export const getContextNotes = async (
	valorId: string,
): Promise<GetContextNotesResponse> => {
	const result = await axios.get<GetContextNotesResponse>(
		`/api/contexts/${valorId}/notes`,
	);
	return result.data;
};

type GetContextSurveys = paths["/api/contexts/{valor_id}/surveys"]["get"];
export type GetContextSurveysResponse =
	GetContextSurveys["responses"]["200"]["content"]["application/json"];

export const getContextSurveys = async (
	valorId: string,
): Promise<GetContextSurveysResponse> => {
	const result = await axios.get<GetContextSurveysResponse>(
		`/api/contexts/${valorId}/surveys`,
	);
	return result.data;
};

type GetGeneralContexts = paths["/api/contexts/{valor_id}/general"]["get"];
export type GetGeneralContextsResponse =
	GetGeneralContexts["responses"]["200"]["content"]["application/json"];

export const getGeneralContexts = async (
	valorId: string,
): Promise<GetGeneralContextsResponse> => {
	const result = await axios.get<GetGeneralContextsResponse>(
		`/api/contexts/${valorId}/general`,
	);
	return result.data;
};

type GetCrmContexts = paths["/api/contexts/{valor_id}/crm"]["get"];
export type GetCrmContextsResponse =
	GetCrmContexts["responses"]["200"]["content"]["application/json"];

export const getCrmContexts = async (
	valorId: string,
): Promise<GetCrmContextsResponse> => {
	const result = await axios.get<GetCrmContextsResponse>(
		`/api/contexts/${valorId}/crm`,
	);
	return result.data;
};

type PostTokenCount = paths["/api/contexts/token-count"]["post"];
export type TokenCountRequest =
	PostTokenCount["requestBody"]["content"]["application/json"];
export type TokenCountResponse =
	PostTokenCount["responses"]["200"]["content"]["application/json"];

export const countTokens = async (
	text: string,
	model: string,
): Promise<TokenCountResponse> => {
	const result = await axios.post<TokenCountResponse>(
		"/api/contexts/token-count",
		{
			text,
			model,
		},
	);
	return result.data;
};

type PostConvertFile = paths["/api/contexts/convert-file"]["post"];
export type ConvertFileResponse =
	PostConvertFile["responses"]["200"]["content"]["application/json"];

export const convertFile = async (file: File): Promise<ConvertFileResponse> => {
	const formData = new FormData();
	formData.append("file", file);

	try {
		const result = await axios.post<ConvertFileResponse>(
			"/api/contexts/convert-file",
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			},
		);
		return result.data;
	} catch (error) {
		if (axios.isAxiosError(error) && error.response) {
			return error.response.data as ConvertFileResponse;
		}
		return {
			filename: file.name,
			content: "",
			error: "Failed to convert file",
		};
	}
};

export default {
	getContext,
	getContextNotes,
	getContextSurveys,
	countTokens,
	getGeneralContexts,
	getCrmContexts,
	convertFile,
};
