import Clamps from "@/components/Clamps";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Box, Link, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

export default function Chat({
	title,
	createdAt,
	onClick,
}: {
	title: string;
	createdAt: string;
	onClick: () => void;
}) {
	return (
		<Box
			display="flex"
			flexDirection="column"
			gap={1}
			padding={2}
			border={(theme) => `solid 1px ${theme.palette.text.primary}`}
			borderRadius={1}
			sx={{
				"&:hover": {
					opacity: 0.5,
				},
				cursor: "pointer",
			}}
			onClick={onClick}
		>
			<ChatBubbleOutlineIcon fontSize="small" />
			<Clamps maxLines={2} collapsed>
				{title}
			</Clamps>
			<Typography variant="caption">{dayjs(createdAt).fromNow()}</Typography>
		</Box>
	);
}
