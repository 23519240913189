import { canSeeCompliance } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import PathBreadCrumbs from "@/ui/atoms/BreadCrumbs";
import ErrorMessage from "@/ui/atoms/ErrorMessage";
import Page from "@/ui/molecules/Page";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "@mui/material";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Compliance from "./Compliance";
import Role from "./Role";
import Individual from "./User";

function ComplianceRoot() {
	const { user } = useAuth();
	const location = useLocation();

	if (!canSeeCompliance(user)) {
		return (
			<ErrorMessage
				Icon={<LockIcon />}
				title="Unauthorized"
				message={
					<>
						You don’t have access to view Compliance page. If you think this is
						an error contact{" "}
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<Link
							onClick={(e) => {
								window.location.href =
									"mailto:labs@valorep.com?subject=Compliance Page Permissions";
								e.preventDefault();
							}}
						>
							labs@valorep.com
						</Link>
					</>
				}
			/>
		);
	}

	return (
		<Page data-cy="page__compliance">
			<Page.Title>Compliance</Page.Title>
			<Page.Content>
				<PathBreadCrumbs path={location.pathname} />
				<Routes>
					<Route path="*" element={<Compliance />} />
					<Route path="role" element={<Role />} />
					<Route path="user" element={<Individual />} />
					<Route path="individuals" element={<Compliance />} />
				</Routes>
			</Page.Content>
		</Page>
	);
}

export default ComplianceRoot;
