import { Box, MenuItem, MenuList, Radio } from "@mui/material";
import styled from "@mui/material/styles/styled";
import type React from "react";

type FilterProps = {
	getFilterValue: () => { label: string; value: boolean };
	setFilterValue: (value: { label: string; value: boolean }) => void;
};
type BooleanFilterProps = {
	onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
};

const DenseMenuList = styled(MenuList)(() => ({
	padding: 0,
	maxHeight: "300px",
	overflow: "auto",
}));

const DenseMenuItem = styled(MenuItem)(() => ({
	padding: "4px",
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: 1,
	fontSize: "14px",
	maxHeight: "30px",
	cursor: "pointer",
	minWidth: 144,
}));

export default function BooleanFilter({
	getFilterValue,
	setFilterValue,
	onChange = null,
}: FilterProps & BooleanFilterProps) {
	// get options from unique column values
	const filterValue = getFilterValue();
	const Control = Radio;
	const options = [
		{ label: "True", value: true },
		{ label: "False", value: false },
	];
	const isChecked = (optionValue) => filterValue === optionValue;

	return (
		<Box>
			<DenseMenuList>
				{options.map(({ label, value }) => (
					<DenseMenuItem
						key={label}
						value={value}
						onClick={(event) => {
							if (onChange) {
								onChange({
									...event,
									target: {
										...event.target,
										value,
									},
								});
							} else if (filterValue === value) {
								setFilterValue(undefined);
							} else {
								setFilterValue(value);
							}
						}}
					>
						<Control size="small" checked={isChecked(value)} />
						{label}
					</DenseMenuItem>
				))}
			</DenseMenuList>
		</Box>
	);
}
