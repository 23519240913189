import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import OverflowTip from "@/ui/atoms/OverflowTip";
import SearchIcon from "@mui/icons-material/Search";
import {
	Box,
	ButtonBase,
	FormControl,
	Input,
	InputAdornment,
	Typography,
	useTheme,
} from "@mui/material";
/* eslint-disable max-len */
import React, { useReducer } from "react";

const initialState = {
	query: "",
	tab: "all",
	tabEffect: (x) => x,
	queryEffect: (x) => x,
};

const reducer = (state, action) => {
	switch (action.type) {
		case "setTab":
			return {
				...state,
				tab: action.tab,
				tabEffect: action.tabEffect,
			};
		case "setQuery":
			return {
				...state,
				query: action.query,
				queryEffect: action.queryEffect,
			};
		default:
			throw new Error();
	}
};

export function MapClassifierFooter(props) {
	const {
		companies,
		currentMappingId,
		setCurrentMappingId,
		companyMemberships,
	} = props;
	const theme = useTheme();

	const filterTabs = {
		all: (x) => x,
		categorized: (cos) =>
			cos?.filter((co) => (companyMemberships[co.valorId]?.length ?? 0) > 0),
		uncategorized: (cos) =>
			cos?.filter((co) => (companyMemberships[co.valorId]?.length ?? 0) === 0),
	};
	const [state, dispatch] = useReducer(reducer, initialState);

	const currentTabStyle = {
		color: theme.palette.action.active,
		borderBottom: `2px solid ${theme.palette.action.active}`,
	};

	const onSearch = (e) => {
		const term = e.target.value;
		setCurrentMappingId(null);
		// TODO check this UX makes sense ^
		if (term) {
			dispatch({
				type: "setQuery",
				query: e.target.value,
				queryEffect: (cos) =>
					cos?.filter((co) =>
						co.name.toLowerCase().includes(term.toLowerCase()),
					),
			});
		} else {
			dispatch({
				type: "setQuery",
				query: e.target.value,
				queryEffect: (cos) => cos,
			});
		}
	};

	const onTabClick = (t) => {
		setCurrentMappingId(null);
		// TODO check this UX makes sense ^

		dispatch({
			type: "setTab",
			tab: t,
			tabEffect: filterTabs[t],
		});
	};
	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					gap: theme.spacing(4),
					height: theme.spacing(7),
					borderBottom: `1px solid ${theme.palette.divider}`,
					color: theme.palette.text.secondary,
					backgroundColor: theme.palette.background.paper,
					boxShadow:
						"0px -24px 48px rgba(90, 91, 106, 0.08), 0px -12px 24px rgba(58, 58, 68, 0.08)",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "flex-end",
						justifyContent: "center",
						gap: theme.spacing(1),
					}}
				>
					{Object.keys(filterTabs).map((t) => (
						<ButtonBase
							key={t}
							sx={{
								height: theme.spacing(4),
								padding: theme.spacing(2, 0),
								color: theme.palette.text.secondary,
								borderBottom: "2px solid transparent",
							}}
							style={t === state.tab ? currentTabStyle : {}}
							onClick={() => onTabClick(t)}
						>
							<Typography
								component="p"
								sx={{
									fontWeight: theme.typography.fontWeightBold,
									fontSize: "14px",
									letterSpacing: "1.25px",
								}}
							>
								{t.toUpperCase()}
								{t === "all" && (
									<>
										{" ("}
										{companies?.length})
									</>
								)}
							</Typography>
						</ButtonBase>
					))}
				</Box>
				<Box display="flex" alignItems="flex-end">
					<FormControl>
						<Input
							value={state.query}
							onChange={onSearch}
							placeholder="Search for a company"
							startAdornment={
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							}
						/>
					</FormControl>
				</Box>
			</Box>
			<Box
				sx={{
					display: "inline-flex",
					flexDirection: "row",
					gap: theme.spacing(1),
					alignItems: "center",
					padding: "0px 8px",
					width: "100%",
					overflowX: "scroll",
					height: theme.spacing(12),
					backgroundColor: theme.palette.background.paper,
					color: theme.palette.text.secondary,
					"-ms-overflow-style": "auto",
					scrollbarWidth: "thin",
					"&::-webkit-scrollbar": {
						display: "block",
						height: "8px",
						backgroundColor: theme.palette.gray.light,
					},
					"&::-webkit-scrollbar-thumb": {
						display: "block",
						height: "4px",
						width: "32px",
						backgroundColor: theme.palette.primary.main,
					},
				}}
			>
				{companies?.length > 0 &&
					state.queryEffect(state.tabEffect(companies)).map((co) => (
						<Box
							style={
								co.valorId === currentMappingId
									? { backgroundColor: theme.palette.primary.highlight }
									: { backgroundColor: "transparent" }
							}
							sx={{
								cursor: "pointer",
								display: "flex",
								gap: theme.spacing(1),
								justifyContent: "space-between",
								alignItems: "center",
								height: theme.spacing(8),
								width: theme.spacing(20),
								minWidth: theme.spacing(20),
								padding: theme.spacing(1),
								borderRadius: 1,
								border: `1px solid ${theme.divider}`,
							}}
							key={co.valorId}
							onClick={() => {
								if (co.valorId === currentMappingId) {
									setCurrentMappingId(null);
								} else {
									setCurrentMappingId(co.valorId);
								}
							}}
						>
							<Box style={{ flexGrow: 1 }}>
								<CompanyAvatar
									size={48}
									src={co.logoUrl}
									name={co.name}
									domain={co.domain}
								/>
							</Box>

							<Box style={{ flexGrow: 1 }}>
								<OverflowTip>{co.name}</OverflowTip>
								{companyMemberships[co.valorId]?.length ?? 0} Placed
							</Box>
						</Box>
					))}
			</Box>
		</Box>
	);
}

export default MapClassifierFooter;
