import { Box, ClickAwayListener } from "@mui/material";
import React, { useState } from "react";
import OverflowBox from "./OverflowBox";

export default function TextCell({ value }: { value: string }) {
	const [showOverflow, setShowOverflow] = useState(false);
	if (value === undefined || value === null) return <>-</>;

	return (
		<ClickAwayListener onClickAway={() => setShowOverflow(false)}>
			<Box position="relative" display="flex" height="100%" alignItems="center">
				<Box
					sx={{
						overflow: "hidden",
						whiteSpace: "nowrap",
						cursor: "pointer",
					}}
					onClick={() => setShowOverflow(true)}
				>
					{value}
				</Box>
				<OverflowBox open={showOverflow}>{value}</OverflowBox>
			</Box>
		</ClickAwayListener>
	);
}
