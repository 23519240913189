import type { MetricsLabel } from "@/api/Metrics";
import { TextField, Typography } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { styled } from "@mui/system";
import type React from "react";

const filter = createFilterOptions();

const GroupHeader = styled("div")(({ theme }) => ({
	position: "sticky",
	top: "-8px",
	padding: "4px 10px",
	color: theme.palette.primary.main,
	backgroundColor: theme.palette.background.blue,
}));

const GroupItems = styled("ul")({ padding: 0 });

interface MetricsAutocompleteProps {
	label?: string;
	value: MetricsLabel | MetricsLabel[];
	options: MetricsLabel[];
	disabled?: boolean;
	loading?: boolean;
	multiple: boolean;
	onChange: (
		event: React.ChangeEvent<unknown>,
		value: MetricsLabel | MetricsLabel[],
	) => void;
}

export default function MetricsAutocomplete({
	label = "Add Company Metric",
	options,
	value,
	disabled = false,
	loading = false,
	multiple,
	onChange,
}: MetricsAutocompleteProps) {
	return (
		<Autocomplete
			options={options}
			value={value}
			disabled={disabled}
			loading={loading}
			multiple={multiple}
			clearOnBlur
			handleHomeEndKeys
			openOnFocus
			disableClearable
			sx={{ minWidth: "240px", maxWidth: "320px" }}
			isOptionEqualToValue={(option, optionValue) =>
				option.id === optionValue.id
			}
			groupBy={(option) => option.familyId}
			getOptionLabel={(option) => option.name}
			renderInput={(params) => <TextField {...params} label={label} />}
			renderGroup={(params) => {
				const category = options.find(
					(opt) => opt.familyId === params.group,
				) || { name: "No Category" };
				return (
					<li key={params.key}>
						<GroupHeader>{category.family}</GroupHeader>
						<GroupItems>{params.children}</GroupItems>
					</li>
				);
			}}
			renderOption={(props, option) => (
				<li {...props} key={option.id}>
					<Typography variant="body1">{option.name}</Typography>
				</li>
			)}
			renderTags={() => null}
			disableCloseOnSelect
			onChange={onChange}
			freeSolo
			filterSelectedOptions
			filterOptions={(opts, params) => {
				const filtered = filter(opts, params);
				const { inputValue } = params;
				const isExisting = opts.some((option) => inputValue === option.name);
				if (inputValue !== "" && !isExisting) {
					filtered.push({
						inputValue,
						name: `Add "${inputValue}"`,
					});
				}
				return filtered;
			}}
		/>
	);
}
