import { createOrgOnPipeline } from "@/api/Affinity";
import { getInvestorUsers } from "@/api/Users";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import UserAvatar from "@/ui/atoms/UserAvatar";
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	InputLabel,
	Link,
	MenuItem,
	Select,
	Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Field, Form } from "react-final-form";
import Helmet from "react-helmet";
import { useQuery } from "react-query";

export function AffinityModal({ name, domain, valorId, open, onClose }) {
	const { data: investors = [], isLoading } = useQuery(
		["Investors"],
		async () => getInvestorUsers(),
	);
	const [isBusy, setIsBusy] = useState(false);
	const [response, setResponse] = useState(null);
	const deptOptions = [
		{ label: "Growth", value: "growth" },
		{ label: "VSV", value: "vsv" },
	];

	useEffect(() => {
		setResponse(null);
	}, [name, domain, valorId]);

	const handleOnClick = async ({
		department,
		monitoringServiceLead,
		owners,
	}) => {
		setIsBusy(true);
		const affResponse = await createOrgOnPipeline({
			valorId,
			department,
			monitoringServiceLead,
			owners,
		});

		if (affResponse.status !== 200) {
			setResponse({
				text: affResponse?.data?.detail?.text ?? "Unknown Error",
				affId: affResponse?.data?.detail?.id ?? null,
			});
		} else {
			setResponse({ text: affResponse.data.text, affId: affResponse.data.id });
		}
		setIsBusy(false);
	};
	if (isLoading) {
		return <CenteredProgress />;
	}

	let body = null;

	if (isLoading) {
		body = <CenteredProgress />;
	}
	if (!response) {
		body = (
			<Form
				onSubmit={handleOnClick}
				render={({ handleSubmit, submitting, pristine }) => (
					<form onSubmit={handleSubmit}>
						<Box display="flex" flexDirection="column">
							<Field
								name="department"
								validate={(val) => {
									if (val) {
										return undefined;
									}
									return "Please select a list";
								}}
							>
								{({ input }) => (
									<FormControl variant="filled" sx={{ mb: 2 }}>
										<InputLabel>Select Pipeline</InputLabel>
										<Select
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...input}
											label="Department"
											placeholder="Select Department"
										>
											{deptOptions.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
							</Field>
							<Field
								name="monitoringServiceLead"
								validate={(val) => {
									if (val) {
										return undefined;
									}
									return "Please select a monitoring service lead";
								}}
							>
								{({ input }) => (
									<FormControl variant="filled" sx={{ mb: 2 }}>
										<InputLabel>Select Monitoring Service Lead</InputLabel>
										<Select
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...input}
											label="Monitoring Service Lead"
											placeholder="Select Department"
											renderValue={(selected) => (
												<Box display="flex">
													{investors
														.filter((x) => selected.indexOf(x.id) !== -1)
														.map(({ name: userName, profilePicture, id }) => (
															<Box
																display="flex"
																key={id}
																alignItems="center"
																paddingRight={0.5}
															>
																<UserAvatar
																	user={{ name: userName, profilePicture }}
																/>
																{userName}
															</Box>
														))}
												</Box>
											)}
										>
											{investors?.map(
												({ name: userName, id, profilePicture }) => (
													<MenuItem key={id} value={id}>
														<UserAvatar
															user={{ name: userName, profilePicture }}
														/>
														{userName}
													</MenuItem>
												),
											)}
										</Select>
									</FormControl>
								)}
							</Field>
							{/* TODO make Owners not optional? */}
							<Field
								name="owners"
								validate={(val) => {
									if (val) {
										return undefined;
									}
									return "Please select at least one owner";
								}}
							>
								{({ input }) => (
									<FormControl variant="filled" sx={{ mb: 2 }}>
										<InputLabel>Select Owner(s)</InputLabel>
										<Select
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...input}
											value={[...input.value]}
											label="Owners"
											placeholder="Owners"
											multiple
											renderValue={(selected) => (
												<Box display="flex">
													{investors
														.filter((x) => selected.indexOf(x.id) !== -1)
														.map(({ name: userName, profilePicture, id }) => (
															<Box
																display="flex"
																key={id}
																alignItems="center"
																paddingRight={0.5}
															>
																<UserAvatar
																	user={{ name: userName, profilePicture }}
																/>
																{userName}
															</Box>
														))}
												</Box>
											)}
										>
											{investors?.map(
												({ name: userName, id, profilePicture }) => (
													<MenuItem key={id} value={id}>
														<UserAvatar
															user={{ name: userName, profilePicture }}
														/>
														{userName}
													</MenuItem>
												),
											)}
										</Select>
										<FormHelperText>
											This includes any Associate tracking the company
										</FormHelperText>
									</FormControl>
								)}
							</Field>
							<Button
								color="primary"
								variant="contained"
								type="submit"
								disabled={submitting || pristine || isBusy}
							>
								Submit
							</Button>
						</Box>
					</form>
				)}
			/>
		);
	}
	if (response) {
		body = (
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="space-around"
				alignItems="center"
				height="100%"
			>
				<Typography variant="h3">{response.text}</Typography>
				{response?.affId && (
					<Link href={`https://valor.affinity.co/companies/${response?.affId}`}>
						See on Org Affinity
					</Link>
				)}

				{response?.list && (
					<Link href={`https://valor.affinity.co/lists/${response?.list}`}>
						See List
					</Link>
				)}
			</Box>
		);
	}

	return (
		<Dialog open={!!open} fullWidth maxWidth="md" onClose={onClose}>
			<Helmet>
				<title>{`${name} - Affinity`}</title>
			</Helmet>
			<DialogTitle>Add Organization to Pipeline</DialogTitle>
			<DialogContent>
				<Box>
					<Typography variant="h3">{name}</Typography>
					<Box display="flex" flexDirection="column">
						<Typography variant="body2">{domain}</Typography>
					</Box>
				</Box>
				{body}
			</DialogContent>
		</Dialog>
	);
}

export default AffinityModal;
