import { FormControl } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function MultiSelect({
	id,
	allValues,
	colorMap,
	dontDisplayAll,
	value,
	onChange,
	width,
	maxWidth,
	title,
	allText,
	disabled,
	indeterminate,
	defaultValue,
	getKey,
	style,
	getLabel,
}) {
	const [allCheck, setAllCheck] = useState(true);

	useEffect(() => {
		setAllCheck(!dontDisplayAll && value.length === allValues.length);
	}, [allValues, value, dontDisplayAll]);

	const onClickAll = () => {
		let selected = []; // all check case
		if (!allCheck) {
			selected = allValues;
		}
		setAllCheck(!allCheck);
		onChange(selected);
	};

	const thisOnChange = (event, e) => {
		if (e.props.value === "ALL") return;

		setAllCheck(event.target.value.length === allValues.length);
		onChange(event.target.value);
	};

	return (
		<FormControl style={{ width, maxWidth, ...style }}>
			{title && (
				<InputLabel id={`${id}-mutiple-checkbox-label`} shrink sx={{ mb: -1 }}>
					{title}
				</InputLabel>
			)}
			<Select
				labelId={`${id}-mutiple-checkbox-label`}
				id={`${id}-mutiple-checkbox`}
				multiple
				value={value}
				defaultValue={defaultValue}
				onChange={thisOnChange}
				input={<Input />}
				renderValue={(selected) =>
					allCheck ? allText : selected.map((o) => getLabel(o)).join(", ")
				}
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left",
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left",
					},
					getContentAnchorEl: null,
					autoFocus: false,
					PaperProps: {
						style: {
							maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
							width: "fit-content",
						},
					},
				}}
				disabled={disabled}
			>
				{!dontDisplayAll && (
					<MenuItem key="ALL" value="ALL" onClick={onClickAll}>
						<Checkbox
							indeterminate={indeterminate}
							checked={allCheck}
							color="primary"
							style={{
								color: indeterminate ? "#E60000" : "primary",
							}}
						/>
						<ListItemText primary={allText} />
					</MenuItem>
				)}
				{allValues.map((option) => (
					<MenuItem key={getKey(option)} value={option}>
						<Checkbox
							indeterminate={indeterminate}
							checked={value.some((v) => getKey(v) === getKey(option))}
							color="primary"
							style={{
								color: indeterminate ? "#E60000" : colorMap[getKey(option)],
							}}
						/>
						<ListItemText primary={getLabel(option)} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

MultiSelect.propTypes = {
	id: PropTypes.string.isRequired,
	allValues: PropTypes.array.isRequired,
	colorMap: PropTypes.object,
	dontDisplayAll: PropTypes.bool,
	value: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	width: PropTypes.number,
	maxWidth: PropTypes.number,
	title: PropTypes.string,
	allText: PropTypes.string,
	disabled: PropTypes.bool,
	getKey: PropTypes.func,
	getLabel: PropTypes.func,
	style: PropTypes.object,
	indeterminate: PropTypes.bool,
	defaultValue: PropTypes.array,
};

MultiSelect.defaultProps = {
	defaultValue: [],
	colorMap: {},
	dontDisplayAll: false,
	width: 150,
	maxWidth: 200,
	getKey: (x) => x,
	getLabel: (x) => x,
	allText: "ALL",
	disabled: false,
	style: {},
	indeterminate: false,
};
