import { getFollowList, snoozeUpdates, updateFollowing } from "@/api/Follow";
import { useAuth } from "@/hooks/useAuth";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import FollowButton from "@/ui/atoms/FollowButton";
import SnoozeMenu from "@/ui/atoms/SnoozeMenu";
import Page from "@/ui/molecules/Page";
import { Box, Link, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

export function Watchlist() {
	const {
		user: { id: userId },
	} = useAuth();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const { data: watchlistCompanies, isLoading } = useQuery(
		["Watchlist", userId],
		async () => {
			try {
				const response = await getFollowList(userId);
				return response;
			} catch (e) {
				throw new Error(e);
			}
		},
		{
			retry: 1,
			retryOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchInterval: false,
		},
	);

	const handleUnfollow = (valorId, isFollowing) => {
		queryClient.setQueryData(["Watchlist", userId], (followData) => {
			const copy = [...followData];
			const found = followData.findIndex((el) => el.valorId === valorId);
			copy[found] = { ...followData[found], isFollowing: !isFollowing };
			return copy;
		});
		return updateFollowing(userId, valorId, isFollowing);
	};

	const handleSnooze = (valorId, newSnoozeDate) => {
		queryClient.setQueryData(["Watchlist", userId], (followData) => {
			const copy = [...followData];
			const found = followData.findIndex((el) => el.valorId === valorId);
			// when newSnooze is null, it is unsnoozing
			copy[found] = { ...followData[found], snoozedUntil: newSnoozeDate };
			return copy;
		});
		return snoozeUpdates(newSnoozeDate, userId, valorId);
	};

	const renderFollow = ({
		valorId,
		name,
		domain,
		logoUrl,
		isFollowing,
		snoozedUntil,
	}) => (
		<Box
			key={valorId}
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				height: (theme) => theme.spacing(8),
				padding: (theme) => theme.spacing(1, 2),
			}}
			data-cy={`followed-companies__company-${valorId}`}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				<Box
					sx={{
						"&:hover": {
							cursor: "pointer",
						},
					}}
					onClick={() => navigate(`/org/${valorId}`)}
				>
					<CompanyAvatar src={logoUrl} name={name} domain={domain} size="40" />
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						marginLeft: 4,
					}}
				>
					<Box
						sx={{
							"&:hover": {
								cursor: "pointer",
							},
						}}
						onClick={() => navigate(`/org/${valorId}`)}
					>
						<Typography
							variant="body2"
							sx={{
								color: (theme) => theme.palette.text.primary,
								fontWeight: (theme) => theme.typography.fontWeightBold,
							}}
						>
							{name}
						</Typography>
					</Box>
					<Link
						sx={{
							color: (theme) => theme.palette.text.secondary,
							fontSize: (theme) => theme.typography.caption.fontSize,
						}}
						href={`https://www.${domain}`}
						target="_blank"
					>
						{domain}
					</Link>
				</Box>
			</Box>
			<div style={{ display: "flex" }}>
				{snoozedUntil && dayjs(snoozedUntil).isAfter(dayjs()) && (
					<SnoozeMenu
						snoozedUntil={snoozedUntil}
						onSnooze={(newSnoozeDate) => handleSnooze(valorId, newSnoozeDate)}
					/>
				)}
				<FollowButton
					isXs={isSmDown}
					isFollowing={isFollowing}
					onClick={() => handleUnfollow(valorId, isFollowing)}
				/>
			</div>
		</Box>
	);
	return (
		<Page>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<CenteredProgress isLoading={isLoading} displayChildren>
					<Page.Title data-cy="followed_companies__title">
						Company Watchlist
					</Page.Title>
					<Page.Content>
						{Boolean(watchlistCompanies?.length) || isLoading ? (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									backgroundColor: (theme) => theme.palette.background.paper,
									border: (theme) => `1px solid ${theme.palette.divider}`,
									borderRadius: 1,
									"& > div:not(:last-child)": {
										borderBottom: (theme) =>
											`1px solid ${theme.palette.divider}`,
									},
								}}
							>
								{watchlistCompanies?.map(renderFollow)}
							</Box>
						) : (
							<Typography>
								No Companies on your watchlist add some to receive updates.
							</Typography>
						)}
					</Page.Content>
				</CenteredProgress>
			</Box>
		</Page>
	);
}

export default Watchlist;
