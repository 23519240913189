import {
	getKey as defaultGetKey,
	getLabel as defaultGetLabel,
} from "@/utils/general";
import {
	FormControl,
	InputLabel,
	ListSubheader,
	MenuItem,
	Select,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

export default function SingleSelect({
	id,
	title,
	options,
	onChange,
	value,
	showNone,
	variant,
	className,
	getKey,
	getLabel,
	width,
	defaultValue,
	style,
	noneLabel,
	noneValue,
	disabled,
}) {
	return (
		<FormControl
			style={{ width, marginRight: "8px", ...style }}
			variant={variant}
			margin="dense"
			className={className}
			disabled={disabled}
		>
			{title && <InputLabel id={`${id}-select-label`}>{title}</InputLabel>}
			<Select
				labelId={`${id}-select-label`}
				id={`${id}-select`}
				value={value}
				defaultValue={defaultValue}
				label={title}
			>
				{showNone && (
					<MenuItem
						key={noneValue}
						value={noneValue}
						style={{ minHeight: "30px" }}
						onClick={() => onChange(noneValue)}
					>
						{noneLabel}
					</MenuItem>
				)}
				{Array.isArray(options)
					? options.map((option) => {
							const key = getKey(option);
							if (key === "subheader") {
								return (
									<ListSubheader key={option}>{getLabel(option)}</ListSubheader>
								);
							}
							return (
								<MenuItem
									style={{ minHeight: "30px" }}
									onClick={() => onChange(key, option)}
									key={key}
									value={key}
								>
									{getLabel(option)}
								</MenuItem>
							);
						}) // array case
					: Object.keys(options).map((key) => (
							<MenuItem key={key} value={key} onClick={() => onChange(key)}>
								{options[key]}
							</MenuItem>
						))}
			</Select>
		</FormControl>
	);
}

SingleSelect.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string,
	options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	showNone: PropTypes.bool,
	variant: PropTypes.string,
	className: PropTypes.string,
	getKey: PropTypes.func,
	getLabel: PropTypes.func,
	width: PropTypes.number,
	style: PropTypes.object,
	noneLabel: PropTypes.string,
	noneValue: PropTypes.string,
	disabled: PropTypes.bool,
};

SingleSelect.defaultProps = {
	title: null,
	showNone: false,
	variant: "outlined",
	getKey: defaultGetKey,
	getLabel: defaultGetLabel,
	width: 150,
	style: {},
	noneLabel: "All",
	noneValue: "",
	disabled: false,
};
