import { createPrompt, listPromptFamilies } from "@/api/Chat";
import {
	Alert,
	Box,
	Button,
	Container,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Snackbar,
	TextField,
	Typography,
} from "@mui/material";
import type React from "react";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

interface PromptForm {
	content: string;
	promptMetadata: string;
	promptFamilyId: string;
}

function PromptEditor() {
	const queryClient = useQueryClient();
	const [formData, setFormData] = useState<PromptForm>({
		content: "",
		promptMetadata: "{}",
		promptFamilyId: "", // Initialize with empty string
	});
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [metadataError, setMetadataError] = useState<string>("");

	// Query for prompt families
	const { data: promptFamilies, isLoading: isLoadingFamilies } = useQuery(
		"promptFamilies",
		listPromptFamilies,
	);

	const createPromptMutation = useMutation(createPrompt, {
		onSuccess: () => {
			queryClient.invalidateQueries("prompts");
			setOpenSnackbar(true);
			// Reset form
			setFormData({
				content: "",
				promptMetadata: "{}",
				promptFamilyId: "",
			});
			setMetadataError("");
		},
	});

	const validateAndProcessMetadata = (metadataString: string) => {
		try {
			const trimmedMetadata = metadataString.trim();

			// Return null if empty object
			if (trimmedMetadata === "{}" || trimmedMetadata === "") {
				return null;
			}

			const parsedMetadata = JSON.parse(trimmedMetadata);
			return parsedMetadata;
		} catch (error) {
			throw new Error("Invalid JSON format for metadata");
		}
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		setMetadataError("");

		try {
			const processedMetadata = validateAndProcessMetadata(
				formData.promptMetadata,
			);
			const submissionData = {
				...formData,
				promptMetadata: processedMetadata,
			};
			createPromptMutation.mutate(submissionData);
		} catch (error) {
			setMetadataError((error as Error).message);
		}
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));

		if (name === "promptMetadata") {
			setMetadataError("");
		}
	};

	const handleFamilyChange = (event: any) => {
		setFormData((prev) => ({
			...prev,
			promptFamilyId: event.target.value,
		}));
	};

	return (
		<Container maxWidth="lg">
			<Paper elevation={3} sx={{ p: 4, mt: 4 }}>
				<Typography variant="h5" component="h1" gutterBottom>
					Create New Prompt
				</Typography>

				<Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
					<TextField
						fullWidth
						label="Content"
						name="content"
						value={formData.content}
						onChange={handleInputChange}
						margin="normal"
						required
						multiline
						minRows={16}
					/>

					<FormControl fullWidth margin="normal" required>
						<InputLabel>Prompt Family</InputLabel>
						<Select
							value={formData.promptFamilyId}
							label="Prompt Family"
							onChange={handleFamilyChange}
							disabled={isLoadingFamilies}
						>
							{promptFamilies?.map((family) => (
								<MenuItem key={family.id} value={family.id}>
									{family.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<TextField
						fullWidth
						label="Metadata"
						name="promptMetadata"
						value={formData.promptMetadata}
						onChange={handleInputChange}
						margin="normal"
						multiline
						InputLabelProps={{ shrink: true }}
						rows={4}
						required
						error={!!metadataError}
						helperText={
							metadataError ||
							"Enter JSON metadata as a string (empty {} will be sent as null)"
						}
					/>

					<Button
						type="submit"
						variant="contained"
						fullWidth
						sx={{ mt: 3 }}
						disabled={createPromptMutation.isLoading}
					>
						{createPromptMutation.isLoading ? "Creating..." : "Create Prompt"}
					</Button>

					{createPromptMutation.isError && (
						<Alert severity="error" sx={{ mt: 2 }}>
							Error creating prompt:{" "}
							{(createPromptMutation.error as Error).message}
						</Alert>
					)}
				</Box>
			</Paper>

			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert severity="success" sx={{ width: "100%" }}>
					Prompt created successfully!
				</Alert>
			</Snackbar>
		</Container>
	);
}

export default PromptEditor;
