import { getProcessTasks } from "@/api/Process";
import { getHomepageCalendar } from "@/api/Reporting";
import { Box, Card, Skeleton, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

const NAME_FIELD_ID = "a2607337-2592-44a2-b5da-14b4b13d216c";
const START_FIELD_ID = "558f9b18-5cc1-4c21-a4a6-8fc6bbe5fa1e";

export function CalendarCard() {
	const { data: events = [], isLoading } = useQuery("Events", () =>
		getHomepageCalendar(3, true),
	);

	return (
		<Card>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					color: (theme) => theme.palette.text.secondary,
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						py: 1,
						px: 2,
						borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
					}}
				>
					<Typography
						sx={{
							color: (theme) => theme.palette.text.secondary,
							fontWeight: (theme) => theme.typography.fontWeightSemiBold,
							fontSize: "12px",
						}}
					>
						UPCOMING EVENTS
					</Typography>
				</Box>
				<Box
					sx={{
						"& > div:not(:last-child)": {
							borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						},
					}}
				>
					{isLoading && (
						<Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
							<Skeleton variant="rectangular" width="100%" height={60} />
							<Skeleton variant="rectangular" width="100%" height={60} />
							<Skeleton variant="rectangular" width="100%" height={60} />
							<Skeleton variant="rectangular" width="100%" height={60} />
						</Box>
					)}
					{events.length === 0 && !isLoading && (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "80px",
							}}
						>
							<Typography
								sx={{
									color: (theme) => theme.palette["13"],
									textAlign: "center",
								}}
							>
								No Upcoming Events
							</Typography>
						</Box>
					)}
					{events?.map((event) => (
						<Box
							key={event.id}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
								width: "100%",
								padding: (theme) => theme.spacing(1, 1.5),
							}}
						>
							<Box display="flex" flexDirection="column" width="100%">
								<Typography
									sx={{
										color: (theme) => theme.palette.text.secondary,
										whiteSpace: "prewrap",
									}}
									variant="subtitle1"
								>
									{`${event?.fieldValues?.[NAME_FIELD_ID]?.value}`}
								</Typography>
								<Typography variant="caption">
									{event?.fieldValues?.[START_FIELD_ID]?.dateTimeValue
										? dayjs(
												event?.fieldValues?.[START_FIELD_ID]?.dateTimeValue,
											).format("ddd, MMM Do hh:mm A z")
										: dayjs(
												event?.fieldValues?.[START_FIELD_ID]?.dateValue,
											).format("ddd, MMM Do")}
								</Typography>
							</Box>
						</Box>
					))}
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						padding: (theme) => theme.spacing(1, 2),
						borderTop: (theme) => `1px solid ${theme.palette.divider}`,
						color: (theme) => theme.palette.primary.main,
						fontWeight: (theme) => theme.typography.fontWeightBold,
						fontSize: "16px",
						textDecoration: "none !important",
						"&:hover": {
							backgroundColor: (theme) => theme.palette.action.hover,
						},
					}}
				>
					<Link to="/home/calendar">SEE MORE</Link>
				</Box>
			</Box>
		</Card>
	);
}

export default CalendarCard;
