import Affinity from "@/ui/atoms/Icons/Affinity";
import Crunchbase from "@/ui/atoms/Icons/Crunchbase";
import Diffbot from "@/ui/atoms/Icons/Diffbot";
import Linkedin from "@/ui/atoms/Icons/Linkedin";
import Pitchbook from "@/ui/atoms/Icons/Pitchbook";
import Synaptic from "@/ui/atoms/Icons/Synaptic";
import ButtonBase from "@material-ui/core/ButtonBase";
import { type Theme, createStyles, makeStyles } from "@material-ui/core/styles";
/* eslint-disable react/destructuring-assignment */
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			[theme.breakpoints.up("sm")]: {
				marginRight: theme.spacing(2),
			},
			marginRight: theme.spacing(0.5),
			padding: theme.spacing(1, 1.5),
			color: theme.palette.text.secondary,
			border: `1px solid ${theme.palette.divider}`,
			borderRadius: theme.shape.borderRadius,
			"&:hover": {
				color: theme.palette.primary.main,
				border: `1px solid ${theme.palette.primary.main}`,
			},
		},
	}),
);

interface Props {
	crunchbase?: string;
	pitchbook?: string;
	affinity?: string;
	linkedin?: string;
	synaptic?: string;
	diffbot?: string;
}

export default function ResourceLinks(props: Props) {
	const { crunchbase, affinity, pitchbook, linkedin, synaptic, diffbot } =
		props;
	const classes = useStyles();
	return (
		<>
			{crunchbase && (
				<ButtonBase
					className={classes.button}
					onClick={() =>
						window.open(
							`https://www.crunchbase.com/organization/${crunchbase}`,
							"_blank",
						)
					}
				>
					<Crunchbase />
				</ButtonBase>
			)}
			{pitchbook && (
				<ButtonBase
					className={classes.button}
					onClick={() =>
						window.open(
							`https://my.pitchbook.com/profile/${pitchbook}`,
							"_blank",
						)
					}
				>
					<Pitchbook />
				</ButtonBase>
			)}
			{affinity && (
				<ButtonBase
					className={classes.button}
					onClick={() =>
						window.open(
							`https://valor.affinity.co/companies/${affinity}`,
							"_blank",
						)
					}
				>
					<Affinity />
				</ButtonBase>
			)}
			{linkedin && (
				<ButtonBase
					className={classes.button}
					onClick={() => window.open(`https://www.${linkedin}`, "_blank")}
				>
					<Linkedin />
				</ButtonBase>
			)}
			{synaptic && (
				<ButtonBase
					className={classes.button}
					onClick={() =>
						window.open(
							`https://app.synaptic.com/companies/${synaptic}/`,
							"_blank",
						)
					}
				>
					<Synaptic />
				</ButtonBase>
			)}

			{diffbot && (
				<ButtonBase
					className={classes.button}
					onClick={() =>
						window.open(`https://app.diffbot.com/entity/${diffbot}`, "_blank")
					}
				>
					<Diffbot />
				</ButtonBase>
			)}
			{affinity && (
				<ButtonBase
					className={classes.button}
					onClick={() =>
						window.open(
							`https://valor.affinity.co/companies/${affinity}`,
							"_blank",
						)
					}
				>
					<Affinity />
				</ButtonBase>
			)}
		</>
	);
}
