import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import MapCell from "./MapCell";
import { MapStep } from "./MapStepEnum";
import buildCoordinate from "./buildCoordinate";

const AddDimensionButton = styled(Button)(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	color: theme.palette.text.secondary,
	paddingTop: 0,
	paddingBottom: 0,
}));

const GridContent = styled(Box)(() => ({
	height: "100%",
	width: "100%",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const DimHeader = styled(Box)(({ theme, activeStep }) => ({
	position: "relative",
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	whiteSpace: "nowrap",
	color: theme.palette.text.secondary,
	maxWidth: "320px",
	"& > input": {
		fontWeight: "400",
		whiteSpace: "nowrap",
		fontSize: "100%",
		minWidth: "128px",
		width: "100%",
		color: theme.palette.text.secondary,
		"&:not(:focus)": {
			backgroundColor: "transparent",
			border: "none",
		},
		"&:hover": {
			borderBottom:
				activeStep === MapStep.Plan
					? `1px solid ${theme.palette.divider}`
					: null,
		},
	},
}));

const defaultState = {
	columnTitle: null,
	columns: [],
	rowTitle: null,
	rows: [],
	cells: {},
};

export function MapEditor(props) {
	const {
		onRenameAxis = () => {},
		onRenameAxisBlur = () => {},
		onRenameDimension = () => {},
		onRenameDimensionBlur = () => {},
		onNewDimension = () => {},
		deleteDimension = () => {},
		onChangeTooltip = () => {},
		activeStep,
		propState = defaultState,
		activeValorIdMemberships,
		companyLookup,
		activeValorId,
		onCellButtonClick = () => {},
	} = props;
	const noOfRows = Object.keys(propState.rows).length;
	const noOfCols = Object.keys(propState.columns).length;

	const [tooltipDialog, setTooltipDialog] = useState(null);
	const [columnDialog, setColumnDialog] = useState(null);

	const theme = useTheme();

	if (activeStep === MapStep.Map && (!noOfRows || !noOfCols)) {
		return (
			<Box>
				<Typography variant="h2">
					Add a dimension on the &quot;Plan&quot; tab
				</Typography>
			</Box>
		);
	}

	return (
		<>
			<Box
				sx={{
					display: "grid",
					flex: 1,
					gridTemplateRows: `36px 36px repeat(${
						noOfRows === 0 ? 1 : noOfRows
					}, minmax(0, 1fr))`,
					gridTemplateColumns: `36px 36px repeat(${
						noOfCols === 0 ? 1 : noOfCols
					}, minmax(0, 1fr))`,
					height: "100%",
					maxHeight: "100%",
				}}
			>
				<Box style={{ gridArea: "1 / 2 / 2 / -1" }}>
					<GridContent>
						<DimHeader activeStep={activeStep}>
							{activeStep === MapStep.Plan ? (
								<input
									maxLength={50}
									style={{
										borderBottom:
											activeStep === MapStep.Plan
												? `1px solid ${theme.palette.divider}`
												: null,
										textAlign: "center",
									}}
									placeholder="Column Title"
									value={propState.columnTitle}
									disabled={activeStep !== MapStep.Plan}
									onChange={(e) => {
										onRenameAxis(e.target.value, "columnTitle");
									}}
									onBlur={(e) => {
										onRenameAxisBlur(e.target.value, "columnTitle");
									}}
								/>
							) : (
								<Typography variant="h2">{propState.columnTitle}</Typography>
							)}
						</DimHeader>
					</GridContent>
				</Box>
				<Box style={{ gridArea: "2 / 1 / -1 / 2" }}>
					<GridContent>
						<DimHeader
							activeStep={activeStep}
							sx={{
								transform: "rotate(-90deg)",
							}}
						>
							{activeStep === MapStep.Plan ? (
								<input
									maxLength={50}
									style={{
										borderBottom:
											activeStep === MapStep.Plan
												? `1px solid ${theme.palette.divider}`
												: null,
										textAlign: "center",
									}}
									placeholder="Row Title"
									readOnly
									value={propState.rowTitle}
									disabled={activeStep !== MapStep.Plan}
									onClick={() =>
										setColumnDialog({
											id: "rowTitle",
											value: propState.rowTitle,
										})
									}
								/>
							) : (
								<Typography variant="h2">{propState.rowTitle}</Typography>
							)}
						</DimHeader>
					</GridContent>
				</Box>

				{activeStep === MapStep.Plan && (
					<Box
						style={{
							backgroundColor: "transparent",
							color: theme.palette.text.secondary,
							gridArea: `2 / ${3 + noOfCols} / 2 / -1`,
						}}
					>
						<GridContent>
							{noOfCols < 1 ? (
								<AddDimensionButton
									onClick={() =>
										onNewDimension(`Column Name ${noOfCols + 1}`, "columns")
									}
								>
									<AddCircleOutlineOutlinedIcon />
									<Typography variant="h2">Add Dimension</Typography>
								</AddDimensionButton>
							) : (
								<IconButton
									style={{ width: "40px" }}
									disabled={noOfCols === 5}
									onClick={() =>
										onNewDimension(`Column Name ${noOfCols + 1}`, "columns")
									}
								>
									<AddCircleOutlineOutlinedIcon />
								</IconButton>
							)}
						</GridContent>
					</Box>
				)}
				{activeStep === MapStep.Plan && (
					<Box
						style={{
							backgroundColor: "transparent",
							color: theme.palette.text.secondary,
							gridArea: `${3 + noOfRows} / 2 / -1 / 2`,
						}}
					>
						<GridContent>
							{noOfRows < 1 ? (
								<Box style={{ transform: "rotate(-90deg)" }}>
									<AddDimensionButton
										onClick={() =>
											onNewDimension(`Row Name ${noOfRows + 1}`, "rows")
										}
									>
										<AddCircleOutlineOutlinedIcon />
										<Typography style={{ width: "168px" }} variant="h2">
											Add dimension
										</Typography>
									</AddDimensionButton>
								</Box>
							) : (
								<IconButton
									style={{ height: "40px" }}
									disabled={noOfRows === 5}
									onClick={() =>
										onNewDimension(`Row Name ${noOfRows + 1}`, "rows")
									}
								>
									<AddCircleOutlineOutlinedIcon />
								</IconButton>
							)}
						</GridContent>
					</Box>
				)}
				{Object.keys(propState.columns).map((key, i) => (
					<div
						key={`colHeaders${key}`}
						style={{
							gridRow: 2,
							gridColumn: 3 + i,
							border: `1px solid ${theme.palette.divider}`,
							color: "rgba(0,0,0,0.87)",
							borderTopLeftRadius: theme.spacing(1),
							borderTopRightRadius: theme.spacing(1),
							backgroundColor: theme.palette.blue.light,
						}}
					>
						<GridContent>
							<DimHeader activeStep={activeStep}>
								{activeStep === MapStep.Plan ? (
									<>
										<input
											maxLength={50}
											style={{
												color: "white",
												backgroundColor: "transparent",
											}}
											value={propState.columns[key].name}
											disabled={activeStep !== MapStep.Plan}
											onBlur={(e) => {
												if (
													e.target.value === "" &&
													activeStep === MapStep.Plan
												) {
													deleteDimension(key, "columns");
												} else {
													onRenameDimensionBlur(e.target.value, key, "columns");
												}
											}}
											onChange={(event) =>
												onRenameDimension(event.target.value, key, "columns")
											}
											onFocus={(event) => event.target.select()}
										/>
										<Box
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												width: "32px",
												height: "32px",
											}}
										>
											{propState.columns[key].name?.length >= 3 && (
												<Tooltip
													title={
														propState.columns[key]?.tooltip ||
														"Add a tooltip to this row value"
													}
												>
													<IconButton
														style={{ color: "white" }}
														onClick={() =>
															setTooltipDialog({
																id: key,
																field: "columns",
																value: propState.columns[key]?.tooltip ?? "",
															})
														}
													>
														<InfoOutlinedIcon />
													</IconButton>
												</Tooltip>
											)}
											{!!propState.columns[key]?.tooltip?.length && (
												<Tooltip
													title={
														propState.columns[key]?.tooltip ||
														"Add a tooltip to this row value"
													}
												>
													<InfoOutlinedIcon />
												</Tooltip>
											)}
										</Box>
									</>
								) : (
									<Typography
										style={{
											color: "white",
											fontWeight: theme.typography.fontWeightBold,
											whiteSpace: "normal",
											fontSize: "100%",
										}}
										variant="h2"
									>
										{propState.columns[key].name}
									</Typography>
								)}
							</DimHeader>
						</GridContent>
					</div>
				))}

				{Object.keys(propState.rows).map((key, i) => (
					<div
						key={`rowHeader${key}`}
						style={{
							gridRow: 3 + i,
							gridColumn: 2,
							color: "rgba(0,0,0,0.87)",
							border: `1px solid ${theme.palette.divider}`,
							borderTopLeftRadius: theme.spacing(1),
							borderBottomLeftRadius: theme.spacing(1),
							backgroundColor: theme.palette.blue.light,
						}}
					>
						<GridContent
							style={{ position: "relative", justifyContent: "flex-start" }}
						>
							<DimHeader
								activeStep={activeStep}
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									overflow: activeStep === MapStep.Plan ? "hidden" : "scroll",
									transform: "translateX(-50%) translateY(-50%) rotate(-90deg)",
									whiteSpace: "nowrap",
									"-ms-overflow-style": "none",
									scrollbarWidth: "none",
									"&::-webkit-scrollbar": {
										display: "none",
									},
								}}
							>
								{activeStep === MapStep.Plan ? (
									<>
										<input
											maxLength={50}
											readOnly
											style={{
												height: "100%",
												width: "100%",
												textAlign: "center",
												color: "white",
												backgroundColor: "transparent",
											}}
											value={propState.rows[key].name}
											onClick={() =>
												setColumnDialog({
													id: key,
													value: propState.rows[key].name,
												})
											}
										/>
										<Box
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												width: "32px",
												height: "32px",
											}}
										>
											{(propState.rows[key].name?.length >= 3 ||
												!!propState.rows[key]?.tooltip?.length) && (
												<Tooltip
													title={
														propState.rows[key]?.tooltip
															? propState.rows[key]?.tooltip
															: "Add a tooltip to this row value"
													}
												>
													<IconButton
														style={{ color: "white" }}
														onClick={() =>
															setTooltipDialog({
																id: key,
																field: "rows",
																value: propState.rows[key]?.tooltip ?? "",
															})
														}
														disabled={activeStep !== MapStep.Plan}
													>
														<InfoOutlinedIcon />
													</IconButton>
												</Tooltip>
											)}
										</Box>
									</>
								) : (
									<Typography
										style={{
											maxWidth: "180px",
											color: "white",
											fontWeight: theme.typography.fontWeightBold,
											whiteSpace: "nowrap",
											fontSize: "100%",
										}}
										variant="h2"
									>
										{propState.rows[key].name}
									</Typography>
								)}
							</DimHeader>
						</GridContent>
					</div>
				))}
				{[
					...Array(noOfCols + (activeStep === MapStep.Plan ? 1 : 0)).keys(),
				].map((colI) =>
					[
						...Array(noOfRows + (activeStep === MapStep.Plan ? 1 : 0)).keys(),
					].map((rowI) => {
						const rowKey = Object.keys(propState.rows)[rowI];
						const colKey = Object.keys(propState.columns)[colI];
						const fieldDict = { row: rowKey, col: colKey };
						const coordinates = buildCoordinate({
							rows: fieldDict.row,
							columns: fieldDict.col,
						});

						const mode = (() => {
							if (activeStep === MapStep.Map) {
								if (activeValorId) {
									if (activeValorIdMemberships.indexOf(coordinates) === -1) {
										return "add";
									}
									return "remove";
								}
								return "map";
							}
							if (activeStep === MapStep.Plan) {
								return "map";
							}
							return "blank";
						})();
						return (
							<MapCell
								key={`${fieldDict.row}${fieldDict.col}`}
								rowName={propState.rows[rowKey]?.name}
								colName={propState.columns[colKey]?.name}
								rowKey={rowKey}
								colKey={colKey}
								gridRowNumber={rowI + 3}
								gridColumnNumber={colI + 3}
								onCellButtonClick={onCellButtonClick}
								mode={mode}
								companies={
									propState.cells?.[coordinates]
										?.map((valorId) => {
											if (companyLookup[valorId]) {
												return companyLookup[valorId];
											}
											return null;
										})
										.filter((x) => x) ?? []
								}
							/>
						);
					}),
				)}
			</Box>
			<Dialog
				open={tooltipDialog !== null}
				onClose={() => setTooltipDialog(null)}
				aria-labelledby="responsive-dialog-title"
			>
				{tooltipDialog && (
					<>
						<DialogTitle id="responsive-dialog-title">
							Update the tool tip for{" "}
							{propState[tooltipDialog.field][tooltipDialog.id].name}
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								<TextField
									id="standard-basic"
									label="Tooltip"
									value={tooltipDialog.value}
									onChange={(e) =>
										setTooltipDialog({
											...tooltipDialog,
											value: e.target.value,
										})
									}
								/>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								autoFocus
								onClick={() => {
									setTooltipDialog(null);
								}}
								color="primary"
							>
								Close
							</Button>
							<Button
								onClick={() => {
									setTooltipDialog(null);
									onChangeTooltip(
										tooltipDialog.value,
										tooltipDialog.id,
										tooltipDialog.field,
									);
								}}
								color="primary"
								variant="contained"
								autoFocus
							>
								Update
							</Button>
						</DialogActions>
					</>
				)}
			</Dialog>
			<Dialog
				fullWidth
				open={columnDialog !== null}
				onClose={() => setColumnDialog(null)}
				aria-labelledby="responsive-column-dialog-title"
			>
				{columnDialog && (
					<>
						<DialogTitle id="responsive-column-dialog-title">
							Update row {columnDialog.id === "rowTitle" ? "axis" : "name"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								<TextField
									style={{ width: "500px" }}
									width="90%"
									id="standard-basic"
									label="Name"
									value={columnDialog.value}
									onChange={(e) =>
										setColumnDialog({
											...columnDialog,
											value: e.target.value,
										})
									}
								/>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							{columnDialog.id !== "rowTitle" && (
								<Button
									autoFocus
									onClick={() => {
										deleteDimension(columnDialog.id, "rows");
										setColumnDialog(null);
									}}
									color="primary"
								>
									Delete Row
								</Button>
							)}
							<Button
								onClick={() => {
									if (columnDialog.id === "rowTitle") {
										onRenameAxis(columnDialog.value, "rowTitle");
										onRenameAxisBlur(columnDialog.value, "rowTitle");
									} else {
										onRenameDimension(
											columnDialog.value,
											columnDialog.id,
											"rows",
										);
										onRenameDimensionBlur(
											columnDialog.value,
											columnDialog.id,
											"rows",
										);
									}
									setColumnDialog(null);
								}}
								color="primary"
								variant="contained"
								autoFocus
							>
								Update
							</Button>
						</DialogActions>
					</>
				)}
			</Dialog>
		</>
	);
}

export default MapEditor;
