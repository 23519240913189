import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import Progress from "@/ui/atoms/Progress";
import titleCase from "@/utils/titleCase";
import { CheckCircle } from "@mui/icons-material";
import { Box, Chip, Stack } from "@mui/material";
import React, { useMemo } from "react";
import { AddContextButton } from "./AddContextButton";
import type { SelectedContextType } from "./utils/ChatState";

function ContextTypeChip({
	context,
	onRemove,
}: {
	context: SelectedContextType;
	onRemove: (id: string, contextType: string) => void;
}) {
	const isLoading = !context.data;
	const label = context.data
		? `${context.contextType} (${context.data.tokenCount.toLocaleString()} tokens)`
		: context.contextType;

	return (
		<Chip
			label={
				<Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
					{titleCase(label)}
					{isLoading && <Progress sx={{ fixSize: 12 }} />}
					{!isLoading && (
						<CheckCircle
							sx={{
								fontSize: 12,
								color: "success.main",
							}}
						/>
					)}
				</Box>
			}
			onDelete={() => onRemove(context.id, context.contextType)}
			sx={{
				minWidth: 180,
				justifyContent: "flex-start",
			}}
		/>
	);
}

function CompanyContextGroup({ company, contexts, onRemove, onAddContext }) {
	return (
		<Box
			sx={{
				p: 2,
				bgcolor: "background.paper",
				borderRadius: 1,
				border: (theme) => `1px solid ${theme.palette.divider}`,
				width: "100%",
			}}
		>
			<Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
				<CompanyAvatar src={company.logoUrl} name={company.name} size={32} />
				<Box sx={{ fontWeight: "bold" }}>{company.name}</Box>
			</Box>
			<Stack direction="row" spacing={1} flexWrap="wrap" sx={{ gap: 1 }}>
				{contexts.map((context) => (
					<ContextTypeChip
						key={`${context.id}-${context.contextType}`}
						context={context}
						onRemove={onRemove}
					/>
				))}
				<Box sx={{ mt: 1 }}>
					<AddContextButton
						onClick={() => onAddContext(company)}
						label="Add More"
					/>
				</Box>
			</Stack>
		</Box>
	);
}

interface SelectedContextsProps {
	contexts: SelectedContextType[];
	onRemoveContext: (contextId: string, contextType: string) => void;
	onAddContext: (company: any) => void;
}

export function SelectedContexts({
	contexts,
	onRemoveContext,
	onAddContext,
}: SelectedContextsProps) {
	const groupedContexts = useMemo(
		() =>
			contexts.reduce((acc, context) => {
				const key = context.valorId;
				if (!acc[key]) {
					acc[key] = {
						company: {
							name: context.name,
							logoUrl: context.logoUrl,
							valorId: context.valorId,
						},
						contexts: [],
					};
				}
				acc[key].contexts.push(context);
				return acc;
			}, {}),
		[contexts],
	);

	return (
		<Stack spacing={2} sx={{ width: "100%" }}>
			{Object.values(groupedContexts).map((group: any) => (
				<CompanyContextGroup
					key={group.company.valorId}
					company={group.company}
					contexts={group.contexts}
					onRemove={onRemoveContext}
					onAddContext={onAddContext}
				/>
			))}
		</Stack>
	);
}

export default SelectedContexts;
