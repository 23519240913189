import type { components } from "@/api/index";
import { Box } from "@mui/material";
import React from "react";
import {
	CapitalMarketsActive,
	CapitalMarketsClosed,
	LiquidityEventActive,
	LiquidityEventClosed,
	type MeetingType,
	MergersAndAcquisitionActive,
	MergersAndAcquisitionClosed,
} from "../meeting-types";
import Note from "./Note";

type NoteModel = components["schemas"]["Note"];
type NotesListProps = {
	notes: NoteModel[];
	userId: string;
	hideControls?: boolean;
	editNote: (documentId: string, valorId?: string) => void;
	viewNote: (documentId: string, valorId?: string) => void;
	deleteNote: (documentId: string) => void;
	// hideDelete is temporary until we add delete functionality to NotesDrawer
	hideDelete?: boolean;
};

function NotesList({
	notes,
	userId,
	editNote,
	viewNote,
	deleteNote,
	hideControls,
	// hideDelete is temporary until we add delete functionality to NotesDrawer
	hideDelete,
}: NotesListProps) {
	const shouldEdit = (note: NoteModel) => {
		if (hideControls) {
			return false;
		}
		if (userId === note.authorId) {
			return true;
		}
		if (
			[
				CapitalMarketsActive,
				CapitalMarketsClosed,
				MergersAndAcquisitionActive,
				MergersAndAcquisitionClosed,
				LiquidityEventActive,
				LiquidityEventClosed,
			].indexOf(note.meetingType as MeetingType) > -1
		) {
			return true;
		}
		return false;
	};
	return (
		<Box display="flex" flexDirection="column" gap={1}>
			{notes.map((note) => (
				<Note
					key={note.documentId}
					note={note}
					showEdit={shouldEdit(note)}
					// hideDelete is temporary until we add delete functionality to NotesDrawer
					showDelete={!hideDelete && !hideControls && note.authorId === userId}
					showViewNote={!hideControls}
					showCopyLink={!hideControls}
					editNote={() => editNote(note.documentId, note.valorId)}
					deleteNote={() => deleteNote(note.documentId)}
					viewNote={() => viewNote(note.documentId, note.valorId)}
				/>
			))}
		</Box>
	);
}

export default NotesList;
