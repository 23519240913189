/* eslint-disable no-console */
import axios from "axios";

export const discoverCompetitors = async (params) => {
	try {
		const response = await axios.post(
			"/theia-api/discover",
			JSON.stringify(params),
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const getSavedSearch = async (savedSearchId) => {
	const response = await axios.get(`/theia-api/saved_search/${savedSearchId}`);
	return response.data;
};

export const getSearchParams = async (payload) => {
	try {
		const response = await axios.post(
			"/theia-api/searchparams",
			JSON.stringify(payload),
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const searchKeywords = async (query) => {
	try {
		const response = await axios.get(
			`/theia-api/keywords?q=${encodeURIComponent(query)}`,
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const discoverMarketMapDiscover = async (
	marketMapId,
	extraIds,
	nResults = 20,
	filters = {},
) => {
	try {
		const response = await axios.post("/theia-api/market_map_discover", {
			marketMapId,
			extraValorIds: extraIds,
			nResults,
			filters,
		});
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export default {
	discoverCompetitors,
	getSearchParams,
	searchKeywords,
	discoverMarketMapDiscover,
};
