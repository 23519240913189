import { SignalChip } from "@/components/SignalChip";
import { Box } from "@mui/material";
import React from "react";

type PredictedMoicCellProps = {
	value: number;
	country?: string;
	valorId: string;
};

export default function PredictedMoicCell({
	value,
	country,
	valorId,
}: PredictedMoicCellProps) {
	if (value === null) {
		if (country !== "US") {
			return "Not US";
		}
		return "-";
	}

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			height="100%"
			gap={1}
		>
			<SignalChip
				signal={{
					signalType: "signal_fimoica_prediction",
					score: value,
				}}
				valorId={valorId}
				showIcon
			/>
		</Box>
	);
}
