import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

export default function DateCell({
	value,
	format = "MM/DD/YYYY",
}: {
	value: number | string | null;
	format?: string;
}) {
	let content = null;
	if (value === null || value === undefined) {
		content = "-";
	} else {
		content = dayjs(value).utc().local().format(format);
	}
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			sx={{ width: "100%", height: "100%" }}
		>
			<Typography variant="body2">{content}</Typography>
		</Box>
	);
}
