import type React from "react";

import { Box, List, Table, TableRow, Typography } from "@mui/material";

import {
	MHeaderCell,
	MTableBody,
	MTableCell,
	MTableHead,
} from "@/components/MiniTable";

export const renderContent = (htmlContent: string) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlContent, "text/html");
	return Array.from(doc.body.childNodes).map((node, index) =>
		convertNode(node, index),
	);
};

export const convertNode = (node: Node, key?: number): React.ReactNode => {
	// Handle text nodes
	if (node.nodeType === Node.TEXT_NODE) {
		// Return null for pure whitespace text nodes
		if (node.textContent?.trim() === "") {
			return null;
		}
		return node.textContent;
	}

	// Handle element nodes
	if (node.nodeType === Node.ELEMENT_NODE) {
		const element = node as Element;
		const children = Array.from(element.childNodes).map((child, index) =>
			convertNode(child, index),
		);
		const tagName = element.tagName.toLowerCase();
		switch (tagName) {
			case "div":
				return (
					<Box key={key} sx={{ my: 1 }}>
						{children}
					</Box>
				);
			case "span":
				if (element.classList.contains("katex")) {
					return (
						<Box
							key={key}
							component="span"
							dangerouslySetInnerHTML={{ __html: element.outerHTML }}
							sx={{
								"& .katex-display": {
									margin: "1em 0",
								},
								"& .katex": {
									fontSize: "1.1em",
								},
							}}
						/>
					);
				}
				return children;
			case "a":
				return (
					<Typography
						key={key}
						component="a"
						href={element.getAttribute("href") || "#"}
						target="_blank"
						rel="noopener noreferrer"
						sx={{
							color: "inherit",
							textDecoration: "underline",
							"&:hover": {
								opacity: 0.8,
							},
							cursor: "pointer",
							display: "inline",
						}}
					>
						{children}
					</Typography>
				);
			case "p":
				return (
					<Box
						variant="body2"
						key={key}
						sx={{
							my: 2,
							lineHeight: 1.6,
							fontSize: "1rem",
							color: "text.primary",
							"& + p": {
								// Add spacing between consecutive paragraphs
								mt: 2,
							},
							"&:last-child": {
								mb: 0,
							},
						}}
					>
						{children}
					</Box>
				);
			case "h1":
				return (
					<Typography key={key} variant="h1" sx={{ my: 2 }}>
						{children}
					</Typography>
				);

			case "h2":
				return (
					<Typography key={key} variant="h2" sx={{ my: 2 }}>
						{children}
					</Typography>
				);

			case "h3":
				return (
					<Typography key={key} variant="h3" sx={{ my: 2 }}>
						{children}
					</Typography>
				);

			case "h4":
				return (
					<Typography key={key} variant="h3" sx={{ my: 2 }}>
						{children}
					</Typography>
				);

			case "table":
				return (
					<Box
						key={key}
						sx={{
							my: 2,
							maxWidth: "100%",
							overflowX: "auto",
						}}
					>
						<Table
							sx={(theme) => ({
								border: "1px solid",
								borderColor: theme.palette.divider,
								tableLayout: "auto",
								width: "auto",
								minWidth: "100%",
								"& td, & th": {
									borderRight: "1px solid",
									borderColor: theme.palette.divider,
									whiteSpace: "nowrap",
									"&:last-child": {
										borderRight: "none",
									},
								},
							})}
						>
							{children}
						</Table>
					</Box>
				);

			case "thead":
				return <MTableHead key={key}>{children}</MTableHead>;

			case "tbody":
				return <MTableBody key={key}>{children}</MTableBody>;

			case "tr":
				return <TableRow key={key}>{children}</TableRow>;

			case "td":
				return <MTableCell key={key}>{children}</MTableCell>;

			case "th":
				return <MHeaderCell key={key}>{children}</MHeaderCell>;
			case "ol":
			case "ul":
				return (
					<List
						key={key}
						component="ul"
						sx={{
							pl: 2,
							listStyle: "disc",
							"& .MuiListItem-root": {
								display: "list-item",
								"&::before": {
									display: "none !important",
								},
							},
						}}
					>
						{children}
					</List>
				);

			case "li":
				return (
					<Box
						key={key}
						component="li"
						sx={{
							pl: 0,
							py: 0.5,
							"&::marker": {
								// Remove the marker pseudo-element
								display: "none",
							},
							"& > *": {
								display: "block",
							},
							"& p": {
								margin: 0,
								marginBottom: 1,
							},
							"& pre": {
								display: "in-line",
								my: 1,
								mx: 0,
							},
						}}
					>
						{children}
					</Box>
				);

			case "br":
				return <Box key={key} component="br" />;

			case "hr":
				return (
					<Box
						key={key}
						component="hr"
						sx={(theme) => ({
							my: 2,
							border: "none",
							height: "1px",
							backgroundColor: theme.palette.divider,
							width: "100%",
						})}
					/>
				);

			case "strong":
				return (
					<Typography
						key={key}
						component="span"
						sx={{
							fontWeight: "bold",
							display: "inline",
						}}
					>
						{children}
					</Typography>
				);
			case "s":
				return (
					<Typography
						key={key}
						component="span"
						sx={{
							textDecoration: "line-through",
							display: "inline",
						}}
					>
						{children}
					</Typography>
				);
			case "em":
				return (
					<Typography key={key} component="span" sx={{ fontStyle: "italic" }}>
						{children}
					</Typography>
				);
			case "pre":
				return (
					<Box
						key={key}
						component="pre"
						sx={(theme) => ({
							backgroundColor: theme.palette.grey[900],
							color: theme.palette.grey[100],
							borderRadius: 1,
							p: 2,
							overflow: "auto",
							"& code": {
								fontFamily:
									'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
								fontSize: "0.875rem",
							},
							"& .hljs": {
								background: "transparent",
								padding: 0,
							},
						})}
					>
						{children}
					</Box>
				);

			case "code": {
				const isInPre = element.parentElement?.tagName.toLowerCase() === "pre";
				return (
					<Typography
						key={key}
						component="code"
						sx={(theme) => ({
							fontFamily:
								'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
							...(isInPre
								? {
										display: "block",
										backgroundColor: "transparent",
									}
								: {
										backgroundColor: theme.palette.grey[200],
										p: 0.5,
										borderRadius: 0.5,
										fontSize: "0.875em",
									}),
						})}
						{...(isInPre
							? { dangerouslySetInnerHTML: { __html: element.innerHTML } }
							: { children })}
					/>
				);
			}
			case "sub":
				return (
					<Typography
						key={key}
						component="sub"
						sx={{
							fontSize: "75%",
							lineHeight: 0,
							position: "relative",
							verticalAlign: "baseline",
							bottom: "-0.25em",
						}}
					>
						{children}
					</Typography>
				);

			case "sup":
				return (
					<Typography
						key={key}
						component="sup"
						sx={{
							fontSize: "75%",
							lineHeight: 0,
							position: "relative",
							verticalAlign: "baseline",
							top: "-0.5em",
						}}
					>
						{children}
					</Typography>
				);
			// LaTex Crap
			case "annotation":
			case "math":
			case "mfrac":
			case "mi":
			case "mn":
			case "mo":
			case "mrow":
			case "mspace":
			case "msqrt":
			case "msub":
			case "msubsup":
			case "msup":
			case "mtext":
			case "semantic":
			case "semantics":
				return children;
			default:
				console.log(tagName);
				return children;
		}
	}

	return null;
};

export default { convertNode, renderContent };
