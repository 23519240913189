import { saytForOrg } from "@/api/Search";
import SAYT from "@/components/SAYT";
import SAYTCompanyResult from "@/ui/atoms/SAYTCompanyResult";
import React from "react";

type CompanyInputValue = {
	valorId: string;
	name: string;
	logoUrl: string;
	domain: string;
};

type CompanyInputProps = {
	value: CompanyInputValue;
	onChange: (value: CompanyInputValue) => void;
	required?: boolean;
	disabled?: boolean;
	onBlur: (value: CompanyInputValue) => void;
	label: string;
};

export default function CompanyInput({
	value,
	onChange,
	required,
	disabled,
	onBlur,
	label,
	error,
	dataCy,
}: CompanyInputProps) {
	return (
		<SAYT
			value={value}
			onChange={onChange}
			required={required}
			disabled={disabled}
			onBlur={onBlur}
			style={{ width: "100%" }}
			label={label}
			search={(term) => saytForOrg(term)}
			getOptionSelected={(option, selectedOption) =>
				option.valorId === selectedOption?.valorId
			}
			getOptionLabel={(option) => (option ? option.name : "")}
			renderOption={(option, { inputValue }) => (
				<SAYTCompanyResult
					title={option.name}
					logoUrl={option.logoUrl}
					domain={option.domain}
					query={inputValue}
				/>
			)}
			error={error}
			data-cy={dataCy}
		/>
	);
}
