import MultiSparkLine from "@/ui/atoms/MultiSparkLine";
import SparkLine from "@/ui/atoms/SparkLine";
import {
	Box,
	Grid,
	type Theme,
	Typography,
	createStyles,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			minWidth: theme.spacing(40),
			minHeight: theme.spacing(30),
			padding: theme.spacing(1),
			overflow: "hidden",
			backgroundColor: theme.palette.background.paper,
			border: `1px solid ${theme.palette.divider}`,
			borderRadius: theme.shape.borderRadius,
		},
		titleContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
		title: {
			paddingBottom: theme.spacing(0.5),
			color: theme.palette.text.primary,
			fontWeight: theme.typography.fontWeightBold,
			fontSize: "16px",
		},
		subtitle: {
			paddingBottom: theme.spacing(0.5),
			color: theme.palette.text.primary,
			fontWeight: theme.typography.fontWeightMedium,
			fontSize: "14px",
		},
		label: {
			color: theme.palette.text.secondary,
			fontWeight: theme.typography.fontWeightBold,
			fontSize: "12px",
		},
		value: {
			paddingBottom: theme.spacing(1),
			fontWeight: theme.typography.fontWeightSemiBold,
			fontSize: "24px",
		},
	}),
);

interface Props {
	title: string;
	subtitle?: string;
	keys?: string[];
	dataFormatter: (val: number) => number | string;
	metrics: {
		value?: string;
		label?: string;
	}[];
	data: {
		value?: number;
		timestamp?: number;
	}[];
}
export default function KPICard(props: Props) {
	const {
		data,
		title,
		subtitle,
		metrics,
		dataFormatter,
		keys = ["value"],
	} = props;
	const theme = useTheme();
	const classes = useStyles();
	const chooseValueColor = (percent, multiple, positive) => {
		if (!percent && !multiple) return theme.palette.text.primary;
		if (positive) return theme.palette.green.main;
		return theme.palette.red.main;
	};

	return (
		<Box className={classes.container}>
			<Grid container>
				<Grid className={classes.titleContainer} item xs={12}>
					<Typography className={classes.title}>{title}</Typography>
					<Typography className={classes.subtitle}>{subtitle}</Typography>
				</Grid>
				{metrics.map(({ label, value }) => {
					if (!value) return null;
					const isPercent = value.indexOf("%") !== -1;
					const isMultiple =
						value.indexOf("up") !== -1 || value.indexOf("down") !== -1;

					let isPositive;
					if (isPercent) {
						isPositive = value.indexOf("-") === -1;
					}

					if (isMultiple) {
						if (value > 100) {
							isPositive = value.indexOf("down") === -1;
						}
						isPositive = value.indexOf("-") === -1;
					}

					const color = chooseValueColor(isPercent, isMultiple, isPositive);
					return (
						<Grid item sm={4} xs={6} key={label}>
							<Box flexDirection="column">
								<Typography className={classes.label}>{label}</Typography>
								<Typography style={{ color }} className={classes.value}>
									{value}
								</Typography>
							</Box>
						</Grid>
					);
				})}
			</Grid>
			<Grid item xs={12}>
				<Box display="flex" width="100%" justifyContent="center">
					{keys.length === 1 ? (
						<SparkLine
							xAxis
							dataFormatter={dataFormatter}
							data={data}
							height={theme.spacing(20)}
						/>
					) : (
						<MultiSparkLine
							xAxis
							lines={[
								{ lineName: "iOS", color: "#0042ED" },
								{ lineName: "Android", color: "#00BB04" },
							]}
							dataFormatter={dataFormatter}
							data={data}
							height={theme.spacing(20)}
						/>
					)}
				</Box>
			</Grid>
		</Box>
	);
}

KPICard.defaultProps = {
	keys: ["value"],
	subtitle: "",
};
