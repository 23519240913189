import { type RefObject, useEffect, useState } from "react";

export function useVisibility<T extends HTMLElement>(
	ref: RefObject<T>,
): boolean {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	useEffect(() => {
		const currentRef = ref.current;
		const observer = new IntersectionObserver(
			([entry]: IntersectionObserverEntry[]) => {
				setIsVisible(entry.isIntersecting);
			},
			{ threshold: 0.1 }, // Trigger when at least 10% of the element is visible
		);

		if (currentRef) {
			observer.observe(currentRef);
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, [ref]);

	return isVisible;
}

export default useVisibility;
