import { useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography } from "@mui/material";
import React from "react";

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return {
		root: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			width: "520px",
			height: "280px",
			backgroundColor: theme.palette.background.paper,
			borderRadius: theme.shape.borderRadius,
			boxShadow: theme.shadows[1],
		},
		logo: {
			height: "75%",
		},
		header: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "100%",
			height: "25%",
			color: "white",
			backgroundColor: theme.palette.black.main,
			borderTopLeftRadius: theme.shape.borderRadius,
			borderTopRightRadius: theme.shape.borderRadius,
		},
		content: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-around",
			height: "100%",
			padding: theme.spacing(4),
		},
		welcome: {
			color: theme.palette.text.secondary,
		},
	};
});

interface Props {
	logoUrl: string;
	onLogin: () => void;
}

export default function LoginCard(props: Props) {
	const { onLogin, logoUrl } = props;
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Box className={classes.header}>
				<img className={classes.logo} alt="Argos Logo" src={logoUrl} />
			</Box>
			<Box className={classes.content}>
				<Typography variant="h2" className={classes.welcome}>
					Welcome, please log in.
				</Typography>
				<Button onClick={onLogin} variant="outlined" color="primary">
					Login With Microsoft
				</Button>
			</Box>
		</Box>
	);
}
