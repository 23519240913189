import { useTheme } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";

const InfiniteTableContext = React.createContext(null);
export const InfiniteTableProvider = InfiniteTableContext.Provider;
export const useParentRef = () => {
	const parentRef = useContext(InfiniteTableContext);
	if (!parentRef) {
		throw new Error("InfiniteTableContext is null");
	}
	return parentRef;
};

const useVirtualTableBody = ({
	rows,
	estimateSize,
	onRowDoubleClick,
	table,
}) => {
	const theme = useTheme();
	const [isInitialized, setIsInitialized] = useState(false);
	const [touchState, setTouchState] = useState({
		currentRow: null,
		touchStartTime: null,
		touchX: null,
		touchY: null,
	});
	const tableContainerRef = useParentRef();

	const initializeVirtualization = useCallback(() => {
		if (!tableContainerRef.current || !rows.length) return;
		const container = tableContainerRef.current;
		const { height } = container.getBoundingClientRect();

		if (height > 0) {
			setIsInitialized(true);
		}
	}, [rows.length, tableContainerRef.current]);

	useEffect(() => {
		if (!tableContainerRef.current) return null;
		const resizeObserver = new ResizeObserver(() => {
			initializeVirtualization();
		});
		resizeObserver.observe(tableContainerRef.current);
		return () => {
			resizeObserver.disconnect();
		};
	}, [tableContainerRef, initializeVirtualization]);

	useEffect(() => {
		initializeVirtualization();
	}, [initializeVirtualization]);

	const rowVirtualizer = useVirtualizer({
		getScrollElement: useCallback(
			() => tableContainerRef?.current,
			[tableContainerRef],
		),
		count: rows.length,
		estimateSize: useCallback(() => estimateSize, [estimateSize]),
		overscan: 15,
		measureElement:
			typeof window !== "undefined" &&
			navigator.userAgent.indexOf("Firefox") === -1
				? (element) => element?.getBoundingClientRect().height
				: undefined,
	});

	const visibleColumns = table.getVisibleLeafColumns();
	const virtualRows = rowVirtualizer.getVirtualItems();
	const totalSize = rowVirtualizer.getTotalSize() + estimateSize;

	const columnVirtualizer = useVirtualizer({
		horizontal: true,
		count: visibleColumns.length - 1,
		getScrollElement: useCallback(
			() => tableContainerRef?.current,
			[tableContainerRef],
		),
		estimateSize: useCallback(
			(index) => visibleColumns[index + 1].getSize(),
			[visibleColumns],
		),
		overscan: 30,
	});

	const virtualColumns = columnVirtualizer.getVirtualItems();

	// Calculate padding for virtual columns
	const virtualPaddingLeft = useMemo(
		() =>
			visibleColumns.reduce((acc, curr, index) => {
				if (index !== 0 && index < ((virtualColumns[0]?.index ?? 0) + 1 || 0)) {
					return acc + curr.getSize();
				}
				return acc;
			}, 0),
		[visibleColumns, virtualColumns],
	);

	const virtualPaddingRight = useMemo(
		() =>
			virtualColumns.length
				? columnVirtualizer.getTotalSize() -
					(virtualColumns[virtualColumns.length - 1]?.end ?? 0)
				: 0,
		[virtualColumns, columnVirtualizer],
	);

	const handleTouchStart = useCallback((e: React.TouchEvent, row: any) => {
		setTouchState({
			currentRow: row,
			touchStartTime: new Date().getTime(),
			touchX: e.touches[0].clientX,
			touchY: e.touches[0].clientY,
		});
	}, []);

	const handleTouchEnd = useCallback(
		(e: React.TouchEvent) => {
			const { touchX, touchY, touchStartTime, currentRow } = touchState;
			if (!touchX || !touchY || !touchStartTime) return;

			const endTouchX = e.changedTouches[0].clientX;
			const endTouchY = e.changedTouches[0].clientY;
			const deltaX = Math.abs(endTouchX - touchX);
			const deltaY = Math.abs(endTouchY - touchY);

			if (deltaX > 5 || deltaY > 5) {
				setTouchState({
					currentRow: null,
					touchStartTime: null,
					touchX: null,
					touchY: null,
				});
				return;
			}

			const endTime = new Date().getTime();
			if (endTime - touchStartTime < 500) {
				onRowDoubleClick?.(currentRow);
			}
		},
		[touchState, onRowDoubleClick],
	);

	return {
		touchState,
		setTouchState,
		theme,
		virtualRows,
		virtualColumns,
		totalSize,
		virtualPaddingLeft,
		virtualPaddingRight,
		handleTouchStart,
		handleTouchEnd,
		visibleColumns,
		isInitialized,
	};
};

export default useVirtualTableBody;
