import React from "react";

const isValidDepartment = (
	department: string,
): department is "seed" | "vsv" | "platform" | "ancillary" => {
	return ["seed", "vsv", "platform", "ancillary"].includes(department);
};
const departmentMap = {
	seed: "Seed",
	vsv: "VSV",
	platform: "Platform",
	ancillary: "Ancillary",
};

function Department({ department }: { department: string }) {
	if (!isValidDepartment(department)) {
		return null;
	}

	return (
		<span style={{ display: "contents" }}>{departmentMap[department]}</span>
	);
}

export default Department;
