import {
	type Organization,
	getOrganizationSignalsById,
} from "@/api/Organization";
import FimoicaChip from "@/components/SignalChip/FimoicaChip";
import TartarusChip from "@/components/SignalChip/TartarusChip";
import { canSeeMOICChip } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import ValorFolio from "@/ui/atoms/ValorFolio";
import {
	Box,
	Divider,
	Grid,
	Skeleton,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useMemo } from "react";
import { useQuery } from "react-query";

interface Props {
	company: Organization;
}

const usedSignals = ["signal_tartarus_prediction", "signal_fimoica_prediction"];

const SignalBox = styled(Box)(({ theme }) => ({
	display: "flex",
	flexWrap: "wrap",
	gap: "32px 32px",
	maxHeight: "120px",
	[theme.breakpoints.down("sm")]: {
		gap: "8px",
		maxWidth: "420px",
	},
}));
function ModelFolio({ company }: Props) {
	const theme = useTheme();
	const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
	const { user } = useAuth();
	const { data: predictions = { signals: [] }, isLoading } = useQuery(
		["Signals", ...usedSignals, company.valorId],
		async () => getOrganizationSignalsById(company.valorId, usedSignals),
	);

	const canSeeChips = canSeeMOICChip(user);

	const tartarusSignal = useMemo(
		() =>
			predictions?.signals?.find(
				(signal) => signal.signalType === "signal_tartarus_prediction",
			),
		[predictions],
	);
	const fimoicaSignal = useMemo(
		() =>
			predictions?.signals?.find(
				(signal) => signal.signalType === "signal_fimoica_prediction",
			),
		[predictions],
	);

	if (!canSeeChips || isLoading || !predictions?.signals) {
		return null;
	}

	return (
		<Grid item xs={12} md={6}>
			<ValorFolio title="VALOR MODELS">
				<SignalBox>
					{isLoading ? (
						<Box
							display="flex"
							flexWrap="wrap"
							sx={{ gap: isSmDown ? "4px" : "8px" }}
						>
							<Skeleton variant="rounded" width="120px" height="80px" />
							<Skeleton variant="rounded" width="120px" height="80px" />
						</Box>
					) : predictions && predictions?.signals ? (
						<Box display="flex" sx={{ gap: isSmDown ? "4px" : "8px" }}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									gap: "8px",
								}}
								data-cy="model-folio__left-tail"
							>
								<Typography
									variant="boldBody2"
									data-cy="model-folio__left-tail"
								>
									Left Tail Model
								</Typography>
								<TartarusChip
									signalId={tartarusSignal?.signalId}
									score={tartarusSignal?.score}
								/>
							</Box>
							<Divider orientation="vertical" flexItem />
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									gap: "8px",
								}}
								data-cy="model-folio__right-tail"
							>
								<Typography
									variant="boldBody2"
									data-cy="model-folio__right-tail-title"
								>
									Right Tail Model
								</Typography>
								{fimoicaSignal && (
									<FimoicaChip
										signal={fimoicaSignal}
										showIcon
										showName
										showBorder
										valorId={company.valorId}
									/>
								)}
							</Box>
						</Box>
					) : (
						<Typography data-cy="model-folio__no-signals-message" mt={1}>
							No Signals
						</Typography>
					)}
				</SignalBox>
			</ValorFolio>
		</Grid>
	);
}
export default ModelFolio;
