import { Box, Checkbox, MenuItem, MenuList, Radio } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { uniq } from "lodash";
import React, { useEffect } from "react";

type FilterProps = {
	getFilterValue: () => any;
	setFilterValue: (value: any) => void;
	getFacetedUniqueValues: () => any;
};

export function uniqFlatSort(arr: string[]) {
	return uniq(arr.flat()).sort();
}

type Option =
	| {
			label: string;
			value: string | number;
	  }
	| string;
//  TODO figure out this isChecked thing
type SelectFilterProps = {
	options: Option[];
	onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
	multiple?: boolean;
	getOptionsLabel?: (value: any) => string | React.ReactNode;
	getOptionsValue?: (value: any) => string;
	getUniqueVal?: (value: any) => string;
	// isChecked?: (value: any, currentValue: any) => boolean;
	filterOptions?: (options: any[]) => any[];
	getFilterCounts?: () => Promise<any>;
};

const DenseMenuList = styled(MenuList)(() => ({
	padding: 0,
	maxHeight: "300px",
	overflow: "auto",
}));

const DenseMenuItem = styled(MenuItem)(() => ({
	padding: "4px",
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: 1,
	fontSize: "14px",
	maxHeight: "30px",
	cursor: "pointer",
	minWidth: 144,
}));

export function SelectFilter({
	getFilterValue,
	setFilterValue,
	getFacetedUniqueValues,
	multiple,
	options = null,
	getOptionsLabel = (value: any) => value,
	getOptionsValue = (value: any) => value,
	getUniqueVal = (value: any) => value,
	filterOptions = (opt: any[]) => opt,
	getFilterCounts = null,
	onChange = null,
}: FilterProps & SelectFilterProps) {
	// get options from unique column values
	const columnFilterValue = getFilterValue();

	const [filterCounts, setFilterCount] = React.useState(null);
	const sortedUniqueValues = React.useMemo(() => {
		if (options) {
			return filterOptions(options);
		}
		const possibleValues = getFacetedUniqueValues().keys();
		const values = uniq(Array.from(possibleValues).flat())
			.sort()
			.filter(Boolean);
		return filterOptions(values);
	}, [getFacetedUniqueValues, options]);

	const Control = multiple ? Checkbox : Radio;
	let isChecked = (optionValue) => {
		const a = getUniqueVal(columnFilterValue);
		const b = getOptionsValue(getUniqueVal(optionValue));
		return a === b;
	};
	if (multiple) {
		isChecked = (optionValue) => {
			if (!columnFilterValue) {
				return false;
			}
			const b = getUniqueVal(optionValue);
			return !!columnFilterValue
				?.map(getOptionsValue)
				.map(getUniqueVal)
				?.includes(getOptionsValue(b));
		};
	}
	useEffect(() => {
		if (getFilterCounts) {
			getFilterCounts().then((data) => {
				setFilterCount(
					data.reduce((acc, { label, count }) => {
						acc[label] = count;
						return acc;
					}, {}),
				);
			});
		}
	}, [columnFilterValue, getFilterCounts]);

	return (
		<Box>
			<DenseMenuList>
				{sortedUniqueValues.map((option) => (
					<DenseMenuItem
						key={
							getUniqueVal(getOptionsValue(option)) ?? getOptionsValue(option)
						}
						value={getOptionsValue(option)}
						onClick={(event) => {
							if (multiple) {
								let value = (columnFilterValue as []) || [];

								const optionValue = getUniqueVal(option);
								const index = value
									.map(getUniqueVal)
									.map(getOptionsValue)
									.indexOf(getOptionsValue(optionValue));
								if (index === -1) {
									value.push(optionValue);
								} else {
									value.splice(index, 1);
								}

								if (value.length === 0) {
									value = undefined;
								}

								if (onChange) {
									onChange({
										...event,
										target: {
											...event.target,
											value,
										},
									});
								} else {
									setFilterValue(value);
								}
							} else if (onChange) {
								onChange({
									...event,
									target: {
										...event.target,
										value: getOptionsValue(option),
									},
								});
							} else if (
								getUniqueVal(columnFilterValue) ===
								getUniqueVal(getOptionsValue(option))
							) {
								setFilterValue(undefined);
							} else {
								setFilterValue(getOptionsValue(option));
							}
						}}
					>
						{getFilterCounts !== null && (
							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
								fontSize="12px"
								color="text.secondary"
								ml={1}
								width={24}
							>
								{filterCounts
									? (filterCounts[getOptionsLabel(option)] ?? 0)
									: null}
							</Box>
						)}
						<Control size="small" checked={isChecked(option)} />
						{getOptionsLabel(option)}
					</DenseMenuItem>
				))}
			</DenseMenuList>
		</Box>
	);
}
