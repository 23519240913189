import type { paths } from "@/api/index";
import axios from "axios";

type getPDLInternalId = paths["/api/pdl/slug/{slug}/internal-id"]["get"];
export type getPDLInternalIdResponses = getPDLInternalId["responses"];
export type getPDLInternalIdResult =
	getPDLInternalIdResponses["200"]["content"]["application/json"];

export const getPDLCompanyIdBySlug = async (
	slug: string,
): Promise<getPDLInternalIdResult> => {
	const response = await axios.get<getPDLInternalIdResult>(
		`/api/pdl/slug/${slug}/internal-id`,
	);
	return response.data;
};
