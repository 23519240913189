import dayjs from "dayjs";
import React from "react";

import {
	useProcessActions,
	useProcessData,
} from "@/pages/ProcessManagement/ProcessContext";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import UnarchiveIcon from "@mui/icons-material/RestoreFromTrashOutlined";
import {
	Box,
	IconButton,
	Skeleton,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { getNameFieldValue, getPrimaryCompany } from "./ViewUtils";

export default function ArchivedView() {
	const {
		entityName,
		tasks,
		fields,
		isLoadingTasks: isLoading,
		isFetching,
	} = useProcessData();

	const { fetchNextPage, unarchiveTask } = useProcessActions();

	const parentRef = React.useRef();

	const onScrollBottomReached = React.useCallback(() => {
		fetchNextPage();
	}, [fetchNextPage]);

	const theme = useTheme();
	let defaultNegativeHeight = 350;
	if (theme.breakpoints.values.md < window.innerWidth) {
		defaultNegativeHeight = 250;
	}
	if (theme.breakpoints.values.sm < window.innerWidth) {
		defaultNegativeHeight = 150;
	}

	const handleScroll = React.useCallback(
		(containerRefElement?: HTMLDivElement | null) => {
			if (containerRefElement) {
				const { scrollTop, scrollHeight, clientHeight } = containerRefElement;
				if (scrollHeight - scrollTop - clientHeight < defaultNegativeHeight) {
					onScrollBottomReached?.();
				}
			}
		},
		[onScrollBottomReached, defaultNegativeHeight],
	);

	if (tasks) {
		tasks.sort(
			(a, b) => dayjs(b.archivedAt).valueOf() - dayjs(a.archivedAt).valueOf(),
		);
	}

	return (
		<Box
			ref={parentRef}
			display="flex"
			flexDirection="column"
			gap={1}
			height="calc(100vh - 204px)"
			overflow="auto"
			data-cy="archived-view"
		>
			{isLoading && (
				<Box display="flex" flexDirection="column" gap={1}>
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
					<Skeleton variant="rounded" height={40} />
				</Box>
			)}

			{!isLoading && (!tasks || tasks.length === 0) && (
				<Typography variant="body1">
					No archived {entityName.toLowerCase()}
					s.
				</Typography>
			)}

			{tasks && tasks.length > 0 && (
				<Typography variant="body2">
					<Box
						component="span"
						display="flex"
						flexDirection="row"
						alignItems="center"
					>
						Below is a list of archived {entityName.toLowerCase()}
						s, click
						<UnarchiveIcon />
						to restore a {entityName.toLowerCase()}.
					</Box>
				</Typography>
			)}

			<Box
				display="flex"
				flexDirection="column"
				gap={1}
				position="relative"
				overflow="auto"
				onScroll={(e) => handleScroll(e.target as HTMLDivElement)}
			>
				{isFetching && <CenteredProgress />}
				{tasks.map((task) => {
					const name =
						getNameFieldValue(task, fields) ||
						getPrimaryCompany(task, fields)?.name;
					return (
						<Box
							key={task.id}
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							alignItems="center"
							gap={1}
							data-cy="archived-task"
						>
							<Box
								display="flex"
								flexDirection="row"
								alignItems="center"
								gap={1}
							>
								<Typography variant="body1">{name}</Typography>
								<Typography variant="body2">
									{`archived ${dayjs.utc(task.archivedAt).fromNow()}`}
								</Typography>
							</Box>
							<Tooltip title={`Restore ${entityName}`} placement="left" arrow>
								<IconButton
									onClick={() => {
										unarchiveTask(task);
									}}
									data-cy={`unarchive-button-${name}`}
								>
									<UnarchiveIcon />
								</IconButton>
							</Tooltip>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
}
