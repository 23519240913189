import dayjs from "dayjs";

export function getStartAndEndDate(value) {
	const today = dayjs();
	switch (value) {
		case "last_6_months":
			return {
				startDate: today.subtract(6, "month").startOf("month"),
				endDate: today.subtract(1, "month").endOf("month"),
			};
		case "last_year":
			return {
				startDate: today.subtract(12, "month").startOf("month"),
				endDate: today.subtract(1, "month").endOf("month"),
			};
		case "last_2_years":
			return {
				startDate: today.subtract(24, "month").startOf("month"),
				endDate: today.subtract(1, "month").endOf("month"),
			};
		case "custom":
		case "all_time":
			return { startDate: null, endDate: null };
		default:
	}
	return {
		startDate: null,
		endDate: null,
	};
}

export default getStartAndEndDate;
