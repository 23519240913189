import type { Organization } from "@/api/Organization";
import { getProcessTasks } from "@/api/Process";
import ProcessForm from "@/components/ProcessForm";
import { TrackChanges } from "@mui/icons-material";
import { Box, Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useQuery } from "react-query";

import { canSeeProcess } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";

const HeaderMenuItem = styled(MenuItem)(({ theme }) => ({
	// caption style
	color: theme.palette.getContrastText(theme.palette.background.blue),
	background: theme.palette.background.blue,
	fontSize: "0.75rem",
	fontWeight: "bold",
	textTransform: "uppercase",
	"&:hover": {
		// remove hover styles
		backgroundColor: theme.palette.background.blue,
	},
}));

function ParentMenuItem({ children }) {
	return <HeaderMenuItem disabled>{children}</HeaderMenuItem>;
}

const ChildMenuItem = styled(MenuItem)(() => ({
	ml: 2,
}));

const NEW_OPPORTUNITY = Symbol("new-opportunity");
const NEW_REVGEN = Symbol("new-revgen");
const NEW_LEANGROWTH = Symbol("new-leangrowth");
const NEW_PORTFOLIO = Symbol("new-portfolio");
const EDIT_PORTFOLIO = Symbol("edit-portfolio");
const QUARTERLY_ASSESSMENT = Symbol("quarterly-assessment");
const ADDITIONAL_CAPITAL_DEPLOYMENT = Symbol("additional-capital-deployment");

export default function CRMDropdown({
	company,
}: {
	company: Organization;
}) {
	// get tasks from portfolio where company is primary
	const hardcodedFilters = {
		AND: {
			eq: {
				"tasks.fieldValues.384595e3-10d3-49df-8398-1412e9fdfdd1.company": [
					company.valorId,
				],
			},
		},
	};
	const {
		data: tasks,
		isLoading: tasksLoading,
		refetch,
	} = useQuery(
		["tasks", "portfolio", company.valorId],
		async () => getProcessTasks(["portfolio"], 1, null, null, hardcodedFilters),
		{
			enabled: !!company?.valorId,
		},
	);

	const portcoTask = React.useMemo(() => tasks?.data?.[0], [tasks]);
	const isPortco = React.useMemo(() => !!portcoTask, [portcoTask]);

	const anchorEl = React.useRef(null);
	const [open, setOpen] = React.useState(false);

	const { user } = useAuth();
	const hasDealAccess = canSeeProcess(user, "deals");
	const hasPortfolioAccess = canSeeProcess(user, "portfolio");
	const hasLeanGrowthAccess = canSeeProcess(user, "leangrowth");
	const hasRevGenAccess = canSeeProcess(user, "rev-gen");

	const [openForm, setOpenForm] = React.useState(null);

	return (
		<Box>
			<Button
				onClick={() => setOpen(true)}
				ref={anchorEl}
				startIcon={<TrackChanges />}
				fullWidth
				variant="outlined"
			>
				Edit CRM
			</Button>

			<Menu
				open={open}
				onClose={() => setOpen(false)}
				anchorEl={anchorEl.current}
			>
				<ParentMenuItem>Deals</ParentMenuItem>
				<ChildMenuItem
					disabled={!hasDealAccess}
					onClick={() => {
						setOpen(false);
						setOpenForm(NEW_OPPORTUNITY);
					}}
				>
					New Opportunity
				</ChildMenuItem>

				<ParentMenuItem>Lean Growth</ParentMenuItem>
				<ChildMenuItem
					disabled={!hasLeanGrowthAccess}
					onClick={() => {
						setOpen(false);
						setOpenForm(NEW_LEANGROWTH);
					}}
				>
					New LeanGrowth Project
				</ChildMenuItem>

				<ParentMenuItem>Rev Gen</ParentMenuItem>
				<ChildMenuItem
					disabled={!hasRevGenAccess}
					onClick={() => {
						setOpen(false);
						setOpenForm(NEW_REVGEN);
					}}
				>
					New RevGen Project
				</ChildMenuItem>

				<ParentMenuItem>Portfolio</ParentMenuItem>
				{!isPortco && (
					<ChildMenuItem
						disabled={!hasPortfolioAccess || tasksLoading}
						sx={{
							display: "flex",
							justifyContent: "space-between",
							gap: 1,
						}}
						onClick={() => {
							setOpen(false);
							setOpenForm(NEW_PORTFOLIO);
						}}
					>
						Portfolio Company Initial Setup
						{tasksLoading && <CircularProgress size={20} />}
					</ChildMenuItem>
				)}

				{isPortco && (
					<ChildMenuItem
						disabled={!hasPortfolioAccess}
						onClick={() => {
							setOpen(false);
							setOpenForm(EDIT_PORTFOLIO);
						}}
					>
						Portfolio Company Data
					</ChildMenuItem>
				)}

				<ChildMenuItem
					disabled={!hasPortfolioAccess || !isPortco}
					onClick={() => {
						setOpen(false);
						setOpenForm(QUARTERLY_ASSESSMENT);
					}}
				>
					Quarterly Assessment
				</ChildMenuItem>

				<ChildMenuItem
					disabled={!hasPortfolioAccess || !isPortco}
					onClick={() => {
						setOpen(false);
						setOpenForm(ADDITIONAL_CAPITAL_DEPLOYMENT);
					}}
				>
					Additional Capital Deployment
				</ChildMenuItem>
			</Menu>

			<ProcessForm
				processId="deals"
				valorId={company?.valorId}
				formType="new"
				open={openForm === NEW_OPPORTUNITY}
				onClose={() => {
					setOpen(false);
					setOpenForm(null);
				}}
			/>

			<ProcessForm
				processId="rev-gen"
				valorId={company?.valorId}
				formType="new"
				open={openForm === NEW_REVGEN}
				onClose={() => {
					setOpen(false);
					setOpenForm(null);
				}}
			/>

			<ProcessForm
				processId="leangrowth"
				valorId={company?.valorId}
				formType="new"
				open={openForm === NEW_LEANGROWTH}
				onClose={() => {
					setOpen(false);
					setOpenForm(null);
				}}
			/>

			<ProcessForm
				processId="portfolio"
				valorId={company?.valorId}
				formType="new"
				open={openForm === NEW_PORTFOLIO}
				onClose={() => {
					setOpen(false);
					setOpenForm(null);
					refetch();
				}}
				disabled={tasksLoading}
			/>

			<ProcessForm
				processId="portfolio"
				taskId={portcoTask?.id}
				formType="edit"
				open={openForm === EDIT_PORTFOLIO}
				onClose={() => {
					setOpen(false);
					setOpenForm(null);
					refetch();
				}}
			/>

			<ProcessForm
				processId="portfolio"
				formId="d5495316-7131-4612-97b7-73f8ade3b55e"
				formType="custom"
				taskId={portcoTask?.id}
				open={openForm === QUARTERLY_ASSESSMENT}
				onClose={() => {
					setOpen(false);
					setOpenForm(null);
				}}
			/>

			<ProcessForm
				processId="portfolio"
				formId="2b88ae61-1452-456a-8936-df8044eadaf1"
				formType="custom"
				taskId={portcoTask?.id}
				open={openForm === ADDITIONAL_CAPITAL_DEPLOYMENT}
				onClose={() => {
					setOpen(false);
					setOpenForm(null);
				}}
			/>
		</Box>
	);
}
