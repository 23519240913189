import { Box, Paper, Typography, useTheme } from "@mui/material";
import React from "react";

type TickProps = {
	x: number;
	y: number;
	payload: {
		value: number;
	};
	tickFormatter?: (value: number) => string;
};

export function CustomXTick({ x, y, payload, tickFormatter }: TickProps) {
	const theme = useTheme();
	const value = tickFormatter?.(payload.value) || payload.value;
	return (
		<g transform={`translate(${x},${y})`}>
			<text
				x={0}
				y={0}
				dy={16}
				textAnchor="middle"
				fill={theme.palette.text.primary}
			>
				{value}
			</text>
		</g>
	);
}
export function CustomYTick({ x, y, payload, tickFormatter }: TickProps) {
	const theme = useTheme();
	const value = tickFormatter?.(payload.value) || payload.value;
	return (
		<g transform={`translate(${x},${y})`}>
			<text
				x={0}
				y={0}
				dy="4"
				textAnchor="end"
				fill={theme.palette.text.primary}
			>
				{value}
			</text>
		</g>
	);
}

type TooltipProps = {
	active: boolean;
	payload: {
		value: number;
	}[];
	label: number;
	formatter?: (value: number) => [string, string];
	labelFormatter?: (label: number, payload: { value: number }[]) => string;
};

export function CustomTooltip({
	active,
	payload,
	label,
	formatter,
	labelFormatter,
}: TooltipProps) {
	if (active && payload && payload.length) {
		const [yVal, yUnit] = formatter?.(payload[0].value) || [
			payload[0].value,
			"",
		];
		const formattedLabel = labelFormatter?.(label, payload) || label;
		return (
			<Paper
				sx={{
					borderRadius: 0.5,
				}}
			>
				<Box padding={1}>
					<Typography variant="body1">{formattedLabel}</Typography>
					<Typography variant="body2">{`${yUnit}: ${yVal}`}</Typography>
				</Box>
			</Paper>
		);
	}
	return null;
}
