import { queryTaskComments } from "@/api/Process";
import TextCell from "@/components/Table/Primitives/TextCell";
import { useVisibility } from "@/hooks/useVisibility"; // Adjust the import path as needed
import { Skeleton } from "@mui/material";
import React, { useRef } from "react";
import { useQuery } from "react-query";

export default function NoteCell({
	processId,
	taskId,
}: {
	processId?: string;
	taskId?: string;
}) {
	const cellRef = useRef<HTMLDivElement>(null);
	const isVisible = useVisibility(cellRef);

	const { data: comments = [], isLoading: commentLoading } = useQuery(
		["Comments", processId, taskId],
		() => queryTaskComments(processId, taskId, 1),
		{
			enabled: !!processId && !!taskId && isVisible,
			refetchInterval: false,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		},
	);

	if (!isVisible) {
		return <div ref={cellRef}>-</div>;
	}

	if (commentLoading) {
		return (
			<div
				ref={cellRef}
				style={{
					height: "100%",
					display: "flex",
					alignItems: "center",
				}}
			>
				<Skeleton variant="text" width={100} />
			</div>
		);
	}

	if (!comments.length) {
		return (
			<div
				ref={cellRef}
				style={{
					height: "100%",
				}}
			>
				-
			</div>
		);
	}

	return (
		<div
			ref={cellRef}
			style={{
				height: "100%",
			}}
		>
			<TextCell value={comments[0]?.comment} />
		</div>
	);
}
