import type { FieldList, Task } from "@/api/Process";
import type React from "react";
import { Draggable } from "react-beautiful-dnd";

import BoardTask from "./BoardTask";

type BoardTaskProps = {
	processId: string;
	groupedByFieldId: string;
	fields: FieldList;
	task: Task;
	index: number;
	onClick: (e: React.MouseEvent) => void;
};

export default function DraggableBoardTask({
	groupedByFieldId,
	fields,
	task,
	index,
	onClick,
	onMoveToStart,
	onMoveToEnd,
}: BoardTaskProps) {
	return (
		<Draggable draggableId={task.id.toString()} index={index}>
			{(provided) => (
				<div
					ref={provided.innerRef}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...provided.draggableProps}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...provided.dragHandleProps}
				>
					<BoardTask
						groupedByFieldId={groupedByFieldId}
						fields={fields}
						task={task}
						onClick={onClick}
						onMoveToStart={onMoveToStart}
						onMoveToEnd={onMoveToEnd}
						draggable
					/>
				</div>
			)}
		</Draggable>
	);
}
