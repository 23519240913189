import { getConversations } from "@/api/Chat";
import useDebounce from "@/hooks/useDebounce";
import renderTextWithHighlighting from "@/utils/renderTextWithHighlighting";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Skeleton, TextField } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

export default function Search() {
	const navigate = useNavigate();

	const [search, setSearch] = React.useState("");
	const debouncedQuery = useDebounce(search, 300);

	const { data: chats = [], isLoading: chatsLoading } = useQuery(
		["conversations", debouncedQuery],
		() => getConversations(debouncedQuery),
	);

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			gap={5}
			padding={1}
			width={600}
			maxWidth="100%"
			margin="auto"
			my={6}
		>
			<TextField
				label="Search your chats..."
				variant="outlined"
				fullWidth
				size="small"
				InputProps={{
					startAdornment: <SearchIcon />,
				}}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
			/>

			<Box
				display="flex"
				flexDirection="column"
				gap={2}
				width="100%"
				maxWidth="100%"
			>
				{chats.map((chat) => (
					<Box
						key={chat.id}
						padding={1}
						borderRadius={1}
						sx={{
							"&:hover": {
								backgroundColor: "rgba(0, 0, 0, 0.05)",
							},
							cursor: "pointer",
						}}
						onClick={() => {
							navigate(`/grok/chat/${chat.id}`);
						}}
					>
						{renderTextWithHighlighting(chat.title, search)}
					</Box>
				))}

				{chatsLoading && (
					<>
						<Skeleton variant="rounded" height={40} />
						<Skeleton variant="rounded" height={40} />
						<Skeleton variant="rounded" height={40} />
						<Skeleton variant="rounded" height={40} />
					</>
				)}
			</Box>
		</Box>
	);
}
