import { Box, Chip, ClickAwayListener } from "@mui/material";
import React, { useState } from "react";

export default function InvestorsCell({
	nameToOmit,
	info: { getValue },
}: {
	nameToOmit: string;
	info: Info;
}) {
	const value = getValue();
	const filteredValues = value?.filter((investor) => investor !== nameToOmit);

	const hasValues = filteredValues?.length > 0;
	const hasOverflow = filteredValues?.length > 1;
	const [showOverflow, setShowOverflow] = useState(false);

	if (filteredValues?.length === 0) {
		return <>-</>;
	}

	return (
		<ClickAwayListener onClickAway={() => setShowOverflow(false)}>
			<Box height="100%" maxWidth="150px" position="relative">
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					gap={1}
					overflow="hidden"
					height="100%"
					visibility={showOverflow ? "hidden" : "visible"}
					sx={{
						cursor: "pointer",
					}}
					onClick={() => setShowOverflow(hasOverflow)}
				>
					{hasValues && (
						<Chip
							label={filteredValues[0]}
							size="small"
							sx={{
								cursor: "pointer",
								maxWidth: hasOverflow ? "100px" : "auto",
							}}
						/>
					)}
					{hasOverflow && (
						<Chip label={`+${filteredValues.length - 1}`} size="small" />
					)}
				</Box>
				<Box
					position="absolute"
					top={0}
					left={0}
					display="flex"
					flexDirection="row"
					flexWrap="wrap"
					gap={1}
					visibility={showOverflow ? "visible" : "hidden"}
					bgcolor="background.paper"
					border={(theme) => `1px solid ${theme.palette.divider}`}
					padding={1}
					borderRadius={1}
					zIndex={1}
				>
					{filteredValues?.map((investor) => (
						<Chip key={investor} label={investor} size="small" />
					))}
				</Box>
			</Box>
		</ClickAwayListener>
	);
}
