import type { ResponsesSearchResult } from "@/api/FrameworkSurvey";
import MoneyCell from "@/components/Table/Primitives/MoneyCell";
import TextCell from "@/components/Table/Primitives/TextCell";
import { Box } from "@mui/material";
import React from "react";
import ChoiceCell from "../ChoiceCell";
import { P2P_METRICS_QUESTIONS } from "../constants";
import type matchSurveys from "../utils/surveyMatchers";

const getAnswer = (survey, questionsIds) => {
	if (!survey) {
		return null;
	}
	const answers = Object.values(survey.answers);
	const foundAnswer = answers.find((answer) =>
		questionsIds.includes(answer.question.id),
	);

	return foundAnswer;
};

const useP2PMetricsTable = (activeSurvey: ResponsesSearchResult[0][0]) => {
	// build data for the table
	const data = React.useMemo(() => {
		if (!activeSurvey) {
			return [];
		}

		const p2pQuestions = P2P_METRICS_QUESTIONS.map((group) => {
			const colorQuestions = group.find(
				(attr) => attr.questionType === "MULTIPLE_CHOICE",
			);
			const colorAnswer = getAnswer(activeSurvey, colorQuestions.questionIds);

			const commentaryQuestions = group.find(
				(attr) => attr.questionType === "RICH_TEXT",
			);
			const commentaryAnswer = getAnswer(
				activeSurvey,
				commentaryQuestions.questionIds,
			);

			const initialDollarQuestions = group.find(
				(attr) => attr.questionType === "DOLLAR_ESTIMATE" && !attr.compareId,
			);
			const initialAnswer = getAnswer(
				activeSurvey,
				initialDollarQuestions.questionIds,
			);
			const latestDollarQuestions = group.find(
				(attr) =>
					attr.questionType === "DOLLAR_ESTIMATE" &&
					attr.compareId === "updatedValue",
			);
			const latestAnswer = getAnswer(
				activeSurvey,
				latestDollarQuestions.questionIds,
			);

			if (
				!colorAnswer ||
				!commentaryAnswer ||
				!initialAnswer ||
				!latestAnswer
			) {
				// old platform surveys that are missing these questions might exist
				// return null and handle in the component
				return null;
			}

			let initialValue = initialAnswer
				? Number(initialAnswer.answerData)
				: null;
			let latestValue = latestAnswer ? Number(latestAnswer.answerData) : null;

			if (Number.isNaN(initialValue)) {
				initialValue = null;
			}
			if (Number.isNaN(latestValue)) {
				latestValue = null;
			}

			const delta =
				initialValue !== null && latestValue !== null
					? latestValue - initialValue
					: null;
			const deltaPercent =
				initialValue !== null && latestValue !== null
					? (latestValue - initialValue) / initialValue
					: null;

			return {
				groupName: group[0].name,
				choice: colorAnswer.choice,
				commentary: commentaryAnswer.commentary,
				initialValue,
				latestValue,
				delta,
				deltaPercent,
			};
		}).filter((n) => n);
		return p2pQuestions;
	}, [activeSurvey]);

	const columns = React.useMemo(() => {
		return [
			{
				header: "P2P Metric",
				accessorKey: "groupName",
				cell: (prop) => <TextCell value={prop.getValue()} />,
			},
			{
				header: "Choice",
				accessorKey: "choice",
				cell: (prop) => <ChoiceCell value={prop.getValue()} />,
			},
			{
				header: "Commentary",
				accessorKey: "commentary",
				cell: (prop) => <TextCell value={prop.getValue()} />,
			},
			{
				header: "OS Forecast",
				accessorKey: "initialValue",
				cell: (prop) => <MoneyCell value={prop.getValue()} />,
			},
			{
				header: "Updated Metric",
				accessorKey: "latestValue",
				cell: (prop) => <MoneyCell value={prop.getValue()} />,
			},
			{
				header: "Delta",
				accessorKey: "delta",
				cell: (prop) => <MoneyCell value={prop.getValue()} />,
			},
			{
				header: "Delta Percent",
				accessorKey: "deltaPercent",
				cell: (prop) => {
					const prettyPercent = prop.getValue()
						? `${(prop.getValue() * 100).toFixed(2)}%`
						: "-";
					return <TextCell value={prettyPercent} />;
				},
			},
		];
	}, []);

	return {
		data,
		columns,
	};
};

export default useP2PMetricsTable;
