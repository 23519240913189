import { canSeeVPO, canSendVPOReports } from "@/constants/Roles";
import MultiSelect from "@/ui/atoms/MultiSelect";
import SingleSelect from "@/ui/atoms/SingleSelect";
import CustomTable from "@/ui/molecules/CustomTable";
import Page from "@/ui/molecules/Page";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	TextField,
	useTheme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Link } from "@mui/material";
import dayjs from "dayjs";
import React, { useState, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { useQuery } from "react-query";

import {
	getApplicationsAggregatedBy,
	getCandidatePipeline,
	getCompaniesStats,
	getInternalJobsStats,
	getJobs,
	getLastTimeReportsDistributed,
	getPortcoJobsStats,
	getSourcesPerJob,
	sendOutReports,
	updateColor,
	updateLevelOfSupport,
	updateRecruitmentStage,
} from "@/api/VPO";
import { useAuth } from "@/hooks/useAuth";
import { useLocation } from "react-router-dom";
import {
	CartesianGrid,
	Cell,
	Funnel,
	FunnelChart,
	Label,
	LabelList,
	Legend,
	Line,
	LineChart,
	Pie,
	PieChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import { useQueryParam } from "use-query-params";
import { ColoredSelect } from "./ColoredSelect";

const useStyles = makeStyles((theme) =>
	createStyles({
		header: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
		},
		link: {
			color: theme.palette.text.primary,
			fontWeight: theme.typography.fontWeightMedium,
		},
	}),
);

const portcoRecruitmentStages = [
	null,
	"Following",
	"Fit",
	"Company Call / Selling",
	"Engagement Letter",
	"Kickoff",
	"Active",
	"Interviewing Candidates",
	"On-Hold",
	"Canceled",
	"Closed",
];

const supportOptions = [
	null,
	"Full Recruitment",
	"Testing/Technical Interviews",
	"Mid Funnel",
	"Top of Funnel Candidate Flow",
];

const reportsLabels = {
	vpo_referrals: "VPO Referrals",
	vpo_ag_referrals: "VPO AG Referrals",
	vpo_hiring_manager_dashboard: "VPO Hiring Manager Dashboard",
};

const allReports = [
	"vpo_referrals",
	"vpo_ag_referrals",
	"vpo_hiring_manager_dashboard",
];

export function TalentAcquisition() {
	const { user } = useAuth();
	const classes = useStyles();
	const theme = useTheme();
	const indexToColor = theme.palette.vpo;

	// Override Report Distribution
	const [selectedReports, setSelectedReports] = useState(allReports);
	const [recipient, setRecipient] = useState();
	const [CCS, setCCS] = useState();

	const { hash } = useLocation();
	const [jobId] = useQueryParam("job-id");

	const [openReport, setOpenReport] = useState(false);
	const [selectedJob, setSelectedJob] = useState("");
	const [applicationTrendsGroupBy, setApplicationTrendsGroupBy] =
		useState("week");
	const [status, setStatus] = useState("open");

	const [, setInternalJobsStats] = useState([]);
	const [portcoJobsStats, setPortcoJobsStats] = useState([]);

	const { isLoading: isLoadingInternalJobsStats } = useQuery(
		["InternalJobsStats", status],
		async () => getInternalJobsStats(status),
		{
			onSuccess: setInternalJobsStats,
		},
	);

	useQuery(
		["PortcoJobsStats", status],
		async () => getPortcoJobsStats(status),
		{
			onSuccess: setPortcoJobsStats,
		},
	);

	const { data: jobs = [], isLoading: isLoadingJobs } = useQuery(
		["Jobs", status],
		async () => getJobs(status),
	);
	const { data: companiesStats = [] } = useQuery(
		["CompaniesStats", status],
		async () => getCompaniesStats(status),
	);
	const {
		data: candidatePipeline = [],
		isLoading: isLoadingCandidatePipeline,
	} = useQuery(["CandidatePipeline-1"], getCandidatePipeline);
	const { data: sourcesPerJob = [] } = useQuery(
		["SourcesPerJob"],
		getSourcesPerJob,
	);
	const { data: applicationsPerMonth = [] } = useQuery(
		["ApplicationsPerMonth", applicationTrendsGroupBy],
		async () => getApplicationsAggregatedBy(applicationTrendsGroupBy),
	);
	const { data: lastTimeReportsDistributed } = useQuery(
		["LastTimeReportsDistributed", status],
		getLastTimeReportsDistributed,
	);

	const isLoading = isLoadingInternalJobsStats;

	const updatedOld = (old, rowIndex, columnId, value) =>
		old.map((row, index) => {
			if (index === rowIndex) {
				return {
					...old[rowIndex],
					[columnId]: value,
				};
			}
			return row;
		});

	useEffect(() => {
		if (hash) {
			const element = document.getElementById(hash.slice(1));
			element?.scrollIntoView({ behavior: "smooth" });
		}
	}, [hash, isLoading]);

	useEffect(() => {
		if (jobId) {
			setTimeout(() => {
				setSelectedJob(Number(jobId));
			}, 1000);
		}
	}, [jobId, isLoadingJobs, isLoadingCandidatePipeline]);

	const companiesStatsColumns = React.useMemo(
		() => [
			{
				Header: "Company",
				accessor: "company",
				disableFilters: true,
			},
			{
				Header: "Job Count",
				accessor: "jobCount",
				disableFilters: true,
			},
			{
				Header: "Total",
				accessor: "totalCandidates",
				disableFilters: true,
			},
			{
				Header: "Active",
				accessor: "activeCandidates",
				disableFilters: true,
			},
			{
				Header: "Presented",
				accessor: "presentedCandidates",
				disableFilters: true,
			},
			{
				Header: "Offer",
				accessor: "receivedOffer",
				disableFilters: true,
			},
			{
				Header: "Hired",
				accessor: "hiredCandidates",
				disableFilters: true,
			},
			{
				Header: "Avg Conversion Rate",
				accessor: (row) =>
					`${((row.hiredCandidates / (row.presentedCandidates === 0 ? 1 : row.presentedCandidates)) * 100).toFixed(2)}%`,
				disableFilters: true,
			},
		],
		[],
	);

	const RYGSortingMap = {
		green: 0,
		yellow: 1,
		red: 2,
	};

	const RYGSort = useMemo(
		() => (rowA, rowB, columnId, desc) => {
			const a = rowA.values[columnId];
			const b = rowB.values[columnId];

			if (a === null) {
				// we always want the nulls to be at the bottom
				return desc ? -1 : 1;
			}
			if (b === null) {
				return desc ? 1 : -1;
			}
			return RYGSortingMap[a] > RYGSortingMap[b] ? 1 : -1;
		},
		[],
	);

	const portcoJobsStatsColumns = React.useMemo(
		() => [
			{
				Header: "Company",
				accessor: "company",
				disableFilters: true,
			},
			{
				Header: "Job Title",
				accessor: "title",
				Cell: ({ value: initialValue, row }) => (
					<Link
						sx={{
							color: theme.palette.text.primary,
							fontWeight: theme.typography.fontWeightMedium,
						}}
						href={`https://app5.greenhouse.io/sdash/${row.original.id}`}
						target="_blank"
						rel="noreferrer"
					>
						{initialValue}
					</Link>
				),
				disableFilters: true,
			},
			{
				Header: "Department",
				accessor: "department",
				disableFilters: true,
			},
			{
				Header: "Stage of Recruitment",
				accessor: "recruitmentStage",
				Cell: ({ value: initialValue, row: { index }, column: { id } }) => {
					// We need to keep and update the state of the cell normally
					const [value, setValue] = useState(initialValue);

					const onChange = (newValue) => {
						setValue(newValue);
						setPortcoJobsStats((old) => {
							updateRecruitmentStage(old[index].id, newValue);
							return updatedOld(old, index, id, newValue);
						});
					};

					// If the initialValue is changed external, sync it up with our state
					useEffect(() => {
						setValue(initialValue);
					}, [initialValue]);

					return (
						<SingleSelect
							width={200}
							id={`column-select-portco-recruitment-stage-${index}`}
							value={value}
							variant="standard"
							onChange={onChange}
							options={portcoRecruitmentStages}
						/>
					);
				},
				disableFilters: true,
			},
			{
				Header: "Level of Support",
				accessor: "levelOfSupport",
				Cell: ({ value: initialValue, row: { index }, column: { id } }) => {
					// We need to keep and update the state of the cell normally
					const [value, setValue] = useState(initialValue);

					const onChange = (newValue) => {
						setValue(newValue);
						setPortcoJobsStats((old) => {
							try {
								updateLevelOfSupport(old[index].id, newValue);
							} catch (e) {
								console.error(e);
							}
							return updatedOld(old, index, id, newValue);
						});
					};

					// If the initialValue is changed external, sync it up with our state
					useEffect(() => {
						setValue(initialValue);
					}, [initialValue]);

					return (
						<SingleSelect
							width={200}
							id={`column-select-level-of-support-${index}`}
							value={value}
							variant="standard"
							onChange={onChange}
							options={supportOptions}
						/>
					);
				},
				disableFilters: true,
			},
			{
				Header: "RYG",
				accessor: "color",
				sortType: RYGSort,
				Cell: ({ value: initialValue, row: { index }, column: { id } }) => {
					// We need to keep and update the state of the cell normally
					const [value, setValue] = useState(initialValue);

					const onChange = (newValue) => {
						setValue(newValue);
						setPortcoJobsStats((old) => {
							updateColor(old[index].id, newValue);
							return updatedOld(old, index, id, newValue);
						});
					};

					// If the initialValue is changed external, sync it up with our state
					useEffect(() => {
						setValue(initialValue);
					}, [initialValue]);

					return (
						<ColoredSelect
							value={value}
							onChange={onChange}
							id={`column-select-portco-ryg-${index}`}
						/>
					);
				},
				disableFilters: true,
			},
			{
				Header: "Days Open",
				accessor: "daysOpen",
				disableFilters: true,
			},
			{
				Header: "Total",
				accessor: "totalCandidates",
				disableFilters: true,
			},
			{
				Header: "Active",
				accessor: "activeCandidates",
				disableFilters: true,
			},
			{
				Header: "Presented",
				accessor: "presentedCandidates",
				disableFilters: true,
			},
			{
				Header: "Offer",
				accessor: "receivedOffer",
				disableFilters: true,
			},
			{
				Header: "Hired",
				accessor: "hiredCandidates",
				disableFilters: true,
			},
			{
				Header: "Avg Conversion Rate",
				accessor: (row) =>
					`${(
						(row.hiredCandidates /
							(row.presentedCandidates === 0 ? 1 : row.presentedCandidates)) *
							100
					).toFixed(2)}%`,
				disableFilters: true,
			},
		],
		[],
	);

	if (!canSeeVPO(user)) {
		return (
			<Box textAlign="center" marginTop={3}>
				<h3>You are not authorized to view this page.</h3>
			</Box>
		);
	}

	const closeReportDialog = () => {
		setOpenReport(false);
		setSelectedReports(allReports);
		setRecipient(null);
		setCCS(null);
	};

	const filterCondition = (o) => o.company !== "Valor Equity Partners";

	return (
		<Page data-cy="page__talent_acquisition">
			<Helmet>
				<title>Talent Acquisition - vOS</title>
			</Helmet>
			<Page.Title>Talent Acquisition</Page.Title>
			<Page.Content>
				<Box className={classes.header}>
					{canSendVPOReports(user) && (
						<Button
							variant="outlined"
							color="primary"
							onClick={() => setOpenReport(true)}
						>
							{" "}
							Send Reports{" "}
						</Button>
					)}
				</Box>
				<Box style={{ display: "flex", justifyContent: "space-between" }}>
					<SingleSelect
						id="single-select-status"
						value={status}
						title="Status"
						onChange={(value) => {
							setStatus(value);
							setSelectedJob(null);
						}}
						options={[
							{ key: null, label: "All" },
							{ key: "open", label: "Open" },
							{ key: "closed", label: "Closed" },
						]}
					/>
				</Box>
				<Box marginTop={1}>
					<CustomTable
						title="Companies"
						headerBackgroundColor={theme.palette.blue.main}
						headerTextColor={theme.palette.getContrastText(
							theme.palette.blue.main,
						)}
						pageSize={25}
						data={companiesStats}
						columns={companiesStatsColumns}
					/>
					<Box marginTop={1}>
						<CustomTable
							title="Portfolio Companies Jobs"
							headerBackgroundColor={theme.palette.blue.main}
							headerTextColor={theme.palette.getContrastText(
								theme.palette.blue.main,
							)}
							pageSize={25}
							data={portcoJobsStats}
							columns={portcoJobsStatsColumns}
						/>
					</Box>
				</Box>
				<Box>
					<SingleSelect
						width={400}
						id="jobs"
						title="Jobs"
						value={selectedJob}
						onChange={setSelectedJob}
						options={jobs.filter((job) => filterCondition(job))}
						getKey={(job) => job.id}
						getLabel={(job) =>
							`${job.company} - ${job.name} (${job.department})`
						}
					/>
					<div>
						<Grid container spacing={1}>
							<Grid item xs={12} md={7}>
								<Box
									id="candidate-pipeline"
									borderRadius="10px"
									border={1}
									borderColor={theme.palette.divider}
									bgcolor="background.paper"
									height={460}
								>
									{selectedJob && (
										<ResponsiveContainer width="99%" height={460}>
											<FunnelChart
												height={460}
												margin={{ right: 20, left: 20, marginBottom: 5 }}
											>
												<Tooltip />
												<Funnel
													dataKey="candidates"
													nameKey="stage"
													data={candidatePipeline[selectedJob]?.map(
														(item, index) => ({
															...item,
															fill: indexToColor[index],
														}),
													)}
													isAnimationActive
												>
													<LabelList
														position="right"
														fill="#000"
														stroke="none"
														valueAccessor={(v) =>
															`${v.stage} - ${v.candidates}`
														}
													/>
												</Funnel>
											</FunnelChart>
										</ResponsiveContainer>
									)}
								</Box>
							</Grid>
							<Grid item xs={12} md={5}>
								<Box
									borderRadius="10px"
									border={1}
									borderColor={theme.palette.divider}
									bgcolor="background.paper"
									height={460}
								>
									{selectedJob && (
										<ResponsiveContainer width="99%" height={460}>
											<PieChart height={460} margin={{ left: 20 }}>
												<Pie
													dataKey="candidates"
													nameKey="source"
													data={sourcesPerJob[selectedJob]?.map((job) => ({
														...job,
														source:
															job.source === "Jobs page on your website"
																? "LinkedIn"
																: job.source,
													}))}
													cx="50%"
													cy="50%"
													outerRadius={140}
													fill={theme.palette.blue.light}
													label
												>
													{sourcesPerJob[selectedJob]?.map((_, index) => (
														<Cell
															key={`cell-${indexToColor[index]}`}
															fill={indexToColor[index]}
														/>
													))}
												</Pie>
												<Tooltip />
												<Legend
													layout="vertical"
													verticalAlign="center"
													align="right"
												/>
											</PieChart>
										</ResponsiveContainer>
									)}
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box
									borderRadius="10px"
									border={1}
									borderColor={theme.palette.divider}
									bgcolor="background.paper"
									height={405}
								>
									<Box
										style={{ display: "flex", justifyContent: "space-between" }}
									>
										<h3 style={{ marginLeft: "8px" }}>Application Trends</h3>
										<SingleSelect
											width={200}
											id="application-trends-group-by"
											title="Group By"
											value={applicationTrendsGroupBy}
											onChange={setApplicationTrendsGroupBy}
											options={[
												{ key: "month", label: "Month" },
												{ key: "week", label: "Week" },
												{ key: "day", label: "Day" },
											]}
										/>
									</Box>
									{selectedJob && (
										<ResponsiveContainer width="99%" height={350}>
											<LineChart
												width={1200}
												height={350}
												margin={{
													top: 5,
													right: 5,
													left: 5,
													bottom: 5,
												}}
												data={applicationsPerMonth[selectedJob]}
											>
												<CartesianGrid strokeDasharray="3 3" />
												<XAxis allowDataOverflow dataKey="month" />
												<YAxis type="number" yAxisId="1">
													<Label
														value="Applications"
														position="insideLeft"
														angle={-90}
														style={{ textAnchor: "middle" }}
													/>
												</YAxis>
												<Tooltip />
												<Line
													yAxisId="1"
													type="monotone"
													strokeWidth={2}
													dataKey="candidates"
													stroke={theme.palette.primary.main}
													animationDuration={300}
												/>
											</LineChart>
										</ResponsiveContainer>
									)}
								</Box>
							</Grid>
						</Grid>
					</div>
				</Box>
				<Dialog
					open={openReport}
					onClose={() => closeReportDialog()}
					aria-labelledby="responsive-dialog-title"
				>
					<DialogTitle id="responsive-dialog-title">
						Are you sure you want to send out the VPO reports?
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							The last time reports were sent out was on{" "}
							{dayjs(lastTimeReportsDistributed).format("hh:mmA MMM DD, YYYY")}
							.
							<br />
						</DialogContentText>
						<div>Override Default Values</div>
						<MultiSelect
							allValues={allReports}
							value={selectedReports}
							title="Reports"
							maxWidth={1000}
							style={{ width: "100%", marginBottom: "8px" }}
							getLabel={(value) => reportsLabels[value]}
							onChange={setSelectedReports}
						/>
						<TextField
							value={recipient}
							onChange={(e) => setRecipient(e.target.value)}
							style={{ width: "100%", marginBottom: "8px" }}
							id="recipient"
							variant="outlined"
							label="Recipient"
						/>
						<TextField
							value={CCS}
							placeholder="jshulkin@valorep.com,kgeoghegan@valorep.com"
							onChange={(e) => setCCS(e.target.value)}
							style={{ width: "100%" }}
							id="ccs"
							variant="outlined"
							label="CCS (Comma-separated emails)"
						/>
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={closeReportDialog} color="primary">
							Cancel
						</Button>
						<Button
							onClick={() => {
								sendOutReports(recipient, CCS, selectedReports.join(","));
								closeReportDialog();
							}}
							color="primary"
						>
							Yes Send Reports
						</Button>
					</DialogActions>
				</Dialog>
			</Page.Content>
		</Page>
	);
}

export default TalentAcquisition;
