import { listEvaluationCriteria } from "@/api/Chat";
import {
	Alert,
	Box,
	CircularProgress,
	Container,
	Typography,
} from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import CriterionCard from "./CriteriaCard";

const CriteriaList = () => {
	const {
		data: criteria,
		isLoading,
		error,
	} = useQuery("criteria", listEvaluationCriteria);

	if (isLoading) {
		return (
			<Box display="flex" justifyContent="center" m={4}>
				<CircularProgress />
			</Box>
		);
	}

	if (error) {
		return (
			<Box m={4}>
				<Alert severity="error">
					Error loading criteria: {(error as Error).message}
				</Alert>
			</Box>
		);
	}

	return (
		<Container maxWidth="md">
			<Typography variant="h4" component="h1" gutterBottom sx={{ my: 4 }}>
				Evaluation Criteria
			</Typography>

			{criteria?.length === 0 ? (
				<Typography color="text.secondary">No criteria found.</Typography>
			) : (
				<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
					{criteria?.map((criterion) => (
						<CriterionCard
							key={criterion.id}
							id={criterion.id}
							name={criterion.name}
							description={criterion.description}
							scoringType={criterion.scoringType}
							minScore={criterion.minScore}
							maxScore={criterion.maxScore}
							categories={criterion.categories}
						/>
					))}
				</Box>
			)}
		</Container>
	);
};

export default CriteriaList;
