import WarningIcon from "@mui/icons-material/Warning";
import { Box, Link, Typography } from "@mui/material";
import React from "react";

export default function PageNotFound() {
	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height="100%"
		>
			<WarningIcon
				sx={{
					fontSize: 60,
				}}
			/>
			<Typography variant="h1">Not Found</Typography>
			<Typography variant="body1">
				Click <Link href="/home">here</Link> to go back to the home page.
			</Typography>
		</Box>
	);
}
