import { type Draft, patchResponse } from "@/api/FrameworkSurvey";
import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";
import {
  canEditAllSurveys,
  canEditDraftCollabSurveys,
} from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import { usePopover } from "@/hooks/useGlobalPopover";
import UserAvatar from "@/ui/atoms/UserAvatar";
import { CloseRounded, MoreVert as MoreVertIcon } from "@mui/icons-material";
import {
  AvatarGroup,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { useRef } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

interface DraftCardProps {
  draft: Draft;
  valorId: string;
}

interface DraftGridProps {
  drafts: Draft[];
  valorId: string;
}

function DraftCard({ draft, valorId }: DraftCardProps) {
  const navigate = useNavigate();
  const ref = useRef(null);
  const { user } = useAuth();
  const { openPopover, closePopover } = usePopover();
  const queryClient = useQueryClient();
  const canEditAll = canEditAllSurveys(user);
  const canEditDraftCollab = canEditDraftCollabSurveys(user, draft);

  const shouldDisableDelete = draft.authorUserId !== user.id;

  const canEditSurvey =
    canEditAll ||
    (canEditDraftCollab && draft.allowCollaborators) ||
    draft.authorUserId === user.id;

  const deleteDraft = async () => {
    await patchResponse(draft.id, {
      hidden: true,
    });
    queryClient.invalidateQueries(["DraftResponses", valorId]);
  };

  const DeleteDraft = (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flex={1}
    >
      <Typography variant="h3">Delete Draft?</Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        gap={2}
      >
        <Box display="flex" gap={2}>
          {shouldDisableDelete ? (
            <Tooltip
              title={`Only the author (${draft.author.firstName} ${draft.author.lastName}) can delete this draft `}
              arrow
            >
              <Box>
                <Button
                  variant="outlined"
                  disabled={canEditAll ? false : shouldDisableDelete}
                  color="error"
                  onClick={deleteDraft}
                >
                  <CloseRounded />
                </Button>
              </Box>
            </Tooltip>
          ) : (
            <Button variant="outlined" color="error" onClick={deleteDraft}>
              <CloseRounded />
            </Button>
          )}

          <Button onClick={() => closePopover()} variant="outlined">
            Cancel
          </Button>
        </Box>
        <Typography>This can be undone by the labs team.</Typography>
      </Box>
    </Box>
  );

  return (
    <Card
      sx={{
        padding: 1,
        height: "fit-content",
        width: "fit-content",
      }}
      ref={ref}
    >
      <CardHeader
        title={`${InvestmentProductMappings[draft.department]} ${draft.name}`}
        subheader={`Last saved: ${dayjs
          .utc(draft.lastModified)
          .local()
          .format("MM/DD/YYYY h:mm a")}`}
        action={
          <IconButton
            aria-label="settings"
            onClick={() => openPopover(ref, DeleteDraft)}
            disabled={shouldDisableDelete && !canEditAll}
          >
            <MoreVertIcon />
          </IconButton>
        }
      />
      <CardContent>
        <AvatarGroup max={4}>
          {draft.collaborators?.map((collaborator) => (
            <UserAvatar key={collaborator.id} user={collaborator} />
          ))}
        </AvatarGroup>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          sx={{ width: "100%" }}
          disabled={!canEditSurvey}
          onClick={() =>
            navigate(
              `/org/${valorId}/surveys/${draft.surveyId}?responseId=${draft.id}`
            )
          }
        >
          Edit
        </Button>
        {/* {shouldDisableDelete ? (
          <Tooltip
            title={`Only the author (${draft.author.firstName} ${draft.author.lastName}) can delete this draft `}
            arrow
          >
            <Box>
              <Button
                variant="outlined"
                disabled={canEditAll ? false : shouldDisableDelete}
                color="error"
                onClick={() => openPopover(ref, DeleteDraft)}
              >
                <CloseRounded />
              </Button>
            </Box>
          </Tooltip>
        ) : (
          <Button
            variant="outlined"
            color="error"
            onClick={() => openPopover(ref, DeleteDraft)}
          >
            <CloseRounded />
          </Button>
        )} */}
      </CardActions>
    </Card>
  );
}
export const DraftGrid: React.FC<DraftGridProps> = ({ drafts, valorId }) => {
  return (
    <Box
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: {
          xs: "1fr",
          sm: "repeat(auto-fill, minmax(320px, 1fr))",
        },
      }}
    >
      {drafts.map((draft) => (
        <DraftCard key={draft.id} draft={draft} valorId={valorId} />
      ))}
    </Box>
  );
};

export default DraftGrid;
