import type { SignalFeatures } from "@/api/SignalFeatures";
import Progress from "@/ui/atoms/Progress";
import { Alert, Box, Popover, Tooltip, Typography } from "@mui/material";
import React, { useRef, useState } from "react";

import analytics from "@/shared/analytics";
import BarChart from "./BarChart";
import HistChart from "./HistChart";
import useDistribution from "./useDistribution";
import useFeature from "./useFeature";

type ExplainabilityChipProps = {
	modelId: string;
	indexId: string;
	feature: SignalFeatures["data"][0];
	dense?: boolean;
	showAsPositiveOrNegative?: boolean;
};

export function DumbChip({
	feature,
	onClick,
	dense = false,
	distributionLoading = false,
	showAsPositiveOrNegative = false,
}: {
	feature: SignalFeatures["data"][0];
	onClick: () => void;
	dense?: boolean;
	distributionLoading?: boolean;
	showAsPositiveOrNegative?: boolean;
}) {
	const [
		{
			Icon,
			value: transformedValue,
			isOutlier,
			OutlierIcon,
			outlierIconColor,
			isPositiveContributor,
		},
	] = useFeature(feature);

	let borderColor = null;
	let color = null;
	if (showAsPositiveOrNegative) {
		if (!isPositiveContributor) {
			color = (theme) => theme.palette.error.main;
			borderColor = (theme) => `1px solid ${theme.palette.error.main}`;
		}
	}

	if (dense) {
		return (
			<Tooltip title={feature.name} placement="top">
				<Box
					key={feature.id}
					component="div"
					display="flex"
					flexDirection="row"
					alignItems="center"
					gap={1}
					borderRadius={1}
					padding={1}
					border={(theme) => `1px solid ${theme.palette.divider}`}
					maxHeight={40}
					sx={{
						cursor: "pointer",
						backgroundColor: (theme) => theme.palette.background.paper,
						"&:hover": {
							backgroundColor: (theme) => theme.palette.action.hover,
						},
					}}
					onClick={(e) => {
						analytics.track("EXPLAINABILITY chip clicked", {
							featureName: feature.name,
							featureId: feature.id,
							modelId: feature.modelId,
						});
						onClick(e);
					}}
				>
					{isOutlier ? (
						<OutlierIcon
							sx={{
								color: outlierIconColor,
							}}
						/>
					) : (
						<Icon />
					)}
					{distributionLoading && (
						<Progress
							sx={{
								width: "20px",
								height: "20px",
							}}
						/>
					)}
					{!distributionLoading && (
						<Typography variant="boldSubtitle2" sx={{ color }}>
							{transformedValue}
						</Typography>
					)}
				</Box>
			</Tooltip>
		);
	}
	return (
		<Box
			key={feature.id}
			display="flex"
			flexDirection="column"
			alignItems="center"
			gap={0}
			position="relative"
			borderRadius={1}
			padding={1}
			sx={{
				cursor: "pointer",
				backgroundColor: (theme) => theme.palette.background.paper,
				"&:hover": {
					backgroundColor: (theme) => theme.palette.action.hover,
				},
				borderColor,
				color,
			}}
			onClick={onClick}
		>
			<Typography variant="boldSubtitle2">{feature.name}</Typography>
			<Icon />
			{distributionLoading && (
				<Progress
					sx={{
						fontSize: "5px",
					}}
				/>
			)}
			{!distributionLoading && (
				<Typography variant="boldSubtitle2">{transformedValue}</Typography>
			)}
			{isOutlier && (
				<OutlierIcon
					sx={{
						position: "absolute",
						bottom: 0,
						right: 0,
						color: outlierIconColor,
					}}
				/>
			)}
		</Box>
	);
}

export default function ExplainabilityChip({
	modelId,
	indexId,
	feature,
	dense = false,
	showAsPositiveOrNegative = false,
}: ExplainabilityChipProps) {
	const ref = useRef();
	const [active, setActive] = useState(false);

	const {
		data: distribution,
		isLoading: distributionLoading,
		isError: distributionError,
	} = useDistribution(modelId, indexId, feature?.id, active);

	const quantileOrder = distribution?.quantileOrder;
	const [{ isOutlier, OutlierIcon, outlierIconColor }] = useFeature(feature);

	return (
		<div>
			<Box ref={ref}>
				<DumbChip
					feature={feature}
					onClick={() => setActive(true)}
					dense={dense}
					distributionLoading={distributionLoading}
					showAsPositiveOrNegative={showAsPositiveOrNegative}
				/>
			</Box>

			<Popover
				anchorEl={ref.current}
				open={active && !distributionLoading}
				onClose={() => setActive(false)}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				sx={{
					overflowX: "auto",
				}}
			>
				{distributionError && (
					<Alert severity="error" sx={{ margin: 1 }}>
						Unable to access feature information.
					</Alert>
				)}
				{!distributionLoading && !distributionError && (
					<>
						<Box textAlign="center" padding={1}>
							<Typography
								variant="h2"
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									alignContent: "center",
								}}
							>
								{feature.name}
								{isOutlier && (
									<OutlierIcon
										sx={{
											color: outlierIconColor,
										}}
									/>
								)}
							</Typography>
							<Typography variant="h3">
								{distribution?.featureFamily}
							</Typography>
							<Box maxWidth="300px" margin="auto" mt={1}>
								<Typography variant="body2">
									{distribution?.description}
								</Typography>
							</Box>
						</Box>
						{distribution?.chartType === "HIST" && (
							<HistChart
								data={distribution}
								xAsc={quantileOrder === "ASCENDING"}
							/>
						)}
						{distribution?.chartType === "BAR" && (
							<BarChart data={distribution} />
						)}
					</>
				)}
			</Popover>
		</div>
	);
}
