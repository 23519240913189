// import { Node } from "slate";
import type { RichText } from "../types/RichText";

// Define a serializing function that takes a value and returns a string.
function jsonToMarkdown(data) {
	function processChildren(children, prefix = "", listType = null, level = 0) {
		let counter = listType === "numbered" ? 1 : null; // Initialize counter for numbered lists
		const indentation = "  ".repeat(level); // Indent based on nesting level

		return children
			.map((child) => {
				if (child.type === "list-item") {
					const text = processChildren(
						child.children,
						"",
						listType,
						level + 1,
					).trim();
					const listPrefix = listType === "numbered" ? `${counter}.` : "-";
					counter += 1;
					return `${indentation}${listPrefix} ${text}`;
				}
				if (child.type === "list-item-text") {
					return child.children.map((grandChild) => grandChild.text).join("");
				}
				if (child.type === "bulleted-list") {
					return processChildren(child.children, "-", "bulleted", level);
				}
				if (child.type === "numbered-list") {
					return processChildren(child.children, "", "numbered", level);
				}
				if (child.type === "paragraph") {
					return `${indentation}${child.children.map((grandChild) => grandChild.text).join("")}`;
				}
				return processChildren(child.children || [], prefix, listType, level);
			})
			.join("\n");
	}

	return data
		.map((item) => {
			if (item.type === "bulleted-list") {
				return processChildren(item.children, "-", "bulleted");
			}
			if (item.type === "numbered-list") {
				return processChildren(item.children, "", "numbered");
			}
			if (item.type === "paragraph") {
				return item.children.map((child) => child.text).join("");
			}
			return "";
		})
		.join("\n\n");
}

const serialize = (value?: RichText): string => {
	if (!value) return "";
	return jsonToMarkdown(value);
};

// Define a deserializing function that takes a string and returns a value.
function markdownToJson(markdown) {
	const newLines = markdown.split("\n");

	function parseLines(lines, level = 0) {
		const result = [];
		let currentList = null;

		while (lines.length) {
			const line = lines[0];
			const match = line.match(/^(\s*)(-|\d+\.)\s+(.*)$/);
			const isParagraph = line.trim() && !match;

			// Handle paragraphs
			if (isParagraph) {
				result.push({
					type: "paragraph",
					children: [{ text: line.trim() }],
				});
				lines.shift();
			} else {
				// Handle list items
				if (!match) {
					lines.shift();
					break;
				}

				const indentLevel = match[1].length / 2; // Each nesting level is 2 spaces
				const itemType = match[2] === "-" ? "bulleted-list" : "numbered-list";
				const text = match[3];

				// Handle nested lists
				if (indentLevel > level) {
					const nestedList = parseLines(lines, indentLevel);
					if (currentList && currentList.children.length > 0) {
						const lastItem =
							currentList.children[currentList.children.length - 1];
						if (!lastItem.children) lastItem.children = [];
						lastItem.children.push(...nestedList);
					}
				} else if (indentLevel < level) {
					break;
				} else {
					lines.shift();

					if (!currentList || currentList.type !== itemType) {
						currentList = { type: itemType, children: [] };
						result.push(currentList);
					}

					currentList.children.push({
						type: "list-item",
						children: [{ type: "list-item-text", children: [{ text }] }],
					});
				}
			}
		}

		return result;
	}

	const result = [];
	while (newLines.length) {
		result.push(...parseLines(newLines));
	}
	return result;
}
const deserialize = (markdown: string): RichText => markdownToJson(markdown);

export default {
	serialize,
	deserialize,
};
