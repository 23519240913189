import { createSession, getSession } from "@/api/Process";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
	ArrayParam,
	JsonParam,
	StringParam,
	useQueryParam,
} from "use-query-params";

export default function useTrackerTable(processId) {
	const [filter, setFilter] = useQueryParam("filter", JsonParam);
	const [sort, setSort] = useQueryParam("sort", JsonParam);
	const [visibility, setVisibility] = useQueryParam("visibility", JsonParam);
	const [order, setOrder] = useQueryParam("order", ArrayParam);

	const [maskFilter, setMaskFilter] = useState([]);
	const [maskSort, setMaskSort] = useState([]);
	const [maskVisibility, setMaskVisibility] = useState({});
	const [maskOrder, setMaskOrder] = useState([]);

	// save session id in query
	const [sessionId, setSessionId] = useQueryParam("sessionId", StringParam);
	const [currentProcessId, setCurrentProcessId] = useState(null);
	// if session id, get session
	const { isLoading } = useQuery(
		["session", processId, sessionId],
		() => getSession(processId, sessionId),
		{
			enabled: !!sessionId,
			onSuccess: (data) => {
				// set session data
				setMaskFilter(data.columnFilters);
				setMaskSort(data.sorting);
				setMaskVisibility(data.columnVisibility);
				setMaskOrder(data.columnOrder);
			},
			refetchInterval: false,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		},
	);

	useEffect(() => {
		// create session if current session differs from local state
		const sessionBody = {
			columnFilters: maskFilter || [],
			sorting: maskSort || [],
			columnVisibility: maskVisibility || {},
			columnOrder: maskOrder || [],
		};

		createSession(processId, sessionBody).then((result) => {
			// update result id
			setSessionId(result?.id);
		});
	}, [
		isLoading,
		maskFilter,
		maskSort,
		maskVisibility,
		maskOrder,
		processId,
		setSessionId,
		sessionId,
	]);

	useEffect(() => {
		if (isLoading) return;
		// if process id changes, initialize state
		if (processId !== currentProcessId) {
			setSessionId(null);
			setMaskFilter(null);
			setMaskSort(null);
			setMaskVisibility(null);
			setMaskOrder(null);
			setCurrentProcessId(processId);
		}
	}, [
		currentProcessId,
		processId,
		setSessionId,
		setMaskFilter,
		setMaskSort,
		setMaskVisibility,
		setMaskOrder,
		isLoading,
	]);

	// if existing query params, set local state and clear query params
	useEffect(() => {
		if (filter?.length) {
			setMaskFilter(filter);
			setFilter(null);
		}

		if (sort?.length) {
			setMaskSort(sort);
			setSort(null);
		}

		if (visibility) {
			setMaskVisibility(visibility);
			setVisibility(null);
		}

		if (order?.length) {
			setMaskOrder(order);
			setOrder(null);
		}
	}, [
		filter,
		sort,
		visibility,
		order,
		setFilter,
		setSort,
		setVisibility,
		setOrder,
	]);

	return {
		filter: maskFilter || [],
		sort: maskSort || [],
		visibility: maskVisibility || {},
		order: maskOrder || [],
		setFilter: setMaskFilter,
		setSort: setMaskSort,
		setVisibility: setMaskVisibility,
		setOrder: setMaskOrder,
	};
}
