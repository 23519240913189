import { Box, Chip } from "@mui/material";
import React from "react";
import {
	CapitalMarketsActive,
	CapitalMarketsClosed,
	CompanyMeetingCall,
	DealEngagementStatus,
	MergersAndAcquisitionActive,
	MergersAndAcquisitionClosed,
	Operations,
	Other,
	Research,
	ThesisFormulation,
} from "../meeting-types";

type FiltersProps = {
	userId: string;
	onChange: (label: string, filter: string, value: string) => void;
	filtersToShow: string[];
	value: string;
};

export default function Filters({
	userId,
	onChange,
	filtersToShow,
	value,
}: FiltersProps) {
	const filterOptions = [
		{ label: "My Notes", filter: "authorId", value: userId },
		{
			label: CompanyMeetingCall,
			filter: "meetingType",
			value: CompanyMeetingCall,
		},
		{
			label: ThesisFormulation,
			filter: "meetingType",
			value: ThesisFormulation,
		},
		{
			label: DealEngagementStatus,
			filter: "meetingType",
			value: DealEngagementStatus,
		},
		{
			label: CapitalMarketsActive,
			filter: "meetingType",
			value: CapitalMarketsActive,
		},
		{
			label: CapitalMarketsClosed,
			filter: "meetingType",
			value: CapitalMarketsClosed,
		},
		{
			label: MergersAndAcquisitionActive,
			filter: "meetingType",
			value: MergersAndAcquisitionActive,
		},
		{
			label: MergersAndAcquisitionClosed,
			filter: "meetingType",
			value: MergersAndAcquisitionClosed,
		},
		{ label: Operations, filter: "meetingType", value: Operations },
		{ label: Research, filter: "meetingType", value: Research },
		{ label: Other, filter: "meetingType", value: Other },
	];

	return (
		<Box marginBottom={1} display="flex" flexWrap="wrap" gap={0.5}>
			{filterOptions.map(
				(filterOption) =>
					filtersToShow.includes(filterOption.label) && (
						<Chip
							key={filterOption.label}
							label={filterOption.label}
							color={value === filterOption.label ? "primary" : "default"}
							onClick={() => {
								onChange(
									filterOption.label,
									filterOption.filter,
									filterOption.value,
								);
							}}
						/>
					),
			)}
		</Box>
	);
}
