import { createEvaluationCriteria, listPromptFamilies } from "@/api/Chat";
import PreviewIcon from "@mui/icons-material/Preview"; //
import {
	Alert,
	Box,
	Button,
	Chip,
	Container,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Snackbar,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { Collapse, IconButton } from "@mui/material"; // Add these imports
import type React from "react";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import CriteriaPreview from "./CriteriaPreview";

interface CriterionForm {
	name: string;
	description: string;
	scoring_type: "binary" | "numeric" | "categorical";
	min_score?: number;
	max_score?: number;
	categories?: string[];
}

const AddCriterion = () => {
	const queryClient = useQueryClient();
	const [showPreview, setShowPreview] = useState(false);
	const [formData, setFormData] = useState<CriterionForm>({
		name: "",
		description: "",
		scoring_type: "binary",
		categories: [],
		promptFamilyId: "", // Initial
	});
	const [newCategory, setNewCategory] = useState("");
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const { data: promptFamilies, isLoading: isLoadingFamilies } = useQuery(
		"promptFamilies",
		listPromptFamilies,
	);

	const createCriterionMutation = useMutation(createEvaluationCriteria, {
		onSuccess: () => {
			queryClient.invalidateQueries("criteria");
			setOpenSnackbar(true);
			setFormData({
				name: "",
				description: "",
				scoring_type: "binary",
				categories: [],
				promptFamilyId: "", // Reset
			});
		},
	});

	const handleFamilyChange = (event: any) => {
		setFormData((prev) => ({
			...prev,
			promptFamilyId: event.target.value,
		}));
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		createCriterionMutation.mutate(formData);
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleScoringTypeChange = (
		value: "binary" | "numeric" | "categorical",
	) => {
		setFormData((prev) => ({
			...prev,
			scoring_type: value,
			// Reset related fields when type changes
			min_score: undefined,
			max_score: undefined,
			categories: value === "categorical" ? [] : undefined,
		}));
	};

	const handleAddCategory = () => {
		if (newCategory && formData.categories) {
			setFormData((prev) => ({
				...prev,
				categories: [...(prev.categories || []), newCategory],
			}));
			setNewCategory("");
		}
	};

	const handleDeleteCategory = (categoryToDelete: string) => {
		setFormData((prev) => ({
			...prev,
			categories: prev.categories?.filter((cat) => cat !== categoryToDelete),
		}));
	};

	return (
		<Container maxWidth="sm">
			<Paper elevation={3} sx={{ p: 4, mt: 4 }}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h5" component="h1" gutterBottom>
						Add Evaluation Criterion
					</Typography>
					<IconButton
						onClick={() => setShowPreview(!showPreview)}
						color={showPreview ? "primary" : "default"}
						title="Toggle Preview"
					>
						<PreviewIcon />
					</IconButton>
				</Box>

				<Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
					<TextField
						fullWidth
						label="Criterion Name"
						name="name"
						value={formData.name}
						onChange={handleInputChange}
						margin="normal"
						required
					/>

					<TextField
						fullWidth
						label="Description of Eval"
						name="description"
						value={formData.description}
						onChange={handleInputChange}
						margin="normal"
						multiline
						minRows={3}
						required
					/>

					<FormControl fullWidth margin="normal" required>
						<InputLabel>Prompt Family</InputLabel>
						<Select
							value={formData.promptFamilyId}
							label="Prompt Family"
							onChange={handleFamilyChange}
							disabled={isLoadingFamilies}
						>
							{promptFamilies?.map((family) => (
								<MenuItem key={family.id} value={family.id}>
									{family.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl fullWidth margin="normal" required>
						<InputLabel>Scoring Type</InputLabel>
						<Select
							value={formData.scoring_type}
							label="Scoring Type"
							onChange={(e) => handleScoringTypeChange(e.target.value as any)}
						>
							<MenuItem value="binary">Binary</MenuItem>
							<MenuItem value="numeric">Numeric</MenuItem>
							<MenuItem value="categorical">Categorical</MenuItem>
						</Select>
					</FormControl>

					{formData.scoring_type === "numeric" && (
						<>
							<TextField
								fullWidth
								label="Minimum Score"
								name="min_score"
								type="number"
								value={formData.min_score || ""}
								onChange={handleInputChange}
								margin="normal"
								required
							/>
							<TextField
								fullWidth
								label="Maximum Score"
								name="max_score"
								type="number"
								value={formData.max_score || ""}
								onChange={handleInputChange}
								margin="normal"
								required
							/>
						</>
					)}

					{formData.scoring_type === "categorical" && (
						<Box sx={{ mt: 2 }}>
							<Typography variant="subtitle2" gutterBottom>
								Categories
							</Typography>
							<Stack direction="row" spacing={1} sx={{ mb: 2 }}>
								<TextField
									label="Add Category"
									value={newCategory}
									onChange={(e) => setNewCategory(e.target.value)}
									size="small"
								/>
								<Button variant="outlined" onClick={handleAddCategory}>
									Add
								</Button>
							</Stack>
							<Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
								{formData.categories?.map((category) => (
									<Chip
										key={category}
										label={category}
										onDelete={() => handleDeleteCategory(category)}
									/>
								))}
							</Box>
						</Box>
					)}
					<Collapse in={showPreview}>
						<CriteriaPreview
							description={formData.description}
							scoringType={formData.scoring_type}
							minScore={formData.min_score}
							maxScore={formData.max_score}
							categories={formData.categories}
						/>
					</Collapse>

					<Button
						type="submit"
						variant="contained"
						fullWidth
						sx={{ mt: 3 }}
						disabled={createCriterionMutation.isLoading}
					>
						{createCriterionMutation.isLoading
							? "Creating..."
							: "Create Criterion"}
					</Button>

					{createCriterionMutation.isError && (
						<Alert severity="error" sx={{ mt: 2 }}>
							Error creating criterion:{" "}
							{(createCriterionMutation.error as Error).message}
						</Alert>
					)}
				</Box>
			</Paper>

			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert severity="success" sx={{ width: "100%" }}>
					Criterion created successfully!
				</Alert>
			</Snackbar>
		</Container>
	);
};

export default AddCriterion;
