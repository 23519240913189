import type { Model } from "@/api/Chat";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
	Box,
	Button,
	CircularProgress,
	Collapse,
	Grid,
	Typography,
} from "@mui/material";
import React from "react";
import ModelCard from "../Models/ModelCard";

interface ModelSectionProps {
	isOpen: boolean;
	selectedModel: any;
	models: Model[];
	isLoading: boolean;
	onModelSelect: (model: any) => void;
	onEditClick: () => void;
}

export function ModelSection({
	isOpen,
	selectedModel,
	models,
	isLoading,
	onModelSelect,
	onEditClick,
}: ModelSectionProps) {
	return (
		<Box sx={{ mb: 3, position: "relative" }}>
			<Box sx={{ color: "black", backgroundColor: "#ff00ff" }}>
				<pre>
					ACD - CRM
					<br />- edit Evals / hide old evals and criteria and prompts
					<br />- when copying private context make the copy button only grab
					context not token count n shite.
					<br />- add performance metrics link up on eval pipeline - maybe
					remove ol and just replace with ul
					<br /> - Add a distinguishing for prepend system prompt or not -
					pretty render responses in Eval suite Questions for xAI - Figuring out
					date issues
					<br /> - Thoughts on what’s been made - how to think about evals for
					chat vs one off tasks
					<br /> - RAG considerations - Private tool calling or figuring out how
					to stop
					<br />- Adding private context (RAG these context docs or ? )
					<br />
					<br />
					TODO: <br /> - share system prompt <br />- add more context onto
					private context to explain but maybe just - cosigns pgvector + RAG
					over private context. <br />- include system prompt at the end of the
					message for evals
				</pre>
			</Box>
			<Box
				sx={{
					p: 2,
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
					<Typography variant="h4">1. Select Model </Typography>
					{selectedModel && <CheckCircleIcon color="success" sx={{ ml: 1 }} />}
				</Box>
				{selectedModel && !isOpen && (
					<Button startIcon={<EditIcon />} onClick={onEditClick}>
						Edit
					</Button>
				)}
			</Box>
			<Collapse in={isOpen}>
				<Box sx={{ p: 2 }}>
					{isLoading ? (
						<CircularProgress />
					) : (
						<Grid container spacing={2}>
							{models?.map((model) => (
								<Grid item xs={12} md={6} key={model.id}>
									<Box
										onClick={() => onModelSelect(model)}
										sx={{ cursor: "pointer", "&:hover": { opacity: 0.8 } }}
									>
										<ModelCard {...model} />
									</Box>
								</Grid>
							))}
						</Grid>
					)}
				</Box>
			</Collapse>
			{selectedModel && !isOpen && (
				<Box sx={{ px: 2, pb: 2 }}>
					<ModelCard {...selectedModel} />
				</Box>
			)}
		</Box>
	);
}

export default ModelSection;
