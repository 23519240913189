import {
	IndeterminateCheckBox,
	Stars,
	ThumbDownOutlined,
	ThumbUpOutlined,
} from "@mui/icons-material";

export const FrameworkChoiceColorIconMapping = {
	green: ThumbUpOutlined,
	blue: Stars,
	yellow: IndeterminateCheckBox,
	red: ThumbDownOutlined,
	gray: IndeterminateCheckBox,
};

export default { FrameworkChoiceColorIconMapping };
