import TextCell from "@/components/Table/Primitives/TextCell";
import { Box, Popover } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import {
  COMPARISON_QUESTIONS,
  COMPARISON_ATTRIBUTES,
  type RYG,
} from "../constants";
import type { matchSurveys } from "../utils/matchSurveys";

import type { ResponsesSearchResult } from "@/api/FrameworkSurvey";
import ChoiceCell from "../ChoiceCell";

function surveyHeader(
  response: ResponsesSearchResult[0][0],
  isOneMonth = false
) {
  let surveyName = response.surveyName
    .replace("Opportunity Summary", "OS")
    .replace("Performance To Plan", "P2P");
  if (isOneMonth) {
    surveyName = surveyName + " (1M)";
  } else if (!isOneMonth && surveyName.includes("P2P")) {
    surveyName = surveyName + " (6M)";
  }
  return `${surveyName} ${dayjs(response.completedAt).format("MMM' YY")}`;
}

export default function useMonthlyAssessmentData(
  matchedSurveys: ReturnType<typeof matchSurveys>
) {
  // management data + columns
  // split each section out with questions
  // management
  // fundamentals
  // growth aka revenuee
  // assymetric risk
  const monthlyAssessmentData:
    | {
        name: string;
        rows: {
          name: string;
          position: number;
          os: ResponsesSearchResult[0][0]["answers"][0]["choice"];
          oneMonth: ResponsesSearchResult[0][0]["answers"][0]["choice"];
          sixMonth: ResponsesSearchResult[0][0]["answers"][0]["choice"];
          osMeta: ResponsesSearchResult[0][0];
          oneMonthMeta: ResponsesSearchResult[0][0];
          sixMonthMeta: ResponsesSearchResult[0][0];
          oneMonthCommentary: string;
        }[];
      }[]
    | null = React.useMemo(() => {
    const activePTPSurvey =
      matchedSurveys?.sixMonthSurvey || matchedSurveys?.oneMonthSurvey;

    if (!activePTPSurvey) return null;

    const sectionSortMap = {
      Management: 1,
      Fundamentals: 2,
      Revenue: 3,
      "Asymmetric Risk": 4,
    };
    const attributeSortMap = {
      Management: 1,
      "Skills / Traits / Attributes": 2,
      "Domain Experience / Expertise": 3,
      "Resources / Processes / Values": 4,
      "Alignment / Engagement": 5,
      "Performance and/or Milestones": 6,
      "Market Positioning": 7,
      "Macro Perspective": 8,
      "Micro Perspective": 9,
      Financials: 10,
      Liquidity: 10.5,
      Risk: 11,
      Scalability: 12,
      "Product Market Fit": 13,
      "Growth Rate": 14,
      "Operating Leverage": 15,
      "Cash Flow": 16,
      "Uniqueness of Asset": 17,
      "Information Advantage": 18,
      Structure: 19,
      "Take Out Opportunity": 20,
      "Returns Distribution": 21,
    };

    // TODO UPDATE TO USE COMPARISON_ATTRIBUTES
    const SURVEY_QUESTIONS = COMPARISON_ATTRIBUTES.map((section) => ({
      ...section,
      attributes: section.attributes.toSorted(
        (a, b) =>
          attributeSortMap[a.attributeName] - attributeSortMap[b.attributeName]
      ),
    })).toSorted(
      (a, b) => sectionSortMap[a.sectionName] - sectionSortMap[b.sectionName]
    );

    return SURVEY_QUESTIONS.map(({ sectionName, attributes }) => {
      const s: {
        name: string;
        rows: {
          name: string;
          position: number;
          os: ResponsesSearchResult[0][0]["answers"][0]["choice"];
          oneMonth: ResponsesSearchResult[0][0]["answers"][0]["choice"];
          sixMonth: ResponsesSearchResult[0][0]["answers"][0]["choice"];
          osMeta: ResponsesSearchResult[0][0];
          oneMonthMeta: ResponsesSearchResult[0][0];
          sixMonthMeta: ResponsesSearchResult[0][0];
          oneMonthCommentary: string;
        }[];
      } = {
        name: sectionName,
        rows: [],
      };

      // step through each of the question ids and build table data
      const findChoice = (attributeId, survey) => {
        const color = survey && survey.answers[attributeId]?.choice;
        return color;
      };
      const findCommentary = (attributeId, survey) => {
        return survey && survey.answers[attributeId]?.commentary;
      };

      s.rows = attributes.map((a) => ({
        name: a.name,
        os: findChoice(a.id, matchedSurveys.osSurvey),
        osMeta: matchedSurveys.osSurvey,
        oneMonth: findChoice(a.id, matchedSurveys.oneMonthSurvey),
        oneMonthCommentary: findCommentary(a.id, matchedSurveys.oneMonthSurvey),
        oneMonthMeta: matchedSurveys.oneMonthSurvey,
        sixMonth: findChoice(a.id, matchedSurveys.sixMonthSurvey),
        sixMonthMeta: matchedSurveys.sixMonthSurvey,
      }));

      return s;
    }, []);
  }, [matchedSurveys]);

  const monthlyAssessmentColumns = React.useMemo(() => {
    return [
      {
        header: "Elements",
        id: "element",
        accessorFn: (row) => row.name,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        size: 230,
      },
      {
        header: matchedSurveys?.sixMonthSurvey
          ? "P2P (6M) Commentary"
          : "P2P (1M) Commentary",
        id: "commentary",
        accessorFn: (row) => row.sixMonthCommentary || row.oneMonthCommentary,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        size: 300,
      },
      matchedSurveys?.osSurvey && {
        header: surveyHeader(matchedSurveys.osSurvey),
        id: "os",
        accessorFn: (row) => row.os,
        cell: (prop) => <ChoiceCell value={prop.getValue()} />,
      },
      matchedSurveys?.oneMonthSurvey && {
        header: surveyHeader(matchedSurveys.oneMonthSurvey, true),
        id: "oneMonth",
        accessorFn: (row) => row.oneMonth,
        cell: (prop) => <ChoiceCell value={prop.getValue()} />,
      },
      matchedSurveys?.sixMonthSurvey && {
        header: surveyHeader(matchedSurveys.sixMonthSurvey),
        id: "sixMonth",
        accessorFn: (row) => row.sixMonth,
        cell: (prop) => <ChoiceCell value={prop.getValue()} />,
      },
    ].filter((x) => x);
  }, [matchedSurveys]);

  return { monthlyAssessmentData, monthlyAssessmentColumns };
}
