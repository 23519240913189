import { Box, Link, useTheme } from "@mui/material";
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import CompanyAvatar from "../CompanyAvatar";

export default function CompanyCell({ companyName, valorId, domain, logoUrl }) {
	const theme = useTheme();

	return (
		<Box
			style={{
				display: "flex",
				gap: "24px",
				cursor: "pointer",
				width: "100%",
				alignItems: "center",
			}}
		>
			<Link href={`/org/${valorId}`}>
				<CompanyAvatar
					src={logoUrl}
					name={companyName}
					domain={domain}
					size="40"
				/>
			</Link>
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				<Link
					sx={{
						color: theme.palette.text.primary,
						fontWeight: theme.typography.fontWeightSemiBold,
						fontSize: ".875rem",
					}}
					href={`/org/${valorId}`}
				>
					{companyName}
				</Link>
				<Link
					sx={{
						color: theme.palette.text.primary,
						fontWeight: theme.typography.fontWeightRegular,
						fontSize: ".75rem",
						textDecoration: "none",
					}}
					href={`https://www.${domain}`}
				>
					{domain}
				</Link>
			</Box>
		</Box>
	);
}
