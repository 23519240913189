import React from "react";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";

export default function RenameDialog({
	open,
	onClose,
	onSubmit,
}: {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
}) {
	const [disabled, setDisabled] = React.useState(false);
	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Delete chat?</DialogTitle>
			<DialogContent>Are you sure you want to delete this chat?</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button
					onClick={async () => {
						setDisabled(true);
						await onSubmit();
						setDisabled(false);
					}}
					variant="contained"
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
}
