export const InvestmentProductMappings = {
	vsv: "VSV",
	growth: "Growth",
	investments: "Investment Team",
	scale_group: "Scale Group",
	after_action_review: "After Action Review",
	operations: "Scale Group",
	platform: "Growth/VOF Platform",
	ancillary: "Growth-Ancillary",
	growth_ops: "Lean/Growth",
	seed: "Seed 1.0",
	rev_gen: "Revenue Generation",
	revenue: "Revenue Generation",
	human_capital: "Human Capital",
	self_review: "Self Review",
	peer_review: "Peer Review",
};

export default { InvestmentProductMappings };
