import {
	MultiUserCell,
	TextCell,
	UserCell,
} from "@/components/Table/Primitives";
import { formatBigDollars } from "@/utils/numberFormat";
import { Box, type Theme, Typography } from "@mui/material";
import React from "react";

// Constants and Types
const periodConverter = {
	MONTHLY: "M",
	QUARTERLY: "Q",
	YEARLY: "Y",
} as const;

type PeriodType = keyof typeof periodConverter;

type QuestionType =
	| "MULTIPLE_CHOICE"
	| "RETURN_DISTRIBUTION"
	| "PERCENTAGE_ESTIMATE"
	| "QUARTER"
	| "TEXT"
	| "RICH_TEXT"
	| "FISCAL_YEAR"
	| "MULTIPLE_USER"
	| "SINGLE_USER"
	| "SINGLE_USER_PARTNER"
	| "DOLLAR_ESTIMATE"
	| "DOLLAR_ESTIMATE_WITH_PERIOD_TYPE"
	| "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE"
	| "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION";

interface User {
	id: string;
	name: string;
}

interface Choice {
	text: string | null;
}

interface Question {
	questionType: QuestionType;
}

interface Row {
	question: Question;
	choice: Choice;
	commentary?: string;
	answerData?: string;
	users?: User[];
}

interface CellProps {
	getValue: () => any;
	row: {
		original: Row;
	};
}

interface CellHelperResult {
	accessor: (row: Row) => any;
	cell: (props: CellProps) => JSX.Element;
}

interface PersonalCoDifferenceParams {
	personal: string;
	company: string;
}

interface DollarEstimateAnswer {
	personal: string;
	company?: string;
	period?: PeriodType;
}

// Helper Functions
const getDifferenceColor = (theme: Theme, value: string | null): string => {
	if (value === null) {
		return theme.palette.gray.main;
	}
	const numValue = Number(value);
	if (numValue >= 10) {
		return theme.palette.green.main;
	}
	if (numValue >= -25) {
		return theme.palette.yellow.main;
	}
	return theme.palette.red.dark;
};

const calculatePersonalCoDifference = ({
	personal,
	company,
}: PersonalCoDifferenceParams): string | null => {
	let difference: number | null = null;
	if (company !== "N/A" && personal !== "N/A") {
		const coNum = Number.parseFloat(company);
		const perNum = Number.parseFloat(personal);
		if (coNum === 0 && perNum === 0) {
			return null;
		}
		if (coNum === 0) {
			difference = ((coNum - perNum) / Math.abs(perNum)) * 100;
		} else {
			difference = ((perNum - coNum) / Math.abs(coNum)) * 100;
		}
		return difference.toFixed(2);
	}
	return null;
};

export function cellHelper(row: Row, theme: Theme): CellHelperResult {
	const acc: CellHelperResult = {
		accessor: () => "",
		cell: () => <TextCell value="" />,
	};

	const { questionType } = row.question;

	if (
		questionType === "MULTIPLE_CHOICE" ||
		questionType === "RETURN_DISTRIBUTION" ||
		questionType === "TEXT" ||
		questionType === "RICH_TEXT"
	) {
		acc.accessor = (r: Row) => r.choice.text;
		acc.cell = (prop: CellProps) => <TextCell value={prop.getValue()} />;
	} else if (questionType === "PERCENTAGE_ESTIMATE") {
		acc.accessor = (r: Row) => `${r.commentary}%`;
		acc.cell = (prop: CellProps) => <TextCell value={prop.getValue()} />;
	} else if (questionType === "QUARTER") {
		acc.accessor = (r: Row) => r.commentary;
		acc.cell = (prop: CellProps) => <TextCell value={prop.getValue()} />;
	} else if (questionType === "FISCAL_YEAR") {
		acc.accessor = (r: Row) => r.answerData;
		acc.cell = (prop: CellProps) => {
			const parse = JSON.parse(prop.getValue());
			return <TextCell value={`Current FY: ${parse.currentYear}`} />;
		};
	} else if (questionType === "MULTIPLE_USER") {
		acc.accessor = (r: Row) => r.users;
		acc.cell = (prop: CellProps) => <MultiUserCell value={prop.getValue()} />;
	} else if (
		questionType === "SINGLE_USER" ||
		questionType === "SINGLE_USER_PARTNER"
	) {
		acc.accessor = (r: Row) => r.users?.[0];
		acc.cell = (prop: CellProps) => <UserCell value={prop.getValue()} />;
	} else if (questionType.startsWith("DOLLAR_ESTIMATE")) {
		acc.accessor = (r: Row) => r.answerData;
		acc.cell = (prop: CellProps) => {
			const cellQuestionType = prop.row.original.question.questionType;
			let personal: string;
			let period: PeriodType | undefined;
			let company: string | undefined;
			let style = {};

			if (
				[
					"DOLLAR_ESTIMATE_WITH_PERIOD_TYPE",
					"DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE",
				].includes(cellQuestionType)
			) {
				const answerObj: DollarEstimateAnswer = JSON.parse(prop.getValue());
				personal = answerObj.personal;
				period = answerObj.period;
				company = answerObj.company;
			} else if (
				cellQuestionType === "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION"
			) {
				const answerObj: DollarEstimateAnswer = JSON.parse(prop.getValue());
				personal = answerObj.personal;
				company = answerObj.company;
			} else {
				personal = prop.getValue();
			}

			const difference = calculatePersonalCoDifference({
				personal,
				company: company ?? "N/A",
			});
			const differenceColor = getDifferenceColor(theme, difference);

			if (
				[
					"DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE",
					"DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION",
				].includes(cellQuestionType)
			) {
				style = { borderBottom: `3px solid ${differenceColor}` };
			}

			return (
				<Box sx={{ ...style }}>
					{company && (
						<Typography variant="subtitle2">
							{company === "N/A" ? company : formatBigDollars(company)}
						</Typography>
					)}
					{company && <Typography variant="subtitle2">v.</Typography>}
					<Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
						{personal === "N/A" ? personal : formatBigDollars(personal)}
					</Typography>
					{period && (
						<Typography variant="subtitle2">
							{periodConverter[period]}
						</Typography>
					)}
				</Box>
			);
		};
	} else {
		acc.accessor = (r: Row) => r.choice.text;
		acc.cell = (prop: CellProps) => <TextCell value={prop.getValue()} />;
	}

	return acc;
}

export default {
	cellHelper,
	calculatePersonalCoDifference,
	getDifferenceColor,
	periodConverter,
} as const;
