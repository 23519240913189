import { useEffect, useState } from "react";

export const useLocalStorageState = (defaultValue, key) => {
	const [value, setValue] = useState(() => {
		const stickyValue = window.localStorage.getItem(key);
		try {
			if (stickyValue !== null) {
				return JSON.parse(stickyValue);
			}
			return defaultValue;
		} catch (e) {
			return stickyValue;
		}
	});
	useEffect(() => {
		if (value !== null) {
			window.localStorage.setItem(key, JSON.stringify(value));
		}
	}, [key, value]);
	return [value, setValue];
};

export default useLocalStorageState;
