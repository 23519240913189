import { Box, Card, Typography } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import PropTypes from "prop-types";
/* eslint-disable react/prop-types */
import React from "react";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";

dayjs.extend(utc);

function CustomizedAxisTick({ x, y, payload }) {
	return (
		<g transform={`translate(${x},${y})`}>
			<text
				x={0}
				y={0}
				dy={16}
				textAnchor="middle"
				fill="#666"
				transform="rotate(0)"
			>
				{payload.value}
			</text>
		</g>
	);
}
function CustomToolTip({ payload, formatter, lines }) {
	if (payload?.length && Array.isArray(payload)) {
		const datum = payload[0];
		const { timestamp, ...rest } = datum?.payload ?? {};
		return (
			<Card style={{ padding: "8px" }}>
				{timestamp && <Typography variant="body2">{timestamp}</Typography>}
				{Object.keys(rest).map((key) => (
					<Box key={key} style={{ marginBottom: "8px" }}>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="space-between"
						>
							<Typography style={{ marginRight: "8px" }} variant="subtitle1">
								{key}
							</Typography>
							<div
								style={{
									width: "16px",
									height: "16px",
									backgroundColor: lines.filter((x) => x.lineName === key)[0]
										.color,
								}}
							/>
						</Box>
						<Typography variant="h2">{formatter(rest[key])}</Typography>
					</Box>
				))}
			</Card>
		);
	}
	return null;
}

export default function MultiSparkLine(props) {
	const { data, lines, xAxis, height, width, dataFormatter } = props;
	const formattedData =
		data &&
		data.map(({ timestamp, ...dataPoints }) => ({
			// value,
			// formattedValue: dataFormatter(value),
			...dataPoints,
			timestamp: dayjs.unix(timestamp).utc().format("MMM-YY"),
		}));
	return (
		<ResponsiveContainer width={width || "100%"} height={height}>
			<LineChart
				data={formattedData}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<Tooltip
					offset={0}
					allowEscapeViewBox={{ x: false, y: false }}
					content={<CustomToolTip formatter={dataFormatter} lines={lines} />}
				/>
				{xAxis && data?.length && (
					<XAxis
						minTickGap={30}
						dataKey="timestamp"
						type="category"
						tick={<CustomizedAxisTick />}
						height={60}
					/>
				)}
				{lines.map(({ lineName, color }) => (
					<Line
						key={lineName}
						type="linear"
						dataKey={lineName}
						stroke={color}
						strokeWidth={3}
						animationDuration={1000}
						dot={false}
					/>
				))}
			</LineChart>
		</ResponsiveContainer>
	);
}

MultiSparkLine.propTypes = {
	data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.number)),
	lines: PropTypes.arrayOf(
		PropTypes.shape({ lineName: PropTypes.string, color: PropTypes.string }),
	),
	width: PropTypes.number,
	xAxis: PropTypes.bool,
	height: PropTypes.number,
	dataFormatter: PropTypes.func,
};

MultiSparkLine.defaultProps = {
	data: [],
	lines: [],
	width: null,
	height: 72,
	xAxis: false,
	dataFormatter: (value) => value,
};
