import { getSignalModelPipeline } from "@/api/Reporting";
import { depageData, getNextPageParam } from "@/components/InfiniteTable";
import OverrideTable from "@/components/OverrideTable";
import React, { useMemo } from "react";
import { useInfiniteQuery } from "react-query";

export default function AdminTab({
	visible,
}: {
	visible: boolean;
}) {
	const { data } = useInfiniteQuery({
		queryKey: "SignalModelPipelineAll",
		queryFn: ({ pageParam = null }) => getSignalModelPipeline(50000, pageParam),
		getNextPageParam,
	});

	const depagedData = useMemo(
		() =>
			depageData(data)
				?.map((row) => row.company?.valorId)
				.filter((x) => x),
		[data],
	);

	if (!visible) {
		return null;
	}
	return <OverrideTable valorIds={depagedData || []} />;
}
