import { canSeeMarketMapsDrafts } from "@/constants/Roles";
import Segments from "@/constants/Segments";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import DateRangeFilter from "@/ui/atoms/TableFilters/DateRangeFilter";
import SelectColumnFilter from "@/ui/atoms/TableFilters/SelectColumnFilter";
import CustomTable from "@/ui/molecules/CustomTable";
import Page from "@/ui/molecules/Page";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Link } from "@mui/material";
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { getAllMarketMaps } from "@/api/MarketMap";
import { useAuth } from "@/hooks/useAuth";
import { format } from "date-fns";

function MarketMaps() {
	const { user } = useAuth();
	const navigate = useNavigate();

	const canSeeAllDrafts = canSeeMarketMapsDrafts(user);
	const { isLoading, data: maps = [] } = useQuery(
		["getAllMarketMaps"],
		async () => {
			const response = await getAllMarketMaps();
			return response.map((map) => ({
				...map,
				segment: Segments[map.segment],
			}));
		},
	);

	const drafts = useMemo(
		() =>
			maps.filter(
				(map) =>
					map.isDraft && (map.authorUserId === user.id || canSeeAllDrafts),
			),
		[maps, user.id, canSeeAllDrafts],
	);
	const published = useMemo(() => maps.filter((map) => !map.isDraft), [maps]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Title",
				accessor: "title",
				Cell: ({ cell: { value }, row }) => (
					<Link
						key={row.original.id}
						href={`/market-maps/view/${row.original.id}`}
					>
						{value}
					</Link>
				),
			},
			{
				Header: "Segment",
				accessor: "segment",
				Filter: SelectColumnFilter,
				filter: "equals",
			},
			{
				Header: "Unique Companies",
				accessor: "numCompanies",
			},
			{
				Header: "Last Edited",
				accessor: (row) =>
					row?.editor?.lastEdited ? new Date(row.editor.lastEdited) : null,
				filter: "dateRange",
				sortType: "date",
				Filter: ({ column }) => (
					<Box style={{ maxWidth: "260" }}>
						<DateRangeFilter
							filterValue={column.filterValue}
							setFilter={column.setFilter}
						/>
					</Box>
				),
				Cell: ({
					value: lastEdited,
					row: {
						original: { editor },
					},
				}) =>
					lastEdited
						? `${format(lastEdited, "MMM dd, yyyy")}${
								editor?.editor ? ` by ${editor?.editor}` : ""
							}`
						: "",
			},
			{
				Header: "Published",
				accessor: (row) => new Date(row.publishedAt),
				filter: "dateRange",
				sortType: "date",
				Filter: ({ column }) => (
					<Box style={{ maxWidth: "260" }}>
						<DateRangeFilter
							filterValue={column.filterValue}
							setFilter={column.setFilter}
						/>
					</Box>
				),
				Cell: ({
					value: publishedAt,
					row: {
						original: { author },
					},
				}) =>
					`${format(publishedAt, "MMM dd, yyyy")}${
						author ? ` by ${author}` : ""
					}`,
			},
		],
		[],
	);

	const draftColumns = React.useMemo(
		() => [
			{
				Header: "Title",
				accessor: "title",
				Cell: ({ cell: { value }, row }) => (
					<Link
						key={row.original.id}
						href={`/market-maps/edit/${row.original.id}`}
					>
						{value}
					</Link>
				),
			},
			{
				Header: "Segment",
				accessor: "segment",
				Filter: SelectColumnFilter,
				filter: "equals",
			},
			{
				Header: "Unique Companies",
				accessor: "numCompanies",
			},
			{
				Header: "Last Edited",
				accessor: (row) =>
					row?.editor?.lastEdited ? new Date(row.editor.lastEdited) : null,
				filter: "dateRange",
				sortType: "date",
				Filter: ({ column }) => (
					<Box style={{ maxWidth: "260" }}>
						<DateRangeFilter
							filterValue={column.filterValue}
							setFilter={column.setFilter}
						/>
					</Box>
				),
				Cell: ({
					value: lastEdited,
					row: {
						original: { editor },
					},
				}) =>
					lastEdited
						? `${format(lastEdited, "MMM dd, yyyy")}${
								editor?.editor ? ` by ${editor?.editor}` : ""
							}`
						: "",
			},
			{
				Header: "Published",
				accessor: (row) => new Date(row.publishedAt),
				filter: "dateRange",
				sortType: "date",
				Filter: ({ column }) => (
					<Box style={{ maxWidth: "260" }}>
						<DateRangeFilter
							filterValue={column.filterValue}
							setFilter={column.setFilter}
						/>
					</Box>
				),
				Cell: ({
					value: publishedAt,
					row: {
						original: { author },
					},
				}) =>
					`${format(publishedAt, "MMM dd, yyyy")}${
						author ? ` by ${author}` : ""
					}`,
			},
		],
		[],
	);

	return (
		<CenteredProgress isLoading={isLoading}>
			<Page data-cy="page__market_maps">
				<Page.Title> Market Maps</Page.Title>
				<Box
					sx={{
						float: "right",
						marginBottom: 1,
					}}
				>
					<Button
						variant="contained"
						color="primary"
						startIcon={<AddIcon />}
						// component={Link}
						onClick={() => navigate("/market-maps/create")}
					>
						Add
					</Button>
				</Box>
				{drafts.length ? (
					<Box style={{ marginBottom: "8px" }}>
						<CustomTable
							title="Drafts"
							pageSize={25}
							data={drafts}
							columns={draftColumns}
						/>
					</Box>
				) : null}
				<Page.Content data-cy="market-maps__published">
					<CustomTable
						title="Market Maps"
						pageSize={25}
						data={published}
						columns={columns}
					/>
				</Page.Content>
			</Page>
		</CenteredProgress>
	);
}

export default MarketMaps;
