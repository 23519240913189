/* eslint-disable no-console */
import axios from "axios";

import type { paths } from "@/api/index";

type GetSignalExplanation = paths["/api/signals/explain/{signal_id}"]["get"];
export type ExplainedSignal =
	GetSignalExplanation["responses"]["200"]["content"]["application/json"];

export const getSignalExplainability = async (
	signalId: string,
): Promise<ExplainedSignal> => {
	const response = await axios.get<ExplainedSignal>(
		`/api/signals/explain/${signalId}`,
	);
	return response.data as ExplainedSignal;
};

export const hideFimoicaSignal = async (signalId: string) => {
	await axios.post(`/api/signals/hide-fimoical-signal/${signalId}`);
};
export default {
	getSignalExplainability,
	hideFimoicaSignal,
};
