import SlidingTabs from "@/ui/atoms/SlidingTabs";
import { Box } from "@mui/material";
import React from "react";
import Helmet from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import Permissions from "./Permissions";
import Processes from "./Processes";
import Roles from "./Roles";
import UsersTable from "./UsersTable";

function Compliance() {
	const { "*": subPage } = useParams();
	const navigate = useNavigate();

	const pages = ["Roles", "Users", "Permissions", "Processes"];
	let page = "Roles";
	const onChange = (value) => {
		// push to history
		navigate(`/compliance-and-permissions/${value.toLowerCase()}`);
	};
	if (subPage) {
		page = subPage[0].toUpperCase() + subPage.slice(1);
	} else {
		navigate("/compliance-and-permissions/roles");
	}

	return (
		<>
			<Helmet>
				<title>Compliance And Permissions - {page}</title>
			</Helmet>
			<Box>
				<SlidingTabs values={pages} value={page} setValue={onChange} />
				{page === "Roles" && <Roles />}
				{page === "Users" && (
					<Box marginTop={2}>
						<UsersTable title="All Users" />
					</Box>
				)}
				{page === "Permissions" && (
					<Box marginTop={2}>
						<Permissions />
					</Box>
				)}
				{page === "Processes" && (
					<Box marginTop={2}>
						<Processes />
					</Box>
				)}
			</Box>
		</>
	);
}

export default Compliance;
