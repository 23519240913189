export const promptTypeTitleMap = {
	skills_traits_attributes: "Skills / Traits / Attributes",
	domain_experience_expertise: "Domain Experience / Expertise",
	resources_process_values: "Resources / Process / Values",
	market_positioning: "Market Positioning",
	macro_perspective: "Macro Perspective",
	micro_perspective: "Micro Perspective",
	financials: "Financials",
	risk: "Risk",
	scalability: "Scalability",
	product_market_fit: "Product Market Fit",
	growth_rate: "Growth Rate",
	cash_flow: "Cash Flow",
	uniqueness_of_asset: "Uniqueness of Asset",
};

export const promptTypeSort = {
	skills_traits_attributes: 1,
	domain_experience_expertise: 2,
	resources_process_values: 3,
	market_positioning: 4,
	macro_perspective: 5,
	micro_perspective: 6,
	financials: 7,
	risk: 8,
	scalability: 9,
	product_market_fit: 10,
	growth_rate: 11,
	cash_flow: 12,
	uniqueness_of_asset: 13,
};

export default {
	promptTypeTitleMap,
	promptTypeSort,
};
