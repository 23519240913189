import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";

type NotesSearchProps = {
	value: string;
	onUpdate: (value: string) => void;
};

function NotesSearch({ value, onUpdate }: NotesSearchProps) {
	return (
		<Box marginY={1}>
			<TextField
				variant="outlined"
				fullWidth
				label="Search Notes"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
					endAdornment: value && value.length > 0 && (
						<InputAdornment position="end">
							<IconButton edge="end" onClick={() => onUpdate("")}>
								<CloseIcon />
							</IconButton>
						</InputAdornment>
					),
				}}
				value={value}
				onChange={(event) => onUpdate(event.target.value)}
				data-cy="view-notes__search"
			/>
		</Box>
	);
}

export default NotesSearch;
