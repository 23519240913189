import type { PromptFamily } from "@/api/Chat";
import type { User } from "@/api/Users";
import UserAvatar from "@/ui/atoms/UserAvatar";
import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Dialog,
	DialogContent,
	DialogTitle,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useState } from "react";

interface PromptCardProps {
	id: string;
	content: string;
	promptFamily: PromptFamily;
	metadata: string | null;
	creator: User;
}
const PromptCard = ({
	id,
	content,
	creator,
	promptFamily,
	metadata,
}: PromptCardProps) => {
	const [open, setOpen] = useState(false);
	const [copyTooltip, setCopyTooltip] = useState("Click to copy");

	// Pretty print the metadata JSON
	const prettyMetadata = () => {
		if (!metadata) return null;
		try {
			const parsedMetadata = JSON.parse(metadata);
			return JSON.stringify(parsedMetadata, null, 2);
		} catch {
			return metadata; // Return as-is if parsing fails
		}
	};

	// Get first 4 lines of content
	const previewContent = content.split("\n").slice(0, 4).join("\n");
	const hasMoreContent = content.split("\n").length > 4;

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleCopyId = async () => {
		await navigator.clipboard.writeText(id);
		setCopyTooltip("Copied!");
		setTimeout(() => setCopyTooltip("Click to copy"), 2000);
	};

	const IdComponent = ({ sx = {} }) => (
		<Tooltip title={copyTooltip}>
			<Typography
				variant="caption"
				color="text.secondary"
				sx={{
					cursor: "pointer",
					"&:hover": { opacity: 0.7 },
					...sx,
				}}
				onClick={handleCopyId}
			>
				ID: {id}
			</Typography>
		</Tooltip>
	);

	return (
		<>
			<Card sx={{ minWidth: 275, mb: 2 }}>
				<CardContent>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center",
							gap: "8px",
							mb: 2,
						}}
					>
						<Chip label={promptFamily.name} color="primary" size="small" />
						<UserAvatar user={creator} />
						<IdComponent />
					</Box>

					<Typography variant="body1" gutterBottom>
						{previewContent}
						{hasMoreContent && (
							<Button
								size="small"
								onClick={handleOpen}
								sx={{ mt: 1, display: "block" }}
							>
								See more
							</Button>
						)}
					</Typography>

					{metadata && (
						<Box sx={{ mt: 2 }}>
							<Typography variant="body2" color="text.secondary" gutterBottom>
								Metadata:
							</Typography>
							<Box
								component="pre"
								sx={{
									backgroundColor: "grey.100",
									p: 1,
									borderRadius: 1,
									overflow: "auto",
									fontSize: "0.875rem",
								}}
							>
								{prettyMetadata()}
							</Box>
						</Box>
					)}
				</CardContent>
			</Card>

			<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
				<DialogTitle>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center",
							gap: "8px",
							mb: 2,
						}}
					>
						<Chip label={promptFamily.name} color="primary" size="small" />
						<UserAvatar user={creator} />
						<IdComponent />
					</Box>
				</DialogTitle>
				<DialogContent>
					<Typography
						variant="body1"
						component="pre"
						sx={{ whiteSpace: "pre-wrap" }}
					>
						{content}
					</Typography>

					{metadata && (
						<Box sx={{ mt: 2 }}>
							<Typography variant="body2" color="text.secondary" gutterBottom>
								Metadata:
							</Typography>
							<Box
								component="pre"
								sx={{
									backgroundColor: "grey.100",
									p: 1,
									borderRadius: 1,
									overflow: "auto",
									fontSize: "0.875rem",
								}}
							>
								{prettyMetadata()}
							</Box>
						</Box>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
};
export default PromptCard;
