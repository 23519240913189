import TableReport from "@/pages/ProcessManagement/TableReport";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { Box, Card } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AffinitySignalModelFund from "./AffinitySignalModelFund";

import FirstMeetingsReport from "../FirstMeetingReports";

export default function InvestmentsNewOps() {
	const navigate = useNavigate();
	const location = useLocation();

	const icPage = "requested-investment-approvals";
	const tsPage = "investments-and-opportunities/under-ts";
	const cPage = "investments-and-opportunities/considering-for-ts-review";
	const preOAPage = "investments-and-opportunities/pre-oa";
	const OAPage = "investments-and-opportunities/oa-approvals";
	const smPage = "investments-and-opportunities/signal-model-fund";
	// affinity back up for signal model fund
	const asmPage = "investments-and-opportunities/affinity-signal-model-fund";

	const isICPage = location.pathname.includes(icPage);
	const isTermSheetPage = location.pathname.includes(tsPage);
	const isConsideringPage = location.pathname.includes(cPage);
	const isPreOAPage = location.pathname.includes(preOAPage);
	const isSignalModelPage = location.pathname.includes(smPage);
	const isAffinitySignalModelPage = location.pathname.includes(asmPage);
	const isOAPAge = location.pathname.includes(OAPage);

	const activeKey = location.pathname.split("/").pop();

	let report = null;

	if (isICPage) {
		report = (
			<TableReport
				processId="deals"
				viewId="a0b904d4-f164-40e6-936c-a70697e2770b"
			/>
		);
	} else if (isTermSheetPage) {
		report = (
			<TableReport
				processId="deals"
				viewId="36bf04f6-ef59-4f69-99cc-e606be141993"
			/>
		);
	} else if (isConsideringPage) {
		report = (
			<TableReport
				processId="deals"
				viewId="381fbd70-e4a3-4f45-b300-7dc1a9a2c95c"
			/>
		);
	} else if (isPreOAPage) {
		report = (
			<TableReport
				processId="deals"
				viewId="aebf9438-5fad-48c4-851c-f2abc408490f"
			/>
		);
	} else if (isOAPAge) {
		report = <FirstMeetingsReport />;
	} else if (isSignalModelPage) {
		report = (
			<TableReport
				processId="sig-model"
				viewId="a92a3c1a-0ef0-4a5d-9637-8499d39a64f5"
			/>
		);
	}
	// else if (isLPage) {
	//   report = (
	//     <TableReport
	//       processId="deals"
	//       viewId="a40cc835-581c-4502-bd65-86cedc88a925"
	//     />
	//   );
	// }

	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<Box display="flex" flexDirection="column" gap={2}>
				{isConsideringPage ||
				isTermSheetPage ||
				isSignalModelPage ||
				isPreOAPage ||
				isOAPAge ? (
					<ButtonTabs
						width="625px"
						tabLabel="Status"
						options={[
							{
								key: "pre-oa",
								label: "Pre OA Backlog",
							},
							{
								key: "oa-approvals",
								label: "OA Approvals",
							},
							{
								key: "under-ts",
								label: "Under Term Sheet",
							},
							{
								key: "considering-for-ts-review",
								label: "Considering for TS",
							},
							{
								key: "signal-model-fund",
								label: "Signal Model Fund",
							},
						]}
						activeKey={activeKey}
						onClick={(option) => {
							navigate(
								`/weekly-ic/weekly-ic/investments-and-opportunities/${option.key}`,
							);
						}}
						disableTest={({ disabled = false }) => disabled}
					/>
				) : null}
			</Box>
			<Card elevation={0} sx={{ height: "auto" }}>
				{report}
				{isAffinitySignalModelPage && <AffinitySignalModelFund />}
			</Card>
		</Box>
	);
}
