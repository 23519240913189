import {
	Avatar,
	Box,
	Button,
	Chip,
	Modal,
	type Theme,
	Tooltip,
	Typography,
	createStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import SearchBar from "../SearchBar";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			"& .MuiChip-deletable:focus": {
				backgroundColor: "none !important",
			},
		},
		topBox: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},
		helperText: {
			color: theme.palette.text.primary,
			fontSize: "14px",
		},
		edit: {
			fontWeight: theme.typography.fontWeightBold,
		},
		title: {
			color: theme.palette.text.secondary,
			fontWeight: theme.typography.fontWeightBold,
			fontSize: "12px",
		},
		paper: {
			position: "absolute",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			width: "100%",
			maxWidth: "640px",
			height: "100%",
			maxHeight: "600px",
			padding: theme.spacing(4),
			backgroundColor: theme.palette.background.paper,
			borderRadius: theme.shape.borderRadius,
			outline: "none",
			boxShadow: theme.shadows[5],
		},
		upperModalContainer: {
			display: "flex",
			flexDirection: "column",
		},
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		modalTitle: {
			marginBottom: theme.spacing(4),
			fontWeight: theme.typography.fontWeightBold,
		},
		barContainer: {
			display: "flex",
			justifyContent: "space-between",
			marginBottom: theme.spacing(1),
		},
		searchBar: {
			width: "80%",
			maxWidth: "400px",
		},
		cancelButton: {
			color: theme.palette.red.main,
		},
		upperKeywordContainer: {
			display: "inline-flex",
			flexWrap: "wrap",
			gap: theme.spacing(1),
			alignItems: "center",
			color: theme.palette.text.secondary,
		},
		modalKeywordContainer: {
			display: "inline-flex",
			flexWrap: "wrap",
			gap: theme.spacing(1),
			alignItems: "center",
			maxHeight: "440px",
			overflowY: "scroll",
		},
		modalButtonContainer: {
			display: "flex",
			gap: theme.spacing(1),
			justifyContent: "flex-end",
			justifySelf: "flex-end",
			width: "100%",
		},
	}),
);

interface Company {
	name: string;
	logoUrl?: string;
	valorId: string;
}

interface Props {
	onSubmit: (companies: Company[]) => void;
	searchCompanies: (keyword: string) => Company[];
	seedCompanies: Company[];
}
export default function SeedCompanyEditor(props: Props) {
	const { onSubmit, searchCompanies, seedCompanies = [] } = props;
	const title = "Known Competitors";
	const [modalSeedCompanies, setModalSeedCompanies] = useState([]);

	const isSameKeywordList = modalSeedCompanies === seedCompanies;
	const [openModal, setModalOpen] = useState(false);
	const classes = useStyles();

	const searchAndParse = async (query) => {
		const results = await searchCompanies(query);
		return results
			.filter(
				(searchResultCompany) =>
					modalSeedCompanies
						.map((x) => x.valorId)
						.indexOf(searchResultCompany.valorId) === -1,
			)
			.slice(0, 5);
	};

	const handleDelete = async (companyId) => {
		setModalSeedCompanies(
			[...modalSeedCompanies].filter(
				(company) => company.valorId !== companyId,
			),
		);
	};

	const onOpenModal = () => {
		setModalSeedCompanies([...seedCompanies]);
		setModalOpen(true);
	};

	const onCloseModal = () => {
		setModalSeedCompanies([...seedCompanies]);
		setModalOpen(false);
	};

	const submitSeedCompany = () => {
		onSubmit(modalSeedCompanies);
		setModalOpen(false);
	};

	const onSelectSeedCompany = (company) => {
		const copy = [...modalSeedCompanies];
		copy.push(company);
		setModalSeedCompanies((oldState) => [company, ...oldState]);
	};

	return (
		<>
			<Box className={classes.container}>
				<Box className={classes.topBox}>
					<Tooltip
						title="Add Known Competitors to get a better set of keywords"
						placement="top"
					>
						<Typography className={classes.title}>
							{title.toUpperCase()}
						</Typography>
					</Tooltip>

					<Button
						onClick={onOpenModal}
						className={classes.edit}
						color="primary"
					>
						EDIT
					</Button>
				</Box>
				<Box className={classes.upperKeywordContainer}>
					{seedCompanies.length === 0 && (
						<Typography className={classes.helperText}>
							<b>No Known Competitors Set</b>
							&nbsp; Add some known competitors to get better keywords.
						</Typography>
					)}
					{seedCompanies.map((company) => (
						<Chip
							key={company.valorId}
							avatar={<Avatar alt={company.name} src={company?.logoUrl} />}
							variant="outlined"
							label={company?.name}
						/>
					))}
				</Box>
			</Box>
			<Modal open={openModal} onClose={onCloseModal} className={classes.modal}>
				<Box className={classes.paper}>
					<Box className={classes.upperModalContainer}>
						<Typography variant="h1" className={classes.modalTitle}>
							Edit {title}
						</Typography>
						<Typography className={classes.title}>SEARCH</Typography>
						<Box className={classes.barContainer}>
							<Box className={classes.searchBar}>
								<SearchBar
									showSAYT
									search={searchAndParse}
									entity="company"
									selectTerm={onSelectSeedCompany}
									placeholder="Enter known similar companies"
								/>
							</Box>
							<Button
								className={classes.cancelButton}
								onClick={() => setModalSeedCompanies([])}
							>
								CLEAR ALL
							</Button>
						</Box>
						<Box className={classes.modalKeywordContainer}>
							{modalSeedCompanies.map((company) => (
								<Chip
									avatar={<Avatar alt={company.name} src={company?.logoUrl} />}
									label={company?.name}
									key={company.valorId}
									variant="outlined"
									onDelete={() => handleDelete(company.valorId)}
								/>
							))}
						</Box>
					</Box>
					<Box className={classes.modalButtonContainer}>
						<Button color="primary" onClick={onCloseModal}>
							Cancel
						</Button>
						<Button
							color="primary"
							disabled={isSameKeywordList}
							onClick={submitSeedCompany}
						>
							Accept
						</Button>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
