import {
	evaluatePrompt,
	listEvaluationCriteria,
	listModels,
	listPrompts,
} from "@/api/Chat";
import { Alert, Box, Button, Card, Container } from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import CriteriaSection from "./CriteriaSection";
import ModelSection from "./ModelSection";
import PromptSection from "./PromptSection";

const BuildEvaluation = () => {
	// State for selections
	const [selectedModel, setSelectedModel] = useState<string | null>(null);
	const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null);
	const [selectedCriteria, setSelectedCriteria] = useState<string[]>([]);

	// State for section visibility
	const [modelSectionOpen, setModelSectionOpen] = useState(true);
	const [promptSectionOpen, setPromptSectionOpen] = useState(false);
	const [criteriaSectionOpen, setCriteriaSectionOpen] = useState(false);

	// Fetch data
	const { data: models, isLoading: modelsLoading } = useQuery(
		"models",
		listModels,
	);
	const { data: prompts, isLoading: promptsLoading } = useQuery("prompts", () =>
		listPrompts(null),
	);
	const { data: criteria, isLoading: criteriaLoading } = useQuery(
		"criteria",
		listEvaluationCriteria,
	);

	const evaluateMutation = useMutation(evaluatePrompt);

	// Section completion handlers
	const handleModelSelect = (model: any) => {
		setSelectedModel(model);
		setModelSectionOpen(false);
		setPromptSectionOpen(true);
	};

	const handlePromptSelect = (prompt: any) => {
		setSelectedPrompt(prompt);
		setPromptSectionOpen(false);
		setCriteriaSectionOpen(true);
	};

	const handleCriteriaSelect = (criterion: any) => {
		setSelectedCriteria((prev) => {
			const isAlreadySelected = prev.some((c) => c.id === criterion.id);
			if (isAlreadySelected) {
				return prev.filter((c) => c.id !== criterion.id);
			}
			return [...prev, criterion];
		});
	};

	const handleEvaluate = () => {
		evaluateMutation.mutate({
			modelId: selectedModel.id,
			promptId: selectedPrompt.id,
			criteriaIds: selectedCriteria.map((c) => c.id),
		});
	};

	return (
		<Container maxWidth="lg" sx={{ py: 4 }}>
			<Card sx={{ mb: 3, p: 3 }}>
				<ModelSection
					isOpen={modelSectionOpen}
					selectedModel={selectedModel}
					models={models || []}
					isLoading={modelsLoading}
					onModelSelect={handleModelSelect}
					onEditClick={() => setModelSectionOpen(true)}
				/>
			</Card>

			{selectedModel && (
				<Card sx={{ mb: 3, p: 3 }}>
					<PromptSection
						isOpen={promptSectionOpen}
						selectedPrompt={selectedPrompt}
						prompts={prompts || []}
						isLoading={promptsLoading}
						onPromptSelect={handlePromptSelect}
						onEditClick={() => setPromptSectionOpen(true)}
					/>
				</Card>
			)}

			{selectedPrompt && (
				<Card sx={{ mb: 3, p: 3 }}>
					<CriteriaSection
						isOpen={criteriaSectionOpen}
						selectedCriteria={selectedCriteria}
						criteria={criteria || []}
						isLoading={criteriaLoading}
						onCriteriaSelect={handleCriteriaSelect}
					/>
				</Card>
			)}

			{/* Submit Section */}
			{selectedModel && selectedPrompt && selectedCriteria.length > 0 && (
				<Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
					<Button
						variant="contained"
						size="large"
						onClick={handleEvaluate}
						disabled={evaluateMutation.isLoading}
					>
						{evaluateMutation.isLoading ? "Evaluating..." : "Start Evaluation"}
					</Button>
				</Box>
			)}

			{/* Error Display */}
			{evaluateMutation.isError && (
				<Alert severity="error" sx={{ mt: 2 }}>
					Error submitting evaluation:{" "}
					{(evaluateMutation.error as Error).message}
				</Alert>
			)}

			{/* Success Display */}
			{evaluateMutation.isSuccess && (
				<Alert severity="success" sx={{ mt: 2 }}>
					Evaluation submitted successfully!
				</Alert>
			)}
		</Container>
	);
};

export default BuildEvaluation;
