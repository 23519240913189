import { Add, Close } from "@mui/icons-material";
import {
	Box,
	Button,
	Divider,
	Drawer,
	IconButton,
	Skeleton,
	Tooltip,
	Typography,
} from "@mui/material";
import { omit } from "lodash";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { getOrgFields } from "@/api/Organization";
import { useQuery } from "react-query";
import { useProcessActions, useProcessData } from "../ProcessContext";
import FieldForm from "./FieldForm";
import FieldListItem from "./FieldListItem";

export default function FieldEditor({
	processId,
	open,
	onClose,
}: {
	processId: string;
	open: boolean;
	onClose: () => void;
}) {
	const [showNew, setShowNew] = React.useState(false);
	const [activeField, setActiveField] = React.useState(null);

	const [formDisabled, setFormDisabled] = React.useState(false);
	const [formError, setFormError] = React.useState(null);

	// load current fields
	const { fields, isProcessLoading: isLoading } = useProcessData();

	const {
		createField,
		updateField,
		updateFields,
		deleteField,
		linkField,
		createChoice,
		updateChoice,
		deleteChoice,
		refetchProcess,
	} = useProcessActions();

	// get possible org fields
	const { data: orgFieldData } = useQuery(["orgFields"], async () =>
		getOrgFields(),
	);

	const orgFields = React.useMemo(
		() =>
			orgFieldData?.map((field) => ({
				...field,
				disabled: fields.some((f) => f.settingId === field.id),
			})),
		[orgFieldData, fields],
	);

	const sortedFields = React.useMemo(() => {
		const sorted = fields.sort((a, b) => a.sort - b.sort);
		return sorted;
	}, [fields]);

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={() => {
				if (!formDisabled) {
					onClose();
				}
			}}
		>
			<Box maxWidth="100%" width="min(100vw, 600px)" padding={1}>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					marginBottom={1}
				>
					<Typography variant="h2">Edit Fields</Typography>
					<IconButton onClick={onClose} disabled={formDisabled}>
						<Close />
					</IconButton>
				</Box>

				{showNew && (
					<Box display="flex" flexDirection="column" gap={1}>
						<Typography variant="h3">New Field</Typography>
						<FieldForm
							disabled={formDisabled}
							formError={formError}
							dismissFormError={() => {
								setFormError(null);
							}}
							onCancel={() => {
								setShowNew(false);
							}}
							onSave={async (fieldValue) => {
								setFormDisabled(true);

								try {
									// save field
									const createdField = await createField(
										processId,
										omit(fieldValue, ["id", "choices"]),
									);
									// save field choices
									if (fieldValue.choices) {
										await Promise.all(
											fieldValue.choices.map((choice, index) =>
												createChoice(processId, createdField.id, {
													...choice,
													fieldId: createdField.id,
													sort: index,
												}),
											),
										);
									}

									refetchProcess();

									setShowNew(false);
									setFormError(null);
								} catch (error) {
									setFormError(error);
								}
								setFormDisabled(false);
							}}
						/>
					</Box>
				)}

				{activeField && (
					<Box display="flex" flexDirection="column" gap={1}>
						<Typography variant="h3">{activeField?.name}</Typography>
						<FieldForm
							field={activeField}
							disabled={formDisabled}
							formError={formError}
							dismissFormError={() => {
								setFormError(null);
							}}
							onCancel={() => {
								setActiveField(null);
							}}
							onSave={async (fieldValue) => {
								setFormDisabled(true);

								// save field
								try {
									await updateField(processId, {
										id: activeField.id,
										...fieldValue,
									});

									// save field choices
									if (fieldValue.choices) {
										// get new choices
										const newChoices = fieldValue.choices.filter((choice) =>
											activeField.choices.every((c) => c.id !== choice.id),
										);
										const updatedChoices = fieldValue.choices.filter((choice) =>
											activeField.choices.some((c) => c.id === choice.id),
										);
										const deletedChoices = activeField.choices.filter(
											(choice) =>
												fieldValue.choices.every((c) => c.id !== choice.id),
										);
										await Promise.all([
											...newChoices.map((choice) =>
												createChoice(processId, activeField.id, {
													...choice,
													fieldId: activeField.id,
													sort: fieldValue.choices.indexOf(choice),
												}),
											),
											...updatedChoices.map((choice) =>
												updateChoice(processId, activeField.id, {
													...choice,
													sort: fieldValue.choices.indexOf(choice),
												}),
											),
											...deletedChoices.map((choice) =>
												deleteChoice(processId, activeField.id, choice.id),
											),
										]);
									}

									refetchProcess();

									// clear form and close
									setActiveField(null);
									setFormError(null);
								} catch (error) {
									setFormError(error);
								}
								setFormDisabled(false);
							}}
						/>
					</Box>
				)}

				{!showNew && !activeField && (
					<Box display="flex" flexDirection="column" gap={1}>
						{isLoading && (
							<>
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
								<Skeleton variant="rounded" height={40} />
							</>
						)}

						<DragDropContext
							onDragEnd={async (dragEvent) => {
								const { source, destination } = dragEvent;
								if (!destination) return;

								const newFields = [...sortedFields];
								const [removed] = newFields.splice(source.index, 1);
								newFields.splice(destination.index, 0, removed);

								// update sort order on server
								const reorderedFields = newFields.map((field, index) => ({
									id: field.id,
									sort: index,
								}));
								await updateFields(processId, reorderedFields);
							}}
						>
							<Droppable droppableId="fields">
								{(dropProvided) => (
									<Box
										ref={dropProvided.innerRef}
										/* eslint-disable-next-line react/jsx-props-no-spreading */
										{...dropProvided.droppableProps}
									>
										{sortedFields?.map((field, index) => (
											<Draggable
												key={field.id}
												draggableId={field.id}
												index={index}
											>
												{(dragProvided) => (
													<Box
														ref={dragProvided.innerRef}
														/* eslint-disable-next-line react/jsx-props-no-spreading */
														{...dragProvided.draggableProps}
														/* eslint-disable-next-line react/jsx-props-no-spreading */
														{...dragProvided.dragHandleProps}
													>
														<FieldListItem
															key={field.id}
															field={field}
															onDelete={async () => {
																// delete field
																await deleteField(processId, field.id);
															}}
															onEdit={(fieldValue) => {
																setActiveField(fieldValue);
															}}
														/>
													</Box>
												)}
											</Draggable>
										))}
										{dropProvided.placeholder}
									</Box>
								)}
							</Droppable>
						</DragDropContext>

						<Tooltip title="Add Field">
							<Button
								fullWidth
								onClick={() => {
									setShowNew(true);
								}}
							>
								<Add />
							</Button>
						</Tooltip>

						<Divider />

						{/* Add org field list here */}
						{orgFields?.map((field) => (
							<FieldListItem
								key={field.id}
								field={{
									id: field.id,
									name: field.label,
								}}
								onLink={async () => {
									await linkField(processId, field);
								}}
								disabled={field.disabled}
							/>
						))}
					</Box>
				)}
			</Box>
		</Drawer>
	);
}
