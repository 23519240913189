import config from "@/config";
import * as amplitude from "@amplitude/analytics-browser";

const init = (settings: {
	defaultTracking: {
		attribution: false;
		pageViews: true;
		sessions: false;
		formInteractions: false;
		fileDownloads: false;
	};
}) => {
	amplitude.init(config.amplitude.key, {
		...settings,
	});
};

const track = (eventName, eventProperties) => {
	amplitude.track(eventName, eventProperties);
};

const identify = (id: amplitude.Identify) => {
	amplitude.identify(id);
};

export default {
	init,
	identify,
	Identify: amplitude.Identify,
	track,
};
