import IHEChange from "@/pages/IHEChange/IHEChanges";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import Page from "@/ui/molecules/Page";
import { Box, type Theme, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import {
	Navigate,
	Route,
	Routes,
	useNavigate,
	useParams,
} from "react-router-dom";
import TableReport from "../ProcessManagement/TableReport";
import Agenda from "./Agenda";
import InvestorRelations from "./InvestorRelations";
import MeetingNotesPages from "./Notes/MeetingNotesPages";
import InvestmentsNewOps from "./PipelineReview/InvestmentsAndNewOps";
import ScaleGroupApprovals from "./ScaleGroupApprovals";
import TitlePage from "./TitlePage";
import { agendaActions, flattenData } from "./utils";

export function WeeklyMeeting() {
	const { "*": typepage } = useParams<{ type: string; page?: string }>();
	const [type, page] = typepage.split("/");
	const isSmDown = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("sm"),
	);
	const titleMapping = {
		"weekly-ic": isSmDown
			? "Weekly IC Meeting"
			: "One Firm - Weekly Investment Committee Meeting",
	};
	const title = titleMapping[type];
	const navigate = useNavigate();
	const tabs =
		type === "weekly-ic"
			? agendaActions["weekly-ic"]
			: flattenData(agendaActions[type]).filter(
					(x) => x.uselessParent !== true,
				);

	return (
		<Page data-cy="page__weekly_ic">
			<Page.Title>{title}</Page.Title>
			<Page.Content>
				<Box mb={5}>
					<ButtonTabs
						width="100%"
						tabLabel="Agenda"
						wrap
						disableTest={(o) => o?.disabled === true}
						activeKey={page}
						onClick={(option) => navigate(`${type}/${option.link}`)}
						options={tabs}
					/>
				</Box>
				<Routes>
					<Route path="weekly-ic" element={<Navigate to="agenda" replace />} />
					<Route path="standup" element={<Navigate to="agenda" replace />} />
					<Route
						path="weekly-ic/investment-ihe-review/*"
						element={<IHEChange />}
					/>
					<Route
						path="weekly-ic/requested-scale-group-approvals/:subPage?/:subPage2?"
						element={<ScaleGroupApprovals />}
					/>
					<Route
						path="weekly-ic/requested-investment-approvals"
						element={<InvestmentsNewOps />}
					/>
					<Route
						path="weekly-ic/investments-and-opportunities/*"
						element={<InvestmentsNewOps />}
					/>
					<Route
						path="weekly-ic/completed-since-last-ic"
						element={<InvestmentsNewOps />}
					/>
					<Route
						path="weekly-ic/portfolio-financing/:subPage?/*"
						element={
							<MeetingNotesPages subPath="../weekly-ic/portfolio-financing" />
						}
					/>
					<Route path=":type/agenda" element={<Agenda />} />
					<Route
						path="weekly-ic/smaller-group-discussion"
						element={
							<TitlePage>
								<Typography variant="h1">Smaller Group Discussion</Typography>
							</TitlePage>
						}
					/>
					<Route path="weekly-ic/ir/*" element={<InvestorRelations />} />
					<Route
						path="weekly-ic/partner-group-discussion"
						element={
							<TitlePage>
								<Typography variant="h1">Partner Group Discussion</Typography>
							</TitlePage>
						}
					/>
					<Route
						path="weekly-ic/post-close-actions"
						element={
							<TableReport
								disableFilters
								processId="deals"
								viewId="50091f73-11eb-447b-8856-196036e6a7f5"
							/>
						}
					/>
					<Route
						path="weekly-ic/following-for-investment"
						element={
							<TableReport
								disableFilters
								processId="deals"
								viewId="e8707b9a-5dd3-41e3-b46b-f87b6ae5457e"
							/>
						}
					/>
				</Routes>
			</Page.Content>
		</Page>
	);
}

export default WeeklyMeeting;
