/* eslint-disable no-console */
import axios from "axios";

export const createOrgOnPipeline = async ({
	valorId,
	department,
	monitoringServiceLead,
	owners,
}) => {
	try {
		const response = await axios.post("/api/affinity/organization", {
			valorId,
			department,
			monitoringServiceLead,
			owners,
		});
		return response;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const getAllSectors = async () => {
	try {
		const response = await axios.get("/api/affinity/sectors");
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const getAllInvestments = async () => {
	try {
		const response = await axios.get("/api/affinity/investments");
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const getOppsFromOrg = async (orgId) => {
	const response = await axios.get(
		`/api/affinity/organization/${orgId}/opportunities`,
	);
	return response.data;
};

export default {
	createOrgOnPipeline,
	getAllSectors,
	getAllInvestments,
	getOppsFromOrg,
};
