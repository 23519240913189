import { buildURLParameters } from "@/utils/general";
import axios from "axios";
import { AuthenticationError } from "./Error";
import type { operations, paths } from "./index";

type SearchResponses =
	operations["get_notes_api_notes__valor_id__get"]["responses"];
export const PAGE_SIZE = 25;

export const getLastestNotesByValorIds = async (valorIds: string[]) => {
	const params = buildURLParameters({
		valorIds,
	});
	const response = await axios.get(`/api/notes/organizations?${params}`);
	return response.data;
};

export type CompanyNoteResult =
	SearchResponses["200"]["content"]["application/json"];
const queryCompanyNotes = async (
	valorId: string,
	query?: string,
	offset?: number,
	size: number = PAGE_SIZE,
): Promise<CompanyNoteResult> => {
	let queryParams = "";
	if (query) {
		queryParams = `?q=${query}`;
		if (offset) {
			queryParams += `&offset=${offset}`;
		}
	} else if (offset) {
		queryParams = `?offset=${offset}`;
	}

	if (queryParams === "") {
		queryParams = `?size=${size}`;
	} else {
		queryParams += `&size=${size}`;
	}

	let response;
	try {
		response = await axios.get<CompanyNoteResult | Error>(
			`/api/notes/${valorId}${queryParams}`,
		);
	} catch (error) {
		if (error.request.status === 403) {
			throw new AuthenticationError();
		}
		throw error;
	}

	return response?.data as CompanyNoteResult;
};

type GetNotes = paths["/api/notes"]["get"];
export type NotesResult =
	GetNotes["responses"]["200"]["content"]["application/json"];
const searchNotes = async (
	noteType?: string,
	createdDate?: string,
	listId?: number,
	opportunityId?: number,
	offset?: number,
	size?: number,
): Promise<NotesResult> => {
	const params = buildURLParameters({
		noteType,
		createdDate,
		listId,
		opportunityId,
		offset,
		size,
	});
	const response = await axios.get(`/api/notes?${params}`);

	return response.data;
};

type CountResponses =
	operations["get_note_count_api_notes__valor_id__count_get"]["responses"];
export type NoteCount = CountResponses["200"]["content"]["application/json"];
const getNoteCount = async (valorId: string): Promise<NoteCount> => {
	const response = await axios.get<NoteCount>(`/api/notes/${valorId}/count`);
	return response.data;
};

type ReadResponses =
	operations["get_note_by_id_api_notes__valor_id___document_id__get"]["responses"];
export type Note = ReadResponses["200"]["content"]["application/json"];
const readNote = async (valorId: string, documentId: string): Promise<Note> => {
	const response = await axios.get<Note>(`/api/notes/${valorId}/${documentId}`);
	return response.data;
};

const getNoteByValorIdAndOpportunityId = async (
	valorId,
	oppId,
	daysAgo = 14,
	limit = 100,
): Promise<Note> => {
	const response = await axios.get(
		`/api/notes/${valorId}/opportunity/${oppId}?days=${daysAgo}&limit=${limit}`,
	);

	return response.data;
};

type CreateResponses =
	operations["create_new_note_api_notes_post"]["responses"];

export type CreateCompanyNoteResult =
	CreateResponses["200"]["content"]["application/json"];
const createNote = async (note: {
	valorId: string;
	title: string;
	plainText: string;
	richText: any;
	meetingDate?: number;
	meetingType: string;
	visibility: string;
}): Promise<CreateCompanyNoteResult> => {
	const newNote = note;

	if (!newNote.meetingDate) {
		newNote.meetingDate = Math.floor(Date.now() / 1000);
	}

	const response = await axios.post<CreateCompanyNoteResult>(
		"/api/notes",
		JSON.stringify(note),
	);
	return response.data;
};

type UpdateResponses = operations["edit_note_api_notes_patch"]["responses"];
export type UpdateCompanyNoteResult =
	UpdateResponses["200"]["content"]["application/json"];
const updateNote = async (note: {
	documentId: string;
	title: string;
	text: string;
	plainText: string;
	richText: any;
	meetingDate: number;
	meetingType: string;
	visibility: string;
}): Promise<UpdateCompanyNoteResult> => {
	const response = await axios.patch<UpdateCompanyNoteResult>(
		"/api/notes",
		JSON.stringify(note),
		{ headers: { "Content-Type": "application/json" } },
	);
	return response.data;
};

type DeleteResponses =
	operations["delete_note_api_notes__document_id__delete"]["responses"];
export type DeleteCompanyNoteResult =
	DeleteResponses["200"]["content"]["application/json"];
const deleteNote = async (noteId): Promise<DeleteCompanyNoteResult> => {
	const response = await axios.delete<DeleteCompanyNoteResult>(
		`/api/notes/${noteId}`,
	);
	return response.data;
};

type PushToAffinityResponses =
	operations["push_to_affinity_api_notes_affinity_push_post"]["responses"];
export type PushToAffinityResults =
	PushToAffinityResponses["200"]["content"]["application/json"];
const pushToAffinity = async (
	orgValorId: string,
	oppId: number,
	oppName: string,
	noteId: string,
): Promise<PushToAffinityResults> => {
	const response = await axios.post(
		"/api/notes/affinity-push",
		JSON.stringify({
			documentId: noteId,
			organizationId: orgValorId,
			opportunityId: oppId,
			opportunityName: oppName,
		}),
		{
			headers: { "Content-Type": "application/json" },
		},
	);
	return response.data;
};

const addNoteOppLink = async (noteId: string, oppId: string): Promise<void> => {
	await axios.post(`/api/notes/${noteId}/opportunity/${oppId}`, {
		headers: { "Content-Type": "application/json" },
	});
};

const removeNoteOppLink = async (
	noteId: string,
	oppId: string,
): Promise<void> => {
	await axios.delete(`/api/notes/${noteId}/opportunity/${oppId}`, {
		headers: { "Content-Type": "application/json" },
	});
};

export {
	queryCompanyNotes,
	searchNotes,
	createNote,
	readNote,
	updateNote,
	deleteNote,
	getNoteCount,
	pushToAffinity,
	getNoteByValorIdAndOpportunityId,
	addNoteOppLink,
	removeNoteOppLink,
};
