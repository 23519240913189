import {
	TableBody as MUITableBody,
	TableCell,
	Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

export function TableHeaderCell({ children }) {
	return (
		<TableCell>
			<Typography
				variant="boldSubtitle2"
				sx={{
					textTransform: "uppercase",
				}}
			>
				{children}
			</Typography>
		</TableCell>
	);
}

export const TableBody = styled(MUITableBody)(({ theme }) => ({
	"& > tr:nth-of-type(odd)": {
		background: theme.palette.background.paper,
	},
	"& > tr:nth-of-type(even)": {
		background: theme.palette.background.oddTableRow,
	},
}));
