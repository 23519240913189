import CenteredProgress from "@/ui/atoms/CenteredProgress";
import { formatBigDollars } from "@/utils/numberFormat";
import {
	Card,
	Grid,
	Typography,
	makeStyles,
	useTheme,
} from "@material-ui/core";
import { addDays, addMonths } from "date-fns";
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState, useRef } from "react";
import { createStaticRanges } from "react-date-range";
import { useQuery } from "react-query";
import {
	Bar,
	BarChart,
	CartesianGrid,
	LabelList,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { getProcessTasks } from "@/api/Process";

const PRIMARY_CO_FIELD_ID = "e1a0e34d-9402-4db9-8160-5886226443aa";
const STRATEGIC_PARTNER_FIELD_ID = "4145a2a7-30dd-4d0a-8af5-1a171e7fe357";
const DOLLAR_OPPORTUNITY_FIELD_ID = "a88cfda5-262f-4bec-8fac-dc026315a239";
const ENGAGEMENT_STATUS_FIELD_ID = "61340f7b-03fb-4866-948b-b6495beeb46b";
const INTRO_CALL_DATE_FIELD_ID = "7de6230d-d6dd-4a54-84d7-8f7a9059478f";
const CLOSING_DATE_FIELD_ID = "a795d112-7ff5-43a6-b2c4-6c9687479363";

const useStyles = makeStyles({
	datePopover: {
		"& > .argos-MuiPopover-paper": {
			overflowX: "scroll",
		},
	},
});

const today = new Date(new Date().setHours(0, 0, 0, 0));
const staticRanges = createStaticRanges([
	{
		label: "Last 30 days",
		range: () => ({
			startDate: addDays(today, -29),
			endDate: new Date(),
		}),
	},
	{
		label: "Last 90 days",
		range: () => ({
			startDate: addDays(today, -89),
			endDate: new Date(),
		}),
	},
	{
		label: "Last 6 Months",
		range: () => ({
			startDate: addMonths(today, -6),
			endDate: new Date(),
		}),
	},
	{
		label: "Last Year",
		range: () => ({
			startDate: addMonths(today, -12),
			endDate: new Date(),
		}),
	},
	{
		label: "All Time",
		range: () => ({
			startDate: null,
			endDate: null,
		}),
	},
]);

export function Meeting() {
	const classes = useStyles();
	const [openPopOver, setOpenPopover] = useState(false);
	const [dateRange, setDateRange] = useState({
		startDate: null,
		endDate: null,
	});
	const theme = useTheme();

	const dateRangeRef = useRef(null);
	const { data: trackerRecords = [], isLoading } = useQuery(
		"revOps",
		async () => {
			const { data } = await getProcessTasks(
				["vsv-revops"],
				50000,
				null,
				null,
				null,
				null,
			);
			return data;
		},
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			staleTime: Number.POSITIVE_INFINITY,
		},
	);

	const strategicValorIds = useMemo(
		() => [
			"1c721ad4-e963-4638-9d2a-0dc24d1f8c31", // Lettuce Entertain You Enterprises
			"3a1cc68f-600e-4d23-8b8f-791f3e468ae8", // W Hotels
			"10d797c7-b0f0-405c-9ed4-f5de478f5794", // Gordon Food Service
			"b0951715-0ff5-47fd-8841-c61a8a0d8858", // Nestlé
			"0aba176d-fcb8-46fc-bea8-a505be61477c", // Starbucks
			"b195777c-a7e4-4cb4-9834-ad1d6194aeca", // Alshaya Group
			"e6d0af77-9fbe-4bae-b2dd-ad9af447d9c3", // Alsea
			"b1029b9b-bef5-4f54-a954-4efb47ca3013", // Target
			"fe2e7201-a5ea-4f52-8f47-4454a73f7fbd", // Gfs
			"db96d0b4-eb50-48a3-b5d5-641648e2f3fb", // HAVI
			"11b2b1da-7473-4eb9-9f06-1443539bc96f", // Marriott International
		],
		[],
	);

	const chartData = useMemo(() => {
		const data = {};
		if (!trackerRecords) return data;
		let dataCopy = [...trackerRecords];
		if (dateRange.startDate && dateRange.endDate) {
			dataCopy = dataCopy.filter((x) => {
				const numericStartDate =
					x.fieldValues?.[INTRO_CALL_DATE_FIELD_ID]?.dateValue;
				const numericEndDate =
					x.fieldValues?.[CLOSING_DATE_FIELD_ID]?.dateValue;
				// This makes nulls always come back, so they are not filtered out
				if (!numericStartDate || !numericEndDate) return true;
				const startDate = new Date(numericStartDate);
				const endDate = new Date(numericEndDate);
				return (
					(startDate >= dateRange.startDate &&
						startDate <= dateRange.endDate) ||
					(endDate >= dateRange.startDate && endDate <= dateRange.endDate)
				);
			});
		}

		const aliveStatus = [
			"50ed3be3-4efc-4bf7-ae89-0d68e68298d4", // Deployment
			"8dc89df7-e104-446a-a92e-8e19e682f1c3", // Introduction
			"b03b40e0-c6c2-4f18-b179-224992dc28f2", // Evaluation
			"a361720b-866a-41aa-930c-6968dcb53454", // Test
		];
		data.dollars = dataCopy.reduce((acc, curr) => {
			const dollarOpportunity =
				curr.fieldValues?.[DOLLAR_OPPORTUNITY_FIELD_ID]?.numericValue ?? 0;
			if (dollarOpportunity > 0) {
				return acc + dollarOpportunity;
			}
			return acc;
		}, 0);

		data.totalActive = dataCopy.reduce((acc, curr) => {
			const engagementStatus =
				curr.fieldValues?.[ENGAGEMENT_STATUS_FIELD_ID]?.choiceId;
			if (aliveStatus.indexOf(engagementStatus) !== -1) {
				return acc + 1;
			}
			return acc;
		}, 0);

		data.totalDeployed = dataCopy.reduce((acc, curr) => {
			const engagementStatus =
				curr.fieldValues?.[ENGAGEMENT_STATUS_FIELD_ID]?.choiceId;
			if (engagementStatus === "50ed3be3-4efc-4bf7-ae89-0d68e68298d4") {
				return acc + 1;
			}
			return acc;
		}, 0);

		data.totalByStrategic = dataCopy.reduce((acc, curr) => {
			const strategicCo =
				curr.fieldValues?.[STRATEGIC_PARTNER_FIELD_ID]?.company;
			if (!strategicCo) return acc;
			const { valorId } = strategicCo;
			const dollarOpportunity =
				curr.fieldValues?.[DOLLAR_OPPORTUNITY_FIELD_ID]?.numericValue ?? 0;
			if (acc[valorId] === undefined) {
				acc[valorId] = {
					name: strategicCo.name,
					total: dollarOpportunity,
				};
			} else {
				acc[valorId].total += dollarOpportunity;
			}
			return acc;
		}, {});

		data.totalByStrategic = Object.keys(data.totalByStrategic).reduce(
			(acc, key) => {
				if (strategicValorIds.indexOf(key) === -1) {
					acc.other.total += data.totalByStrategic[key].total;
					delete data.totalByStrategic[key];
				} else {
					acc[key] = data.totalByStrategic[key];
				}
				return acc;
			},
			{ other: { name: "Ecosystem", total: 0 } },
		);

		data.totalByStrategic = Object.values(data.totalByStrategic)
			.filter((x) => x.total > 0)
			.sort((a, b) => b.total - a.total);

		data.totalByPortCo = dataCopy.reduce((acc, curr) => {
			const primaryCo = curr.fieldValues?.[PRIMARY_CO_FIELD_ID]?.company;
			if (!primaryCo) return acc;
			const { valorId } = primaryCo;
			const dollarOpportunity =
				curr.fieldValues?.[DOLLAR_OPPORTUNITY_FIELD_ID]?.numericValue ?? 0;
			if (acc[valorId] === undefined) {
				acc[valorId] = {
					name: primaryCo.name,
					total: dollarOpportunity,
				};
			} else {
				acc[valorId].total += dollarOpportunity;
			}
			return acc;
		}, {});
		data.totalByPortCo = Object.values(data.totalByPortCo)
			.filter((x) => x.total > 0)
			.sort((a, b) => b.total - a.total);

		data.activeByStrategic = dataCopy.reduce((acc, curr) => {
			const strategicCo =
				curr.fieldValues?.[STRATEGIC_PARTNER_FIELD_ID]?.company;
			if (!strategicCo) return acc;
			const engagementStatus =
				curr.fieldValues?.[ENGAGEMENT_STATUS_FIELD_ID]?.choiceId;
			const { valorId } = strategicCo;
			if (
				acc[valorId] === undefined &&
				aliveStatus.indexOf(engagementStatus) !== -1
			) {
				acc[valorId] = {
					name: strategicCo.name,
					total: 1,
				};
			} else if (aliveStatus.indexOf(engagementStatus) !== -1) {
				acc[valorId].total += 1;
			}
			return acc;
		}, {});

		data.activeByStrategic = Object.keys(data.activeByStrategic).reduce(
			(acc, key) => {
				if (strategicValorIds.indexOf(key) === -1) {
					acc.other.total += data.activeByStrategic[key].total;
					delete data.activeByStrategic[key];
				} else {
					acc[key] = data.activeByStrategic[key];
				}
				return acc;
			},
			{ other: { name: "Ecosystem", total: 0 } },
		);
		data.activeByStrategic = Object.values(data.activeByStrategic)
			.filter((x) => x.total > 0)
			.sort((a, b) => b.total - a.total);

		data.activeByPortco = dataCopy.reduce((acc, curr) => {
			const primaryCo = curr.fieldValues?.[PRIMARY_CO_FIELD_ID]?.company;
			if (!primaryCo) return acc;
			const { valorId } = primaryCo;
			const engagementStatus =
				curr.fieldValues?.[ENGAGEMENT_STATUS_FIELD_ID]?.choiceId;
			if (
				acc[valorId] === undefined &&
				aliveStatus.indexOf(engagementStatus) !== -1
			) {
				acc[valorId] = {
					name: primaryCo.name,
					total: 1,
				};
			} else if (aliveStatus.indexOf(engagementStatus) !== -1) {
				acc[valorId].total += 1;
			}
			return acc;
		}, {});
		data.activeByPortco = Object.values(data.activeByPortco)
			.filter((x) => x.total > 0)
			.sort((a, b) => b.total - a.total);

		return data;
	}, [
		trackerRecords,
		dateRange.startDate,
		dateRange.endDate,
		strategicValorIds,
	]);

	if (isLoading) {
		return <CenteredProgress />;
	}

	return (
		<div style={{ margin: "4px" }}>
			{/* <Button
        onClick={() => setOpenPopover((o) => !o)}
        ref={dateRangeRef}
        size="small"
        color="primary"
        variant="outlined"
      >
        <DateRange />
        Change Date Range
      </Button>
      <Popover
        className={classes.datePopover}
        open={openPopOver}
        anchorEl={dateRangeRef.current}
        onClose={() => setOpenPopover(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DateRangePicker
          staticRanges={staticRanges}
          inputRanges={[]}
          onChange={(item) => {
            const { startDate, endDate } = item.selection;
            setDateRange({
              startDate: startDate
                ? new Date(startDate).setHours(0, 0, 0, 0)
                : null,
              endDate: endDate
                ? new Date(endDate).setHours(23, 59, 59, 999)
                : null,
            });
          }}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[
            { ...dateRange, key: "selection", color: theme.palette.blue.main },
          ]}
          direction="horizontal"
        />
      </Popover> */}
			<Grid container style={{ padding: "4px" }}>
				<Grid item xs={12} sm={4}>
					<Card
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							padding: "8px",
						}}
					>
						<Typography variant="h6">{chartData.totalActive}</Typography>
						<Typography variant="subtitle2">Active Engagements</Typography>
					</Card>
				</Grid>

				<Grid item xs={12} sm={4}>
					<Card
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							padding: "8px",
							margin: "0 8px",
						}}
					>
						<Typography variant="h6">{chartData.totalDeployed}</Typography>
						<Typography variant="subtitle2">Total Deployments</Typography>
					</Card>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Card
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							padding: "8px",
						}}
					>
						<Typography variant="h6">
							{formatBigDollars(chartData.dollars)}
						</Typography>
						<Typography variant="subtitle2">
							Total Revenue Generation
						</Typography>
					</Card>
				</Grid>

				<Grid item xs={12}>
					<Typography variant="subtitle1">
						Active Engagements by Strategic
					</Typography>
					<ResponsiveContainer width="100%" height={450}>
						<BarChart data={chartData.activeByStrategic}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								height={128}
								dy={60}
								type="category"
								angle={-35}
								dataKey="name"
								interval={0}
							/>
							<YAxis domain={[0, "dataMax + 20"]} />
							<Tooltip
								contentStyle={{
									backgroundColor: theme.palette.background.paper,
								}}
							/>
							<Bar dataKey="total" fill={theme.palette.blue.main}>
								<LabelList dataKey="total" position="top" />
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="subtitle1">
						Revenue Generation by Strategic
					</Typography>
					<ResponsiveContainer width="100%" height={450}>
						<BarChart
							data={chartData.totalByStrategic}
							margin={{
								top: 20,
								right: 20,
								bottom: 20,
								left: 20,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								height={128}
								dy={70}
								dx={-35}
								type="category"
								angle={-45}
								dataKey="name"
								interval={0}
								domain={[0, "dataMax + 1000000"]}
							/>
							<YAxis
								tickFormatter={formatBigDollars}
								domain={[0, "dataMax => (dataMax * 1.3)"]}
							/>
							<Tooltip
								formatter={(val) => formatBigDollars(val)}
								contentStyle={{
									backgroundColor: theme.palette.background.paper,
								}}
							/>
							<Bar dataKey="total" fill={theme.palette.blue.main}>
								<LabelList
									dataKey="total"
									position="top"
									formatter={(val) => formatBigDollars(val)}
								/>
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="subtitle1">
						Active Engagements by PortCo
					</Typography>
					<ResponsiveContainer width="100%" height={550}>
						<BarChart data={chartData.activeByPortco}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								height={160}
								dy={70}
								dx={-35}
								type="category"
								angle={-65}
								dataKey="name"
								interval={0}
							/>
							<YAxis />
							<Tooltip
								contentStyle={{
									backgroundColor: theme.palette.background.paper,
								}}
							/>
							<Bar dataKey="total" fill={theme.palette.blue.main}>
								<LabelList dataKey="total" position="top" />
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="subtitle1">
						Revenue Generation by PortCo
					</Typography>
					<ResponsiveContainer width="100%" height={450}>
						<BarChart data={chartData.totalByPortCo}>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								height={160}
								dy={70}
								dx={-35}
								type="category"
								angle={-65}
								dataKey="name"
								interval={0}
							/>
							<YAxis tickFormatter={formatBigDollars} />
							<Tooltip
								formatter={(val) => formatBigDollars(val)}
								contentStyle={{
									backgroundColor: theme.palette.background.paper,
								}}
							/>
							<Bar dataKey="total" fill={theme.palette.blue.main}>
								<LabelList
									dataKey="total"
									position="top"
									angle={-45}
									dy={-35}
									formatter={(val) => formatBigDollars(val)}
								/>
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</Grid>
			</Grid>
		</div>
	);
}

export default Meeting;
