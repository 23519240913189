import { getIHEPortfolioChanges } from "@/api/Reporting";
import {
	depageData,
	getNextPageParam,
	mapFilters,
	mapSorting,
} from "@/components/InfiniteTable";
import { Box, useTheme } from "@mui/material";
import type {
	ColumnFiltersState,
	SortingState,
	VisibilityState,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { useState, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { JsonParam, useQueryParam, withDefault } from "use-query-params";
import IHEPorfolioChangesTable from "./IHEChangeTable";

const DEFAULT_SORT = [{ id: "primaryFund", desc: false }];
const DEFAULT_FILTER = [
	{
		id: "isMsl",
		value: true,
	},
	{
		id: "completedAt",
		value: {
			min: dayjs().subtract(1, "week").format("MM/DD/YYYY"),
			max: dayjs().format("MM/DD/YYYY"),
		},
	},
	{
		id: "furtherInvestmentStatus",
		value: {
			value: "0d52c59f-3c2f-62e1-1358-bc3aa3821e1d",
			label: "Following",
		},
	},
];

const DEFAULT_VISIBILITY = {
	iheChangesManagement: false,
	iheChangesFundamentals: false,
	iheChangesRevenue: false,
	iheChangesRisk: false,
	iheChangesOther: false,
};

const DefaultFilterQueryParam = withDefault(JsonParam, DEFAULT_FILTER);
const DefaultVisibilityQueryParam = withDefault(JsonParam, DEFAULT_VISIBILITY);

// TODO add fund type ^^^ and make it some where else.
export function IHEPorfolioChanges() {
	const theme = useTheme();

	const [sorting, setSorting] = useState<SortingState>(DEFAULT_SORT);
	const [columnFilters, setColumnFilters] = useQueryParam<ColumnFiltersState>(
		"columnFilters",
		DefaultFilterQueryParam,
	);
	const [globalFilter, setGlobalFilter] = useState<string | null>("");

	const [columnVisibility, setColumnVisibility] =
		useQueryParam<VisibilityState>("columnVis", DefaultVisibilityQueryParam);
	const [shouldPivotColumns, setPivotColumns] = useState(false);

	const pagedQueryKey = useMemo(
		() => ["IHEPortfolioChange", sorting, columnFilters, globalFilter],
		[sorting, columnFilters, globalFilter],
	);

	const {
		data,
		isLoading,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
	} = useInfiniteQuery({
		queryKey: pagedQueryKey,
		queryFn: ({ pageParam = null }) =>
			getIHEPortfolioChanges(
				20,
				pageParam,
				mapSorting(sorting),
				mapFilters([...columnFilters], globalFilter),
			),
		getNextPageParam,
	});

	const pages = depageData(data);
	const totalCount = data?.pages[0]?.totalCount;

	const pageData = pages
		.sort((a, b) => a?.primaryFund.localeCompare(b?.primaryFund))
		.reverse();

	return (
		<Box>
			<Box
				sx={{
					marginTop: {
						xs: theme.spacing(0.5),
						sm: theme.spacing(1),
						md: theme.spacing(2),
					},
				}}
			>
				<IHEPorfolioChangesTable
					pages={pageData}
					totalCount={totalCount}
					// activeFund={fundFilter}
					isLoading={isLoading}
					isFetching={isFetching}
					isFetchingNextPage={isFetchingNextPage}
					hasNextPage={hasNextPage}
					fetchNextPage={fetchNextPage}
					sorting={sorting}
					columnFilters={columnFilters}
					columnVisibility={columnVisibility}
					globalFilter={globalFilter}
					shouldPivotColumns={shouldPivotColumns}
					togglePivotColumns={() => setPivotColumns((o) => !o)}
					onSortingChange={setSorting}
					onColumnFiltersChange={(o) => {
						setColumnFilters(o);
					}}
					onGlobalFilterChange={setGlobalFilter}
					onColumnVisibilityChange={(o) =>
						setColumnVisibility(o(columnVisibility))
					}
				/>
			</Box>
		</Box>
	);
}

export default IHEPorfolioChanges;
