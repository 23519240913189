import { Delete, Edit } from "@mui/icons-material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import DownArrowIcon from "@mui/icons-material/KeyboardArrowDown";
import {
	Box,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import DeleteDialog from "./DeleteDialog";
import RenameDialog from "./RenameDialog";

export default function ChatHeader({
	title,
	onRename,
	onDelete,
	disabled,
}: {
	title: string;
	onRename: (newTitle: string) => void;
	onDelete: () => void;
	disabled: boolean;
}) {
	const navigate = useNavigate();

	const anchorEl = React.useRef(null);
	const [showMenu, setShowMenu] = React.useState(false);

	const [showRenameDialog, setShowRenameDialog] = React.useState(false);
	const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

	function handleRename() {
		setShowRenameDialog(true);
	}

	function handleDelete() {
		setShowDeleteDialog(true);
	}

	return (
		<Box>
			<Box
				display="flex"
				alignItems="center"
				gap={1}
				sx={{
					cursor: "pointer",
				}}
				ref={anchorEl}
				onClick={() => {
					if (disabled) return;
					setShowMenu(true);
				}}
			>
				<ChatBubbleOutlineIcon fontSize="small" />
				{title?.length ? (
					<Typography variant="overline">{title}</Typography>
				) : (
					<Skeleton width={100} variant="text" />
				)}
				<DownArrowIcon fontSize="small" />
			</Box>

			<Menu
				anchorEl={anchorEl.current}
				open={showMenu}
				onClose={() => {
					setShowMenu(false);
				}}
				MenuListProps={{
					dense: true,
				}}
			>
				<MenuItem
					onClick={() => {
						handleRename();
						setShowMenu(false);
					}}
				>
					<ListItemIcon>
						<Edit />
					</ListItemIcon>
					<ListItemText>Rename</ListItemText>
				</MenuItem>

				<MenuItem
					onClick={() => {
						handleDelete();
						setShowMenu(false);
					}}
				>
					<ListItemIcon>
						<Delete />
					</ListItemIcon>
					<ListItemText>Delete</ListItemText>
				</MenuItem>
			</Menu>

			<RenameDialog
				open={showRenameDialog}
				onClose={() => {
					setShowRenameDialog(false);
				}}
				initialValue={title}
				onSubmit={async (newTitle) => {
					await onRename(newTitle);
					setShowRenameDialog(false);
				}}
			/>

			<DeleteDialog
				open={showDeleteDialog}
				onClose={() => {
					setShowDeleteDialog(false);
				}}
				onSubmit={async () => {
					await onDelete();
					navigate("/grok");
				}}
			/>
		</Box>
	);
}
