import {
	type Experience,
	type GetPerson as PersonWithExperience,
	createPerson,
	deleteExperience,
	deletePerson,
	getPerson,
	patchExperience,
	patchPerson,
	postExperience,
	searchPersons,
} from "@/api/People";
import Input from "@/components/FormInputs/Input";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import Progress from "@/ui/atoms/Progress";
import Page from "@/ui/molecules/Page";
import {
	Add as AddIcon,
	ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Autocomplete,
	Box,
	Button,
	Card,
	Chip,
	Grid,
	Link,
	List,
	ListItem,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import dayjs from "dayjs";
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo } from "react";
import { Field, Form } from "react-final-form";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

type ExperienceItemFormProps = {
	name: string;
	experienceItem: Experience;
	onDelete: () => void;
	index: number;
	isLast: boolean;
};

export function Person() {
	const { id } = useParams<{ id: string }>();
	const location = useLocation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { state } = location || {};
	const { valorId = "", companyName = "", logoUrl = "" } = state || {};
	const [formStatus, setFormStatus] = useState(null);
	const [queryParamFirstName = ""] = useQueryParam("firstName", StringParam);
	const [queryParamLastName = ""] = useQueryParam("lastName", StringParam);
	const theme = useTheme();

	const initializeExperience = () => ({
		organization: {
			valorId: valorId || "",
			name: companyName || "",
			domain: "",
			logoUrl: logoUrl || null,
		},
		title: "",
		startDate: null,
		endDate: null,
	});

	const { data, isLoading } = useQuery(["person", id], () => getPerson(id), {
		enabled: !!id,
	});
	const { experience, ...person } = data || {};

	const initialValues = useMemo(
		() => ({
			firstName: person?.firstName || queryParamFirstName || "",
			lastName: person?.lastName || queryParamLastName || "",
			primaryEmail: person?.primaryEmail || "",
			otherEmails: person?.otherEmails || [],
			experiences: experience || [initializeExperience()],
		}),
		[person, experience, valorId, companyName, logoUrl],
	);

	function ExperienceItemForm({
		name,
		experienceItem,
		onDelete,
		index,
		isLast,
	}: ExperienceItemFormProps) {
		const [expanded, setExpanded] = useState(!!person?.id || index === 0);
		const fieldName = (fieldPath) => `${name}.${fieldPath}`;
		return (
			<Accordion expanded={expanded}>
				<AccordionSummary
					onClick={() => setExpanded(!expanded)}
					expandIcon={<ExpandMoreIcon />}
					sx={{
						borderBottom: (t) => `1px solid ${t.palette.divider}`,
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							width: "100%",
						}}
					>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<Typography variant="h6" mr={2}>
								{experienceItem?.title
									? experienceItem.title
									: "New Experience"}
								{" - "}
								{experienceItem?.organization?.name
									? experienceItem.organization.name
									: index + 1}
							</Typography>
							{experienceItem.organization?.logoUrl && (
								<CompanyAvatar
									name={experienceItem.organization.name}
									src={experienceItem.organization.logoUrl}
									size={40}
								/>
							)}
						</Box>
					</Box>
				</AccordionSummary>
				<AccordionDetails>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
						<Field name={fieldName("title")}>
							{({ input, meta }) => (
								<TextField
									{...input}
									label="Title"
									fullWidth
									margin="normal"
									error={meta.touched && meta.error}
									helperText={meta.touched && meta.error}
									required
								/>
							)}
						</Field>
						<Field name={fieldName("organization")}>
							{({ input, meta }) => (
								<Input
									type="company"
									name="Organization"
									value={input.value}
									onChange={input.onChange}
									onBlur={input.onBlur}
									error={meta.touched && meta.error}
									required
								/>
							)}
						</Field>
						<Field name={fieldName("startDate")}>
							{({ input, meta }) => (
								<Input
									type="date"
									name="Start Date"
									value={input.value ? dayjs(input.value) : null}
									onChange={input.onChange}
									onBlur={input.onBlur}
									error={meta.touched && meta.error}
								/>
							)}
						</Field>
						<Field name={fieldName("endDate")}>
							{({ input, meta }) => (
								<Input
									type="date"
									name="End Date"
									value={input.value ? dayjs(input.value) : null}
									onChange={input.onChange}
									onBlur={input.onBlur}
									error={meta.touched && meta.error}
								/>
							)}
						</Field>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
						<Button
							color="error"
							variant="outlined"
							onClick={onDelete}
							disabled={isLast}
						>
							Remove
						</Button>
					</Box>
				</AccordionDetails>
			</Accordion>
		);
	}

	function PersonCell({
		personResult,
	}: { personResult: PersonWithExperience }) {
		const currentExperiences = personResult?.experience
			?.filter((exp) => !exp.endDate)
			.slice(0, 3);
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					width: "100%",
					alignItems: "center",
					gap: 1,
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
						py: 1,
					}}
				>
					<Tooltip
						key={`tooltip-${personResult.id}`}
						title={`Go to profile of ${personResult.firstName} ${personResult.lastName}`}
					>
						<Link
							key={`link-${personResult.id}`}
							href={`/person/${personResult.id}`}
							target="_blank"
							rel="noopener noreferrer"
							sx={{
								color: theme.palette.text.primary,
								fontWeight: theme.typography.fontWeightRegular,
							}}
						>
							<div>{`${personResult.firstName} ${personResult.lastName}`}</div>
							<div>{personResult.primaryEmail}</div>
						</Link>
					</Tooltip>
				</Box>
				<Box
					sx={{
						display: "flex",
						gap: 1,
						marginLeft: "auto",
					}}
				>
					{currentExperiences?.map((currentExperience) => (
						<Tooltip
							key={`tooltip-${currentExperience.organization.valorId}`}
							title={`Go to ${currentExperience.organization.name}`}
						>
							<Link
								key={`link-${currentExperience.organization.valorId}`}
								href={`/org/${currentExperience.organization.valorId}/summary`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<CompanyAvatar
									key={`avatar-${currentExperience.organization.valorId}`}
									size="32"
									src={currentExperience.organization?.logoUrl}
									name={currentExperience.organization.name}
								/>
							</Link>
						</Tooltip>
					))}
				</Box>
			</Box>
		);
	}

	function PersonForm() {
		const [experiencesToDelete, setExperiencesToDelete] = useState(new Set());
		const [searchResults, setSearchResults] = useState({});

		const performSearch = async (field, value, firstName = "") => {
			if (value) {
				try {
					let results;
					if (field === "name") {
						results = await searchPersons(
							null,
							value,
							firstName ? ["firstName", "lastName"] : ["lastName"],
							firstName,
						);
					} else {
						results = await searchPersons(null, value, [field]);
					}
					return results;
				} catch (error) {
					console.error("Error fetching search results:", error);
					return [];
				}
			}
			return [];
		};

		async function handlePersonOnBlur(values) {
			const nameResults = await performSearch(
				"name",
				values.lastName,
				values.firstName,
			);
			const primaryEmailResults = await performSearch(
				"primaryEmail",
				values.primaryEmail,
			);

			setSearchResults({
				name: nameResults.slice(0, 5),
				primaryEmail: primaryEmailResults.slice(0, 5),
			});
		}

		const validateEmail = (email) => {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			return emailRegex.test(email);
		};

		const validate = (values) => {
			const errors = {};

			if (!values.firstName) errors.firstName = "Required";
			if (!values.lastName) errors.lastName = "Required";
			if (!values.primaryEmail) errors.primaryEmail = "Required";
			if (!validateEmail(values.primaryEmail))
				errors.primaryEmail = "Invalid email";
			if (values.otherEmails) {
				values.otherEmails.forEach((email, index) => {
					if (!validateEmail(email)) {
						errors.otherEmails = errors.otherEmails || [];
						errors.otherEmails[index] = `Invalid email: ${email}`;
					}
				});
			}

			if (values.experiences.length === 0) {
				errors.experiences = "At lease one experience is required";
			}

			if (values.experiences) {
				const experienceErrors = values.experiences.map((exp) => {
					const expError = {};
					if (!exp.title) expError.title = "Required";
					if (!exp.organization?.valorId) expError.organization = "Required";
					return Object.keys(expError).length ? expError : undefined;
				});

				if (experienceErrors.some((error) => error)) {
					errors.experiences = experienceErrors;
				}
			}

			return errors;
		};

		const onSubmit = async (values) => {
			const personParams = {
				firstName: values.firstName,
				lastName: values.lastName,
				primaryEmail: values.primaryEmail,
				otherEmails: values.otherEmails,
			};

			const experiencesParams = values.experiences.map((exp) => ({
				id: exp?.id || undefined,
				valorId: exp.organization.valorId,
				title: exp.title,
				startDate: exp.startDate
					? dayjs(exp.startDate).format("YYYY-MM-DD")
					: null,
				endDate: exp.endDate ? dayjs(exp.endDate).format("YYYY-MM-DD") : null,
			}));

			try {
				if (person?.id) {
					await patchPerson(person.id, personParams);
					await Promise.all(
						Array.from(experiencesToDelete).map((experienceId) =>
							deleteExperience(person.id, experienceId),
						),
					);
					await Promise.all(
						experiencesParams.map((experienceParams) => {
							if (experienceParams?.id) {
								return patchExperience(
									person.id,
									experienceParams.id,
									experienceParams,
								);
							}
							return postExperience(person.id, experienceParams);
						}),
					);

					setFormStatus({
						type: "success",
						message: "Person updated successfully",
					});
					queryClient.invalidateQueries(["person", person.id]);
				} else {
					const newPerson = await createPerson(personParams);
					await Promise.all(
						experiencesParams.map((exp) => postExperience(newPerson.id, exp)),
					);

					setFormStatus({
						type: "success",
						message: "Person created successfully",
					});
					navigate(`/person/${newPerson.id}`, {
						state: { valorId, companyName },
					});
				}
			} catch (error) {
				setFormStatus({
					type: "error",
					message: "An error occurred. Please try again.",
				});
			} finally {
				setExperiencesToDelete(new Set());
			}
		};

		const handleDeletePerson = async () => {
			try {
				if (person.id) {
					await deletePerson(person.id);
					setFormStatus({
						type: "success",
						message: "Person deleted successfully",
					});
					setTimeout(
						() => navigate("/person", { state: { valorId, companyName } }),
						2000,
					);
				}
			} catch (error) {
				setFormStatus({
					type: "error",
					message: "Failed to delete person. Please try again.",
				});
			}
		};

		const handleDeleteExperience = (index, form) => {
			const currentExperiences = [...form.getState().values.experiences];
			if (currentExperiences.length > 1) {
				const deletedExperience = currentExperiences.splice(index, 1)[0];
				if (deletedExperience?.id) {
					setExperiencesToDelete(
						(prev) => new Set([...prev, deletedExperience.id]),
					);
				}
				form.change("experiences", currentExperiences);
				form.mutators.setDirty(true);
			}
		};

		return (
			<Form
				onSubmit={onSubmit}
				validate={validate}
				initialValues={initialValues}
				render={({
					handleSubmit,
					form,
					submitting,
					pristine,
					values,
					hasValidationErrors,
				}) => (
					<form onSubmit={handleSubmit}>
						<Card elevation={4} sx={{ padding: 2, marginBottom: 2 }}>
							<Box>
								<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
									<Typography variant="h5">Person</Typography>
									<Field name="firstName">
										{({ input, meta }) => (
											<TextField
												{...input}
												fullWidth
												label="First Name"
												error={meta.touched && !!meta.error}
												helperText={meta.touched && meta.error}
												onBlur={(e) => {
													const currvalues = form.getState().values;
													input.onBlur(e);
													handlePersonOnBlur(currvalues);
												}}
												required
											/>
										)}
									</Field>
									<Field name="lastName">
										{({ input, meta }) => (
											<Box>
												<TextField
													{...input}
													fullWidth
													label="Last Name"
													error={meta.touched && !!meta.error}
													helperText={meta.touched && meta.error}
													onBlur={(e) => {
														const currvalues = form.getState().values;
														input.onBlur(e);
														if (input.value) {
															handlePersonOnBlur(currvalues);
														} else {
															setSearchResults({
																name: [],
																primaryEmail: [...searchResults.primaryEmail],
															});
														}
													}}
													required
												/>
											</Box>
										)}
									</Field>
									<Field name="primaryEmail">
										{({ input, meta }) => (
											<TextField
												{...input}
												fullWidth
												label="Primary Email"
												error={meta.touched && !!meta.error}
												helperText={meta.touched && meta.error}
												onBlur={(e) => {
													const currvalues = form.getState().values;
													input.onBlur(e);
													if (input.value) {
														handlePersonOnBlur(currvalues);
													} else {
														setSearchResults({
															name: [...searchResults.name],
															primaryEmail: [],
														});
													}
												}}
												required
											/>
										)}
									</Field>
									<Field name="otherEmails">
										{({ input, meta }) => (
											<Autocomplete
												multiple
												freeSolo
												options={[]}
												value={input.value}
												onChange={(event, newValue) => input.onChange(newValue)}
												renderTags={(value, getTagProps) =>
													value.map((option, index) => (
														<Chip
															key={`${option}`}
															variant="outlined"
															label={option}
															{...getTagProps({ index })}
															color={
																option === values.primaryEmail
																	? "primary"
																	: "default"
															}
														/>
													))
												}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Other Emails (Press Enter to Add)"
														error={meta.touched && !!meta.error}
														helperText={
															meta.touched && meta.error
																? meta.error.join(", ")
																: null
														}
													/>
												)}
											/>
										)}
									</Field>
									{!person?.id &&
										(searchResults.name?.length > 0 ||
											searchResults.primaryEmail?.length > 0) && (
											<Box>
												<Alert
													severity="warning"
													onClose={() => {
														setSearchResults({});
													}}
												>
													<Typography variant="subtitle1">
														Existing People
													</Typography>
													<Grid container spacing={2}>
														{!!searchResults.name?.length && (
															<Grid item>
																<Typography variant="caption">
																	{"Matches on "}
																	{form.getState().values.firstName
																		? "Name: "
																		: "Last Name: "}
																	{form.getState().values.firstName
																		? `${form.getState().values.firstName} ${form.getState().values.lastName}`
																		: form.getState().values.lastName}
																</Typography>
																<List dense>
																	{searchResults.name?.map((result) => (
																		<ListItem
																			key={`item-${result.id}`}
																			component="li"
																		>
																			<PersonCell personResult={result} />
																		</ListItem>
																	))}
																</List>
															</Grid>
														)}
														{!!searchResults.primaryEmail?.length && (
															<Grid item>
																<Typography variant="caption">
																	{"Matches on "}
																	{`Primary Email: ${form.getState().values.primaryEmail}`}
																</Typography>
																<List dense>
																	{searchResults.primaryEmail?.map((result) => (
																		<ListItem
																			key={`item-${result.id}`}
																			component="li"
																		>
																			<PersonCell personResult={result} />
																		</ListItem>
																	))}
																</List>
															</Grid>
														)}
													</Grid>
												</Alert>
											</Box>
										)}
									<Typography variant="h5">Experience</Typography>
								</Box>
								{values.experiences.map((exp, index) => (
									<ExperienceItemForm
										key={exp.id || index}
										name={`experiences[${index}]`}
										experienceItem={exp}
										onDelete={() => handleDeleteExperience(index, form)}
										index={index}
										isLast={values.experiences.length === 1}
									/>
								))}
								<Button
									variant="outlined"
									color="primary"
									startIcon={<AddIcon />}
									sx={{ marginTop: 2 }}
									onClick={() => {
										const newExperience = initializeExperience();
										form.change("experiences", [
											...values.experiences,
											newExperience,
										]);
									}}
								>
									Add Experience
								</Button>
							</Box>
						</Card>
						{formStatus && (
							<Alert severity={formStatus.type} sx={{ mb: 2 }}>
								{formStatus.message}
							</Alert>
						)}
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								gap: 2,
								justifyContent: "space-between",
							}}
						>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={
									submitting ||
									(pristine && experiencesToDelete.size === 0) ||
									hasValidationErrors
								}
							>
								{person?.id ? "Save Changes" : "Create Person"}
							</Button>
							{person?.id && (
								<Button
									onClick={handleDeletePerson}
									color="error"
									variant="outlined"
								>
									Delete Person
								</Button>
							)}
						</Box>
					</form>
				)}
			/>
		);
	}

	if (isLoading) {
		return <Progress />;
	}

	return (
		<Page>
			<Page.Title>{person?.id ? "Edit Person" : "Create Person"}</Page.Title>
			<Page.Content>
				{valorId ? (
					<Button
						sx={{ marginBottom: 2 }}
						variant="outlined"
						onClick={() => navigate(`/org/${valorId}/contacts`)}
					>
						Back to {companyName}
					</Button>
				) : null}
				<PersonForm />
			</Page.Content>
		</Page>
	);
}

export default Person;
