import {
	type Organization,
	getOrganizationFeaturesById,
	getOrganizationInteractions,
} from "@/api/Organization";
import LabeledDetail from "@/components/LabeledDetail";
import SectorChip from "@/components/SectorChip";
import Section from "@/ui/atoms/Section";
import UserAvatar from "@/ui/atoms/UserAvatar";
import { formatTotal } from "@/utils/numberFormat";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Grid, Link, Skeleton, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useQuery } from "react-query";
import MarketMapsFolio from "./MarketMapsFolio";
import ModelFolio from "./ModelFolio";
import SignalFolio from "./SignalsFolio";

interface Props {
	company: Organization;
}
export function GeneralInformation({ company }: Props) {
	const { valorId } = company;
	const EMPLOYEE_FEATURE_TYPE = "li_org_employee_total";

	const { data: mostRecentEmployeeCount, isLoading: isLoadingEmployee } =
		useQuery(
			["CURRENT_EMPLOYEE", EMPLOYEE_FEATURE_TYPE, valorId],
			async () => {
				const response = await getOrganizationFeaturesById(valorId, [
					EMPLOYEE_FEATURE_TYPE,
				]);
				const val = null;
				if (response?.features) {
					const employees = response.features.reduce(
						(x) => x.type === EMPLOYEE_FEATURE_TYPE,
					)?.data;
					const { length } = employees;
					return employees[length - 1].value;
				}
				return val;
			},
			{
				retry: 1,
				refetchOnWindowFocus: false,
				refetchOnMount: false,
				refetchOnReconnect: false,
				staleTime: Number.POSITIVE_INFINITY,
			},
		);

	const { data: recentInteractions = [], isLoading: isLoadingInteractions } =
		useQuery(
			["RecentInteractions", valorId],
			async () => getOrganizationInteractions(valorId),
			{
				retry: 1,
				refetchOnWindowFocus: false,
				refetchOnMount: false,
				refetchOnReconnect: false,
				staleTime: Number.POSITIVE_INFINITY,
			},
		);

	let locationText = "";
	if (company.country !== "US") {
		locationText = [company?.city, company?.state, company?.country]
			.filter(Boolean)
			.join(", ");
	} else if (!company.city && !company.state) {
		locationText = "US";
	} else {
		locationText = [company?.city, company?.state].filter(Boolean).join(", ");
	}
	locationText = locationText.toUpperCase();

	const interactionTypeIcon = {
		email: <EmailIcon />,
		meeting: <CalendarMonthIcon />,
	};

	return (
		<Section>
			<Section.Title>General Information</Section.Title>
			<Section.Content>
				<Grid spacing={2} container>
					<Grid item xs={6} sm={3} lg={3}>
						<LabeledDetail title="WEBSITE">
							<Link
								data-cy="general-information__website-link"
								href={`https://www.${company.domain}`}
							>
								{company.domain}
							</Link>
						</LabeledDetail>
					</Grid>
					<Grid item xs={6} sm={3} lg={3}>
						<LabeledDetail title="EMPLOYEES">
							{isLoadingEmployee ? (
								<Skeleton
									data-cy="general-information__employees-loading"
									width="48px"
									variant="text"
								/>
							) : mostRecentEmployeeCount ? (
								<Typography
									data-cy="general-information__employees-count"
									variant="body2"
								>
									{formatTotal(mostRecentEmployeeCount)}
								</Typography>
							) : (
								<Typography
									data-cy="general-information__employees-not-available"
									variant="overline"
								>
									---
								</Typography>
							)}
						</LabeledDetail>
					</Grid>
					<Grid item xs={6} sm={3} lg={3}>
						<LabeledDetail title="LOCATION">
							{locationText.length ? (
								<Typography
									data-cy="general-information__location"
									variant="subtitle2"
								>
									{locationText}
								</Typography>
							) : (
								<Typography
									data-cy="general-information__location-not-available"
									variant="overline"
								>
									---
								</Typography>
							)}
						</LabeledDetail>
					</Grid>
					<Grid item xs={6} sm={3} lg={3}>
						<LabeledDetail title="FOUNDED">
							<Typography
								data-cy="general-information__founded"
								variant="subtitle2"
							>
								{company.yearFounded ? (
									company.yearFounded
								) : (
									<Typography
										data-cy="general-information__founded-not-available"
										variant="overline"
									>
										---
									</Typography>
								)}
							</Typography>
						</LabeledDetail>
					</Grid>
					<Grid item xs={12}>
						<LabeledDetail
							title="DESCRIPTION"
							expandable={!!company.description}
						>
							<Typography
								data-cy="general-information__description-text"
								variant="body2"
							>
								{company.description ? (
									company.description
								) : (
									<Typography
										data-cy="general-information__description-not-available"
										variant="overline"
									>
										---
									</Typography>
								)}
							</Typography>
						</LabeledDetail>
					</Grid>

					<Grid item xs={6} sm={6} md={4} lg={4}>
						<LabeledDetail title="MONITORING SERVICE LEAD">
							<Box
								data-cy="general-information__monitoring-service-lead"
								mt={1}
								display="inline-flex"
								flexWrap="wrap"
								sx={{ gap: "8px" }}
							>
								{company.monitoringServiceLead ? (
									<UserAvatar
										user={company.monitoringServiceLead}
										displayTooltip
									/>
								) : (
									<Typography
										data-cy="general-information__monitoring-service-lead-not-available"
										variant="overline"
									>
										---
									</Typography>
								)}
							</Box>
						</LabeledDetail>
					</Grid>

					<Grid item xs={6} sm={6} md={4} lg={4}>
						<LabeledDetail
							title="LAST INTERACTION(S)"
							expandable={recentInteractions.length > 1}
						>
							<Box
								data-cy="general-information__last-interactions-container"
								mt={1}
								display="flex"
								flexDirection="column"
								flexWrap="wrap"
								sx={{ gap: "8px" }}
							>
								{isLoadingInteractions ? (
									<Skeleton
										data-cy="general-information__last-interactions-loading"
										variant="rectangular"
									/>
								) : recentInteractions?.length ? (
									recentInteractions.map(
										({ id, date, interactionType, persons }, index) => (
											<Box
												data-cy={`general-information__last-interactions-item-${index}`}
												key={id}
												display="flex"
												alignItems="center"
												gap="2px"
											>
												{persons.slice(0, 2).map((p) => (
													<UserAvatar
														data-cy={`general-information__last-interactions-avatar-${index}-${p.id}`}
														style={{ height: "32px", width: "32px" }}
														key={p.id}
														user={p}
														displayTooltip
													/>
												))}
												{persons.length > 2 ? (
													<Tooltip
														title={persons
															.slice(2)
															.map((p) => `${p.firstName} ${p.lastName}`)
															.join(", ")}
													>
														<Box display="flex" padding={0.25}>
															<Typography
																sx={{ cursor: "pointer" }}
																variant="body2"
															>
																+{persons.length - 2}
															</Typography>
														</Box>
													</Tooltip>
												) : null}

												{interactionTypeIcon[interactionType]}
												<Typography
													data-cy={`general-information__last-interactions-date-${index}`}
												>
													{dayjs(date).format("MM/DD/YY")}
												</Typography>
											</Box>
										),
									)
								) : null}
							</Box>
						</LabeledDetail>
					</Grid>
					<Grid item xs={12} md={4} lg={4}>
						<LabeledDetail title="VALOR INDUSTRY CLASSIFICATION">
							<Box
								data-cy="general-information__industry-classification"
								mt={1}
								display="inline-flex"
								flexWrap="wrap"
								sx={{ gap: "8px", height: "100%" }}
							>
								{company.predictedSectors?.length ? (
									company.predictedSectors?.map((s, index) => (
										<SectorChip key={s} sectorType={s} />
									))
								) : (
									<Typography
										data-cy="general-information__industry-classification-not-available"
										variant="overline"
									>
										---
									</Typography>
								)}
							</Box>
						</LabeledDetail>
					</Grid>

					<ModelFolio company={company} />
					<SignalFolio company={company} />
					<MarketMapsFolio company={company} />
				</Grid>
			</Section.Content>
		</Section>
	);
}

export default GeneralInformation;
