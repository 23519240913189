import { getAllUsers } from "@/api/Users";
import SAYT from "@/components/SAYT";
import UserAvatar from "@/ui/atoms/UserAvatar";
import renderTextWithHighlighting from "@/utils/renderTextWithHighlighting";
import { Box, Chip } from "@mui/material";
import React from "react";

let users = null;
function getUsers() {
	if (users) {
		return users;
	}
	users = getAllUsers();
	return users;
}

type UserValue = {
	id: string;
	firstName: string;
	lastName: string;
	profilePicture: string;
};

type UserInputProps = {
	value?: UserValue;
	onChange: (value: UserValue | UserValue[]) => void;
	multiple?: boolean;
	required?: boolean;
	disabled?: boolean;
	label?: string;
};

export default function UserInput({
	value,
	onChange,
	multiple,
	required,
	disabled,
	label,
	error,
	dataCy,
}: UserInputProps) {
	// add sayt
	return (
		<SAYT
			required={required}
			value={multiple ? value || [] : value || null}
			onChange={onChange}
			multiple={multiple}
			disabled={disabled}
			style={{ width: "100%" }}
			label={label}
			search={() => getUsers()}
			getOptionSelected={(option, selectedOption) =>
				option.id === selectedOption?.id
			}
			getOptionLabel={(option) =>
				option ? `${option.firstName} ${option.lastName}` : ""
			}
			useDefaultFilter
			renderTags={(tags, getTagProps) => {
				const nonNullTags = tags.filter((tag) => Boolean(tag));
				return (
					<>
						{nonNullTags.map((option, index) => (
							<Chip
								key={option.id}
								avatar={
									<UserAvatar
										user={option}
										style={{
											width: "25px",
											height: "25px",
											fontSize: "1em",
										}}
									/>
								}
								sx={{ margin: 0.5 }}
								label={`${option.firstName} ${option.lastName}`}
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...getTagProps({ index })}
							/>
						))}
					</>
				);
			}}
			renderOption={(option, { inputValue }) => (
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					gap="8px"
					py={1}
				>
					<UserAvatar
						user={{
							name: `${option.firstName} ${option.lastName}`,
							...option,
						}}
					/>
					<span>
						{renderTextWithHighlighting(
							`${option.firstName} ${option.lastName}`,
							inputValue,
						)}
					</span>
				</Box>
			)}
			data-cy={dataCy}
			error={error}
		/>
	);
}
