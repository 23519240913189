import {
	Box,
	Button,
	Modal,
	TextField,
	type Theme,
	Typography,
	createStyles,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			"& .MuiChip-deletable:focus": {
				backgroundColor: "none !important",
			},
		},
		topBox: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},
		edit: {
			fontWeight: theme.typography.fontWeightBold,
		},
		title: {
			color: theme.palette.text.primary,
			fontWeight: theme.typography.fontWeightBold,
			fontSize: "12px",
		},
		paper: {
			position: "absolute",
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			width: "100%",
			maxWidth: "640px",
			height: "100%",
			maxHeight: "520px",
			padding: theme.spacing(4),
			backgroundColor: theme.palette.background.paper,
			borderRadius: theme.shape.borderRadius,
			outline: "none",
			boxShadow: theme.shadows[5],
		},
		upperModalContainer: {
			display: "flex",
			flexDirection: "column",
		},
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		truncatedText: {
			display: "-webkit-box",
			overflow: "hidden",
			color: theme.palette.text.primary,
			fontSize: "14px",
			"-webkit-box-orient": "vertical",
			"-webkit-line-clamp": "3",
		},
		modalTitle: {
			marginBottom: theme.spacing(4),
			fontWeight: theme.typography.fontWeightBold,
		},
		modalButtonContainer: {
			display: "flex",
			gap: theme.spacing(1),
			justifyContent: "flex-end",
			justifySelf: "flex-end",
			width: "100%",
		},
	}),
);

interface Props {
	description: string;
	onSubmit(val: string): void;
}

export default function DescriptionEditor(props: Props) {
	const { onSubmit, description } = props;
	const [modalDescription, setModalDescription] = useState("");

	const isSameDescription =
		description.length === modalDescription.length &&
		description === modalDescription;

	const [openModal, setModalOpen] = useState(false);
	const classes = useStyles();

	const onOpenModal = () => {
		setModalDescription(description);
		setModalOpen(true);
	};

	const onCloseModal = () => {
		setModalDescription(description);
		setModalOpen(false);
	};

	const submitDescription = () => {
		onSubmit(modalDescription);
		setModalOpen(false);
	};

	return (
		<>
			<Box className={classes.container}>
				<Box className={classes.topBox}>
					<Typography className={classes.title}>DESCRIPTION</Typography>
					<Button
						onClick={onOpenModal}
						className={classes.edit}
						color="primary"
					>
						EDIT
					</Button>
				</Box>
				<Box>
					<Typography className={classes.truncatedText}>
						{description}
					</Typography>
				</Box>
			</Box>
			<Modal open={openModal} onClose={onCloseModal} className={classes.modal}>
				<Box className={classes.paper}>
					<Box className={classes.upperModalContainer}>
						<Typography variant="h1" className={classes.modalTitle}>
							Edit Description
						</Typography>
						<Typography className={classes.title}>DESCRIPTION</Typography>
						<Box>
							<TextField
								fullWidth
								autoFocus
								multiline
								maxRows={14}
								value={modalDescription}
								onChange={(event) => setModalDescription(event.target.value)}
							/>
						</Box>
					</Box>
					<Box className={classes.modalButtonContainer}>
						<Button color="primary" onClick={onCloseModal}>
							Cancel
						</Button>
						<Button
							color="primary"
							disabled={isSameDescription}
							onClick={submitDescription}
						>
							Accept
						</Button>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
