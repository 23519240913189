import { Box, Card, Grid, Link, Typography } from "@mui/material";
import React from "react";

export function HelpPage() {
	return (
		<Box
			sx={{
				py: 4,
				px: 5,
			}}
		>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Box
						sx={{
							display: "flex",
							py: 2,
							px: 0,
						}}
					>
						<Typography
							sx={{
								color: (theme) => theme.palette.text.secondary,
								fontSize: "32px",
							}}
						>
							Help Page
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} lg={3}>
					<Card>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignContent: "center",
									padding: 3,
									borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
								}}
							>
								<Typography
									sx={{
										px: 2,
										color: (theme) => theme.palette.text.secondary,
									}}
									variant="h2"
								>
									Adding to iPhone or iPad
								</Typography>
							</Box>
							<Box
								sx={{
									padding: 3,
								}}
							>
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
								<Link
									target="_blank"
									href="https://v-labs.notion.site/Adding-vOS-to-your-iPhone-or-iPad-4d617a72155f44ab87c52ac8b34493da?pvs=4"
									sx={{
										color: (theme) => theme.palette["13"],
									}}
								>
									{" "}
									Click Here
								</Link>
							</Box>
						</Box>
					</Card>
				</Grid>

				<Grid item xs={12} lg={3}>
					<Card>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignContent: "center",
									padding: 3,
									borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
								}}
							>
								<Typography
									sx={{
										px: 2,
										color: (theme) => theme.palette.text.secondary,
									}}
									variant="h2"
								>
									Chrome Extension
								</Typography>
							</Box>
							<Box
								sx={{
									padding: 3,
								}}
							>
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
								<Link
									target="_blank"
									href="https://v-labs.notion.site/vOS-Chrome-Extension-1f63cecc14b54e9a9f29c1efb3ad732a?pvs=4"
									sx={{
										color: (theme) => theme.palette["13"],
									}}
								>
									{" "}
									Click Here
								</Link>
							</Box>
						</Box>
					</Card>
				</Grid>

				<Grid item xs={12} lg={3}>
					<Card>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignContent: "center",
									padding: 3,
									borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
								}}
							>
								<Typography
									sx={{
										px: 2,
										color: (theme) => theme.palette.text.secondary,
									}}
									variant="h2"
								>
									Deal Search
								</Typography>
							</Box>
							<Box
								sx={{
									padding: 3,
								}}
							>
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
								<Link
									target="_blank"
									href="https://v-labs.notion.site/Deal-Search-Tool-c683733ddd5a44f686ee1ff3f88d71ef?pvs=4"
									sx={{
										color: (theme) => theme.palette["13"],
									}}
								>
									Click Here
								</Link>
							</Box>
						</Box>
					</Card>
				</Grid>

				<Grid item xs={12} lg={3}>
					<Card>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignContent: "center",
									padding: 3,
									borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
								}}
							>
								<Typography
									sx={{
										px: 2,
										color: (theme) => theme.palette.text.secondary,
									}}
									variant="h2"
								>
									Synaptic Knowledge Base
								</Typography>
							</Box>
							<Box
								sx={{
									padding: 3,
								}}
							>
								{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
								<Link
									target="_blank"
									href="https://synaptic-knowledge-base.notion.site/synaptic-knowledge-base/Synaptic-Knowledge-Base-367a880f426d461c85cd16af857e43d8"
									sx={{
										color: (theme) => theme.palette["13"],
									}}
								>
									Click Here
								</Link>
							</Box>
						</Box>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}

export default HelpPage;
