import { useAuth } from "@/hooks/useAuth";
import React, { createContext, useContext, useEffect, useRef } from "react";
import io from "socket.io-client";
import config from "../config";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);
export function SocketProvider({ path = "/socket-io", children }) {
	const { ingress } = config.api;
	const { firebaseToken } = useAuth();
	const socketRef = useRef(null);

	const baseUrl =
		process.env.NODE_ENV === "development" ? "http://localhost:8080" : ingress;

	useEffect(() => {
		// Only create socket connection if we have a token
		if (!firebaseToken) {
			return;
		}

		// Prevent multiple socket instances
		if (!socketRef.current) {
			socketRef.current = io(baseUrl, {
				path,
				transports: ["websocket", "polling", "flashsocket"],
				auth: {
					token: firebaseToken,
				},
				autoConnect: false, // Prevent auto-connection
			});
		}

		// Ensure token is up to date
		socketRef.current.auth.token = firebaseToken;

		// Connect the socket
		socketRef.current.connect();

		// Cleanup function
		return () => {
			if (socketRef.current) {
				return socketRef.current.disconnect();
			}
		};
	}, [firebaseToken, baseUrl, path]);

	return (
		<SocketContext.Provider value={socketRef.current}>
			{children}
		</SocketContext.Provider>
	);
}
