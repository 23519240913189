import type { Dayjs } from "dayjs";
import React from "react";
import ActivityEvent from "./ActivityEvent";

export default function TaskDetails({
	author,
	when,
	name,
	description,
	entityName,
}: {
	author: {
		firstName: string;
		lastName: string;
	};
	when: Dayjs;
	name?: {
		from: string;
		to: string;
	};
	description?: {
		from: string;
		to: string;
	};
	entityName: string;
}) {
	let change;
	if (name) {
		change = "name";
	}
	if (description) {
		change = "description";
	}
	if (name && description) {
		change = "name and description";
	}
	return (
		<ActivityEvent>
			<ActivityEvent.Author author={author} />
			{" updated the "}
			<ActivityEvent.Value>{change}</ActivityEvent.Value>
			{` of the ${entityName} `}
			<ActivityEvent.When when={when} />
		</ActivityEvent>
	);
}
