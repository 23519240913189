import type { SignalModelPipeline } from "@/api/Reporting";
import { Card, TableRow, useMediaQuery, useTheme } from "@mui/material";
import React, { useMemo } from "react";

import {
	type ColumnFiltersState,
	type SortingState,
	type VisibilityState,
	getCoreRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	useReactTable,
} from "@tanstack/react-table";

import {
	VirtualTableBody,
	VirtualTableContainer,
	depageData,
} from "@/components/InfiniteTable";
import { SelectFilter } from "@/components/Table/CustomFilters/Select";
import { Toolbar as TableToolbar } from "@/components/Table/Header";
import HeaderCell from "@/components/Table/Header/Cell";
import OpportunityNoteCell from "@/components/Table/OpportunityNote/OpportunityNoteCell";
import { CompanyCell, DateCell, TextCell } from "@/components/Table/Primitives";
import MoicCell from "@/components/Table/Primitives/MoicCell";
import MoneyCell from "@/components/Table/Primitives/MoneyCell";
import MultiUserCell from "@/components/Table/Primitives/MultiUserCell";
import { StickyTable, StickyTableHead } from "@/components/Table/StickyTable";
import CenteredProgress from "@/ui/atoms/CenteredProgress";

export default function MantisTable({
	data,
	isLoading,
	isFetching,
	isFetchingNextPage,
	hasNextPage,
	fetchNextPage,
	sorting,
	columnFilters,
	columnVisibility,
	onSortingChange,
	onColumnFiltersChange,
	onColumnVisibilityChange,
	onGlobalFilterChange,
}: {
	data: SignalModelPipeline[];
	isLoading?: boolean;
	isFetching?: boolean;
	isFetchingNextPage?: boolean;
	hasNextPage?: boolean;
	sorting: SortingState;
	columnFilters: ColumnFiltersState;
	columnVisibility: VisibilityState;
	fetchNextPage?: () => void;
	onSortingChange?: (s: SortingState) => void;
	onColumnFiltersChange?: (c: ColumnFiltersState) => void;
	onColumnVisibilityChange?: (cv: VisibilityState) => void;
	onGlobalFilterChange?: (globalFilter: string | null) => void;
}) {
	const modelId = "1035901f-04fa-4155-bf5c-49f5ee035c33";
	const theme = useTheme();
	const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

	const columnDef = useMemo(
		() =>
			[
				{
					header: "Company",
					accessorFn: (row) => ({
						...row.organization,
						description: row.description,
					}),
					cell: (prop) => <CompanyCell value={prop.getValue()} />,
					id: "organization",
					minSize: isSmDown ? 48 : 250,
					size: isSmDown ? 48 : 250,
				},
				{
					header: "Status",
					accessorKey: "status",
					cell: (prop) => <TextCell value={prop.getValue()} />,
					id: "status",
					enableColumnFilter: true,
					filter: (prop) => (
						<SelectFilter
							getFilterValue={prop.column.getFilterValue}
							setFilterValue={prop.column.setFilterValue}
							getFacetedUniqueValues={prop.column.getFacetedUniqueValues}
							multiple
							options={["Outreach", "Quality Review", "Connected", "Access"]}
						/>
					),
				},
				// {
				//   header: "Date Added",
				//   accessorKey: "dateAdded",
				//   cell: (prop) => <DateCell value={prop.getValue()} format="MMMM YYYY" />,
				//   id: "dateAdded",
				// },
				{
					header: "MOIC Score",
					accessorKey: "predictedMoic",
					cell: (prop) => (
						<MoicCell
							value={prop.getValue()}
							signalType="signal_mantis_prediction"
						/>
					),
					id: "predictedMoic",
					meta: {
						sortType: "numeric",
					},
				},
				{
					header: "Owners",
					accessorKey: "owners",
					cell: (prop) => <MultiUserCell value={prop.getValue()} />,
					id: "owners",
					minSize: 200,
				},
				{
					header: "Notes",
					accessorFn: (row) => row.entityId,
					cell: (prop) => (
						<OpportunityNoteCell opportunityId={prop.getValue()} />
					),
					id: "notes",
					minSize: 200,
				},
				{
					header: "Amount",
					accessorKey: "amount",
					cell: (prop) => (
						<MoneyCell value={prop.getValue()} isFundingRound={false} />
					),
					id: "amount",
				},
				{
					header: "Close Date",
					accessorKey: "closeDate",
					cell: (prop) => <DateCell value={prop.getValue()} />,
					id: "closeDate",
				},
				{
					header: "Valor Security Type",
					accessorKey: "dealType",
					cell: (prop) => <TextCell value={prop.getValue()} />,
					id: "dealType",
				},
			].map((x) => ({
				enableColumnFilter: false,
				enableSorting: false,
				filterFn: () => true,
				...x,
			})),
		[modelId, isSmDown],
	);

	const pages = useMemo(() => depageData(data), [data]);

	// const totalCount = data?.pages[0]?.totalCount;

	const table = useReactTable({
		data: pages,
		columns: columnDef,
		state: {
			sorting,
			columnFilters,
			columnVisibility: {
				...columnVisibility,
			},
		},
		onColumnFiltersChange,
		onGlobalFilterChange,
		onColumnVisibilityChange,
		onSortingChange,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
	});

	return (
		<Card
			sx={{
				maxHeight: "100vh",
				overflowY: "hidden",
			}}
		>
			{(isLoading || isFetchingNextPage) && (
				<CenteredProgress
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				/>
			)}

			<TableToolbar
				columnHeaders={columnDef}
				sorting={sorting}
				columnFilters={columnFilters}
				columnVisibility={columnVisibility}
				onRemoveColumnFilters={() => onColumnFiltersChange([])}
				onRemoveSorting={() => onSortingChange([])}
				onRemoveVisibility={() => onColumnVisibilityChange({})}
				readOnly
			/>

			<VirtualTableContainer
				height="calc(100vh - 200px)"
				onScrollBottomReached={() => {
					if (!isFetching && hasNextPage) {
						fetchNextPage?.();
					}
				}}
			>
				<StickyTable>
					<StickyTableHead>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<HeaderCell
										key={header.id}
										header={header}
										column={header.column}
										table={table}
									/>
								))}
							</TableRow>
						))}
					</StickyTableHead>
					<VirtualTableBody
						rows={table.getRowModel().rows}
						estimateSize={64}
						table={table}
					/>
				</StickyTable>
			</VirtualTableContainer>
		</Card>
	);
}
