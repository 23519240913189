import { buildURLParameters, searchParamsObject } from "@/utils/general";
import axios from "axios";
import dayjs from "dayjs";
import type { components, operations, paths } from "./index";
import download from "./utils/Download";

export type HTTPValidationError = components["schemas"]["HTTPValidationError"];

type GetProcessesResponses =
	operations["get_processes_api_processes__get"]["responses"];
export type Processes =
	GetProcessesResponses["200"]["content"]["application/json"];
export const getProcesses = async (
	limit = 250,
	marker: string | null = null,
	sort: string[] | null = null,
	filter: { [key: string]: string | any } | null = null,
): Promise<Processes> => {
	const params = searchParamsObject({
		limit,
		marker,
		"sort[]": sort || null,
		filter: filter ? JSON.stringify(filter) : null,
	});
	const response = await axios.get("/api/processes", {
		params,
	});
	return response.data.data;
};

type CreateProcessEndpoint = operations["create_process_api_processes__post"];
export type CreateProcessRequestBody = CreateProcessEndpoint["requestBody"];
export type CreateProcessResponses = CreateProcessEndpoint["responses"];
export type CreateProcessResult =
	CreateProcessResponses["200"]["content"]["application/json"];
export const createProcess = async (
	processValues: CreateProcessRequestBody,
): Promise<CreateProcessResult> => {
	const response = await axios.post<CreateProcessResult>(
		"/api/processes",
		processValues,
	);
	return response.data;
};

type UpdateProcessEndpoint =
	operations["update_process_api_processes__process_id__put"];
export type UpdateProcessRequestBody = UpdateProcessEndpoint["requestBody"];
export type UpdateProcessResponses = UpdateProcessEndpoint["responses"];
export type UpdateProcessResult =
	UpdateProcessResponses["200"]["content"]["application/json"];
export const updateProcess = async (
	processValues: UpdateProcessRequestBody,
): Promise<UpdateProcessResult> => {
	const response = await axios.put<UpdateProcessResult>(
		`/api/processes/${processValues.id}`,
		processValues,
	);
	return response.data;
};

export const createFavorite = async (processId: string): Promise<void> => {
	await axios.post(`/api/processes/${processId}/favorite`);
};

export const removeFavorite = async (processId: string): Promise<void> => {
	await axios.delete(`/api/processes/${processId}/favorite`);
};

export const deleteProcess = async (processId: string): Promise<void> => {
	await axios.delete(`/api/processes/${processId}`);
};

export const createProcessView = async (processId: string): Promise<void> => {
	await axios.post(`/api/processes/${processId}/view`);
};

export const createField = async (
	processId: string,
	field: components["schemas"]["ProcessField"],
): Promise<void> => {
	const res = await axios.post(`/api/processes/${processId}/fields`, field);
	return res.data;
};

export const linkField = async (
	processId: string,
	fieldId: string,
): Promise<void> => {
	const res = await axios.post(`/api/processes/${processId}/fields/${fieldId}`);
	return res.data;
};

export const updateField = async (
	processId: string,
	field: components["schemas"]["ProcessField"],
): Promise<void> => {
	await axios.patch(`/api/processes/${processId}/fields/${field.id}`, field);
};

export const updateFields = async (
	processId: string,
	fields: components["schemas"]["ProcessField"][],
): Promise<void> => {
	await axios.patch(`/api/processes/${processId}/fields`, fields);
};

export const deleteField = async (
	processId: string,
	fieldId: string,
): Promise<void> => {
	await axios.delete(`/api/processes/${processId}/fields/${fieldId}`);
};

export const createChoice = async (
	processId: string,
	fieldId: string,
	choice: components["schemas"]["ProcessChoice"],
): Promise<void> => {
	await axios.post(
		`/api/processes/${processId}/fields/${fieldId}/choices`,
		choice,
	);
};

export const updateChoice = async (
	processId: string,
	fieldId: string,
	choice: components["schemas"]["ProcessChoice"],
): Promise<void> => {
	await axios.patch(
		`/api/processes/${processId}/fields/${fieldId}/choices/${choice.id}`,
		choice,
	);
};

export const deleteChoice = async (
	processId: string,
	fieldId: string,
	choiceId: string,
): Promise<void> => {
	await axios.delete(
		`/api/processes/${processId}/fields/${fieldId}/choices/${choiceId}`,
	);
};

type GetTaskResponses =
	operations["get_task_api_processes__process_id__tasks__task_id__get"]["responses"];
export type Task = GetTaskResponses["200"]["content"]["application/json"];
export const getTask = async (processId, taskId): Promise<Task> => {
	const response = await axios.get<Task>(
		`/api/processes/${processId}/tasks/${taskId}`,
	);
	return response.data;
};

export const getResponseLinkedTasks = async (
	responseIds: string[],
): Promise<TaskList> => {
	const params = buildURLParameters({
		responseIds,
	});
	const response = await axios.get<TaskList>(
		`/api/processes/responses/tasks?${params}`,
	);
	return response.data;
};

type GetTaskSurveyResponses =
	operations["get_task_survey_responses_api_processes__process_id__tasks__task_id__responses_get"]["responses"];
export type ProcessTaskSurveyResponse =
	GetTaskSurveyResponses["200"]["content"]["application/json"];
export const getTaskPossibleResponses = async ({
	processId,
	taskId,
	valorIds,
	userIds,
}: {
	processId: string;
	taskId: string;
	valorIds: string[];
	userIds?: string[];
}): Promise<Array<ProcessTaskSurveyResponse>> => {
	const params = buildURLParameters({
		processId,
		taskId,
		valorIds,
		userIds,
	});
	const response = await axios.get(
		`/api/processes/${processId}/tasks/${taskId}/responses?${params}`,
	);
	return response.data;
};

export const getTaskLinkedResponses = async ({
	processId,
	taskId,
	valorIds,
	userIds,
}: {
	processId: string;
	taskId: string;
	valorIds: string[];
	userIds?: string[];
}): Promise<Array<ProcessTaskSurveyResponse>> => {
	const params = buildURLParameters({
		processId,
		taskId,
		valorIds,
		userIds,
	});
	const response = await axios.get(
		`/api/processes/${processId}/tasks/${taskId}/responses/linked?${params}`,
	);
	return response.data;
};

type CreateTaskSurveyResponse =
	operations["create_task_response_api_processes__process_id__tasks__task_id__responses_post"];
export type ProcessTaskResponseView =
	CreateTaskSurveyResponse["200"]["content"]["application/json"];
export const createTaskSurveyResponse = async ({
	processId,
	taskId,
	responseId,
}: {
	processId: string;
	taskId: string;
	responseId: string;
}): Promise<ProcessTaskResponseView> => {
	const response = await axios.post(
		`/api/processes/${processId}/tasks/${taskId}/responses`,
		{ responseId },
	);
	return response.data;
};

export const deleteTaskSurveyResponse = async ({
	processId,
	taskId,
	responseId,
}: {
	processId: string;
	taskId: string;
	responseId: string;
}): Promise<void> => {
	const response = await axios.delete(
		`/api/processes/${processId}/tasks/${taskId}/responses/${responseId}`,
	);
	return response.data;
};

export const getProcessTasks = async (
	processIds: string[],
	limit = 20,
	marker: string | null = null,
	sort: string[] | null = null,
	filter: { [key: string]: string | any } | null = null,
	options: {
		archived?: boolean;
	} = null,
): Promise<TaskList> => {
	const params = buildURLParameters({
		limit,
		marker,
		sort,
		processIds,
		filter: filter ? JSON.stringify(filter) : null,
		archived: options?.archived || false,
	});
	const response = await axios.get<TaskList>(`/api/processes/tasks?${params}`);
	return response.data;
};

export const getProcessSettings = async (
	processId: string = null,
	settingKey: string = null,
	settingValue: string = null,
) => {
	const params = buildURLParameters({
		processId,
		settingKey,
		settingValue,
	});
	const response = await axios.get(`/api/processes/settings?${params}`);
	return response.data;
};

type GetProcessResponses =
	operations["get_process_api_processes__process_id__get"]["responses"];
export type Process = GetProcessResponses["200"]["content"]["application/json"];
export const getProcess = async (processId: string): Promise<Process> => {
	const response = await axios.get(`/api/processes/${processId}`);
	return response.data;
};

type GetProcessFieldsResponses =
	operations["get_process_fields_api_processes__process_id__fields_get"]["responses"];
export type FieldList =
	GetProcessFieldsResponses["200"]["content"]["application/json"];
export type Field = components["schemas"]["ProcessField"];
export type FieldChoice = components["schemas"]["ProcessChoice"];
export const getProcessFields = async (
	processId: string,
): Promise<FieldList | HTTPValidationError> => {
	const response = await axios.get(`/api/processes/${processId}/fields`);
	return response.data;
};

export const queryProcessFields = async (
	processIds: string[],
	filters: { [key: string]: string | any } | null = null,
): Promise<any | HTTPValidationError> => {
	const params = buildURLParameters({
		processIds,
		filters: filters ? JSON.stringify(filters) : null,
	});
	const response = await axios.get(`/api/processes/process-fields?${params}`);
	return response.data;
};

type CreateTaskEndpoint =
	operations["create_task_api_processes__process_id__tasks_post"];
type CreateTaskRequestBody = CreateTaskEndpoint["requestBody"];
type CreateTaskResponses = CreateTaskEndpoint["responses"];

export type CreateTaskRequest =
	CreateTaskRequestBody["content"]["application/json"];
export type CreateTaskResponse =
	CreateTaskResponses["200"]["content"]["application/json"];
export const createTask = async (
	processId: string,
	task: CreateTaskRequest,
): Promise<CreateTaskResponse | HTTPValidationError> => {
	const response = await axios.post(`/api/processes/${processId}/tasks`, task);
	return response.data;
};

type UpdateTaskEndpoint =
	operations["update_task_api_processes__process_id__tasks__task_id__patch"];
type UpdateTaskRequestBody = UpdateTaskEndpoint["requestBody"];
type UpdateTaskResponses = UpdateTaskEndpoint["responses"];

export type UpdateTaskRequest =
	UpdateTaskRequestBody["content"]["application/json"];
export type UpdateTaskResponse =
	UpdateTaskResponses["200"]["content"]["application/json"];
export const updateTask = async (
	processId: string,
	taskId: string,
	task: UpdateTaskRequest,
): Promise<UpdateTaskResponse | HTTPValidationError> => {
	const response = await axios.patch(
		`/api/processes/${processId}/tasks/${taskId}`,
		task,
	);
	return response.data;
};

type UpdateTaskFieldValue =
	operations["update_task_field_value_api_processes__process_id__tasks__task_id__fields__field_id__post"];
type UpdateTaskFieldValueRequestBody = UpdateTaskFieldValue["requestBody"];
type UpdateTaskFieldValueResponses = UpdateTaskFieldValue["responses"];

export type UpdateTaskFieldValueRequest =
	UpdateTaskFieldValueRequestBody["content"]["application/json"];
export type TaskFieldValue = components["schemas"]["TaskFieldValue"];
export type UpdateTaskFieldValueResponse =
	UpdateTaskFieldValueResponses["200"]["content"]["application/json"];
export const updateTaskFieldValue = async (
	processId: string,
	taskId: string,
	fieldId: string,
	body: UpdateTaskFieldValueRequest,
): Promise<UpdateTaskFieldValueResponse | HTTPValidationError> => {
	const response = await axios.post(
		`/api/processes/${processId}/tasks/${taskId}/fields/${fieldId}`,
		body,
	);
	return response.data;
};

export const updateTaskFieldValues = async (
	processId: string,
	taskId: string,
	body: {
		correlationId: string;
		values: {
			[key: string]: TaskFieldValue[];
		};
	},
): Promise<void> => {
	const response = await axios.post(
		`/api/processes/${processId}/tasks/${taskId}/fields/values`,
		body,
	);
	return response.data;
};

type CreateComment =
	operations["create_task_comment_api_processes__process_id__tasks__task_id__comments_post"];
type CreateCommentRequestBody = CreateComment["requestBody"];
type CreateCommentResponses = CreateComment["responses"];

export type CreateCommentRequest =
	CreateCommentRequestBody["content"]["application/json"];
export type CreateCommentResponse =
	CreateCommentResponses["200"]["content"]["application/json"];
export const createTaskComment = async (
	processId: string,
	taskId: string,
	comment: CreateCommentRequest,
): Promise<CreateCommmentResponse> => {
	const { data } = await axios.post(
		`/api/processes/${processId}/tasks/${taskId}/comments`,
		comment,
	);
	return data;
};

type UpdateComment =
	operations["update_task_comment_api_processes_comments__comment_id__patch"];
type UpdateCommentRequestBody = UpdateComment["requestBody"];
type UpdateCommentResponses = UpdateComment["responses"];

export type UpdateCommentRequest =
	UpdateCommentRequestBody["content"]["application/json"];
export type UpdateCommentResponse =
	UpdateCommentResponses["200"]["content"]["application/json"];
export const updateTaskComment = async (
	processId: string,
	commentId: string,
	comment: UpdateCommentRequest,
): Promise<UpdateCommentResponse> => {
	const { data } = await axios.patch(
		`/api/processes/${processId}/comments/${commentId}`,
		comment,
	);
	return data;
};

type Querycomments = paths["/api/processes/{process_id}/comments"]["get"];
type QueryCommentsResponses = Querycomments["responses"];
export type QueryCommentsResponse =
	QueryCommentsResponses["200"]["content"]["application/json"];

export const queryTaskComments = async (
	processId: string,
	taskId: string,
	limit?: number,
): Promise<QueryCommentsResponse> => {
	const params = buildURLParameters({
		limit,
		taskId,
	});
	const response = await axios.get(
		`/api/processes/${processId}/comments?${params}`,
	);
	return response.data;
};

type DownloadProcessTasks =
	operations["download_process_tasks_api_processes__process_id__tasks_download_get"];
type DownloadProcessRequestBody = DownloadProcessTasks["requestBody"];

export type DownloadProcessRequest =
	DownloadProcessRequestBody["content"]["application/json"];
export const downloadProcessTasks = async (
	processId: string,
	processName: string,
	archived: boolean,
	activeView: string,
	options: DownloadProcessRequest,
): Promise<void> => {
	const params = buildURLParameters({
		processIds: [processId],
		archived,
	});
	const today = dayjs().format("MM-DD-YYYY");
	const filename = activeView
		? `${processName} ${today} - ${activeView}`
		: `${processName} ${today}`;
	return download({
		url: `/api/processes/tasks?${params}`,
		filename: `${filename}.xlsx`,
		params: options,
		headers: {
			Accept:
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		},
	});
};

type TaskActivity =
	operations["get_task_activity_api_processes__process_id__tasks__task_id__activity_get"];
type TaskActivityResponses = TaskActivity["responses"];
type TaskActivityResponse =
	TaskActivityResponses["200"]["content"]["application/json"];
export const getTaskActivity = async (
	processId: string,
	taskId: string,
): Promise<TaskActivityResponse> => {
	const response = await axios.get(
		`/api/processes/${processId}/tasks/${taskId}/activity`,
	);
	return response.data;
};

export const isHTTPValidationError = (
	error: any | HTTPValidationError,
): error is HTTPValidationError => error.detail !== undefined;

export const getNotesByTasks = async (ids: string[]): Promise<any> => {
	const params = buildURLParameters({
		taskIds: ids,
	});
	const response = await axios.get(`/api/processes/tasks/notes?${params}`);
	return response.data;
};

export const getNotesByTask = async (
	processId: string,
	taskId: string,
	limit?: number,
): Promise<any> => {
	const params = buildURLParameters({
		limit,
	});
	const response = await axios.get(
		`/api/processes/${processId}/tasks/${taskId}/notes?${params}`,
	);
	return response.data;
};

export const getFilterOptionsCount = async (
	processId: string,
	fieldId: string,
	filter: { [key: string]: string | any } | null = null,
): Promise<any> => {
	const params = buildURLParameters({
		processId,
		filter: filter ? JSON.stringify(filter) : null,
	});
	const response = await axios.get(
		`/api/processes/filter-options/${fieldId}?${params}`,
	);
	return response.data;
};

export const createNoteOnTask = async (
	processId: string,
	taskId: string,
	note: {
		richText: any;
		title: string;
		plainText: string;
		meetingDate: number;
		meetingType: string;
		visibility: string;
	},
): Promise<any> => {
	const response = await axios.post(
		`/api/processes/${processId}/tasks/${taskId}/notes`,
		note,
	);
	return response.data;
};

export const createView = async (
	processId: string,
	view: components["schemas"]["View"],
): Promise<void> => {
	const response = await axios.post(`/api/processes/${processId}/views`, view);
	return response.data;
};

export const updateView = async (
	processId: string,
	view: components["schemas"]["View"],
): Promise<void> => {
	await axios.patch(`/api/processes/${processId}/views/${view.id}`, view);
};

export const updateViews = async (
	processId: string,
	views: components["schemas"]["View"][],
): Promise<void> => {
	await axios.patch(`/api/processes/${processId}/views`, views);
};

export const deleteView = async (
	processId: string,
	viewId: string,
): Promise<void> => {
	await axios.delete(`/api/processes/${processId}/views/${viewId}`);
};

type GetFormsResponse =
	paths["/api/processes/{process_id}/forms/{form_id}"]["get"]["responses"];
export type Form = GetFormsResponse["200"]["content"]["application/json"];
export const getForm = async (
	processId: string,
	formId: string,
): Promise<Form> => {
	const response = await axios.get(
		`/api/processes/${processId}/forms/${formId}`,
	);
	return response.data;
};

type PostFormSubmissionResponse =
	paths["/api/processes/forms/{form_id}/submissions"]["post"]["responses"];
export type FormSubmission =
	PostFormSubmissionResponse["200"]["content"]["application/json"];
export const createFormSubmission = async (
	processId: string,
	formId: string,
	body: {
		taskInternalId: string;
		correlationId: string;
	},
): Promise<void> => {
	await axios.post(
		`/api/processes/${processId}/forms/${formId}/submissions`,
		body,
	);
};

export const getAddtionalCapitalDeploymentReport = async (
	sort: string[] | null = null,
	filter: { [key: string]: string | any } | null = null,
): Promise<TaskList> => {
	const params = buildURLParameters({
		sort,
		filter: filter ? JSON.stringify(filter) : null,
	});
	const response = await axios.get<TaskList>(
		`/api/processes/additional-capital-deployment-report?${params}`,
	);
	return response.data;
};

type GetSessionResponse =
	paths["/api/processes/{process_id}/sessions/{session_id}"]["get"]["responses"];
export type Session = GetSessionResponse["200"]["content"]["application/json"];
export const getSession = async (
	processId: string,
	sessionId: string,
): Promise<Session> => {
	const response = await axios.get(
		`/api/processes/${processId}/sessions/${sessionId}`,
	);
	return response.data;
};

type CreateSessionResponse =
	paths["/api/processes/{process_id}/sessions"]["post"]["requestBody"];
export type CreateSessionRequest =
	CreateSessionResponse["content"]["application/json"];
export const createSession = async (
	processId: string,
	body: CreateSessionRequest,
): Promise<Session> => {
	const response = await axios.post(
		`/api/processes/${processId}/sessions`,
		body,
	);
	return response.data;
};

type RedirectProcessId =
	paths["/api/processes/redirect/{internal_id}"]["get"]["responses"];
export type Redirect = RedirectProcessId["200"]["content"]["application/json"];
export const getRedirectProcessId = async (
	internalId: string,
): Promise<Redirect> => {
	const response = await axios.get(`/api/processes/redirect/${internalId}`);
	return response.data;
};

export default {
	createTask,
	createTaskSurveyResponse,
	deleteTaskSurveyResponse,
	getProcess,
	getProcessFields,
	getProcessSettings,
	getProcessTasks,
	getRedirectProcessId,
	getResponseLinkedTasks,
	getTask,
	getTaskLinkedResponses,
	getTaskPossibleResponses,
};
