/* eslint-disable no-console */
import axios from "axios";

const getRoles = async () => {
	try {
		const response = await axios.get("/api/compliance/roles");
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

const getPermissions = async () => {
	try {
		const response = await axios.get("/api/compliance/permissions");
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

const getUserInfo = async (id) => {
	try {
		const response = await axios.get(`/api/compliance/user-info/${id}`);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

const getRoleInfo = async (id) => {
	try {
		const response = await axios.get(`/api/compliance/role-info/${id}`);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

const getRolesMembersRestrictions = async () => {
	try {
		const response = await axios.get(
			"/api/compliance/roles-members-restrictions",
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

const getUsers = async () => {
	try {
		const response = await axios.get("/api/compliance/users");
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

const getRestrictions = async (id, column) => {
	try {
		const response = await axios.get(
			`/api/compliance/restrictions/${id}/${column}`,
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

const removeRestriction = async (userId, valorId, column) => {
	try {
		const response = await axios.delete(
			`/api/compliance/remove-restriction/${userId}/${valorId}/${column}`,
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

const updateUserRole = (userId, newRoleId) => {
	try {
		return axios.put(`/api/compliance/user-role/${userId}/${newRoleId}`);
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

const addRestrictions = async (id, valorIds, granted, column) => {
	try {
		const response = await axios.post(
			`/api/compliance/add-restrictions/${id}`,
			{
				column,
				valor_ids: valorIds,
				granted,
			},
		);
		return response.data;
	} catch (error) {
		console.log(error);
		return error.response;
	}
};

export const grantPermissions = async ({
	userId,
	roleId,
	processId,
	permissionIds,
}) => {
	const response = await axios.post("/api/compliance/permissions/grant", {
		userId,
		roleId,
		processId,
		permissionIds,
	});
	return response.data;
};

export const denyPermissions = async ({
	userId,
	roleId,
	processId,
	permissionIds,
}) => {
	const response = await axios.post("/api/compliance/permissions/deny", {
		userId,
		roleId,
		processId,
		permissionIds,
	});
	return response.data;
};

export const revokePermissions = async ({
	userId,
	roleId,
	processId,
	permissionIds,
}) => {
	const response = await axios.post("/api/compliance/permissions/revoke", {
		userId,
		roleId,
		processId,
		permissionIds,
	});
	return response.data;
};

export {
	getRoles,
	getPermissions,
	getUserInfo,
	getRoleInfo,
	getUsers,
	getRolesMembersRestrictions,
	getRestrictions,
	removeRestriction,
	addRestrictions,
	updateUserRole,
};
