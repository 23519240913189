import { createNote, queryCompanyNotes } from "@/api/Notes";
import { createNoteOnTask, getNotesByTask } from "@/api/Process";
import { useAuth } from "@/hooks/useAuth";
import NotesList from "@/pages/Org/Notes/NotesList";
import PlainSerializer from "@/ui/molecules/RichTextEditor/Serializer/PlainSerializer";
import {
	Box,
	Breadcrumbs,
	Button,
	Pagination,
	Skeleton,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import Editor from "./Editor";

const VIEW_VIEW = "view";
const ADD_VIEW = "add";

export default function NotesDrawer({
	open,
	processId,
	taskId,
	valorId,
}: {
	open: boolean;
	processId?: string;
	taskId?: string;
	valorId?: string;
}) {
	const [creatingNote, setCreatingNote] = React.useState(false);
	const [creatingNoteError, setCreatingNoteError] = React.useState(false);
	const theme = useTheme();
	const isScreenThreshold = useMediaQuery(theme.breakpoints.up("sm"));
	const limit = useMemo(() => (isScreenThreshold ? 3 : 2), [isScreenThreshold]);
	const [page, setPage] = React.useState(1);
	const offset = (page - 1) * limit || 0;

	const [view, setView] = React.useState(VIEW_VIEW);
	const { user } = useAuth();

	// get notes
	const {
		data: oppData,
		isLoading: oppLoading,
		refetch: refetchOppNotes,
	} = useQuery(
		["notes", processId, taskId, limit, page],
		() => getNotesByTask(processId, taskId, limit, offset),
		{
			enabled: !!processId && !!taskId,
		},
	);

	const {
		data: orgData,
		isLoading: orgLoading,
		refetch: refetchOrgNotes,
	} = useQuery(
		["notes", valorId, limit],
		() => queryCompanyNotes(valorId, null, null, limit),
		{
			enabled: !!valorId,
		},
	);

	const { hits: notes = [], total } = React.useMemo(() => {
		if (oppData) {
			return oppData;
		}
		if (orgData) {
			return orgData;
		}
		return {};
	}, [oppData, orgData]);

	const handleAddNote = () => {
		setView("add");
	};

	if (!open) {
		return null;
	}

	return (
		<Box minWidth={400} maxWidth="100%">
			<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
				{view === VIEW_VIEW && (
					<>
						<Breadcrumbs>
							<Typography>Notes</Typography>
						</Breadcrumbs>
						<Button variant="outlined" size="small" onClick={handleAddNote}>
							Add Note
						</Button>
					</>
				)}
				{view === ADD_VIEW && (
					<Breadcrumbs>
						<Button variant="text" onClick={() => setView(VIEW_VIEW)}>
							Notes
						</Button>
						<Typography>New Note</Typography>
					</Breadcrumbs>
				)}
			</Box>

			<Box
				visibility={view === VIEW_VIEW ? "visible" : "hidden"}
				height={view === VIEW_VIEW ? "auto" : 0}
			>
				{(oppLoading || orgLoading) && (
					<Box display="flex" flexDirection="column" gap={1}>
						{Array.from({ length: limit }).map((key) => (
							<Skeleton
								variant="rectangular"
								width="100%"
								height="200px"
								key={`${key}`}
							/>
						))}
					</Box>
				)}
				{notes.length === 0 && !(oppLoading || orgLoading) && (
					<Box p={1}>
						<Typography>No notes found</Typography>
					</Box>
				)}

				{notes.length > 0 && (
					<>
						<Box overflow="auto" my={1}>
							<NotesList
								notes={notes}
								userId={user.id}
								hideControls={false}
								// hideDelete is temporary until we add delete functionality
								hideDelete
								editNote={(documentId, noteValorId) => {
									window.open(
										`${window.location.origin}/org/${noteValorId}/notes/edit/${documentId}`,
										"_blank",
									);
								}}
								deleteNote={() => {}}
								viewNote={(documentId, noteValorId) => {
									window.open(
										`${window.location.origin}/org/${noteValorId}/notes/view/${documentId}`,
										"_blank",
									);
								}}
							/>
						</Box>
						<Pagination
							count={Math.ceil((total?.value || 0) / limit)}
							page={page}
							onChange={(event, p) => setPage(p)}
						/>
					</>
				)}
			</Box>

			{view === ADD_VIEW && (
				<Box>
					<Editor
						disabled={creatingNote}
						error={creatingNoteError}
						onPublish={async ({
							title,
							richText,
							meetingDate,
							meetingType,
						}) => {
							// create note
							setCreatingNote(true);
							setCreatingNoteError(false);
							try {
								if (processId && taskId) {
									await createNoteOnTask(processId, taskId, {
										richText,
										title,
										meetingDate: Math.floor(meetingDate / 1000),
										meetingType,
										visibility: "public",
										plainText: PlainSerializer.serialize(richText),
									});
								} else if (valorId) {
									await createNote({
										valorId,
										richText,
										title,
										meetingDate: Math.floor(meetingDate / 1000),
										meetingType,
										visibility: "public",
										plainText: PlainSerializer.serialize(richText),
									});
								}
								setView(VIEW_VIEW);
							} catch (e) {
								setCreatingNoteError(true);
							} finally {
								setCreatingNote(false);
							}

							// refetch notes
							if (processId && taskId) {
								refetchOppNotes();
							} else if (valorId) {
								refetchOrgNotes();
							}
						}}
					/>
				</Box>
			)}
		</Box>
	);
}
