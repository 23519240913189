import { getResponses } from "@/api/FrameworkSurvey";
import type { ResponsesSearchResult } from "@/api/FrameworkSurvey";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { P2P_SURVEYS } from "../constants";

export default function useP2PResponses(lastXDays: number) {
	const completedSince = dayjs()
		.subtract(lastXDays, "days")
		.format("YYYY-MM-DD");

	return useQuery<ResponsesSearchResult[], Error>(["p2pResponses"], () =>
		getResponses({
			surveyIds: Object.values(P2P_SURVEYS),
			completedSince,
		}),
	);
}
