import { getNewsArticles } from "@/api/NewsArticles";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import NewsArticle from "@/ui/atoms/NewsArticle";
import Progress from "@/ui/atoms/Progress";
import ItemMeasurer from "@/utils/ItemMeasurer";
import { Box, Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/system";
import { useVirtualizer } from "@tanstack/react-virtual";
import uniqBy from "lodash/uniqBy";
/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from "react";
import { useInfiniteQuery } from "react-query";
import { Link } from "react-router-dom";

const UpdateList = styled(Box)(({ theme }) => ({
	width: "100%",
	height: "80vh",
	overflowX: "hidden",
	overflowY: "auto",
	msOverflowStyle: "none" /* IE and Edge */,
	scrollbarWidth: "none" /* Firefox */,
	"&::-webkit-scrollbar": {
		display: "none",
	},
	[theme.breakpoints.down("md")]: {
		height: "75vh",
	},
}));

const filterKey = {
	FOLLOW_LIST: {
		display: "On your Watchlist",
		filterKey: "isFollowing",
	},
	PIPELINE: {
		display: "In the Pipeline",
		filterKey: "isPipeline",
	},
	PORTFOLIO: {
		display: "In the Portfolio",
		filterKey: "isPortfolio",
	},
};

export function News() {
	const isMdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const [filter, setFilter] = React.useState(filterKey.FOLLOW_LIST);
	const updateListRef = useRef();
	const theme = useTheme();

	const ARTICLES_PER_PAGE = 30;
	const {
		fetchNextPage,
		hasNextPage,
		isFetching,
		isLoading,
		data: newsArticles,
		isFetchingNextPage,
	} = useInfiniteQuery(
		["NewsArticles", filter.filterKey, { limit: ARTICLES_PER_PAGE }],
		async (props) => {
			const { pageParam } = props;

			const response = await getNewsArticles({
				cursor: pageParam,
				limit: ARTICLES_PER_PAGE,
				[filter.filterKey]: true,
			});
			return response;
		},
		{
			getNextPageParam: (lastPage) => {
				if (lastPage?.hasNext) {
					if (lastPage.nextPage.cursors) {
						return lastPage.nextPage.cursors;
					}
					return lastPage.nextPage;
				}
				return null;
			},
			retryOnMount: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchInterval: false,
		},
	);

	// this is a hack to prevent our infinite query w/
	// pagination from creating dupes. Not sure why this is happening
	const flattendNews =
		uniqBy(
			newsArticles?.pages?.flatMap((x) => x.results),
			(art) => art?.articleDid,
		) ?? [];

	const rowVirtualizer = useVirtualizer({
		count: hasNextPage ? (flattendNews?.length ?? 0) + 1 : flattendNews?.length,
		getScrollElement: () => updateListRef.current,
		overScan: 40,
		keyExtractor: (index) => flattendNews[index]?.articleDid,
		estimateSize: React.useCallback(() => 96, []),
	});

	useEffect(() => {
		const lastItem = [...rowVirtualizer.virtualItems][
			rowVirtualizer.virtualItems.length - 1
		];

		if (!lastItem) {
			return;
		}

		if (
			lastItem.index > (flattendNews?.length ?? 0) - 10 &&
			hasNextPage &&
			!isFetchingNextPage &&
			!isFetching
		) {
			fetchNextPage();
		}
	}, [
		hasNextPage,
		fetchNextPage,
		isFetching,
		flattendNews?.length,
		isFetchingNextPage,
		rowVirtualizer.virtualItems,
	]);

	return (
		<Box style={{ width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					width: "98%",
					padding: 3,
					pt: 1,
					pl: 2,
					[theme.breakpoints.down("md")]: {
						flexDirection: "column",
						alignItems: "flex-start",
						padding: 0,
						paddingBottom: 1,
					},
				}}
			>
				{!isMdDown && (
					<Box>
						<Typography
							sx={{
								"& > a": {
									color: (t) => t.palette.primary.main,
									textDecoration: "none",
								},
							}}
							variant="h3"
						>
							<Link to="/">Home</Link> &gt; News
						</Typography>
					</Box>
				)}
				<Box>
					<ButtonTabs
						options={Object.values(filterKey)}
						getKey={(option) => option.filterKey}
						getLabel={(option) => option.display}
						onClick={(option) => setFilter(option)}
						activeKey={filter.filterKey}
						evenSizedButtons={false}
					/>
				</Box>
			</Box>
			<UpdateList>
				<CenteredProgress isLoading={isLoading}>
					<UpdateList ref={updateListRef}>
						<div
							style={{
								height: `${rowVirtualizer.totalSize}px`,
								position: "relative",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								width: "100%",
							}}
						>
							{rowVirtualizer.virtualItems.map((virtualRow) => {
								const article = flattendNews[virtualRow.index];
								const isLoaderRow = virtualRow.index > flattendNews.length - 1;

								if (isLoaderRow) {
									return null;
								}
								return (
									<ItemMeasurer
										tagName="div"
										key={`${virtualRow.key}${filter.filterKey}`}
										measure={virtualRow.measureRef}
										style={{
											position: "absolute",
											top: 0,
											left: 0,
											width: "98%",
											transform: `translateY(${virtualRow.start}px)`,
										}}
									>
										<Card
											sx={{
												margin: 0.5,
												mb: 1,
												padding: 1,
												// gives the box shadow room on the sides
												boxShadow: (t) => t.shadows[2],
											}}
											elevation={1}
											spacing={3}
										>
											<NewsArticle
												key={article.articleDid}
												title={article.title}
												imageUrl={article.imageUrl}
												pageUrl={article.pageUrl}
												siteName={article.siteName}
												articleTimestamp={article.articleTimestamp}
												logoUrl={article.logoUrl}
												name={article.name}
												valorId={article.valorId}
												domain={article.domain}
												showCompany
												showNewsImage
												backupImage="/images/news-backup.png"
											/>
										</Card>
									</ItemMeasurer>
								);
							})}
						</div>
					</UpdateList>
				</CenteredProgress>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
					}}
				>
					{isFetchingNextPage && (
						<Progress
							sx={(t) => ({
								width: `${t.spacing(2)}px !important`,
								height: `${t.spacing(2)}px !important`,
							})}
						/>
					)}
				</Box>
			</UpdateList>
		</Box>
	);
}

export default News;
