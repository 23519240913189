import RTFEditor from "@/ui/molecules/RichTextEditor";
import MarkdownSerializer from "@/ui/molecules/RichTextEditor/Serializer/MarkdownSerializer";
import { Box } from "@mui/material";
import React from "react";
import { useField } from "react-final-form";

interface Props {
	questionId: string;
	skippable: boolean;
	initialValue: string;
}

export default function RichText({
	questionId,
	skippable,
	initialValue = "",
}: Props) {
	const required = (value: string) =>
		value || skippable ? undefined : "Required";
	const { input: commentaryInput, meta: commentaryMeta } = useField(
		`${questionId}.commentary`,
		{
			initialValue,
			validate: required,
		},
	);

	const initValue = React.useMemo(() => {
		try {
			const richText = MarkdownSerializer.deserialize(
				commentaryInput.value || "",
			);

			if (richText.length === 0) {
				return [
					{
						type: "paragraph",
						children: [{ text: "" }],
					},
				];
			}

			return richText;
		} catch (e) {
			return [
				{
					type: "paragraph",
					children: [{ text: "" }],
				},
			];
		}
	}, [commentaryInput?.value]);

	const label = commentaryMeta.submitFailed
		? commentaryMeta.error
		: skippable
			? ""
			: "Required";

	return (
		<Box width="100%" data-formstate={commentaryMeta.error ? "error" : ""}>
			<RTFEditor
				hideToolbar
				initialValue={initValue}
				onChange={(newRichText) => {
					commentaryInput.onChange(MarkdownSerializer.serialize(newRichText));
				}}
				error={!!(commentaryMeta.submitFailed && commentaryMeta.error)}
				label={label}
			/>
		</Box>
	);
}
