import { type Process, createTask, getForm } from "@/api/Process";
import { Close } from "@mui/icons-material";
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	useMediaQuery,
} from "@mui/material";
import React, { useMemo, useEffect } from "react";
import { useQuery } from "react-query";

import FormContainer from "@/pages/ProcessManagement/Forms";
import EditTaskDialog from "@/pages/ProcessManagement/ProcessView/EditTaskDialog";
import NewTaskFormDialog from "@/pages/ProcessManagement/TaskForms/NewTaskFormDialog";

import { canSeeProcess } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import useProcess from "@/pages/ProcessManagement/ProcessContext/useProcess";
import useTask from "@/pages/ProcessManagement/ProcessContext/useTask";

function createTaskBody(process: Process, valorId?: string) {
	// create task body based on the process
	// find the primary company
	const { fields } = process;
	const primaryCompanyField = fields.find(
		(field) => field.isPrimary && field.type === "company",
	);

	// set the primary company
	if (!primaryCompanyField) return {};

	return {
		fields: {
			company: {
				[primaryCompanyField.id]: valorId,
			},
		},
	};
}

export default function ProcessFormWizard({
	processId,
	taskId,
	valorId,
	formType,
	formId,
	open,
	onOpen,
	onClose,
}: {
	processId: string;
	taskId?: string;
	valorId?: string;
	formType?: "new" | "edit" | "custom";
	formId?: string;
	open: boolean;
	onOpen?: () => void;
	onClose?: () => void;
}) {
	const { user } = useAuth();

	const hasAccess = useMemo(
		() => canSeeProcess(user, processId),
		[user, processId],
	);

	const [loading, setLoading] = React.useState(false);
	const [active, setActive] = React.useState(false);
	const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	// load process settings
	const {
		data: process,
		// isLoading: processLoading,
	} = useProcess(processId, {
		enabled: hasAccess,
	});

	// if new form, create new task
	const [newTaskId, setNewTaskId] = React.useState<string | null>(null);

	const {
		// data: {
		//   task,
		// },
		actions: { archiveTask, refetch },
		// isLoading: taskLoading,
	} = useTask(
		processId,
		(formType === "new" || formType === "edit") && active && open
			? taskId || newTaskId
			: null,
	);

	// if custom form, load form info
	const {
		data: form,
		// isLoading: formLoading,
	} = useQuery(["form", formId], () => getForm(processId, formId), {
		enabled: !!formId && formType === "custom" && open,
	});

	const handleCreateTask = React.useCallback(() => {
		if (newTaskId) {
			return;
		}
		if (!valorId) {
			setActive(true);
			setLoading(true);
			createTask(processId).then((result) => {
				setNewTaskId(result.id);
				setLoading(false);
				onOpen?.();
			});
		} else if (process) {
			setActive(true);
			setLoading(true);
			createTask(processId, createTaskBody(process, valorId)).then((result) => {
				setNewTaskId(result.id);
				setLoading(false);
				onOpen?.();
			});
		}
	}, [process, valorId, newTaskId, onClose, processId]);

	useEffect(() => {
		if (open) {
			if (formType === "new") {
				handleCreateTask();
			}
			if (formType === "edit") {
				setActive(true);
				onOpen?.();
			}
			if (formType === "custom") {
				onOpen?.();
			}
		}
	}, [open, formType]);

	return (
		<Box>
			{formType === "new" && active && (
				<NewTaskFormDialog
					open={open}
					onClose={() => {
						setNewTaskId(null);
						onClose?.();
					}}
					onCancel={() => {
						setNewTaskId(null);
						onClose?.();
					}}
					onChange={() => {}}
					onCreate={() => {
						setNewTaskId(null);
						onClose?.();
					}}
					processId={processId}
					taskId={newTaskId}
					loading={loading}
				/>
			)}

			{formType === "edit" && (
				<EditTaskDialog
					processId={processId}
					taskId={taskId}
					open={open}
					onClose={() => {
						onClose();
					}}
					onArchive={() => {
						archiveTask(taskId);
					}}
					onArchived={() => {
						refetch();
					}}
					fullWidth
					maxWidth="lg"
					scroll="paper"
					onUpdate={() => {
						refetch();
					}}
				/>
			)}

			{formType === "custom" && form && (
				<Dialog
					open={open}
					onClose={() => {
						onClose();
					}}
					fullWidth
					fullScreen={isSmDown}
					maxWidth="md"
				>
					<DialogTitle>
						<Box
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							alignItems="center"
						>
							{form?.title}
							<IconButton
								onClick={() => {
									onClose?.();
								}}
							>
								<Close />
							</IconButton>
						</Box>
					</DialogTitle>
					<DialogContent>
						<FormContainer
							processId={processId}
							formId={formId}
							oppId={taskId}
							onCancel={() => {
								onClose?.();
							}}
							onSubmit={() => {
								onClose?.();
							}}
						/>
					</DialogContent>
				</Dialog>
			)}
		</Box>
	);
}
