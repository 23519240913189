import GrokIcon from "@/ui/atoms/Icons/Grok";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

import { useAuth } from "@/hooks/useAuth";
import ChatBox from "@/pages/Grok/ChatBox";
import { useNavigate, useParams } from "react-router-dom";
import Chat from "./Chat";
import Drawer from "./Drawer";
import RecentChats from "./RecentChats";
import ChatSearch from "./Search";

export default function Grok() {
	const { user } = useAuth();

	const { section, subsection } = useParams();
	const navigate = useNavigate();

	const [showDrawer, setShowDrawer] = React.useState(false);
	const now = dayjs();
	const startChat = (question: string) => {
		const newChatId = crypto.randomUUID();
		navigate(`/grok/chat/${newChatId}`, {
			state: { question },
		});
	};

	let greeting = "evening";
	if (now.hour() < 12) {
		greeting = "morning";
	} else if (now.hour() < 18) {
		greeting = "afternoon";
	}

	return (
		<Box
			py={1}
			width="100%"
			height="100%"
			position="relative"
			overflow="hidden"
		>
			<Box
				position="absolute"
				left={0}
				width="100px"
				height="100%"
				onMouseOver={() => setShowDrawer(true)}
			/>

			<Drawer open={showDrawer} onClose={() => setShowDrawer(false)} />

			{section === "chat" && (
				<Box
					py={1}
					height="100%"
					margin="auto"
					sx={{
						width: {
							xs: "100%",
							sm: "100%",
							md: "800px",
							lg: "1000px",
							xl: "1400px",
						},
						maxWidth: "100%",
					}}
				>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						gap={5}
						padding={1}
						height="100%"
					>
						<Chat id={subsection} />
					</Box>
				</Box>
			)}

			{section === "search" && (
				<Box>
					<ChatSearch />
				</Box>
			)}

			{section !== "chat" && section !== "search" && (
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					gap={5}
					padding={1}
					width={600}
					maxWidth="100%"
					margin="auto"
					my={1}
				>
					<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
						<GrokIcon />
						<Typography variant="h2">
							Good {greeting}, {user.firstName}
						</Typography>
					</Box>

					<Box
						width="100%"
						minHeight={"480px"}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-end",
						}}
					>
						<ChatBox
							onSend={(question) => startChat(question)}
							placeholder="How can Grok Help you today"
						/>
					</Box>

					<RecentChats />
				</Box>
			)}
		</Box>
	);
}
