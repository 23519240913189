import { Box } from "@mui/material";
import React from "react";
//                              ..                     @%(/#%%@
//                         .%,,,,,,,,,              @,,,,///*//#   %/%,,*****
//                   .%,,,,,,,,,,,,,,,(#           ,,,,,,@@////#  #*@,,,,*****/
//              &/@,,,,,,,,,,,,,,,,,.      &      (,,,,,,,*/////(  &,,,,,,/****/
//           ,,,,,,,,(,,@&&&        %    %@ *     ,,,,,,,,&#/////*#,,,,,,,,*****
//           (,,,,,,,,,(,,&       &  (   .(,,     ,,,,,,,,,/&///@(,,,,,,,,/*****
//             ,,,,,,,,,,,,*        */,,,,,,,      ,,,,,,,,%*//(/,,,,,,,,,*****
//              &,,,,,,,,,@,,&&@*,,,,,,,,,,,*      /,,,,,,,*#/(/,,,,,,,,*****/
//                ,,,,,,,,*/,%@#,,,,,,,,,,,,,       @,,,,&%*,@@,,,,,,,,@****&
//                 *,,,,,,,,/*(#,,,,,,,,,,,,,,@           &******&%,,,*****#
//                   %,,%*********(,,,,,,//.,             ////%
//                        /@&*******(.....% /,,,         //@@&
//                         &//*****... %&,,,,,,.        (////
//                        @///// **@%#/,,,,,&          //@%&.
//                       #/*//%    @**&**             /////
//                      (@///*                      &%*///
//        (******#,,,,,,///@,%**&  ,@*,,,,(*********%*//%
//      %*********,,,,,,,,,,&,,,,,,,,,,,,,,,,,/*******/
//      **(//#****%,,,,,,/,,,,,,,,,,,,,,,,,,,,,,****(/(
//      @&@///@***,,,,,,,*%,,,,,,,,,,,,,,,,,,,#@//&
//    @/////@***&,,,,,,,,&*#,,,,,/@#/*@(&#.
//   (////#,,,,,,,,,,/,,,***&///(,@@////&#&@  &
//  @//(/@,,,,,,,,,*@*///&**@,,/@&@/#////%///*##////
//  @////(@@@%%///////////,/,,,,,,,,,,,,,#@//*%//&**%
//   ,////////&//////@,,,,,&,,,,,,,,,,,,,,,,,,,&***#
//      ,,**,,,,,,@,,,,,,,,,,&((,,,,,,,,,,,,,,%**&
//      *,,,,,,,,,,,,,,,,,,,,*,.       .&&**@
//   #****,,,,,,,*,,,,,,,,,,&,&
//    .*****//,,,,,,,,,,,,,,%**%

type ClampsProps = {
	maxLines?: number;
	lineHeight?: number | string;
	collapsed?: boolean;
	onReflow?: (hasOverflow: boolean) => void;
	children: React.ReactNode;
};

export default function Clamps({
	maxLines = 1,
	lineHeight = 1.2,
	collapsed = true,
	onReflow = () => {},
	children,
}: ClampsProps) {
	const contentRef = React.useRef<HTMLDivElement>(null);
	const fakeRef = React.useRef<HTMLDivElement>(null);

	let maxHeightFinal = "";
	if (typeof lineHeight === "number") {
		maxHeightFinal = `${maxLines * lineHeight}em`;
	} else {
		const lineHeightNumber = Number.parseFloat(lineHeight);
		const lineHeightUnit = lineHeight.replace(lineHeightNumber.toString(), "");
		maxHeightFinal = (maxLines * lineHeightNumber).toString() + lineHeightUnit;
	}

	React.useEffect(() => {
		// listen for reflow
		let reflowTimeout;
		const handleReflow = () => {
			if (!collapsed) {
				return;
			}

			clearTimeout(reflowTimeout);
			reflowTimeout = setTimeout(() => {
				const contentBox = contentRef.current;
				const fakeBox = fakeRef.current;

				if (contentBox && fakeBox) {
					const contentHeight = contentBox.clientHeight;
					const fakeHeight = fakeBox.clientHeight;
					const hasOverflow = contentHeight < fakeHeight;
					onReflow(hasOverflow);
				}
			}, 100);
		};

		handleReflow();

		window.addEventListener("resize", handleReflow);
		return () => {
			window.removeEventListener("resize", handleReflow);
		};
	}, [contentRef, fakeRef, onReflow, collapsed]);

	return (
		<Box position="relative">
			<Box
				ref={contentRef}
				sx={
					collapsed
						? {
								height: maxHeightFinal,
								overflow: "hidden",
								// overflowX: "auto",
								display: "-webkit-box",
								WebkitBoxOrient: "vertical",
								WebkitLineClamp: maxLines,
								textOverflow: "ellipsis",
								transition: "height 0.5s ease",
								lineHeight,
								"@media print": {
									display: "block",
									height: "auto",
									overflow: "visible",
								},
							}
						: {
								height: fakeRef.current?.clientHeight,
								overflow: "hidden",
								overflowX: "auto",
								transition: "height 0.5s ease",
								lineHeight,
							}
				}
			>
				{children}
			</Box>
			{/* fake box to gauge height of content */}
			<Box
				ref={fakeRef}
				visibility="hidden"
				position="absolute"
				top={0}
				left={0}
				sx={{
					pointerEvents: "none",
				}}
			>
				{children}
			</Box>
		</Box>
	);
}
