import { type SxProps, TextField, type Theme } from "@mui/material";
import React from "react";

interface TextFieldAdapterProps {
	input: any;
	meta: any;
	minWidth?: string | SxProps<Theme>["minWidth"];
	label: string;
	required: boolean;
}

function TextFieldAdapter({
	input,
	meta,
	minWidth,
	...rest
}: TextFieldAdapterProps) {
	return (
		<TextField
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...input}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...rest}
			maxRows={5}
			onChange={(event) => input.onChange(event.target.value)}
			error={meta.submitFailed && meta.error}
			label={
				meta.submitFailed
					? meta.error
						? `${rest.label}`
						: rest.label
					: rest.label
			}
			data-formstate={meta?.error ? "error" : ""}
			variant="standard"
			required={rest.required}
			sx={{
				minWidth,
			}}
		/>
	);
}

export default TextFieldAdapter;
