import type { ChatAction, ChatState } from "./ChatState";

export const initialState: ChatState = {
	message: "",
	inputTokens: 0,
	fileContexts: [],
	pastedContents: [],
	selectedContexts: [],
	selectedCompany: null,
	selectedContextTypes: [],
	isContextDialogOpen: false,
	error: null,
	loadingCount: 0, // Track number of loading operations
	isLoading: false,
};

export function chatReducer(state: ChatState, action: ChatAction): ChatState {
	switch (action.type) {
		case "SET_MESSAGE":
			return { ...state, message: action.payload };

		case "SET_INPUT_TOKENS":
			return { ...state, inputTokens: action.payload };

		case "ADD_FILE_CONTEXT":
			return {
				...state,
				fileContexts: [
					...state.fileContexts,
					{ ...action.payload, timestamp: Date.now() },
				],
			};

		case "UPDATE_FILE_CONTEXT":
			return {
				...state,
				fileContexts: state.fileContexts.map((f) =>
					f.id === action.payload.id ? action.payload : f,
				),
			};

		case "ADD_PASTED_CONTENT":
			return {
				...state,
				pastedContents: [
					...state.pastedContents,
					{ ...action.payload, timestamp: Date.now() },
				],
			};
		case "REMOVE_PASTED_CONTENT":
			return {
				...state,
				pastedContents: state.pastedContents.filter(
					(content) => content.id !== action.payload,
				),
			};

		case "REMOVE_FILE_CONTEXT":
			return {
				...state,
				fileContexts: state.fileContexts.filter((f) => f.id !== action.payload),
			};

		case "SET_SELECTED_CONTEXTS":
			return { ...state, selectedContexts: action.payload };

		case "ADD_SELECTED_CONTEXTS":
			return {
				...state,
				selectedContexts: [...state.selectedContexts, ...action.payload],
			};

		case "REMOVE_SELECTED_CONTEXT":
			return {
				...state,
				selectedContexts: state.selectedContexts.filter(
					(context) =>
						!(
							context.id === action.payload.id &&
							context.contextType === action.payload.contextType
						),
				),
			};

		case "UPDATE_CONTEXT_DATA":
			return {
				...state,
				selectedContexts: state.selectedContexts.map((context) =>
					context.id === action.payload.id &&
					context.contextType === action.payload.contextType
						? { ...context, data: action.payload.data }
						: context,
				),
			};

		case "SET_SELECTED_COMPANY":
			return { ...state, selectedCompany: action.payload };

		case "SET_SELECTED_CONTEXT_TYPES":
			return { ...state, selectedContextTypes: action.payload };

		case "TOGGLE_CONTEXT_DIALOG":
			return { ...state, isContextDialogOpen: action.payload };

		case "SET_ERROR":
			return { ...state, error: action.payload };

		case "SET_LOADING":
			return {
				...state,
				isLoading: action.payload,
				loadingCount: action.payload ? state.loadingCount : 0,
			};

		case "INCREMENT_LOADING":
			return {
				...state,
				loadingCount: state.loadingCount + 1,
				isLoading: true,
			};

		case "DECREMENT_LOADING":
			const newCount = Math.max(0, state.loadingCount - 1);
			return {
				...state,
				loadingCount: newCount,
				isLoading: newCount > 0,
			};

		case "RESET_CHAT":
			return initialState;

		default:
			return state;
	}
}
