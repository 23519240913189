import { Box, Typography } from "@mui/material";
import React from "react";
import Pipeline from "./Pipeline";

export default function LeanGrowthUpdates() {
	return (
		<Box
			display="flex"
			flexDirection="column"
			gap={2}
			data-cy="lean-growth__container"
		>
			<Typography variant="h2">Lean/Growth Key Updates</Typography>
			<Pipeline />
		</Box>
	);
}
