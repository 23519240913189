import { isNewUser } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import { Link } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function NewUser() {
	const { user } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (!isNewUser(user)) {
			navigate("/home");
		}
	}, [user, navigate]);

	return (
		<div
			style={{
				marginTop: "20%",
				width: "80%",
				marginLeft: "10%",
				textAlign: "center",
			}}
		>
			<h1>Welcome to vOS!</h1>
			We are very excited to have you onboard. For compliance reasons you can
			not access any page until the proper role is assigned. Please contact the
			labs team at {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			<Link
				onClick={(e) => {
					window.location.href = "mailto:labs@valorep.com?subject=New User";
					e.preventDefault();
				}}
			>
				labs@valorep.com
			</Link>
			; to update the role.
		</div>
	);
}
