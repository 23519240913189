import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";

interface ModelCardProps {
	id: string;
	name: string;
	version: string;
	provider: string;
	parameters: string;
}

const ModelCard = ({
	id,
	name,
	version,
	provider,
	parameters,
}: ModelCardProps) => {
	// Pretty print the parameters JSON
	const prettyParameters = () => {
		try {
			const parsedParams = JSON.parse(parameters);
			return JSON.stringify(parsedParams, null, 2);
		} catch {
			return parameters; // Return as-is if parsing fails
		}
	};

	return (
		<Card sx={{ minWidth: 275, mb: 2 }}>
			<CardContent>
				<Typography variant="h5" component="div" gutterBottom>
					{name}
				</Typography>

				<Typography variant="body1" color="text.secondary" gutterBottom>
					Version: {version}
				</Typography>

				<Typography variant="body1" color="text.secondary" gutterBottom>
					Provider: {provider}
				</Typography>

				<Box sx={{ mt: 2 }}>
					<Typography variant="body2" color="text.secondary" gutterBottom>
						Parameters:
					</Typography>
					<Box
						component="pre"
						sx={{
							backgroundColor: "grey.100",
							p: 1,
							borderRadius: 1,
							overflow: "auto",
							fontSize: "0.875rem",
						}}
					>
						{prettyParameters()}
					</Box>
				</Box>

				<Typography
					variant="caption"
					color="text.secondary"
					sx={{ mt: 2, display: "block" }}
				>
					ID: {id}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default ModelCard;
