import { getAllSections, getResponses } from "@/api/FrameworkSurvey";
import AARSurveys from "@/constants/AARSurveys";
import { canViewAllAARs, canViewAnalystAssociateAARs } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import MultiSelect from "@/ui/atoms/MultiSelect";
import Section from "@/ui/atoms/Section";
import DateRangeFilter from "@/ui/atoms/TableFilters/DateRangeFilter";
import FrameworkGrid from "@/ui/templates/FrameworkGrid";
import { Autocomplete, Box, Skeleton, TextField } from "@mui/material";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

dayjs.extend(isBetween);
dayjs.extend(utc);

interface Props {
	department?: string;
	tab?: string;
}

function AARResponse({ department, tab }: Props) {
	const title = "After Action Review Responses";
	const { user } = useAuth();

	const [selectedSurveys, setSelectedSurveys] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectedSubjects, setSelectedSubjects] = useState([]);
	const [selectedCompanies, setSelectedCompanies] = useState([]);
	const [uniqueSubjects, setUniqueSubjects] = useState([]);
	const [uniqueUsers, setUniqueUsers] = useState([]);
	const [uniqueSurveys, setUniqueSurveys] = useState([]);
	const [uniqueCompanies, setUniqueCompanies] = useState([]);
	const [selectedDepartments, setSelectedDepartments] = useState([]);
	const [uniqueDepartments, setUniqueDepartments] = useState([]);
	const [isLoadingData, setLoadingData] = useState(true);

	const TODAY = dayjs();
	const TWO_WEEKS_AGO = TODAY.subtract(14, "day");
	const [selectedDateRange, setSelectedDateRange] = useState({
		startDate: TWO_WEEKS_AGO.toDate(),
		endDate: TODAY.endOf("day").toDate(),
		key: "selection",
	});

	const { data: sections = [], isLoading: isLoadingSections } = useQuery(
		["Sections"],
		() => getAllSections({ isAAR: true }),
	);

	const aarParams = {
		verticals: ["after_action_review"],
		includeActiveEngagements: false,
	};
	if (tab === "admin") {
		if (canViewAllAARs(user)) {
			aarParams.surveyIds = [
				AARSurveys.selfReviewAnalyst,
				AARSurveys.selfReviewAssociate,
				AARSurveys.selfReviewVP,
				AARSurveys.selfReviewPrincipal,
				AARSurveys.peerReviewAnalyst,
				AARSurveys.peerReviewAssociate,
				AARSurveys.peerReviewVP,
				AARSurveys.peerReviewPrincipal,
			];
		} else if (canViewAnalystAssociateAARs(user)) {
			aarParams.surveyIds = [
				AARSurveys.selfReviewAnalyst,
				AARSurveys.selfReviewAssociate,
				AARSurveys.peerReviewAnalyst,
				AARSurveys.peerReviewAssociate,
			];
		}
	} else if (tab === "self-reviews") {
		aarParams.userIds = [user.id];
		aarParams.departments = [department];
	} else if (tab === "peer-reviews") {
		aarParams.userIds = [user.id];
		aarParams.departments = [department];
	} else if (tab === "peer-reviews-me") {
		aarParams.isReleased = true;
		aarParams.subjectIds = [user.id];
		aarParams.departments = [department];
	}

	const {
		data: responses,
		isLoading: isLoadingResponses,
		status,
	} = useQuery(
		["aarSurveyResponses", aarParams],
		async () => {
			const response = await getResponses(aarParams);
			if (response?.status === 404 || response?.status === 422) {
				throw new Error(response.status);
			}
			return response;
		},
		{},
	);

	const getUniqueItems = (items, keyPath, namePath) => {
		if (_.isEmpty(items)) {
			return [];
		}

		return _.chain(items)
			.map((item) => ({
				id: _.get(item, keyPath),
				name: _.isFunction(namePath) ? namePath(item) : _.get(item, namePath),
			}))
			.uniqBy("id")
			.filter((item) => !_.isNil(item.id) && !_.isNil(item.name))
			.value();
	};

	const getUniqueCompanies = (responses) =>
		getUniqueItems(responses, "organization.valorId", "organization.name");

	const getUniqueSurveys = (responses) =>
		getUniqueItems(responses, "surveyId", "surveyName");

	const getUniqueUsers = (responses) =>
		getUniqueItems(
			responses,
			"user.id",
			(response) => `${response.user.firstName} ${response.user.lastName}`,
		);

	const getUniqueSubjects = (responses) =>
		getUniqueItems(responses, "surveySubjectUser.id", (response) =>
			response.surveySubjectUser
				? `${response.surveySubjectUser.firstName} ${response.surveySubjectUser.lastName}`
				: null,
		);

	const getUniqueDepartments = (responses) =>
		_.chain(responses).map("department").uniq().compact().value();

	useEffect(() => {
		if (responses?.length) {
			setLoadingData(true);
			const users = getUniqueUsers(responses);
			setSelectedUsers(users);
			setUniqueUsers(users);

			const subjects = getUniqueSubjects(responses);
			setSelectedSubjects(subjects);
			setUniqueSubjects(subjects);

			const surveyNames = getUniqueSurveys(responses);
			setSelectedSurveys(
				surveyNames.filter((s) =>
					[
						"5539da8b-67c9-44e1-b35d-9db20b9958bb",
						"5349d5e8-62a4-4bfc-be22-ef53b5aef8c7",
					].includes(s.id),
				),
			);
			setUniqueSurveys(surveyNames);

			const companies = getUniqueCompanies(responses);
			setSelectedCompanies(
				companies.sort((a, b) => a?.name.localeCompare(b?.name)),
			);
			setUniqueCompanies(companies);

			const departments = getUniqueDepartments(responses);
			setSelectedDepartments(departments);
			setUniqueDepartments(departments);
			setLoadingData(false);
		} else {
			setLoadingData(false);
		}
	}, [
		responses,
		isLoadingResponses,
		setSelectedUsers,
		setSelectedSurveys,
		setSelectedCompanies,
		setSelectedSubjects,
		setSelectedDepartments,
		setUniqueUsers,
		setUniqueSurveys,
		setUniqueCompanies,
		setUniqueSubjects,
		setUniqueDepartments,
		setLoadingData,
	]);

	if (isLoadingSections || isLoadingResponses || isLoadingData) {
		return (
			<Section>
				<Section.Title>{title}</Section.Title>
				<Section.Content>
					<Box style={{ marginLeft: "8px", marginRight: "8px" }}>
						<Box
							marginBottom={1}
							display="flex"
							justifyContent="space-between"
							sx={{ gap: "8px" }}
						>
							<Box display="flex" sx={{ gap: "8px" }}>
								<Skeleton variant="rounded" height="40px" width="80px" />
								<Skeleton variant="rounded" height="40px" width="80px" />
								<Skeleton variant="rounded" height="40px" width="80px" />
							</Box>
							<Box>
								<Skeleton variant="rounded" height="36px" width="144px" />
							</Box>
						</Box>
						<Box display="flex" flexDirection="column" sx={{ gap: "8px" }}>
							<Skeleton variant="rounded" height="100px" width="100%" />
							<Skeleton variant="rounded" height="100px" width="100%" />
							<Skeleton variant="rounded" height="100px" width="100%" />
							<Skeleton variant="rounded" height="100px" width="100%" />
						</Box>
					</Box>
				</Section.Content>
			</Section>
		);
	}

	const startDate = selectedDateRange?.startDate;
	const endDate = selectedDateRange?.endDate;

	let filteredResponses = responses?.filter((r) => {
		const responseDate = dayjs.utc(r.completedAt);
		const isWithinRange = responseDate.isBetween(
			startDate,
			endDate,
			null,
			"[]",
		);

		return (
			isWithinRange &&
			selectedSurveys.some((s) => s.id === r.surveyId) &&
			selectedCompanies.some((c) => c.id === r.organization.valorId) &&
			selectedUsers.some((u) => u.id === r.user.id) &&
			selectedDepartments.includes(r.department)
		);
	});

	if (tab === "peer-reviews" || tab === "admin") {
		filteredResponses = filteredResponses.filter((r) => {
			if (r?.department === "self_review") {
				return true;
			}
			if (r?.department === "peer_review") {
				return selectedSubjects.some((s) => s.id === r.surveySubjectUser?.id);
			}
			return false;
		});
	}

	filteredResponses?.sort(
		(a, b) => dayjs(b?.completedAt).unix() - dayjs(a?.completedAt).unix(),
	);

	return (
		<Section>
			<Section.Title>{title}</Section.Title>
			<Section.Content>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						marginLeft: "8px",
						marginRight: "8px",
						marginBottom: (t) => t.spacing(1),
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							flexDirection: "column",
							marginBottom: (t) => t.spacing(1),
						}}
					>
						{!isLoadingResponses && !!responses?.length && (
							<>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										marginBottom: (t) => t.spacing(1),
										width: "50%",
									}}
								>
									<Autocomplete
										id="companies-select"
										multiple
										options={uniqueCompanies}
										getOptionLabel={(option) => option?.name}
										disableCloseOnSelect
										value={selectedCompanies}
										sx={{ width: "100%" }}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Companies"
												placeholder="Select companies"
											/>
										)}
										onChange={(event, newValue) =>
											setSelectedCompanies(newValue)
										}
										isOptionEqualToValue={(option, value) =>
											option.id === value.id
										}
									/>
								</Box>
								<Box
									sx={{
										display: "flex",
										gap: "8px",
										flexDirection: {
											xs: "column",
											sm: "row",
										},
										flexWrap: {
											xs: "wrap",
											sm: "nowrap",
										},
									}}
								>
									<MultiSelect
										id="users-select"
										allValues={uniqueUsers}
										getKey={(u) => u.id}
										getLabel={(u) => u.name}
										value={selectedUsers}
										title="Reviewers"
										style={{ marginRight: "8px" }}
										onChange={setSelectedUsers}
									/>
									{tab === "peer-reviews" || tab === "admin" ? (
										<MultiSelect
											id="subjects-select"
											allValues={uniqueSubjects}
											getKey={(subject) => subject.id}
											getLabel={(subject) => subject.name}
											value={selectedSubjects}
											title="Reviewees"
											style={{ marginRight: "8px" }}
											onChange={setSelectedSubjects}
										/>
									) : null}
									<MultiSelect
										id="surveys-select"
										allValues={uniqueSurveys}
										getKey={(survey) => survey.id} //
										getLabel={(survey) => survey.name} //
										value={selectedSurveys}
										title="Surveys"
										style={{ marginRight: "8px" }}
										onChange={setSelectedSurveys}
									/>
									{tab === "admin" ? (
										<MultiSelect
											id="department-select"
											allValues={uniqueDepartments}
											value={selectedDepartments}
											title="Type"
											style={{ marginRight: "8px" }}
											onChange={setSelectedDepartments}
										/>
									) : null}
									<Box>
										<DateRangeFilter
											filterValue={selectedDateRange}
											setFilter={setSelectedDateRange}
											maxDate={TODAY.endOf("day").toDate()}
											useDayjs
										/>
									</Box>
								</Box>
							</>
						)}
					</Box>
					<Box>
						{status === "success" && !isLoadingResponses && (
							<FrameworkGrid
								sections={sections}
								status={status}
								responses={filteredResponses}
								isAAR
								aarParams={aarParams}
							/>
						)}
					</Box>
				</Box>
			</Section.Content>
		</Section>
	);
}

export default AARResponse;
