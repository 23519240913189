import {
	type ExplainedMetricResult,
	type Metric as MetricType,
	type PostNewMetricRequestBody,
	explainMetric,
	patchHideMetricValue,
	postNewMetricValue,
} from "@/api/Metrics";
import { usePopover } from "@/hooks/useGlobalPopover";
import Progress from "@/ui/atoms/Progress";
import UserAvatar from "@/ui/atoms/UserAvatar";
import { buildSharepointUrl } from "@/utils/general";
import {
	formatBigDollars,
	formatPercentage,
	formatTotal,
} from "@/utils/numberFormat";
import { VisibilityOff } from "@mui/icons-material";
import {
	Box,
	Button,
	ButtonBase,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

type ExplainedHistory = ExplainedMetricResult["history"][0];

const typeFormatter: Record<string, (value: number | string) => string> = {
	PERCENTAGE: formatPercentage,
	DOLLAR: formatBigDollars,
	FLOAT: (v) => formatTotal(v, 2),
	INTEGER: (v) => formatTotal(v, 0),
	DEFAULT: (v) => formatTotal(v, 2),
};

interface ExplainValueCellProps {
	id: string;
	closePopover: () => void;
	currentValue: string;
	isCalculated: boolean;
	isRolledUp: boolean;
	labelId: string;
	periodName: string;
	periodType: string;
	formatFunction: (value: number | string) => string;
	valorId: string;
}

function ExplainValueCell({
	id,
	closePopover,
	currentValue,
	isCalculated,
	isRolledUp,
	labelId,
	periodName,
	periodType,
	formatFunction,
	valorId,
}: ExplainValueCellProps) {
	const queryClient = useQueryClient();
	const [currentPane, setCurrentPane] = useState<null | "history" | "addValue">(
		null,
	);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitStatus, setSubmitStatus] = useState<string | null>(null);
	const [newValue, setNewValue] = useState<number | null>(null);
	const [commentary, setCommentary] = useState("");
	const {
		data: explanation = { source: null, calculatedSource: null, history: [] },
		isLoading,
		error,
	} = useQuery<ExplainedMetricResult>(
		["explainMetric", id],
		() => explainMetric(valorId, labelId, periodName, isCalculated),
		{ enabled: !!id },
	);

	const rolledUpStatement = explanation?.source?.rollupValues?.map(
		(v) => `${v.periodName} (${formatFunction(v.value)})`,
	);

	const onSubmit = async () => {
		try {
			setIsSubmitting(true);
			const body: PostNewMetricRequestBody = {
				value: newValue,
				commentary,
				period: periodName,
				periodType,
				labelId,
				valorId,
			};
			await postNewMetricValue(body);
			setSubmitStatus("success");
			queryClient.refetchQueries(["Metrics", valorId]);
			setTimeout(() => {
				setSubmitStatus(null);
				setCurrentPane(null);
				setNewValue(null);
				setCommentary("");
				closePopover();
			}, 2000);
		} catch (e) {
			setSubmitStatus(
				"Error: Try Again, Contact the Labs Team if this issue persists.",
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	const renderCalculation = ({
		lhs,
		rhs,
		operator,
		lhsValue,
		rhsValue,
	}: any) => {
		const lhsSideFunction = typeFormatter[lhs?.[0].valueType ?? "DEFAULT"];
		const lhsSide = lhs
			.map(({ name, value }) => `${name} (${lhsSideFunction(value)})`)
			.join(" + ");
		const rhsSideFunction = typeFormatter[rhs?.[0].valueType ?? "DEFAULT"];
		const rhsSide = rhs
			.map(({ name, value }) => `${name} (${rhsSideFunction(value)})`)
			.join(" + ");
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 1,
					padding: 1,
					border: 1,
					borderRadius: 1,
					borderColor: (t) => t.palette.divider,
				}}
				data-cy="explain-value-cell__calculation"
			>
				<Typography variant="body2">
					{lhsSide}
					{lhs.length > 1 ? ` = ${lhsSideFunction(lhsValue)}` : null}
				</Typography>
				<Typography variant="body2">{operator}</Typography>
				<Typography variant="body2">
					{rhsSide}
					{rhs.length > 1 ? ` = ${rhsSideFunction(rhsValue)}` : null}
				</Typography>
				<Typography variant="boldBody1">{currentValue}</Typography>
			</Box>
		);
	};

	const otherHistory =
		explanation?.history?.filter((x) => x.id !== explanation?.source?.id) ?? [];
	const currentHistoryValue = explanation?.history?.find(
		(x) => x.id === explanation?.source?.id,
	);

	const renderHistory = ({
		id: metricValueId,
		filename,
		path,
		product,
		fileCreatedAt,
		createdAt,
		value,
		commentary: uploadCommentary,
		user,
		deletingUser,
		deletedAt,
	}: ExplainedHistory) => (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 0.5,
				padding: 1,
				border: 1,
				borderRadius: 1,
				borderColor: (t) => t.palette.divider,
			}}
			data-cy="explain-value-cell__history-item"
		>
			<Typography
				variant="body1"
				sx={{
					textDecoration: deletedAt ? "line-through" : "none",
					color: (t) =>
						deletedAt ? t.palette.error.main : t.palette.text.primary,
				}}
			>
				{formatFunction(value)}
			</Typography>
			{filename ? (
				<Tooltip
					title={`${product} - ${path} File uploaded on ${dayjs(
						fileCreatedAt,
					).format("MM/DD/YYYY")}`}
				>
					<Link to={buildSharepointUrl(product, path, filename)}>
						<Typography>{filename}</Typography>
					</Link>
				</Tooltip>
			) : null}
			<Typography variant="caption">
				{`Data uploaded on ${dayjs(createdAt).format("MM/DD/YYYY")}`}
			</Typography>
			{user && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 0.5,
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-start",
							alignItems: "center",
							gap: 0.5,
						}}
					>
						<Typography variant="caption">
							{`Uploaded manually by ${user.firstName} ${user.lastName} `}
						</Typography>
						<UserAvatar user={user} />
					</Box>
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Typography variant="overline">Commentary </Typography>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography sx={{ fontStyle: "italic" }} variant="caption">
								{uploadCommentary}
							</Typography>
							<Tooltip title="Hide Value">
								<IconButton
									disabled={isSubmitting}
									sx={{
										visibility:
											deletedAt || isCalculated || isRolledUp
												? "hidden"
												: "visible",
									}}
									onClick={async () => {
										try {
											setIsSubmitting(true);
											await patchHideMetricValue(metricValueId);
											await queryClient.refetchQueries(["Metrics", valorId]);
										} finally {
											setIsSubmitting(false);
										}
									}}
								>
									<VisibilityOff />
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
				</Box>
			)}

			{(deletingUser || deletedAt) && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1,
					}}
				>
					<Typography variant="caption">
						{`deleted by ${deletingUser?.firstName} ${deletingUser?.lastName} `}
					</Typography>
					<Typography
						sx={{ fontWeight: "bolder !important" }}
						variant="caption"
					>
						{`on ${dayjs(deletedAt).format("MM/DD/YYYY")}`}
					</Typography>
				</Box>
			)}
		</Box>
	);

	if (currentPane === "history") {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 1,
					width: "340px",
					overflowY: "scroll",
					maxHeight: "400px",
				}}
				data-cy="explain-value-cell__history-pane"
			>
				<Box>
					{otherHistory.map((h) => renderHistory(h))}
					{currentHistoryValue && renderHistory(currentHistoryValue)}
				</Box>
				<Button
					size="small"
					onClick={() => setCurrentPane(null)}
					sx={{ alignSelf: "flex-end" }}
				>
					Close History
				</Button>
			</Box>
		);
	}
	if (currentPane === "addValue") {
		return (
			<Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
				<Typography variant="boldBody2">Add Value</Typography>
				{newValue && (
					<Typography variant="caption">
						{`New value: ${formatFunction(newValue)}`}
					</Typography>
				)}
				<TextField
					label="Value"
					disabled={isSubmitting}
					type="number"
					variant="outlined"
					value={newValue ?? ""}
					onChange={(e) => setNewValue(Number.parseFloat(e.target.value))}
					data-cy="explain-value-cell__value-input"
				/>
				<TextField
					label="Commentary"
					disabled={isSubmitting}
					variant="outlined"
					multiline
					value={commentary}
					onChange={(e) => setCommentary(e.target.value)}
					data-cy="explain-value-cell__commentary-input"
				/>
				{submitStatus && (
					<Typography
						sx={{
							color: submitStatus === "success" ? "success.main" : "error.main",
						}}
						variant="caption"
					>
						{submitStatus === "success"
							? "Value submitted successfully, you may need to refresh for them to show up."
							: submitStatus}
					</Typography>
				)}
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Button
						disabled={isSubmitting || newValue === null}
						variant="contained"
						onClick={onSubmit}
					>
						Submit
					</Button>
					<Button disabled={isSubmitting} onClick={() => setCurrentPane(null)}>
						Cancel
					</Button>
				</Box>
			</Box>
		);
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				gap: 1,
				width: "340px",
				minHeight: "200px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
				}}
			>
				<Box sx={{ minHeight: "120px" }}>
					{error && id && "Error loading explanation"}
					{isLoading ? (
						<Box
							sx={{
								height: "96px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Progress />
						</Box>
					) : (
						currentValue !== "-" && (
							<>
								{isRolledUp && rolledUpStatement && (
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											gap: 1,
											padding: 1,
											border: 1,
											borderRadius: 1,
											borderColor: (t) => t.palette.divider,
										}}
									>
										{rolledUpStatement.map((v) => (
											<Typography key={v} variant="body2">
												{v}
											</Typography>
										))}
										<Typography variant="boldBody1">{currentValue}</Typography>
									</Box>
								)}
								{currentHistoryValue
									? renderHistory(currentHistoryValue)
									: null}
								{explanation?.calculatedSource
									? renderCalculation(explanation.calculatedSource)
									: null}
							</>
						)
					)}
					{isRolledUp && (
						<Typography variant="body2">
							* This value is an aggregate of multiple values
						</Typography>
					)}
				</Box>
				{isCalculated && currentValue === "-" && (
					<Typography variant="body2">
						This is a calculated Metric and requires other fields to be filled
						in for the same period to have a value
					</Typography>
				)}
				<Box
					sx={{
						display: "flex",
						justifyContent: isRolledUp ? "flex-end" : "space-between",
					}}
					mt={0.5}
				>
					{isRolledUp || isCalculated ? null : (
						<Button
							size="small"
							onClick={() => setCurrentPane("history")}
							disabled={otherHistory?.length === 0}
						>
							See Metric History
						</Button>
					)}
					{isCalculated ? null : (
						<Button
							size="small"
							onClick={() => setCurrentPane("addValue")}
							variant="contained"
						>
							Add New Value
						</Button>
					)}
				</Box>
			</Box>
		</Box>
	);
}

interface MetricValueCellProps {
	row: {
		original: {
			isCalculated: boolean;
			labelId: string;
			name: string;
			entityId: string;
			valueType: string;
		};
	};
	getValue: () => MetricType | null;
	period: string;
	periodType: string;
}

export function MetricValueCell({
	row,
	getValue,
	period,
	periodType,
}: MetricValueCellProps) {
	const cellValue = getValue();
	const { openPopover, closePopover } = usePopover();
	const buttonRef = useRef<HTMLButtonElement>(null);
	const theme = useTheme();
	let renderValue = "-";
	let isRolledUp = false;
	let isManuallyEntered = false;
	let formatFunction = (v: number) => formatTotal(v, 2);
	const {
		original: { isCalculated, labelId, name, entityId, valueType },
	} = row;

	if (typeFormatter[valueType]) {
		formatFunction = typeFormatter[valueType];
	}

	if (cellValue) {
		const {
			value,
			isRolledUp: cellRolledUp,
			isManuallyEntered: manuallyEntered,
		} = cellValue;
		isRolledUp = cellRolledUp;
		isManuallyEntered = manuallyEntered;
		renderValue = formatFunction(value);
		if (isRolledUp && renderValue !== "-") {
			renderValue = `${renderValue}*`;
		}
		if (value === "Infinity") {
			renderValue = "N/A";
		}
	}

	const chooseColor = () => {
		if (isCalculated) {
			return theme.palette.text.disabled;
		}
		if (isManuallyEntered) {
			return theme.palette.primary.main;
		}
		return theme.palette.text.primary;
	};

	return (
		<ButtonBase
			ref={buttonRef}
			onClick={() =>
				openPopover(
					buttonRef,
					<ExplainValueCell
						id={cellValue?.id || ""}
						closePopover={closePopover}
						currentValue={renderValue}
						isCalculated={isCalculated}
						isRolledUp={isRolledUp}
						label={name}
						labelId={labelId}
						periodName={period}
						periodType={periodType}
						formatFunction={formatFunction}
						valorId={entityId}
					/>,
					`${name} - ${period}`,
				)
			}
			sx={{
				width: "100%",
				height: "24px",
				display: "flex",
				justifyContent: "flex-start",
				padding: (t) => t.spacing(0.5),
				borderRadius: (t) => t.spacing(1),
			}}
			data-cy="metric-value-cell__button"
		>
			<Typography
				variant="body2"
				sx={{
					color: `${chooseColor()} !important`,
					fontWeight: isCalculated ? "bold" : "normal",
					fontStyle: isCalculated ? "italic" : "normal",
				}}
			>
				{renderValue}
			</Typography>
		</ButtonBase>
	);
}

export default MetricValueCell;
