import type { paths } from "@/api/index";
import { buildURLParameters, searchParamsObject } from "@/utils/general";
/* eslint-disable no-console */
import axios from "axios";

type GetMSLPortfolioCommunication =
	paths["/api/reporting/msl-portfolio-management"]["get"];
export type MSLPortfolioCommunication =
	GetMSLPortfolioCommunication["responses"]["200"]["content"]["application/json"];
export const getMSLPortfolioCommunication = async (
	limit = 25,
	marker: string | null = null,
	sort: string[] | null = null,
	filter: { [key: string]: string | any } | null = null,
): Promise<MSLPortfolioCommunication> => {
	const params = searchParamsObject({
		limit,
		marker,
		sort,
		filter: filter ? JSON.stringify(filter) : null,
	});
	const response = await axios.get("/api/reporting/msl-portfolio-management", {
		params,
	});
	return response.data;
};

type GetIHEPortfolioChanges = paths["/api/reporting/ihe-portfolio"]["get"];
export type IHEPortfolioChanges =
	GetIHEPortfolioChanges["responses"]["200"]["content"]["application/json"];
export const getIHEPortfolioChanges = async (
	limit = 25,
	marker: string | null = null,
	sort: string[] | null = null,
	filter: { [key: string]: string | any } | null = null,
): Promise<IHEPortfolioChanges> => {
	const params = searchParamsObject({
		limit,
		marker,
		"sort[]": sort,
		filter: filter ? JSON.stringify(filter) : null,
	});
	const response = await axios.get("/api/reporting/ihe-portfolio", { params });
	return response.data;
};

type AuditLPPdfs = paths["/api/reporting/lp-report"]["post"];
export type AuditLPPdfsBody =
	AuditLPPdfs["requestBody"]["content"]["multipart/form-data"];
export type AuditLPPdfsResponse =
	AuditLPPdfs["responses"]["200"]["content"]["application/json"];
export const auditLPPdfs = async (
	pdfFile,
	excelFile,
	partitionPdfBy,
): Promise<void> => {
	const formData = new FormData();
	formData.append("pdf_file", pdfFile);
	formData.append("excel_file", excelFile);
	formData.append("split_by", partitionPdfBy);
	const response = await axios.post("/api/reporting/lp-report", formData, {
		responseType: "blob",
	});

	const filename = response.headers["content-disposition"]
		.split(";")
		.find((x) => x.includes("filename"))
		.replace("filename=", "")
		.replace(/"/g, "")
		.trim();

	const url = window.URL.createObjectURL(new Blob([response.data]));
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

type GetTooltipData = paths["/api/reporting/report-tooltip"]["get"];
export type TooltipData =
	GetTooltipData["responses"]["200"]["content"]["application/json"];
export const getTooltipData = async (valorIds): Promise<TooltipData> => {
	const params = buildURLParameters({
		valorIds,
	});
	const response = await axios.get(`/api/reporting/report-tooltip?${params}`);
	return response.data;
};

type GetSignalModelPipeline =
	paths["/api/reporting/signal-model-pipeline"]["get"];
export type SignalModelPipeline =
	GetSignalModelPipeline["responses"]["200"]["content"]["application/json"];
export const getSignalModelPipeline = async (
	limit = 25,
	marker: string | null = null,
	sort: string[] | null = null,
	filter: { [key: string]: string | any } | null = null,
): Promise<SignalModelPipeline> => {
	const params = buildURLParameters({
		limit,
		marker,
		sort,
		filter: filter ? JSON.stringify(filter) : null,
	});
	const response = await axios.get(
		`/api/reporting/signal-model-pipeline?${params}`,
	);
	return response.data;
};

type GetAffinitySignalModelPipeline =
	paths["/api/reporting/affinity-signal-model-pipeline"]["get"];
export type AffinitySignalModelPipeline =
	GetAffinitySignalModelPipeline["responses"]["200"]["content"]["application/json"];
export const getAffinitySignalModelPipeline = async (
	limit = 25,
	marker: string | null = null,
	sort: string[] | null = null,
	filter: { [key: string]: string | any } | null = null,
): Promise<AffinitySignalModelPipeline> => {
	const params = buildURLParameters({
		limit,
		marker,
		sort,
		filter: filter ? JSON.stringify(filter) : null,
	});
	const response = await axios.get(
		`/api/reporting/affinity-signal-model-pipeline?${params}`,
	);
	return response.data;
};

type GetSignalModelPipelineExplainability =
	paths["/api/reporting/signal-model-pipeline/explainability"]["get"];
export type SignalModelPipelineExplainability =
	GetSignalModelPipelineExplainability["responses"]["200"]["content"]["application/json"];
export const getSignalModelPipelineExplainability = async (
	limit = 25,
	marker: string | null = null,
	sort: string[] | null = null,
	filter: { [key: string]: string | any } | null = null,
): Promise<SignalModelPipelineExplainability> => {
	const params = buildURLParameters({
		limit,
		marker,
		sort,
		filter: filter ? JSON.stringify(filter) : null,
	});
	const response = await axios.get(
		`/api/reporting/signal-model-pipeline/explainability?${params}`,
	);
	return response.data;
};

type GetPortfolioHealth = paths["/api/reporting/fund-health/{fundId}"]["get"];
export type PortfolioHealth =
	GetPortfolioHealth["responses"]["200"]["content"]["application/json"];
export const getPortfolioHealth = async (
	fundId: string,
): Promise<PortfolioHealth> => {
	const response = await axios.get(`/api/reporting/fund-health/${fundId}`);
	return response.data;
};

export const getVAAIReport = async (reportType) => {
	const response = await axios.get(
		`/api/reporting/vaai-deals?reportType=${reportType}`,
	);
	return response.data;
};

export const getHomepageCalendar = async (limit, tasksOnly = false) => {
	const response = await axios.get(
		`/api/reporting/calendar-homepage?limit=${limit}&tasksOnly=${tasksOnly}`,
	);
	return response.data;
};

export default {
	auditLPPdfs,
	getPortfolioHealth,
	getIHEPortfolioChanges,
	getMSLPortfolioCommunication,
	getTooltipData,
	getVAAIReport,
};
