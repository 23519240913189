export type MeetingType =
	| "Company Meeting / Call"
	| "Thesis Formulation"
	| "Deal Engagement / Status"
	| "Fundraising (Debt and Equity) – Active"
	| "Fundraising (Debt and Equity) – Closed"
	| "Liquidity Event - Active"
	| "Liquidity Event - Closed"
	| "Pro-Entropic M&A - Active"
	| "Pro-Entropic M&A - Closed"
	| "Scale Group"
	| "Research"
	| "Other";

export const CompanyMeetingCall: MeetingType = "Company Meeting / Call";
export const ThesisFormulation: MeetingType = "Thesis Formulation";
export const DealEngagementStatus: MeetingType = "Deal Engagement / Status";
export const CapitalMarketsActive: MeetingType =
	"Fundraising (Debt and Equity) – Active";
export const CapitalMarketsClosed: MeetingType =
	"Fundraising (Debt and Equity) – Closed";
export const LiquidityEventActive: MeetingType = "Liquidity Event - Active";
export const LiquidityEventClosed: MeetingType = "Liquidity Event - Closed";
export const MergersAndAcquisitionActive: MeetingType =
	"Pro-Entropic M&A - Active";
export const MergersAndAcquisitionClosed: MeetingType =
	"Pro-Entropic M&A - Closed";
export const Operations: MeetingType = "Scale Group";
export const Research: MeetingType = "Research";
export const Other: MeetingType = "Other";

export default MeetingType;
