import { useTheme } from "@mui/material";
import React from "react";

export default function NotFoundPage() {
	const theme = useTheme();

	return (
		<div
			style={{
				width: "80%",
				marginTop: "10%",
				marginLeft: "10%",
				textAlign: "center",
			}}
		>
			<h1
				style={{
					color: "red",
					fontSize: "5em",
				}}
			>
				404
			</h1>
			<label
				style={{
					color: theme.palette.text.secondary,
					fontSize: "1.5em",
				}}
			>
				It looks like you may not have access to this page or it doesn&apos;t
				exist.
				<br />
				If you think this is an error, please contact the labs team.
			</label>
		</div>
	);
}
