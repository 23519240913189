import type { Organization } from "@/api/Organization";
import { Box, Card } from "@mui/material";
import React from "react";

import Stats from "./Stats";

type PublicDataPageProps = {
	company: Organization;
};

export function PublicDataPage({ company }: PublicDataPageProps) {
	return (
		<Box display="flex" flexDirection="column" gap={4}>
			<Card elevation={0}>
				<Stats company={company} />
			</Card>
		</Box>
	);
}

export default PublicDataPage;
