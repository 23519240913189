import React from "react";

import { Box, Typography, styled } from "@mui/material";

interface XMLParseError {
	message: string;
	source: string;
}

export const formatContext = (
	context: any,
): { element: Element | null; error: XMLParseError | null } => {
	if (!context) {
		return {
			element: null,
			error: {
				message: "No context provided",
				source: "null",
			},
		};
	}

	// Convert context to string and clean it up
	const xmlString = typeof context === "string" ? context : context.toString();

	// Debug the input
	console.group("XML Parsing Debug");
	console.log("Raw input:", context);
	console.log("Converted to string:", xmlString);

	try {
		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(xmlString, "text/xml");

		// Check for parsing errors
		const parseError = xmlDoc.getElementsByTagName("parsererror");
		if (parseError.length > 0) {
			const errorMessage = parseError[0].textContent;
			console.error("XML Parse Error:", errorMessage);
			console.log("Problematic XML:", xmlString);
			console.groupEnd();

			return {
				element: null,
				error: {
					message: errorMessage || "Invalid XML structure",
					source: xmlString,
				},
			};
		}

		console.log("Parsed successfully:", xmlDoc.documentElement);
		console.groupEnd();
		return {
			element: xmlDoc.documentElement,
			error: null,
		};
	} catch (error) {
		console.error("XML Processing Error:", error);
		console.log("Problematic XML:", xmlString);
		console.groupEnd();

		return {
			element: null,
			error: {
				message: error instanceof Error ? error.message : "Unknown error",
				source: xmlString,
			},
		};
	}
};

export const XMLDebugView: React.FC<{ error: XMLParseError }> = ({ error }) => {
	return (
		<Box sx={{ p: 2, backgroundColor: "#fff3f3", borderRadius: 1 }}>
			<Typography color="error" variant="h6" gutterBottom>
				XML Parse Error
			</Typography>
			<Typography variant="body2" gutterBottom>
				{error.message}
			</Typography>
			<Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
				Problematic XML:
			</Typography>
			<Box
				sx={{
					backgroundColor: "#fff",
					p: 2,
					borderRadius: 1,
					maxHeight: "200px",
					overflow: "auto",
					"& pre": {
						margin: 0,
						whiteSpace: "pre-wrap",
						wordBreak: "break-all",
					},
				}}
			>
				<pre>{error.source}</pre>
			</Box>
		</Box>
	);
};

export const XMLNode = styled(Box)(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	borderLeft: `1px dashed ${theme.palette.grey[300]}`,
	margin: theme.spacing(0.5, 0),
}));

export const XMLTag = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	cursor: "pointer",
	"&:hover": {
		backgroundColor: theme.palette.action.hover,
	},
}));

export const XMLContent = styled(Typography)(({ theme }) => ({
	marginLeft: theme.spacing(1),
	color: theme.palette.text.secondary,
}));

// Add this new component for rendering XML nodes
export const XMLTreeNode: React.FC<{
	node: Element;
	level?: number;
}> = ({ node, level = 0 }) => {
	const [isExpanded, setIsExpanded] = React.useState(true);
	const hasChildren = node.children.length > 0 || node.textContent?.trim();

	const getAttributes = (node: Element) => {
		return Array.from(node.attributes)
			.map((attr) => `${attr.name}="${attr.value}"`)
			.join(" ");
	};

	const attributes = getAttributes(node);
	const textContent =
		node.childNodes.length === 1 && node.firstChild?.nodeType === 3
			? node.textContent?.trim()
			: null;

	return (
		<XMLNode>
			<XMLTag onClick={() => hasChildren && setIsExpanded(!isExpanded)}>
				{hasChildren && (
					<Typography component="span" sx={{ mr: 1 }}>
						{isExpanded ? "▼" : "▶"}
					</Typography>
				)}
				<Typography component="span" sx={{ color: "primary.main" }}>
					&lt;{node.tagName}
					{attributes && (
						<Typography component="span" sx={{ color: "success.main", ml: 1 }}>
							{attributes}
						</Typography>
					)}
					{!hasChildren ? "/>" : ">"}
				</Typography>
				{textContent && (
					<XMLContent>
						{textContent}
						&lt;/{node.tagName}&gt;
					</XMLContent>
				)}
			</XMLTag>

			{hasChildren && isExpanded && !textContent && (
				<Box sx={{ ml: 2 }}>
					{Array.from(node.childNodes).map((child, index) => {
						if (child.nodeType === 1) {
							// Element node
							return (
								<XMLTreeNode
									key={index}
									node={child as Element}
									level={level + 1}
								/>
							);
						} else if (child.nodeType === 3 && child.textContent?.trim()) {
							// Text node
							return (
								<Typography key={index} sx={{ ml: 2, color: "text.secondary" }}>
									{child.textContent.trim()}
								</Typography>
							);
						}
						return null;
					})}
					<Typography sx={{ color: "primary.main" }}>
						&lt;/{node.tagName}&gt;
					</Typography>
				</Box>
			)}
		</XMLNode>
	);
};
const XML = {
	XMLTag,
	XMLContent,
	XMLNode,
	XMLTreeNode,
	XMLDebugView,
	formatContext,
};

export default XML;
