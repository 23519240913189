import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import highlightWords from "highlight-words";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(() => ({
	container: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		cursor: "default",
	},
	query: {
		fontWeight: 800,
		cursor: "default",
	},
}));

export default function SAYTKeywordResult(props) {
	const classes = useStyles();
	const { term, query } = props;

	function renderTextWithHighlighting(text, queryText) {
		if (!text || !queryText) {
			return <Typography component="span">{text}</Typography>;
		}

		const chunks = highlightWords({
			text,
			query: queryText,
		});

		return chunks.map(({ key, text: textChunk, match }) => (
			<Typography
				key={key}
				component="span"
				className={match ? classes.query : null}
			>
				{textChunk}
			</Typography>
		));
	}

	return (
		<Box className={classes.container}>
			<Box paddingLeft={2}>
				<Box>{renderTextWithHighlighting(term, query)}</Box>
			</Box>
		</Box>
	);
}

SAYTKeywordResult.propTypes = {
	term: PropTypes.string.isRequired,
	query: PropTypes.string.isRequired,
};
