import type { ResponsesSearchResult } from "@/api/FrameworkSurvey";
import { useTheme } from "@mui/material";
import { useEffect, useMemo } from "react";

// Type guard to check if organization exists and has required properties
const isValidOrganization = (
	org: ResponsesSearchResult["organization"] | undefined,
): org is ResponsesSearchResult["organization"] =>
	org !== undefined && "valorId" in org && "name" in org;

export function processOpportunities(p2pResponses: ResponsesSearchResult[]) {
	if (!p2pResponses) return [];

	return Object.values(
		p2pResponses.reduce<Record<string, Organization>>((acc, response) => {
			if (!isValidOrganization(response.organization)) return acc;

			const tasks = response.linkedTasks;

			return (
				tasks?.reduce(
					(acc2, task) => ({
						...acc2,
						[task.internalId]: {
							opportunity: task,
							organization: response.organization,
						},
					}),
					acc,
				) || acc
			);
		}, {}),
	);
}
