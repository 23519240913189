import { getConversations } from "@/api/Chat";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Chat from "./Chat";

export default function RecentChats() {
	const navigate = useNavigate();
	const { data: chats = [], isLoading: chatsLoading } = useQuery(
		"conversations",
		() => getConversations(),
	);
	const isLoading = chatsLoading;

	const isSMDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const slicedChats = isSMDown ? chats.slice(0, 1) : chats.slice(0, 3);

	return (
		<Box display="flex" flexDirection="column" gap={2} width="100%">
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				width="100%"
			>
				<Typography
					variant="h3"
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						gap: 1,
					}}
				>
					<ChatBubbleOutlineIcon />
					Your recent chats
				</Typography>

				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						navigate("/grok/search");
					}}
					endIcon={<ArrowForwardIcon />}
					size="small"
				>
					View All
				</Button>
			</Box>
			{chats.length === 0 && !isLoading && (
				<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
					<Typography>No recent chats</Typography>
				</Box>
			)}
			{chats.length > 0 && (
				<Box
					display="grid"
					gridTemplateColumns={{
						xs: "1fr",
						sm: "repeat(3, 1fr)",
					}}
					gap={2}
					sx={{
						maxHeight: { xs: "56vh", sm: "auto" },
						overflow: { xs: "auto" },
					}}
				>
					{slicedChats.map((chat) => (
						<Chat
							key={chat.id}
							title={chat.title}
							createdAt={chat.createdAt}
							onClick={() => {
								navigate(`/grok/chat/${chat.id}`);
							}}
						/>
					))}
				</Box>
			)}
		</Box>
	);
}
