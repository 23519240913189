import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress, TextField, debounce } from "@mui/material";
import omit from "lodash/omit";
import type React from "react";
import { useEffect, useMemo, useState } from "react";

type SAYTOption =
	| {
			id: string;
			name: string;
	  }
	| string;

type AdditionalOption = {
	id: string;
	text: string;
};

type SAYTProps = {
	name: string;
	label: string;
	value: SAYTOption;
	unassignedOption: SAYTOption;
	multiple: boolean;
	fetchSelectedOption: () => Promise<SAYTOption>;
	search: (query: string) => Promise<SAYTOption>;
	renderOption: (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		props: any,
		option: SAYTOption,
		state: { selected: boolean; inputValue: string },
	) => JSX.Element;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	renderTags?: (value: SAYTOption[], getTagProps: any) => JSX.Element;
	getOptionSelected?: (option: SAYTOption, value: SAYTOption) => boolean;
	getOptionLabel?: (option: SAYTOption) => string;
	onChange?: (option: SAYTOption) => void;
	onBlur?: (event: React.FocusEvent) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	filterOptions?: (options: SAYTOption[], state: any) => SAYTOption[];
	required?: boolean;
	useDefaultFilter?: boolean;
	debounceTime?: number;
	additionalOptions?: AdditionalOption[];
	handleAdditionalOptions?: (option: SAYTOption, inputValue: string) => boolean;
};

function SAYT({
	name,
	label,
	value,
	multiple,
	search,
	renderOption,
	renderTags,
	getOptionSelected,
	getOptionLabel,
	onChange,
	onBlur,
	error,
	useDefaultFilter = false,
	debounceTime = 200,
	additionalOptions,
	handleAdditionalOptions,
	...rest
}: SAYTProps) {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentInput, setCurrentInput] = useState("");
	const [internalState, setInternalState] = useState({});

	const InputProps = omit(rest, ["onBlur, unassignedOption"]);

	useEffect(() => {
		setInternalState(value);
	}, [value]);

	const debouncedSearch = useMemo(
		() => debounce((t, callback) => search(t).then(callback), debounceTime),
		[search],
	);

	const onInputChange = async (event) => {
		const newInput = event.target.value;
		setCurrentInput(newInput);
		setLoading(true);
		debouncedSearch(event.target.value, (results) => {
			setOptions(
				additionalOptions ? [...results, ...additionalOptions] : results,
			);
			setLoading(false);
		});
	};

	const isRequired = rest.required;
	const hasValue = !!(multiple ? value?.length > 0 : value);

	return (
		<Autocomplete
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...InputProps}
			multiple={multiple}
			open={open}
			value={value || null}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			getOptionSelected={
				getOptionSelected ||
				((option, optionValue) => option.id === optionValue.id)
			}
			getOptionLabel={getOptionLabel || ((option) => option.name)}
			options={options}
			loading={loading}
			defaultValue={value}
			onChange={(event, newValue) => {
				let shouldUpdateValue = true;
				if (handleAdditionalOptions) {
					shouldUpdateValue = !handleAdditionalOptions(newValue, currentInput);
				}
				if (shouldUpdateValue) {
					onChange(newValue);
					setInternalState(newValue);
				}
			}}
			onBlur={() => {
				if (onBlur) {
					onBlur(internalState);
				}
			}}
			filterOptions={useDefaultFilter ? undefined : (x) => x}
			renderOption={renderOption}
			renderTags={renderTags}
			renderInput={(params) => (
				<TextField
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...params}
					name={name}
					label={label + (isRequired && hasValue ? " *" : "")}
					variant="outlined"
					onChange={onInputChange}
					required={isRequired && !hasValue}
					error={error}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<>
								<SearchIcon />
								{params.InputProps.startAdornment}
							</>
						),
						endAdornment: (
							<>
								{loading ? (
									<CircularProgress color="inherit" size={20} />
								) : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
			error={error}
		/>
	);
}

export default SAYT;
