import TableReport from "@/pages/ProcessManagement/TableReport";
import Page from "@/ui/molecules/Page";
import { Card } from "@mui/material";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React from "react";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(quarterOfYear);

export default function ViewReport({
	processId,
	title,
	viewId,
	disableFilters = false,
	formId = null,
	dataCy,
}: {
	processId: string;
	title: string;
	viewId: string;
	disableFilters?: boolean;
	formId?: string;
	dataCy?: string;
}) {
	return (
		<Page data-cy={dataCy}>
			<Page.Title>{title}</Page.Title>
			<Page.Content>
				<Card elevation={0}>
					<TableReport
						processId={processId}
						viewId={viewId}
						disableFilters={disableFilters}
						formId={formId}
					/>
				</Card>
			</Page.Content>
		</Page>
	);
}

export function PassFollowThrough() {
	return (
		<ViewReport
			processId="deals"
			viewId="590eb5e3-12f1-415d-b391-faff56f4c04b"
			title="Pass Follow Through"
			dataCy="page__pass_follow_through"
		/>
	);
}

export function ClosedLastSevenDays() {
	return (
		<ViewReport
			processId="deals"
			viewId="a40cc835-581c-4502-bd65-86cedc88a925"
			title="Closed Last 7 Days"
			dataCy="page__closed_investments"
		/>
	);
}

export function NewOpportunitiesPreOAReport() {
	return (
		<ViewReport
			processId="deals"
			viewId="aebf9438-5fad-48c4-851c-f2abc408490f"
			title="New Opportunities Pre-OA"
			dataCy="page__pre_oa_status"
		/>
	);
}

export function PostCloseActions() {
	return (
		<ViewReport
			processId="deals"
			viewId="50091f73-11eb-447b-8856-196036e6a7f5"
			title="Post Closing Actions"
			disableFilters
			dataCy="page__post_closing_items"
		/>
	);
}

export function ConsideringForTSReport() {
	return (
		<ViewReport
			processId="deals"
			viewId="381fbd70-e4a3-4f45-b300-7dc1a9a2c95c"
			title="Considering for Term Sheet"
			dataCy="page__considering_for_term_sheet"
		/>
	);
}

export function UnderTermSheet() {
	return (
		<ViewReport
			processId="deals"
			viewId="36bf04f6-ef59-4f69-99cc-e606be141993"
			title="Under Term Sheet"
			dataCy="page__under_term_sheet"
		/>
	);
}

export function NewOppsDistributionReport() {
	return (
		<ViewReport
			processId="deals"
			viewId="bfe9bc4b-8b93-4be1-a931-e4e7daacb12d"
			title="New Opportunities Distribution"
			dataCy="page__new_opportunity_distribution"
		/>
	);
}

export function FollowReport() {
	return (
		<ViewReport
			processId="deals"
			viewId="e8707b9a-5dd3-41e3-b46b-f87b6ae5457e"
			title="Company Follow List"
			disableFilters
			dataCy="page__following_opportunities"
		/>
	);
}
export function InvestmentAnnouncementReport() {
	return (
		<ViewReport
			processId="deals"
			viewId="c49061da-bcf8-4a03-b95f-528ff6819677"
			title="Investment Announcement"
			dataCy="page__investment_announcements"
		/>
	);
}
