import type { ResponsesSearchResult } from "@/api/FrameworkSurvey";
import {
	ONE_MONTH_CHOICE_IDS,
	OS_SURVEYS,
	P2P_SURVEYS,
	SIX_MONTH_CHOICE_IDS,
} from "../constants";

export default function matchSurveys(
	activeDealSurveys: ResponsesSearchResult[0][0][],
): {
	osSurvey: ResponsesSearchResult[0][0] | undefined;
	oneMonthSurvey: ResponsesSearchResult[0][0] | undefined;
	sixMonthSurvey: ResponsesSearchResult[0][0] | undefined;
} {
	if (!activeDealSurveys) return null;

	const osSurveys = activeDealSurveys.filter((n) =>
		Object.values(OS_SURVEYS).includes(n.surveyId),
	);

	const p2pSurveys = activeDealSurveys.filter((n) =>
		Object.values(P2P_SURVEYS).includes(n.surveyId),
	);

	const sixMonthSurvey = p2pSurveys.find((survey) =>
		Object.values(survey.answers).some((answer) =>
			SIX_MONTH_CHOICE_IDS.includes(answer.choice.id),
		),
	);

	const oneMonthSurvey = p2pSurveys.find(
		(survey) =>
			Object.values(survey.answers).some((answer) =>
				ONE_MONTH_CHOICE_IDS.includes(answer.choice.id),
			) &&
			(!sixMonthSurvey || survey.department === sixMonthSurvey.department),
	);

	const activePTPSurvey = sixMonthSurvey || oneMonthSurvey;
	// find os survey with same department
	const osSurvey = osSurveys.find(
		(survey) => survey.department === activePTPSurvey.department,
	);

	return { osSurvey, oneMonthSurvey, sixMonthSurvey };
}
