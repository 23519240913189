/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useCallback, useLayoutEffect } from "react";

export function ItemMeasurer({ children, measure, tagName, ...restProps }) {
	const resizeObserverRef = useRef(null);
	const elRef = useRef(null);

	const measureRef = useRef(measure);
	measureRef.current = measure;

	const refSetter = useCallback((el) => {
		const resizeObserver = resizeObserverRef.current;

		if (resizeObserver !== null && elRef.current !== null) {
			resizeObserver.unobserve(elRef.current);
		}

		elRef.current = el;

		if (resizeObserver !== null && elRef.current !== null) {
			resizeObserver.observe(elRef.current);
		}
	}, []);

	useLayoutEffect(() => {
		const update = () => {
			measureRef.current(elRef.current);
		};

		// sync measure for initial render ?
		update();

		const resizeObserver = resizeObserverRef.current
			? resizeObserverRef.current
			: new ResizeObserver(update);

		const el = elRef.current;
		if (el !== null) {
			resizeObserver.observe(el);
		}
		resizeObserverRef.current = resizeObserver;

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	const Tag = tagName;

	return (
		<Tag ref={refSetter} {...restProps}>
			{children}
		</Tag>
	);
}

export default ItemMeasurer;
