import { useAuth } from "@/hooks/useAuth";
import { useEffect, useState } from "react";

const useIsInTouchPWA = () => {
	const [isInTouchPWA, setIsInTouchPWA] = useState(false);
	const { isAuthenticated } = useAuth();

	useEffect(() => {
		const isPWA =
			window.navigator.standalone ||
			window.matchMedia("(display-mode: standalone)").matches;
		const isTouchDevice =
			"ontouchstart" in window ||
			navigator.maxTouchPoints > 0 ||
			navigator.msMaxTouchPoints > 0;
		setIsInTouchPWA(isPWA && isTouchDevice);
	}, []);

	if (!isAuthenticated) return false;

	return isInTouchPWA;
};

export default useIsInTouchPWA;
