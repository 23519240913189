import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import { formatBigDollars } from "@/utils/numberFormat";
import {
	Box,
	Button,
	Card,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Link,
	Popover,
	Tooltip,
	Typography,
	useMediaQuery,
} from "@mui/material";
import lookup from "country-code-lookup";
import dayjs from "dayjs";
/* eslint-disable react/prop-types */
import React, { useState } from "react";

import Clamps from "@/components/Clamps";
import ClampTooltip from "@/components/ClampsTooltip";
import { SectorChip } from "@/components/SectorChip";
import { SignalChip } from "@/components/SignalChip";
import { styled } from "@mui/system";
import startcase from "lodash/startCase";

const NoStyleLink = styled(Link)(() => ({
	cursor: "pointer",
	textDecoration: "none",
	color: "inherit",
	"&:hover": {
		textDecoration: "underline",
	},
}));

export default function SearchList({
	totalSize,
	items,
	combinedFirmsList,
	relativeDealSearch,
	pages,
	topCompanies,
}) {
	const [showDetails, setShowDetails] = useState(null);
	const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));

	function renderDeal(dealParams, company) {
		let dealTitle = "";
		let deal = {};
		let dealDate = "";
		if (dealParams === "First Deal") {
			dealTitle = "First Deal";
			[deal] = company.deals;
		}
		if (dealParams === "Most Recent Deal" || dealParams === "All Deals") {
			dealTitle = "Most Recent Deal";
			deal = company.deals[company.deals.length - 1];
		} else {
			const namedDeal = company.deals.filter(
				(x) => x.dealType === dealParams,
			)[0];
			if (namedDeal) {
				dealTitle = "";
				deal = namedDeal;
			} else {
				return null;
			}
		}
		if (deal?.dealDate) {
			dealDate = dayjs(deal.dealDate).format("DD MMM YYYY");
		}

		let investors = [];
		let investorsContent = "";
		if (combinedFirmsList?.length) {
			investors = deal?.investorNames?.filter(
				(x) => combinedFirmsList.indexOf(x) !== -1,
			);
		} else {
			investors = deal?.investorNames;
		}
		if (investors?.length > 2) {
			investorsContent = `${investors.slice(0, 2).join(", ")} + ${investors.length - 2}`;
		} else {
			investorsContent = investors.join(", ");
		}

		return (
			<Box>
				<Typography variant="subtitle1">
					{`${dealTitle} ${dealDate}`}
				</Typography>
				<Typography variant="subtitle2">
					{`${deal?.dealType ?? ""}  ${deal?.roundSize ? `- ${formatBigDollars(deal?.roundSize)}` : ""}  ${deal?.premoneyValuation ? `; ${formatBigDollars(deal?.premoneyValuation)}` : ""}`}
				</Typography>
				<Box>
					<Tooltip
						title={investors.join(", ")}
						open={investors?.length <= 2 ? false : undefined}
					>
						<Typography variant="subtitle2">{investorsContent}</Typography>
					</Tooltip>
				</Box>
			</Box>
		);
	}

	function renderClassification(co) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					fontStyle: "italic",
				}}
			>
				<Typography variant="caption">
					{co.synapticClassifications.join(", ")}
				</Typography>
			</Box>
		);
	}

	function renderSignals({ signals, valorId }) {
		return (
			<Box display="flex" flexWrap="wrap" sx={{ gap: "8px" }}>
				{signals
					.sort((a, b) => a.priority - b.priority)
					.map((s) => (
						<SignalChip
							key={s.signalId}
							signal={s}
							showIcon
							showBorder
							valorId={valorId}
						/>
					))}
			</Box>
		);
	}

	function renderSectors({ sectorPredictions }) {
		if (!sectorPredictions) return null;
		return (
			<Box
				mt="8px"
				display="flex"
				flexWrap="nowrap"
				sx={{ gap: "8px", overflow: "hidden", overflowX: "auto" }}
			>
				{sectorPredictions?.map((s) => (
					<SectorChip key={s} sectorType={s} />
				))}
			</Box>
		);
	}

	const topCompaniesValorIdsSet = new Set(topCompanies.map((c) => c.valorId));
	const valorIdToName = topCompanies.reduce((acc, c) => {
		acc[c.valorId] = c.name;
		return acc;
	}, {});

	function CoCard({ co, elHeight }) {
		const [popOverAnchor, setPopOverAnchor] = useState(null);

		const topCompaniesExec = {};
		let execsThatWorkedAtTopCompanies = 0;
		co.executives?.forEach((exec) => {
			let workedAtTopCompany = 0;
			exec.previousCompanies.forEach((c) => {
				if (topCompaniesValorIdsSet.has(c.valorId)) {
					if (!topCompaniesExec[c.valorId]) {
						topCompaniesExec[c.valorId] = {
							set: new Set(),
							arr: [],
						};
					}
					if (!topCompaniesExec[c.valorId].set.has(exec.name)) {
						topCompaniesExec[c.valorId].set.add(exec.name);
						topCompaniesExec[c.valorId].arr.push(
							<div>
								<Link
									style={
										exec.url
											? {}
											: {
													textDecoration: "inherit",
													color: "inherit",
													pointerEvents: "none",
												}
									}
									href={`https://${exec.url}`}
									target="_blank"
								>
									{exec.name}
								</Link>{" "}
								{startcase(exec.currentTitle)}, previous role{" "}
								<strong>{startcase(c.title)}</strong>
							</div>,
						);
					}
					workedAtTopCompany = 1;
				}
			});
			execsThatWorkedAtTopCompanies += workedAtTopCompany;
		});

		return (
			<Box
				padding={1}
				height={elHeight}
				display="flex"
				flex="1"
				data-cy={`search-list-item__${co.valorId}`}
			>
				<Card
					style={{
						display: "flex",
						padding: "8px",
						flexDirection: "column",
						flex: 1,
					}}
				>
					<Grid
						container
						style={{
							flex: 1,
						}}
					>
						<Grid
							container
							item
							xs={12}
							style={{
								marginBottom: "4px",
								paddingBottom: "4px",
								borderBottom: "1px solid gray",
							}}
						>
							<Grid item xs={6} sm={4}>
								<Box
									style={{ display: "flex", gap: "16px", overflow: "hidden" }}
								>
									<Link
										sx={{
											cursor: "pointer",
											textDecoration: "none",
											color: "inherit",
											"&:hover": {
												textDecoration: "underline",
											},
										}}
										href={`/org/${co.valorId}`}
									>
										<CompanyAvatar
											src={co.logoUrl}
											name={co.name}
											domain={co.domain}
											size={64}
										/>
									</Link>
									<Box
										display="flex"
										flexDirection="column"
										justifyContent="flex-start"
										minWidth={0} // this allows clamp tooltip to work
									>
										<Link
											sx={{
												cursor: "pointer",
												textDecoration: "none",
												color: "inherit",
												"&:hover": {
													textDecoration: "underline",
												},
											}}
											href={`/org/${co.valorId}`}
										>
											<ClampTooltip title={co.name}>
												<Typography style={{ fontWeight: 700 }}>
													{co.name}
												</Typography>
											</ClampTooltip>
										</Link>
										<NoStyleLink
											href={`https://www.${co.domain}`}
											target="_blank"
										>
											<ClampTooltip title={co.domain}>
												<Typography variant="subtitle2">{co.domain}</Typography>
											</ClampTooltip>
										</NoStyleLink>
										<Tooltip
											title={`${co.city ?? ""} ${
												(co?.country &&
													co?.country !== "Other" &&
													lookup.byIso(co.country).country) ??
												""
											}`}
											disableHoverListener={!co.city}
											disableFocusListener={!co.city}
											disableTouchListener={!co.city}
										>
											<Typography variant="body2">
												{co?.country &&
													co?.country !== "Other" &&
													lookup.byIso(co.country).country}
											</Typography>
										</Tooltip>
										<Popover
											open={Boolean(popOverAnchor)}
											anchorEl={popOverAnchor}
											onClose={() => setPopOverAnchor(null)}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "center",
											}}
											transformOrigin={{
												vertical: "top",
												horizontal: "center",
											}}
											disableScrollLock
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													width: "420px",
													padding: 2,
													backgroundColor: (theme) =>
														theme.palette.background.paper,
													borderRadius: (theme) => theme.shape.borderRadius,
													outline: "none",
													boxShadow: (theme) => theme.shadows[5],
												}}
											>
												{Object.keys(topCompaniesExec).map((valorId) => (
													<Box
														key={valorId}
														style={{
															display: "flex",
															flexDirection: "column",
															gap: "8px",
														}}
													>
														<NoStyleLink
															target="_blank"
															href={`/org/${valorId}`}
														>
															<Typography style={{ fontWeight: 700 }}>
																{valorIdToName[valorId]}
															</Typography>
														</NoStyleLink>
														<ol>
															{topCompaniesExec[valorId].arr.map((exec) => (
																<li key={exec}>{exec}</li>
															))}
														</ol>
													</Box>
												))}
											</Box>
										</Popover>
									</Box>
								</Box>
							</Grid>
							{!isSmUp ? (
								<>
									<Grid item xs={6} sm={4}>
										<Box display="flex" justifyContent="flex-end" flex={1}>
											{/* <RelationshipSection
                        setAffModalCo={setAffModalCo}
                        company={co}
                      /> */}
										</Box>
									</Grid>
									<Grid item xs={12} sm={4}>
										{renderSignals(co)}
										{/* {renderSectors(co)} */}
									</Grid>
								</>
							) : (
								<>
									<Grid item xs={12} sm={4}>
										{renderSignals(co)}
										{/* {renderSectors(co)} */}
									</Grid>
									<Grid item xs={6} sm={4}>
										<Box display="flex" justifyContent="flex-end" flex={1}>
											{/* <RelationshipSection
                        setAffModalCo={setAffModalCo}
                        company={co}
                      /> */}
										</Box>
									</Grid>
								</>
							)}
						</Grid>
						{!isSmUp && (
							<Box
								display="flex"
								flexDirection="column"
								flex="1"
								justifyContent="space-between"
							>
								<Clamps maxLines={2} lineHeight={1.43}>
									<Typography variant="body2">{co.description}</Typography>
								</Clamps>
								<Box>
									<Tooltip variant="subtitle2" title={co.description}>
										<Typography
											sx={(t) => ({
												cursor: "pointer",
												fontStyle: "italic",
												border: `1px solid ${t.palette.divider}`,
												borderRadius: t.shape.borderRadius,
												width: "fit-content",
												padding: t.spacing(0, 0.5),
											})}
										>
											Read More
										</Typography>
									</Tooltip>
									{renderSectors(co)}
								</Box>

								<Button
									onClick={() => setShowDetails(co)}
									fullWidth
									variant="outlined"
									color="primary"
								>
									View Details
								</Button>
							</Box>
						)}

						{isSmUp && (
							<Grid container spacing={2} item xs={12} style={{ flex: 1 }}>
								<Grid item xs={12} sm={8}>
									<Box
										display="flex"
										flexDirection="column"
										flex={1}
										justifyContent="space-between"
									>
										<Clamps maxLines={2} lineHeight={1.43}>
											<Typography variant="body2">{co.description}</Typography>
										</Clamps>
										<Tooltip variant="subtitle2" title={co.description}>
											<Typography
												sx={(t) => ({
													cursor: "pointer",
													fontStyle: "italic",
													border: `1px solid ${t.palette.divider}`,
													borderRadius: t.shape.borderRadius,
													width: "fit-content",
													padding: t.spacing(0, 0.5),
												})}
											>
												Read More
											</Typography>
										</Tooltip>
										{renderSectors(co)}
										{/* {renderClassification(co)} */}
									</Box>
								</Grid>
								<Grid item xs={12} sm={4}>
									{renderDeal(relativeDealSearch, co)}
									<Box
										style={{ cursor: "pointer" }}
										onClick={(e) => setPopOverAnchor(e.currentTarget)}
									>
										{execsThatWorkedAtTopCompanies
											? `${execsThatWorkedAtTopCompanies} top execs`
											: ""}
									</Box>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Card>
			</Box>
		);
	}

	return (
		<div
			style={{
				height: `${totalSize}px`,
				width: "100%",
				position: "relative",
			}}
		>
			{(!items || items.length === 0) && (
				<Box>
					<Typography>No Results</Typography>
				</Box>
			)}

			{items?.map((item) => {
				const co = pages[item.index];

				return (
					<div
						key={co.valorId}
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							height: `${item.size}px`,
							transform: `translateY(${item.start}px)`,
						}}
					>
						<CoCard co={co} elHeight={item.size} />
					</div>
				);
			})}

			<Dialog open={showDetails !== null} onClose={() => setShowDetails(null)}>
				<DialogTitle>
					<Box
						display="flex"
						flexDirection="row"
						gridGap={8}
						alignItems="center"
					>
						<Link
							sx={{
								cursor: "pointer",
								textDecoration: "none",
								color: "inherit",
								"&:hover": {
									textDecoration: "underline",
								},
							}}
							href={`/org/${showDetails?.valorId}`}
						>
							<CompanyAvatar
								src={showDetails?.logoUrl}
								name={showDetails?.name}
								domain={showDetails?.domain}
								size={64}
							/>
						</Link>
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="space-between"
						>
							ClampTooltip
							<Typography style={{ fontWeight: "bold" }}>
								{showDetails?.name}
							</Typography>
							<Link
								sx={{
									cursor: "pointer",
									textDecoration: "none",
									color: "inherit",
									"&:hover": {
										textDecoration: "underline",
									},
								}}
								href={`https://www.${showDetails?.domain}`}
							>
								<Typography variant="subtitle2">
									{showDetails?.domain}
								</Typography>
							</Link>
							<Tooltip
								title={`${showDetails?.city ?? ""} ${
									(showDetails?.country &&
										showDetails?.country !== "Other" &&
										lookup.byIso(showDetails?.country).country) ??
									""
								}`}
								disableHoverListener={!showDetails?.city}
								disableFocusListener={!showDetails?.city}
								disableTouchListener={!showDetails?.city}
							>
								<Typography variant="body2">
									{showDetails?.country &&
										showDetails?.country !== "Other" &&
										lookup.byIso(showDetails?.country).country}
								</Typography>
							</Tooltip>
						</Box>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Box display="flex" flexDirection="column" gridGap={8}>
						{showDetails && renderSignals(showDetails)}
						<Typography variant="body2">{showDetails?.description}</Typography>
						{showDetails && renderClassification(showDetails)}
						{showDetails && renderDeal(relativeDealSearch, showDetails)}
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}
