import InfoIcon from "@mui/icons-material/Info";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import type React from "react";

const useStyles = makeStyles(() => {
	const theme = useTheme();
	return createStyles({
		documentationMargin: {
			marginRight: theme.spacing(1),
		},
		iconButton: {
			color: theme.palette.text.secondary,
			"&:hover": {
				color: theme.palette.text.disabled,
			},
		},
	});
});

const HtmlTooltip = withStyles(() => {
	const theme = useTheme();
	return {
		tooltip: {
			backgroundColor: "#f5f5f9",
			color: "rgba(0, 0, 0, 0.87)",
			maxWidth: 500,
			fontSize: theme.typography.pxToRem(12),
			border: "1px solid #dadde9",
		},
	};
})(Tooltip);

interface Props {
	component: React.ReactNode;
	title: React.ReactNode;
	style?: object;
}

export default function DropdownInfo({ title, component, style }: Props) {
	const classes = useStyles();

	return (
		<label style={style} className={classes.documentationMargin}>
			{component}
			<HtmlTooltip title={title}>
				<IconButton
					aria-label="upload picture"
					size="small"
					style={{ width: "10", height: "10", padding: "0" }}
				>
					<InfoIcon
						className={classes.iconButton}
						style={{ width: "12px", height: "12px" }}
					/>
				</IconButton>
			</HtmlTooltip>
		</label>
	);
}
