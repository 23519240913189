import type { Organization } from "@/api/Organization";
import {
	getDocNotesMap,
	getSharepointFiles,
	updateDocNoteType,
} from "@/api/SharepointFiles";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import ErrorMessage from "@/ui/atoms/ErrorMessage";
import Section from "@/ui/atoms/Section";
import SingleSelect from "@/ui/atoms/SingleSelect";
import SelectColumnFilter from "@/ui/atoms/TableFilters/SelectColumnFilter";
import CustomTable from "@/ui/molecules/CustomTable";
import { buildSharepointUrl } from "@/utils/general";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import GetAppIcon from "@mui/icons-material/GetApp";
import LockIcon from "@mui/icons-material/Lock";
import {
	Box,
	Button,
	ButtonBase,
	ButtonGroup,
	Card,
	IconButton,
	Link,
	Skeleton,
	Tooltip,
	useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import startCase from "lodash/startCase";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import useTripwire from "../useTripwire";

interface DocSearchProps {
	company: Organization;
}

export function DocSearchPage({ company }: DocSearchProps) {
	const [noteType, setNoteType] = React.useState("All");
	const [filesData, setFilesData] = React.useState([]);
	useTripwire(company.valorId, "documents");

	const { isLoading, error } = useQuery(
		["DocSearch", company.valorId],
		async () => getSharepointFiles(company.valorId),
		{
			onSuccess: setFilesData,
		},
		{
			retry: 1,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchInterval: false,
		},
	);

	const { data: docNotesMap = [] } = useQuery(["DocNotesMap"], getDocNotesMap);

	const docs = React.useMemo(() => Object.keys(docNotesMap), [docNotesMap]);
	const noteTypes = React.useMemo(
		() =>
			["All", ...new Set(Object.values(docNotesMap))]
				.map((note) => startCase(note))
				.sort(),
		[docNotesMap],
	);

	const [skipPageReset, setSkipPageReset] = useState(true);

	const updateDocNote = (fileId, doc) => {
		// We also turn on the flag to not reset the page
		setSkipPageReset(true);

		setFilesData((old) => {
			updateDocNoteType(fileId, doc, docNotesMap[doc]);
			return old.map((row) => {
				if (row.id === fileId) {
					return {
						...row,
						docType: doc,
						noteType: docNotesMap[doc],
						isEditing: false,
					};
				}
				return row;
			});
		});
	};

	const handleClickEditRow = (fileId) => {
		setFilesData((prev) =>
			prev.map((r) => ({
				...r,
				isEditing: r.id === fileId ? !r.isEditing : false,
			})),
		);
	};

	const filesColumns = React.useMemo(
		() => [
			{
				Header: "File",
				accessor: "filename",
				Cell: ({ cell: { value }, row }) => (
					<div style={{ maxWidth: "460px" }}>
						{" "}
						<Link
							target="_blank"
							href={buildSharepointUrl(
								row.original.product,
								row.original.path,
								value,
							)}
							rel="noreferrer"
						>
							{value}
						</Link>
					</div>
				),
			},
			{
				Header: "Doc Type",
				accessor: "docType",
				Filter: SelectColumnFilter,
				filter: "equals",
				Cell: ({
					value: initialValue,
					row: {
						original: { isEditing, id },
					},
				}) => {
					// We need to keep and update the state of the cell normally
					const [value, setValue] = useState(initialValue);

					const onChange = (newValue) => {
						setValue(newValue);
						updateDocNote(id, newValue);
					};

					// If the initialValue is changed external, sync it up with our state
					useEffect(() => {
						setValue(initialValue);
					}, [initialValue]);

					return isEditing ? (
						<SingleSelect
							id={`column-select-${id}`}
							value={value || ""}
							variant="standard"
							onChange={onChange}
							options={docs}
						/>
					) : (
						value
					);
				},
			},
			{
				accessor: "[editButton]",
				disableFilters: true,
				Cell: ({ row }) => (
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={() => handleClickEditRow(row.original.id)}
					>
						{row.original.isEditing ? (
							<CloseIcon fontSize="small" />
						) : (
							<EditIcon fontSize="small" />
						)}
					</IconButton>
				),
			},
			{
				Header: "Category",
				disableFilters: true,
				accessor: (row) => startCase(row.noteType),
			},
			{
				Header: "Directory",
				Filter: ({ column }) => (
					<SelectColumnFilter column={column} width={50} />
				),
				filter: "equals",
				Cell: ({ cell: { value }, row }) => (
					<Tooltip title={row.original.path} placement="top">
						<ButtonBase>{value}</ButtonBase>
					</Tooltip>
				),
				accessor: "product",
			},
			{
				Header: "Last Modified",
				accessor: (row) =>
					`${dayjs(row.lastModifiedDatetime).format("MMM DD, YYYY")}${
						row.lastModifiedByFirstName
							? ` by ${row.lastModifiedByFirstName} ${row.lastModifiedByLastName}`
							: ""
					}`,
				disableFilters: true,
			},
			{
				Header: " ",
				disableFilters: true,
				accessor: "path",
				Cell: ({ cell: { value }, row }) => (
					<div style={{ alignContent: "center" }}>
						<GetAppIcon
							style={{ cursor: "pointer" }}
							onClick={() =>
								window.open(
									buildSharepointUrl(
										row.original.product,
										value,
										row.original.filename,
									),
								)
							}
						/>
					</div>
				),
			},
		],
		[docs, handleClickEditRow],
	);

	const filteredFilesData = React.useMemo(() => {
		if (noteType === "All") {
			return filesData;
		}
		return filesData.filter((file) => startCase(file.noteType) === noteType);
	}, [filesData, noteType]);

	const smDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	if (error?.response?.status === 403) {
		return (
			<ErrorMessage
				Icon={<LockIcon />}
				title="Unauthorized"
				message={
					<>
						You don’t have access to Doc Search for this company. If you think
						this is an error, please contact{" "}
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<Link
							onClick={(e) => {
								window.location.href =
									"mailto:labs@valorep.com?subject=Doc Search Access";
								e.preventDefault();
							}}
						>
							labs@valorep.com
						</Link>
					</>
				}
			/>
		);
	}

	return (
		<CenteredProgress isLoading={isLoading} displayChildren>
			<Card elevation={0} sx={{ padding: 1 }}>
				<Section mt={2}>
					<Section.Title> Sharepoint Folders </Section.Title>
					<Section.Content>
						<ButtonGroup variant="outlined" aria-label="outlined button group">
							{company.growthPortfolioFolderUrl ? (
								<Button
									onClick={() => window.open(company.growthPortfolioFolderUrl)}
								>
									Growth Portfolio
								</Button>
							) : null}
							{company.growthFinanceFolderUrl ? (
								<Button
									onClick={() => window.open(company.growthFinanceFolderUrl)}
								>
									Growth Finance
								</Button>
							) : null}
							{company.vsvPortfolioFolderUrl ? (
								<Button
									onClick={() => window.open(company.vsvPortfolioFolderUrl)}
								>
									VSV Portfolio
								</Button>
							) : null}
							{company.vsvFinanceFolderUrl ? (
								<Button
									onClick={() => window.open(company.vsvFinanceFolderUrl)}
								>
									VSV Finance
								</Button>
							) : null}
						</ButtonGroup>
					</Section.Content>
				</Section>
				<Box
					mt={2}
					display="flex"
					flexDirection="column"
					data-cy="sharepoint__container"
				>
					{isLoading ? (
						<>
							<Skeleton height="72px" />
							<Skeleton height="500px" />
						</>
					) : (
						<>
							<Section>
								<Section.Title> File Types </Section.Title>
								<Section.Content>
									{filesData.length > 0 && (
										<Box mb={2}>
											<ButtonTabs
												width={smDown ? "100%" : "60%"}
												options={noteTypes}
												onClick={(option) => setNoteType(option)}
												activeKey={noteType}
											/>
										</Box>
									)}
								</Section.Content>
							</Section>
							<CustomTable
								title="Documents"
								data={filteredFilesData}
								skipPageReset={skipPageReset}
								columns={filesColumns}
								autoResetFilters={false}
							/>
						</>
					)}
				</Box>
			</Card>
		</CenteredProgress>
	);
}

export default DocSearchPage;
