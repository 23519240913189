import { createTask, getActiveTasks, getOrganizations } from "@/api/Metrics";
import {
	canFetchAllTasks,
	canSeeMetricTasks,
	canSeeMetricsActivity,
	canSeeMetricsInternal,
} from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import Page from "@/ui/molecules/Page";
import DownloadIcon from "@mui/icons-material/Download";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import RuleFolderIcon from "@mui/icons-material/RuleFolder";
import { LoadingButton } from "@mui/lab";
import {
	Alert,
	Box,
	Button,
	Divider,
	Paper,
	Skeleton,
	Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import stringSimilarity from "string-similarity";

import OrganizationFilter from "./OrganizationFilter";
import OrganizationTaskList from "./OrganizationTaskList";

// add started by data, in progress by other user state
// link up to api
export default function TaskList() {
	const { user } = useAuth();
	const [showNoTasks, setShowNoTasks] = useState(false);

	const [showFetchTasksError, setShowFetchTasksError] = useState(false);
	const [showFetchTasksResult, setShowFetchTasksResult] = useState(false);
	const [fetchAllTasksMessage, setFetchAllTasksMessage] = useState(null);

	const [currentFetchedTask, setCurrentFetchedTask] = useState(null);
	const [fetchTasksDisabled, setFetchTasksDisabled] = useState(false);
	const [filter, setFilter] = useState("");

	const {
		data: activeOrganizations = [],
		isLoading: activeOrganizationsLoading,
		error: activeOrganizationsError,
	} = useQuery(["metric-tasks", "activeOrganizations"], () =>
		getOrganizations(),
	);

	const {
		data: activeTasks = [],
		isLoading: activeTasksLoading,
		error: activeTasksError,
		refetch: refetchActiveTasks,
	} = useQuery(["metric-tasks", "activeTasks"], () => getActiveTasks());

	const pageTitle = () => (
		<Box display="flex" flexDirection="row" justifyContent="space-between">
			<Page.Title>Financial Metric Tasks</Page.Title>
			<Box display="flex" flexDirection="row" gap={1}>
				{canSeeMetricsInternal(user) && (
					<Button
						component={RouterLink}
						endIcon={<RuleFolderIcon />}
						to="internal-files"
					>
						View Internal Files
					</Button>
				)}
				{canSeeMetricsActivity(user) && (
					<Button
						component={RouterLink}
						endIcon={<DynamicFeedIcon />}
						to="activity-feed"
					>
						View Activity Feed
					</Button>
				)}
			</Box>
		</Box>
	);

	if (!canSeeMetricTasks(user)) {
		return (
			<Box padding={2}>
				<Alert severity="info">You do not have access to this page.</Alert>
			</Box>
		);
	}

	if (activeTasksLoading || activeOrganizationsLoading) {
		return (
			<Page>
				{pageTitle()}
				<Page.Content>
					<Paper sx={{ padding: 2 }}>
						<Box display="flex" flexDirection="column" gap={2}>
							<Skeleton variant="rounded" width="100%" height={30} />
							<Skeleton variant="rounded" width="100%" height={50} />
							<Skeleton variant="rounded" width="100%" height={50} />
							<Skeleton variant="rounded" width="100%" height={50} />
							<Skeleton variant="rounded" width="100%" height={50} />
							<Skeleton variant="rounded" width="100%" height={50} />
						</Box>
					</Paper>
				</Page.Content>
			</Page>
		);
	}

	if (activeTasksError || activeOrganizationsError) {
		return (
			<Page>
				{pageTitle()}
				<Page.Content>
					<Paper sx={{ padding: 2 }}>
						<Box>
							<Alert severity="error">
								There was an error loading the active tasks. Please refresh the
								page and if issues persist contact the labs team.
							</Alert>
						</Box>
					</Paper>
				</Page.Content>
			</Page>
		);
	}

	// combine two data sources into one
	const datasource = activeOrganizations.map((organization) => {
		const relatedTask = activeTasks.find(
			(task) => task.valorId === organization.valorId,
		);
		const progress = relatedTask?.progress;
		const remainingTaskCount =
			progress?.find((x) => x.status === "remaining")?.taskCount || 0;
		const completedTaskCount =
			progress?.find((x) => x.status === "completed")?.taskCount || 0;
		const activeTask = relatedTask?.tasks.length
			? {
					valorId: organization.valorId,
					filename: relatedTask.filename,
					createdDatetime: relatedTask.createdDatetime,
					status: relatedTask.tasks.reduce(
						(acc, curr) => curr.status || acc,
						"READY",
					),
					reportingPeriods: relatedTask.tasks.reduce(
						(acc, curr) => [...new Set([...acc, curr.reportingPeriod])],
						[],
					),
					keyMetrics: relatedTask.tasks.reduce(
						(acc, curr) => [...new Set([...acc, curr.name])],
						[],
					),
					assignedUser: relatedTask.assignedUser,
					tasks: relatedTask.tasks,
					progress: {
						remainingTaskCount,
						completedTaskCount,
					},
				}
			: null;
		return {
			...organization,
			activeTask,
		};
	});

	datasource.sort((a, b) => {
		if (filter && a.name && b.name) {
			const aSimilarity = stringSimilarity.compareTwoStrings(filter, a.name);
			const bSimilarity = stringSimilarity.compareTwoStrings(filter, b.name);
			if (aSimilarity !== bSimilarity) {
				return bSimilarity - aSimilarity;
			}
		}
		if (a.activeTask && !b.activeTask) {
			return -1;
		}
		if (!a.activeTask && b.activeTask) {
			return 1;
		}
		return a.name.localeCompare(b.name);
	});

	const fetchAllTasks = async () => {
		setFetchTasksDisabled(true);
		try {
			const result = await Promise.all(
				datasource.map((org) => createTask(org.valorId)),
			);

			// notify user of result
			const exists = result.filter(
				(x) => x === "task already exists for this valor_id",
			);
			const notFetched = result.filter((x) => x === "no new files to process");
			const fetched = result.filter((x) => x === "success");

			setShowFetchTasksResult(true);
			setFetchAllTasksMessage(
				`${fetched.length} tasks fetched, ${exists.length} already have an active task and ${notFetched.length} organizations had no new files to process.`,
			);
		} catch (e) {
			setShowFetchTasksError(true);
			setFetchAllTasksMessage(
				"There was an error fetching some tasks. Please try again.",
			);
		}

		setFetchTasksDisabled(false);
	};

	return (
		<Page>
			{pageTitle()}
			<Page.Content>
				<Paper sx={{ padding: 2 }}>
					<Box>
						<Box
							display="flex"
							flexDirection="row"
							alignItems="center"
							gap={1}
							width="100%"
						>
							<OrganizationFilter
								value={filter}
								onChange={(value) => setFilter(value)}
								sx={{
									flexGrow: {
										md: 0.4,
										sm: 1,
									},
								}}
							/>
							{canFetchAllTasks(user) && (
								<>
									<Divider flexItem orientation="vertical" />
									<LoadingButton
										variant="contained"
										onClick={fetchAllTasks}
										disabled={fetchTasksDisabled}
										loading={fetchTasksDisabled}
										loadingPosition="start"
										startIcon={<DownloadIcon />}
									>
										<span>Fetch All Tasks</span>
									</LoadingButton>
								</>
							)}
						</Box>

						{datasource.map((org) => (
							<OrganizationTaskList
								key={org.valorId}
								company={org}
								activeTask={org.activeTask}
								currentUser={user}
								onChange={() => {
									refetchActiveTasks();
								}}
								fetchTasks={async () => {
									setFetchTasksDisabled(true);
									const response = await createTask(org.valorId);
									if (response === "no new files to process") {
										setShowNoTasks(true);
										setCurrentFetchedTask(org.name);
									}
									setFetchTasksDisabled(false);
									refetchActiveTasks();
								}}
								fetchTasksDisabled={fetchTasksDisabled}
							/>
						))}

						<Snackbar
							open={showNoTasks}
							autoHideDuration={6000}
							onClose={() => setShowNoTasks(null)}
						>
							<Alert severity="info">
								{`There are currently no tasks for ${currentFetchedTask}.`}
							</Alert>
						</Snackbar>

						<Snackbar
							open={showFetchTasksResult}
							autoHideDuration={6000}
							onClose={() => setShowFetchTasksResult(false)}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
						>
							<Alert
								severity="success"
								onClose={() => setShowFetchTasksResult(false)}
							>
								{fetchAllTasksMessage}
							</Alert>
						</Snackbar>

						<Snackbar
							open={showFetchTasksError}
							autoHideDuration={6000}
							onClose={() => setShowFetchTasksError(false)}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right",
							}}
						>
							<Alert
								severity="error"
								onClose={() => setShowFetchTasksError(false)}
							>
								{fetchAllTasksMessage}
							</Alert>
						</Snackbar>
					</Box>
				</Paper>
			</Page.Content>
		</Page>
	);
}
