import useKeyPress from "@/hooks/useKeyPress";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import LabeledDetail from "@/ui/atoms/LabeledDetail";
import Progress from "@/ui/atoms/Progress";
import ResourceLinks from "@/ui/molecules/ResourceLinks";
import AttachMoney from "@mui/icons-material/AttachMoney";
import Close from "@mui/icons-material/Close";
import EventNote from "@mui/icons-material/EventNote";
import LocationOn from "@mui/icons-material/LocationOn";
import Public from "@mui/icons-material/Public";
import {
	Box,
	ButtonBase,
	Drawer,
	Link,
	Typography,
	useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
/* eslint-disable react/prop-types */
import React from "react";

const FastFact = styled(Box)(({ theme }) => ({
	display: "flex",
	paddingBottom: 1,
	color: theme.palette.text.secondary,
}));

const FactText = styled(Typography)(() => ({
	paddingLeft: 2,
}));

export function MarketMapDrawer(props) {
	const { company, onClose, open, pushHistory, isLoading } = props;
	const theme = useTheme();

	useKeyPress("Escape", () => onClose());

	const fastFacts = () => (
		<>
			<FastFact>
				<Public />
				<Link
					target="_blank"
					sx={{
						paddingLeft: 2,
					}}
					href={`https://www.${company.domain}`}
				>
					{company.domain}
				</Link>
			</FastFact>
			<FastFact>
				<LocationOn />
				<FactText>
					{Boolean(company.city) && company.city}
					{Boolean(company.city) && Boolean(company.state) && ", "}
					{Boolean(company.state) && company.state}
				</FactText>
			</FastFact>
			{company.yearFounded && (
				<FastFact>
					<EventNote />
					<FactText>Founded in {company.yearFounded}</FactText>
				</FastFact>
			)}
			{company.lastRoundType && (
				<FastFact>
					<AttachMoney />
					<FactText>{company.lastRoundType}</FactText>
				</FastFact>
			)}
		</>
	);

	const renderContent = () => (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				padding: theme.spacing(4),
				borderBottom: `1px solid ${theme.palette.divider}`,
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					height: theme.spacing(8),
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						width: "100%",
						height: theme.spacing(8),
						color: theme.palette.text.secondary,
						cursor: "pointer",
					}}
					onClick={() => pushHistory(`/org/${company.valorId}`)}
				>
					<CompanyAvatar
						src={company.logoUrl}
						name={company.name}
						domain={company.domain}
						size="48"
					/>
					<Typography
						sx={{
							margin: theme.spacing(0, 1),
							textDecoration: "underline",
						}}
						variant="h2"
					>
						{company.name}
					</Typography>
				</Box>
				<ButtonBase onClick={onClose}>
					<Close />
				</ButtonBase>
			</Box>
			<Box paddingTop={4}>
				<ResourceLinks
					linkedin={company.linkedinUrl}
					crunchbase={company.cbId}
					pitchbook={company.pbId}
					affinity={company.affId}
					synaptic={company.syId}
				/>
			</Box>
			<Box
				sx={{
					padding: theme.spacing(2, 0),
				}}
			>
				{fastFacts()}
			</Box>
			<Box>
				<LabeledDetail title="DESCRIPTION">
					<Typography variant="body2">{company.description}</Typography>
				</LabeledDetail>
			</Box>
		</Box>
	);
	return (
		<Drawer
			anchor="right"
			open={open}
			variant="persistent"
			sx={{
				flexShrink: 0,
				width: 400,
				[theme.breakpoints.down("xs")]: {
					width: "95vw",
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: 400,
					[theme.breakpoints.down("xs")]: {
						width: "95vw",
					},
					paddingTop: theme.spacing(7),
					backgroundColor: theme.palette.background.paper,
					boxShadow: theme.shadows[1],
				}}
			>
				{isLoading && !company ? (
					<Box
						display="flex"
						sx={{
							width: "100%",
							height: "100%",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Progress />
					</Box>
				) : null}
				{!isLoading && company ? renderContent() : null}
			</Box>
		</Drawer>
	);
}

MarketMapDrawer.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	pushHistory: PropTypes.func.isRequired,
};

export default MarketMapDrawer;
