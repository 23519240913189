import { getHomepageCalendar } from "@/api/Reporting";
import TableView from "@/pages/ProcessManagement/TableView";
import Progress from "@/ui/atoms/Progress";
import React, { useCallback, useMemo } from "react";
import { useQuery } from "react-query";

export function CalendarReport() {
	const {
		data = {
			process: {},
			tasks: [],
			view: {},
		},
		isLoading,
	} = useQuery(["Calendar"], () => getHomepageCalendar(50000));
	const { process, tasks, view } = data;

	const fetchNextPage = useCallback(() => {}, []);
	const onColumnOrderChange = useCallback(() => {}, []);
	const onSortingChange = useCallback(() => {}, []);
	const onColumnFilterChange = useCallback(() => {}, []);
	const onColumnVisibilityChange = useCallback(() => {}, []);

	const columnFilters = useMemo(() => [], []);
	const sorting = useMemo(() => view.sorting || [], [view.sorting]);
	const columnOrder = useMemo(() => view.columnOrder || [], [view.columnOrder]);
	const columnVisibility = useMemo(
		() => view.columnVisibility || {},
		[view.columnVisibility],
	);
	const fields = useMemo(() => {
		const processFields = process?.fields || [];
		const orgFields = process?.organizationFields || [];
		return [...processFields, ...orgFields];
	}, [process]);

	if (isLoading) return <Progress />;
	return (
		<TableView
			isLoading={isLoading}
			fields={fields}
			processId={process.processId}
			tasks={tasks}
			views={process.views}
			isFetching={isLoading}
			fetchNextPage={fetchNextPage}
			sorting={sorting}
			columnFilters={columnFilters}
			columnOrder={columnOrder}
			columnVisibility={columnVisibility}
			onColumnOrderChange={onColumnOrderChange}
			onSortingChange={onSortingChange}
			onColumnFilterChange={onColumnFilterChange}
			onColumnVisibilityChange={onColumnVisibilityChange}
			enableColumnFilter={false}
			enableSorting={false}
			enableViews={false}
			readOnly
		/>
	);
}
export default CalendarReport;
