import { Box, keyframes } from "@mui/material";
import React from "react";
import ChatBubble from "./ChatBubble";

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
`;

function Dot({ delay }: { delay: number }) {
	return (
		<Box
			sx={{
				width: "6px",
				height: "6px",
				borderRadius: "50%",
				backgroundColor: "currentColor",
				animation: `${bounce} 1.4s infinite ease-in-out both`,
				animationDelay: `${delay}s`,
			}}
		/>
	);
}

export default function LoadingMessage() {
	return (
		<ChatBubble
			sx={(theme) => ({
				color: theme.palette.getContrastText(theme.palette.background.blue),
				backgroundColor: theme.palette.background.blue,
				minHeight: "36px",
				width: "fit-content",
			})}
		>
			<Box
				sx={{
					display: "flex",
					gap: 0.5,
					alignItems: "center",
					px: 1,
				}}
			>
				<Dot delay={-0.32} />
				<Dot delay={-0.16} />
				<Dot delay={0} />
			</Box>
		</ChatBubble>
	);
}
