import { createOrg } from "@/api/Combined";
import {
	getOrgFields,
	publishOrganization,
	updateOrgFieldValue,
} from "@/api/Organization";
import { saytForOrg } from "@/api/Search";
import Input from "@/components/FormInputs/Input";
import CompanyCell from "@/ui/atoms/CompanyCell";
import Progress from "@/ui/atoms/Progress";
import Page from "@/ui/molecules/Page";
import {
	Alert,
	Box,
	Button,
	Grid,
	List,
	ListItem,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

export default function CreateForm() {
	const navigate = useNavigate();
	const filteredFields = ["name", "domain", "description", "logo_url"];
	const required = ["name", "domain"];

	const { data: fields, isLoading } = useQuery(
		["organization-fields"],
		getOrgFields,
	);

	const sortedFields = React.useMemo(() => {
		if (!fields) return [];
		return fields
			.sort(
				(a, b) =>
					filteredFields.indexOf(a.field?.key) -
					filteredFields.indexOf(b.field?.key),
			)
			.filter((f) => filteredFields.includes(f.key));
	}, [fields]);

	const [mask, setMasks] = useState({});
	const [saving, setSaving] = useState(false);
	const [formError, setFormError] = useState(null);
	const [searchResults, setSearchResults] = useState([]);

	const performSearch = async (field, value) => {
		if (value && (field === "name" || field === "domain")) {
			try {
				const results = await saytForOrg(value, [field]);
				return results;
			} catch (error) {
				console.error("Error fetching search results:", error);
				return [];
			}
		}
		return [];
	};

	function handleOnChange(field, newValue) {
		setMasks((prev) => ({
			...prev,
			[field.key]: newValue,
		}));
	}

	async function handleOnBlur(field) {
		if (field.key === "name" || field.key === "domain") {
			const nameResults = await performSearch("name", mask.name);
			const domainResults = await performSearch("domain", mask.domain);

			setSearchResults({
				name: nameResults.slice(0, 5),
				domain: domainResults.slice(0, 5),
			});
		}
	}
	function transformValue(field, value) {
		const typeMap = {
			company: "company",
			person: "person",
			person_multi: "person",
			user: "user",
			user_multi: "user",
			select: "choiceId",
			select_multi: "choiceId",
		};
		if (field.type.includes("multi") && field.type !== "text_multi") {
			return value.map((v) => ({
				[typeMap[field.type]]: v,
			}));
		}
		return [
			{
				[typeMap[field.type] || "value"]: value,
			},
		];
	}

	async function submitChange() {
		setSaving(true);

		let valorId = null;
		try {
			valorId = await createOrg();
		} catch (e) {
			setSaving(false);
			setFormError("Failed to create organization.");
			return;
		}

		const keys = Object.keys(mask);
		const updatePromises = [];
		for (let i = 0; i < keys.length; i += 1) {
			const key = keys[i];
			const maskValue = mask[key];

			const field = fields.find((v) => v.key === key);
			updatePromises.push(
				updateOrgFieldValue(valorId, field.id, {
					values: transformValue(field, maskValue),
				}),
			);
		}

		try {
			await Promise.all(updatePromises);
		} catch (e) {
			setSaving(false);
			setFormError("Failed to create organization fields.");
			return;
		}

		try {
			await publishOrganization(valorId);
		} catch (e) {
			setSaving(false);
			setFormError("Failed to publish organization.");
			return;
		}

		setSaving(false);
		navigate(`/org/${valorId}`);
	}

	if (isLoading) {
		return <Progress />;
	}

	return (
		<Page data-cy="page__create_organization">
			<Page.Title>Create New Organization</Page.Title>
			<Page.Content>
				<Box mb={1}>
					<Alert severity="info">
						For Domain do not include www. or https://. For example, if the
						website is www.example.com, enter example.com.
					</Alert>
				</Box>
				{formError && (
					<Box mb={2}>
						<Alert severity="error">{formError}</Alert>
					</Box>
				)}

				<form
					onSubmit={(e) => {
						e.preventDefault();
						submitChange();
					}}
				>
					<Box display="flex" flexDirection="column" gap={2}>
						{sortedFields?.map((field) => (
							<Box key={field.key}>
								<Input
									type={field.type}
									name={field.label}
									value={mask[field.key] || null}
									onChange={(newValue) => handleOnChange(field, newValue)}
									onBlur={() => handleOnBlur(field)}
									required={required.includes(field.key)}
									choices={field.choices}
									disabled={saving}
								/>
							</Box>
						))}

						<Box display="flex" justifyContent="flex-start" gap={1}>
							<Button
								variant="outlined"
								onClick={(e) => {
									if (!saving) {
										e.preventDefault();
										setMasks({});
										setSearchResults([]);
									}
								}}
								disabled={saving}
							>
								Reset Form
							</Button>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								disabled={saving}
							>
								Save
							</Button>
						</Box>
					</Box>
				</form>
				{(searchResults.name?.length > 0 ||
					searchResults.domain?.length > 0) && (
					<Box mt={2}>
						<Alert severity="warning">
							<Grid container spacing={2}>
								{!!searchResults.name?.length && (
									<Grid item xs={12} sm={6}>
										<Typography variant="subtitle1">
											Name Search Results:
										</Typography>
										<Typography variant="caption">
											Name:
											{mask.name}
										</Typography>
										<List dense>
											{searchResults.name?.map((result) => (
												<ListItem key={result.valorId}>
													<CompanyCell
														companyName={result.name}
														valorId={result.valorId}
														domain={result.domain}
														logoUrl={result.logoUrl}
													/>
												</ListItem>
											))}
										</List>
									</Grid>
								)}
								{!!searchResults.domain?.length && (
									<Grid item xs={12} sm={6}>
										<Typography variant="subtitle1">
											Domain Search Results:
										</Typography>
										<Typography variant="caption">
											Domain: {mask.domain}
										</Typography>
										<List dense>
											{searchResults.domain?.map((result) => (
												<ListItem key={result.valorId}>
													<CompanyCell
														companyName={result.name}
														valorId={result.valorId}
														domain={result.domain}
														logoUrl={result.logoUrl}
													/>
												</ListItem>
											))}
										</List>
									</Grid>
								)}
							</Grid>
						</Alert>
					</Box>
				)}
			</Page.Content>
		</Page>
	);
}
