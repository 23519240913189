import { Table, TableHead, useMediaQuery, useTheme } from "@mui/material";
import type React from "react";

import { styled } from "@mui/system";

export const StickyTableHead = styled(TableHead)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	borderBottom: `1px solid ${theme.palette.divider}`,
}));

export function StickyTable({
	centerTotalSize,
	children,
}: {
	centerTotalSize?: number;
	children: React.ReactNode;
}) {
	const theme = useTheme();
	const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<Table
			sx={() => ({
				width: centerTotalSize || "auto",
				whiteSpace: "nowrap",
				borderCollapse: "separate",
				"& > thead": {
					position: "sticky",
					top: 0,
					zIndex: theme.layers.stickyTableHeaderCell,
				},
				"& > tbody > tr > td": {
					padding: isMdDown ? theme.spacing(0.5) : theme.spacing(1),
				},
				"& > thead > tr > th": {
					position: "sticky",
					top: 0,
					zIndex: theme.layers.stickyTableRow,
					background: theme.palette.background.paper,
					// boxShadow: "0px 4px 2px 0px #3A3A4433, 0px 4px 8px -4px #5A5B6A33",
					borderTop: `2px solid ${theme.palette.divider}`,
					borderBottom: "2px solid #3A3A4455",
				},
				"& > thead > tr > th:first-of-type": {
					position: "sticky",
					left: 0,
					// boxShadow: "0px 4px 2px 0px #3A3A4433, 0px 4px 8px 0px #5A5B6A33",
					zIndex: theme.layers.stickyTableHeaderCell,
					borderRight: "2px solid #3A3A4455 !important",
				},
				"& > tbody > tr > td:first-of-type": {
					position: "sticky",
					left: 0,
					// boxShadow: "0px 4px 2px 0px #3A3A4433, 0px 4px 8px 0px #5A5B6A33",
					zIndex: theme.layers.stickyTableCell,
					borderRight: "2px solid #3A3A4455 !important",
				},
				display: "grid",
				position: "relative",
			})}
		>
			{children}
		</Table>
	);
}
