import {
	type GetOrganizationKeyMetricsResult,
	type MetricsLabel,
	postOrganizationKeyMetric,
} from "@/api/Metrics";
import React from "react";
import { useQueryClient } from "react-query";
import MetricsAutocomplete from "../MetricsAutocomplete";

type KeyMetricsProps = {
	valorId: string;
	openNewLabelDialog: (labelText: any, newIsCalculated: any) => void;
	labelSet: MetricsLabel[];
	chosenLabels: GetOrganizationKeyMetricsResult;
	refetchMetrics: () => void;
};

export function KeyMetrics({
	valorId,
	openNewLabelDialog,
	labelSet,
	chosenLabels,
	refetchMetrics,
}: KeyMetricsProps) {
	const queryClient = useQueryClient();

	const addKeyMetric = async (newValues) => {
		await Promise.all(
			newValues.map(async (newValue) => {
				if (newValue.id) {
					await postOrganizationKeyMetric(valorId, newValue.id);
				}
			}),
		);
		await queryClient.refetchQueries(["keyMetrics", valorId]);
		await refetchMetrics();
	};

	return (
		<MetricsAutocomplete
			options={labelSet}
			value={chosenLabels}
			multiple
			onChange={(event, values) => {
				const hasNewStringEnteredLabel = values.some(
					(value) => typeof value === "string" || value.inputValue,
				);
				const newValues = values.filter(
					(newSelection) =>
						!chosenLabels.some(
							(alreadySelected) => alreadySelected.id === newSelection.id,
						),
				);

				if (hasNewStringEnteredLabel) {
					const newLabelValue = values.find(
						(value) => typeof value === "string" || value.inputValue,
					);
					openNewLabelDialog(newLabelValue.inputValue || newLabelValue, false);
				} else {
					addKeyMetric(newValues);
				}
			}}
		/>
	);
}
KeyMetrics.displayName = "KeyMetrics";

export default KeyMetrics;
