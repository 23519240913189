import { Box, Collapse, Typography } from "@mui/material";
import React, { memo } from "react";
import { getFieldValue } from "../ProcessView/ViewUtils";
import { MaskedField } from "./TaskFormField";

type LifecycleStageFieldProps = {
	stageId: string;
	stageName: string;
	isFieldDependent: boolean;
	fields: any;
	[key: string]: any;
};

const LifecycleStageField = memo(
	({
		stageId,
		stageName,
		isFieldDependent,
		fields,
		currentStageId,
		validating,
		unfilledRequiredFields,
		getStageIdentifier,
		computedValues,
		handleBlur,
		requiredFields,
		primaryCompany,
		primaryCompanyFieldsDisabled,
		primaryValorId,
		openStage,
		setOpenStage,
	}: LifecycleStageFieldProps) => {
		return (
			<Box key={`stage-${stageId || stageName}`} my={1}>
				{stageId && stageName.length > 0 && (
					<Box
						sx={{
							cursor: "pointer",
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: 1,
							borderWidth: 1,
							borderStyle: "solid",
							borderColor: (theme) =>
								validating &&
								unfilledRequiredFields.some((f) =>
									f.stages.some(
										(stage) =>
											getStageIdentifier(
												stageId,
												stageName,
												isFieldDependent,
											) ===
											getStageIdentifier(
												stage?.stageId,
												stage?.stageName,
												isFieldDependent,
											),
									),
								)
									? theme.palette.error.main
									: theme.palette.divider,
							background: (theme) =>
								getStageIdentifier(stageId, stageName, isFieldDependent) ===
									currentStageId ||
								(getStageIdentifier(stageId, stageName, isFieldDependent) ===
									openStage &&
									isFieldDependent)
									? theme.palette.blue.main
									: theme.palette.disabled,
							color: (theme) =>
								getStageIdentifier(stageId, stageName, isFieldDependent) ===
									currentStageId ||
								(getStageIdentifier(stageId, stageName, isFieldDependent) ===
									openStage &&
									isFieldDependent)
									? theme.palette.getContrastText(theme.palette.blue.main)
									: validating &&
											unfilledRequiredFields.some((f) =>
												f.stages.some(
													(stage) =>
														getStageIdentifier(
															stageId,
															stageName,
															isFieldDependent,
														) ===
														getStageIdentifier(
															stage?.stageId,
															stage?.stageName,
															isFieldDependent,
														),
												),
											)
										? theme.palette.error.main
										: theme.palette.text.primary,
							fontWeight: (theme) =>
								getStageIdentifier(stageId, stageName, isFieldDependent) ===
									currentStageId ||
								(getStageIdentifier(stageId, stageName, isFieldDependent) ===
									openStage &&
									isFieldDependent)
									? theme.typography.fontWeightBold
									: theme.typography.fontWeightRegular,
							px: 1,
						}}
						onClick={() => {
							if (!isFieldDependent) {
								setOpenStage((prev) => (prev === stageId ? null : stageId));
							} else {
								setOpenStage((prev) => (prev === stageName ? null : stageName));
							}
						}}
						data-cy={`stage-${stageId}`}
					>
						<Typography variant="overline">{stageName}</Typography>
					</Box>
				)}
				<Collapse
					in={
						getStageIdentifier(stageId, stageName, isFieldDependent) ===
							openStage ||
						getStageIdentifier(stageId, stageName, isFieldDependent) === null ||
						getStageIdentifier(stageId, stageName, isFieldDependent) ===
							"all" ||
						stageName.length === 0
					}
					key={stageId || stageName}
				>
					<Box display="flex" flexDirection="column" gap={1} my={1}>
						{fields
							.filter((field) => field.visible)
							.map((field) => {
								if (field) {
									return (
										<MaskedField
											key={field.id}
											field={field}
											value={getFieldValue(
												computedValues?.[field.id],
												field.type,
											)}
											onBlur={handleBlur}
											choices={field.choices}
											isOrganizationField={field.isOrganizationField}
											organizationName={primaryCompany?.name}
											required={requiredFields.some((f) => f.id === field.id)}
											disabled={
												(field.isOrganizationField &&
													(primaryCompanyFieldsDisabled ||
														// has primary company
														!primaryValorId)) ||
												field.readOnly
											}
											error={
												validating &&
												unfilledRequiredFields.some((f) => f.id === field.id)
											}
										/>
									);
								}
								return null;
							})}
					</Box>
				</Collapse>
			</Box>
		);
	},
);
LifecycleStageField.displayName = "LifecycleStageField";
export default LifecycleStageField;
