import UserAvatar from "@/ui/atoms/UserAvatar";
import { Grid, ListItemText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import React from "react";
import { Field } from "react-final-form";

interface User {
	id: string;
	firstName: string;
	lastName: string;
	profilePicture: string;
}

interface Props {
	questionId: string;
	users: User[];
	initialValue: string[];
}

export function UsersMultiSelect({
	questionId,
	users,
	initialValue = [],
}: Props) {
	const objectUsers = users.reduce(
		(acc, user) => {
			acc[user.id] = user;
			return acc;
		},
		{} as Record<string, User>,
	);
	return (
		<Grid item xs={12} sx={{ paddingLeft: "0 !important" }}>
			<Field name={`${questionId}.userIds`} initial={initialValue}>
				{({ input, initial }) => (
					<Autocomplete
						multiple
						id="checkboxes-tags-demo"
						options={users.map((user) => user.id)}
						disableCloseOnSelect
						defaultValue={initial}
						getOptionLabel={(userId: User) =>
							`${objectUsers[userId].firstName} ${objectUsers[userId].lastName}`
						}
						renderOption={(props, userId: string, { selected }) => (
							<li {...props} key={userId}>
								<Checkbox
									icon={<UserAvatar user={objectUsers[userId] as User} />}
									checkedIcon={
										<UserAvatar user={objectUsers[userId] as User} />
									}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								<ListItemText
									primary={`${objectUsers[userId].firstName} ${objectUsers[userId].lastName}`}
								/>
							</li>
						)}
						style={{ width: 500 }}
						renderInput={(params) => (
							<TextField {...params} label="Select users" placeholder="Users" />
						)}
						onChange={(event, value) => {
							input.onChange(value);
						}}
					/>
				)}
			</Field>
		</Grid>
	);
}

export default UsersMultiSelect;
