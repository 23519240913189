import { canSeeDev } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import Page from "@/ui/molecules/Page";
import { Box } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import AdminTab from "./AdminTab";
import MantisTab from "./MantisTab";

export default function Mantis() {
	const { activeTab } = useParams<{ activeTab: string }>();
	const navigate = useNavigate();

	const setActiveTab = (tab: string) => {
		navigate(`/signal-model-pipeline/${tab}`);
	};

	if (!activeTab) {
		// redirect to default tab
		setActiveTab("pipeline");
	}

	const tabs = [
		{ key: "pipeline", label: "Pipeline" },
		{ key: "portfolio", label: "Portfolio" },
		{ key: "passed", label: "Passed Deals" },
	];

	const { user } = useAuth();
	if (canSeeDev(user)) {
		tabs.push({ key: "admin", label: "Admin" });
	}

	return (
		<Page>
			<Helmet>
				<title>Signal Model Pipeline</title>
			</Helmet>
			<Page.Title>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
					gap={1}
				>
					<Box>Signal Model Pipeline</Box>
				</Box>
			</Page.Title>
			<Page.Content>
				<Box display="flex" flexDirection="column" gap={1}>
					<ButtonTabs
						width="320px"
						activeKey={activeTab}
						onClick={(option) => {
							setActiveTab(option.key);
						}}
						options={tabs}
					/>

					<MantisTab tab="pipeline" visible={activeTab === "pipeline"} />

					<MantisTab tab="passed" visible={activeTab === "passed"} />

					<MantisTab tab="portfolio" visible={activeTab === "portfolio"} />

					<AdminTab visible={activeTab === "admin"} />
				</Box>
			</Page.Content>
		</Page>
	);
}
