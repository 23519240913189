import PortfolioMonitoring from "@/pages/MSLSurvey/PortfolioMonitoring";
import Page from "@/ui/molecules/Page";
import React from "react";

function MSLSurveyPage() {
	return (
		<Page>
			<Page.Content>
				<PortfolioMonitoring />
			</Page.Content>
		</Page>
	);
}

export default MSLSurveyPage;
