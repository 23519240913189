import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { Box } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import FundAdmin from "../FundAdministration";
// import ValorFundraising from "../ValorFundraising";

import TableReport from "@/pages/ProcessManagement/TableReport";

export default function InvestorRelations() {
	const location = useLocation();
	const navigate = useNavigate();
	const activeKey = location.pathname.split("/").pop();

	return (
		<Box>
			<ButtonTabs
				width="648px"
				tabLabel="Status"
				options={[
					{
						key: "active",
						label: "Fundraising - Active",
					},
					{
						key: "in-consideration",
						label: "Fundraising - In Consideration",
					},
					{
						key: "upcoming-meetings",
						label: "Upcoming LP/AB Meetings",
					},
					{
						key: "lp-consents",
						label: "LP/AB Consents",
					},
				]}
				activeKey={activeKey}
				onClick={(option) => {
					navigate(`/weekly-ic/weekly-ic/ir/${option.key}`);
				}}
			/>
			{activeKey === "active" && (
				<TableReport
					viewId="ffc011da-e59f-4efd-9804-30de6f2f2fbe"
					processId="funds"
				/>
			)}
			{activeKey === "in-consideration" && (
				<TableReport
					viewId="a6469c37-5b01-4953-a496-cab545b2dddf"
					processId="funds"
				/>
			)}
			{activeKey === "upcoming-meetings" && (
				<TableReport
					viewId="06dcabc7-c045-4f42-a005-c0471092346e"
					processId="lp-ab"
				/>
			)}
			{activeKey === "lp-consents" && (
				<TableReport
					viewId="9fca6427-1ee0-4b54-9cc5-0658c2c788fd"
					processId="lp-ab"
				/>
			)}
		</Box>
	);
}
