import React, { useState } from "react";

import { Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import { useField } from "react-final-form";

const PREFIX = "SurveyDate";
const classes = {
	subSection: `${PREFIX}-subSection`,
	text: `${PREFIX}-text`,
};

const Root = styled("div")(({ theme }) => ({
	width: "100%",
	[`& .${classes.subSection}`]: {
		color: theme.palette.text.secondary,
		textAlign: "left",
		fontWeight: "bold",
	},

	[`& .${classes.text}`]: {
		width: "100%",
		"& > div > textarea": {
			height: theme.spacing(4),
			fontSize: theme.spacing(3),
		},
	},
}));

export default function DateInput({
	questionId,
	initialValue = { date: null },
	initialComment,
	showComment,
	skippable,
}: {
	questionId: string;
	initialValue?: {
		date: string;
	};
	initialComment: string;
	showComment: boolean;
	skippable: boolean;
}) {
	const validate = (value: string) => {
		const { date } = JSON.parse(value);
		if (!date && !skippable) {
			return "Please enter a date";
		}
		return undefined;
	};

	const parse = (value: string) => JSON.stringify(value);

	const { input: dateInput, meta: dateMeta } = useField(
		`${questionId}.answerData`,
		{
			initialValue: JSON.stringify(initialValue),
			validate,
			parse,
		},
	);

	const { input: commentaryInput } = useField(`${questionId}.commentary`, {
		initialValue: initialComment,
	});

	const [date, setDate] = useState(initialValue.date);
	const handleChange = (val: string) => {
		setDate(val);
		dateInput.onChange({
			date: val,
		});
	};

	return (
		<Root>
			<Grid container item xs={12} spacing={1}>
				<Grid item xs={12}>
					<DateInput
						value={date ? dayjs(date).toDate() : null}
						onChange={(value) => {
							handleChange(value ? dayjs(value).format("MM/DD/YYYY") : null);
						}}
						error={!!(dateMeta.submitFailed && !!dateMeta.error)}
						label={dateMeta.submitFailed && dateMeta.error}
					/>
				</Grid>
				{showComment && (
					<Grid item xs={12}>
						<Typography className={classes.subSection} variant="body2">
							COMMENTS
						</Typography>
						<TextField
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...commentaryInput}
							className={classes.text}
							multiline
							variant="outlined"
							onChange={(event) => commentaryInput.onChange(event.target.value)}
						/>
					</Grid>
				)}
			</Grid>
		</Root>
	);
}
