import { getLastInteractions } from "@/api/Users";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import { Box, Card, Skeleton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import InteractionLineItem from "./InteractionLineItem";

export function InteractionsCard(props) {
	const { onClick, onSurveyClick } = props;

	const { data: companies = [], isLoading: isLoadingInteractions } = useQuery(
		["lastInteractions", 5],
		async () => {
			const response = await getLastInteractions({ limit: 5 });
			return response;
		},
	);

	const lineItems = companies?.map(
		({ valorId, name, domain, logoUrl, interactionDate }) => (
			<InteractionLineItem
				key={valorId}
				valorId={valorId}
				name={name}
				domain={domain}
				logoUrl={logoUrl}
				interactionDate={interactionDate}
				onClick={onClick}
				onSurveyClick={onSurveyClick}
			/>
		),
	);

	return (
		<Card style={{ minHeight: "300px", marginTop: "8px" }}>
			<CenteredProgress displayChildren isLoading={isLoadingInteractions}>
				<Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							py: 1,
							px: 2,
							borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						}}
					>
						<Typography
							sx={{
								color: (theme) => theme.palette.text.secondary,
								fontWeight: (theme) => theme.typography.fontWeightSemiBold,
								fontSize: "12px",
							}}
							data-cy="home__recently-contacted"
						>
							PIPELINE & PORTCO LAST CONTACTED
						</Typography>
					</Box>
					{companies.length === 0 && !isLoadingInteractions ? (
						<Box>
							<Typography
								sx={{
									color: (theme) => theme.palette["13"],
									textAlign: "center",
								}}
							>
								No Recently Met Companies Captured.
							</Typography>
						</Box>
					) : isLoadingInteractions ? (
						<Box display="flex" flexDirection="column" sx={{ gap: "1px" }}>
							<Skeleton variant="rectangular" height={64} />
							<Skeleton variant="rectangular" height={64} />
							<Skeleton variant="rectangular" height={64} />
						</Box>
					) : (
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="space-between"
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									"& > div:not(:last-child)": {
										borderBottom: (theme) =>
											`1px solid ${theme.palette.divider}`,
									},
								}}
							>
								{lineItems}
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									padding: (theme) => theme.spacing(1, 2),
									borderTop: (theme) => `1px solid ${theme.palette.divider}`,
									color: (theme) => theme.palette.primary.main,
									fontWeight: (theme) => theme.typography.fontWeightBold,
									fontSize: "16px",
									textDecoration: "none !important",
									"&:hover": {
										backgroundColor: (theme) => theme.palette.action.hover,
									},
								}}
							>
								<Link to="/home/recently-viewed">SEE MORE</Link>
							</Box>
						</Box>
					)}
				</Box>
			</CenteredProgress>
		</Card>
	);
}
InteractionsCard.propTypes = {
	onClick: PropTypes.func.isRequired,
	onSurveyClick: PropTypes.func.isRequired,
};

export default InteractionsCard;
