import TableReport from "@/pages/ProcessManagement/TableReport";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { Box, Card } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const REVOPS_WEEKLY_IC_VIEW_ID = "0a246a5f-92a4-40a4-a163-4f5ccc1766c9";
const ONBOARDING_WEEKLY_IC_VIEW_ID = "a124ff1f-b3fd-4b6e-bbbd-6f88df721641";

export function RevGen() {
	const { subPage2 } = useParams<{ "*"?: string }>();
	const navigate = useNavigate();
	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<ButtonTabs
				activeKey={subPage2}
				tabLabel="RevGen"
				width="480px"
				options={[
					{ key: "portco-update", label: "Portfolio Company Updates" },
					{ key: "onboarding", label: "Onboarding Status" },
				]}
				onClick={(option) => {
					navigate(`../../rev-gen/${option.key}`, { relative: "path" });
				}}
			/>
			<Box display="flex" flexDirection="column" gap={2}>
				<Card elevation={0} sx={{ height: "auto" }}>
					{subPage2 === "portco-update" && (
						<TableReport
							processId="vsv-revops"
							viewId={REVOPS_WEEKLY_IC_VIEW_ID}
						/>
					)}
					{subPage2 === "onboarding" && (
						<TableReport
							processId="rev-gen"
							viewId={ONBOARDING_WEEKLY_IC_VIEW_ID}
						/>
					)}
				</Card>
			</Box>
		</Box>
	);
}
export default RevGen;
