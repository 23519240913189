import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import type { TokenUsageChartProps } from "./utils/ChatState";

export function TokenUsageChart({
	usedTokens,
	maxTokens,
}: TokenUsageChartProps) {
	const theme = useTheme();
	const data = [
		{ name: "Used", value: usedTokens },
		{ name: "Available", value: Math.max(maxTokens - usedTokens, 0) },
	];

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				gap: 1.5,
				p: 1.5,
				bgcolor: "background.paper",
				borderRadius: 1,
				border: `1px solid ${theme.palette.divider}`,
			}}
		>
			<Box sx={{ width: 60, height: 60 }}>
				<ResponsiveContainer>
					<PieChart>
						<Pie
							data={data}
							innerRadius="70%"
							outerRadius="100%"
							dataKey="value"
							startAngle={90}
							endAngle={-270}
						>
							{data.map((_, i) => (
								<Cell
									key={`cell-${i}`}
									fill={
										i === 0
											? theme.palette.primary.main
											: theme.palette.background.default
									}
									strokeWidth={0}
								/>
							))}
						</Pie>
					</PieChart>
				</ResponsiveContainer>
			</Box>
			<Box>
				<Typography variant="body2" color="text.secondary">
					Token Usage: {Math.round((usedTokens / maxTokens) * 100)}%
				</Typography>
				<Typography variant="body2">
					{usedTokens.toLocaleString()} / {maxTokens.toLocaleString()}
				</Typography>
			</Box>
		</Box>
	);
}

export default TokenUsageChart;
