export const svgElToSvgFile = (svgEl, fileName) => {
	svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
	svgEl.setAttribute(
		"font-family",
		"Open Sans, Roboto, Helvetica, Arial, sans-serif",
	);
	svgEl.setAttribute("font-weight", "300");
	svgEl.setAttribute("width", svgEl.width + 32);
	svgEl.setAttribute("height", svgEl.height + 32);

	const svg = svgEl.outerHTML;
	const blob = new Blob([svg], { type: "image/svg+xml" });
	const url = URL.createObjectURL(blob);
	const link = document.createElement("a");
	link.href = url;
	link.download = fileName;
	link.click();
};

export default svgElToSvgFile;
