import {
	addRestrictions,
	getRestrictions,
	getRoles,
	getUserInfo,
	removeRestriction,
	updateUserRole,
} from "@/api/Compliance";
import { saytForOrg } from "@/api/Search";
import DropdownInfo from "@/ui/atoms/DropdownInfo";
import RemovableCompany from "@/ui/atoms/RemovableCompany";
import SingleSelect from "@/ui/atoms/SingleSelect";
import DragAndDropCompanies from "@/ui/molecules/DragAndDropCompanies";
import { Avatar, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useQuery } from "react-query";
import { useQueryParam } from "use-query-params";

const roleDoc = (name) => (
	<>
		<h3>Change Role</h3>
		Select which role you would like to assign to <strong>{name}</strong>
		.
		<br />
		<br />
	</>
);

function Individual() {
	const [id] = useQueryParam("id");
	const [role, setRole] = useState(1);
	const [restrictions, setRestrictions] = useState([]);

	const { data: roles = [] } = useQuery(["Roles"], getRoles);

	const { data: user = {} } = useQuery(["User", id], () => getUserInfo(id), {
		onSuccess: (userResult) => {
			setRole(userResult.roleId);
			getRestrictions(userResult.roleId, "role_id").then((data) => {
				setRestrictions(data.restrictions || []);
			});
		},
	});

	const handleRoleChange = (newRole) => {
		updateUserRole(id, newRole);
		setRole(newRole);
	};

	return (
		<Box>
			<Helmet>
				<title>Compliance - User</title>
			</Helmet>
			<Typography
				sx={{
					marginBottom: 2,
					color: (theme) => theme.palette.text.primary,
					fontWeight: (theme) => theme.typography.fontWeightMedium,
					fontSize: "1.5rem",
				}}
			>
				{}
			</Typography>
			<Box
				boxShadow={1}
				bgcolor="background.paper"
				borderRadius="10px"
				p={1}
				marginBottom={2}
			>
				<Typography
					sx={{
						display: "flex",
						justifyContent: "space-between",
						borderBottom: (theme) => `2px solid ${theme.palette.divider}`,
					}}
					variant="h1"
					align="left"
				>
					{user.name}
				</Typography>
				<div style={{ display: "flex" }}>
					<Avatar
						alt={user.name}
						src={user.largeProfilePicture}
						sx={{
							margin: 0.5,
							width: 160,
							height: 160,
						}}
					/>
					<div>
						<Typography variant="subtitle1" align="left">
							Email: <strong>{user.email}</strong>
						</Typography>
						<Typography variant="subtitle1" align="left">
							Created at:{" "}
							<strong>{dayjs(user.createdAt).format("MMM DD, YYYY")}</strong>
						</Typography>
						<Typography variant="subtitle1" align="left">
							Department: <strong>{user.department}</strong>
						</Typography>
						<DropdownInfo
							component={
								<SingleSelect
									id="role-dropdown"
									title="Role"
									value={role}
									getKey={(option) => option.id}
									getLabel={(option) => option.name}
									onChange={handleRoleChange}
									options={roles}
								/>
							}
							title={roleDoc(user.name)}
						/>
					</div>
				</div>
			</Box>
			<Box
				boxShadow={1}
				bgcolor="background.paper"
				borderRadius="10px"
				p={1}
				marginBottom={2}
			>
				<Typography
					variant="h1"
					align="left"
					sx={{
						marginBottom: 2,
						color: (theme) => theme.palette.text.primary,
						fontWeight: (theme) => theme.typography.fontWeightMedium,
						fontSize: "1.5rem",
					}}
				>
					Role Restrictions ({restrictions.length})
				</Typography>
				<div
					style={{
						overflowX: "auto",
						display: "flex",
						minHeight: "128px",
					}}
				>
					{restrictions.map((company) => (
						<RemovableCompany
							key={`removable-company-${company.valorId}`}
							company={company}
						/>
					))}
				</div>
			</Box>
			<DragAndDropCompanies
				getRestrictions={getRestrictions}
				removeRestriction={removeRestriction}
				addRestrictions={addRestrictions}
				search={saytForOrg}
				id={id}
				column="user_id"
				prefixTitle="Additional User"
			/>
		</Box>
	);
}

export default Individual;
