import { Box, Divider, Paper, Typography } from "@mui/material";
import type React from "react";

interface CriteriaPreviewProps {
	description: string;
	scoringType: "binary" | "numeric" | "categorical";
	minScore?: number;
	maxScore?: number;
	categories?: string[];
}

const CriteriaPreview: React.FC<CriteriaPreviewProps> = ({
	description,
	scoringType,
	minScore,
	maxScore,
	categories,
}) => {
	const getSystemPrompt = () => {
		const basePrompt = `
<prompt>
What is a good cowboy greeting!?
</prompt>

<response>
Howdy, partner!
</response>

You are an AI evaluator. Your task is to evaluate the following aspect of the response above given the prompt:

Evaluation criteria: ${description}

You must provide your evaluation in the following format:`;

		switch (scoringType) {
			case "binary":
				return `${basePrompt}

EVALUATION: [true/false]
REASONING: [Explain your reasoning]`;

			case "numeric":
				return `${basePrompt}

EVALUATION: [provide a number between ${minScore} and ${maxScore}]
REASONING: [Explain your reasoning and justify the score]`;

			case "categorical":
				return `${basePrompt}

EVALUATION: [select one: ${categories?.join(" | ")}]
REASONING: [Explain your reasoning and justify the category selection]`;

			default:
				return basePrompt;
		}
	};

	return (
		<Paper sx={{ p: 3, my: 2, backgroundColor: "#f5f5f5" }}>
			<Typography variant="h6" gutterBottom>
				LLM Evaluation Preview
			</Typography>

			<Divider sx={{ my: 2 }} />

			<Box
				sx={{
					fontFamily: "monospace",
					whiteSpace: "pre-wrap",
					backgroundColor: "#fff",
					p: 2,
					borderRadius: 1,
					border: "1px solid #ddd",
				}}
			>
				{getSystemPrompt()}
			</Box>

			<Box sx={{ mt: 2 }}>
				<Typography variant="subtitle2" color="text.secondary">
					Expected Response Format:
				</Typography>
				<Box
					sx={{
						fontFamily: "monospace",
						backgroundColor: "#fff",
						p: 2,
						mt: 1,
						borderRadius: 1,
						border: "1px solid #ddd",
					}}
				>
					{scoringType === "binary" &&
						`{
  "evaluation": true/false,
  "reasoning": "Detailed explanation..."
}`}
					{scoringType === "numeric" &&
						`{
  "evaluation": ${minScore}-${maxScore},
  "reasoning": "Detailed explanation..."
}`}
					{scoringType === "categorical" &&
						`{
  "evaluation": "${categories?.[0] || "category"}",
  "reasoning": "Detailed explanation..."
}`}
				</Box>
			</Box>
		</Paper>
	);
};

export default CriteriaPreview;
