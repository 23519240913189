import getThemeColor from "@/utils/getThemeColor";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";

type CompanyAvatarProps = {
	src?: string;
	name?: string;
	size?: string;
	minStretch?: boolean;
	loading?: boolean;
};

const CompanyImage = styled("img", {
	shouldForwardProp: (prop) =>
		!["shouldFallback", "minStretch"].includes(prop as string),
})<{ minStretch: boolean; shouldFallback: boolean }>(
	({ theme, minStretch, shouldFallback }) => ({
		maxWidth: "100%",
		minWidth: minStretch && "100%",
		minHeight: minStretch && "100%",
		maxHeight: "100%",
		boxSizing: "border-box",
		borderRadius: !shouldFallback && theme.shape.borderRadius,
	}),
);

const Container = styled(Box, {
	shouldForwardProp: (prop) =>
		!["src", "name", "fallback", "size"].includes(prop as string),
})<{
	src: string;
	name: string;
	fallback: boolean;
	size: string | number;
}>(({ theme, src, name, fallback, size }) => ({
	background: theme.palette.mode === "dark" && src ? "#f4f4f4" : null,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	color:
		fallback || !src
			? theme.palette.getContrastText(getThemeColor(name, theme))
			: theme.palette.text.primary,
	borderRadius: theme.shape.borderRadius,
	width: size ? `${size}px` : "100%",
	height: size ? `${size}px` : fallback ? "auto" : "100%",
	overflow: "hidden",
}));

export default function CompanyAvatar({
	src,
	name = "",
	size,
	minStretch,
	loading,
}: CompanyAvatarProps) {
	const [shouldFallBack, setFallBackStatus] = useState(false);
	if (!name) {
		console.warn("CompanyAvatar: name is required");
		return null;
	}
	return (
		<Container src={src} name={name} fallback={shouldFallBack} size={size}>
			{shouldFallBack || !src ? (
				<Box
					sx={(theme) => ({
						backgroundColor: getThemeColor(name, theme),
					})}
				>
					<Typography
						sx={(theme) => ({
							textAlign: "center",
							lineHeight: size ? `${size}px` : "100%",
							fontSize: size ? `${Number(size) / 3}px` : "2.5em",
							overflowX: "hidden",
							cursor: "default",
							padding: theme.spacing(0.5, 2),
						})}
					>
						{name
							.split(/\s/)
							.reduce((response, word) => response + word.slice(0, 1), "")
							.slice(0, 3)
							.toUpperCase()}
					</Typography>
				</Box>
			) : (
				<CompanyImage
					minStretch={minStretch}
					shouldFallback={shouldFallBack}
					src={src}
					onError={() => setFallBackStatus(true)}
					alt={name}
					loading={loading ? "lazy" : "eager"}
				/>
			)}
		</Container>
	);
}

CompanyAvatar.defaultProps = {
	loading: "lazy",
};
