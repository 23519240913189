import {
	get3MonthGrowth,
	getLastValue,
	getYoYGrowth,
} from "@/utils/calculateTimeSeriesMetrics";
import {
	formatBigDollars,
	formatFloat,
	formatPercentage,
	formatShrunkTotal,
	formatTotal,
} from "@/utils/numberFormat";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import React from "react";

const TeamSizeAndHiringSection = {
	sectionTitle: "Team Size and Hiring",
	subtitle: () => "Sourced from Linkedin",
	stats: [
		{
			title: "Employees",
			metrics: [
				{
					label: "Total",
					getter: getLastValue,
					formatter: formatShrunkTotal,
				},
				{
					label: "3Mo",
					getter: get3MonthGrowth,
					formatter: formatPercentage,
				},
				{
					label: "YoY",
					getter: getYoYGrowth,
					formatter: formatPercentage,
				},
			],
			data: "li_org_employee_total",
			dataFormatter: formatTotal,
		},
		{
			title: "Job Postings",
			metrics: [
				{
					label: "Total",
					getter: getLastValue,
					formatter: formatShrunkTotal,
				},
				{
					label: "3Mo",
					getter: get3MonthGrowth,
					formatter: formatPercentage,
				},
				{
					label: "YoY",
					getter: getYoYGrowth,
					formatter: formatPercentage,
				},
			],
			data: "li_org_job_total",
			dataFormatter: formatTotal,
		},
	],
};

const WebsiteTraffic = {
	sectionTitle: "Website Traffic",
	subtitle: (co) => {
		const { trafficDomain } = co;
		if (trafficDomain) {
			return (
				<Box>
					<Tooltip title="This domain may be different from the primary domain above. If you think another domain should be tracked please contact the Labs team">
						<Chip
							style={{ marginRight: "8px" }}
							color="secondary"
							variant="outlined"
							label={trafficDomain}
						/>
					</Tooltip>
					<Typography variant="subtitle2">Sourced from Similarweb</Typography>
				</Box>
			);
		}
		return null;
	},
	dataFetchedAccessor: "trafficDomain",
	stats: [
		{
			title: "Web",
			metrics: [
				{
					label: "Total",
					getter: getLastValue,
					formatter: formatShrunkTotal,
				},
				{
					label: "3Mo",
					getter: get3MonthGrowth,
					formatter: formatPercentage,
				},
				{
					label: "YoY",
					getter: getYoYGrowth,
					formatter: formatPercentage,
				},
			],
			data: "si_web_traffic",
			dataFormatter: formatTotal,
		},
	],
};

const LaunchCoFinancials = {
	sectionTitle: "Launch.co Financials",
	stats: [
		{
			title: "Revenue",
			metrics: [
				{
					label: "Current",
					getter: getLastValue,
					formatter: formatBigDollars,
				},
				{
					label: "3Mo",
					getter: get3MonthGrowth,
					formatter: formatPercentage,
				},
				{
					label: "YoY",
					getter: getYoYGrowth,
					formatter: formatPercentage,
				},
			],
			data: "lh_revenue",
			dataFormatter: formatBigDollars,
		},
		{
			title: "Spend",
			metrics: [
				{
					label: "Current",
					getter: getLastValue,
					formatter: formatBigDollars,
				},
				{
					label: "3Mo",
					getter: get3MonthGrowth,
					formatter: formatPercentage,
				},
				{
					label: "YoY",
					getter: getYoYGrowth,
					formatter: formatPercentage,
				},
			],
			data: "lh_spend",
			dataFormatter: formatBigDollars,
		},
		{
			title: "Cash on Hand",
			metrics: [
				{
					label: "Current",
					getter: getLastValue,
					formatter: formatBigDollars,
				},
				{
					label: "3Mo",
					getter: get3MonthGrowth,
					formatter: formatPercentage,
				},
				{
					label: "YoY",
					getter: getYoYGrowth,
					formatter: formatPercentage,
				},
			],
			data: "lh_cash_on_hand",
			dataFormatter: formatBigDollars,
		},
	],
};

const ConsumerTransactions = {
	sectionTitle: "Consumer Transactions",
	subtitle: () => "Sourced from SecondMeasure",
	stats: [
		{
			title: "Observed Sales",
			metrics: [
				{
					label: "Current",
					getter: getLastValue,
					formatter: formatBigDollars,
				},
				{
					label: "3Mo",
					getter: get3MonthGrowth,
					formatter: formatPercentage,
				},
				{
					label: "YoY",
					getter: getYoYGrowth,
					formatter: formatPercentage,
				},
			],
			data: "sm_observed_sales",
			dataFormatter: formatBigDollars,
		},
		{
			title: "Observed Transactions",
			metrics: [
				{
					label: "Current",
					getter: getLastValue,
					formatter: formatShrunkTotal,
				},
				{
					label: "3Mo",
					getter: get3MonthGrowth,
					formatter: formatPercentage,
				},
				{
					label: "YoY",
					getter: getYoYGrowth,
					formatter: formatPercentage,
				},
			],
			data: "sm_observed_transactions",
			dataFormatter: formatTotal,
		},
		{
			title: "Observed Customers",
			metrics: [
				{
					label: "Current",
					getter: getLastValue,
					formatter: formatShrunkTotal,
				},
				{
					label: "3Mo",
					getter: get3MonthGrowth,
					formatter: formatPercentage,
				},
				{
					label: "YoY",
					getter: getYoYGrowth,
					formatter: formatPercentage,
				},
			],
			data: "sm_observed_customers",
			dataFormatter: formatTotal,
		},
		{
			title: "Sales Per Customers",
			metrics: [
				{
					label: "Current",
					getter: getLastValue,
					formatter: (v) => v.toFixed(2),
				},
				{
					label: "3Mo",
					getter: get3MonthGrowth,
					formatter: formatPercentage,
				},
				{
					label: "YoY",
					getter: getYoYGrowth,
					formatter: formatPercentage,
				},
			],
			data: "sm_sales_per_customer",
			dataFormatter: (value) => value.toFixed(2),
		},
		{
			title: "Transactions Per Customers",
			metrics: [
				{
					label: "Current",
					getter: getLastValue,
					formatter: (value) => formatFloat(value),
				},
				{
					label: "3Mo",
					getter: get3MonthGrowth,
					formatter: formatPercentage,
				},
				{
					label: "YoY",
					getter: getYoYGrowth,
					formatter: formatPercentage,
				},
			],
			data: "sm_txns_per_customer",
			dataFormatter: (value) => formatFloat(value),
		},
		{
			title: "Average Transaction Value",
			metrics: [
				{
					label: "Current",
					getter: getLastValue,
					formatter: formatBigDollars,
				},
				{
					label: "3Mo",
					getter: get3MonthGrowth,
					formatter: formatPercentage,
				},
				{
					label: "YoY",
					getter: getYoYGrowth,
					formatter: formatPercentage,
				},
			],
			data: "sm_avg_txn_value",
			dataFormatter: formatBigDollars,
		},
		{
			title: "Retention",
			metrics: [
				{
					label: "Current",
					getter: getLastValue,
					formatter: formatPercentage,
				},
			],
			data: "sm_retention",
			dataFormatter: (value) => formatPercentage(value),
		},
		{
			title: "Customer as % of Pop.",
			metrics: [
				{
					label: "Current",
					getter: getLastValue,
					formatter: (value) => formatPercentage(value, 4),
				},
			],
			data: "sm_customers_as_pct_of_population",
			dataFormatter: (value) => formatPercentage(value, 6),
		},
		{
			title: "Lifetime Customer as % of Pop.",
			metrics: [
				{
					label: "Current",
					getter: getLastValue,
					formatter: (value) => formatPercentage(value, 4),
				},
			],
			data: "sm_lifetime_customers_as_pct_of_population",
			dataFormatter: (value) => formatPercentage(value, 6),
		},
	],
};

export default [
	TeamSizeAndHiringSection,
	WebsiteTraffic,
	LaunchCoFinancials,
	ConsumerTransactions,
];
