import type { Conversation as ConversationModel } from "@/api/Chat";
import { useAuth } from "@/hooks/useAuth";
import { ArrowDownward } from "@mui/icons-material";
import { Box, Skeleton } from "@mui/material";
import React, { useEffect, useRef } from "react";
import GrokChatBubble from "./GrokChatBubble";
import LoadingMessage from "./LoadingMessage";
import UserChatBubble from "./UserChatBubble";

export default function Conversation({
	conversation,
	loading,
	awaitingResponse,
}: {
	conversation: ConversationModel;
	loading: boolean;
	awaitingResponse: boolean;
}) {
	const { user } = useAuth();
	const chatBoxRef = useRef<HTMLDivElement>(null);
	const [autoScroll, setAutoScroll] = React.useState(true);

	const scrollToBottom = () => {
		chatBoxRef.current.scrollTo({
			top: chatBoxRef.current.scrollHeight,
			behavior: "smooth",
		});
	};

	const handleScroll = () => {
		if (!chatBoxRef.current) return;

		const { scrollHeight, scrollTop, clientHeight } = chatBoxRef.current;
		const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 50;

		setAutoScroll(isAtBottom);
	};

	useEffect(() => {
		if (!chatBoxRef.current || !autoScroll) return;
		scrollToBottom();
	}, [conversation.messages, autoScroll]);

	useEffect(() => {
		if (!loading && chatBoxRef.current && autoScroll) {
			scrollToBottom();
		}
	}, [loading, autoScroll]);

	return (
		<Box
			ref={chatBoxRef}
			sx={{
				width: "100%",
				flexGrow: 1,
				display: "flex",
				flexDirection: "column",
				position: "relative",
				overflowY: "auto",
				overflowX: "hidden",
				py: 1,
				gap: 1,

				// Scrollbar styling
				"&::-webkit-scrollbar": {
					width: "8px",
					background: "transparent",
				},
				"&::-webkit-scrollbar-thumb": {
					background: (theme) => theme.palette.divider,
					borderRadius: "4px",
					"&:hover": {
						background: (theme) => theme.palette.action.hover,
					},
				},
				// Firefox scrollbar
				scrollbarWidth: "thin",
				scrollbarColor: (theme) => `${theme.palette.divider} transparent`,

				scrollBehavior: "smooth",
			}}
			onScroll={handleScroll}
		>
			{/* Loading skeletons */}
			{loading && conversation.messages?.length === 0 && (
				<Box
					sx={{
						px: 2,
						display: "flex",
						flexDirection: "column",
						gap: 2,
					}}
				>
					<Skeleton variant="rounded" height={50} />
					<Skeleton variant="rounded" height={150} />
					<Skeleton variant="rounded" height={50} />
					<Skeleton variant="rounded" height={200} />
				</Box>
			)}

			{/* Chat messages */}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 1,
					px: 2,
					minHeight: "100%",
				}}
			>
				{conversation.messages?.map((chat) => (
					<Box
						key={chat.id}
						sx={{
							opacity: 1,
							transform: "translateY(0)",
							transition: "opacity 0.2s ease, transform 0.2s ease",
						}}
					>
						{chat.role === "user" ? (
							<UserChatBubble user={user} content={chat.content} />
						) : (
							<GrokChatBubble content={chat.content} stream={chat.stream} />
						)}
					</Box>
				))}

				{/* Loading message indicator */}
				{awaitingResponse && (
					<Box
						sx={{
							opacity: 1,
							transform: "translateY(0)",
							transition: "opacity 0.2s ease, transform 0.2s ease",
						}}
					>
						<LoadingMessage />
					</Box>
				)}
			</Box>

			{/* Scroll to bottom indicator (optional) */}
			{!autoScroll && (
				<Box
					onClick={scrollToBottom}
					sx={{
						position: "absolute",
						bottom: 16,
						right: 16,
						borderRadius: "50%",
						backgroundColor: (theme) => theme.palette.background.paper,
						boxShadow: (theme) => theme.shadows[2],
						width: 40,
						height: 40,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						cursor: "pointer",
						transition: "opacity 0.2s ease",
						opacity: 0.7,
						"&:hover": {
							opacity: 1,
						},
					}}
				>
					<ArrowDownward />
				</Box>
			)}
		</Box>
	);
}
