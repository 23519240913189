import { getNewsArticles } from "@/api/NewsArticles";
import type { Organization } from "@/api/Organization";
import ClampsTooltip from "@/components/ClampsTooltip";
import Section from "@/ui/atoms/Section";
import { Box, Link, Skeleton, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useQuery } from "react-query";

interface Props {
	company: Organization;
}

function RecentNews({ company }: Props) {
	const { valorId } = company;
	const theme = useTheme();
	const { data: newsArticles = [], isLoading } = useQuery(
		["NewsArticles", valorId],
		async () => {
			const response = await getNewsArticles({ valorId, limit: 10 });
			return response?.results;
		},
		{
			retry: 1,
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			staleTime: Number.POSITIVE_INFINITY,
		},
	);

	const renderContent = () => {
		if (isLoading) {
			const fake = (key) => (
				<Box
					data-cy="recent-news__skeleton-item"
					display="flex"
					sx={{ gap: "8px", margin: "16px 0", width: "100%" }}
					key={key}
				>
					<Skeleton variant="rounded" height="56px" width="86px" />
					<Box display="flex" flexDirection="column">
						<Skeleton variant="text" height="20px" width="220px" />
						<Skeleton variant="text" height="16px" width="80px" />
						<Skeleton variant="text" height="16px" width="120px" />
					</Box>
				</Box>
			);
			return [fake(1), fake(2), fake(3), fake(4), fake(5)];
		}
		let newsRender: JSX.Element | JSX.Element[] | null = null;
		if (newsArticles.length) {
			newsRender = newsArticles.map(
				({
					title,
					siteName,
					imageUrl,
					pageUrl,
					articleTimestamp,
					articleDid,
				}) => (
					<Box
						data-cy={`recent-news__news-item-${articleDid}`}
						key={articleDid}
						display="flex"
						alignItems="center"
						sx={{
							gap: "16px",
							textOverflow: "ellipsis",
							overflow: "hidden",
							whiteSpace: "nowrap",
							width: "100%",
						}}
					>
						<Link href={pageUrl} target="_blank">
							<Box
								sx={{
									minWidth: 88,
									width: 88,
									height: 56,
									borderRadius: theme.shape.borderRadius,
								}}
							>
								{imageUrl ? (
									<img
										data-cy="recent-news__news-image"
										alt=""
										style={{
											display: "block",
											objectFit: "cover",
											width: "100%",
											height: "100%",
											borderRadius: theme.shape.borderRadius,
										}}
										src={imageUrl}
									/>
								) : null}
							</Box>
						</Link>
						<Box display="flex" flexDirection="column" overflow="hidden">
							<ClampsTooltip title={title}>
								<Link
									href={pageUrl}
									target="_blank"
									sx={{
										color: theme.palette.text.primary,
										textDecoration: "none",
										"&:hover": {
											textDecoration: "underline",
										},
									}}
								>
									<Typography
										data-cy="recent-news__news-title"
										variant="boldSubtitle2"
									>
										{title}
									</Typography>
								</Link>
							</ClampsTooltip>
							<Typography
								data-cy="recent-news__news-site"
								sx={{
									width: "220px",
									textOverflow: "ellipsis",
									overflow: "hidden",
									whiteSpace: "nowrap",
								}}
								variant="caption"
							>
								{siteName}
							</Typography>
							<Typography data-cy="recent-news__news-date" variant="caption">
								{dayjs(articleTimestamp).format("MM/DD/YYYY")}
							</Typography>
						</Box>
					</Box>
				),
			);
		} else {
			return null;
		}

		return (
			<Box
				data-cy="recent-news__news-list"
				display="flex"
				flexDirection="column"
				sx={{ gap: "8px", margin: "16px 0", width: "100%" }}
			>
				{newsRender}
			</Box>
		);
	};
	const content = renderContent();
	return (
		<Section>
			<Section.Title>Recent News</Section.Title>
			<Section.Content>
				{content !== null ? (
					<Box data-cy="recent-news__content" paddingLeft={1} paddingRight={1}>
						{content}
					</Box>
				) : (
					<Typography data-cy="recent-news__no-news-message">
						No Recent News
					</Typography>
				)}
			</Section.Content>
		</Section>
	);
}

export default RecentNews;
