import {
	type Organization,
	getOrganizationInternalFunding,
} from "@/api/Organization";
import LabeledDetail from "@/components/LabeledDetail";
import MiniTable from "@/components/MiniTable";
import ProcessForm from "@/components/ProcessForm";
import { canSeeDeals } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import Section from "@/ui/atoms/Section";
import { formatBigFundingRoundDollars } from "@/utils/numberFormat";
import { Edit } from "@mui/icons-material";
import {
	Box,
	Card,
	CardContent,
	Chip,
	Divider,
	IconButton,
	Skeleton,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useQuery } from "react-query";

interface Props {
	company: Organization;
}

function CardLoadingSkeleton() {
	return (
		<>
			{[1, 2, 3].map((i) => (
				<Box
					key={i}
					sx={{ mb: 1, p: 2, bgcolor: "background.paper", borderRadius: 1 }}
				>
					<Skeleton variant="text" width="60%" height={24} sx={{ mb: 1 }} />
					<Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
						<Skeleton variant="text" width="20%" />
						<Skeleton variant="text" width="20%" />
						<Skeleton variant="rectangular" width={80} height={24} />
					</Box>
				</Box>
			))}
		</>
	);
}

function ActionCell({
	getValue,
	refetch,
}: {
	getValue: () => number;
	refetch: () => void;
}) {
	const id = getValue();
	const [open, setOpen] = React.useState(false);
	return (
		<Box>
			<IconButton
				size="small"
				onClick={() => {
					setOpen(true);
				}}
			>
				<Edit />
			</IconButton>

			{open ? (
				<ProcessForm
					open={open}
					processId="deals"
					taskId={id.toString()}
					formType="edit"
					onClose={() => {
						setOpen(false);
						// refetch
						refetch();
					}}
				/>
			) : null}
		</Box>
	);
}

export function ValorFunding({ company }: Props) {
	const { valorId } = company;
	const { user } = useAuth();
	const userCanSeeDeals = useMemo(() => canSeeDeals(user), [user]);
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const {
		data = [],
		isLoading,
		refetch,
	} = useQuery(["ValorFunding", valorId], () =>
		getOrganizationInternalFunding(valorId),
	);

	const theme = useTheme();

	const colorCoding = useMemo(
		() => ({
			"New Opportunity": {
				backgroundColor: theme.palette.blue.highlight,
				color: theme.palette.getContrastText(theme.palette.blue.highlight),
			},
			"Pre Opportunity Assessment": {
				backgroundColor: theme.palette.blue.highlight,
				color: theme.palette.getContrastText(theme.palette.blue.highlight),
			},
			"IC Opportunity Assessment Approval": {
				backgroundColor: theme.palette.blue.highlight,
				color: theme.palette.getContrastText(theme.palette.blue.highlight),
			},
			"Considering for Team Sheet": {
				backgroundColor: theme.palette.blue.highlight,
				color: theme.palette.getContrastText(theme.palette.blue.highlight),
			},
			"IC Term Sheet Approval": {
				backgroundColor: theme.palette.teal.highlight,
				color: theme.palette.getContrastText(theme.palette.teal.highlight),
			},
			"Considering for TS": {
				backgroundColor: theme.palette.teal.highlight,
				color: theme.palette.getContrastText(theme.palette.teal.highlight),
			},
			"Term Sheet Approved by IC": {
				backgroundColor: theme.palette.teal.highlight,
				color: theme.palette.getContrastText(theme.palette.teal.highlight),
			},
			"IC Approval to Close": {
				backgroundColor: theme.palette.blue.main,
				color: theme.palette.getContrastText(theme.palette.blue.main),
			},
			"IC Approved to Close": {
				backgroundColor: theme.palette.blue.main,
				color: theme.palette.getContrastText(theme.palette.blue.main),
			},
			"Post Closing Items": {
				backgroundColor: theme.palette.green.highlight,
				color: theme.palette.getContrastText(theme.palette.green.highlight),
			},
			"Post Closing Complete": {
				backgroundColor: theme.palette.green.highlight,
				color: theme.palette.getContrastText(theme.palette.green.highlight),
			},
			Pass: {
				backgroundColor: theme.palette.text.disabled,
				color: theme.palette.getContrastText(theme.palette.text.disabled),
			},
			Following: {
				backgroundColor: theme.palette.yellow.main,
				color: theme.palette.getContrastText(theme.palette.yellow.main),
			},
		}),
		[theme],
	);
	const columns = useMemo(
		() =>
			[
				{
					id: "name",
					header: "Deal Name",
					accessorKey: "name",
					width: 200,
					cell: ({ getValue }) => {
						const val = getValue();
						if (!val) {
							return null;
						}
						return val;
					},
					sortType: "alpha",
				},
				{
					id: "fund",
					header: "Target Fund",
					accessorKey: "targetFund",
					cell: ({ getValue }) => {
						const val = getValue();
						if (!val) {
							return null;
						}
						return val;
					},
					sortType: "alpha",
				},
				{
					id: "type",
					header: "Type",
					accessorKey: "investmentType",
					cell: ({ getValue }) => {
						const val = getValue();
						if (!val) {
							return null;
						}
						return val;
					},
					sortType: "alpha",
				},
				{
					id: "amount",
					header: "Amount",
					accessorKey: "investmentAmount",
					cell: ({ getValue }) => {
						const val = getValue();
						if (!val) {
							return null;
						}

						return formatBigFundingRoundDollars(val);
					},
					width: 85,
					sortType: "numeric",
				},
				{
					id: "status",
					header: "Status",
					accessorKey: "stage",
					width: "auto",
					sortType: "alpha",
					cell: ({ getValue }) => {
						const val = getValue();
						if (!val) {
							return null;
						}

						return <Chip sx={colorCoding[val]} label={val} />;
					},
				},

				{
					id: "close_date",
					header: "Close Date",
					sortType: "date",
					accessorFn: (row) => {
						const { stage } = row;

						const { passDate } = row;

						const closeDate = row.targetCloseDate;

						if (stage === "Pass" && passDate) {
							return passDate;
						}
						return closeDate;
					},
					width: "auto",
					cell: ({ getValue }) => {
						const val = getValue();
						if (!val) {
							return null;
						}
						return dayjs(val).utc().format("YYYY-MM-DD");
					},
				},
				userCanSeeDeals
					? {
							id: "actions",
							header: "Edit",
							accessorKey: "taskId",
							width: 40,
							cell: ({ getValue }) => (
								<ActionCell getValue={getValue} refetch={refetch} />
							),
						}
					: null,
			].filter((x) => x),
		[colorCoding, refetch, userCanSeeDeals],
	);

	function MobileCard({ row, colorCoding, refetch }) {
		const [open, setOpen] = React.useState(false);

		const displayValue = (value, formatter = (val) => val) => {
			if (value === null || value === undefined || value === "") {
				return "---";
			}
			return formatter(value);
		};

		const closeDate =
			row.stage === "Pass" && row.passDate
				? dayjs(row.passDate).utc().format("DD MMM YY")
				: row.targetCloseDate
					? dayjs(row.targetCloseDate).utc().format("DD MMM YY")
					: "---";

		return (
			<>
				<Card
					sx={{
						mb: 2,
						border: (theme) => `1px solid ${theme.palette.divider}`,
						"&:hover": {
							boxShadow: (theme) => theme.shadows[4],
						},
					}}
				>
					<CardContent>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								mb: 2,
							}}
						>
							<Typography variant="boldBody2" component="div">
								{displayValue(row.name)}
							</Typography>
							<Chip
								sx={{ ...colorCoding[row.stage] }}
								label={row.stage}
								size="small"
							/>
						</Box>

						<Divider sx={{ my: 1 }} />
						<Box sx={{ display: "flex", justifyContent: "space-between" }}>
							<Box>
								<Typography variant="body2" color="textSecondary">
									Fund
								</Typography>
								<Typography variant="body1">
									{displayValue(row.targetFund)}
								</Typography>
							</Box>
							<Box sx={{ textAlign: "center" }}>
								<Typography variant="body2" color="textSecondary">
									Type
								</Typography>
								<Typography variant="body1">
									{displayValue(row.investmentType)}
								</Typography>
							</Box>
							<Box sx={{ textAlign: "center" }}>
								<Typography variant="body2" color="textSecondary">
									Close Date
								</Typography>
								<Typography variant="body1">{closeDate}</Typography>
							</Box>
							<Box sx={{ textAlign: "right" }}>
								<Typography variant="body2" color="textSecondary">
									Amount
								</Typography>
								<Typography variant="body1">
									{displayValue(
										formatBigFundingRoundDollars(row.investmentAmount),
									)}
								</Typography>
							</Box>
						</Box>
					</CardContent>
				</Card>

				{open ? (
					<ProcessForm
						open={open}
						processId="deals"
						taskId={row.taskId.toString()}
						formType="edit"
						onClose={() => {
							setOpen(false);
							refetch();
						}}
					/>
				) : null}
			</>
		);
	}

	return (
		<Section>
			<Section.Title>Valor Funding</Section.Title>
			<Section.Content>
				<LabeledDetail
					title="VALOR INVESTMENTS"
					expandable={isMobile ? false : data?.length > 0}
					maxLines={5}
					lineHeight={isMobile ? "44px" : "36px"}
				>
					{isMobile ? (
						<Box
							sx={{
								mt: 2,
								pb: 4,
								maxHeight: 600, // Set a fixed height for the container
								overflowY: "auto", // Enable vertical scrolling
							}}
						>
							{isLoading ? (
								<CardLoadingSkeleton />
							) : data.length === 0 ? (
								<Typography>No Valor Funding Opportunities</Typography>
							) : (
								data.map((row, index) => (
									<MobileCard
										key={index}
										row={row}
										colorCoding={colorCoding}
										refetch={refetch}
									/>
								))
							)}
						</Box>
					) : (
						<MiniTable
							isLoading={isLoading}
							data={data}
							columns={columns}
							emptyTable="No Valor Funding Opportunities "
							enableSorting
						/>
					)}
				</LabeledDetail>
			</Section.Content>
		</Section>
	);
}

export default ValorFunding;
