import {
	formatBigDollars,
	formatBigFundingRoundDollars,
} from "@/utils/numberFormat";
import {
	Box,
	ClickAwayListener,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	styled,
} from "@mui/material";
import React, { useState } from "react";
import OverflowBox from "./OverflowBox";

type Probability = {
	dollar: number;
	percent: number;
};
type ProbabilityValue = {
	numericValue: number;
	probabilities: Probability[];
};

const DisplayBox = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(2),
}));

export default function ProbabilisticEquityValueCell({
	value,
}: {
	value: ProbabilityValue;
}) {
	const [showOverflow, setShowOverflow] = useState(false);
	const displayValue = value?.numericValue;
	if (displayValue === undefined || displayValue === null) return <>-</>;

	const formatDollar = (value: number): string =>
		new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		}).format(value);

	const specificValue = formatDollar(displayValue);

	return (
		<ClickAwayListener onClickAway={() => setShowOverflow(false)}>
			<Box position="relative" display="flex" height="100%" alignItems="center">
				<Box
					sx={{
						overflow: "hidden",
						whiteSpace: "nowrap",
						cursor: "pointer",
					}}
					onClick={() => setShowOverflow(true)}
				>
					{formatBigFundingRoundDollars(displayValue)}
				</Box>
				<OverflowBox open={showOverflow}>
					<DisplayBox elevation={0}>
						<TableContainer>
							<Table size="small" aria-label="probability values">
								<TableHead>
									<TableRow>
										<TableCell>
											<Typography variant="tableHeader">Probability</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="tableHeader">
												Equity Value
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="tableHeader">
												Weighted Value
											</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{value.probabilities.map((prob, index) => (
										<TableRow key={index}>
											<TableCell>{prob.percent}%</TableCell>
											<TableCell>{formatBigDollars(prob.dollar)}</TableCell>
											<TableCell>
												{formatDollar((prob.dollar * prob.percent) / 100)}
											</TableCell>
										</TableRow>
									))}
									<TableRow>
										<TableCell
											colSpan={2}
											align="right"
											sx={{ fontWeight: "bold" }}
										>
											Total Expected Value:
										</TableCell>
										<TableCell sx={{ fontWeight: "bold" }}>
											{specificValue}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</DisplayBox>
				</OverflowBox>
			</Box>
		</ClickAwayListener>
	);
}
