import type { Criterion } from "@/api/Chat";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
	Box,
	CircularProgress,
	Collapse,
	Grid,
	Typography,
} from "@mui/material";
import React from "react";
import CriterionCard from "../EvaluationCriteria/CriteriaCard";

interface CriteriaSectionProps {
	isOpen: boolean;
	selectedCriteria: Criterion[];
	criteria: Criterion[];
	isLoading: boolean;
	onCriteriaSelect: (criterion: Criterion) => void;
}
export const CriteriaSection = ({
	isOpen,
	selectedCriteria,
	criteria,
	isLoading,
	onCriteriaSelect,
}: CriteriaSectionProps) => {
	return (
		<Box sx={{ mb: 3 }}>
			<Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
				<Typography variant="h4">3. Select Criteria </Typography>
				{selectedCriteria.length > 0 && (
					<CheckCircleIcon color="success" sx={{ ml: 1 }} />
				)}
			</Box>

			<Collapse in={isOpen}>
				<Box sx={{ p: 2 }}>
					{isLoading ? (
						<CircularProgress />
					) : (
						<Grid container spacing={2}>
							{criteria?.map((criterion) => (
								<Grid item xs={12} md={6} key={criterion.id}>
									<Box
										onClick={() => onCriteriaSelect(criterion)}
										sx={{
											cursor: "pointer",
											position: "relative",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											"&:hover": { opacity: 0.8 },
											border: selectedCriteria.some(
												(c) => c.id === criterion.id,
											)
												? "2px solid #0042ed"
												: "2px solid transparent",
										}}
									>
										<CriterionCard {...criterion} />
									</Box>
								</Grid>
							))}
						</Grid>
					)}
				</Box>
			</Collapse>

			{selectedCriteria.length > 0 && !isOpen && (
				<Box sx={{ px: 2, pb: 2 }}>
					<Grid container spacing={2}>
						{selectedCriteria.map((criterion) => (
							<Grid item xs={12} md={6} key={criterion.id}>
								<CriterionCard {...criterion} />
							</Grid>
						))}
					</Grid>
				</Box>
			)}
		</Box>
	);
};

export default CriteriaSection;
