import { type BaseElement, Editor, Transforms } from "slate";
import type { ReactEditor } from "slate-react";

import imageExtensions from "image-extensions";
import isUrl from "is-url";

import {
	Image,
	type ImageElement,
	ImagePlaceHolder,
	Paragraph,
} from "../types/Element";
import type { AdvancedDescendant } from "../types/RichText";

type UploadImageFunc = ({
	file,
	url,
}: { file?: File; url?: string }) => Promise<{ name: string; url: string }>;

export function insertPlaceholder(
	editor,
): ({ name, url }: { name: string; url: string }) => void {
	const placeholder = {
		type: ImagePlaceHolder,
		name: crypto.randomUUID(),
		children: [{ text: "" }],
	};
	Transforms.insertNodes(editor, placeholder);
	return (imageUpload) => {
		if (!imageUpload) {
			// get placeholder node
			const placeholders = Editor.nodes(editor, {
				at: [],
				match: (node) => placeholder.name === node.name,
			});

			[...placeholders].forEach((nodeEntry) => {
				Transforms.select(editor, nodeEntry[1]);
				Transforms.removeNodes(editor);
			});
		} else {
			const { name, url } = imageUpload;
			// replace placeholder with image
			const text = { text: "[image]" };
			const image: ImageElement = {
				type: "image",
				url,
				name,
				children: [text],
			};

			// get placeholder node
			const placeholders = Editor.nodes(editor, {
				at: [],
				match: (node) => placeholder.name === node.name,
			});

			[...placeholders].forEach((nodeEntry) => {
				Transforms.select(editor, nodeEntry[1]);
				Editor.insertFragment(editor, [image]);
			});
		}
	};
}

export function insertImage(editor: ReactEditor, url: string, name: string) {
	const text = { text: "[image]" };
	const image: ImageElement = {
		type: "image",
		url,
		name,
		children: [text],
	};
	Transforms.insertNodes(editor, image);
}

export function onKeyDown(editor: ReactEditor, event: KeyboardEvent) {
	if (event.key === "Enter") {
		const [match] = Editor.nodes(editor, {
			match: (n) => n.type === "image",
		});
		if (match) {
			event.preventDefault();
			Transforms.insertNodes(editor, {
				type: Paragraph,
				children: [{ text: "" }],
			});
		}
	}
}

export default (editor: ReactEditor, uploadImage: UploadImageFunc) => {
	const { insertData, isVoid } = editor;

	// eslint-disable-next-line no-param-reassign
	editor.isVoid = (element: BaseElement) =>
		(element as AdvancedDescendant).type === Image ? true : isVoid(element);

	const isImageUrl = (url) => {
		if (!url) return false;
		if (!isUrl(url)) return false;
		const ext = new URL(url).pathname.split(".").pop();
		return imageExtensions.includes(ext);
	};

	// eslint-disable-next-line no-param-reassign
	editor.insertData = (data) => {
		const text = data.getData("text/plain");
		const { files } = data;

		if (files && files.length > 0 && text?.length === 0) {
			const replacePlaceholder = insertPlaceholder(editor);
			[...files].forEach((file) => {
				uploadImage({ file })
					.then(({ url: imageUrl, name }) => {
						replacePlaceholder({
							name,
							url: imageUrl,
						});
					})
					.catch(() => {
						// remove placeholder
						replacePlaceholder(null);
					});
			});
		} else if (isImageUrl(text)) {
			// fetch image, upload to our own storage
			const replacePlaceholder = insertPlaceholder(editor);
			uploadImage({ url: text })
				.then(({ url: imageUrl, name }) => {
					replacePlaceholder({
						name,
						url: imageUrl,
					});
				})
				.catch(() => {
					replacePlaceholder(null);
				});
		} else {
			insertData(data);
		}
	};

	return editor;
};
