import { Box, Stack, Typography } from "@mui/material";
import React from "react";

export default function Conversation({
	conversation,
}: {
	conversation: {
		speaker: string;
		paragraphs: string[];
	}[];
}) {
	const participants = conversation.map((exchange) => exchange.speaker);
	const uniqueParticipants = Array.from(new Set(participants));

	const isNotInterviewer = (participant: string) =>
		participant !== uniqueParticipants[0];

	return (
		<Stack
			spacing={2}
			sx={{
				marginTop: 2,
				"@media print": {
					display: "block",
					marginBottom: 150, // fixes page break issue
				},
			}}
		>
			{conversation.map((exchange) => (
				<Stack
					key={exchange.paragraphs.join("")}
					spacing={1}
					sx={{
						marginTop: 2,
					}}
				>
					{exchange.paragraphs.map((paragraph, j) => (
						<Box key={paragraph} maxWidth="800px">
							{j === 0 && isNotInterviewer(exchange.speaker) && (
								<Box
									sx={{
										fontWeight: (theme) => theme.typography.fontWeightBold,
										color: (theme) => theme.palette.blue.dark,
									}}
									width="100%"
								>
									{exchange.speaker}
								</Box>
							)}
							<Typography
								variant="body2"
								sx={{
									fontWeight: isNotInterviewer(exchange.speaker)
										? (theme) => theme.typography.fontWeightRegular
										: (theme) => theme.typography.fontWeightBold,
									fontStyle: isNotInterviewer(exchange.speaker)
										? "normal"
										: "italic",
								}}
							>
								{paragraph}
							</Typography>
						</Box>
					))}
				</Stack>
			))}
		</Stack>
	);
}
