import Progress from "@/ui/atoms/Progress";
import {
	Autocomplete,
	Box,
	Checkbox,
	Chip,
	MenuItem,
	MenuList,
	Radio,
	TextField,
} from "@mui/material";
import styled from "@mui/material/styles/styled";
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useQuery } from "react-query";

type Option = {
	label: string;
	value: string | number;
	count: number;
};

type SelectFilterProps = {
	multiple?: boolean;
	getFilterCounts: () => Promise<Option[]>;
	setFilterValue: (value: any) => void;
	getFilterValue: () => any;
	columnId: string;
	fieldName: string;
};

const DenseMenuList = styled(MenuList)(() => ({
	padding: 0,
	maxHeight: "300px",
	overflow: "auto",
}));

const DenseMenuItem = styled(MenuItem)(() => ({
	padding: "4px",
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	gap: 1,
	fontSize: "14px",
	maxHeight: "30px",
	cursor: "pointer",
	minWidth: 144,
}));

export function DynamicSelectFilter({
	multiple,
	getFilterCounts,
	getFilterValue,
	columnId,
	setFilterValue,
	fieldName,
}: SelectFilterProps) {
	const columnFilterValue = getFilterValue() as string | string[] | undefined;

	const { data: options = [], isLoading } = useQuery<Option[]>(
		["filterCounts", columnId],
		getFilterCounts,
		{ keepPreviousData: true },
	);

	const sortedOptions = React.useMemo(
		() => [...options].sort((a, b) => a.label.localeCompare(b.label)),
		[options],
	);

	const Control = multiple ? Checkbox : Radio;

	const isChecked = (optionValue: string) => {
		if (multiple) {
			return (
				Array.isArray(columnFilterValue) &&
				columnFilterValue.some((item) => item.value === optionValue)
			);
		}
		return (columnFilterValue as { value: string })?.value === optionValue;
	};

	const handleChange = (option: { label: string; value: string }) => {
		if (multiple) {
			const currentValues =
				(columnFilterValue as { label: string; value: string }[]) || [];
			const newValues = isChecked(option.value)
				? currentValues.filter((v) => v.value !== option.value)
				: [...currentValues, option];
			setFilterValue(newValues.length ? newValues : undefined);
		} else {
			setFilterValue(isChecked(option.value) ? undefined : option);
		}
	};

	if (isLoading) {
		return (
			<Box
				sx={{
					display: "flex",
					minWidth: "220px",
					minHeight: "220px",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Progress />
			</Box>
		);
	}

	if (sortedOptions.length > 10) {
		return (
			<Box
				sx={{
					minWidth: "220px",
					padding: "8px",
				}}
			>
				<Autocomplete
					autoFocus
					multiple={multiple}
					options={sortedOptions}
					getOptionLabel={(option) => option.label}
					renderOption={(props, option) => (
						<MenuItem {...props}>
							<Box
								component="span"
								sx={{ mr: 1, color: "text.secondary", fontSize: 12 }}
							>
								{option.count}
							</Box>
							{option.label}
						</MenuItem>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							size="small"
							placeholder={`Filter ${fieldName}`}
						/>
					)}
					renderTags={(value, getTagProps) =>
						value.map((option, index) => (
							<Chip
								label={option.label}
								size="small"
								{...getTagProps({ index })}
								key={option.value}
							/>
						))
					}
					value={
						multiple
							? sortedOptions.filter((option) =>
									(columnFilterValue as { value: string }[])?.some(
										(item) => item.value === option.value.toString(),
									),
								)
							: sortedOptions.find(
									(option) =>
										option.value.toString() ===
										(columnFilterValue as { value: string })?.value,
								) || null
					}
					onChange={(_, newValue) => {
						if (multiple) {
							const values = Array.isArray(newValue)
								? newValue.map((option) => ({
										label: option.label,
										value: option.value.toString(),
									}))
								: [];
							setFilterValue(values.length ? values : undefined);
						} else {
							setFilterValue(
								newValue
									? { label: option.label, value: option.value.toString() }
									: undefined,
							);
						}
					}}
					sx={{ minWidth: "220px" }}
				/>
			</Box>
		);
	}

	return (
		<Box sx={{ minWidth: "220px" }}>
			<DenseMenuList>
				{sortedOptions.map((option) => (
					<DenseMenuItem
						key={option.value}
						onClick={() => handleChange(option)}
					>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="center"
							fontSize="12px"
							color="text.secondary"
							ml={1}
							width={24}
						>
							{option.count}
						</Box>
						<Control size="small" checked={isChecked(option.value)} />
						{option.label}
					</DenseMenuItem>
				))}
			</DenseMenuList>
		</Box>
	);
}

export default DynamicSelectFilter;
