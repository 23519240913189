import type { FieldList } from "@/api/Process";
import Input from "@/components/FormInputs/Input";
import React, { useEffect, useMemo, useState } from "react";

export const Field = React.memo(
	({
		field,
		value,
		onChange,
		onBlur,
		organizationName,
		required,
		error,
		disabled,
	}: {
		field: FieldList[0];
		value: any;
		onChange: (v: any, f: FieldList[0]) => void;
		onBlur: (v: any, f: FieldList[0]) => void;
		organizationName: string;
		required: boolean;
		error: boolean;
		disabled: boolean;
	}) => (
		<Input
			dataCy={`fields.${field.type}.${field.id}`}
			key={field.id}
			type={field.type}
			name={field.name}
			value={value}
			onChange={(newValue) => onChange(newValue, field)}
			onBlur={(v) => onBlur(v, field)}
			choices={field.choices}
			isOrganizationField={field.isOrganizationField}
			organizationName={organizationName}
			required={required}
			disabled={disabled}
			error={error}
		/>
	),
);
Field.displayName = "Field";

export function MaskedField({
	field,
	value,
	// onChange,
	onBlur,
	organizationName,
	required,
	error,
	disabled,
}: {
	field: FieldList[0];
	value: any;
	// onChange: (value: any, field: FieldList[0]) => void;
	onBlur: (v: any, f: FieldList[0]) => void;
	organizationName: string;
	required: boolean;
	error: boolean;
	disabled: boolean;
}) {
	const [bareValue, setBareValue] = useState();
	const [maskValue, setMaskValue] = useState();

	useEffect(() => {
		if (bareValue !== value) {
			setBareValue(value);
		}
	}, [value, bareValue]);

	const inputValue = useMemo(() => {
		if (maskValue !== undefined) {
			return maskValue;
		}
		if (bareValue !== undefined) {
			return bareValue;
		}
		return null;
	}, [maskValue, bareValue]);

	return (
		<Field
			field={field}
			value={inputValue}
			onChange={(newValue) => {
				setMaskValue(newValue);
			}}
			onBlur={onBlur}
			organizationName={organizationName}
			required={required}
			error={error}
			disabled={disabled}
		/>
	);
}

MaskedField.displayName = "MaskedField";
