import { getProcess } from "@/api/Process";
import { titleCase } from "@/utils/titleCase";
import { useMemo } from "react";
import { useQuery } from "react-query";

export const SETTINGS = {
	ENTITY_NAME: "entity_name",
	REPLY_ENTITY: "reply_entity",
	MODAL_NEW_TASK_TITLE: "modal_new_task_title",
};

export default function useProcess(processId, { enabled = true } = {}) {
	const {
		data: process,
		isLoading: processLoading,
		refetch,
	} = useQuery(["process", processId], () => getProcess(processId), {
		enabled: !!processId && enabled,
		refetchInterval: false,
	});

	const fields = useMemo(
		() =>
			[
				...(process?.fields || []),
				...(process?.organizationFields || []),
			].toSorted((a, b) => a.sort - b.sort),
		[process],
	);

	const views = useMemo(
		() => process?.views?.toSorted((a, b) => a.sort - b.sort) || [],
		[process],
	);

	const settings: {
		[key: string]: string;
	} = useMemo(() => {
		const defaultSettings = {
			entityName: "Task",
			replyEntity: "comments",
			boardViewDisabled: false,
			enableSurveyLinking: false,
			modalEditTaskTitle: false,
			modalNewTaskTitle: false,
			addButtonLabel: false,
		};
		const settingsObject = (process?.settings || []).reduce(
			(acc, setting) => ({
				...acc,
				[setting.key[0] + titleCase(setting.key).split(" ").join("").slice(1)]:
					setting.value,
			}),
			{},
		);
		return {
			...defaultSettings,
			...settingsObject,
		};
	}, [process]);

	return {
		data: {
			...process,
			fields,
			views,
			settings,
		},
		actions: {
			refetch,
		},
		isLoading: processLoading,
	};
}
