import {
	getAllSections,
	getAvailableFrameworkSurveys,
	getCompanyResponses,
	getCompanyResponsesExcel,
	getDrafts,
	patchResponse,
} from "@/api/FrameworkSurvey";
import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";
import { useAuth } from "@/hooks/useAuth";
import ErrorMessage from "@/ui/atoms/ErrorMessage";
import MultiSelect from "@/ui/atoms/MultiSelect";
import Section from "@/ui/atoms/Section";
import FrameworkGrid from "@/ui/templates/FrameworkGrid";
import {
	Close as CloseIcon,
	Download as DownloadIcon,
	Lock as LockIcon,
} from "@mui/icons-material";
import {
	Box,
	Button,
	Container,
	IconButton,
	Link,
	Paper,
	Skeleton,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import _, { uniq } from "lodash";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";
import { DraftGrid } from "./DraftCard";


const opsSurveys = [
  "5539da8b-67c9-44e1-b35d-9db20b9958bb",
  "5349d5e8-62a4-4bfc-be22-ef53b5aef8c7",
  "97da77b8-d7b2-4e23-93b1-c5df0929d4e1",
  "b00c5d03-0d69-4c10-8d46-7e9c6bfa4a47",
];

const opsSurveysNames = [
  "Active Engagement (MSL)",
  "Active Engagement(s) (MSL)",
  "Project Manager",
  "Rev Gen Team",
];

const defaultAttrs = [
  "18290679-4078-4aeb-8efb-e526a7cb9750", // Avengers Symbol
  "5612e495-219b-4300-ac1a-dd8f93e6303e", // Is this Survey for Month 1 or Month 6?
  "48558fe3-7de1-46b4-893a-91057ebdf620", // Is this Survey from a Board Meeting?
  "64e3dacd-6a0d-4f65-baee-817be3b115df", // Relationship
  "bd092e9e-807f-45af-8952-4728888b7615", // Skills / Traits / Attributes
  "6d345e02-5c41-4a46-b5d1-7b9be09412e7", // Domain Experience / Expertise
  "c3a6c9ff-02d5-48f9-82ec-b75fbaf19eed", // Resources / Processes / Values
  "97894ea6-fd45-41cc-ad68-a7548b03ee60", // Alignment / Engagement
  "788d58d7-a946-4109-a7d8-e9b984001cbf", // Performance and/or Milestones
  "a7794e74-a93f-4dca-86af-1e09c8446bfc", // Market Positioning
  "5a0d0042-541b-40e3-8708-ba4f82020046", // Macro Perspective
  "5600da9c-cf55-4124-b637-4b3f1a327b3c", // Micro Perspective
  "3e7fe2bb-4ef1-4a73-9778-af39569b1414", // Financials
  "10b997e4-9064-4d15-8256-84e028dc5031", // Liquidity
  "c2ab60e0-b601-4624-9737-8dcbee2bf3e9", // Risk
  "fb97a2c7-b928-4201-ab47-a72a1e877110", // Scalability
  "9064634a-55e7-46cb-b513-dfa634a982d4", // Product Market Fit
  "86e596e7-497f-4d58-b6bb-cf6ebe060e57", // Growth Rate
  "12b72171-61bb-43d4-baf1-d2b72e8a8eb7", // Operating Leverage
  "a8f30084-cc19-4261-9df7-ae6a462de0dd", // Cash Flow
  "e1761ca3-e6c8-4e03-96bf-ce2f67b6023b", // Uniqueness of Asset
  "902eb787-0df0-44f6-92f2-523785d4897e", // Information Advantage
  "db4363be-b9ca-406d-b038-57846c336048", // Structure
  "2a88d825-ed5a-42f4-98cb-4f1ab4e8697f", // Take Out Opportunity
  "3c76d505-da61-4193-b948-9ab1db194305", // Return Distribution
  "a3c4850b-02e5-47e2-b518-79701c185c05", // Fiscal Year
  "61ceca63-53c0-4e83-81a0-6f21f3b37873", // What was prior year revenue?
  "310eff2b-8754-4c76-affe-f4dd48d73116", // What was prior year revenue run rate?
  "4f4a1281-4214-40a5-8ba6-a901e05cfa4d", // What was prior year cash flow/(burn)?
  "4a584586-8dac-4bbd-b9e4-3a83f2777d9d", // Forecast/Budget for Current Year Cash Flow / Burn
  "b1eb44c1-e2c4-4fb0-a8e1-f36e4a536c49", // Forecast/Budget for Current Year Revenue
  "9ff2f4d1-c1a0-4cac-8ed9-540fa69a9abe", // Forecast/Budget for Current Year Revenue Run Rate
  "9ba5e6fd-84f3-4323-98ba-2f305fc09059", // Was anything discussed that changed your perspective on this investment?
  "1e0ab572-c396-4c10-bdf6-5eee9968ea48", // Are due diligence and the round proceeding as planned?
  "3b9df28b-a992-4399-af79-92677f2e52fb", // Info Rights
  "5fa763a4-dd2b-473e-b10c-867969f66584", // Potential synergy with VSV
  "af815b86-6c9f-44f8-95da-98fc91e4f1d5", // Ops Group Engagement
  "a146d3d0-c2cb-453a-82b8-7f56eabc1e43", // Opportunity to add capital imminently (in the next 90 days)?
  "9d20d38f-7cbe-471c-8b76-9277ad260837", // Discussion of next round capital raise?
  "7c008f49-e4e5-428c-8161-67de47a84cbb", // What is the anticipated quarter of the next round?
  "36258310-f56b-4472-ac23-8ee5c1c92814", // Zoom Recording
];

const opsAttrs = [
  "d3575f17-9b62-47de-a48e-cd05c8fd0fa0",
  "6bcf77f7-1bcf-4134-be68-d521dd82d880",
  "44ccc65d-ed68-4db6-9b08-5a5ac295d0ef",
  "fedb71f6-dc36-4939-88dc-def87ff9134f",
  "8a1ce68e-ee02-4a31-aa60-0a7d6da7abba",
  "216fa233-b813-43e5-ab16-8a3d819272cb",
  "a15c0955-afdf-4301-94d2-0e801ca58b74",
  "be9188a6-290e-4b76-8683-9a60e21ec98c",
  "cbd76ff4-62d2-479f-ae58-9873bda29ec1",
  "253c90fd-eee9-4db0-8be1-882f27d7fca2",
  "1284ac88-8057-46bb-b0ab-cb9d8311dd39",
  "0a146208-99f4-4e97-b3bd-21edda96d165",
  "194a915c-bbec-40ce-a143-31df2ef0dbac",
  "7d5d22f9-77a7-40ef-9502-a47a2d116c5d",
  "08a647b8-4d7c-409f-abde-a3a04b7b0444",
  "62d350f1-e306-43dc-877c-3bbb6dc76900",
  "3399e8c0-6c4a-4497-a9e7-e9f7aa560bfe",
  "63e124b4-2726-4f29-9b30-933d9b184f1e",

  // delete ones that don't apply to ops

  "c41a30ee-b20e-4562-9b2d-5c5229356bfa", // Project Status
  "64e3dacd-6a0d-4f65-baee-817be3b115df", // Relationship
  "bd092e9e-807f-45af-8952-4728888b7615", // Skills / Traits / Attributes
  "c3a6c9ff-02d5-48f9-82ec-b75fbaf19eed", // Resources / Processes / Values
  "97894ea6-fd45-41cc-ad68-a7548b03ee60", // Alignment / Engagement
  "ef0eed17-570e-4532-8ad3-354608da0909", // Project Impact
  "788d58d7-a946-4109-a7d8-e9b984001cbf", // Performance and/or Milestones
  "902eb787-0df0-44f6-92f2-523785d4897e", // Information Advantage
  "9ba5e6fd-84f3-4323-98ba-2f305fc09059", // Was anything discussed that changed your perspective on this investment?
  "af815b86-6c9f-44f8-95da-98fc91e4f1d5", // Ops Group Engagement
  "9d20d38f-7cbe-471c-8b76-9277ad260837", // Discussion of next round capital raise?
  "bcf80750-f42d-4e88-930c-8e703aa0c797", // Any other comments?
];

const RecentSurveyCard = ({ survey, onDelete }) => {
	const theme = useTheme();
	return (
		<Paper
			elevation={1}
			sx={{
				p: 2,
				mb: 1,
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				borderLeft: `4px solid ${theme.palette.primary.main}`,
			}}
		>
			<Stack spacing={0.5}>
				<Typography variant="subtitle1">{survey.surveyName}</Typography>
				<Typography variant="body2" color="text.secondary">
					{survey.department}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					Completed at {survey.completedAt}
				</Typography>
			</Stack>
			<IconButton
				color="error"
				onClick={() => onDelete(survey.id)}
				sx={{ ml: 2 }}
			>
				<CloseIcon />
			</IconButton>
		</Paper>
	);
};

const SurveyResponse = ({ company }) => {
	const theme = useTheme();
	const { valorId } = company;
	const queryClient = useQueryClient();
	const [authorized, setAuthorized] = useState(true);
	const [authorizedIfTaken, setAuthorizedIfTaken] = useState(false);
	const [selectedSurveys, setSelectedSurveys] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [uniqueUsers, setUniqueUsers] = useState([]);
	const [uniqueSurveys, setUniqueSurveys] = useState([]);
	const [isLoadingData, setLoadingData] = useState(true);

	const [selectedProducts, setSelectedProducts] = useState([]);
	const [uniqueProducts, setUniqueProducts] = useState([]);
	const [showFilters, setShowFilters] = useState(false);
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const {
		user: { id: userId },
	} = useAuth();

	const TWENTY_FOUR_HOURS_AGO = dayjs().subtract(24, "hour");

	const authorizedIfTakenPerm = useCallback((perm) => {
		return (
			perm.permission === "canViewSurveyResultsIfTaken" &&
			perm.enabled &&
			!perm.access
		);
	}, []);
  const { data: prefilteredSections = [], isLoading: isLoadingSections } = useQuery(
    ["Sections"],
    async () => getAllSections({ isAAR: false }),
    {
      // refetchOnWindowFocus: false,
      // refetchOnReconnect: false,
      // refetchInterval: false,
    },
  );

  const selectedAttrIds = React.useMemo(() => {
    let attrsToInclude = [];
    if (
      selectedSurveys.some((survey) => !opsSurveysNames.includes(survey))
    ) {
      attrsToInclude = [...attrsToInclude, ...defaultAttrs];
    }
    if (selectedSurveys.some((survey) => opsSurveysNames.includes(survey))) {
      attrsToInclude = [...attrsToInclude, ...opsAttrs];
    }
    return uniq(attrsToInclude);
  }, [selectedSurveys]);

  const sections = React.useMemo(() => {
    // if selected surveys include an ops survey, include ops attributes
    const filteredSections = prefilteredSections.map((section) => ({
      ...section,
      attributes: section.attributes.filter((attr) => selectedAttrIds.includes(attr.id)),
    })).filter((section) => section.attributes.length > 0);
    return filteredSections;
  }, [prefilteredSections, selectedAttrIds]);


	const { data: drafts = [], isLoading: isLoadingDrafts } = useQuery(
		["DraftResponses", valorId],
		async () => getDrafts(valorId),
		{
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchInterval: false,
		},
	);

	const { data: availableSurveys = [], isLoading: isLoadingFrameworks } =
		useQuery(
			["FrameworkUniqueSurveys", valorId],
			async () => {
				const excludeVertical = "after_action_review";
				const response = await getAvailableFrameworkSurveys({
					valorId,
					excludeVertical,
				});
				if (
					response?.status === 404 ||
					response?.status === 422 ||
					response?.status === 403
				) {
					throw new Error(response.status);
				}
				return response;
			},
			{
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
				refetchInterval: false,
				retry: 1,
			},
		);

  const selectedSurveyIds = React.useMemo(() => selectedSurveys.flatMap((survey) => {
    const surveyIds = availableSurveys.filter((s) => s.name === survey).map((x) => x.id);
    return surveyIds;
  }), [selectedSurveys, availableSurveys]);

	const getUniqueSurveys = useCallback((responses) => {
		return _.chain(responses).filter(Boolean).map("name").uniq().value();
	}, []);

	const getUniqueUsers = useCallback((responses) => {
		return _.chain(responses)
			.filter(Boolean)
			.map((response) => ({
				id: response.user.id,
				name: response.user.name,
			}))
			.uniqBy("id")
			.value();
	}, []);

	const getUniqueProducts = useCallback((responses) => {
		return _.chain(responses).filter(Boolean).map("department").uniq().value();
	}, []);

	const {
		data: responses = { responses: [], hiddenResponses: [] },
		isLoading: isLoadingResponses,
		status,
	} = useQuery(
		["FrameworkSurveyResponses", valorId],
		async () => {
			const response = await getCompanyResponses({
				valorId,
			});
			if (response?.status === 404 || response?.status === 422) {
				throw new Error(response.status);
			}
			if (response?.status === 403) {
				setAuthorized(false);
				if (response.data?.detail.some(authorizedIfTakenPerm)) {
					setAuthorizedIfTaken(true);
				}
			}
			return response;
		},
		{
			retry: 1,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchInterval: false,
		},
	);

	useEffect(() => {
		if (responses.responses?.length) {
			setLoadingData(true);
			const users = getUniqueUsers(responses.responses);
			setSelectedUsers(users);
			setUniqueUsers(users);

			const products = getUniqueProducts(responses.responses);
			setSelectedProducts(products);
			setUniqueProducts(products);
			setLoadingData(false);
		}
	}, [
		responses.responses,
		isLoadingResponses,
		setSelectedProducts,
		setSelectedUsers,
		setUniqueUsers,
		setUniqueProducts,
		setLoadingData,
	]);

	useEffect(() => {
		if (availableSurveys.length === 0) {
			return;
		}
		setLoadingData(true);
		const surveyNames = getUniqueSurveys(
      availableSurveys.filter((s) => !opsSurveys.includes(s.id)),
    );
    setSelectedSurveys(surveyNames);
    const uniqueNames = getUniqueSurveys(availableSurveys);
    setUniqueSurveys(uniqueNames);
		setLoadingData(false);
	}, [availableSurveys, setSelectedSurveys, setUniqueSurveys, setLoadingData]);

	const memoizedResponses = useMemo(
		() =>
			responses?.responses?.filter(
				(r) =>
					selectedProducts.includes(r.department) &&
					selectedSurveys.includes(r.surveyName) &&
					selectedUsers.some((u) => u.id === r.user.id),
			),
		[selectedProducts, selectedSurveys, selectedUsers, responses],
	);
	const deleteableResponses = useMemo(() => {
		return responses?.responses
			?.filter(
				(r) =>
					r.user.id === userId &&
					dayjs.utc(r.completedAt).local() > TWENTY_FOUR_HOURS_AGO,
			)
			.map((x) => ({
				surveyName: x.surveyName,
				completedAt: dayjs.utc(x.completedAt).local().format("h:mm A"),
				id: x.id,
				department: InvestmentProductMappings[x.department],
			}));
	}, [responses, userId]);

	const hideSurveyResponse = async (responseId) => {
		await patchResponse(responseId, {
			hidden: true,
			status: "DELETED",
			isReleased: false,
		});
		queryClient.resetQueries(["FrameworkSurveyResponses", valorId], {
			exact: true,
		});
	};

	if (
		isLoadingFrameworks ||
		isLoadingSections ||
		isLoadingResponses ||
		isLoadingDrafts ||
		isLoadingData
	) {
		return (
			<Box>
				<Stack spacing={4} sx={{ py: 4 }}>
					<Section>
						<Section.Title>Survey Drafts</Section.Title>
						<Box
							sx={{
								display: "grid",
								gap: 2,
								gridTemplateColumns: {
									xs: "1fr",
									sm: "repeat(auto-fill, minmax(320px, 1fr))",
								},
							}}
						>
							{[1, 2].map((i) => (
								<Skeleton key={i} variant="rectangular" height={200} />
							))}
						</Box>
					</Section>

					<Section>
						<Section.Title>Survey Responses</Section.Title>
						<Stack spacing={2} sx={{ mt: 1 }}>
							<Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
								{[1, 2, 3].map((i) => (
									<Skeleton
										key={i}
										variant="rectangular"
										width={120}
										height={40}
									/>
								))}
							</Box>
							{[1, 2, 3].map((i) => (
								<Skeleton key={i} variant="rectangular" height={100} />
							))}
						</Stack>
					</Section>
				</Stack>
			</Box>
		);
	}

	if (!authorized && !authorizedIfTaken) {
		return (
			<Container maxWidth="sm" sx={{ py: 8 }}>
				<ErrorMessage
					Icon={<LockIcon sx={{ fontSize: 48 }} />}
					title="Unauthorized"
					message={
						<Typography variant="body1">
							You don't have access to view Survey Results. If you think this is
							an error, contact{" "}
							<Link href="mailto:labs@valorep.com?subject=Survey Permissions">
								labs@valorep.com
							</Link>
						</Typography>
					}
				/>
			</Container>
		);
	}

	return (
		<Box>
			<Stack spacing={4} sx={{ py: 4 }}>
				<Section>
					<Section.Title>Survey Drafts</Section.Title>
					<Box
						sx={{
							display: "flex",
							gap: 2,
						}}
					>
						{drafts?.length ? (
							<DraftGrid drafts={drafts} valorId={valorId} />
						) : null}
					</Box>
				</Section>

				<Section>
					<Section.Title>Survey Responses</Section.Title>
					<Stack spacing={3} sx={{ mt: 1 }}>
						{Boolean(deleteableResponses?.length) && (
							<Paper sx={{ p: 3 }}>
								<Typography variant="h6" gutterBottom>
									Your Recently Completed Surveys
								</Typography>
								<Typography variant="body2" color="text.secondary" paragraph>
									You can delete these surveys if you'd like to retake them.
								</Typography>
								<Stack spacing={2}>
									{deleteableResponses.map((response) => (
										<RecentSurveyCard
											key={response.id}
											survey={response}
											onDelete={hideSurveyResponse}
										/>
									))}
								</Stack>
							</Paper>
						)}

						{!isLoadingResponses &&
							responses?.responses?.length > 0 &&
							status === "success" && (
								<Stack spacing={2}>
									{isMobile ? (
										<Button
											variant="outlined"
											onClick={() => setShowFilters(!showFilters)}
										>
											{showFilters
												? "Hide Filters"
												: "Show Filters + Download Excel"}
										</Button>
									) : null}
									{!isMobile || showFilters ? (
										<Stack
											direction={{ xs: "column", md: "row" }}
											justifyContent="space-between"
											spacing={2}
										>
											<Stack
												direction={{ xs: "column", sm: "row" }}
												spacing={2}
												sx={{ flexGrow: 1 }}
											>
												<MultiSelect
													id="users-select"
													allValues={uniqueUsers}
													getKey={(user) => user.id}
													getLabel={(user) => user.name}
													value={selectedUsers}
													title="Users"
													onChange={setSelectedUsers}
													sx={{ minWidth: 200 }}
												/>
												<MultiSelect
													id="surveys-select"
													allValues={uniqueSurveys}
													value={selectedSurveys}
													title="Surveys"
													onChange={setSelectedSurveys}
													sx={{ minWidth: 200 }}
												/>
												<MultiSelect
													id="products-select"
													allValues={uniqueProducts}
													value={selectedProducts}
													title="Products"
													onChange={setSelectedProducts}
													sx={{ minWidth: 200 }}
												/>
											</Stack>

                      <Box
            display="flex"
            flexDirection="row"
            gap={1}
            mb={1}
          >
            <Button
              variant="outlined"
              onClick={() => {
                const surveyNames = getUniqueSurveys(
                  availableSurveys.filter((s) => !opsSurveys.includes(s.id)),
                );
                setSelectedSurveys(surveyNames);
              }}
            >
              View Investment Surveys
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                const surveyNames = getUniqueSurveys(
                  availableSurveys.filter((s) => opsSurveys.includes(s.id)),
                );
                setSelectedSurveys(surveyNames);
              }}
            >
              View Scale Group Surveys
            </Button>
          </Box>

											<Button
												variant="outlined"
                    onClick={() => {
                      getCompanyResponsesExcel({
                        valorId,
                        surveys: selectedSurveyIds,
                        attributes: selectedAttrIds,
                      });
                    }}
												disabled={responses?.responses?.length === 0}
												startIcon={<DownloadIcon />}
												sx={{ maxWidth: 200 }}
											>
												Download Excel
											</Button>
										</Stack>
									) : null}

									{status === "success" && (
										<FrameworkGrid
											sections={sections}
											status={status}
											responses={memoizedResponses}
										/>
									)}
								</Stack>
							)}
					</Stack>
				</Section>
			</Stack>
		</Box>
	);
};

export default SurveyResponse;
