import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import {
	CapitalMarketsActive,
	CapitalMarketsClosed,
	CompanyMeetingCall,
	DealEngagementStatus,
	LiquidityEventActive,
	LiquidityEventClosed,
	MergersAndAcquisitionActive,
	MergersAndAcquisitionClosed,
	Operations,
	Other,
	Research,
	ThesisFormulation,
} from "../meeting-types";

type MeetingTypeSelectProps = {
	value: string;
	onChange: (value: string) => void;
	disabled?: boolean;
};

export default function MeetingTypeSelect({
	value,
	onChange,
	disabled,
}: MeetingTypeSelectProps) {
	const meetingTypes = [
		{ label: CompanyMeetingCall, value: CompanyMeetingCall },
		{ label: ThesisFormulation, value: ThesisFormulation },
		{ label: DealEngagementStatus, value: DealEngagementStatus },
		{ label: CapitalMarketsActive, value: CapitalMarketsActive },
		{ label: CapitalMarketsClosed, value: CapitalMarketsClosed },
		{ label: LiquidityEventActive, value: LiquidityEventActive },
		{ label: LiquidityEventClosed, value: LiquidityEventClosed },
		{ label: MergersAndAcquisitionActive, value: MergersAndAcquisitionActive },
		{ label: MergersAndAcquisitionClosed, value: MergersAndAcquisitionClosed },
		{ label: Operations, value: Operations },
		{ label: Research, value: Research },
		{ label: Other, value: Other },
	];
	return (
		<FormControl fullWidth>
			<Select
				id="meeting-type-select"
				value={value}
				onChange={(e) => onChange(e)}
				disabled={disabled}
				data-cy="meeting-type-select"
			>
				{meetingTypes.map((meetingType) => (
					<MenuItem
						key={meetingType.value}
						value={meetingType.value}
						data-cy="meeting-type-select__option"
					>
						{meetingType.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
