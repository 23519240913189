import { getProcess, getProcessTasks } from "@/api/Process";
import Progress from "@/ui/atoms/Progress";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";

import BoardTask from "@/pages/ProcessManagement/ProcessView/BoardTask";

const PROJECT_STATUS_FIELD_ID = "bc4e201a-6897-4e06-8393-6cbf42e2354b";

function Chevron({ color, nextColor, children }) {
	const theme = useTheme();
	return (
		<Box
			color={theme.palette.getContrastText(color)}
			padding={1}
			position="relative"
			width="100%"
			sx={{
				// add triangle to left of box
				backgroundColor: color,
				"&::before": {
					content: "''",
					position: "absolute",
					top: 0,
					right: 0,
					width: 0,
					height: 0,
					borderTop: "20px solid transparent",
					borderBottom: "20px solid transparent",
					borderLeft: `20px solid ${nextColor}`,
					transform: "rotate(180deg)",
				},
			}}
		>
			<Typography variant="h3">{children}</Typography>
		</Box>
	);
}

const activeStatus = "d62d2c6b-0a67-4262-859e-8f37db3d9bcd";
const backlogStatus = "c1b58357-9cb8-44a4-a114-8c700c78d15d";
const oppDiscoveryStatus = "6cbd5352-68d3-425e-8df0-659ef7f3d14f";
const oppScopingStatus = "e6459b3a-036f-461c-aa9e-16e791d6a1aa";
const leadAlignmentStatus = "acae6845-020d-4e2a-a3c7-1846264f03dd";
const leadSubmissionStatus = "bda124c2-bf86-41a3-954b-0c7da0a65ffc";

export default function Pipeline() {
	const theme = useTheme();
	// pull data
	const statusFieldId = "bc4e201a-6897-4e06-8393-6cbf42e2354b";
	const nonCloseStatuses = [
		activeStatus,
		backlogStatus,
		oppScopingStatus,
		oppDiscoveryStatus,

		leadAlignmentStatus,
		leadSubmissionStatus,
	];

	const { data: taskData, isLoading } = useQuery(
		["leangrowth", "Pipeline"],
		async () =>
			getProcessTasks(
				["leangrowth"],
				200,
				null,
				null,
				{
					[`tasks.fieldValues.${statusFieldId}.choiceId`]: nonCloseStatuses,
				},
				{
					archived: false,
				},
			),
	);

	const { data: processData } = useQuery({
		queryKey: ["process", "leangrowth"],
		queryFn: () => getProcess("leangrowth"),
	});

	// group status
	const groupedStatuses = [
		[activeStatus], // active
		[backlogStatus], // backlog
		[
			// opportunity
			oppScopingStatus,
			oppDiscoveryStatus,
		],
		[
			// lead
			leadAlignmentStatus,
			leadSubmissionStatus,
		],
	];

	const groupedStatusNames = ["Active", "Backlog", "Opportunity", "Lead"];
	const groupedStatusColors = [
		theme.palette.blue.dark,
		theme.palette.blue.main,
		theme.palette.blue.light,
		theme.palette.blue.highlight,
	];

	// group tasks by single status
	const groupedTasks = taskData?.data?.reduce((acc, task) => {
		const status = task.fieldValues[statusFieldId]?.choiceId;
		if (!status) {
			return acc;
		}
		return {
			...acc,
			[status]: [...(acc[status] || []), task],
		};
	}, {});

	const statusLabels = processData?.fields.reduce((acc, field) => {
		if (field.id === statusFieldId) {
			return field.choices.reduce(
				(acc2, choice) => ({
					...acc2,
					[choice.id]: choice.value,
				}),
				{},
			);
		}
		return acc;
	}, {});

	if (isLoading) return <Progress />;

	return (
		<div>
			<Box
				maxHeight="100%"
				display="flex"
				flexDirection="row"
				sx={{
					overflowX: "auto",
				}}
			>
				{groupedStatusNames.map((statusName, i) => (
					<Box
						key={statusName}
						display="flex"
						flexDirection="column"
						sx={{
							backgroundColor: theme.palette.background.default,
						}}
					>
						<Chevron
							color={groupedStatusColors[i]}
							nextColor={
								groupedStatusColors[i + 1] || theme.palette.background.default
							}
						>
							{statusName}
						</Chevron>
						<Box display="flex" flexDirection="row" height="100%">
							{groupedStatuses[i].map((status) => (
								<Box
									key={status}
									borderRight={`1px solid ${theme.palette.background.default}`}
									minWidth={314}
									padding={1}
								>
									<Typography variant="boldBody1" sx={{ py: 1 }}>
										{statusLabels?.[status]}
									</Typography>
									<Box
										sx={{
											display: "grid",
											grid: "200px 200px 200px 200px / auto-flow",
											gridAutoFlow: "column",
											gap: 1,
											overflowX: "auto",
										}}
									>
										{groupedTasks?.[status]
											?.toSorted((a, b) => a.sortOrder - b.sortOrder)
											.map((task) => (
												<BoardTask
													key={task.id}
													task={task}
													fields={processData?.fields}
													groupedByFieldId={PROJECT_STATUS_FIELD_ID}
												/>
											))}
									</Box>
								</Box>
							))}
						</Box>
					</Box>
				))}
			</Box>
		</div>
	);
}
