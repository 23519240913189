import React from "react";
import type { AdvancedDescendant } from "../../types/RichText";

export default function renderLeaf(
	leaf: AdvancedDescendant,
	attributes: JSX.IntrinsicAttributes,
	child: string,
): JSX.Element {
	let modifiedChild: string | JSX.Element = child;

	if (leaf.bold) {
		modifiedChild = <strong>{modifiedChild}</strong>;
	}

	if (leaf.code) {
		modifiedChild = <code>{modifiedChild}</code>;
	}

	if (leaf.italic) {
		modifiedChild = <em>{modifiedChild}</em>;
	}

	if (leaf.underline) {
		modifiedChild = <u>{modifiedChild}</u>;
	}

	if (leaf.color) {
		modifiedChild = <span style={{ color: leaf.color }}>{modifiedChild}</span>;
	}

	if (leaf.backgroundColor) {
		modifiedChild = (
			<span style={{ backgroundColor: leaf.backgroundColor }}>
				{modifiedChild}
			</span>
		);
	}

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <span {...attributes}>{modifiedChild}</span>;
}
