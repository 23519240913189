import { getCompletionDetail } from "@/api/Chat";
import UserAvatar from "@/ui/atoms/UserAvatar";
import PreviewIcon from "@mui/icons-material/Preview";
import {
	Avatar,
	Box,
	Card,
	CardContent,
	Chip,
	CircularProgress,
	Grid,
	IconButton,
	Popover,
	Stack,
	Typography,
} from "@mui/material";
import dayjs from "dayjs";
import type React from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import CriteriaPreview from "../EvaluationCriteria/CriteriaPreview";

const Evaluation: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const [promptExpanded, setPromptExpanded] = useState(false);
	const [responseExpanded, setResponseExpanded] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [selectedCriteria, setSelectedCriteria] = useState<any>(null);

	const handlePreviewClick = (
		event: React.MouseEvent<HTMLButtonElement>,
		criteria: any,
	) => {
		setAnchorEl(event.currentTarget);
		setSelectedCriteria(criteria);
	};

	const {
		data: completion,
		isLoading,
		error,
	} = useQuery(["completion", id], () => getCompletionDetail(id!), {
		enabled: !!id,
	});

	if (isLoading) {
		return (
			<Box display="flex" justifyContent="center" padding={4}>
				<CircularProgress />
			</Box>
		);
	}

	if (error || !completion) {
		return (
			<Box padding={4}>
				<Typography color="error">Error loading completion details</Typography>
			</Box>
		);
	}
	const renderExpandableContent = (
		content: string,
		isExpanded: boolean,
		setExpanded: (value: boolean) => void,
	) => {
		const maxCharsToShow = 150; // Adjust this number to show more/less characters initially
		const truncatedContent =
			content.length > maxCharsToShow
				? `${content.substring(0, maxCharsToShow)}...`
				: content;

		return (
			<Box sx={{ whiteSpace: "pre-wrap", fontFamily: "monospace" }}>
				<Typography variant="body1">
					{isExpanded ? content : truncatedContent}
				</Typography>
				{content.length > maxCharsToShow && (
					<Box
						sx={{
							mt: 1,
							cursor: "pointer",
							color: "primary.main",
							"&:hover": { textDecoration: "underline" },
							fontSize: "0.875rem",
						}}
						onClick={() => setExpanded(!isExpanded)}
					>
						{isExpanded ? "Show Less" : "Show More"}
					</Box>
				)}
			</Box>
		);
	};

	return (
		<Box sx={{ padding: 3, maxWidth: "1200px", margin: "0 auto" }}>
			<Card>
				<CardContent>
					<Grid container spacing={3}>
						{/* Header Section */}
						<Grid item xs={12}>
							<Box
								sx={{
									borderColor: "primary.main",
									border: "1px solid",
									color: "primary.text.main",
									p: 3,
									borderRadius: 1,
									mb: 2,
								}}
							>
								<Stack spacing={2}>
									<Typography variant="h4">
										{completion.prompt.promptFamily.name}
									</Typography>
									<Box display="flex" alignItems="center" gap={2}>
										<UserAvatar user={completion.prompt.creator} />
										<Box>
											<Typography variant="subtitle2">
												Prompt Created by:{" "}
												{`${completion.prompt.creator.firstName} ${completion.prompt.creator.lastName}`}
											</Typography>
											<Typography variant="boldBody2">
												{`Evaluated at  ${dayjs(completion.createdAt).format(
													"YYYY-MM-DD HH:mm:ss",
												)}`}
											</Typography>
										</Box>
									</Box>
								</Stack>
							</Box>
						</Grid>

						{/* Model Information */}
						<Grid item xs={12} md={6}>
							<Typography variant="h3" gutterBottom>
								Model Details
							</Typography>
							<Box display="flex" gap={1} flexWrap="wrap">
								<Chip
									label={`Provider: ${completion.model.provider}`}
									color="primary"
								/>
								<Chip
									label={`Model: ${completion.model.name}`}
									color="primary"
								/>
								<Chip
									label={`Version: ${completion.model.version}`}
									color="primary"
								/>
							</Box>
						</Grid>

						{/* Performance Metrics */}
						<Grid item xs={12} md={6}>
							<Typography variant="h3" gutterBottom>
								Performance Metrics
							</Typography>
							<Box display="flex" gap={1} flexWrap="wrap">
								<Chip
									label={`Tokens Used: ${completion.tokensUsed}`}
									color="secondary"
								/>
								<Chip
									label={`Latency: ${completion.latencyMs}ms`}
									color="secondary"
								/>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h2" gutterBottom>
								Prompt
							</Typography>
							<Card variant="outlined" sx={{ mb: 3, p: 2 }}>
								<Typography variant="body1">
									{renderExpandableContent(
										completion.prompt.content,
										promptExpanded,
										setPromptExpanded,
									)}
								</Typography>
							</Card>

							<Typography variant="h2" gutterBottom>
								Response
							</Typography>
							<Card variant="outlined" sx={{ mb: 3, p: 2 }}>
								<Typography variant="body1">
									{renderExpandableContent(
										completion.response,
										responseExpanded,
										setResponseExpanded,
									)}
								</Typography>
							</Card>
						</Grid>

						<Popover
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={() => setAnchorEl(null)}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							transformOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
						>
							{selectedCriteria && (
								<Box sx={{ p: 2 }}>
									<CriteriaPreview
										description={selectedCriteria.description}
										scoringType={selectedCriteria.scoringType}
										minScore={selectedCriteria.minScore}
										maxScore={selectedCriteria.maxScore}
										categories={selectedCriteria.categories}
									/>
								</Box>
							)}
						</Popover>
						{/* Evaluation Results */}
						{completion.evaluations && completion.evaluations.length > 0 && (
							<Grid item xs={12}>
								<Typography variant="h2" gutterBottom>
									Evaluation Results
								</Typography>
								<Grid container spacing={2}>
									{completion.evaluations.map((evaluation) => (
										<Grid item xs={12} key={evaluation.id}>
											<Card
												variant="outlined"
												sx={{
													p: 2,
													"&:hover": {
														boxShadow: 3,
													},
												}}
											>
												<Box
													display="flex"
													justifyContent="flex-start"
													alignItems="center"
													border="1px solid"
													borderColor="primary.main"
													width="fit-content"
													borderRadius={2}
													p={1}
												>
													<Typography variant="h3">
														{evaluation.criteria.name}
													</Typography>
													<IconButton
														size="small"
														onClick={(e) =>
															handlePreviewClick(e, evaluation.criteria)
														}
													>
														<PreviewIcon />
													</IconButton>
												</Box>

												<Typography
													variant="body1"
													color="textSecondary"
													sx={{ mt: 1 }}
												>
													{evaluation.notes}
												</Typography>
											</Card>
										</Grid>
									))}
								</Grid>
							</Grid>
						)}
					</Grid>
				</CardContent>
			</Card>
		</Box>
	);
};

export default Evaluation;
