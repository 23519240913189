import {
	formatBigDollars,
	formatDollars,
	formatPercentage,
	formatShrunkTotal,
	formatTotal,
} from "./numberFormat";

// TODO rename this before merging @andy_dyer you clown.

// because useLocalStorageState does not serialize func for now
export const getFunc = {
	formatDollars,
	formatBigDollars,
	formatTotal,
	formatPercentage,
	formatShrunkTotal,
};

export default { getFunc };
