import TableReport from "@/pages/ProcessManagement/TableReport";
import { Box, Card } from "@mui/material";
import React from "react";

const RESEARCH_WEEKLY_IC_VIEW_ID = "e2482bc0-389f-402d-9e29-230555a9f3ab";

export function ResearchUpdates() {
	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<Card elevation={0} sx={{ height: "auto" }}>
				<TableReport processId="research" viewId={RESEARCH_WEEKLY_IC_VIEW_ID} />
			</Card>
		</Box>
	);
}
export default ResearchUpdates;
