import { Box } from "@mui/material";
import { styled } from "@mui/system";
import type React from "react";

interface Props {
	children: React.ReactNode[];
	isLast?: boolean;
}

const Cell = styled(Box, {
	shouldForwardProp: (prop) => prop !== "isLast" && prop !== "numChildren",
})(({ theme, isLast, numChildren }) => ({
	display: "grid",
	height: "100%",
	justifyItems: "center",
	gridGap: "8px",
	gridTemplateColumns: `repeat(auto-fit, minmax(calc(${
		100 / Math.ceil(Math.sqrt(numChildren))
	}% - 8px), 1fr) )`,
	gridTemplateRows: `repeat(auto-fit, minmax(calc(${
		100 / Math.ceil(Math.sqrt(numChildren))
	}% - 8px), 1fr) )`,
	padding: "8px",
	backgroundColor: theme.palette.background.paper,
	borderBottom: `1px solid ${theme.palette.divider}`,
	borderRight: `1px solid ${theme.palette.divider}`,
	borderBottomRight: isLast ? 1 : "inherit",
}));

export function MarketMapCell(props: Props) {
	const { isLast = false, children } = props;
	return (
		<Cell isLast={isLast} numChildren={children.length}>
			{children}
		</Cell>
	);
}

export default MarketMapCell;
