import {
	getTask,
	updateTask,
	updateTaskFieldValue as updateTaskFieldValueApi,
} from "@/api/Process";
import { useCallback } from "react";
import { useQuery, useQueryClient } from "react-query";

import { updateOrgFieldValue } from "@/api/Organization";
import isDateOnlyISOString from "@/utils/dates";

export default function useTask(processId, taskId) {
	const queryClient = useQueryClient();
	const {
		data: task,
		refetch,
		isLoading,
	} = useQuery(["task", processId, taskId], () => getTask(processId, taskId), {
		enabled: !!processId && !!taskId,
		refetchInterval: false,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		refetchIntervalInBackground: false,
	});

	const publishTask = useCallback(
		(targetId) => {
			// get task
			updateTask(processId, targetId.toString(), {
				published: true,
			});
		},
		[processId],
	);

	const getTaskFn = useCallback(
		async (pId: string, targetId: string, refresh = false) => {
			if (targetId === undefined) {
				throw new Error("Task Id is undefined");
			}

			if (targetId === task?.id.toString() && !refresh) {
				return task;
			}

			const result = await getTask(pId, targetId);
			if (targetId === task?.id.toString()) {
				queryClient.setQueryData(["task", pId, targetId], result);
			}
			return result;
		},
		[task, queryClient],
	);

	const toTypeFieldValue = (fieldType, fieldValue) => {
		if (fieldValue === null) {
			return null;
		}
		if (fieldType === "user_multi") {
			return fieldValue.map(({ id }) => ({ userId: id }));
		}
		if (fieldType === "person_multi") {
			return fieldValue.map(({ id }) => ({ personId: id }));
		}
		if (fieldType === "select_multi") {
			return fieldValue.map((choiceId) => ({ choiceId }));
		}
		if (fieldType === "date_time") {
			if (!fieldValue) {
				return [{ dateValue: null, dateTimeValue: null }];
			}
			if (isDateOnlyISOString(fieldValue)) {
				return [{ dateValue: fieldValue }];
			}
			return [{ dateTimeValue: fieldValue }];
		}
		return [
			{
				...(fieldType === "probability_equity_value_json" && {
					jsonValue: fieldValue,
				}),
				...(fieldType === "company" && { valorId: fieldValue.valorId }),
				...(fieldType === "user" && { userId: fieldValue.id }),
				...(fieldType === "person" && { personId: fieldValue.id }),
				...(fieldType === "select" && { choiceId: fieldValue }),
				...(["ryg", "text", "text_multi"].includes(fieldType) && {
					value: fieldValue,
				}),
				...(["number", "dollar"].includes(fieldType) && {
					numericValue: fieldValue === "" ? null : Number(fieldValue),
				}),
				...(["date", "target_date"].includes(fieldType) && {
					dateValue: fieldValue === "" ? null : fieldValue,
				}),
				...(fieldType === "checkbox" && {
					value: fieldValue ? "Yes" : "No",
				}),
			},
		];
	};
	const updateTaskFieldValue = useCallback(
		async (
			pId: string,
			targetId: string, // TODO update to default.
			fieldType: string,
			fieldId: string,
			settingId: string,
			value: any, // TODO type this
			isOrganizationField?: boolean,
			primaryValorId?: string,
		) => {
			const apiValue = toTypeFieldValue(fieldType, value);
			if (isOrganizationField && primaryValorId) {
				await updateOrgFieldValue(primaryValorId, settingId, {
					correlationId: crypto.randomUUID(),
					values: apiValue,
				});
			} else if (!isOrganizationField) {
				await updateTaskFieldValueApi(pId, targetId, fieldId, {
					correlationId: crypto.randomUUID(),
					values: apiValue,
				});
			} else {
				console.error(
					"Invalid field update, org field missing primary valor id.",
				);
			}
		},
		[],
	);

	return {
		data: {
			task,
		},
		actions: {
			refetch,
			getTask: getTaskFn,
			publishTask,
			updateTaskFieldValue,
		},
		isLoading,
	};
}
