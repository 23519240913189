import SearchBar from "@/ui/molecules/SearchBar";
import DownloadIcon from "@mui/icons-material/Download";
import Edit from "@mui/icons-material/Edit";
import ListIcon from "@mui/icons-material/List";
import {
	Box,
	ButtonBase,
	Hidden,
	IconButton,
	Menu,
	MenuItem,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { styled } from "@mui/system";

const IconButtonBase = styled(ButtonBase)(({ theme }) => ({
	height: "36px",
	marginLeft: theme.spacing(1),
	padding: theme.spacing(1),
	color: theme.palette.text.secondary,
	border: "1px solid rgb(0,0,0,0.38)",
	borderRadius: 1,
}));

export function MarketMapHeader(props) {
	const {
		title,
		isDraft,
		lastUpdated,
		editor,
		onSearch,
		onSelect,
		exportToExcel,
		exportAsPNG,
		onList,
		canEdit,
	} = props;
	const theme = useTheme();

	const navigate = useNavigate();
	const { id } = useParams();
	const bull = (
		<span
			style={{
				display: "inline-block",
				margin: "0 2px",
				transform: "scale(0.8)",
			}}
		>
			•
		</span>
	);

	const downloadMenuAnchorEl = React.useRef(null);
	const [downloadMenuOpen, setDownloadMenuOpen] = React.useState(false);

	const isSmDown = useMediaQuery((t) => t.breakpoints.down("sm"));

	if (isSmDown) {
		return (
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					padding: theme.spacing(3, 4),
					backgroundColor: theme.palette.background.paper,
					borderBottom: `1px solid ${theme.palette.divider}`,
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						marginBottom: theme.spacing(2),
					}}
				>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="flex-end"
					>
						<Typography
							variant="h3"
							sx={{
								color: theme.palette.text.secondary,
							}}
						>
							{title}
						</Typography>
						{isDraft && (
							<Typography
								variant="subtitle1"
								sx={{
									borderBottom: "3px solid red",
									color: "red",
									fontWeight: "900",
									marginLeft: theme.spacing(2),
								}}
							>
								<i>[ DRAFT ]</i>
							</Typography>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							color: theme.palette.text.secondary,
							marginTop: theme.spacing(1),
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
							}}
						>
							{lastUpdated && (
								<Typography variant="subtitle1">
									Published on {lastUpdated.format("MM/DD/YYYY")}
								</Typography>
							)}
							{lastUpdated && editor && bull}
							<Typography variant="subtitle1" flex={1}>
								{editor}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						width: "100%",
					}}
				>
					<SearchBar
						showSAYT
						search={onSearch}
						entity="company"
						selectTerm={onSelect}
						placeholder="Search in Map"
						sx={{ flex: 1, marginRight: theme.spacing(2) }}
					/>
					<Hidden xsDown>
						{onList && (
							<IconButton onClick={onList}>
								<ListIcon color="inherit" />
							</IconButton>
						)}
						<IconButton
							ref={downloadMenuAnchorEl}
							onClick={() => setDownloadMenuOpen(true)}
						>
							<DownloadIcon />
						</IconButton>
						<Menu
							id="downloadMenu"
							anchorEl={downloadMenuAnchorEl.current}
							open={downloadMenuOpen}
							onClose={() => setDownloadMenuOpen(false)}
						>
							<MenuItem
								onClick={() => {
									exportToExcel();
									setDownloadMenuOpen(false);
								}}
							>
								Export to Excel
							</MenuItem>
							<MenuItem
								onClick={() => {
									exportAsPNG();
									setDownloadMenuOpen(false);
								}}
							>
								Export to PNG
							</MenuItem>
						</Menu>
					</Hidden>
				</Box>
			</Box>
		);
	}

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				[theme.breakpoints.down("xs")]: {
					flexDirection: "column",
				},
				padding: theme.spacing(3, 4),
				backgroundColor: theme.palette.background.paper,
				borderBottom: `1px solid ${theme.palette.divider}`,
			}}
		>
			<Box display="flex">
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="flex-end"
					>
						<Typography
							variant="h3"
							sx={{
								color: theme.palette.text.secondary,
							}}
						>
							{title}
						</Typography>
						{isDraft && (
							<Typography
								variant="subtitle1"
								style={{
									borderBottom: "3px solid red",
									color: "red",
									fontWeight: "900",
									marginLeft: "16px",
								}}
							>
								<i>[ DRAFT ]</i>
							</Typography>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							color: theme.palette.text.secondary,
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							{lastUpdated && (
								<Typography variant="subtitle1">
									Published on {lastUpdated.format("MM/DD/YYYY")}
								</Typography>
							)}
							{lastUpdated && editor && bull}
							<Typography variant="subtitle1">{editor}</Typography>
						</Box>
					</Box>
				</Box>
			</Box>

			<Box
				sx={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<SearchBar
					showSAYT
					search={onSearch}
					entity="company"
					selectTerm={onSelect}
					placeholder="Search in Map"
				/>
				<Hidden xsDown>
					{onList && (
						<IconButtonBase onClick={onList}>
							<ListIcon color="inherit" />
						</IconButtonBase>
					)}
					{canEdit ? (
						<IconButtonBase onClick={() => navigate(`/market-maps/edit/${id}`)}>
							<Edit color="inherit" />
						</IconButtonBase>
					) : null}
					<IconButton
						ref={downloadMenuAnchorEl}
						onClick={() => setDownloadMenuOpen(true)}
					>
						<DownloadIcon />
					</IconButton>
					<Menu
						id="downloadMenu"
						anchorEl={downloadMenuAnchorEl.current}
						open={downloadMenuOpen}
						onClose={() => setDownloadMenuOpen(false)}
					>
						<MenuItem
							onClick={() => {
								exportToExcel();
								setDownloadMenuOpen(false);
							}}
						>
							Export to Excel
						</MenuItem>
						<MenuItem
							onClick={() => {
								exportAsPNG();
								setDownloadMenuOpen(false);
							}}
						>
							Export to PNG
						</MenuItem>
					</Menu>
				</Hidden>
			</Box>
		</Box>
	);
}

MarketMapHeader.propTypes = {
	title: PropTypes.string.isRequired,
	lastUpdated: PropTypes.instanceOf(dayjs),
	editor: PropTypes.string,
	onSearch: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	exportToExcel: PropTypes.func.isRequired,
	exportAsPNG: PropTypes.func.isRequired,
	onList: PropTypes.func,
};

MarketMapHeader.defaultProps = {
	lastUpdated: dayjs(),
	editor: null,
	onList: null,
};

export default MarketMapHeader;
