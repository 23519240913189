import { HeaderCellMenu } from "@/components/Table/Header/Cell";
import { ArrowDownward, ArrowUpward, FilterList } from "@mui/icons-material";
import {
	Alert,
	Badge,
	Box,
	Button,
	Checkbox,
	Divider,
	Popover,
	Tab,
	Tabs,
	TextField,
	Typography,
} from "@mui/material";
import React, { useMemo, useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import BooleanFilter from "@/components/Table/CustomFilters/Boolean";
import DateRange from "@/components/Table/CustomFilters/DateRange";
import NumberRange from "@/components/Table/CustomFilters/NumberRange";
import { SelectFilter } from "@/components/Table/CustomFilters/Select";
import Filter, { TextFilter } from "@/components/Table/Filter";
import { useAuth } from "@/hooks/useAuth";
import { useProcessData } from "../ProcessContext";

import {
	SORT_TYPE_MAP as SortTypeMap,
	FIELD_NAME_TYPE_MAP as fieldNameMap,
} from "../constants";

const FilterComponents = {
	text: TextFilter,
	date: DateRange,
	date_time: DateRange,
	number: NumberRange,
	dollar: NumberRange,
	ryg: (props) => (
		<SelectFilter
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			options={[
				{ value: "R", label: "R" },
				{ value: "Y", label: "Y" },
				{ value: "G", label: "G" },
			]}
			getOptionsLabel={(option) => option.label}
			getOptionsValue={(option) => option.value}
			getFilterCount={props.getFilterCount}
			multiple
		/>
	),
	// user: (props) => <UserFilter {...props} />,
	// user_multi: (props) => <UserFilter {...props} />,
	select: (props) => (
		<SelectFilter
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			options={props.getFacetedUniqueValues()?.map((choice) => ({
				value: choice.id,
				label: choice.value,
			}))}
			getOptionsLabel={(option) => option.label}
			getOptionsValue={(option) => option.value}
			multiple
		/>
	),
	select_multi: (props) => (
		<SelectFilter
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			options={props.getFacetedUniqueValues()?.map((choice) => ({
				value: choice.id,
				label: choice.value,
			}))}
			getOptionsLabel={(option) => option.label}
			getOptionsValue={(option) => option.value}
			multiple
		/>
	),
	boolean: BooleanFilter,
};

function buildKey(fieldId, fieldType) {
	if (fieldId === "dateAdded") return "dateAdded";
	return `tasks.fieldValues.${fieldId}.${fieldNameMap[fieldType]}`;
}

function JSONField({
	initialValue,
	onBlur,
}: {
	initialValue: any;
	onBlur: (value: any) => void;
}) {
	const [value, setValue] = React.useState(
		JSON.stringify(initialValue, null, 2),
	);
	React.useEffect(() => {
		setValue(JSON.stringify(initialValue, null, 2));
	}, [initialValue]);
	return (
		<TextField
			multiline
			fullWidth
			value={value}
			onChange={(event) => {
				setValue(event.target.value);
			}}
			onBlur={(event) => {
				// validate
				try {
					onBlur(JSON.parse(event.target.value));
				} catch (e) {
					// do nothing
				}
			}}
		/>
	);
}

function FilterMenu({
	field,
	getSortingValue,
	setSortingValue,
	getFilterValue,
	setFilterValue,
	getFacetedUniqueValues,
	component,
	header,
	disabled,
}: {
	field: any;
	getSortingValue: (sortField) => any;
	setSortingValue: (sortField, order) => void;
	getFilterValue: () => any;
	setFilterValue: (value) => void;
	getFacetedUniqueValues: () => any;
	component: React.ComponentType<any>;
	header: string;
	disabled: boolean;
}) {
	const menuRef = useRef(null);
	const [active, setActive] = React.useState(false);
	return (
		<Box
			ref={menuRef}
			sx={{
				userSelect: disabled && "none",
			}}
		>
			<HeaderCellMenu
				name={field.name}
				sortType={SortTypeMap[field.type]}
				canSort
				canFilter
				canHide={false}
				isSorted={getSortingValue(field)}
				onSortOptionClick={(order) => {
					// update sort option
					setSortingValue(field, order);
				}}
				onFilterOptionClick={() => {
					setActive(true);
				}}
			/>

			{menuRef.current && active && (
				<Popover
					id="header-cell-filter"
					open
					anchorEl={menuRef.current}
					onClose={() => setActive(false)}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
				>
					<Box display="flex" flexDirection="column">
						<Typography variant="body2" sx={{ padding: 1 }}>
							{field.name}
						</Typography>
						<Divider />
						<Filter
							component={component}
							header={header}
							getFilterValue={getFilterValue}
							setFilterValue={setFilterValue}
							getFacetedUniqueValues={getFacetedUniqueValues}
						/>
					</Box>
				</Popover>
			)}
		</Box>
	);
}

export default function ViewForm({
	initialValue,
	disabled,
	onSubmit,
	onCancel,
}: {
	initialValue?: {
		name: string;
		columnVisibility: Record<string, boolean>;
		columnOrder: string[];
		sorting: Record<string, "asc" | "desc">;
		filters: Record<string, string>;
	};
	disabled?: boolean;
	onSubmit: (value: any) => void;
	onCancel: () => void;
}) {
	const { fields } = useProcessData();

	const [mask, setMask] = React.useState({});
	const computedValue = useMemo(() => {
		const currentValue = {
			name: "",
			columnVisibility: fields.reduce(
				(acc, field) => ({
					...acc,
					[buildKey(field.id, field.type)]: true,
				}),
				{},
			),
			columnOrder: [],
			sorting: [],
			columnFilters: {
				AND: {
					eq: {},
				},
			},
			...initialValue,
			...mask,
		};
		return {
			...currentValue,
			columnVisibility: currentValue.columnVisibility || {},
			columnOrder: currentValue.columnOrder || [],
			sorting: currentValue.sorting || [],
			columnFilters: currentValue.columnFilters || {},
		};
	}, [initialValue, mask, fields]);

	const sortedFields = useMemo(
		() => [
			...fields.toSorted((a, b) => {
				const aIndex = computedValue.columnOrder?.indexOf(
					buildKey(a.id, a.type),
				);
				const bIndex = computedValue.columnOrder?.indexOf(
					buildKey(b.id, b.type),
				);

				if (aIndex === -1 && bIndex === -1) return a.sort - b.sort;
				if (aIndex === -1) return 1;
				if (bIndex === -1) return -1;

				return aIndex - bIndex;
			}),
			{
				id: "dateAdded",
				name: "Date Added",
				type: "date",
			},
		],
		[fields, computedValue.columnOrder],
	);

	const isVisible = (visibleField): boolean => {
		// pull id out of name
		const key = buildKey(visibleField.id, visibleField.type);
		return computedValue.columnVisibility[key] === undefined
			? false
			: computedValue.columnVisibility[key];
	};

	const getSortingIndex = (sortField) => {
		const sort = computedValue.sorting.findIndex(
			({ id }) => id === buildKey(sortField.id, sortField.type),
		);
		return sort;
	};

	const getSorting = (sortField) => {
		const sort = computedValue.sorting.find(
			({ id }) => id === buildKey(sortField.id, sortField.type),
		);
		if (sort?.desc === true) return "desc";
		if (sort?.desc === false) return "asc";
		return false;
	};

	const getColumnFilter = (columnField) => {
		const filters = computedValue.columnFilters?.AND;
		const eqFilters = filters?.eq;

		const rangeFields = [
			"number",
			"dollar",
			"date",
			"target_date",
			"date_time",
		];
		if (rangeFields.includes(columnField.type)) {
			const gteFilters = filters?.gte || {};
			const lteFilters = filters?.lte || {};

			const key = buildKey(columnField.id, columnField.type);
			if (gteFilters[key] !== undefined || lteFilters[key] !== undefined) {
				return {
					min: gteFilters[key],
					max: lteFilters[key],
				};
			}
		} else {
			if (!eqFilters) return false;

			const result = eqFilters[buildKey(columnField.id, columnField.type)];
			if (Array.isArray(result)) {
				return result?.map((value) => ({
					value,
				}));
			}
			return result;
		}

		return null;
	};

	const setSorting = (sortField, order) => {
		const otherSorts = computedValue.sorting.filter(
			(sort) => sort.id !== buildKey(sortField.id, sortField.type),
		);
		if (order === false) {
			setMask({
				...mask,
				sorting: otherSorts,
			});
		} else {
			setMask({
				...mask,
				sorting: [
					...otherSorts,
					{
						id: buildKey(sortField.id, sortField.type),
						desc: order === "desc",
					},
				],
			});
		}
	};

	const setColumnFilter = (fieldId, fieldType, filterValue) => {
		const filters = computedValue.columnFilters?.AND;
		const eqFilters = filters?.eq || {};
		const gteFilters = filters?.gte || {};
		const lteFilters = filters?.lte || {};

		const rangeFields = [
			"number",
			"dollar",
			"date",
			"target_date",
			"date_time",
		];
		if (rangeFields.includes(fieldType)) {
			const { min, max } = filterValue;
			const key = buildKey(fieldId, fieldType);

			const newGteFilters = { ...gteFilters };
			const newLteFilters = { ...lteFilters };
			if (min != null) {
				newGteFilters[key] = min;
			}
			if (max != null) {
				newLteFilters[key] = max;
			}
			setMask({
				...mask,
				columnFilters: {
					AND: {
						...filters,
						gte: newGteFilters,
						lte: newLteFilters,
					},
				},
			});
		} else {
			const key = buildKey(fieldId, fieldType);
			setMask({
				...mask,
				columnFilters: {
					...(computedValue.columnFilters || {}),
					AND: {
						...filters,
						eq: {
							...eqFilters,
							[key]: Array.isArray(filterValue)
								? filterValue
										.map((value) => {
											if (typeof value === "object") {
												return value.value;
											}
											return value;
										})
										.filter(Boolean)
								: filterValue,
						},
					},
				},
			});
		}
	};

	const [editingJSON, setEditingJSON] = React.useState(false);
	const [activeTab, setActiveTab] = React.useState(0);

	function discardChanges() {
		onCancel();
	}

	const [formError, setFormError] = React.useState(null);
	function saveChanges() {
		// validate a name is present
		if (!computedValue.name.trim()) {
			setFormError("Name is required");
			return;
		}
		setFormError(null);
		onSubmit(computedValue);
		// onDirty(false);
		onCancel();
	}

	function selectAll() {
		setMask({
			...mask,
			columnVisibility: sortedFields.reduce(
				(acc, field) => ({
					...acc,
					[buildKey(field.id, field.type)]: true,
				}),
				{},
			),
		});
	}

	function selectNone() {
		setMask({
			...mask,
			columnVisibility: sortedFields.reduce(
				(acc, field) => ({
					...acc,
					[buildKey(field.id, field.type)]: false,
				}),
				{},
			),
		});
	}

	const { user: currentUser } = useAuth();

	return (
		<Box>
			<Box>
				{formError && <Alert severity="error">{formError}</Alert>}

				<Box py={1} display="flex" gap={1}>
					<Button
						variant="outlined"
						onClick={() => discardChanges()}
						disabled={disabled}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						onClick={() => saveChanges()}
						disabled={disabled}
					>
						Save Changes
					</Button>
				</Box>

				<Divider />

				{[1, 18].includes(currentUser.roleId) && (
					<Button
						onClick={() => {
							setEditingJSON((prev) => !prev);
						}}
					>
						Edit As JSON
					</Button>
				)}

				{editingJSON && (
					<Box>
						<Tabs
							value={activeTab}
							onChange={(event, newValue) => {
								setActiveTab(newValue);
							}}
						>
							<Tab label="Sorting" />
							<Tab label="Filters" />
							<Tab label="Order" />
							<Tab label="Visibility" />
						</Tabs>

						{activeTab === 0 && (
							<JSONField
								initialValue={computedValue.sorting}
								onBlur={(value) => {
									setMask((prev) => ({
										...prev,
										sorting: value,
									}));
								}}
							/>
						)}

						{activeTab === 1 && (
							<JSONField
								initialValue={computedValue.columnFilters}
								onBlur={(value) => {
									setMask((prev) => ({
										...prev,
										columnFilters: value,
									}));
								}}
							/>
						)}

						{activeTab === 2 && (
							<JSONField
								initialValue={computedValue.columnOrder}
								onBlur={(value) => {
									setMask((prev) => ({
										...prev,
										columnOrder: value,
									}));
								}}
							/>
						)}

						{activeTab === 3 && (
							<JSONField
								initialValue={computedValue.columnVisibility}
								onBlur={(value) => {
									setMask((prev) => ({
										...prev,
										columnVisibility: value,
									}));
								}}
							/>
						)}
					</Box>
				)}
			</Box>

			{!editingJSON && (
				<TextField
					value={computedValue.name}
					onChange={(e) => {
						setMask({
							...mask,
							name: e.target.value,
						});
					}}
					fullWidth
					label="View Name"
					disabled={disabled}
				/>
			)}

			<Box display="flex" gap={1} py={1}>
				<Button
					onClick={() => selectAll()}
					disabled={disabled}
					variant="outlined"
					size="small"
				>
					Show All
				</Button>
				<Button
					onClick={() => selectNone()}
					disabled={disabled}
					variant="outlined"
					size="small"
				>
					Hide All
				</Button>
			</Box>

			{!editingJSON && (
				<DragDropContext
					onDragEnd={async (dragEvent) => {
						const { source, destination } = dragEvent;
						if (!destination) return;

						const newFields = [...sortedFields];
						const [removed] = newFields.splice(source.index, 1);
						newFields.splice(destination.index, 0, removed);

						setMask({
							...mask,
							columnOrder: newFields.map((field) =>
								buildKey(field.id, field.type),
							),
						});
					}}
				>
					<Droppable droppableId="fields">
						{(dropProvided) => (
							<Box
								ref={dropProvided.innerRef}
								/* eslint-disable-next-line react/jsx-props-no-spreading */
								{...dropProvided.droppableProps}
							>
								{sortedFields.map((field, index) => (
									<Draggable
										key={field.id}
										draggableId={field.id}
										index={index}
										isDragDisabled={disabled}
									>
										{(dragProvided) => (
											<Box
												ref={dragProvided.innerRef}
												/* eslint-disable-next-line react/jsx-props-no-spreading */
												{...dragProvided.draggableProps}
												/* eslint-disable-next-line react/jsx-props-no-spreading */
												{...dragProvided.dragHandleProps}
												sx={{
													height: 40,
													display: "flex",
													alignItems: "center",
													justifyContent: "space-between",
												}}
											>
												<Box display="flex" alignItems="center" gap={1}>
													<Checkbox
														checked={isVisible(field)}
														onChange={(e) => {
															const { checked } = e.target;
															setMask({
																...mask,
																columnVisibility: {
																	...(computedValue.columnVisibility || {}),
																	[buildKey(field.id, field.type)]: checked,
																},
															});
														}}
														disabled={disabled}
													/>
													<Typography
														sx={{
															// width: "200px",
															display: "flex",
															alignItems: "center",
														}}
													>
														{field.name}
														{getSorting(field) === "desc" && (
															<Badge
																badgeContent={getSortingIndex(field) + 1}
																anchorOrigin={{
																	vertical: "bottom",
																	horizontal: "right",
																}}
															>
																<ArrowDownward fontSize="small" />
															</Badge>
														)}
														{getSorting(field) === "asc" && (
															<Badge
																badgeContent={getSortingIndex(field) + 1}
																anchorOrigin={{
																	vertical: "bottom",
																	horizontal: "right",
																}}
															>
																<ArrowUpward fontSize="small" />
															</Badge>
														)}
														{getColumnFilter(field) && (
															<FilterList fontSize="small" />
														)}
													</Typography>
												</Box>
												<FilterMenu
													field={field}
													getSortingValue={getSorting}
													setSortingValue={setSorting}
													getFilterValue={() => getColumnFilter(field)}
													setFilterValue={(value) =>
														setColumnFilter(field.id, field.type, value)
													}
													getFacetedUniqueValues={() => field.choices}
													component={FilterComponents[field.type]}
													header={field.name}
													disabled={disabled}
												/>
											</Box>
										)}
									</Draggable>
								))}
								{dropProvided.placeholder}
							</Box>
						)}
					</Droppable>
				</DragDropContext>
			)}
		</Box>
	);
}
