import type { TaskList } from "@/api/Process";
import useLocalStorageState from "@/hooks/useLocalStorageState";
import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableBoardTask from "./DraggableBoardTask";
import HiddenTasksInfo from "./HiddenTasksInfo";
import { onDragEndHandler } from "./ViewUtils";

type BoardViewProps = {
	processId: string;
	isLoading: boolean;
	groupedBy: string;
	groupedTasks: {
		group: string;
		choiceId: string;
		taskList: TaskList["tasks"];
	}[];
	fields: TaskList["fields"];
	fieldFilters: TaskList["fieldFilters"];
	showTask: (processId: string, taskId: string) => void;
	updateTaskGroup: (taskId: string, group: string, sortOrder: number) => void;
	entityName?: string;
};

export default function BoardView({
	processId,
	isLoading,
	groupedBy,
	groupedTasks,
	fields,
	fieldFilters,
	showTask,
	updateTaskGroup,
	entityName,
}: BoardViewProps) {
	const [columnCollapseState, setColumnCollapseState] = useLocalStorageState<{
		[key: string]: boolean;
	}>("columnCollapseState", {});

	const onDragEnd = onDragEndHandler(updateTaskGroup, groupedTasks);

	if (isLoading) {
		return (
			<Box display="flex" flexDirection="row" gap={1} width="100%">
				<Box display="flex" flexDirection="column" gap={1} flexGrow={1}>
					<Skeleton variant="text" width="60%" height={30} />
					<Skeleton variant="rectangular" width="100%" height={100} />
					<Skeleton variant="rectangular" width="100%" height={100} />
				</Box>

				<Box display="flex" flexDirection="column" gap={1} flexGrow={1}>
					<Skeleton variant="text" width="60%" height={30} />
					<Skeleton variant="rectangular" width="100%" height={100} />
					<Skeleton variant="rectangular" width="100%" height={100} />
					<Skeleton variant="rectangular" width="100%" height={100} />
					<Skeleton variant="rectangular" width="100%" height={100} />
				</Box>

				<Box display="flex" flexDirection="column" gap={1} flexGrow={1}>
					<Skeleton variant="text" width="60%" height={30} />
					<Skeleton variant="rectangular" width="100%" height={100} />
				</Box>

				<Box display="flex" flexDirection="column" gap={1} flexGrow={1}>
					<Skeleton variant="text" width="60%" height={30} />
					<Skeleton variant="rectangular" width="100%" height={100} />
					<Skeleton variant="rectangular" width="100%" height={100} />
					<Skeleton variant="rectangular" width="100%" height={100} />
				</Box>
			</Box>
		);
	}

	const columnWidth = 320;
	const columnHeaderHeight = 60;
	const columnGap = 8;
	const parentWidth = `${groupedTasks.length * (columnWidth + columnGap)}px`;

	const bottomMargin = 20;

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<HiddenTasksInfo
				currentGroup={groupedBy}
				fields={fields}
				fieldFilters={fieldFilters}
				entityName={entityName}
			/>
			<Box width="100%" overflow="auto" data-cy="process-board-view">
				<Box display="flex" gap={1} width={parentWidth}>
					{groupedTasks?.map(({ group, choiceId, taskList }) => {
						const columnId = choiceId;
						return (
							<Box
								display="flex"
								flexDirection="column"
								gap={1}
								position="relative"
								width={!columnCollapseState[columnId] ? columnWidth : "60px"}
								height="calc(100vh - 262px)"
								key={group}
							>
								<Box
									sx={{
										cursor: "pointer",
										position: "absolute",
										transformOrigin: "top left",
										transform: !columnCollapseState[columnId]
											? "rotate(0deg)"
											: "rotate(90deg) translate(0px, -100%)",
										fontSize: (theme) => theme.typography.pxToRem(16),
										fontWeight: (theme) => theme.typography.fontWeightBold,
										width: columnWidth,
										height: columnHeaderHeight,
										lineHeight: "22px",
										lineClamp: 2,
										whiteSpace: "wrap",
										textTransform: "uppercase",
										padding: 1,
										borderRadius: 1,
										"&:hover": {
											backgroundColor: (theme) => theme.palette.action.hover,
										},
									}}
									onClick={() => {
										setColumnCollapseState({
											...columnCollapseState,
											[columnId]: !columnCollapseState[columnId],
										});
									}}
								>
									<Typography variant="boldBody1">
										{group} - {taskList.length}
									</Typography>
								</Box>
								{!columnCollapseState[columnId] && (
									<Droppable droppableId={group}>
										{(provided, dropSnapshot) => (
											<div
												ref={provided.innerRef}
												// eslint-disable-next-line react/jsx-props-no-spreading
												{...provided.droppableProps}
												style={{
													display: "flex",
													flexDirection: "column",
													flexGrow: 1,
												}}
											>
												<Box
													padding="4px"
													gap={1}
													position="absolute"
													top={columnHeaderHeight + columnGap}
													bottom={bottomMargin}
													width={columnWidth}
													borderTop={(theme) =>
														`1px solid ${theme.palette.divider}`
													}
													borderBottom={(theme) =>
														`1px solid ${theme.palette.divider}`
													}
													sx={{
														overflowY: "auto",
														overflowX: "hidden",
														backgroundColor: (theme) =>
															dropSnapshot.isDraggingOver
																? theme.palette.background.default
																: theme.palette.background.paper,
														"&::-webkit-scrollbar": {
															width: 0,
														},
														"& > *": {
															mb: 1,
														},
													}}
													data-cy={`process__${processId}__board__${group.toLowerCase().replace(" ", "-")}`}
												>
													{taskList
														.toSorted((a, b) => a.sortOrder - b.sortOrder)
														.map((task, i) => (
															<DraggableBoardTask
																key={task.id}
																task={task}
																index={i}
																processId={processId}
																groupedByFieldId={groupedBy}
																fields={fields}
																onClick={() => showTask(processId, task.id)}
																onMoveToStart={() => {
																	// get the first task in the list
																	const firstTask = taskList.toSorted(
																		(a, b) => a.sortOrder - b.sortOrder,
																	)[0];
																	if (firstTask) {
																		updateTaskGroup(
																			task.id.toString(),
																			groupedBy,
																			firstTask.sortOrder - 1,
																		);
																	} else {
																		updateTaskGroup(
																			task.id.toString(),
																			group,
																			0,
																		);
																	}
																}}
																onMoveToEnd={() => {
																	// get the last task in the list
																	const lastTask = taskList.toSorted(
																		(a, b) => a.sortOrder - b.sortOrder,
																	)[taskList.length - 1];
																	if (lastTask) {
																		updateTaskGroup(
																			task.id.toString(),
																			groupedBy,
																			lastTask.sortOrder + 1,
																		);
																	} else {
																		updateTaskGroup(
																			task.id.toString(),
																			group,
																			0,
																		);
																	}
																}}
															/>
														))}
													{provided.placeholder}
												</Box>
											</div>
										)}
									</Droppable>
								)}
							</Box>
						);
					})}
				</Box>
			</Box>
		</DragDropContext>
	);
}
