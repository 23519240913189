import { Description } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";

import type { FileContextsProps, PastedContent } from "./utils/ChatState";

interface ContextBoxProps {
	item: File | PastedContent;
	onRemove: (id: string) => void;
	type: "FILE" | "PASTED";
}

function isFile(item: File | PastedContent): item is File {
	return (item as File).name !== undefined;
}

function ContextBox({ item, onRemove, type }: ContextBoxProps) {
	return (
		<Box
			key={item.id}
			sx={{
				backgroundColor: "background.paper",
				borderRadius: 2,
				border: "1px solid",
				borderColor: "divider",
				p: 2,
				width: 120,
				height: 120,
				position: "relative",
			}}
		>
			<IconButton
				size="small"
				onClick={() => onRemove(item.id)}
				sx={{
					position: "absolute",
					top: -8,
					left: -8,
					backgroundColor: "grey.300",
					"&:hover": {
						backgroundColor: "grey.400",
					},
					p: 0.5,
				}}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
			<Typography
				variant="gigaSmall"
				color="primary.main"
				sx={{
					wordBreak: "break-word",
					overflow: "hidden",
					display: "-webkit-box",
					WebkitBoxOrient: "vertical",
					WebkitLineClamp: 5,
					mt: 0,
					mb: 0,
				}}
			>
				{isFile(item) ? item.name : item.content}
			</Typography>
			<Box
				sx={{
					position: "absolute",
					bottom: -16,
					left: 0,
					right: 0,
					display: "flex",
					justifyContent: "center",
					pb: 1,
				}}
			>
				<Typography
					variant="caption"
					sx={{
						backgroundColor: "grey.300",
						px: 1,
						py: 0.5,
						borderRadius: 1,
						textAlign: "center",
					}}
				>
					{type}
					{item?.tokenCount && ` ${item.tokenCount.toLocaleString()} tokens`}
				</Typography>
			</Box>
		</Box>
	);
}

export function PasteAndFileContexts({
	files,
	pastedContents,
	onRemoveFile,
	onRemovePastedContent,
}: FileContextsProps) {
	const combinedItems = [...files, ...pastedContents].sort(
		(a, b) => a.timestamp - b.timestamp,
	);

	if (combinedItems.length === 0) return null;

	return (
		<Box
			sx={{
				p: 2,
				bgcolor: "background.paper",
				borderRadius: 1,
				border: (theme) => `1px solid ${theme.palette.divider}`,
				width: "100%",
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: 1,
					mb: 1,
				}}
			>
				<Description />
				<Typography fontWeight="bold">File and Pasted Contexts</Typography>
			</Box>
			<Stack direction="row" spacing={1} flexWrap="wrap" sx={{ gap: 1 }}>
				{combinedItems.map((item) =>
					isFile(item) ? (
						<ContextBox
							key={item.id}
							item={item}
							onRemove={onRemoveFile}
							type="FILE"
						/>
					) : (
						<ContextBox
							key={item.id}
							item={item}
							onRemove={onRemovePastedContent}
							type="PASTED"
						/>
					),
				)}
			</Stack>
		</Box>
	);
}

export default PasteAndFileContexts;
