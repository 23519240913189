import SectorMapping from "@/components/SectorChip/SectorMapping";
import { SelectFilter, uniqFlatSort } from "@/components/Table/CustomFilters";
import Progress from "@/ui/atoms/Progress";
import { Box, Typography, useTheme } from "@mui/material";
import type { Column } from "@tanstack/react-table";
import React from "react";

import { getAllSectors } from "@/api/Organization";
import { useQuery } from "react-query";

type SectorsFilterProps = {
	column: Column<unknown>;
};

export default function SectorsFilter({ column }: SectorsFilterProps) {
	const { data, isLoading } = useQuery("sectors", getAllSectors);
	const theme = useTheme();

	const options = data?.data.map((sector) => ({
		value: sector,
		label: sector,
	}));
	if (isLoading) {
		return (
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				width={theme.spacing(30)}
				height={theme.spacing(10)}
			>
				<Progress />
			</Box>
		);
	}

	return (
		<SelectFilter
			getFilterValue={() => column.getFilterValue()}
			setFilterValue={(value) => column.setFilterValue(value)}
			getFacetedUniqueValues={() => column.getFacetedUniqueValues()}
			multiple
			options={options}
			getOptionsValue={(option) => option?.value}
			getOptionsLabel={(option) => {
				if (!SectorMapping[option.value]) {
					return option.label;
				}
				const { Icon, label } = SectorMapping[option.value];
				return (
					<Box display="flex" gap={1}>
						<Icon />
						<Typography variant="body2">{label}</Typography>
					</Box>
				);
			}}
			filterOptions={(opts) => uniqFlatSort(opts)}
		/>
	);
}
