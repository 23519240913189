import { createPromptFamily } from "@/api/Chat";
import {
	Alert,
	Box,
	Button,
	Container,
	Paper,
	Snackbar,
	TextField,
	Typography,
} from "@mui/material";
import type React from "react";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

interface PromptFamilyForm {
	name: string;
	description: string;
}

function AddPromptFamily() {
	const queryClient = useQueryClient();
	const [formData, setFormData] = useState<PromptFamilyForm>({
		name: "",
		description: "",
	});
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const createPromptFamilyMutation = useMutation(createPromptFamily, {
		onSuccess: () => {
			queryClient.invalidateQueries("promptFamilies");
			setOpenSnackbar(true);
			// Reset form
			setFormData({
				name: "",
				description: "",
			});
		},
	});

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		createPromptFamilyMutation.mutate(formData);
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	return (
		<Container maxWidth="lg">
			<Paper elevation={3} sx={{ p: 4, mt: 4 }}>
				<Typography variant="h5" component="h1" gutterBottom>
					Create New Prompt Family
				</Typography>

				<Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
					<TextField
						fullWidth
						label="Name"
						name="name"
						value={formData.name}
						onChange={handleInputChange}
						margin="normal"
						required
					/>

					<TextField
						fullWidth
						label="Description"
						name="description"
						value={formData.description}
						onChange={handleInputChange}
						margin="normal"
						required
						multiline
						rows={4}
					/>

					<Button
						type="submit"
						variant="contained"
						fullWidth
						sx={{ mt: 3 }}
						disabled={createPromptFamilyMutation.isLoading}
					>
						{createPromptFamilyMutation.isLoading
							? "Creating..."
							: "Create Prompt Family"}
					</Button>

					{createPromptFamilyMutation.isError && (
						<Alert severity="error" sx={{ mt: 2 }}>
							Error creating prompt family:{" "}
							{(createPromptFamilyMutation.error as Error).message}
						</Alert>
					)}
				</Box>
			</Paper>

			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert severity="success" sx={{ width: "100%" }}>
					Prompt family created successfully!
				</Alert>
			</Snackbar>
		</Container>
	);
}

export default AddPromptFamily;
