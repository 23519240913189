import DayUtils from "@date-io/dayjs";
import {
	Box,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import EventIcon from "@material-ui/icons/Event";
import RemoveIcon from "@material-ui/icons/HighlightOff";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import SnoozeIcon from "@material-ui/icons/Snooze";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
	menuItem: {
		width: "280px",
		padding: theme.spacing(2, 1),
	},
	menuIcon: {
		color: theme.palette.text.secondary,
	},
	menuText: {
		paddingLeft: theme.spacing(2),
	},
	date: {
		paddingLeft: theme.spacing(2),
		color: theme.palette.text.secondary,
		fontSize: "14px",
		textAlign: "right",
	},
}));

export default function SnoozeMenu({
	onUnfollow,
	onSnooze,
	displayRemove,
	snoozedUntil,
}) {
	const classes = useStyles();
	const isXsDown = useMediaQuery((theme) => theme.breakpoints.down("xs"));
	const theme = useTheme();
	const selectedDate = useState(new Date())[0];

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorEl(null);
	};

	const handleSnooze = (date) => {
		onSnooze(date);
		closeMenu();
	};

	const handleUnfollow = () => {
		onUnfollow();
		closeMenu();
	};

	const periodOption = ({ title, date }) => (
		<MenuItem
			className={classes.menuItem}
			onClick={() => handleSnooze(date.format("YYYY-MM-DD"))}
		>
			<Grid container justifyContent="space-around">
				<Grid item>{title}</Grid>
				<Grid item className={classes.date}>
					{date.format("MMM DD, YYYY")}
				</Grid>
			</Grid>
		</MenuItem>
	);

	return (
		<>
			{snoozedUntil && !isXsDown && (
				<span style={{ paddingTop: "12px" }}>
					Snoozed until{" "}
					{dayjs(snoozedUntil, "YYYY-MM-DD").format("MMM DD, YYYY")}
				</span>
			)}
			<IconButton onClick={handleClick} className={classes.dropDownBtn}>
				<SnoozeIcon />
			</IconButton>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				open={Boolean(anchorEl)}
				onClose={closeMenu}
			>
				<MenuItem
					disabled
					className={classes.menuItem}
					style={{ height: "40px" }}
				>
					<Box>
						<Typography variant="subtitle1">Snooze updates for...</Typography>
					</Box>
				</MenuItem>
				{[
					{
						title: "2 weeks",
						date: dayjs().add(2, "week"),
					},
					{
						title: "1 month",
						date: dayjs().add(1, "month"),
					},
					{
						title: "3 months",
						date: dayjs().add(3, "month"),
					},
				].map(periodOption)}
				<MenuItem
					style={{ borderTop: `1px solid ${theme.palette.divider}` }}
					className={classes.menuItem}
					onClick={() => setIsOpenDatePicker(true)}
				>
					<EventIcon className={classes.menuIcon} />
					<Box>
						<Typography variant="subtitle1" className={classes.menuText}>
							Pick date
						</Typography>
					</Box>
				</MenuItem>
				{displayRemove ? (
					<MenuItem className={classes.menuItem} onClick={handleUnfollow}>
						<RemoveIcon className={classes.menuIcon} />
						<Box>
							<Typography variant="subtitle1" className={classes.menuText}>
								Remove from watch list
							</Typography>
						</Box>
					</MenuItem>
				) : (
					<MenuItem
						className={classes.menuItem}
						onClick={() => handleSnooze(null)}
					>
						<RemoveCircleOutlineIcon className={classes.menuIcon} />
						<Box>
							<Typography variant="subtitle1" className={classes.menuText}>
								Unsnooze
							</Typography>
						</Box>
					</MenuItem>
				)}
			</Menu>
			<MuiPickersUtilsProvider utils={DayUtils}>
				<DatePicker
					minDate={new Date()}
					open={isOpenDatePicker}
					onOpen={() => setIsOpenDatePicker(true)}
					onClose={() => setIsOpenDatePicker(false)}
					value={selectedDate}
					onChange={(date) => handleSnooze(date.format("YYYY-MM-DD"))}
					TextFieldComponent={() => null}
				/>
			</MuiPickersUtilsProvider>
		</>
	);
}

SnoozeMenu.propTypes = {
	onSnooze: PropTypes.func.isRequired,
	onUnfollow: PropTypes.func,
	displayRemove: PropTypes.bool,
	snoozedUntil: PropTypes.string,
};

SnoozeMenu.defaultProps = {
	onUnfollow: () => {},
	displayRemove: false,
	snoozedUntil: null,
};
