import PreviewIcon from "@mui/icons-material/Preview";
import {
	Box,
	Card,
	CardContent,
	Chip,
	IconButton,
	Popover,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import type React from "react";
import { useState } from "react";

interface CriterionCardProps {
	id: string;
	name: string;
	description: string;
	scoringType: "binary" | "numeric" | "categorical";
	minScore?: number;
	maxScore?: number;
	categories?: string[];
}
import CriteriaPreview from "./CriteriaPreview";

const CriterionCard = ({
	id,
	name,
	description,
	scoringType,
	minScore,
	maxScore,
	categories,
}: CriterionCardProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	return (
		<Card
			sx={{
				width: {
					xs: "95%",
					sm: 320,
					md: 480,
				},
				height: {
					xs: "auto",
					sm: 240,
				},
			}}
		>
			<CardContent>
				<Box display="flex" alignItems="center">
					<Typography variant="h4" component="div" gutterBottom>
						{name}
					</Typography>
					<IconButton onClick={handleClick} size="small">
						<PreviewIcon />
					</IconButton>

					<Popover
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "left",
						}}
					>
						<Box sx={{ p: 2 }}>
							<CriteriaPreview
								description={description}
								scoringType={scoringType}
								minScore={minScore}
								maxScore={maxScore}
								categories={categories}
							/>
						</Box>
					</Popover>
				</Box>

				<Typography variant="body1" color="text.secondary" gutterBottom>
					<Tooltip title={description}>
						<Box
							sx={{
								maxHeight: "64px",
								overflow: "hidden",
								textOverflow: "ellipsis",
								display: "-webkit-box",
								WebkitLineClamp: 3,
								WebkitBoxOrient: "vertical",
							}}
						>
							{description}
						</Box>
					</Tooltip>
				</Typography>

				<Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
					Scoring Type: {scoringType}
				</Typography>

				{scoringType === "numeric" && (
					<Box sx={{ mt: 1 }}>
						<Typography variant="body2" color="text.secondary">
							Score Range: Min: {minScore} Max: {maxScore}
						</Typography>
					</Box>
				)}

				{scoringType === "categorical" &&
					categories &&
					categories.length > 0 && (
						<Box sx={{ mt: 2 }}>
							<Typography variant="body2" color="text.secondary" gutterBottom>
								Categories:
							</Typography>
							<Stack direction="row" spacing={1} flexWrap="wrap">
								{categories.map((category) => (
									<Chip key={category} label={category} size="small" />
								))}
							</Stack>
						</Box>
					)}

				<Typography
					variant="caption"
					color="text.secondary"
					sx={{ mt: 2, display: "block" }}
				>
					ID: {id}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default CriterionCard;
