import {
	Biotech,
	CorporateFare,
	Cottage,
	CurrencyBitcoin,
	DeveloperBoard,
	ElectricBolt,
	Engineering,
	FamilyRestroom,
	HealthAndSafety,
	LocalHospital,
	LocalShipping,
	MilitaryTech,
	PrecisionManufacturing,
	Psychology,
	Restaurant,
	Savings,
	Security,
	VideogameAsset,
} from "@mui/icons-material";

export default {
	consumer: {
		Icon: FamilyRestroom,
		label: "Consumer",
	},
	real_estate: {
		Icon: Cottage,
		label: "Real Estate",
	},
	enterprise_b_2_b: {
		Icon: CorporateFare,
		label: "Enterprise B2B",
	},
	vertical_ai: {
		Icon: Psychology,
		label: "Vertical AI",
	},
	health: {
		Icon: LocalHospital,
		label: "Health",
	},
	security: {
		Icon: Security,
		label: "Security",
	},
	food_retail: {
		Icon: Restaurant,
		label: "Food / Retail",
	},
	fintech: {
		Icon: Savings,
		label: "Financial Technology",
	},
	blockchain_crypto: {
		Icon: CurrencyBitcoin,
		label: "Blockchain / Crypto",
	},
	biotech_pharma: {
		Icon: Biotech,
		label: "Bio Tech & Pharma",
	},
	energy_sustainability: {
		Icon: ElectricBolt,
		label: "Energy & Sustainability",
	},
	metaverse: {
		Icon: VideogameAsset,
		label: "Metaverse",
	},
	advanced_computing: {
		Icon: DeveloperBoard,
		label: "Advanced Computing",
	},
	// old sectors ^
	enterprise_b2b: {
		Icon: CorporateFare,
		label: "Enterprise B2B",
	},
	bio_tech_pharma: {
		Icon: Biotech,
		label: "Bio Tech & Pharma",
	},
	financial_technology: {
		Icon: Savings,
		label: "Financial Technology",
	},
	transportation_logistics: {
		Icon: LocalShipping,
		label: "Transportation & Logistics",
	},
	strategic_tech: {
		Icon: MilitaryTech,
		label: "Strategic Tech",
	},
	advanced_engineering: {
		Icon: Engineering,
		label: "Advanced Engineering",
	},
	advanced_manufacturing: {
		Icon: PrecisionManufacturing,
		label: "Advanced Manufacturing",
	},
	health_wellness: {
		Icon: HealthAndSafety,
		label: "Health & Wellness",
	},
	food_retail_consumer: {
		Icon: Restaurant,
		label: "Food, Retail, & Consumer",
	},
	energy: {
		Icon: ElectricBolt,
		label: "Energy",
	},
};
