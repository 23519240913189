import type { paths } from "@/api/index";
import axios from "axios";

type GetConversations = paths["/api/chats/conversations"]["get"];
export type GetConversationsResponse =
	GetConversations["responses"]["200"]["content"]["application/json"];

export const getConversations = async (
	q?: string,
): Promise<GetConversationsResponse> => {
	const result = await axios.get<GetConversationsResponse>(
		"/api/chats/conversations",
		{
			params: { q },
		},
	);
	return result.data;
};

type GetConversation =
	paths["/api/chats/conversations/{conversation_id}"]["get"];
export type GetConversationResponse =
	GetConversation["responses"]["200"]["content"]["application/json"];

export const getConversation = async (
	conversationId: string,
): Promise<GetConversationResponse> => {
	const result = await axios.get<GetConversationResponse>(
		`/api/chats/conversations/${conversationId}`,
	);
	return result.data;
};

type UpdateConversation =
	paths["/api/chats/conversations/{conversation_id}"]["put"];
export type UpdateConversationBody =
	UpdateConversation["requestBody"]["content"]["application/json"];
export type UpdateConversationResponse =
	UpdateConversation["responses"]["200"]["content"]["application/json"];

export const updateConversation = async (
	conversationId: string,
	body: UpdateConversationBody,
): Promise<UpdateConversationResponse> => {
	const result = await axios.put<UpdateConversationResponse>(
		`/api/chats/conversations/${conversationId}`,
		body,
	);
	return result.data;
};

type DeleteConversation =
	paths["/api/chats/conversations/{conversation_id}"]["delete"];
export type DeleteConversationResponse =
	DeleteConversation["responses"]["200"]["content"]["application/json"];

export const deleteConversation = async (
	conversationId: string,
): Promise<DeleteConversationResponse> => {
	const result = await axios.delete<DeleteConversationResponse>(
		`/api/chats/conversations/${conversationId}`,
	);
	return result.data;
};

type CreateModel = paths["/api/chats/models"]["post"];
export type CreateModelBody =
	CreateModel["requestBody"]["content"]["application/json"];
export type ModelResponse =
	CreateModel["responses"]["200"]["content"]["application/json"];

export const createModel = async (
	model: CreateModelBody,
): Promise<ModelResponse> => {
	const result = await axios.post<ModelResponse>("/api/chats/models", model);
	return result.data;
};

type GetModels = paths["/api/chats/models"]["get"];
export type GetModelsResponse =
	GetModels["responses"]["200"]["content"]["application/json"];

export const listModels = async (): Promise<GetModelsResponse> => {
	const result = await axios.get<GetModelsResponse>("/api/chats/models");
	return result.data;
};

export type Model = GetModelsResponse[0];

type CreatePrompt = paths["/api/chats/prompts"]["post"];
export type CreatePromptBody =
	CreatePrompt["requestBody"]["content"]["application/json"];
export type PromptResponse =
	CreatePrompt["responses"]["200"]["content"]["application/json"];

export const createPrompt = async (
	prompt: CreatePromptBody,
): Promise<PromptResponse> => {
	const result = await axios.post<PromptResponse>("/api/chats/prompts", prompt);
	return result.data;
};

type GetPrompts = paths["/api/chats/prompts"]["get"];
export type GetPromptsResponse =
	GetPrompts["responses"]["200"]["content"]["application/json"];

export const listPrompts = async (
	userId?: string,
): Promise<GetPromptsResponse> => {
	const result = await axios.get<GetPromptsResponse>("/api/chats/prompts", {
		params: { userId },
	});
	return result.data;
};

export type Prompt = GetPromptsResponse[0];

type CreateCriteria = paths["/api/chats/criteria"]["post"];
export type CreateCriteriaBody =
	CreateCriteria["requestBody"]["content"]["application/json"];
export type CriteriaResponse =
	CreateCriteria["responses"]["200"]["content"]["application/json"];

export const createEvaluationCriteria = async (
	criteria: CreateCriteriaBody,
): Promise<CriteriaResponse> => {
	const result = await axios.post<CriteriaResponse>(
		"/api/chats/criteria",
		criteria,
	);
	return result.data;
};

type GetCriteria = paths["/api/chats/criteria"]["get"];
export type GetCriteriaResponse =
	GetCriteria["responses"]["200"]["content"]["application/json"];

export const listEvaluationCriteria =
	async (): Promise<GetCriteriaResponse> => {
		const result = await axios.get<GetCriteriaResponse>("/api/chats/criteria");
		return result.data;
	};

export type Criterion = GetCriteriaResponse[0];

type EvaluatePrompt = paths["/api/chats/evaluate"]["post"];
export type EvaluatePromptBody =
	EvaluatePrompt["requestBody"]["content"]["application/json"];
export type EvaluateResponse =
	EvaluatePrompt["responses"]["200"]["content"]["application/json"];

export const evaluatePrompt = async (
	request: EvaluatePromptBody,
): Promise<EvaluateResponse> => {
	const result = await axios.post<EvaluateResponse>(
		"/api/chats/evaluate",
		request,
	);
	return result.data;
};

type CreatePromptFamily = paths["/api/chats/prompt-families"]["post"];
export type CreatePromptFamilyBody =
	CreatePromptFamily["requestBody"]["content"]["application/json"];
export type PromptFamilyResponse =
	CreatePromptFamily["responses"]["200"]["content"]["application/json"];

export const createPromptFamily = async (
	promptFamily: CreatePromptFamilyBody,
): Promise<PromptFamilyResponse> => {
	const result = await axios.post<PromptFamilyResponse>(
		"/api/chats/prompt-families",
		promptFamily,
	);
	return result.data;
};

// For listing prompt families
type GetPromptFamilies = paths["/api/chats/prompt-families"]["get"];
export type GetPromptFamiliesResponse =
	GetPromptFamilies["responses"]["200"]["content"]["application/json"];

export const listPromptFamilies =
	async (): Promise<GetPromptFamiliesResponse> => {
		const result = await axios.get<GetPromptFamiliesResponse>(
			"/api/chats/prompt-families",
		);
		return result.data;
	};

export type PromptFamily = GetPromptFamiliesResponse[0];
// For deleting prompt families
type DeletePromptFamily =
	paths["/api/chats/prompt-families/{prompt_family_id}"]["delete"];
export type DeletePromptFamilyResponse =
	DeletePromptFamily["responses"]["200"]["content"]["application/json"];

export const deletePromptFamily = async (
	promptFamilyId: string,
): Promise<DeletePromptFamilyResponse> => {
	const result = await axios.delete<DeletePromptFamilyResponse>(
		`/api/chats/prompt-families/${promptFamilyId}`,
	);
	return result.data;
};

type GetCompletions = paths["/api/chats/completions"]["get"];
export type GetCompletionsResponse =
	GetCompletions["responses"]["200"]["content"]["application/json"];

export const getCompletions = async (): Promise<GetCompletionsResponse> => {
	const result = await axios.get<GetCompletionsResponse>(
		"/api/chats/completions",
	);
	return result.data;
};

// Get completion detail
type GetCompletionDetail =
	paths["/api/chats/completions/{completion_id}"]["get"];
export type GetCompletionDetailResponse =
	GetCompletionDetail["responses"]["200"]["content"]["application/json"];

export const getCompletionDetail = async (
	completionId: string,
): Promise<GetCompletionDetailResponse> => {
	const result = await axios.get<GetCompletionDetailResponse>(
		`/api/chats/completions/${completionId}`,
	);
	return result.data;
};

export default {
	createEvaluationCriteria,
	createModel,
	createPrompt,
	createPromptFamily,
	deleteConversation,
	deletePromptFamily,
	evaluatePrompt,
	getConversation,
	getConversations,
	listEvaluationCriteria,
	listModels,
	listPromptFamilies,
	listPrompts,
	updateConversation,
	getCompletions,
	getCompletionDetail,
};
