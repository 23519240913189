import {
	downloadAllFiles,
	downloadFile,
	downloadTemplate,
	postMetrics,
	putFileTask,
	uploadMetricsFile as uploadFile,
} from "@/api/Metrics";
import { canFetchAllTasks } from "@/constants/Roles";
import type { User } from "@/hooks/useAuth";
import {
	Alert,
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	LinearProgress,
	Snackbar,
	TextField,
	Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";

import OrganizationTaskListHeader from "./OrganizationTaskListHeader";
import OrganizationTaskListTask from "./OrganizationTaskListTask";
import UploadDiff from "./UploadDiff";

type Task = {
	valorId: string;
	filename: string;
	createdDatetime: string;
	status: string;
	reportingPeriods: string[];
	keyMetrics: string[];
	assignedUser?: {
		id: string;
		firstName: string;
		lastName: string;
		profilePicture: string;
	};
	tasks: {
		taskId: string;
		name: string;
		reportingPeriod: string;
		status: string;
	}[];
	progress: {
		remainingTaskCount: number;
		completedTaskCount: number;
	};
};

type OrganizationTaskListProps = {
	company: {
		name;
		domain;
		logoUrl;
		fyeMonth: number;
		sensitiveCompany: boolean;
	};
	activeTask: Task;
	currentUser: User;
	onChange: () => void;
	fetchTasks: () => void;
	fetchTasksDisabled: boolean;
};

function canFetchTasks(currentUser: User) {
	return canFetchAllTasks(currentUser);
}

export default function OrganizationTaskList({
	company,
	currentUser,
	activeTask,
	onChange,
	fetchTasks,
	fetchTasksDisabled,
}: OrganizationTaskListProps) {
	const [latestDiff, setLatestDiff] = useState(null);
	const [diffOpen, setDiffOpen] = useState(false);
	const [diffDisabled, setDiffDisabled] = useState(false);
	const [startErrorOpen, setStartErrorOpen] = useState(false);
	const [skipErrorOpen, setSkipErrorOpen] = useState(false);
	const [askForCommentOpen, setAskForCommentOpen] = useState(false);
	const [commentOpen, setCommentOpen] = useState(false);
	const [commentValue, setCommentValue] = useState("");
	const [commentDisabled, setCommentDisabled] = useState(false);
	const [previousActiveTask, setPreviousActiveTask] = useState(null);
	const [commentSuccess, setCommentSuccess] = useState(false);
	const [emptyDiffOpen, setEmptyDiffOpen] = useState(false);

	function openAskForCommentDialog() {
		setAskForCommentOpen(true);
	}

	function openCommentDialog() {
		setCommentOpen(true);
	}

	const { fyeMonth, sensitiveCompany } = company;

	let numerator = 0;
	let denominator = 0;
	if (activeTask) {
		numerator = activeTask.progress.completedTaskCount;
		denominator =
			activeTask.progress.completedTaskCount +
			activeTask.progress.remainingTaskCount;
		if (numerator === denominator) {
			numerator -= 1;
		}
	}

	return (
		<Box display="flex" flexDirection="column" my={0.5}>
			<Box
				display="flex"
				flexDirection={{ xs: "column", sm: "row" }}
				alignItems={{
					xs: "flex-start",
					sm: "center",
				}}
				my={0.5}
				gap={1}
				data-cy={`organization-task-list-${company.valorId}`}
			>
				<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
					<OrganizationTaskListHeader
						valorId={company.valorId}
						name={company.name}
						avatar={company.logoUrl}
					/>
					<Box visibility={{ xs: "hidden", sm: "inherit" }}>&gt;</Box>
				</Box>
				{!activeTask && (
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
						flexGrow={1}
						gap={1}
					>
						<Box>
							<Typography variant="body2">No tasks yet.</Typography>
							{company.lastUpdatedAt && (
								<Typography variant="caption">
									Last updated:{" "}
									{dayjs.utc(company.lastUpdatedAt).format("MMMM D, YYYY")}
								</Typography>
							)}
						</Box>
						{canFetchTasks(currentUser) && (
							<Button
								size="small"
								color="primary"
								variant="outlined"
								onClick={() => {
									fetchTasks();
								}}
								disabled={fetchTasksDisabled}
							>
								Fetch Tasks
							</Button>
						)}
					</Box>
				)}
				{activeTask && (
					<Box flexGrow={1}>
						<OrganizationTaskListTask
							fileName={activeTask.filename}
							fileDate={activeTask.createdDatetime}
							state={activeTask.status}
							periods={activeTask.reportingPeriods}
							metrics={activeTask.keyMetrics}
							currentUser={currentUser}
							user={activeTask.assignedUser}
							remainingTaskCount={activeTask.progress.remainingTaskCount}
							completedTaskCount={activeTask.progress.completedTaskCount}
							downloadFileAndTemplate={() => {
								downloadTemplate(
									company.name,
									activeTask.valorId,
									activeTask.filename,
								);
								downloadFile(activeTask.tasks.map((x) => x.taskId));
							}}
							downloadAllFiles={() => {
								downloadAllFiles(activeTask.valorId);
							}}
							uploadMetricsFile={async (file) => {
								// TODO: handle diff + errors
								const diff = await uploadFile(
									file,
									activeTask.tasks.map((x) => x.taskId),
								);
								if (diff.data.length === 0) {
									setEmptyDiffOpen(true);
									onChange();
								} else {
									setLatestDiff(diff);
									setDiffOpen(true);
									onChange();
								}
							}}
							skipTask={async (status, details) => {
								try {
									await putFileTask(
										activeTask.tasks.map((x) => x.taskId),
										status,
										details,
									);
								} catch (e) {
									setSkipErrorOpen(true);
								}
								onChange();
							}}
							startTask={async () => {
								try {
									await putFileTask(
										activeTask.tasks.map((x) => x.taskId),
										"IN_PROGRESS",
									);
								} catch (e) {
									setStartErrorOpen(true);
								}
								onChange();
							}}
						/>
					</Box>
				)}

				<Dialog
					open={diffOpen}
					onClose={() => {
						if (!diffDisabled) {
							setDiffOpen(false);
						}
					}}
					maxWidth="xl"
					fullWidth
				>
					<DialogTitle>{`Changes for ${activeTask?.filename}`}</DialogTitle>
					<DialogContent>
						{latestDiff && (
							<>
								<UploadDiff diff={latestDiff} periodType="MONTHLY" />
								<UploadDiff diff={latestDiff} periodType="QUARTERLY" />
								<UploadDiff diff={latestDiff} periodType="YEARLY" />
							</>
						)}
					</DialogContent>
					<DialogActions>
						<Button
							color="primary"
							variant="outlined"
							onClick={() => {
								setLatestDiff(null);
								setDiffOpen(false);
							}}
							disabled={diffDisabled}
						>
							Reject
						</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={async () => {
								setDiffDisabled(true);
								await postMetrics(company.valorId, {
									task_ids: activeTask.tasks.map((x) => x.taskId),
									data: latestDiff.data,
								});
								setDiffDisabled(false);
								setDiffOpen(false);
								setLatestDiff(null);

								setPreviousActiveTask(activeTask);
								openAskForCommentDialog();

								onChange();
							}}
							disabled={diffDisabled}
						>
							Accept
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={askForCommentOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						{`Do you want to add commentary for ${previousActiveTask?.filename}?`}
					</DialogTitle>
					<DialogContent>
						<Typography variant="body1">
							This could include issues with data, labelling, unclear
							instructions etc.
						</Typography>
					</DialogContent>
					<DialogActions>
						<Button
							color="primary"
							variant="outlined"
							onClick={() => {
								setAskForCommentOpen(false);
							}}
						>
							No
						</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={() => {
								setAskForCommentOpen(false);
								openCommentDialog();
							}}
						>
							Yes
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog open={commentOpen} maxWidth="md" fullWidth>
					<DialogTitle>
						{`Commentary for ${previousActiveTask?.filename}`}
					</DialogTitle>
					<DialogContent>
						<Box py={1}>
							<TextField
								label="Add Comments..."
								multiline
								fullWidth
								rows={4}
								disabled={commentDisabled}
								value={commentValue}
								onChange={(e) => {
									setCommentValue(e.target.value);
								}}
							/>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							color="primary"
							variant="outlined"
							disabled={commentDisabled}
							onClick={() => {
								setCommentOpen(false);
								setCommentValue("");
								setCommentDisabled(false);
							}}
						>
							Cancel
						</Button>
						<Button
							color="primary"
							variant="outlined"
							disabled={commentDisabled}
							onClick={async () => {
								setCommentDisabled(true);
								await putFileTask(
									previousActiveTask.tasks.map((x) => x.taskId),
									null,
									null,
									commentValue,
								);

								setCommentOpen(false);
								setCommentValue("");
								setCommentDisabled(false);
								setCommentSuccess(true);
							}}
						>
							Submit
						</Button>
					</DialogActions>
				</Dialog>

				<Snackbar
					open={commentSuccess}
					autoHideDuration={6000}
					onClose={() => {
						setCommentSuccess(false);
					}}
				>
					<Alert
						onClose={() => {
							setCommentSuccess(false);
						}}
						severity="success"
					>
						Comment submitted successfully. Thank you!
					</Alert>
				</Snackbar>

				<Snackbar
					open={skipErrorOpen || startErrorOpen}
					autoHideDuration={6000}
					onClose={() => {
						setSkipErrorOpen(false);
						setStartErrorOpen(false);
					}}
				>
					<Alert
						onClose={() => {
							setSkipErrorOpen(false);
							setStartErrorOpen(false);
						}}
						severity="error"
					>
						Action failed. Please refresh the page.
					</Alert>
				</Snackbar>

				<Snackbar
					open={emptyDiffOpen}
					autoHideDuration={6000}
					onClose={() => {
						setEmptyDiffOpen(false);
					}}
				>
					<Alert
						onClose={() => {
							setEmptyDiffOpen(false);
						}}
						severity="warning"
					>
						No changes detected. Please check your file or use the skip
						function.
					</Alert>
				</Snackbar>
			</Box>

			<Box
				display="flex"
				flexDirection="row"
				alignItems="center"
				gap={1}
				paddingY={0.5}
			>
				{numerator + denominator > 0 && (
					<>
						<LinearProgress
							variant="determinate"
							value={(numerator / denominator) * 100}
							sx={{
								flexGrow: 1,
								maxWidth: 200,
								height: 8,
								borderRadius: 4,
							}}
						/>
						<Typography variant="caption">
							{`${numerator} / ${denominator} tasks completed`}
						</Typography>
					</>
				)}

				{fyeMonth && fyeMonth !== 12 && activeTask && (
					<Chip
						variant="filled"
						size="small"
						color="error"
						label={`FYE: ${dayjs()
							.month(fyeMonth - 1)
							.format("MMM")}`}
					/>
				)}

				{sensitiveCompany && (
					<Chip
						variant="filled"
						size="small"
						color="warning"
						label="Sensitive Company"
					/>
				)}
			</Box>
			<Divider />
		</Box>
	);
}
