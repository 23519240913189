import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import React from "react";

type DateInputProps = {
	value: string | null;
	onChange: (value: string | null) => void;
	label: string;
	required?: boolean;
	disabled?: boolean;
};

export default function DateInput({
	value,
	onChange,
	label,
	required,
	disabled,
	error,
}: DateInputProps) {
	return (
		<DatePicker
			disabled={disabled}
			sx={{
				width: "100%",
			}}
			label={label}
			value={value ? dayjs(value).local() : null}
			onChange={(date) => {
				onChange(date ? dayjs(date).format("YYYY-MM-DD") : null);
			}}
			slotProps={{
				field: {
					clearable: true,
					// onClear: () => {
					//   onChange(null);
					// },
					onKeyDown: (e) => {
						e.preventDefault();
					},
					required,
					error,
				},
			}}
			closeOnSelect
			orientation="portrait"
		/>
	);
}
