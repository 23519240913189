import {
	FormControl,
	MenuItem,
	Select,
	type SelectChangeEvent,
} from "@mui/material";
import React from "react";

type Color = "red" | "yellow" | "green" | null;

const colors: Color[] = [null, "red", "yellow", "green"];

interface ColoredSelectProps {
	value: Color;
	onChange: (value: Color) => void;
	id: string;
}

export function ColoredSelect({
	value,
	onChange,
	id,
}: ColoredSelectProps): JSX.Element {
	const handleChange = (event: SelectChangeEvent<Color>) => {
		onChange(event.target.value as Color);
	};

	return (
		<FormControl
			variant="standard"
			margin="dense"
			sx={{
				width: "100px",
				backgroundColor: value || undefined,
			}}
		>
			<Select id={id} value={value} onChange={handleChange}>
				{colors.map((color) => (
					<MenuItem
						sx={{
							backgroundColor: color || undefined,
							minHeight: "30px",
						}}
						key={color ?? "none"}
						value={color}
					/>
				))}
			</Select>
		</FormControl>
	);
}

export default ColoredSelect;
