import { searchPersons } from "@/api/People";
import SAYT from "@/components/SAYT";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import renderTextWithHighlighting from "@/utils/renderTextWithHighlighting";
import { Box, Chip } from "@mui/material";
import React from "react";

type PersonInputValue = {
	id: string;
	firstName: string;
	lastName: string;
	primaryEmail: string;
};

type PersonInputProps = {
	value?: PersonInputValue;
	onChange: (value: PersonInputValue | PersonInputValue[]) => void;
	multiple?: boolean;
	required?: boolean;
	disabled?: boolean;
	label?: string;
};

export default function PersonInput({
	value,
	onChange,
	multiple,
	required,
	disabled,
	label,
	error,
}: PersonInputProps) {
	const buildPersonUrl = (firstName, lastName) => {
		let url = `${window.location.origin}/person`;
		if (firstName) url += `?firstName=${encodeURIComponent(firstName)}`;
		if (lastName) url += `&lastName=${encodeURIComponent(lastName)}`;
		return url;
	};

	const splitName = (input) => {
		const [firstName, ...rest] = input.trim().split(" ");
		const lastName = rest.join(" ");
		return {
			firstName,
			lastName,
		};
	};

	return (
		<SAYT
			value={multiple ? value || [] : value}
			onChange={onChange}
			multiple={multiple}
			disabled={disabled}
			style={{ width: "100%" }}
			label={label}
			search={(term) => searchPersons(null, term)}
			getOptionSelected={(option, selectedOption) =>
				option.id === selectedOption.id
			}
			getOptionLabel={(option: { firstName: string; lastName: string }) =>
				option ? `${option.firstName} ${option.lastName}` : ""
			}
			useDefaultFilter={false}
			required={required}
			renderTags={(tags, getTagProps) => (
				<>
					{tags.map((option, index) => (
						<Chip
							key={option.id}
							sx={{ margin: 0.5 }}
							label={`${option.firstName} ${option.lastName}`}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...getTagProps({ index })}
						/>
					))}
				</>
			)}
			renderOption={(option, { inputValue }) => {
				const isCreateNewPersonOption = option.id === "create-person";
				const currentExperiences = option?.experience
					?.filter((exp) => !exp.endDate)
					.slice(0, 3);
				return (
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
							alignItems: "center",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								py: 1,
							}}
						>
							{isCreateNewPersonOption ? (
								<span>
									<i>{option.text}</i>
									<i>
										<b>{inputValue}</b>
									</i>
								</span>
							) : (
								<>
									<span>
										{renderTextWithHighlighting(
											`${option.firstName} ${option.lastName}`,
											inputValue,
										)}
									</span>
									<span>
										{renderTextWithHighlighting(
											option.primaryEmail,
											inputValue,
										)}
									</span>
								</>
							)}
						</Box>
						<Box
							sx={{
								display: "flex",
								gap: 1,
								marginLeft: "auto",
							}}
						>
							{currentExperiences?.map((currentExperience) => (
								<CompanyAvatar
									key={currentExperience.organization.valorId}
									size="40"
									src={currentExperience.organization?.logoUrl}
									name={currentExperience.organization.name}
								/>
							))}
						</Box>
					</Box>
				);
			}}
			error={error}
			additionalOptions={[
				{
					id: "create-person",
					text: "Create new contact ",
				},
			]}
			handleAdditionalOptions={(option, inputValue) => {
				let tempValue = option;
				if (Array.isArray(option)) {
					tempValue = option.at(-1);
				}
				if (tempValue && tempValue.id === "create-person") {
					const { firstName, lastName } = splitName(inputValue || "");
					window.open(buildPersonUrl(firstName, lastName), "_blank");
					return true;
				}
				return false;
			}}
		/>
	);
}
