import {
	createTaskSurveyResponse,
	deleteTaskSurveyResponse,
	getTaskLinkedResponses,
	getTaskPossibleResponses,
} from "@/api/Process";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import UserAvatar from "@/ui/atoms/UserAvatar";
import {
	Autocomplete,
	Box,
	Breadcrumbs,
	Button,
	Card,
	CardActionArea,
	CardContent,
	Collapse,
	Divider,
	Skeleton,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useQuery, useQueryClient } from "react-query";

import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";
import { getSurveyLink } from "@/utils/surveys";

const VIEW = "view";
const ADD = "add";
const MODE = { VIEW, ADD };

type SurveySelectorProps = {
	open: boolean;
	processId: string;
	taskId: string;
	primaryCompany: {
		valorId: string;
	};
};

export default function SurveySelector({
	open,
	processId,
	taskId,
	primaryCompany,
}: SurveySelectorProps) {
	const [view, setView] = React.useState(MODE.VIEW);
	const [expanded, setExpanded] = React.useState(false);

	const queryClient = useQueryClient();
	const valorId = primaryCompany?.valorId;

	const linkedParams = {
		processId,
		taskId,
		valorIds: [valorId],
	};
	const {
		data: linkedResponses,
		isLoading: isLoadingLinkedResponses,
		isError: isErrorLinkedResponses,
		status: statusLinkedResponses,
	} = useQuery(
		["linkedSurveyResponses", linkedParams],
		async () => {
			try {
				return await getTaskLinkedResponses(linkedParams);
			} catch (err) {
				if (err.response.status === 404 || err.response.status === 422) {
					throw new Error(err.response.status);
				} else {
					throw new Error(`Unexpected error: ${err.response.status}`);
				}
			}
		},
		{
			enabled: !!processId && !!taskId && !!valorId,
		},
	);

	const responsesParams = {
		processId,
		taskId,
		valorIds: [valorId],
	};
	const {
		data: responses,
		isLoading: isLoadingResponses,
		isError: isErrorResponses,
		status: statusResponses,
	} = useQuery(
		["surveyResponses", responsesParams],
		async () => {
			try {
				return await getTaskPossibleResponses(responsesParams);
			} catch (err) {
				if (err.response.status === 404 || err.response.status === 422) {
					throw new Error(err.response.status);
				} else {
					throw new Error(`Unexpected error: ${err.response.status}`);
				}
			}
		},
		{
			enabled: !!processId && !!taskId && !!valorId,
		},
	);

	const handleLinkSurvey = () => {
		setView(MODE.ADD);
	};

	if (!open) {
		return null;
	}

	const renderCard = (response) => (
		<Card key={response.id}>
			<CardActionArea
				onClick={() =>
					window.open(
						`/org/${valorId}/response/${response.id}`,
						"_blank",
						"noopener,noreferrer",
					)
				}
			>
				<CardContent sx={{ py: 1, px: 2 }}>
					<Box display="flex" flexGrow={1} gap={2}>
						<Box display="flex" gap={1} alignItems="center" flexDirection="row">
							<CompanyAvatar
								name={response.organization.name}
								size="40"
								src={response.organization.logoUrl}
							/>
						</Box>
						<Divider orientation="vertical" flexItem />
						<Box
							id="this-one"
							display="flex"
							gap={1}
							alignItems="center"
							flexDirection="row"
							flexGrow={1}
							flexBasis={0}
							minWidth={0}
						>
							<Typography
								variant="body2"
								style={{ fontWeight: "bold", whiteSpace: "normal" }}
							>
								{response.vertical === "after_action_review"
									? `${InvestmentProductMappings[response.vertical]} \u2014 ${response.surveyName}`
									: `${InvestmentProductMappings[response.department]} \u2014 ${response.surveyName}`}
							</Typography>
						</Box>
						<Divider orientation="vertical" flexItem />
						<Box display="flex" gap={1} alignItems="center" flexDirection="row">
							<UserAvatar user={response.user} />
							<Stack>
								<Typography variant="body2">
									{`${response.user.firstName} ${response.user.lastName}`}
								</Typography>
								<Typography variant="body2">
									{dayjs.utc(response.completedAt).local().format("MM/DD/YYYY")}
								</Typography>
							</Stack>
						</Box>
					</Box>
				</CardContent>
			</CardActionArea>
		</Card>
	);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleChange = async (event, newValue, reason, detail) => {
		try {
			if (reason === "selectOption") {
				queryClient.setQueryData(
					["linkedSurveyResponses", linkedParams],
					() => newValue,
				);
				const response = await createTaskSurveyResponse({
					processId,
					taskId,
					responseId: detail.option.id,
				});
				if (response?.status === 409) {
					throw new Error(response.status);
				}
			} else if (reason === "removeOption") {
				queryClient.setQueryData(
					["linkedSurveyResponses", linkedParams],
					() => newValue,
				);
				const response = await deleteTaskSurveyResponse({
					processId,
					taskId,
					responseId: detail.option.id,
				});
				if (response?.status === 404) {
					throw new Error(response.status);
				}
			}
		} catch (err) {
			queryClient.invalidateQueries(["linkedSurveyResponses", linkedParams], {
				exact: true,
			});
			// throw toast on error
		}
	};

	if (isErrorResponses || isErrorLinkedResponses) {
		return (
			<Box>
				<Card>
					<CardContent>
						<Box>
							<Typography
								variant="body2"
								sx={{
									display: "flex",
									justifyContent: "center",
									whiteSpace: "normal",
								}}
							>
								Something went wrong with displaying linked surveys. If this
								issue persists, contact the labs team.
							</Typography>
						</Box>
					</CardContent>
				</Card>
			</Box>
		);
	}

	if (
		isLoadingResponses ||
		isLoadingLinkedResponses ||
		statusLinkedResponses !== "success" ||
		statusResponses !== "success"
	) {
		return (
			<Box>
				<Box
					marginBottom={1}
					display="flex"
					justifyContent="space-between"
					flexDirection="column"
					sx={{ gap: "8px" }}
				>
					<Box display="flex" sx={{ gap: "8px" }}>
						<Skeleton variant="rounded" height="36px" width="80px" />
						<Skeleton variant="rounded" height="40px" width="90px" />
					</Box>
					<Box>
						<Skeleton variant="rounded" height="50px" width="100%" />
					</Box>
				</Box>
			</Box>
		);
	}

	return (
		<Box minWidth={400} maxWidth="100%">
			<Box display="flex" flexDirection="row" alignItems="center" gap={1}>
				{view === MODE.VIEW && (
					<>
						<Breadcrumbs>
							<Typography>Surveys</Typography>
						</Breadcrumbs>
						<Button
							variant="outlined"
							size="small"
							onClick={handleLinkSurvey}
							disabled={isLoadingResponses}
						>
							Link Surveys
						</Button>
					</>
				)}
				{view === MODE.ADD && (
					<Breadcrumbs>
						<Button variant="text" onClick={() => setView(MODE.VIEW)}>
							Surveys
						</Button>
						<Typography>Link Surveys</Typography>
					</Breadcrumbs>
				)}
			</Box>

			<Box
				visibility={view === MODE.VIEW ? "visible" : "hidden"}
				height={view === MODE.VIEW ? "auto" : 0}
			>
				{linkedResponses.length === 0 && (
					<Box p={1}>
						<Typography>No linked surveys found</Typography>
					</Box>
				)}
				{linkedResponses.length > 0 && (
					<Box
						overflow="auto"
						mt={1}
						display="flex"
						flexDirection="column"
						gap={1}
					>
						{linkedResponses.slice(0, 2).map((r) => renderCard(r))}
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<Box
								overflow="auto"
								mb={1}
								display="flex"
								flexDirection="column"
								gap={1}
							>
								{linkedResponses.slice(2).map((r) => renderCard(r))}
							</Box>
						</Collapse>
						{linkedResponses.length > 2 && (
							<Button onClick={handleExpandClick}>
								{expanded
									? "Show Less"
									: `Show ${linkedResponses.length - 2} More`}
							</Button>
						)}
					</Box>
				)}
			</Box>

			{view === MODE.ADD && responses && (
				<Box my={1}>
					<Autocomplete
						id="surveys-select"
						multiple
						options={responses}
						getOptionKey={(option) => option.id}
						getOptionLabel={(option) =>
							`${option.surveyName} | ${option.user.firstName} ${option.user.lastName} | ${dayjs.utc(option.completedAt).local().format("MM/DD/YYYY")}`
						}
						disableCloseOnSelect
						clearIcon={null}
						value={linkedResponses}
						sx={{ width: "100%" }}
						renderInput={(params) => (
							<TextField
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...params}
								label="Survey Responses"
								placeholder="Select survey responses"
							/>
						)}
						onChange={handleChange}
						isOptionEqualToValue={(option, value) => option.id === value.id}
					/>
				</Box>
			)}
		</Box>
	);
}
