import React, { useEffect, useCallback } from "react";

import {
	deleteConversation,
	getConversation,
	updateConversation,
} from "@/api/Chat";
import ChatBox from "@/pages/Grok/ChatBox";
import { Alert, Box } from "@mui/material";
import {
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import useChat, {
	CHAT_ERROR,
	CHAT_RESPONSE_CANCELLED,
	CHAT_RESPONSE_CHUNK,
	CHAT_RESPONSE_COMPLETE,
	STREAM_CANCELLED,
} from "../useChat";
import ChatHeader from "./ChatHeader";
import Conversation from "./Conversation";

export default function Chat() {
	const { id: conversationId } = useParams();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const location = useLocation();

	const {
		joinConversation,
		leaveConversation,
		stopStream,
		sendMessage,
		subscribe,
		unsubscribe,
	} = useChat();

	const [disabled, setDisabled] = React.useState(true);

	const [chatDisabled, setChatDisabled] = React.useState(false);
	const [chatLoading, setChatLoading] = React.useState(true);
	const [alertMessage, setAlertMessage] = React.useState("");

	const [conversation, setConversation] = React.useState({
		conversation: null,
		messages: [],
	});

	const addChunk = useCallback(
		(id, chunk) => {
			// check if last message is from the same user
			setConversation((prev) => {
				if (!prev?.messages) return prev?.messages;
				const lastMessage = prev.messages[prev.messages.length - 1];
				if (lastMessage && lastMessage?.type === "assistant") {
					return {
						...prev,
						messages: [
							...prev.messages.slice(0, prev.messages.length - 1),
							{
								...lastMessage,
								stream: [...lastMessage.stream, chunk],
							},
						],
					};
				}
				return {
					...prev,
					messages: [
						...prev.messages,
						{
							id: crypto.randomUUID(),
							type: "assistant",
							stream: [chunk],
						},
					],
				};
			});
		},
		[setConversation],
	);

	const addReply = useCallback(
		(id, reply) => {
			setConversation((prev) => ({
				...prev,
				messages: [
					...prev.messages,
					{
						id: crypto.randomUUID(),
						role: "user",
						content: reply,
					},
				],
			}));
		},
		[setConversation],
	);

	const handleSend = (message) => {
		sendMessage(conversationId, message);
		addReply(conversationId, message);
	};

	const handleStop = () => {
		stopStream(conversationId);
	};

	useEffect(() => {
		if (!conversationId) {
			const currentSearchParams = searchParams.toString();
			const queryString = currentSearchParams ? `?${currentSearchParams}` : "";
			if (queryString) {
				navigate(`/grok/chat/${crypto.randomUUID()}${queryString}`, {
					replace: true,
					state: { question: location.state.question },
				});
			} else {
				navigate(`/grok/chat/${crypto.randomUUID()}`, {
					replace: true,
					state: { question: location.state.question },
				});
			}
		} else {
			setChatLoading(true);
			setDisabled(true);
			getConversation(conversationId)
				.then((data) => {
					setChatLoading(false);
					setDisabled(false);
					// only update if no messages exist yet
					setConversation((prev) => ({
						conversation: data.conversation,
						messages: prev.messages.length <= 0 ? data.messages : prev.messages,
					}));
				})
				.catch((error) => {
					console.error("Failed to fetch conversation:", error);
					setChatLoading(false);
					setDisabled(false);
				});
		}
	}, [conversationId, navigate, searchParams, location.state?.question]);

	useEffect(() => {
		if (!conversationId) {
			return () => {};
		}

		joinConversation(conversationId);

		return () => {
			leaveConversation(conversationId);
		};
	}, [conversationId, joinConversation, leaveConversation]);

	useEffect(() => {
		if (!conversationId) {
			return;
		}
		const question = location.state?.question;
		if (question) {
			sendMessage(conversationId, question);
			addReply(conversationId, question);
			navigate(`/grok/chat/${conversationId}`, { replace: true });
			setChatLoading(false);
			setDisabled(false);
		}
	}, [conversationId, location, addReply, sendMessage, navigate]);

	const reenableChat = useCallback(() => {
		setChatDisabled(false);
	}, [setChatDisabled]);
	const handleChatResponseChunk = useCallback(
		(data) => {
			setChatDisabled(true);
			addChunk(conversationId, data);
		},
		[addChunk, conversationId],
	);

	const handleError = useCallback(
		(error) => {
			setAlertMessage(error.message);
			reenableChat();
		},
		[setAlertMessage, reenableChat],
	);

	useEffect(() => {
		subscribe(CHAT_RESPONSE_CHUNK, handleChatResponseChunk);
		subscribe(CHAT_ERROR, handleError);
		subscribe(CHAT_RESPONSE_COMPLETE, reenableChat);
		subscribe(STREAM_CANCELLED, reenableChat);
		subscribe(CHAT_RESPONSE_CANCELLED, reenableChat);

		return () => {
			unsubscribe(CHAT_RESPONSE_CHUNK);
			unsubscribe(CHAT_ERROR);
			unsubscribe(CHAT_RESPONSE_COMPLETE);
			unsubscribe(STREAM_CANCELLED);
			unsubscribe(CHAT_RESPONSE_CANCELLED);
		};
	}, [
		subscribe,
		unsubscribe,
		reenableChat,
		handleError,
		handleChatResponseChunk,
	]);

	return (
		<Box
			width="100%"
			height="100%"
			position="relative"
			display="flex"
			flexDirection="column"
			paddingTop={{
				xs: 0,
				sm: 4,
			}}
		>
			<ChatHeader
				title={conversation.conversation?.title}
				disabled={disabled}
				onRename={(title) => {
					setConversation((prev) => ({
						...prev,
						conversation: {
							...prev.conversation,
							title,
						},
					}));
					return updateConversation(conversationId, {
						title,
					});
				}}
				onDelete={() => {
					navigate("/grok");
					return deleteConversation(conversationId);
				}}
			/>
			{alertMessage && (
				<Alert severity="error" onClose={() => setAlertMessage("")}>
					{alertMessage}
				</Alert>
			)}
			<Conversation
				conversation={conversation}
				loading={chatLoading}
				awaitingResponse={
					// is last message user?
					conversation.messages.length > 0 &&
					conversation.messages[conversation.messages.length - 1].role ===
						"user"
				}
			/>
			<ChatBox
				onSend={handleSend}
				onStop={handleStop}
				disabled={chatDisabled}
				placeholder="reply to Grok..."
			/>
		</Box>
	);
}
