import { getResponses } from "@/api/FrameworkSurvey";
import { useQuery } from "react-query";

export default function useActiveDealSurveys(selectedDeal: string | null) {
	return useQuery(
		["activeP2PResponse", selectedDeal],
		() => getResponses({ linkedTaskIds: [selectedDeal] }),
		{ enabled: Boolean(selectedDeal) },
	);
}
