import React from "react";

import {
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
} from "@mui/material";

import { getForm } from "@/api/Process";
import { Close } from "@mui/icons-material";
import { Box, useMediaQuery } from "@mui/system";
import { useQuery } from "react-query";
import ProcessTaskSAYT from "../ProcessTaskSAYT";

import FormContainer from "./index";

function SelectTask({ processId, onChange }) {
	const [value, setValue] = React.useState(null);
	return (
		<ProcessTaskSAYT
			processId={processId}
			value={value}
			onChange={(e) => {
				setValue(e);
				onChange(e);
			}}
		/>
	);
}

export default function Wizard({
	open,
	onClose,
	processId,
	formId,
	forms = null,
}) {
	const [activeOpportunity, setActiveOpportunity] = React.useState(null);

	let { data: currentForm } = useQuery(
		["form", formId],
		() => getForm(processId, formId),
		{
			enabled: !!open && !!processId && !!formId && !forms,
		},
	);

	if (forms) {
		currentForm = forms?.find((f) => f.id === formId);
	}

	const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const handleClose = () => {
		setActiveOpportunity(null);
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			fullScreen={isSmDown}
			maxWidth="md"
		>
			<DialogTitle>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					{currentForm?.title}
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Box py={1} display="flex" flexDirection="column" gap={2}>
					<SelectTask
						processId={processId}
						onChange={(opportunity) => {
							setActiveOpportunity(opportunity);
						}}
					/>

					{activeOpportunity && <Divider />}

					{activeOpportunity && (
						<FormContainer
							processId={processId}
							formId={formId}
							oppId={activeOpportunity.id}
							onCancel={handleClose}
							onSubmit={handleClose}
						/>
					)}
				</Box>
			</DialogContent>
		</Dialog>
	);
}
