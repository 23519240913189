import { Box } from "@mui/material";
import type React from "react";
import { forwardRef, useMemo } from "react";
import { MaskedField } from "./TaskFormField";

import { getFieldValue } from "../ProcessView/ViewUtils";
import LifecycleStageField from "./LifecycleStageField";
import TaskFormLoader from "./TaskFormLoader";
import useEditTaskForm from "./useEditTaskForm";

type EditTaskFormProps = {
	processId: string;
	taskId: string;
	onBeforeUpdate: () => void;
	onUpdateError: () => void;
	onUpdate: () => void;
	scroll?: "none" | "auto";
	alert: React.ReactNode;
};

const EditTaskForm = forwardRef(
	(
		{
			processId,
			taskId,
			onBeforeUpdate,
			onUpdateError,
			onUpdate,
			scroll,
			alert,
		}: EditTaskFormProps,
		ref: React.Ref<{ validate: () => boolean }>,
	) => {
		const {
			isLoadingFields,
			isLoadingTask,
			nameField,
			descriptionField,
			formFields,
			computedValues,
			primaryCompany,
			primaryValorId,
			currentStageId,
			hasLifecycle,
			fieldsByStage,
			requiredFields,
			unfilledRequiredFields,
			primaryCompanyFieldsDisabled,
			validating,
			handleBlur,
			getStageIdentifier,
			openStage,
			setOpenStage,
		} = useEditTaskForm({
			processId,
			taskId,
			onBeforeUpdate,
			onUpdateError,
			onUpdate,
			ref,
		});

		const filteredFields = useMemo(
			() => fieldsByStage?.filter((stage) => stage.hasVisibleFields),
			[fieldsByStage],
		);

		if (isLoadingFields || isLoadingTask || !taskId) {
			return <TaskFormLoader edit />;
		}

		return (
			<Box
				display="flex"
				flexDirection={{
					xs: "column",
					md: "row",
				}}
				gap={1}
				width="100%"
			>
				<Box
					display="flex"
					flexDirection="column"
					gap={1}
					flexGrow={1}
					padding={1}
					sx={{
						overflowY: {
							xs: "visible",
							md: "auto",
						},
						maxHeight: {
							xs: "auto",
							md: scroll !== "none" ? "80vh" : "auto",
						},
					}}
				>
					{alert}
					<Box display="flex" flexDirection="column" gap={1}>
						{!hasLifecycle && (
							<>
								{nameField && (
									<MaskedField
										key={nameField.id}
										field={nameField}
										value={getFieldValue(
											computedValues?.[nameField.id],
											nameField.type,
										)}
										onBlur={handleBlur}
										organizationName={primaryCompany?.name}
										required={requiredFields.some((f) => f.id === nameField.id)}
										disabled={false}
										error={
											validating &&
											unfilledRequiredFields.some((f) => f.id === nameField.id)
										}
									/>
								)}

								{descriptionField && (
									<MaskedField
										key={descriptionField.id}
										field={descriptionField}
										value={getFieldValue(
											computedValues?.[descriptionField.id],
											descriptionField.type,
										)}
										onBlur={handleBlur}
										organizationName={primaryCompany?.name}
										required={requiredFields.some(
											(f) => f.id === descriptionField.id,
										)}
										disabled={false}
										error={
											validating &&
											unfilledRequiredFields.some(
												(f) => f.id === descriptionField.id,
											)
										}
									/>
								)}

								{formFields?.map(
									(d) =>
										d && (
											<MaskedField
												key={d.id}
												field={d}
												value={getFieldValue(computedValues?.[d.id], d.type)}
												onBlur={handleBlur}
												organizationName={primaryCompany?.name}
												required={requiredFields.some((f) => f.id === d.id)}
												disabled={
													(d.isOrganizationField &&
														(primaryCompanyFieldsDisabled ||
															// has primary company
															!primaryValorId)) ||
													d.readOnly
												}
												error={
													validating &&
													unfilledRequiredFields.some((f) => f.id === d.id)
												}
											/>
										),
								)}
							</>
						)}
						{hasLifecycle && (
							<Box>
								{filteredFields.map(
									({ stageId, stageName, isFieldDependent, fields }) => (
										<LifecycleStageField
											key={stageId}
											stageId={stageId}
											stageName={stageName}
											isFieldDependent={isFieldDependent}
											fields={fields}
											currentStageId={currentStageId}
											validating={validating}
											unfilledRequiredFields={unfilledRequiredFields}
											getStageIdentifier={getStageIdentifier}
											computedValues={computedValues}
											handleBlur={handleBlur}
											requiredFields={requiredFields}
											primaryCompany={primaryCompany}
											primaryCompanyFieldsDisabled={
												primaryCompanyFieldsDisabled
											}
											primaryValorId={primaryValorId}
											openStage={openStage}
											setOpenStage={setOpenStage}
										/>
									),
								)}
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		);
	},
);

EditTaskForm.displayName = "EditTaskForm";
export default EditTaskForm;
