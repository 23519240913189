import dayjs from "dayjs";

export default function isDateOnlyISOString(str) {
	// try to parse strictly as YYYY-MM-DD
	const dateOnly = dayjs(str, "YYYY-MM-DD", true);
	if (dateOnly.isValid() && str.length === 10) {
		return true;
	}
	return false;
}
