import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import type React from "react";
import { useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import BuildEvaluation from "./BuildEvals";
import CriteriaEditor from "./EvaluationCriteria/CriteriaEditor";
import CriteriaList from "./EvaluationCriteria/CriteriaList";
import Evaluation from "./Evaluations/Evaluation";
import EvaluationList from "./Evaluations/EvaluationList";
import AddModel from "./Models/AddModel";
import ModelsList from "./Models/ModelsList";
import PrivateContexts from "./PrivateContexts";
import AddPromptFamily from "./Prompts/AddPromptFamily";
import PromptEditor from "./Prompts/PromptEditor";
import PromptList from "./Prompts/PromptList";

const tabSections = [
	"models",
	"prompts",
	"criteria",
	"evals",
	"submit",
	"private",
];

function ModelsSection() {
	const [view, setView] = useState<"list" | "editor">("list");

	return (
		<Box>
			{view === "list" ? (
				<>
					<Typography variant="h3" gutterBottom>
						Models Management
					</Typography>
					<ModelsList />
					<Button onClick={() => setView("editor")}>Add New Model</Button>
				</>
			) : (
				<>
					<Button onClick={() => setView("list")}>Back to List</Button>
					<AddModel />
				</>
			)}
		</Box>
	);
}

function PromptsSection() {
	const [view, setView] = useState<"list" | "prompt" | "family">("list");

	return (
		<Box>
			{view === "list" ? (
				<>
					<Typography variant="h3" gutterBottom>
						Prompts Management
					</Typography>
					<PromptList />
					<Button onClick={() => setView("prompt")}>Create New Prompt</Button>
					<Button onClick={() => setView("family")}>
						Create New Prompt Family
					</Button>
				</>
			) : view === "prompt" ? (
				<>
					<Button onClick={() => setView("list")}>Back to List</Button>
					<PromptEditor />
				</>
			) : (
				<>
					<Button onClick={() => setView("list")}>Back to List</Button>
					<AddPromptFamily />
				</>
			)}
		</Box>
	);
}

function CriteriaSection() {
	const [view, setView] = useState<"list" | "editor">("list");

	return (
		<Box>
			{view === "list" ? (
				<>
					<Typography variant="h3" gutterBottom>
						Evaluation Criteria Management
					</Typography>
					<CriteriaList />
					<Button onClick={() => setView("editor")}>Add New Criteria</Button>
				</>
			) : (
				<>
					<Button onClick={() => setView("list")}>Back to List</Button>
					<CriteriaEditor />
				</>
			)}
		</Box>
	);
}

function EvaluationSection() {
	const { id } = useParams();

	if (id) {
		return <Evaluation id={id} />;
	}

	return (
		<Box>
			<Typography variant="h3" gutterBottom>
				Past Evaluations
			</Typography>
			<EvaluationList />
		</Box>
	);
}

function SubmitSection() {
	return <BuildEvaluation />;
}
export default function PromptEvals() {
	const navigate = useNavigate();
	const { section } = useParams();

	const currentTab = section ? tabSections.indexOf(section) : 0;

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		navigate(`/evals/${tabSections[newValue]}`);
	};

	// - connect model params

	// - add system prompt to non system prompt completions
	// - connect to main chat
	// - templatize and make general (filtering in and out date)

	// - take prompt renderer and pull out and bring to eval system

	// - get back from belce what best format for eval is
	//  	- set max token

	if (!section) {
		return <Navigate to="/evals/models" replace />;
	}

	return (
		<Box sx={{ width: "100%" }}>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs value={currentTab} onChange={handleChange}>
					<Tab label="Models" />
					<Tab label="Prompts" />
					<Tab label="Evaluation Criteria" />
					<Tab label="Past Evaluations" />
					<Tab label="Submit Eval" />
					<Tab label="Private Contexts" />
				</Tabs>
			</Box>
			<Box sx={{ p: 3 }}>
				{section === "models" && <ModelsSection />}
				{section === "prompts" && <PromptsSection />}
				{section === "criteria" && <CriteriaSection />}
				{section === "evals" && <EvaluationSection />}
				{section === "submit" && <SubmitSection />}
				{section === "private" && <PrivateContexts />}
			</Box>
		</Box>
	);
}
