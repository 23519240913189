import type { ResponsesSearchResult } from "@/api/FrameworkSurvey";
import dayjs from "dayjs";
import React from "react";

import useActiveDealSurveys from "./hooks/useActiveDealSurveys";
import useMonthlyAssessmentTable from "./hooks/useMonthlyAssessmentTable";
import useP2PMetricsTable from "./hooks/useP2PMetricsTable";
import useP2PResponses from "./hooks/useP2PResponses";
import useRichCommentary from "./hooks/useRichCommentary";
import { processOpportunities } from "./utils/opportunityUtils";
import matchSurveys from "./utils/surveyMatchers";

// Type guard to check if organization exists and has required properties
const isValidOrganization = (
	org: ResponsesSearchResult["organization"] | undefined,
): org is ResponsesSearchResult["organization"] =>
	org !== undefined && "valorId" in org && "name" in org;

export default function useP2PAnalysis(
	selectedDeal: string | null,
	setSelectedDeal: (deal: string) => void,
) {
	const lastXDays = 7;
	const completedSince = dayjs()
		.subtract(lastXDays, "days")
		.format("YYYY-MM-DD");

	const { data: p2pResponses, isLoading: p2pResponsesLoading } =
		useP2PResponses(lastXDays);
	const { data: activeDealSurveys, isLoading: activeDealSurveysLoading } =
		useActiveDealSurveys(selectedDeal);

	const matchedSurveys = React.useMemo(
		() => matchSurveys(activeDealSurveys),
		[activeDealSurveys],
	);

	const activeSurvey = React.useMemo(
		() => matchedSurveys?.sixMonthSurvey || matchedSurveys?.oneMonthSurvey,
		[matchedSurveys],
	);

	const opportunities = React.useMemo(() => {
		return processOpportunities(p2pResponses);
	}, [p2pResponses]);

	const activeDeal = React.useMemo(() => {
		if (!p2pResponses || !selectedDeal) {
			return null;
		}

		return p2pResponses.find((response) => {
			if (!isValidOrganization(response.organization)) {
				return false;
			}

			return response.linkedTasks?.find((n) => n.internalId === selectedDeal);
		});
	}, [p2pResponses, selectedDeal]);

	const { monthlyAssessmentData, monthlyAssessmentColumns } =
		useMonthlyAssessmentTable(matchedSurveys);

	const richCommentary = useRichCommentary(activeSurvey);

	const { data: p2pMetricsData, columns: p2pMetricsColumns } =
		useP2PMetricsTable(activeSurvey);

	React.useEffect(() => {
		if (p2pResponses?.length > 0) {
			setSelectedDeal((prev) => {
				if (!prev) {
					return p2pResponses.find((n) => n.linkedTasks?.length > 0)
						?.linkedTasks?.[0]?.internalId;
				}
				return prev;
			});
		}
	}, [p2pResponses, setSelectedDeal]);

	return {
		lastXDays,
		completedSince,
		activeSurvey,
		p2pResponses,
		p2pResponsesLoading,
		opportunities,
		selectedDeal,
		matchedSurveys,
		monthlyAssessmentData,
		monthlyAssessmentColumns,
		richCommentary,
		setSelectedDeal,
		activeDeal,
		p2pMetricsData,
		p2pMetricsColumns,
		isLoading: p2pResponsesLoading || activeDealSurveysLoading,
		activeDealSurveysLoading,
	};
}
