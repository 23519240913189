import { useAuth } from "@/hooks/useAuth";
import useIsInTouchPWA from "@/hooks/useIsInTouchPWA";
import useLandscape from "@/hooks/useLandscape";
import { destinations } from "@/utils/destinations";
import { ArrowBackIos } from "@mui/icons-material";
import {
	AppBar,
	Box,
	IconButton,
	Toolbar,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export function ActionBar() {
	const navigate = useNavigate();
	const isInTouchPWA = useIsInTouchPWA();
	const isLandscape = useLandscape();
	const { isAuthenticated, user } = useAuth();
	const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const defaultItems = useMemo(
		() => [
			{
				Icon: ArrowBackIos,
				name: "Back",
				path: -1,
				canSee: () => true,
			},
			destinations.Home,
			{ ...destinations.HomeInvest, smallName: "Invest" },
			{ ...destinations.HomeIR, smallName: "IR" },
			{ ...destinations.HomeInfra, smallName: "Infra" },
			{ ...destinations.HomeScale, smallName: "Scale" },
		],
		[],
	);

	const navigationItems = useMemo(
		() => defaultItems.filter((item) => item.canSee(user)),
		[user, defaultItems],
	);

	if (!isAuthenticated || !isInTouchPWA || isLandscape) {
		return null;
	}

	const renderNavigationItem = (item, index) => (
		<Box
			key={index}
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
		>
			<IconButton
				onClick={() =>
					item.onClick ? item.onClick(navigate) : navigate(item.path)
				}
				sx={{
					width: {
						xs: "32px",
						// md: "2",
					},
					height: {
						xs: "32px",
						// md: 2,
					},
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<item.Icon
					sx={
						item.Icon === ArrowBackIos
							? { color: (t) => t.palette.action.active, marginLeft: "50%" }
							: {}
					}
				/>
				<Typography variant="gigaSmall">
					{isSmDown && item.smallName ? item.smallName : item.name}
				</Typography>
			</IconButton>
		</Box>
	);

	return (
		<AppBar
			position="fixed"
			color="inherit"
			sx={{
				top: "auto",
				bottom: 0,
				backgroundColor: (t) => t.palette.background.blue,
				height: "64px",
			}}
		>
			<Toolbar>
				<Box
					width="100%"
					sx={{
						marginLeft: { xs: 0, sm: "96px" },
						display: "flex",
						gap: { xs: "8px", md: "48px" },
						width: "100%",
						justifyContent: { xs: "space-between", md: "flex-start" },
						alignItems: "center",
						paddingBottom: { xs: "16px", md: "0" },
					}}
				>
					{navigationItems.map(renderNavigationItem)}
				</Box>
			</Toolbar>
		</AppBar>
	);
}

export default ActionBar;
