import { Box } from "@mui/material";
import React from "react";

export default function OverflowBox({ open, children }) {
	return (
		<Box
			position="absolute"
			top={0}
			left={0}
			marginTop="-9px"
			marginLeft="-9px"
			display="flex"
			flexDirection="row"
			flexWrap="wrap"
			gap={1}
			visibility={open ? "visible" : "hidden"}
			bgcolor="background.paper"
			border={(theme) => `1px solid ${theme.palette.divider}`}
			padding={1}
			borderRadius={0.5}
			boxShadow={1}
			zIndex={(t) => t.zIndex.modal}
			minWidth={300}
			maxHeight={400}
			whiteSpace="normal"
			sx={{
				overflow: "auto",
			}}
		>
			{children}
		</Box>
	);
}
