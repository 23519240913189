import {
	type GenericDepartment,
	type MeetingType,
	getResponseReport,
	type getResponseReportResult,
} from "@/api/FrameworkSurvey";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import CompanyCell from "@/ui/atoms/CompanyCell";
import Progress from "@/ui/atoms/Progress";
import UserAvatar from "@/ui/atoms/UserAvatar";
import CustomTable from "@/ui/molecules/CustomTable";
import Page from "@/ui/molecules/Page";
import Poll from "@mui/icons-material/Poll";
import {
	Box,
	Chip,
	FormControl,
	InputLabel,
	Link,
	MenuItem,
	Popover,
	Select,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState, useMemo } from "react";
import { useQuery } from "react-query";

type RowPartial = {
	row: {
		original: getResponseReportResult[0];
	};
};

type ActiveReportData = {
	el: HTMLElement;
	data: getResponseReportResult[0]["responses"];
	valorId: string;
};

type RelativePeriods =
	| "last_week"
	| "last_two_weeks"
	| "last_month"
	| "last_quarter";
export function Meetings() {
	const [department, setDepartment] = useState<GenericDepartment>("growth");
	const [meeting] = useState<MeetingType>("first_meeting");
	const [activeReportData, setActiveReportData] =
		useState<ActiveReportData>(null);
	const today = dayjs();
	const dateOptions = {
		last_week: "Last Week",
		last_two_weeks: "Last Two Weeks",
		last_month: "Last Month",
		last_quarter: "Last Quarter",
	};
	const mapDate = {
		last_week: today.subtract(7, "days"),
		last_two_weeks: today.subtract(14, "days"),
		last_month: today.subtract(30, "days"),
		last_quarter: today.subtract(90, "days"),
	};
	const [sinceDate, setSinceDate] = useState<RelativePeriods>("last_week");

	const theme = useTheme();

	const getSurveyColor = (score) => {
		if (score >= 0.5) {
			return theme.palette.green.main;
		}
		if (score < 0.5 && score > -0.5) {
			return theme.palette.yellow.main;
		}
		return theme.palette.red.dark;
	};

	function renderScore(score: number) {
		if (score === null) {
			return null;
		}
		return (
			<Box
				display="flex"
				sx={{
					color: getSurveyColor(score),
					gap: 1,
					padding: 1,
					borderRadius: theme.shape.borderRadius,
					border: `1px solid ${theme.palette.divider}`,
				}}
			>
				<Typography>{score?.toFixed(2)}</Typography>
				<Poll />
			</Box>
		);
	}

	function renderResponses(responses, valorId) {
		return (
			<Box
				display="flex"
				alignItems="center"
				flexDirection="column"
				sx={{
					gap: 0.5,
					padding: 1,
					width: "320px",
					maxHeight: "480px",
				}}
			>
				{responses?.map((response) => (
					<Box
						key={response.id}
						display="flex"
						alignItems="center"
						sx={{ gap: 1 }}
					>
						<UserAvatar user={response.user} />
						<Typography variant="body2">
							{`${response.user.firstName} ${response.user.lastName} took ${
								response.department
							} ${response.name} survey on ${dayjs(response.completedAt).format("MM/DD/YYYY")}`}
						</Typography>
						{response.score ? renderScore(response.score) : null}
					</Box>
				))}
				<Link href={`/org/${valorId}`}>See Org Page</Link>
			</Box>
		);
	}
	const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

	const columns = useMemo(
		() => [
			{
				Header: "Company",
				accessor: "company.name",
				disableFilter: true,
				Cell: ({ row }: Partial<RowPartial>) => (
					<CompanyCell
						valorId={row.original.company.valorId}
						domain={row.original.company.domain}
						logoUrl={row.original.company.logoUrl}
						companyName={row.original.company.name}
					/>
				),
			},
			{
				Header: "Monitoring Service Lead",
				accessor: "monitoringServiceLead.firstName",
				disableFilter: true,
				Cell: ({ row: { original } }: Partial<RowPartial>) => {
					const { monitoringServiceLead } = original;
					if (monitoringServiceLead) {
						return (
							<Box display="flex" alignItems="center" sx={{ gap: "8px" }}>
								<UserAvatar user={monitoringServiceLead} />
								<Typography variant="body2">
									{`${monitoringServiceLead.firstName} ${monitoringServiceLead.lastName}`}
								</Typography>
							</Box>
						);
					}
					return <Typography variant="body2"> No MSL Set </Typography>;
				},
			},
			{
				Header: "Responses",
				accessor: "responses",
				disableFilter: true,
				Cell: ({
					row: {
						original: { responses, company },
					},
				}: Partial<RowPartial>) => {
					const averageScore =
						responses.reduce((acc, curr) => {
							const sum = acc + curr.score;
							return sum;
						}, 0) / responses.length;

					if (responses.length === 1) {
						const onlyResponse = responses[0];
						const {
							user,
							department: responseDept,
							score,
							completedAt,
						} = onlyResponse;
						return (
							<Box display="flex" alignItems="center" sx={{ gap: "8px" }}>
								<Typography variant="body2">
									{`${user.firstName} ${
										user.lastName
									} took ${responseDept} survey on ${dayjs(completedAt).format(
										"MM/DD/YYYY",
									)} `}
								</Typography>
								{renderScore(score)}
							</Box>
						);
					}

					return (
						<Box display="flex" alignItems="center" sx={{ gap: "8px" }}>
							<Chip
								label={`View ${responses?.length} Responses`}
								onClick={(e) =>
									setActiveReportData({
										el: e.currentTarget,
										data: responses,
										valorId: company.valorId,
									})
								}
							/>
							{renderScore(averageScore)}
						</Box>
					);
				},
			},
			{
				Header: "Recording Consent",
				id: "recordingConsent",
				accessor: "responses",
				disableFilter: true,
				Cell: ({
					row: {
						original: { responses },
					},
				}: Partial<RowPartial>) => {
					// grab unique answers and display those
					const dedupedAnswers = Array.from(
						responses
							.map((x) => x.answer)
							.reduce((map, obj) => map.set(obj?.color, obj), new Map())
							.values(),
					);

					const render = dedupedAnswers.map(
						({ color, choiceText, commentary }) => {
							if (color === null) {
								return null;
							}
							return (
								<Box key={color}>
									<Box
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											padding: theme.spacing(1),
											borderRadius: theme.shape.borderRadius,
											boxShadow: theme.shadows[3],
											border: `1px solid ${theme.palette[color].main}`,
										}}
									>
										<Typography variant="body2">{choiceText}</Typography>
									</Box>
									<Typography variant="caption">{commentary}</Typography>
								</Box>
							);
						},
					);

					return (
						<Box display="flex" flexDirection="column">
							{render}
						</Box>
					);
				},
			},
			{
				Header: "Recordings",
				accessor: "recordings",
				disableFilter: true,
				Cell: ({
					row: {
						original: { recordings = [] },
					},
				}: Partial<RowPartial>) => {
					const noOfRecordings = recordings?.length || 0;
					if (noOfRecordings === 0) {
						return <Typography variant="body2">No Recordings</Typography>;
					}

					return (
						<Box display="flex">
							<Chip
								label=" View Recordings"
								onClick={() =>
									window.open(
										`https://valorep.sharepoint.com/${
											recordings[0].product === "Growth" ? "Investments/" : ""
										}${recordings[0].path}`,
									)
								}
							/>
						</Box>
					);
				},
			},
		],
		[isSmDown],
	);

	const { data = [], isLoading } = useQuery(
		["getResponseReport", department, meeting, sinceDate],
		async () =>
			getResponseReport(department, meeting, mapDate[sinceDate].toDate()),
	);
	return (
		<Page data-cy="page__meeting_recordings">
			<Page.Title data-cy="meeting-report__title">Recording Report</Page.Title>
			<Page.Content>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="flex-end"
				>
					<Box
						display="flex"
						flexDirection="column"
						sx={{ gap: "8px", padding: "4px 0px" }}
					>
						<ButtonTabs
							width="240px"
							options={[
								{
									key: "growth",
									label: "Growth",
								},
								{
									key: "vsv",
									label: "VSV",
								},
							]}
							activeKey={department}
							onClick={(option) => setDepartment(option.key)}
						/>
						<ButtonTabs
							width="240px"
							options={[
								{
									key: "first_meeting",
									label: "Opportunity Assessments",
								},
							]}
							activeKey={meeting}
						/>
					</Box>
					{isLoading && (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							sx={{ marginBottom: 1 }}
						>
							<Progress />
						</Box>
					)}
					<FormControl margin="dense">
						<InputLabel>Since</InputLabel>
						<Select
							sx={{ width: "240px", height: "60px" }}
							label="Since"
							value={sinceDate}
							onChange={(e) => setSinceDate(e.target.value as RelativePeriods)}
						>
							{Object.entries(dateOptions).map(([key, value]) => (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>

				<CustomTable data={data} columns={columns} />
			</Page.Content>
			{!!activeReportData && (
				<Popover
					open={Boolean(activeReportData)}
					anchorEl={activeReportData.el}
					anchorOrigin={{
						vertical: "center",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					PaperProps={{
						onMouseLeave: () => setActiveReportData(null),
					}}
					onClose={() => setActiveReportData(null)}
					disableRestoreFocus
				>
					{renderResponses(activeReportData.data, activeReportData.valorId)}
				</Popover>
			)}
		</Page>
	);
}

export default Meetings;
