import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import { Box, Link, Typography } from "@mui/material";
import React from "react";

type OrganizationTaskListHeaderProps = {
	valorId: string;
	name: string;
	avatar: string;
};

export default function OrganizationTaskListHeader({
	valorId,
	name,
	avatar,
}: OrganizationTaskListHeaderProps) {
	return (
		<Box display="flex" flexDirection="row" alignItems="center" gap={2}>
			<Link href={`/org/${valorId}`}>
				<CompanyAvatar src={avatar} alt={name} size="30" />
			</Link>
			<Typography variant="body2">{name}</Typography>
		</Box>
	);
}
