import React, { useCallback, useMemo } from "react";

import { Box, IconButton, Tooltip, useTheme } from "@mui/material";
import hljs from "highlight.js";
import MarkdownIt from "markdown-it";
import MarkdownItKatex from "markdown-it-katex";
import "highlight.js/styles/atom-one-dark.css";
import "katex/dist/katex.min.css";
import useIsInTouchPWA from "@/hooks/useIsInTouchPWA";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ChatBubble from "./ChatBubble";
import { renderContent } from "./convertHtmltoMuiNodes";

export default function GrokChatBubble({
	content,
	stream,
}: {
	content?: string;
	stream?: { content: string }[];
}) {
	const theme = useTheme();
	const isInTouchPWA = useIsInTouchPWA();
	const color = theme.palette.background.blue;
	const fontColor = theme.palette.getContrastText(color);

	const processStream = (s) => {
		if (!s) return "";
		return s.map((x) => x.content).join("");
	};

	const html = useMemo(() => {
		const md = new MarkdownIt({
			highlight(str, lang) {
				if (lang && hljs.getLanguage(lang)) {
					try {
						return hljs.highlight(str, {
							language: lang,
							ignoreIllegals: true,
						}).value;
					} catch (error) {
						console.error("Failed to highlight:", error);
					}
				}
				return hljs.highlight(str, {
					language: "plaintext",
					ignoreIllegals: true,
				}).value;
			},
		}).use(MarkdownItKatex, {
			throwOnError: false,
			errorColor: "#cc0000",
			displayMode: true,
		});

		let processedContent = content || processStream(stream);

		processedContent = processedContent?.replace(/\\\\/g, "\\");

		// Clean up double backslashes
		processedContent = processedContent?.replace(/\\\\/g, "\\");

		// First, handle display math
		processedContent = processedContent?.replace(
			/\\\[([\s\S]*?)\\\]/g,
			(match, formula) => `\n$$${formula.trim()}$$\n`,
		);

		// Then handle inline math
		processedContent = processedContent?.replace(
			/\\\(([\s\S]*?)\\\)/g,
			(match, formula) => `$${formula.trim()}$`,
		);

		// Handle bullet points with proper spacing
		processedContent = processedContent?.replace(
			/- \*\*(.*?)\*\*:/g,
			"\n- **$1**:",
		);

		// preformatted markdown to not be preformatted
		processedContent = processedContent?.replace(
			/```markdown\n([\s\S]*?)```/g,
			(_, markdownContent) => markdownContent,
		);
		// Process first three backticks markdown to not be preformatted
		processedContent = processedContent?.replace(/```markdown/, "");

		return md.render(processedContent || "");
	}, [content, stream]);

	const handleCopyToClipboard = useCallback(() => {
		const processedContent = content || processStream(stream);
		navigator.clipboard
			.writeText(processedContent)
			.then(() => {
				console.log("Copied to clipboard");
			})
			.catch((err) => {
				console.error("Failed to copy to clipboard:", err);
			});
	}, [content, stream]);

	return (
		<ChatBubble
			sx={{
				color: fontColor,
				backgroundColor: isInTouchPWA ? "transparent" : color,
				borderColor: theme.palette.divider,
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				{renderContent(html)}
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-start",
						marginTop: 1,
					}}
				>
					<Tooltip title="Copy to clipboard">
						<IconButton onClick={handleCopyToClipboard} size="small">
							<ContentCopyIcon fontSize="small" />
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
		</ChatBubble>
	);
}
