import CenteredProgress from "@/ui/atoms/CenteredProgress";
import ImageIcon from "@mui/icons-material/Image";
import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "SVG"];

type ImageButtonProps = {
	uploadImage: ({
		file,
		url,
	}: { file?: File; url?: string }) => Promise<string>;
	droppingImage?: boolean;
};

export default function ImageButton({
	uploadImage,
	droppingImage,
}: ImageButtonProps) {
	const [imgOpen, setImgOpen] = useState(false);
	const [file, setFile] = useState<File | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [uploading, setUploading] = useState(false);

	const insertImage = async () => {
		if (!file) return;
		setError(null);
		setUploading(true);

		try {
			await uploadImage({ file });
			setImgOpen(false);
			setFile(null);
		} catch (e) {
			setError(e.message);
			// eslint-disable-next-line no-console
			console.error(e);
		}
		setUploading(false);
	};

	const handleUpload = (fileToUpload: File) => {
		setFile(fileToUpload);
	};

	return (
		<>
			<ToggleButtonGroup aria-label="image formatting">
				<ToggleButton
					value="image"
					aria-label="image"
					onClick={(e) => {
						e.preventDefault();
						setImgOpen(true);
					}}
					style={{
						animation: droppingImage ? "dance 2s infinite" : "none",
						transformOrigin: "50% 50%",
					}}
				>
					<Tooltip title="Insert Image">
						<ImageIcon />
					</Tooltip>
				</ToggleButton>
				{/* <ToggleButton
          value="screenshot"
          aria-label="screenshot"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <AspectRatioIcon />
        </ToggleButton> */}
			</ToggleButtonGroup>

			<Dialog
				open={imgOpen}
				onClose={() => {
					if (uploading) return;

					setImgOpen(false);
					setFile(null);
				}}
				fullWidth
				maxWidth="sm"
			>
				<DialogContent>
					{error && (
						<Alert severity="error">
							An error occurred when uploading, please try again or contact the
							Labs Team.
						</Alert>
					)}
					<FileUploader
						handleChange={handleUpload}
						name="file"
						types={fileTypes}
						disabled={uploading}
					>
						<div
							style={{
								background: "rgba(0, 0, 0, 0.1)",
								border: "1px dashed rgba(0, 0, 0, 0.2)",
								borderRadius: 4,
								padding: 16,
								textAlign: "center",
							}}
						>
							{file ? (
								<>
									<Typography variant="body2">{file.name}</Typography>
									<img
										src={URL.createObjectURL(file)}
										alt={file.name}
										style={{
											maxWidth: "100%",
											maxHeight: 200,
											marginTop: 16,
											filter: uploading ? "grayscale(100%)" : "none",
										}}
									/>
									{uploading && <CenteredProgress />}
								</>
							) : (
								<>
									<Typography variant="h2">
										Drag and drop an image here
									</Typography>
									<Typography variant="body2">
										or click to select a file
									</Typography>
								</>
							)}
						</div>
					</FileUploader>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setImgOpen(false);
							setFile(null);
						}}
						variant="outlined"
						disabled={uploading}
					>
						Cancel
					</Button>
					<Button
						onClick={() => insertImage()}
						color="primary"
						variant="contained"
						disabled={uploading || !file}
					>
						Insert
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
