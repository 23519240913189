import SvgIcon from "@mui/material/SvgIcon";
import React from "react";

export default function Crunchbase() {
	return (
		<SvgIcon>
			<path d="M8.69,12.47a1.92,1.92,0,0,0-.33-.88,1.39,1.39,0,0,0-1.19-.53A1.57,1.57,0,0,0,5.59,12.2a4.74,4.74,0,0,0-.23,1.61,4.31,4.31,0,0,0,.23,1.54,1.51,1.51,0,0,0,1.54,1.09A1.33,1.33,0,0,0,8.26,16a2.12,2.12,0,0,0,.41-1.12H11a3.87,3.87,0,0,1-.75,2,3.69,3.69,0,0,1-3.18,1.49A3.7,3.7,0,0,1,4,17.1a5,5,0,0,1-1-3.23,5.12,5.12,0,0,1,1.1-3.49,3.82,3.82,0,0,1,3-1.25,4.59,4.59,0,0,1,2.69.74A3.45,3.45,0,0,1,11,12.47Z" />
			<path d="M20,10.39a5,5,0,0,1,1,3.2A5.61,5.61,0,0,1,20,17a3.15,3.15,0,0,1-2.7,1.34,3.2,3.2,0,0,1-1.74-.43,3.39,3.39,0,0,1-.85-.9v1.1H12.5V5.3h2.28v5.18a3.24,3.24,0,0,1,1-.93,2.82,2.82,0,0,1,1.57-.4A3.24,3.24,0,0,1,20,10.39Zm-1.9,5.31a3.23,3.23,0,0,0,.49-1.87,4.26,4.26,0,0,0-.24-1.54,1.69,1.69,0,0,0-1.7-1.15A1.71,1.71,0,0,0,15,12.27a4.2,4.2,0,0,0-.24,1.55,3.17,3.17,0,0,0,.5,1.85,1.71,1.71,0,0,0,1.52.74A1.6,1.6,0,0,0,18.12,15.7Z" />
		</SvgIcon>
	);
}
