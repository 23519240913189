import { getSignalModelPipeline } from "@/api/Reporting";
import { getNextPageParam } from "@/components/InfiniteTable";
import { Box } from "@mui/material";
import type {
	ColumnFiltersState,
	SortingState,
	VisibilityState,
} from "@tanstack/react-table";
import React, { useState, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { JsonParam, useQueryParam, withDefault } from "use-query-params";
import MantisTable from "./MantisTable";

export default function MantisTab({
	tab,
	visible,
}: {
	tab: string;
	visible: boolean;
}) {
	const API_DEFAULTS = {
		pipeline: {
			filters: {
				OR: {
					eq: {
						"tasks.fieldValues.8371826f-d49b-4d95-9f2f-c949876d96ec.choiceId": [
							// status
							"f2e3780e-c12b-45a8-9330-507addaddd1b", // connected
							"34eb79f6-e2a7-48cc-83b2-41cd505b1622", // access
							"c3ebcb25-bb17-4de7-9b45-9c8fc683a5d8", // outreach
						],
					},
				},
			},
			sort: [
				"-tasks.fieldValues.8371826f-d49b-4d95-9f2f-c949876d96ec.choiceId", // status asc
				// "-tasks.fieldValues.93585d6b-8933-43b3-8312-d198752c5028.number", // series B MOIC desc
				"-tasks.fieldValues.5b90cbb2-7cb6-48a3-ab6e-884569ef32cd.number", // series A MOIC desc
			],
		},
		portfolio: {
			filters: {
				AND: {
					eq: {
						"tasks.fieldValues.8371826f-d49b-4d95-9f2f-c949876d96ec.choiceId": [
							// status
							"0fef1ecf-7830-4bc7-8b74-29d8973e1fd0", // won
						],
					},
				},
			},
			sort: ["-dateAdded"],
		},
		passed: {
			filters: {
				AND: {
					eq: {
						"tasks.fieldValues.8371826f-d49b-4d95-9f2f-c949876d96ec.choiceId": [
							// status
							"1576ce7a-a20b-46fb-8621-b24bd4e934f1", // lost
						],
					},
				},
			},
			sort: ["-dateAdded"],
		},
	};
	const apiFilters = useMemo(() => API_DEFAULTS[tab]?.filters || {}, [tab]);
	const apiSorting = useMemo(() => API_DEFAULTS[tab]?.sort || [], [tab]);

	const TABLE_DEFAULTS = {
		pipeline: {
			filters: [
				{
					id: "status",
					value: ["Outreach", "Connected", "Access"],
				},
			],
			visibility: {
				passReason: false,
			},
			sort: [
				{ id: "status", desc: false },
				{ id: "seriesA_moic", desc: true },
			],
		},
		portfolio: {
			filters: [
				{
					id: "status",
					value: ["Won"],
				},
			],
			visibility: {
				status: false,
				predictedMoic: false,
				features: false,
				coverage: false,
				lastContacted: false,
				passReason: false,
			},
			sort: [],
		},
		passed: {
			filters: [
				{
					id: "status",
					value: ["Lost"],
				},
			],
			visibility: {
				status: false,
				features: false,
				amount: false,
				dealType: false,
				lastDealType: false,
				predictedSectors: false,
				location: false,
				lastContacted: false,
				dealDate: false,
				dealSize: false,
				dealPremoney: false,
			},
			sort: [],
		},
	};

	const DefaultFilterQueryParam = useMemo(
		() => withDefault(JsonParam, TABLE_DEFAULTS[tab]?.filters || []),
		[tab],
	);
	const DefaultVisibilityQueryParam = useMemo(
		() => withDefault(JsonParam, TABLE_DEFAULTS[tab]?.visibility || {}),
		[tab],
	);
	const DefaultSortingQueryParam = useMemo(
		() => withDefault(JsonParam, TABLE_DEFAULTS[tab]?.sort || []),
		[tab],
	);

	const [sorting, setSorting] = useQueryParam<SortingState>(
		"sorting",
		DefaultSortingQueryParam,
	);
	const [columnFilters, setColumnFilters] = useQueryParam<ColumnFiltersState>(
		"columnFilters",
		DefaultFilterQueryParam,
	);
	const [globalFilter, setGlobalFilter] = useState<string | null>("");
	const [columnVisibility, setColumnVisibility] =
		useQueryParam<VisibilityState>(
			"columnVisibility",
			DefaultVisibilityQueryParam,
		);

	const pagedQueryKey = useMemo(
		() => ["SignalModelPipeline", sorting, columnFilters, globalFilter, tab],
		[sorting, columnFilters, globalFilter, tab],
	);

	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: pagedQueryKey,
		queryFn: ({ pageParam = null }) =>
			getSignalModelPipeline(50000, pageParam, apiSorting, apiFilters),
		getNextPageParam,
	});

	return (
		<Box
			sx={{
				display: visible ? "block" : "none",
			}}
		>
			<MantisTable
				data={data}
				isLoading={isLoading}
				isFetching={isFetching}
				isFetchingNextPage={isFetchingNextPage}
				hasNextPage={hasNextPage}
				fetchNextPage={fetchNextPage}
				sorting={sorting}
				columnFilters={columnFilters}
				columnVisibility={columnVisibility}
				globalFilter={globalFilter}
				onSortingChange={setSorting}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onColumnVisibilityChange={setColumnVisibility}
			/>
		</Box>
	);
}
