import { listPrompts } from "@/api/Chat";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Box,
	CircularProgress,
	Container,
	Typography,
} from "@mui/material";
import React from "react";
import { useMemo } from "react";
import { useQuery } from "react-query";
import PromptCard from "./PromptCard";

export const PromptList = () => {
	const {
		data: prompts,
		isLoading,
		error,
	} = useQuery("prompts", () => listPrompts(null));

	// Group prompts by category
	const categorizedPrompts = useMemo(() => {
		if (!prompts) return {};

		return prompts.reduce((acc: { [key: string]: typeof prompts }, prompt) => {
			const category = prompt.promptFamily.name || "Uncategorized";
			if (!acc[category]) {
				acc[category] = [];
			}
			acc[category].push(prompt);
			return acc;
		}, {});
	}, [prompts]);

	if (isLoading) {
		return (
			<Box display="flex" justifyContent="center" m={4}>
				<CircularProgress />
			</Box>
		);
	}

	if (error) {
		return (
			<Box m={4}>
				<Alert severity="error">
					Error loading prompts: {(error as Error).message}
				</Alert>
			</Box>
		);
	}

	return (
		<Container maxWidth="md">
			<Typography variant="h4" component="h1" gutterBottom sx={{ my: 4 }}>
				Prompts
			</Typography>

			{Object.keys(categorizedPrompts).length === 0 ? (
				<Typography color="text.secondary">No prompts found.</Typography>
			) : (
				Object.entries(categorizedPrompts).map(
					([category, categoryPrompts]) => (
						<Accordion key={category} defaultExpanded>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls={`${category}-content`}
								id={`${category}-header`}
							>
								<Typography sx={{ width: "33%", flexShrink: 0 }}>
									{category}
								</Typography>
								<Typography sx={{ color: "text.secondary" }}>
									{categoryPrompts.length} prompt
									{categoryPrompts.length !== 1 ? "s" : ""}
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
									{categoryPrompts.map((prompt) => (
										<PromptCard
											key={prompt.id}
											id={prompt.id}
											content={prompt.content}
											promptFamily={prompt.promptFamily}
											creator={prompt.creator}
											metadata={prompt.metadata}
										/>
									))}
								</Box>
							</AccordionDetails>
						</Accordion>
					),
				)
			)}
		</Container>
	);
};

export default PromptList;
