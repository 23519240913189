import { Box, Typography } from "@mui/material";
import isHotkey from "is-hotkey";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
	type BaseEditor,
	type Descendant,
	Editor,
	Transforms,
	createEditor,
} from "slate";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import "./index.css";
import { withHistory } from "slate-history";

import Serializer from "../Serializer";
import { Paragraph, ServerTypes } from "../types/Element";
import Element from "./Element";
import Leaf from "./Leaf";
import EditorToolbar from "./Toolbar";

import autocapitalize from "../utils/autocapitalize";
import withHtml from "../utils/withHtml";
import withImages, {
	insertImage,
	insertPlaceholder,
	onKeyDown as imageOnKeyDown,
} from "../utils/withImages";
import withInlines from "../utils/withInlines";
import withLists, { onKeyDown as listOnKeyDown } from "../utils/withLists";

import { EditorContext } from "./EditorContext";

// type EditorOptions = {
//   fontColorSelection?: string[];fontColorSelection?
//   highlightColorSelection?: string[];highlightColorSelection?
// }

export type RichTextEditorProps = {
	onChange: (
		richText: RichText,
		editor: Editor,
		serializer: Serializer,
	) => void;
	uploadImage?: ({
		file,
		url,
	}: {
		file?: File;
		url?: string;
	}) => Promise<{ name: string; url: string }>;
	initialValue?: RichText;
	// options?: EditorOptions;options?
	hideToolbar?: boolean;
	error?: boolean;
	label?: boolean;
	autoFocus?: boolean;
};

declare module "slate" {
	interface CustomTypes {
		Editor: ReactEditor & BaseEditor;
		Descendant: AdvancedSlateElement & Descendant;
	}
}

export default function RichTextEditor({
	initialValue,
	onChange,
	uploadImage,
	// options,
	hideToolbar = false,
	error = false,
	label = "",
	autoFocus = false,
}: RichTextEditorProps) {
	const context = useContext(EditorContext);

	const { selectedColor, selectedHighlight } = context;

	const [showDropZone, setShowDropZone] = useState(false);

	const [modkey, setModkey] = useState<"Meta" | "Control">("Control");
	const [showHotkeyHints, setShowHotkeyHints] = useState(false);

	// eslint-disable-next-line react/jsx-props-no-spreading
	const renderElement = useCallback((props) => <Element {...props} />, []);
	// eslint-disable-next-line react/jsx-props-no-spreading
	const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

	const [editor] = useState<ReactEditor>(() =>
		withHtml(
			withImages(
				withLists(withInlines(withHistory(withReact(createEditor())))),
				uploadImage,
			),
		),
	);

	const insertImageFromFile = useCallback(
		async ({ file }: { file: File }): Promise<string> => {
			// upload in background
			const response = await uploadImage({ file });

			insertImage(editor, response.url, response.name);

			return "success";
		},
		[editor, uploadImage],
	);

	useEffect(() => {
		let currentTargets: EventTarget[] = [];

		const slateEditorSelector = "[data-slate-editor='true']";

		const handler = async (e: DragEvent) => {
			e.preventDefault();
			e.stopPropagation();
			setShowDropZone(false);

			// get file from event
			const file = e.dataTransfer?.files[0];

			// add to end of document
			const replacePlaceholder = insertPlaceholder(editor);
			const response = await uploadImage({ file });
			replacePlaceholder(
				response && { url: response.url, name: response.name },
			);
		};

		const handleDragOver = (e: DragEvent) => {
			if (
				!e.dataTransfer?.types?.includes("Files") ||
				e.dataTransfer?.types?.length > 1
			) {
				return;
			}

			e.preventDefault();
		};

		const handleDragEnter = (e: DragEvent) => {
			if (
				!e.dataTransfer?.types?.includes("Files") ||
				e.dataTransfer?.types?.length > 1
			) {
				return;
			}

			// check if target is part of slate
			if (e.target.closest(slateEditorSelector)) {
				return;
			}

			currentTargets.push(e.target);

			// add drop handler to new target
			e.target.addEventListener("drop", handler);

			// show drop zone if handler was added
			setShowDropZone(true);

			e.preventDefault();
		};

		const handleDragLeave = (e: DragEvent) => {
			if (
				!e.dataTransfer?.types?.includes("Files") ||
				e.dataTransfer?.types?.length > 1
			) {
				return;
			}

			// check if target is part of slate
			if (e.target.closest(slateEditorSelector)) {
				return;
			}

			currentTargets = currentTargets.filter((target) => target !== e.target);

			// remove drop handler from previous target if it exists
			e.target.removeEventListener("drop", handler);

			// hide drop zone if handler was removed
			setShowDropZone(currentTargets.length > 0);

			e.preventDefault();
		};

		document.body.addEventListener("dragover", handleDragOver);
		document.body.addEventListener("dragenter", handleDragEnter);
		document.body.addEventListener("dragleave", handleDragLeave);

		return () => {
			// remove drop handler
			currentTargets.forEach((target) =>
				target.removeEventListener("drop", handler),
			);

			// remove dragover, leave, and enter handlers
			document.body.removeEventListener("dragover", handleDragOver);
			document.body.removeEventListener("dragenter", handleDragEnter);
			document.body.removeEventListener("dragleave", handleDragLeave);
		};
	}, [editor, uploadImage]);

	editor.insertBreak = () => {
		const { selection } = editor;
		if (selection) {
			const [match] = Editor.nodes(editor, {
				match: (n) => Editor.isBlock(editor, n),
			});
			if (match) {
				// get character before cursor
				const before = Editor.before(editor, selection, { unit: "character" });
				const char =
					before &&
					Editor.string(editor, { anchor: before, focus: selection.focus });
				if (char === undefined || char === "\n") {
					// remove character before cursor
					if (char !== undefined) {
						Transforms.delete(editor, { at: before, unit: "character" });
					}

					Transforms.insertNodes(editor, {
						type: Paragraph,
						children: [{ text: "" }],
					});
				} else {
					Transforms.insertText(editor, "\n");
				}
			}
		}
	};

	const onHotkeyDown = (slate: ReactEditor, e: React.KeyboardEvent) => {
		const simpleHotkeys = {
			"mod+b": "bold",
			"mod+i": "italic",
			"mod+u": "underline",
		};
		const otherHotkeys = {
			"mod+shift+f": {
				mark: "color",
				value: selectedColor,
			},
			"mod+shift+h": {
				mark: "backgroundColor",
				value: selectedHighlight,
			},
		};
		const blockHotkeys = {
			"mod+shift+e": "left",
			"mod+shift+l": "left",
			"mod+shift+c": "center",
			"mod+shift+r": "right",
			"mod+shift+j": "justify",
		};

		if (isHotkey("mod", e)) {
			// show hotkey hints
			setModkey(e.key === "Meta" ? "Meta" : "Control");
			setShowHotkeyHints(true);
		}

		Object.entries(simpleHotkeys).forEach(([hotkey, mark]) => {
			if (isHotkey(hotkey, e)) {
				e.preventDefault();
				const marks = Editor.marks(slate);
				if (marks && marks[mark]) {
					Editor.removeMark(slate, mark);
				} else {
					Editor.addMark(slate, mark, true);
				}
			}
		});

		Object.entries(otherHotkeys).forEach(([hotkey, { mark, value }]) => {
			if (isHotkey(hotkey, e)) {
				e.preventDefault();
				const marks = Editor.marks(slate);
				if (marks && marks[mark] === value) {
					Editor.removeMark(slate, mark);
				} else {
					Editor.addMark(
						slate,
						mark,
						mark === "color" ? selectedColor : selectedHighlight,
					);
				}
			}
		});

		Object.entries(blockHotkeys).forEach(([hotkey, align]) => {
			if (isHotkey(hotkey, e)) {
				e.preventDefault();
				Transforms.setNodes(slate, { align });
			}
		});
	};

	const onHotkeyUp = (slate: ReactEditor, e: React.KeyboardEvent) => {
		if (e.key === "Meta" || e.key === "Control") {
			// hide hotkey hints
			setShowHotkeyHints(false);
		}
	};

	const [initValue] = useState<AdvancedDescendant[]>(() => {
		if (initialValue) return initialValue;
		const iv = Serializer.fromString("");
		return iv;
	});

	const filterTypes = (nodes, types) =>
		nodes
			.map((node) => ({
				...node,
				...(node.children
					? { children: filterTypes(node.children, types) }
					: null),
			}))
			.filter((node) => types.includes(node.type) || !node.type);

	return (
		<Slate
			editor={editor}
			value={initValue}
			onChange={(value) => {
				onChange(filterTypes(value, ServerTypes), this, Serializer);
			}}
		>
			{!hideToolbar && (
				<EditorToolbar
					droppingImage={showDropZone}
					uploadImage={insertImageFromFile}
					showHotkeyHints={showHotkeyHints}
					modkey={modkey}
				/>
			)}
			{label && (
				<Typography
					variant="caption"
					color={(theme) =>
						error ? theme.palette.error.main : theme.palette.text.secondary
					}
				>
					{label}
				</Typography>
			)}
			<Box
				border={(theme) =>
					error ? `solid 1px ${theme.palette.error.main}` : "solid 1px #ccc"
				}
				padding={2}
				borderRadius={1}
        onClick={() => {
          ReactEditor.focus(editor);
        }}
			>
				<Editable
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					onKeyDown={(e) => {
						onHotkeyDown(editor, e);
						listOnKeyDown(editor, e);
						imageOnKeyDown(editor, e);
					}}
					onKeyUp={(e) => {
						onHotkeyUp(editor, e);
					}}
					onDOMBeforeInput={(e) => {
						autocapitalize(editor, e);
					}}
					spellCheck
					// this enables autocapitalize on mobile, doesnt work for physical keyboards
					autoCapitalize="on"
					autoFocus={autoFocus ?? false}
				/>
			</Box>
		</Slate>
	);
}
