import { upsertUserView } from "@/api/Organization";
import { useEffect } from "react";
import { useQueryClient } from "react-query";

export default function useTripwire(
	valorId: string,
	entity: "notes" | "financials" | "competitors" | "documents" | "trascripts",
) {
	const queryClient = useQueryClient();
	useEffect(() => {
		// update last seen time
		if (valorId) {
			const data = {
				[`${entity}LastSeen`]: Math.floor(Date.now() / 1000),
			};
			upsertUserView(valorId, data);
			queryClient.setQueryData(
				["userViews", valorId],
				(oldData: { [key: string]: number }) => ({
					...oldData,
					[entity]: 0,
				}),
			);
		}
	}, [entity, valorId, queryClient]);
}
