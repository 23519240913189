import { isObject } from "@/utils/isObjectTypeGuard";
import {
	Box,
	TableBody as MUITableBody,
	Pagination as MuiPagination,
	TableRow as MuiTableRow,
	TableCell,
} from "@mui/material";
import {
	type ColumnFiltersState,
	type SortingState,
	flexRender,
} from "@tanstack/react-table";
import React, { useMemo, useContext } from "react";

export const TableRow = MuiTableRow;

const TableContext = React.createContext(null);
const useTableContext = () => {
	const tableContext = useContext(TableContext);
	if (!tableContext) {
		throw new Error("TableContext is null");
	}
	return tableContext;
};

export function TableContainer({
	totalCount,
	pageSize,
	children,
	height,
}: {
	totalCount: number;
	pageSize?: number;
	children: React.ReactNode;
	height?: number | string;
}) {
	const [currentPage, setCurrentPage] = React.useState(0);
	const tableContextState = useMemo(
		() => ({
			currentPage,
			pageSize: pageSize || 20,
		}),
		[currentPage, pageSize],
	);

	const hasPages = totalCount > pageSize;
	const pageCount = Math.ceil(totalCount / pageSize);

	function Pagination() {
		return (
			<MuiPagination
				page={currentPage + 1}
				onChange={(e, page) => setCurrentPage(page - 1)}
				count={pageCount}
				size="large"
				sx={{
					mt: 1,
				}}
			/>
		);
	}

	return (
		<TableContext.Provider value={tableContextState}>
			{hasPages && <Pagination />}
			<Box
				sx={{
					overflow: "auto",
					height: height || "auto",
				}}
			>
				{children}
			</Box>
			{hasPages && <Pagination />}
		</TableContext.Provider>
	);
}

export function TableBody({
	rows,
}: {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rows: any[];
}) {
	const tableContext = useTableContext();
	const { currentPage, pageSize } = tableContext;

	const currentRows = rows.slice(
		currentPage * pageSize,
		(currentPage + 1) * pageSize,
	);

	return (
		<MUITableBody>
			{currentRows.map((row) => (
				<MuiTableRow key={row.id}>
					{row.getVisibleCells().map((cell) => (
						<TableCell
							key={cell.id}
							sx={(theme) => ({
								background: cell.column.getIsFiltered()
									? theme.palette.background.activeTableColumn
									: row.index % 2
										? theme.palette.background.oddTableRow
										: theme.palette.background.paper,
								borderRight: `1px solid ${theme.palette.divider}`,
								boxSizing: "border-box",
								minWidth: cell.column.getSize(),
								width: cell.column.getSize(),
								maxWidth: cell.column.getSize(),
							})}
						>
							{flexRender(cell.column.columnDef.cell, cell.getContext())}
						</TableCell>
					))}
				</MuiTableRow>
			))}
		</MUITableBody>
	);
}

export const mapSorting = (sorting: SortingState): string[] =>
	sorting.map((x) => `${x.desc ? "-" : ""}${x.id}`);

const parseValue = (val: Array<{ value: string }> | { value: string }) => {
	if (Array.isArray(val) && isObject(val[0])) {
		return val.map((x) => x.value);
	}
	if (isObject(val) && val.value !== undefined) {
		return val.value;
	}
	return val;
};

export const mapFilters = (
	columnFilters: ColumnFiltersState,
	globalFilter: string | null = "",
) => ({
	...columnFilters.reduce(
		(acc, curr) => ({
			...acc,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			[curr.id]: parseValue(curr.value as any),
		}),
		{},
	),
	...(globalFilter?.length ? { "*": globalFilter } : {}),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const depageData = (data?: { pages: any[] }) =>
	data?.pages.flatMap((x) => x.data) || [];

export const getNextPageParam = (lastPage) => lastPage.markers.next;
