import { getNewsArticles } from "@/api/NewsArticles";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import NewsArticle from "@/ui/atoms/NewsArticle";
import { Box, Card, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

function NewsCard() {
	const { data: newsArticles = [], isLoading = true } = useQuery(
		["NewsArticles", { limit: 5, isFollowing: true }],
		async () => {
			const response = await getNewsArticles({ limit: 4, isFollowing: true });
			return response?.results;
		},
		{
			retry: 1,
		},
	);

	return (
		<CenteredProgress displayChildren isLoading={isLoading}>
			<Card>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						color: (theme) => theme.palette.text.secondary,
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							padding: (theme) => theme.spacing(1, 2),
							borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
						}}
					>
						<Typography
							sx={{
								color: (theme) => theme.palette.text.secondary,
								fontWeight: (theme) => theme.typography.fontWeightSemiBold,
								fontSize: "12px",
							}}
						>
							RECENT NEWS
						</Typography>
					</Box>

					<Box
						sx={{
							"& > div:not(:last-child)": {
								borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
							},
						}}
					>
						{newsArticles.length === 0 && !isLoading ? (
							<Box>
								<Typography
									sx={{
										color: (theme) => theme.palette["13"],
									}}
								>
									No Recent News.
								</Typography>
							</Box>
						) : isLoading ? (
							<Box display="flex" flexDirection="column" sx={{ gap: "1px" }}>
								<Skeleton variant="rectangular" height={82} />
								<Skeleton variant="rectangular" height={82} />
								<Skeleton variant="rectangular" height={82} />
								<Skeleton variant="rectangular" height={82} />
							</Box>
						) : (
							newsArticles?.map((article) => (
								<NewsArticle
									key={article.articleDid}
									title={article.title}
									imageUrl={article.imageUrl}
									pageUrl={article.pageUrl}
									siteName={article.siteName}
									articleTimestamp={article.articleTimestamp}
									logoUrl={article.logoUrl}
									name={article.name}
									valorId={article.valorId}
									domain={article.domain}
									backupImage="/images/news-backup.png"
									showCompany
									showArticleImage
								/>
							))
						)}
					</Box>

					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							padding: (theme) => theme.spacing(1, 2),
							borderTop: (theme) => `1px solid ${theme.palette.divider}`,
							color: (theme) => theme.palette.primary.main,
							fontWeight: (theme) => theme.typography.fontWeightBold,
							fontSize: "16px",
							textDecoration: "none !important",
							"&:hover": {
								backgroundColor: (theme) => theme.palette.action.hover,
							},
						}}
					>
						<Link to="/home/news">SEE MORE</Link>
					</Box>
				</Box>
			</Card>
		</CenteredProgress>
	);
}

export default NewsCard;
