const maskNan = (transform) => (value) => {
	if (value === "nan") {
		return "N/A";
	}
	return transform(value);
};

export const ValueTransformer = {
	default: (value) => {
		if (Number.isNaN(Number(value))) {
			return value;
		}
		return Number(Math.floor(value * 100) / 100).toLocaleString();
	},
	"%": maskNan((value) => `${Math.floor(Number(value) * 1000) / 10}%`),
	day: maskNan((value) => `${Math.floor(Number(value)).toLocaleString()} days`),
	"M ($)": maskNan(
		(value) => `$${Math.floor(Number(value)).toLocaleString()}M`,
	),
	year: maskNan((value) => `${Math.floor(Number(value) * 10) / 10} years`),
	$: maskNan((value) => `$${Math.floor(Number(value)).toLocaleString()}`),
	ratio: maskNan((value) => `${Math.floor(Number(value) * 100) / 100}x`), // 2 decimals
	rating: maskNan((value) => `${Math.floor(Number(value) * 10) / 10}`), // 1 decimal
	integer: maskNan((value) => `${Math.floor(Number(value)).toLocaleString()}`),
	sector: maskNan((value) =>
		value
			.split("_")
			.map((word) => word[0].toUpperCase() + word.slice(1))
			.join(" "),
	),
	"00%": maskNan((value) => `${Math.floor(Number(value) * 10000) / 100}%`),
};

export const toOrdinal = (n) => {
	const s = ["th", "st", "nd", "rd"];
	const v = n % 100;
	return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export default {
	ValueTransformer,
	toOrdinal,
};
