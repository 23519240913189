import TextField from "@mui/material/TextField";
import React from "react";

type TextInputProps = {
	value: string;
	onChange: (value: string) => void;
	onBlur: (value: string) => void;
	label: string;
	required?: boolean;
	multiple?: boolean;
	disabled?: boolean;
	dataCy?: string;
};

export default function TextInput({
	value,
	onChange,
	onBlur,
	label,
	required,
	multiple,
	disabled,
	error,
	dataCy,
}: TextInputProps) {
	return (
		<TextField
			label={label}
			value={value}
			onChange={(e) => onChange(e.target.value)}
			onBlur={(e) => onBlur(e.target.value)}
			maxRows={5}
			variant="standard"
			required={required}
			multiline={multiple}
			minRows={multiple ? 8 : 1}
			resize={multiple ? "vertical" : "none"}
			sx={{
				minWidth: "100%",
			}}
			disabled={disabled}
			inputProps={{
				"data-cy": dataCy,
			}}
			error={error}
		/>
	);
}
