import { createModel } from "@/api/Chat";
import {
	Alert,
	Box,
	Button,
	Container,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Snackbar,
	TextField,
	Typography,
} from "@mui/material";
import type React from "react";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

interface ModelForm {
	name: string;
	version: string;
	provider: "xai";
	parameters: string;
}

const AddModel = () => {
	const queryClient = useQueryClient();
	const [formData, setFormData] = useState<ModelForm>({
		name: "",
		version: "",
		provider: "xai",
		parameters: "{}", // Default empty object as string
	});
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [parametersError, setParametersError] = useState<string>("");

	const createModelMutation = useMutation(createModel, {
		onSuccess: () => {
			queryClient.invalidateQueries("models");
			setOpenSnackbar(true);
			// Reset form
			setFormData({
				name: "",
				version: "",
				provider: "xai",
				parameters: "{}",
			});
			setParametersError("");
		},
	});

	const validateAndProcessParameters = (parametersString: string) => {
		try {
			const trimmedParams = parametersString.trim();

			// Return null if empty object
			if (trimmedParams === "{}" || trimmedParams === "") {
				return null;
			}

			// Validate JSON
			const parsedParams = JSON.parse(trimmedParams);
			return parsedParams;
		} catch (error) {
			throw new Error("Invalid JSON format for parameters");
		}
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		setParametersError("");

		try {
			const processedParams = validateAndProcessParameters(formData.parameters);
			const submissionData = {
				...formData,
				parameters: processedParams,
			};
			createModelMutation.mutate(submissionData);
		} catch (error) {
			setParametersError((error as Error).message);
		}
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));

		// Clear parameters error when user starts typing
		if (name === "parameters") {
			setParametersError("");
		}
	};

	return (
		<Container maxWidth="sm">
			<Paper elevation={3} sx={{ p: 4, mt: 4 }}>
				<Typography variant="h5" component="h1" gutterBottom>
					Add New Model
				</Typography>

				<Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
					<TextField
						fullWidth
						label="Model Name"
						name="name"
						value={formData.name}
						onChange={handleInputChange}
						margin="normal"
						required
					/>

					<TextField
						fullWidth
						label="Version"
						name="version"
						value={formData.version}
						onChange={handleInputChange}
						margin="normal"
						required
					/>

					<FormControl fullWidth margin="normal" required>
						<InputLabel>Provider</InputLabel>
						<Select
							value={formData.provider}
							label="Provider"
							disabled // Since only 'xai' is allowed
						>
							<MenuItem value="xai">XAI</MenuItem>
						</Select>
					</FormControl>

					<TextField
						fullWidth
						label="Parameters"
						name="parameters"
						value={formData.parameters}
						onChange={handleInputChange}
						margin="normal"
						multiline
						rows={4}
						required
						error={!!parametersError}
						helperText={
							parametersError ||
							"Enter JSON parameters as a string (empty {} will be sent as null)"
						}
					/>

					<Button
						type="submit"
						variant="contained"
						fullWidth
						sx={{ mt: 3 }}
						disabled={createModelMutation.isLoading}
					>
						{createModelMutation.isLoading ? "Creating..." : "Create Model"}
					</Button>

					{createModelMutation.isError && (
						<Alert severity="error" sx={{ mt: 2 }}>
							Error creating model:{" "}
							{(createModelMutation.error as Error).message}
						</Alert>
					)}
				</Box>
			</Paper>

			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert severity="success" sx={{ width: "100%" }}>
					Model created successfully!
				</Alert>
			</Snackbar>
		</Container>
	);
};

export default AddModel;
