import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const Root = styled(Box, {
	shouldForwardProp: (prop) =>
		!["gridColumnNumber", "gridRowNumber", "noOfCompanies"].includes(prop),
})(({ theme, mode, gridColumnNumber, gridRowNumber, noOfCompanies }) => ({
	...(mode !== "map" && {
		gridColumn: gridColumnNumber,
		gridRow: gridRowNumber,
		backgroundColor: theme.palette.background.paper,
		borderBottom: `1px solid ${theme.palette.divider}`,
		borderRight: `1px solid ${theme.palette.divider}`,
	}),
	...(mode === "map" && {
		display: "grid",
		padding: theme.spacing(1),
		gridGap: "8px",
		gridTemplateColumns: `repeat(auto-fit, minmax(calc(${Math.min(
			100 / Math.ceil(Math.sqrt(noOfCompanies)),
			"25",
		)}% - 8px), .25fr) )`,
		gridTemplateRows: `repeat(auto-fit, minmax(calc(${Math.min(
			100 / Math.ceil(Math.sqrt(noOfCompanies)),
			"25",
		)}% - 8px), .25fr) )`,
		gridColumn: gridColumnNumber,
		gridRow: gridRowNumber,
		backgroundColor: theme.palette.background.paper,
		borderBottom: `1px solid ${theme.palette.divider}`,
		borderRight: `1px solid ${theme.palette.divider}`,
	}),
}));

const CellButton = styled(Button)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	width: "100%",
	height: "100%",
	"& > span": {
		display: "flex",
		flexDirection: "column",
	},
	"& > h6": {
		color: `${theme.palette.text.primary} !important`,
		textTransform: "none",
	},
}));

function MapCellRender(props) {
	const {
		rowName,
		rowKey,
		colName,
		colKey,
		onCellButtonClick,
		gridRowNumber,
		gridColumnNumber,
		mode,
		companies,
	} = props;

	const fieldDict = { row: rowKey, col: colKey };
	return (
		<Root
			mode={mode}
			gridColumnNumber={gridColumnNumber}
			gridRowNumber={gridRowNumber}
			noOfCompanies={companies.length}
		>
			{mode === "blank" && null}
			{mode === "add" && (
				<CellButton
					color="primary"
					onClick={() => onCellButtonClick(fieldDict)}
				>
					<Typography variant="subtitle1">
						Add to {rowName} and {colName}
					</Typography>
					<AddIcon />
				</CellButton>
			)}
			{mode === "remove" && (
				<CellButton
					color="secondary"
					onClick={() => onCellButtonClick(fieldDict)}
				>
					<Typography variant="subtitle1">Remove from cell</Typography>
					<RemoveCircleOutlineIcon />
				</CellButton>
			)}
			{mode === "map" &&
				Boolean(companies.length) &&
				companies.map(({ name, domain, logoUrl, valorId }) => (
					<Box
						key={valorId}
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							justifySelf: "center",
							minWidth: "100%",
							overflow: "hidden",
							cursor: "pointer",
						}}
					>
						<CompanyAvatar
							src={logoUrl}
							domain={domain}
							name={name}
							loading="lazy"
						/>
					</Box>
				))}
		</Root>
	);
}

export const MapCell = React.memo(MapCellRender);
export default MapCell;
