import IHEChanges from "@/pages/IHEChange/IHEChanges";
import Page from "@/ui/molecules/Page";
import React from "react";

function IHEChangePage() {
	return (
		<Page data-cy="page__ihe_changes">
			<Page.Content>
				<IHEChanges />
			</Page.Content>
		</Page>
	);
}

export default IHEChangePage;
