import { canSeeRevGen } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import TableReport from "@/pages/ProcessManagement/TableReport";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import Page from "@/ui/molecules/Page";
import { Box, Card } from "@mui/material";
/* eslint-disable react/prop-types */
import React from "react";
import {
	Navigate,
	Route,
	Routes,
	useNavigate,
	useParams,
} from "react-router-dom";
import Graph from "./Dashboard";

const MSL_MEETING_VIEW_ID = "78428cae-8a69-462a-8b1b-3457d9861cb7";

export default function VSVOps() {
	const { "*": section } = useParams();
	const navigate = useNavigate();
	const { user } = useAuth();

	const tabs = [
		{
			key: "dashboard",
			label: "Dashboard",
		},
		{
			key: "msl-meeting",
			label: "MSL Meeting",
		},
	];

	if (user.role_id && !canSeeRevGen(user)) {
		return <Navigate to="/" replace />;
	}
	return (
		<Page>
			<Routes>
				<Route path="" element={<Navigate to="/rev-ops/dashboard" />} />
			</Routes>
			<Page.Title>Revenue Generation</Page.Title>
			<Page.Content>
				<Box display="flex" flexDirection="column" gap={2}>
					<div style={{ width: "540px" }}>
						<ButtonTabs
							options={tabs}
							disableTest={(o) =>
								o.key === "vsv-onboarding" || o.key === "vsv-corp-support"
							}
							onClick={({ key }) => {
								navigate(`/rev-ops/${key}`);
							}}
							activeKey={section}
						/>
					</div>
					{section === "dashboard" ? <Graph /> : null}
					{section === "msl-meeting" ? (
						<Card elevation={0} sx={{ height: "auto" }}>
							<TableReport
								processId="vsv-revops"
								viewId={MSL_MEETING_VIEW_ID}
							/>
						</Card>
					) : null}
				</Box>
			</Page.Content>
		</Page>
	);
}
